import React, {Dispatch, useContext, useEffect, useMemo, useState} from "react";
import {ModalProps} from "antd/lib/modal";
import {Form, Input, Modal, notification, Select} from "antd";
import _ from "lodash";
import {IWavePosImage, IWavePosImageUpdatePayload} from "../../types/wave-pos-image";
import npiApi from "../../services/api";
import NpiLanguageContext from "../../contexts/language-context";
import {useTranslation} from "react-i18next";
import {useRequest} from "ahooks";
import {useNpiExternalPosContext} from "../../contexts/external-pos-context";
import {applyConditions, parseAnswersFormQuestion} from "../../helpers/form";
import {SelectProps} from "antd/es/select";
import {FormProps} from "antd/lib/form";
import styled from "styled-components";
import NpiDisplayDate from "../display/date";

interface INpiExternalModalImageEditProps extends ModalProps {
    image: IWavePosImage|null,
    setImage: Dispatch<IWavePosImage|null>,
    onUpdate?: Dispatch<IWavePosImage>
}

const NpiExternalModalImageEdit = ({image, setImage, onUpdate, ...props}: INpiExternalModalImageEditProps) => {
    const {t} = useTranslation();
    const {currentLang} = useContext(NpiLanguageContext);

    const {pos, waves, wavePosMappedByWaveId} = useNpiExternalPosContext();

    const [form] = Form.useForm<IWavePosImageUpdatePayload>();
    const [preview, setPreview] = useState<string>('');


    const closeModal = () => {
        setImage(null);
        form.resetFields();
        setPreview('');
    };

    useEffect(() => {
        if (!!image) {
            form.setFieldsValue(image);

            //load thumbnail
            npiApi.external.image.getImageBase64FromRoute(image.thumbnail_route).then((b64: string) => {
                setPreview(`data:${image.mime};base64,${b64}`)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [image]);


    // -- Request to edit image to server
    const {runAsync: updateImage, loading: loadingEdit} = useRequest(
        (wpiId:number, data: IWavePosImageUpdatePayload) => npiApi.external.image.editWavePosImage(wpiId, data),
        {
            manual: true,
            onSuccess: (updateImage: IWavePosImage) => {
                notification.success({message: t('EXTERNAL.CLIENT.POS_VIEW.IMAGE_EDIT_SUCCESS')})
                onUpdate?.(updateImage);
                closeModal();
            }
    });


    const formProps: FormProps = useMemo(() => ({
        form: form,
        onFinish: data => !!image ? updateImage(image.id, data): {},
        layout: "vertical",
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [image, form]);

    // -- Get visible questions for the image's wave (used to link images to question)
    const translatedQuestions: SelectProps['options'] = useMemo(() => {
        const wave = waves.find(w => w.id === image?.wave_id);
        if( wave ) {
            const wpDetails = wavePosMappedByWaveId[wave.id]!;

            const wpAnswers = parseAnswersFormQuestion(_.values(wpDetails.answers), wave.questions);
            const result = applyConditions(wave, wpAnswers, wpDetails.tags, pos!);
            if( !!result ) {
                const visiblesQuestions = result.questions.filter(q => !q.hiddenInForm);
                const waveTranslation = wave.translations['T'+currentLang?.id];

                return visiblesQuestions.map(q => ({
                    value: q.id,
                    label: _.get(waveTranslation, 'Q'+q.id+'.display_name', q.display_name)
                }))
            }
        }
        return [];
    }, [waves, wavePosMappedByWaveId, currentLang, image, pos]);


    return <Modal title={t("EXTERNAL.CLIENT.POS_VIEW.EDIT_IMG.TITLE")}
                  cancelText={t("COMMON.CANCEL")}
                  okText={t("COMMON.SAVE")}
                  onOk={form.submit}
                  confirmLoading={loadingEdit}
                  open={!!image}
                  onCancel={closeModal}
                  {...props}
    >
        {!!image && <>
            <div style={{textAlign: "center", marginBottom: "20px"}}>
                {!!preview && <StyledImage src={preview} alt={image.original_name}/>}
                <small style={{fontStyle: 'italic'}}><NpiDisplayDate value={image.created_at} hours={'inline'}/></small>
            </div>

            <Form {...formProps}>

                <Form.Item label={t("EXTERNAL.CLIENT.POS_VIEW.EDIT_IMG.COMMENT_LABEL")} name={"comment"}>
                    <Input.TextArea showCount maxLength={100}/>
                </Form.Item>

                { !!translatedQuestions.length &&
                    <Form.Item label={t("EXTERNAL.CLIENT.POS_VIEW.EDIT_IMG.QUESTION_LABEL")} name={"question_id"}>
                        <Select allowClear={true} placeholder={t("EXTERNAL.CLIENT.POS_VIEW.EDIT_IMG.QUESTION_PLACEHOLDER")} options={translatedQuestions}/>
                    </Form.Item>
                }
            </Form>
        </>}
    </Modal>
}

const StyledImage = styled.img`
    max-width: 100%;
    max-height: 25vh;
    display: block;
    margin: auto auto;
`

export default NpiExternalModalImageEdit;