import { IRegionProtectedRoute } from "../../types/route";
import { Redirect, Route } from "react-router-dom";
import React, {useContext} from "react";
import useAcl from "../../hooks/use-acl";
import {NpiInternalContext} from "../../contexts/internal-context";
import {Spin} from "antd";

const RegionProtectedRoute = (props: IRegionProtectedRoute) =>  {
    const { component: Component, regionPermissions, ...rest } = props;
    const { redirectPath =  '/npi' } = props;
    const { hasRegionFeatureAccess } = useAcl();
    const {loaded} = useContext(NpiInternalContext);

    let userAccess: boolean = hasRegionFeatureAccess(regionPermissions);

    return (
        <Route
            {...rest}
            render={(routeProps) =>
                loaded ? (
                    userAccess ? (
                        <Component {...routeProps} />
                    ) : (
                        <Redirect
                            to={{
                                pathname: redirectPath,
                                state: { from: routeProps.location }
                            }}
                        />
                    )
                ) : (
                    <div style={{textAlign: 'center', padding: '30px 50px'}}>
                        <Spin/>
                    </div>
                )
            }
        />
    );
}

export default RegionProtectedRoute;