import React, {useContext, useState} from 'react';
import {useRequest} from "ahooks";
import npiApi from "../services/api";
import {REGION_WW} from "../helpers/constants";
import _ from "lodash";
import {IRightsEnum, IUser, IUserRoleEnum} from "../types/user";
import NpiLanguageContext from "./language-context";
import {ICountry} from "../types/country";

export declare type IBreadcrumbType = {url?:string, name:string}
export declare type IRegionType = {id: number, name:string, has_shipment_access:boolean, has_tags_access:boolean, has_merchandising_groups_access:boolean, daily_notification_utc_time: string}
export declare type ILanguageType = {id: number, name:string, short_name: string, code: string, regions: number[]|null}
export declare type IRoleType = {id: number, name: string, right: string[], hierarchy: number[]}
export declare type IRtmType = {label: string, value: string}
export declare type IProgramType = {label: string, value: string}
export declare type IMerchandisingGroupType = {id: number, name: string, region_id: number}
export declare type IScheduledTaskType = {id:number, key: string, created_at:string, updated_at:string}

const defaultBreadcrumbs:IBreadcrumbType[] = [];
const defaultRegions:IRegionType[] = [];
const defaultLanguages:ILanguageType[] = [];
const defaultUser = {id: 0, region_id: 0, firstname: '', lastname: '', role: 4, countries: [] as number[], rights: [] as IRightsEnum[], is_allowed_dashboard: 1};
const defaultCountries:ICountry[] = [];
const defaultRoles:IRoleType[] = [];
const defaultRtms:IRtmType[] = [];
const defaultPrograms:IProgramType[] = [];
const defaultMerchandisingGroups:IMerchandisingGroupType[] = [];
const defaultScheduledTasks:IScheduledTaskType[] = [];
const defaultEnv = {app_name: '', mail: {from_name: ''}};

const NpiInternalContext = React.createContext({
    breadcrumbs: defaultBreadcrumbs,
    user: defaultUser,
    env: defaultEnv,
    regions: defaultRegions,
    languages: defaultLanguages,
    countries: defaultCountries,
    roles: defaultRoles,
    rtms: defaultRtms,
    programs: defaultPrograms,
    merchandisingGroups: defaultMerchandisingGroups,
    scheduledTasks: defaultScheduledTasks,
    loaded: false,
    setBreadcrumbs: (breadcrumbs:IBreadcrumbType[]) => {},
    setUser: (user:IUser) => {},
    setRegions: (regions:IRegionType[]) => {},
    setLanguages: (languages:[]) => {},
    isSuperAdmin: false,
    isWW: false,
    isAllowedRegion: (regionId:number):boolean => false,
    getRoleName: (roleId:number): string|null => null,
    getRegionName: (regionId:number): string|null => null,
    setCountries: (countries:ICountry[]) => {},
    setRoles: (roles:IRoleType[]) => {},
    setScheduledTasks: (scheduledTasks:IScheduledTaskType[]) => {},
});

const NpiInternalContextProvider = ({children}:any) => {
    const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumbType[]>([]);
    const [loaded, setLoaded] = useState(false);
    const [user, setUser] = useState(defaultUser);
    const [env, setEnv] = useState(defaultEnv);
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);
    const [isWW, setIsWW] = useState(false);
    const [regions, setRegions] = useState<IRegionType[]>([]);
    const [languages, setLanguages] = useState<ILanguageType[]>([]);
    const [countries, setCountries] = useState<ICountry[]>([]);
    const [rtms, setRtms] = useState<IRtmType[]>([]);
    const [programs, setPrograms] = useState<IProgramType[]>([]);
    const [merchandisingGroups, setMerchandisingGroups] = useState<IMerchandisingGroupType[]>([]);
    const [roles, setRoles] = useState<IRoleType[]>([]);
    const [scheduledTasks, setScheduledTasks] = useState<IScheduledTaskType[]>([]);

    const {initializeI18n} = useContext(NpiLanguageContext);

    //Returns TRUE if the region can be managed or seen by the user
    const isAllowedRegion = (regionId:number) => {
        if(user.region_id === regionId) return true;
        else if(user.region_id === REGION_WW) return true;
        else return false;
    };

    const getRoleName = (roleId:number) => {
        if( roles[roleId] !== undefined ) return roles[roleId].name;
        return null;
    };
    const getRegionName = (regionId:number) => {
        const r = _.find(regions, {id: regionId});
        return r ? r.name : null;
    };

    useRequest(npiApi.internal.interface, {onSuccess: (data:any) => {
       setEnv(data.env);
       setUser(data.user);
       setRegions(data.regions);
       setLanguages(data.languages);
       setCountries(data.countries);
       setRoles(data.roles);
       setRtms(data.rtms);
       setPrograms(data.programs);
       setMerchandisingGroups(data.merchandisingGroups);
       setLoaded(true);
       setIsSuperAdmin(data.user.role === IUserRoleEnum.SUPER_ADMIN);
       setIsWW(data.user.region_id === REGION_WW);
       setScheduledTasks(data.scheduledTasks);

       //load language system
       initializeI18n(data.languages);
    }});

    return <NpiInternalContext.Provider value={{breadcrumbs, setBreadcrumbs, user, env, regions, languages, countries, rtms, programs, merchandisingGroups, roles, scheduledTasks, setUser, setRegions, setLanguages, isSuperAdmin, isWW, isAllowedRegion, getRoleName, getRegionName, setCountries, setRoles, setScheduledTasks, loaded}}>{children}</NpiInternalContext.Provider>;
};

export {NpiInternalContextProvider, NpiInternalContext};
