import React, {useCallback, useContext, useEffect, useState} from 'react';
import NpiDisplayLayoutContent from "../../components/display/layout-content";
import {NpiInternalContext} from "../../contexts/internal-context";
import NpiPaginatedTable from "../../components/paginated-table";
import npiApi from "../../services/api";
import {ColumnProps} from "antd/es/table";
import {Button, Input, Space, Spin} from "antd";
import {useHistory} from "react-router";
import {LockOutlined} from "@ant-design/icons";
import NpiInputSelectRegion from "../../components/input/select-region";
import {IUser} from "../../types/user";
import _ from "lodash";
import NpiInputSelectRole from "../../components/input/select-role";
import NpiModalUserAddFromLDAP from "./user-add-from-ldap";
import NpiDisplayDate from "../../components/display/date";
import NpiUserEdit from "./user-edit";
import NpiDisplayCountry from "../../components/display/country";
import NpiDisplayListEllipsis from "../../components/display/list-ellipsis";
import useRestrictedToAdmin from "../../hooks/use-restricted-to-admin";
import {useRequest} from "ahooks";
import {useTranslation} from "react-i18next";


const NpiContainerAdminUserList = () => {
    //get context
    useRestrictedToAdmin();
    const history = useHistory();
    const {t} = useTranslation();
    const {setBreadcrumbs, user:loggedUser, loaded, getRegionName, getRoleName} = useContext(NpiInternalContext);

    //Set navigation
    useEffect(() => setBreadcrumbs([
        {name: t('INTERNAL.CLIENT.COMMON.ADMINISTRATION')},
        {url: '/admin/users', name: t('INTERNAL.CLIENT.COMMON.USERS')},
    ]), [setBreadcrumbs, t]);

    const {runAsync: searchUser, loading} = useRequest(npiApi.internal.user.list, {manual: true});

    //filter table
    const [filterRegions, setFilterRegions] = useState<number[]>([]);
    const [filterRoles, setFilterRoles] = useState<number[]>([]);
    const [search, setSearch] = useState<string>('');

    // load Users
    const [users, setUsers] = useState<IUser[]>([]);

    // fetch users (only the ones with role and region allowed for the current logged user to see)
    const userFetcher = useCallback((params: any) => searchUser({search_filters: {region_id: filterRegions, role: filterRoles, search: search}, allowOnly: true, ...params})
            .then((list:any) => list),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterRegions, filterRoles, search]);


    // add new users
    const addUsers = (newUsers:IUser[]) => new Promise((resolve, reject) => {
        setFilterRoles([]);
        setFilterRegions([]);
        setSearch('');
    });

    //logAs
    const onLogAs = (user:IUser) => npiApi.internal.security.logAsUser(user.id)
        .then(() => history.go(0));

    // update user's row
    const updateUser = (updatedUser:IUser) => {
        const found = _.find(users, {id: updatedUser.id});
        if(found) {
            _.extend(found, updatedUser);
            setUsers([...users]);
        }
    };

    // delete user's row
    const deleteUser = (deletedUser:IUser) => {
        setUsers(users.filter((u) => u.id !== deletedUser.id));
    };

    const columns:ColumnProps<IUser>[] = [
        {title: '#', dataIndex: 'id', sorter: true},
        {title: '# '+t('INTERNAL.CLIENT.COMMON.DSID'), dataIndex: 'dsid', sorter: true},
        {title: t('INTERNAL.CLIENT.COMMON.FIRSTNAME'), dataIndex: 'firstname', sorter: true},
        {title: t('INTERNAL.CLIENT.COMMON.LASTNAME'), dataIndex: 'lastname', sorter: true},
        {title: t('INTERNAL.CLIENT.COMMON.EMAIL'), dataIndex: 'email', sorter: true},
        {title: t('INTERNAL.CLIENT.COMMON.ROLE'), dataIndex: 'role', sorter: true, render: (role) => getRoleName(role) || role},
        {title: t('INTERNAL.CLIENT.COMMON.REGION'), dataIndex: 'region_id', sorter: true, render: (regionId:number) => getRegionName(regionId) || regionId},
        {
            title: t('INTERNAL.CLIENT.COMMON.COUNTRIES'),
            render: (v: IUser) => <NpiDisplayListEllipsis>{v.countries.map(c => <NpiDisplayCountry key={c} id={c} short style={{marginLeft: 5}}/>)}</NpiDisplayListEllipsis>
        },
        {title: t('INTERNAL.CLIENT.COMMON.CREATED_AT'), dataIndex: 'created_at', width: 150, sorter: true, render: (date) => <NpiDisplayDate value={date}/>},
        {
            // actions per user (logAs or Edit)
            render: (user:IUser) => {
                return (user.id === loggedUser.id) ? '' : <Space>

                    {/* log as user */}
                    <Button title={t('INTERNAL.CLIENT.COMMON.LOG_AS')} onClick={() => onLogAs(user)} icon={<LockOutlined/>} style={{background: "#FAAD14", borderColor: "#FAAD14", color: "#fff"}}/>

                    {/*edit user*/}
                    <NpiUserEdit user={user} onUpdate={updateUser} onDelete={deleteUser}/>
                </Space>;
            },
            align: 'right',
        },
    ];

    if( !loaded ){
        return <Spin/>
    }

    return <NpiDisplayLayoutContent>

        <NpiPaginatedTable
            dataSource={users}
            onChange={(d:any) => setUsers(d)}
            fetcher={userFetcher}
            columns={columns}
            pagination={{hideOnSinglePage: true, pageSize: 50}}
            onRow={((user:IUser) => (user.id === loggedUser.id) ? {style: {backgroundColor: '#F2F4F5'}} : {})}
            loading={loading}

            // filters
            title={() => <div style={{marginBottom: '30px'}}>
                <Space style={{float: "right"}}>
                    <Input onChange={(e) => setSearch(e.target.value)} value={search} placeholder={t('INTERNAL.CLIENT.COMMON.SEARCH')} allowClear/>
                    <NpiInputSelectRole onChange={setFilterRoles} restrict={true} value={filterRoles} mode={'multiple'} placeholder={t('INTERNAL.CLIENT.COMMON.FILTER_BY_ROLES')}/>
                    <NpiInputSelectRegion onChange={setFilterRegions} restrict={true} value={filterRegions} mode={'multiple'} placeholder={t('INTERNAL.CLIENT.COMMON.FILTER_BY_REGIONS')}/>
                    <NpiModalUserAddFromLDAP onChange={addUsers}/>
                </Space>
            </div>}
        />

    </NpiDisplayLayoutContent>
};

export default NpiContainerAdminUserList;