import React, {Key, useCallback, useContext, useEffect, useMemo, useState} from "react";
import npiApi from "../../services/api";
import {Tag, TreeSelect} from "antd";
import {useControllableValue, useRequest} from "ahooks";
import {ITag} from "../../types/tag";
import styled from "styled-components";
import {TreeSelectProps} from "antd/lib/tree-select";
import { CustomTagProps } from 'rc-select/lib/BaseSelect';
import _ from "lodash";
import {NpiInternalContext} from "../../contexts/internal-context";
import {useTranslation} from "react-i18next";

//Mimic the default Select tag
const StyledTag = styled(Tag)`
    background-color: rgb(245, 245, 245);
    border: 1px solid rgb(240, 240, 240);
    font-size: 14px;
    padding-inline-end: 4px;
    padding-inline-start: 8px;
    
    .ant-btn-icon-only{
        height: 20px;
    }
`;

export interface SelectTags extends TreeSelectProps<any> {
    regionId?: number
    waves?: number[]
    npis?: number[]
    onlyValues?:boolean
}

/**
 * Remote search and select multiple Tags values
 * can be filtered by wave ids or region id
 */
const NpiInputSelectTagsAndValues = ({regionId, waves, onlyValues=false, npis, ...props}:SelectTags) => {
    const {style, dropdownMatchSelectWidth, disabled, allowClear} = props;
    const {t} = useTranslation();

    const {user} = useContext(NpiInternalContext);
    const [values, onChange] = useControllableValue(props, {
        defaultValue: [],
    });
    const [treeData, setTreeData] = useState<any[]>([]);
    const [loadedKeys, setLoadedKeys] = useState<Key[]>([]);
    const [expandedKeys, setExpandedKeys] = useState<Key[]>([]);

    const {runAsync: fetchValues, loading: loadingValues} = useRequest(npiApi.internal.tag.values, {manual: true});
    const {data: tags, runAsync: fetchTags, loading} = useRequest<ITag[],any>(npiApi.internal.tag.list, { manual: true, onSuccess: (data: ITag[]) => {
        // fix already selected tags not expending after reload (e.g. select a tag and values and apply filter, on reload we can't load/display this tag's values again)
        setLoadedKeys([]);
        setExpandedKeys([]);

        setTreeData(data.map( v => ({
            id: v.id,
            pId: 0,
            value: v.id,
            title: v.name,
            selectable: !onlyValues,
        })) as []);
    }});

    //Load leaves from a parent
    const onLoadData = useCallback(({id}:any) => {
        const params = {tagId: id};
        return fetchValues(params).then((data:any) => {
            const leaves = data.map((v:any) => ({
                id: JSON.stringify([id, v.value]),
                pId: id,
                value: JSON.stringify([id, v.value]),
                title: v.label,
                isLeaf: true,
            }));
            setTreeData([...treeData, ...leaves]);
        })
    }, [fetchValues, setTreeData, treeData]);

    //Render our tags
    const tagRender = useCallback((props: CustomTagProps) => {
        const { label, value: valueAsJSON, closable, onClose} = props;
        const valueParsed = JSON.parse(valueAsJSON);

        //Overwriting the onClose with prevent default because it is causing issues
        const localOnClose = (event:any) => {
            event.preventDefault();
            event.stopPropagation();
            onClose();
        };

        //Use label, or find the parent name to append if we have a child
        let string = label;
        if(_.isArray(valueParsed)){
            string = <span>{_.find(treeData, {id: valueParsed[0]})?.title} <b>=</b> {valueParsed[1]}</span>;
        }
        return <StyledTag closable={closable} onClose={localOnClose}>{string}</StyledTag>
    }, [treeData]);

    //Refresh tree when region or wave changes
    useEffect(() => {

        if( waves !== undefined ) {
            // if we pass a specific wave, we only dispay tags for this wave
            fetchTags({waves, npis}).then(null)
        } else if( regionId !== undefined ) {
            // otherwise, we display tags for a region
            fetchTags({regionId, npis}).then(null)
        } else if(user) {
            // otherwise, we display tags for user's region
            fetchTags({regionId: user.region_id, npis}).then(null)
        }
    }, [regionId, waves, fetchTags, user, npis]);

    const config = useMemo(() => ({
        value: values,
        loading: loading || loadingValues,
        loadData: onLoadData,
        onChange,
        treeData,
        tagRender,

        // fix selected tags not expending again after submit
        treeLoadedKeys: loadedKeys,
        onTreeLoad: setLoadedKeys,
        treeExpandedKeys: expandedKeys,
        onTreeExpand: setExpandedKeys,

        placeholder: props.placeholder ?? t("INTERNAL.CLIENT.TAGS.SELECT_ONE_OR_MORE_TAGS"),
        disabled: disabled || !tags?.length,
        allowClear: allowClear,
        multiple: true,
        treeDataSimpleMode: true,
        showSearch: false,
        dropdownMatchSelectWidth: dropdownMatchSelectWidth,
        style: style,
    }), [values, treeData, tagRender, onChange, onLoadData, loadingValues, loading, tags, style, dropdownMatchSelectWidth, disabled, allowClear, loadedKeys, expandedKeys, props, t]);

    return <TreeSelect {...props} {...config}/>
};

export default NpiInputSelectTagsAndValues;