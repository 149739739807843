import React, {Dispatch, Key, useCallback, useContext, useMemo, useState} from 'react';
import {
    Badge,
    Button,
    Checkbox,
    Form,
    Input,
    Modal,
    notification,
    Select,
    Space, Switch,
    Table,
    Tooltip
} from "antd";
import {IWave} from "../../types/wave";
import {observer} from "mobx-react";
import styled from "styled-components";
import {useForm} from "antd/lib/form/Form";
import NpiInputSelectCountry from "../input/select-country";
import {NpiInternalContext} from "../../contexts/internal-context";
import {useControllableValue, useRequest} from "ahooks";
import {CheckCircleFilled, DownloadOutlined, LinkOutlined, SearchOutlined, WarningFilled} from "@ant-design/icons/lib";
import CheckboxBlankCircle from '@2fd/ant-design-icons/lib/CheckboxBlankCircle';
import npiApi, {npiApiDownloadFileCallback} from "../../services/api";
import NpiDisplayCountry from "../display/country";
import _ from "lodash";
import NpiDisplayNumber from "../display/number";
import colors from "../../services/colors";
import NpiInputSelectNumericalTags from "../input/select-numerical-tags";
import NpiInputSelectProgram from "../input/select-program";
import NpiDisplayProgram from "../display/program";
import moment from "moment";
import NpiDisplayPosTags from "../display/pos-tags";
import NpiInputSelectTagsAndValues from "../input/select-tags-and-values";
import NpiPosStatusFilterFormItem from "../input/select-status";
import IContact from "../../types/contact";
import {Trans, useTranslation} from "react-i18next";
import {nowFormat} from "../../helpers/date";
import {REGION_WW} from "../../helpers/constants";

const StyledForm = styled(Form)`
    .ant-form-item{
        float: left;
        width: 33.33%;
        padding: 10px;
        margin: 0;
    }
`;

const StyledSummaryCell = styled(Table.Summary.Cell)`
    font-weight: bold;
    border-bottom: 0;
`;

const StyledFooterModal = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .footer-infos {
    text-align: left;
    font-size: 0.8rem;
    font-style: italic;
    & > div {
      display: flex;
      justify-content: space-between;
      & > span {
        margin-left: 15px;
      }
    }
  }
`;

interface INpiWaveTabPosSearchModal {
    wave: IWave
    open: boolean
    onCancel: Dispatch<boolean>
    onChange: Dispatch<void>
}

//Sorting function
const sorter = (field:string, a:any, b:any) => a[field].localeCompare ? a[field].localeCompare(b[field]) : a[field]<b[field] ? -1:1;

const NpiWaveTabPosSearchModal = observer(({wave, onChange, ...props}:INpiWaveTabPosSearchModal) => {
    const {rtms, programs, scheduledTasks} = useContext(NpiInternalContext);
    const [visible, setVisible] = useControllableValue(props, {
        valuePropName: 'open',
        trigger: 'onCancel',
    });
    const {t} = useTranslation();
    const {runAsync: search, loading: loadingSearch, data: result, mutate: setResult} = useRequest<any,any>(npiApi.internal.wave.searchPos, {manual: true});
    const {runAsync: modify, loading: loadingModify} = useRequest(npiApi.internal.wave.modifyPos, {manual: true});
    const {runAsync: overlapDetails, loading: loadingOverlapDetails} = useRequest(npiApi.internal.wave.overlapDetails, {manual: true});
    const {runAsync: downloadTemplate, loading: loadingDownload} = useRequest(npiApi.internal.file.exportPosTags, {manual: true});
    const [modalTagVisible, setModalTagVisible] = useState(false);
    const [isDirty, setIsDirty] = useState(false);
    const [filters] = useForm();
    const [selectedSfoIds, setSelectedSfoIds] = useState<Key[]>([]);
    const posWithRequiredTags = useMemo(() => _.filter(result?.data, (v:any) => v.has_required_tags === 0 && selectedSfoIds.indexOf(v.sfo_unique_id)>=0), [result, selectedSfoIds]);

    const displayDateUpdate = (withPrograms: boolean) => {
        if( scheduledTasks ) {
            const key = withPrograms ? 'pos_with_programs' : 'pos_without_programs';
            const found = _.find(scheduledTasks, {key: key}) || null;
            if( found ) {
                return <>{moment(found.updated_at || found.created_at).locale('en').fromNow()}</>
            }
        }
        return <></>;
    };

    //Options of filters
    const options = useMemo(() => ({
        status: [
            {value: "Pre-Closing", label: t('INTERNAL.CLIENT.POS.STATUS.PRE_CLOSING'), },
            {value: "Pre-Opening", label: t('INTERNAL.CLIENT.POS.STATUS.PRE_OPENING'), },
            {value: "Open", label: t('INTERNAL.CLIENT.POS.STATUS.OPEN'), },
            {value: "Cancelled", label: t('INTERNAL.CLIENT.POS.STATUS.CANCELLED'), },
            {value: "Closed",label: t('INTERNAL.CLIENT.POS.STATUS.CLOSED'),},
        ],
        rtms,
        programs,
        branded: [
            {value: 1, label: t('INTERNAL.CLIENT.COMMON.BRANDED')},
            {value: 0, label: t('INTERNAL.CLIENT.COMMON.NON_BRANDED')},
        ]
    }), [rtms, programs, t]);

    //When selecting one row, manage behavior ourselves so as not to impact selection made on other pages
    const onSelectOneRow = (record:any, selected:any) => {
        if (selected) {
            setSelectedSfoIds((keys:any) => [...keys, record.sfo_unique_id]);
        } else {
            setSelectedSfoIds((keys:any) => {
                const index = keys.indexOf(record.sfo_unique_id);
                return [...keys.slice(0, index), ...keys.slice(index + 1)];
            });
        }
    };

    //Launch search
    const onSearch = useCallback(() => {
        search({wave_id: wave.id, ...filters.getFieldsValue()}).then(() => {
            setIsDirty(false);
            setSelectedSfoIds([]);
        });
    }, [wave, filters, search]);

    //Download template of wrongly tagged POS
    const onDownloadTemplate = (posIds:number[]) => {
        downloadTemplate({filters: {pos_id: [posIds]}})
            .then(npiApiDownloadFileCallback(`template-pos-tags-${nowFormat()}.xlsx`));
    };

    //Add POS to the wave, but check overlapping before
    const onAddPos = () => {
        const nbOverlap = _.filter(result.data, (v:any) => v.nb_overlap > 0 && selectedSfoIds.indexOf(v.sfo_unique_id)>=0).length;
        if(posWithRequiredTags.length){
            setModalTagVisible(true);
        }
        else if(nbOverlap>0){
            //Warn about overlap, if any
            Modal.confirm({
                title: t('INTERNAL.CLIENT.POS.OVERLAPPING_POS'),
                width: 600,
                content: <span>
                            <Trans
                                i18nKey="INTERNAL.CLIENT.POS.OVERLAPPING_POS_WARNING"
                                t={t}
                                values={{ count: nbOverlap}}
                                components={{ bold: <strong /> }}
                            />
                            <br/><br/>
                            <Trans
                                i18nKey="INTERNAL.CLIENT.POS.MIGHT_CAUSE_STATISTICAL_BIAS"
                                t={t}
                                components={{ bold: <strong /> }}
                            />
                            <br/><br/>
                            {t('INTERNAL.CLIENT.POS.SURE_WANT_PROCEED')}
                        </span>,
                okText: t('INTERNAL.CLIENT.COMMON.YES'),
                cancelText: t('INTERNAL.CLIENT.COMMON.NO'),
                onOk: performAddPos,
            })
        }
        else{
            //Add directly if there are no issues
            performAddPos();
        }
    };

    //Marks the selected rows as included (or not). Is used after adding or removing POS
    const markSelectedRowsAsIncludedOrNot = (isIncluded:boolean) => {
        const inWave = isIncluded ? 1:0;
        result.data.forEach((r:any) => {
            if(selectedSfoIds.indexOf(r.sfo_unique_id) >= 0){
                r.in_wave = inWave;
            }
        });
        setResult({...result});
    };

    //Add POS to the wave
    const performAddPos = () => modify({wave_id: wave.id, addedSfoIds: selectedSfoIds, deletedAppleIds:[]}).then((data:any) => {
        notification.info({message: <span>{data.added} POS added.</span>});
        markSelectedRowsAsIncludedOrNot(true);
        wave.tags = data.tags; //the tags have changed, we need to update the list
        onChange();
    });

    //Remove POS from the wave
    const onRemovePos = () => {
        //Retrieve Apple IDs from the selection
        const deletedAppleIds = _.chain(result.data).filter(v => selectedSfoIds.indexOf(v.sfo_unique_id)>=0).map('f_pos_apple_id').value();
        modify({wave_id: wave.id, addedSfoIds:[], deletedAppleIds}).then((data:any) => {
            notification.info({message: <span>{data.deleted} POS removed.</span>});
            markSelectedRowsAsIncludedOrNot(false);
            wave.tags = data.tags; //the tags have changed, we need to update the list
            onChange();
        });
    };

    //Fetches the overlap detail of one POS and displays it in a Modal (waves this POS is in for the current NPI)
    const openModalOverlapDetails = useCallback((row:any) => {
        overlapDetails({wave_id: wave.id, pos_id: row.f_pos_apple_id}).then((data:any) => {
            Modal.info({
                title: <span>
                            <Trans
                                i18nKey="INTERNAL.CLIENT.POS.OVERLAPPING_POS_WAVES"
                                t={t}
                                values={{posName: row.name, count: data.length}}
                                components={{ bold: <strong /> }}
                            />
                        </span>,
                content: <ul>
                    {data.map((w:any) => <li key={w.id}>{w.name}</li>)}
                </ul>,
                width: 600,
            });
        })
    }, [overlapDetails, wave.id, t]);

    //Columns
    const columns = useMemo(() => [
        {title: t('INTERNAL.CLIENT.COMMON.APPLE_ID'), dataIndex: 'f_pos_apple_id', sorter: sorter.bind(this,'f_pos_apple_id'),},
        {title: t('INTERNAL.CLIENT.COMMON.HQ_ID'), dataIndex: 'f_hq_apple_id', sorter: sorter.bind(this,'f_pos_apple_id'),},
        {title: t('INTERNAL.CLIENT.COMMON.STORE_NAME'), dataIndex: 'name', sorter: sorter.bind(this,'name')},
        {title: t('INTERNAL.CLIENT.COMMON.CITY'), dataIndex: 'f_city', sorter: sorter.bind(this,'f_city')},
        {title: t('INTERNAL.CLIENT.COMMON.COUNTRY'), dataIndex: 'country_id', render: (v:any) => <NpiDisplayCountry id={v}/>, sorter: sorter.bind(this,'country_id')},
        // {title: 'RTM', dataIndex: 'f_rtm', sorter: sorter.bind(this,'f_rtm')},
        {title: t('INTERNAL.CLIENT.COMMON.TAGS'), dataIndex: 'tags', render: (value:any) => <NpiDisplayPosTags value={value} tags={result.tags} max={0}/>},
        {title: t('INTERNAL.CLIENT.COMMON.PROGRAM'), dataIndex: 'f_program', sorter: sorter.bind(this,'f_program'), render: (v:string) => <NpiDisplayProgram value={v}/>},
        {title: t('INTERNAL.CLIENT.COMMON.INCLUDED'), dataIndex: 'in_wave', sorter: sorter.bind(this,'in_wave'), align: 'center' as 'center',
            render: (v:number) => !!v
                ? <Tooltip title={t('INTERNAL.CLIENT.POS.POS_INCLUDED_IN_WAVE')}><CheckCircleFilled style={{color: colors.green}}/></Tooltip>
                : <Tooltip title={t('INTERNAL.CLIENT.POS.POS_NOT_IN_WAVE')}><CheckboxBlankCircle style={{color: colors.greyDisabledText, fontSize: 16}}/></Tooltip>
        },
        {title: t('INTERNAL.CLIENT.COMMON.OVERLAP'), dataIndex: 'nb_overlap', sorter: sorter.bind(this,'nb_overlap'), align: 'center' as 'center',
            render: (v:number, row:any) => !!v
                ? <Button type="link" onClick={() => openModalOverlapDetails(row)}><Tooltip title={t('INTERNAL.CLIENT.POS.POS_PRESENT_IN_OTHER_WAVES', {nbWaves: v})}><Badge count={v}/></Tooltip></Button>
                : <Tooltip title={t('INTERNAL.CLIENT.POS.POS_NOT_IN_OTHER_WAVES')}><CheckCircleFilled style={{color: colors.green}}/></Tooltip>
        },
        // {title: <Button type="primary" icon={<SearchOutlined/>} onClick={onSearch} loading={loadingSearch} disabled={!isDirty}>Search</Button>, align: 'right' as 'right'}
    ], [openModalOverlapDetails, result, t]);

    //Configuration of the table
    const tableConfig = {
        columns,
        loading: loadingSearch || loadingModify || loadingOverlapDetails,
        dataSource: result?.data,
        style: {marginTop: 20},
        rowKey: "sfo_unique_id",
        size: 'small' as 'small',
        rowSelection: {
            preserveSelectedRowKeys: true,
            selectedRowKeys: selectedSfoIds,
            //We need to manage when selecting one box correctly so that it does not change other pages
            onSelect: onSelectOneRow,
            //We need to override the check all/none box so that it is able to select all pages at once
            columnTitle: <CheckAllNone allKeys={_.map(result?.data, 'sfo_unique_id')} selectedRowKeys={selectedSfoIds} onChange={setSelectedSfoIds}/>
        },
        summary: (pageData:any) => {
            return <Table.Summary.Row>
                <StyledSummaryCell index={0} colSpan={columns.length+1}>
                    <Space style={{marginTop: 20, float: 'right'}}>
                        Selected POS <NpiDisplayNumber value={selectedSfoIds.length??0}/>/<NpiDisplayNumber value={result?.data.length??0}/>
                        <Button type="primary" onClick={onRemovePos} danger disabled={!selectedSfoIds.length} loading={loadingModify}>{t('INTERNAL.CLIENT.POS.REMOVE_SELECTED_POS')}</Button>
                        <Button type="primary" onClick={onAddPos} disabled={!selectedSfoIds.length} loading={loadingModify}>{t('INTERNAL.CLIENT.POS.ADD_SELECTED_POS')}</Button>
                    </Space>
                </StyledSummaryCell>
            </Table.Summary.Row>
        },
    };

    //Configure modal for tags template download
    const modalTagsConfig = {
        title: <span><WarningFilled style={{color: colors.yellow, fontSize: 24, position: 'relative', top: 2, marginRight: 10}}/> {t('INTERNAL.CLIENT.POS.SOME_POS_MISSING_REQUIRED_TAGS')}</span>,
        width: 600,
        open: modalTagVisible,
        footer: null,
        onCancel: () => setModalTagVisible(false),
        maskClosable: false,
    };

    return <Modal title={t('INTERNAL.CLIENT.POS.ADD_REMOVE_POS')} open={visible} onCancel={() => setVisible(false)} width="calc(100vw - 160px)" okButtonProps={{style: {display: 'none'}}} cancelText={t('INTERNAL.CLIENT.COMMON.CLOSE')}
        footer={<StyledFooterModal>
            <div className={"footer-infos"}>
                <div>{t('INTERNAL.CLIENT.POS.LAST_REFRESH_WITH_PROGRAMS')} : {displayDateUpdate(true)}</div>
                <div>{t('INTERNAL.CLIENT.POS.LAST_REFRESH_WITHOUT_PROGRAM')}  : {displayDateUpdate(false)}</div>
            </div>
            <Button key="back" onClick={() => setVisible(false)}>{t('INTERNAL.CLIENT.COMMON.CLOSE')}</Button>
        </StyledFooterModal>}
    >
        <StyledForm form={filters} layout="vertical" onValuesChange={() => setIsDirty(true)}>
            <Form.Item label={t('INTERNAL.CLIENT.COMMON.APPLE_ID')} name="f_pos_apple_id">
                <NpiInputSelectNumericalTags mode="tags" placeholder={`${t('INTERNAL.CLIENT.COMMON.SEARCH_BY')} ${t('INTERNAL.CLIENT.COMMON.APPLE_ID')}`} maxTagCount={5}/>
            </Form.Item>
            <Form.Item label={t('INTERNAL.CLIENT.COMMON.HQ_ID')} name="f_hq_apple_id">
                <NpiInputSelectNumericalTags placeholder={`${t('INTERNAL.CLIENT.COMMON.SEARCH_BY')} ${t('INTERNAL.CLIENT.COMMON.HQ_ID')}`}/>
            </Form.Item>
            <Form.Item label={t('INTERNAL.CLIENT.COMMON.NAME')} name="name">
                <Input placeholder={`${t('INTERNAL.CLIENT.COMMON.SEARCH_BY')} ${t('INTERNAL.CLIENT.COMMON.NAME')}`} />
            </Form.Item>
            <Form.Item label={t('INTERNAL.CLIENT.COMMON.COUNTRY')} name="country_id">
                <NpiInputSelectCountry mode="multiple" placeholder={t('INTERNAL.CLIENT.COUNTRIES.SELECT_COUNTRIES')} inRegions={wave.region_id === REGION_WW ? undefined : [wave.region_id]}/>
            </Form.Item>
            <Form.Item label={t('INTERNAL.CLIENT.COMMON.STATUS')} name="f_sfo_status_group" initialValue={["Open", "Pre-Closing"]}>
                <NpiPosStatusFilterFormItem />
            </Form.Item>
            <Form.Item label={t('INTERNAL.CLIENT.COMMON.TAGS')} name="tags">
                <NpiInputSelectTagsAndValues regionId={wave.region_id} npis={[wave.npi_id]}/>
            </Form.Item>
            <Form.Item label={t('INTERNAL.CLIENT.COMMON.PROGRAMS')} name="f_program">
                <NpiInputSelectProgram withNoProgram mode="multiple" placeholder={`${t('INTERNAL.CLIENT.COMMON.SELECT_ONE_OR_MORE')} ${t('INTERNAL.CLIENT.COMMON.PROGRAMS')}`} />
            </Form.Item>
            <Form.Item label={t('INTERNAL.CLIENT.COMMON.BRANDED_STATUS')} name="branded" style={{width: "16.66%"}}>
                <Select allowClear options={options.branded} placeholder={`${t('INTERNAL.CLIENT.COMMON.SELECT_ONE_OR_MORE')} ${t('INTERNAL.CLIENT.COMMON.BRANDED_STATUS')}`}/>
            </Form.Item>
            <Form.Item label={t('INTERNAL.CLIENT.POS.EXCLUDE_OVERLAPPING_POS')} name="exclude_overlap" valuePropName="checked" tooltip={t('INTERNAL.CLIENT.POS.OVERLAPPING_POS_TOOLTIP')} style={{width: "16.66%"}}><Switch/></Form.Item>
            <Form.Item label={t('INTERNAL.CLIENT.POS.IN_WAVE')} name="already_in_wave" valuePropName="checked" tooltip={t('INTERNAL.CLIENT.POS.IN_WAVE_TOOLTIP')} style={{width: "16.66%"}}><Switch/></Form.Item>

            {/* Peek contact list from region_id of wave_id */}
            {
                !!wave.contacts && !!wave.contacts.length &&
                <Form.Item label={t('INTERNAL.CLIENT.COMMON.CONTACTS')} name={"contact_id"}>
                    <Select
                        allowClear
                        placeholder={t('INTERNAL.CLIENT.COMMON.CHOOSE_CONTACT')}
                        options={wave.contacts.map((contact: IContact) => ({label: contact.name, value: contact.id}))}
                    />
                </Form.Item>
            }


            <div className="ant-row ant-form-item" style={{paddingTop: 40}}>
                <Button type="primary" icon={<SearchOutlined/>} onClick={onSearch} loading={loadingSearch} disabled={!isDirty}>{t('INTERNAL.CLIENT.COMMON.SEARCH')}</Button>
            </div>
        </StyledForm>
        <div className="clearfix"/>
        <Table {...tableConfig}/>
        <div className="clearfix"/>

        {/*Modal to warn about missing tags*/}
        <Modal {...modalTagsConfig}>
            <Trans
                i18nKey="INTERNAL.CLIENT.POS.TRYING_TO_ADD_NB_POS_MISSING_REQUIRED_TAGS"
                t={t}
                values={{nbPosWithRequiredTags: posWithRequiredTags.length, count: posWithRequiredTags.length}}
                components={{ bold: <strong /> }}
            />
            <ol style={{marginTop: 14}}>
                <li>
                    <Button icon={<DownloadOutlined/>} type="primary" size="small" onClick={() => onDownloadTemplate(_.map(posWithRequiredTags, 'f_pos_apple_id'))} loading={loadingDownload}>{t('INTERNAL.CLIENT.WAVE.TAB_POS.DOWNLOAD_TEMPLATE')}</Button>
                    &nbsp;{t('INTERNAL.CLIENT.POS.CONTAINING_THE_POS_WITH_MISSING_TAGS')}
                </li>
                <li>{t('INTERNAL.CLIENT.POS.FIX_REQUIRED_TAGS_IN_FILE')}</li>
                <li>
                    <Trans
                        i18nKey="INTERNAL.CLIENT.POS.GO_TO_TAGS_ADMINISTRATION_PAGE"
                        t={t}
                        // eslint-disable-next-line
                        components={{ a: <a href="/admin/tags" target="_blank"></a>, LinkOutlined: <LinkOutlined/>}}
                    />
                </li>
                <li>{t('INTERNAL.CLIENT.POS.CLOSE_MESSAGE_AND_REFRESH_SEARCH')}</li>
            </ol>
            <b>{t('INTERNAL.CLIENT.POS.OPERATION_CANNOT_PROCEED')}</b>
        </Modal>
    </Modal>
});

/**
 * We need to override the check all/none box so that it is able to select all pages at once
 * @param allKeys
 * @param props
 * @constructor
 */
const CheckAllNone = ({allKeys, ...props}:{allKeys:Key[], selectedRowKeys:Key[], onChange:Dispatch<Key[]>}) => {
    const [selectedRowKeys, setSelectedRowKeys] = useControllableValue(props, {
       valuePropName: 'selectedRowKeys',
    });

    const toggleSelectAll = () => {
        setSelectedRowKeys((keys:any) =>
            keys.length === allKeys.length ? [] : allKeys
        );
    };

    return <Checkbox
        checked={selectedRowKeys.length}
        indeterminate={
            selectedRowKeys.length > 0 && selectedRowKeys.length < allKeys.length
        }
        onChange={toggleSelectAll}
    />
};

export default NpiWaveTabPosSearchModal;