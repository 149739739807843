import React, {Dispatch, useMemo, useState} from "react";
import {IDashboardBlockConfiguration} from "../../../types/dashboard";
import {Form, Input, Space, Switch, Table} from "antd";
import {useControllableValue} from "ahooks";
import {IKpi} from "../../../types/kpi";
import _ from "lodash";
import NpiDisplayKpiName from "../../display/kpi-name";
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import {EyeInvisibleOutlined, MenuOutlined} from "@ant-design/icons/lib";
import { arrayMoveImmutable } from 'array-move';
import {useTranslation} from "react-i18next";

interface INpiInputKpiDashboardBlockConfigPieChart {
    block:IDashboardBlockConfiguration
    kpis:IKpi[]
    onChange: Dispatch<IDashboardBlockConfiguration>
}

//Required sortable elements
const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);
const SortableItem = SortableElement(({style, ...props}:any) => <tr {...props} style={{...style, zIndex: 5000}}/>);
const SortableBody = SortableContainer((props:any) => <tbody {...props} />);

const NpiInputKpiDashboardBlockConfigPieChart = ({kpis, ...props}:INpiInputKpiDashboardBlockConfigPieChart) => {
    const {t} = useTranslation();
    const [block, setBlock] = useControllableValue(props, {valuePropName: "block"});
    const [remainingTitle, setRemainingTitle] = useState<string>(block.config.remainingTitle);

    // merged chart kpis with full list of kpis
    const map = new Map();
    (block.config.chartKpis || []).forEach((kpi:any) => map.set(kpi.id, kpi)); // first to keep chart config's order
    kpis.forEach(kpi => map.set(kpi.id, {id: kpi.id, hidden: false, ...map.get(kpi.id)})); // new kpis are displayed by default

    const [chartKpis, setChartKpis] = useState(Array.from(map.values()));
    const [displayProps, setDisplayProps] = useState<any>({
        displayUnits: block.config.displayUnits ?? false,
        displayLabel: block.config.displayLabel ?? false,
        alignLabel: block.config.alignLabel ?? false,
        bottomLegend: block.config.bottomLegend ?? true,
        innerRadius: block.config.innerRadius ?? 30,
        outerRadius: block.config.outerRadius ?? 80,
        startAngle: block.config.startAngle ?? 90,
    });
    const [showHidden, setShowHidden] = useState(false);

    //Retrieve the list of all other KPIs
    const kpisById = useMemo(() => _.keyBy(kpis, 'id'), [kpis]);

    const configurableKpis = useMemo(() => {
        let result = showHidden ? chartKpis : chartKpis.filter((v:any)=>!v.hidden); //remove hidden
        return result.filter((kpi:any) => !!_.find(kpis, {id: kpi.id})); //clear KPIs which no longer exist
    }, [showHidden, kpis, chartKpis]);

    //Apply changes
    const applyChanges = (chartKpis:any) => {
        setChartKpis([...chartKpis]);
        block.config.chartKpis = chartKpis;
        setBlock(_.cloneDeep(block));
    };

    //Apply changes
    const onChangeRemainingTitle = (event:any) => {
        const remainingTitle = event.target.value;
        setRemainingTitle(remainingTitle);
        block.config.remainingTitle = remainingTitle;
        setBlock(_.cloneDeep(block));
    };

    const onChangeDisplayProps = (key:string, value:any) => {
        displayProps[key] = value;
        block.config[key] = value;
        setDisplayProps({...displayProps});
        setBlock(_.cloneDeep(block));
    }

    //Track changes to sorting
    const onSortEnd = ({ oldIndex, newIndex }:any) => {
        if (oldIndex !== newIndex) {
            const newData = arrayMoveImmutable(chartKpis, oldIndex, newIndex).filter(
                (el:any) => !!el,
            );
            applyChanges(newData);
        }
    };

    //Change visibility of a KPI
    const onChangeHidden = (row:any, value:boolean) => {
        const found = _.find(chartKpis, {id: row.id});
        if(found){
            found.hidden = value;
            applyChanges(chartKpis);
        }
    };

    const config = {
        columns: [
            {title: t('INTERNAL.CLIENT.COMMON.SORT'), width: 30, className: 'drag-visible', render: () => <DragHandle/>,},
            {title: t('INTERNAL.CLIENT.NPI.KPI'), render: (row:any)=> <NpiDisplayKpiName kpi={kpisById[row.id]}/>},
            {title: <><EyeInvisibleOutlined/> {t('INTERNAL.CLIENT.COMMON.HIDE')}</>, render: (row:any) => <Switch checked={!!row.hidden} onChange={v => onChangeHidden(row, v)}/>, align: 'center' as 'center'}
        ],
        dataSource: configurableKpis,
        pagination: false as false,
        rowKey: 'id',
        components: {
            body:{
                wrapper: (props:any) => <SortableBody
                    useDragHandle
                    disableAutoscroll
                    helperClass="row-dragging"
                    onSortEnd={onSortEnd}
                    {...props}
                />,
                row: ({ className, style, ...restProps }:any) => {
                    // function findIndex base on Table rowKey props and should always be a right array index
                    const index = chartKpis.findIndex((x:any) => x.id === restProps['data-row-key']);
                    return <SortableItem index={index} {...restProps} />;
                },
            },
        },
    };

    return <div>
        <Form.Item style={{marginBottom: 8}} label={t('INTERNAL.CLIENT.KPI.DASHBOARD.DISPLAY_UNIT_INSTEAD_OF_PERCENTAGE')}>
            <Switch checked={displayProps.displayUnits} onChange={(v) => onChangeDisplayProps('displayUnits', v)}/>
        </Form.Item>
        <Form.Item style={{marginBottom: 8}} label={t('INTERNAL.CLIENT.KPI.DASHBOARD.DISPLAY_INLINE_LABEL')}>
            <Switch checked={displayProps.displayLabel} onChange={(v) => onChangeDisplayProps('displayLabel', v)}/>
        </Form.Item>
        <Form.Item style={{marginBottom: 8}} label={t('INTERNAL.CLIENT.KPI.DASHBOARD.DISPLAY_ALIGN_LABEL')}>
            <Switch checked={displayProps.alignLabel} onChange={(v) => onChangeDisplayProps('alignLabel', v)}/>
        </Form.Item>
        <Form.Item style={{marginBottom: 8}} label={t('INTERNAL.CLIENT.KPI.DASHBOARD.DISPLAY_BOTTOM_LEGEND')}>
            <Switch checked={displayProps.bottomLegend} onChange={(v) => onChangeDisplayProps('bottomLegend', v)}/>
        </Form.Item>
        <Space size={"large"}>
            <Form.Item style={{marginBottom: 8}} label={t('INTERNAL.CLIENT.KPI.DASHBOARD.DISPLAY_INNER_RADIUS')}>
                <Input type={"number"} min={5} max={100} addonAfter="%" value={displayProps.innerRadius} onChange={e => onChangeDisplayProps('innerRadius', Number(e.target.value))} style={{width: 120}}/>
            </Form.Item>
            <Form.Item style={{marginBottom: 8}} label={t('INTERNAL.CLIENT.KPI.DASHBOARD.DISPLAY_OUTER_RADIUS')}>
                <Input type={"number"} min={5} max={100} addonAfter="%" value={displayProps.outerRadius} onChange={e => onChangeDisplayProps('outerRadius', Number(e.target.value))} style={{width: 120}}/>
            </Form.Item>
            <Form.Item style={{marginBottom: 8}} label={t('INTERNAL.CLIENT.KPI.DASHBOARD.DISPLAY_START_ANGLE')}>
                <Input type={"number"} min={0} max={360} addonAfter="°" value={displayProps.startAngle} onChange={e => onChangeDisplayProps('startAngle', Number(e.target.value))} style={{width: 120}}/>
            </Form.Item>
        </Space>
        <Form.Item style={{marginBottom: 8}} label={t('INTERNAL.CLIENT.KPI.DASHBOARD.REMAINING_TITLE')} tooltip={t('INTERNAL.CLIENT.KPI.DASHBOARD.REMAINING_TITLE_TOOLTIP')}>
            <Input value={remainingTitle} onChange={onChangeRemainingTitle} placeholder={t('INTERNAL.CLIENT.COMMON.REMAINING')}/>
        </Form.Item>
        <div style={{float: 'right', marginTop: 10, marginBottom: 10}}>
            {t('INTERNAL.CLIENT.KPI.DASHBOARD.SHOW_HIDDEN_KPI')}&nbsp;
            <Switch checked={showHidden} onChange={setShowHidden}/>
        </div>
        <Table {...config}/>
    </div>
};

export default NpiInputKpiDashboardBlockConfigPieChart;
