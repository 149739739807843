import React, {Dispatch, useEffect} from "react";
import {useControllableValue} from "ahooks";
import {Button, Col, Dropdown, Menu, Row, Select, Space, Tooltip} from "antd";
import {
    ICondition,
    IConditionAction,
    IConditionActionDisplayEnum,
    IConditionActionTargetEnum,
    IConditionRule,
    IConditionRuleStateEnum,
    IConditionRuleTargetEnum,
    IConditionRulingEnum
} from "../../types/condition";
import styled from "styled-components";
import colors from "../../services/colors";
import {IQuestion} from "../../types/question";
import {MinusOutlined, PlusOutlined} from "@ant-design/icons/lib";
import NpiInputConditionRule from "./condition/rule";
import NpiInputConditionAction from "./condition/action";
import {IWTag} from "../../types/wave";
import {Trans, useTranslation} from "react-i18next";
import NpiInputConditionBlock from "./condition/block";

const StyledDivRuling = styled.div`
    background-color: ${colors.blue};
    color: ${colors.white};
    border-radius: 4px;
    padding: 10px 20px;
`;

const rulingOptions = [
    {label: IConditionRulingEnum.ALL, value: IConditionRulingEnum.ALL},
    {label: IConditionRulingEnum.ANY, value: IConditionRulingEnum.ANY},
];

const {QUESTION, BLOCK} = IConditionRuleTargetEnum;
const {EMPTY, BLOCK_LOGIC_AND, BLOCK_LOGIC_OR} = IConditionRuleStateEnum;

interface INpiInputCondition {
    questions:IQuestion[]
    condition?: ICondition
    onChange?:Dispatch<any>
    withActions?:boolean
    tags?: IWTag[]
    rulesTargets?:IConditionRuleTargetEnum[]
    customRulingText?:string
}

const NpiInputCondition = ({questions, rulesTargets=[QUESTION], tags, withActions=true, customRulingText='', ...props}:INpiInputCondition) => {
    const {t} = useTranslation();
    const [condition, setCondition] = useControllableValue<ICondition>(props, {
        valuePropName: 'condition',
        defaultValue: {id:null, rules: [], actions: [], ruling: IConditionRulingEnum.ALL} as ICondition,
    });

    //Shortcut to set condition prop
    const set = (prop:any) => setCondition({...condition, ...prop});

    //Add a new rule
    const addRule = () => {
        condition.rules.push({
            questionId: undefined,
            target: QUESTION,
            state: EMPTY,
        });
        set({rules: condition.rules});
    };

    //Add a new block of rules
    const addBlock = (logic:IConditionRuleStateEnum.BLOCK_LOGIC_OR|IConditionRuleStateEnum.BLOCK_LOGIC_AND) => {
        condition.rules.push({
            questionId: undefined,
            target: BLOCK,
            state: logic,
            value: [{
                questionId: undefined,
                target: QUESTION,
                state: EMPTY,
            },{
                questionId: undefined,
                target: QUESTION,
                state: EMPTY,
            }]
        });
        set({rules: condition.rules});
    };

    //Remove a rule
    const removeRule = (key:number) => {
        condition.rules.splice(key, 1);
        set({rules: condition.rules});
    };

    //Set a rule
    const setRule = (key:number, rule:IConditionRule) => {
        condition.rules[key] = rule;
        set({rules: condition.rules});
    };

    //Add a new action
    const addAction = () => {
        condition.actions.push({display: IConditionActionDisplayEnum.HIDE, target: IConditionActionTargetEnum.QUESTION});
        set({actions: condition.actions});
    };

    //Remove an action
    const removeAction = (key:number) => {
        condition.actions.splice(key, 1);
        set({actions: condition.actions});
    };

    //Set an action
    const setAction = (key:number, action: IConditionAction) => {
        condition.actions[key] = action;
        set({actions: condition.actions});
    };

    //When a block changes, apply the changes
    const onChangeBlock = (rules:IConditionRule[], block:IConditionRule) => {
        block.value = rules;
        set({rules: [...condition.rules]})
    };

    //Menu to add rules or blocks
    const addRuleMenu = <Menu items={[
        {key: '1', label: <div onClick={addRule}>{t('INTERNAL.CLIENT.CONDITION.NEW_RULE')}</div>},
        {key: '2', label: <div onClick={() => addBlock(BLOCK_LOGIC_OR)}>{t('INTERNAL.CLIENT.CONDITION.NEW_OR_BLOCK_RULES')}</div>},
        {key: '3', label: <div onClick={() => addBlock(BLOCK_LOGIC_AND)}>{t('INTERNAL.CLIENT.CONDITION.NEW_AND_BLOCK_RULES')}</div>},
    ] as any}/>

    //Fix structure on load, if things are not ok
    useEffect(() => {
        if( ! condition) {
            setCondition({
                id: null,
                rules: [{
                    questionId: undefined,
                    target: QUESTION,
                    state: EMPTY,
                }],
                actions: [],
                ruling: IConditionRulingEnum.ALL
            });
        }
        else{
            if( ! condition.rules.length) {addRule()}
            if( withActions && ! condition.actions.length) {addAction()}
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <Space direction="vertical" style={{width: '100%'}}>
        {/******** RULES ********/}
        {condition?.rules.map((r, key) => <Row key={'rule'+key} align="middle">

            {/* RULES LIST */}
            <Col span={key>0 ? 1:0}>
                {condition?.ruling === IConditionRulingEnum.ALL && key>0 && "AND"}
                {condition?.ruling === IConditionRulingEnum.ANY && key>0 && "OR"}
            </Col>
            <Col span={key>0?21:22}>
                {r.target === BLOCK && <NpiInputConditionBlock questions={questions} tags={tags} logic={r.state as any} rules={r.value as IConditionRule[]} rulesTargets={rulesTargets} onChange={rules => onChangeBlock(rules, r)}/>}
                {r.target !== BLOCK && <NpiInputConditionRule key={key} questions={questions} tags={tags} value={r} onChange={r => setRule(key, r)} rulesTargets={rulesTargets}/>}
            </Col>

            {/* ADD & REMOVE RULES*/}
            <Col span={2}>
                <Space align="center" direction="vertical" style={{height: '100%', width: '100%'}}>
                    {key === 0 && <Tooltip title={t('INTERNAL.CLIENT.COMMON.ADD_RULE')} placement="right">
                        {/*If block is available, use a dropdown*/}
                        {rulesTargets?.indexOf(BLOCK) >= 0 && <Dropdown overlay={addRuleMenu}>
                            <Button icon={<PlusOutlined />}/>
                        </Dropdown>}
                        {/*If block is not available, do not offer option to add one*/}
                        {rulesTargets?.indexOf(BLOCK) < 0 && <Button icon={<PlusOutlined />} onClick={addRule}/>}
                    </Tooltip>}
                    {condition.rules.length>1 && <Tooltip title={t('INTERNAL.CLIENT.COMMON.REMOVE_RULE')} placement="right">
                        <Button icon={<MinusOutlined />} onClick={() => removeRule(key)}/>
                    </Tooltip>}
                </Space>
            </Col>
        </Row>)}

        {/******** RULING ********/}
        <Col span={22}>
            <StyledDivRuling>
                <Trans
                    i18nKey="INTERNAL.CLIENT.NPI.STYLED_DIV_RULING"
                    t={t}
                    values={{ customRulingText: customRulingText}}
                    components={{ Select: <Select value={condition?.ruling} options={rulingOptions} onChange={v => set({ruling: v})}  style={{margin: 5, width: 75}}/> }}
                />
            </StyledDivRuling>
        </Col>

        {/******** ACTIONS ********/}
        {withActions && condition?.actions.map((a, key) => <Row key={'act'+key} align="middle">

            {/* ACTIONS LIST */}
            <Col span={22}>
                <NpiInputConditionAction key={key} questions={questions} value={a} onChange={a => setAction(key, a)}/>
            </Col>

            {/* ADD & REMOVE ACTION*/}
            <Col span={2}>
                <Space align="center" direction="vertical" style={{height: '100%', width: '100%'}}>
                    {key === 0 && <Tooltip title={t('INTERNAL.CLIENT.COMMON.ADD_ACTION')} placement="right">
                        <Button icon={<PlusOutlined />} onClick={addAction}/>
                    </Tooltip>}
                    {condition.actions.length>1 && <Tooltip title={t('INTERNAL.CLIENT.COMMON.REMOVE_ACTION')} placement="right">
                        <Button icon={<MinusOutlined />} onClick={() => removeAction(key)}/>
                    </Tooltip>}
                </Space>
            </Col>
        </Row>)}
    </Space>
};

export default NpiInputCondition;