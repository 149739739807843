import {IKpi} from "./kpi";
import {INpi} from "./npi";
import {IWTag} from "./wave";

export enum IDashboardBlockType {
    KPI= 'KPI',
    PIE_CHART = 'PIE_CHART',
    TABLE = 'TABLE',
    SPACE = 'SPACE',
    TITLE = 'TITLE',
}

export interface IDashboardBlockConfiguration {
    key: string //ID
    type: IDashboardBlockType
    config: any
    visible: boolean
    deleted: boolean
    grid: {
        x: number
        y: number
        w: number
        h: number
    }
}

export interface IDashboard{
    id: number
    name: string
    npi_id: number
    configuration: IDashboardConfiguration
}

export interface IDashboardConfiguration {
    blocks: IDashboardBlockConfiguration[]
    print?: Record<string, {x:number, y:number, width:string, height:string}>
    filters?: any
}

export enum IDashboardTableModeEnum {
    PROGRAM = 'PROGRAM',
    REGION = 'REGION',
    COUNTRY = 'COUNTRY',
    PARTNER = 'PARTNER',
}

export interface IDashboardData {
    npi: INpi
    kpis:IKpi[]
    tags: IWTag[]
    dashboards: IDashboard[]
    values:Record<string, number>
    tables: Record<string, {
        mode: IDashboardTableModeEnum
        rows: {}[]
    }>
    charts:Record<string, {
        id: number
        name: string
        color: string
        value: number
    }[]>
}