import React, {Dispatch, useContext, useMemo, useState} from 'react';
import styled from "styled-components";
import {Button, Card, Col, Form, Input, Modal, ModalProps, Row, Space, Switch, Table} from "antd";
import { AlignType } from 'rc-table/lib/interface';
import { IShipmentCode } from "../../types/shipment";
import npiApi from "../../services/api";
import {MinusOutlined, OrderedListOutlined, PlusOutlined} from "@ant-design/icons";
import {useRequest} from "ahooks";
import _ from "lodash";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react";
import {NpiInternalContext, NpiInternalContextProvider} from "../../contexts/internal-context";
import NpiDisplayRegion from "../display/region";

const StyledArchivedDiv = styled.label`
    cursor: pointer;
    margin-left: 5px;
    button{
        position: relative;
        top: -2px;
    }
`;

interface IModalEditShipmentCodes extends ModalProps {
    onChangeShipmentCodeList: Dispatch<void>,
}

const ModalEditShipmentCodes = observer(({onChangeShipmentCodeList, ...props}: IModalEditShipmentCodes) => {
    const {t} = useTranslation();

    const {runAsync: archivedCode, loading: loadingArchived } = useRequest(npiApi.internal.shipments.code.archivedShipmentCode, {manual: true});
    const {loading} = useRequest(npiApi.internal.shipments.code.list, {
        defaultParams: [{with_archived: true}],
        onSuccess: (data : IShipmentCode[]) => setShipmentCodes(data)
    });

    const [ shipmentCodes, setShipmentCodes ] = useState<IShipmentCode[]>( [] );
    const [ showArchived, setShowArchived ] = useState<boolean>( false );
    const [ addMode, setAddMode ] = useState<boolean>( false );
    const list = useMemo( (): IShipmentCode[] => {
        return showArchived ? shipmentCodes : shipmentCodes.filter( (i: IShipmentCode) => !i.is_archived );
    }, [ shipmentCodes, showArchived, ] )

    const onAddCode = (newItem: IShipmentCode) => {
        setShipmentCodes( [ newItem, ...shipmentCodes ] );
        setAddMode(false);
        onChangeShipmentCodeList?.();
    }

    const updateArchivedCode = (id: number, checked: boolean) => archivedCode({id: id, is_archived: checked})
        .then((item: IShipmentCode) => {
            const found = _.find(shipmentCodes, {id: item.id});
            if(found){
                _.extend(found, item);
                setShipmentCodes([...shipmentCodes]);
                onChangeShipmentCodeList?.();
            }
        });

    const tableColumns = [
        { title: '#', dataIndex: 'id', key: 'id' },
        { title: t('INTERNAL.CLIENT.DASHBOARD.SHIPMENT_CODE'), dataIndex: 'code', key: 'code', style: { padding: '5px, 16px' } },
        { title: <>
                {t('INTERNAL.CLIENT.SHIPMENT.IS_ARCHIVED')} -
                <StyledArchivedDiv><small>{t('INTERNAL.CLIENT.COMMON.SHOW_ARCHIVED')} <Switch size="small" checked={showArchived} onChange={setShowArchived}/></small></StyledArchivedDiv>
            </>, dataIndex: '', key: 'id', align: 'right' as AlignType, render: (item: IShipmentCode) =>
            <Switch size="small" loading={loadingArchived} checked={item.is_archived} onChange={(checked => updateArchivedCode(item.id, checked))} />
        }
    ];

    const configModal = {
        title: t('INTERNAL.CLIENT.DASHBOARD.SHIPMENT_CODE'),
        icon: <OrderedListOutlined/>,
        footer: null,
        width: 800,
        ...props,
    }

    return <Modal {...configModal}>
        <NpiInternalContextProvider>
            <Space style={{float: 'right', marginBottom: 20}}>
                <Button type="primary" onClick={ () => setAddMode( !addMode ) } block icon={ addMode ? <MinusOutlined/> : <PlusOutlined/> }>{t('INTERNAL.CLIENT.SHIPMENT.ADD_SHIPMENT_CODE')}</Button>
            </Space>

            <div className="clearfix"/>

            { addMode && <Row>
                <ShipmentCodeFormAdd onAddCode={onAddCode}/>
            </Row> }

            <Space size={ [ 0, 0 ] } wrap/>

            <Row gutter={ [ 0, 48 ] }>
                <Col span={ 24 }>
                    <Table rowKey={ 'id' } loading={ loading } dataSource={ list } columns={ tableColumns }/>
                </Col>
            </Row>
        </NpiInternalContextProvider>
    </Modal>
});

const ShipmentCodeFormAdd = ({onAddCode}:any) => {
    const {t} = useTranslation();
    const {user} = useContext(NpiInternalContext);
    const {runAsync: addNewCode, loading: loadingAdd} = useRequest(npiApi.internal.shipments.code.createShipmentCode, {manual: true});

    const [form] = Form.useForm<IShipmentCode>();
    const [previewString, setPreviewString] = useState<string>('');
    const previewCode = useMemo(() => <>
        <NpiDisplayRegion id={user.region_id}/>-{previewString}
    </>, [user, previewString])

    const onFinish = (value: IShipmentCode) => {
        addNewCode( value ).then( (item: IShipmentCode) => {
            form.resetFields();
            onAddCode(item);
        });
    };

    return <Card style={{ width: "100%" }}>
        <Form form={ form } layout="inline" onFinish={ onFinish }>
            <Col span={10}>
                <Form.Item label={t('INTERNAL.CLIENT.COMMON.CODE')} name="string" required help={<small>{t('INTERNAL.CLIENT.SHIPMENT.PREVIEW_CODE')} : <b>{previewCode}</b></small>}>
                    <Input onChange={(e) => setPreviewString(e.target.value)} placeholder={`${t('INTERNAL.CLIENT.COMMON.CODE')}...`}/>
                </Form.Item>
            </Col>
            <Col span={4}>
                <Form.Item shouldUpdate>
                    <Button icon={<PlusOutlined/>} type="primary" htmlType="submit" loading={loadingAdd} disabled={!previewString}/>
                </Form.Item>
            </Col>
        </Form>
    </Card>
};

export default ModalEditShipmentCodes;
