import React, {useMemo} from "react";
import {SelectProps} from "antd/lib/select";
import {Select, Space, Switch} from "antd";
import {useControllableValue} from "ahooks";
import _ from "lodash";
import {useTranslation} from "react-i18next";

interface SelectTagRules extends SelectProps<any> {

}

/**
 *
 */
const NpiInputSelectTagRules = ({...props}:SelectTagRules) => {
    const [rules, setRules] = useControllableValue<any[]>(props, {
        defaultValue: [],
    });
    const {t} = useTranslation();

    //Check if a rule is present or not
    const activeRules = useMemo(() => _.map(rules, 'type'), [rules]);
    const has = (v:string) => activeRules.indexOf(v)>=0;

    //Activate/Deactivate rule
    const toggleRule = (type: string, value: boolean) => {
        const localRules = rules??[];
        if( ! value){
            _.remove(localRules, {type});
            setRules([...localRules]);
        }
        else setRules([...localRules, {type}]);
    };

    //Set the list of values
    const setList = (list:[]) => {
        _.find(rules, {type: 'list'}).values = list;
        setRules([...rules]);
    };

    return <div>
        <Space direction="vertical" size={10}>
            <div>
                {t('INTERNAL.CLIENT.COMMON.REQUIRED')} : <Switch checked={has('required')} onChange={v => toggleRule('required', v)}/>
            </div>
            <div>
                <div>
                    {t('INTERNAL.CLIENT.COMMON.IN_LIST')} : <Switch checked={has('list')} onChange={v => toggleRule('list', v)}/>
                </div>
                <div>{has('list') && <Select mode="tags" value={_.find(rules, {type: 'list'})?.values} style={{marginTop: 10, width: 400}} onChange={setList} dropdownStyle={{display: 'none'}} placeholder={t('INTERNAL.CLIENT.TAGS.TAG_POSSIBLE_VALUES')}/>}</div>
            </div>
        </Space>
    </div>
};

export default NpiInputSelectTagRules;