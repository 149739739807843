import React, {useContext, useEffect, useMemo, useState} from 'react';
import {useHistory, useParams} from 'react-router';
import {Spin} from "antd";
import {useWaveStore} from "../contexts/stores";
import {observer} from "mobx-react";
import {NpiInternalContext} from "../contexts/internal-context";
import NpiWaveTabPos from "../components/wave/tab-pos";
import NpiWaveTabLaunch from "../components/wave/tab-launch";
import NpiWaveTabForm from "../components/wave/tab-form";
import NpiWaveTabInformation from "../components/wave/tab-information";
import NpiDisplayTabRouter from "../components/display/tab-router";
import NpiConfigKpis from "../components/config/kpis";
import npiApi from "../services/api";
import {useTranslation} from "react-i18next";
import useRestrictedToRights from "../hooks/use-restricted-to-rights";
import {IRightsEnum} from "../types/user";
import {IWaveStatusEnum} from "../types/wave";

const NpiContainerWaveConfig = observer(() => {
    useRestrictedToRights([IRightsEnum.EDIT_WAVES]);
    const history = useHistory();
    const params:any = useParams();
    const {wave, select, loaded} = useWaveStore();
    const {t} = useTranslation();
    const {setBreadcrumbs} = useContext(NpiInternalContext);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    //Tabs to display
    const tabs = useMemo(() => [
        {
            label: t('INTERNAL.CLIENT.COMMON.INFORMATION'),
            component: <NpiWaveTabInformation wave={wave}/>,
            url: 'information',
        },
        {
            label: t('INTERNAL.CLIENT.COMMON.POS'),
            component: <NpiWaveTabPos wave={wave}/>,
            url: 'pos',
        },
        {
            label: t('INTERNAL.CLIENT.COMMON.FORM'),
            component: <NpiWaveTabForm wave={wave}/>,
            url: 'form',
        },
        {
            label: t('INTERNAL.CLIENT.NPI.KPIS'),
            component: <NpiConfigKpis item={wave} saveKpi={npiApi.internal.kpi.waveSave} defaultItem={{npi_id: wave.npi_id, region_id: wave.region_id}} displayExportMenu={true}/>,
            url: 'kpis',
        },
        {
            label: t('INTERNAL.CLIENT.COMMON.LAUNCH'),
            component: <NpiWaveTabLaunch wave={wave}/>,
            url: 'launch',
        },
    ], [wave, t]);

    //Select our wave based on url param
    useEffect(() => {
        setIsLoading(true);
        select(params.waveId).then(({wave, npi}:any) => {
            setBreadcrumbs([
                {url: '/npi', name: 'NPI'},
                {url: '/npi/' + npi.id, name: npi.name},
                {url: '/wave/' + wave.id + '/config', name: wave.name},
            ]);
        }).finally(() => setIsLoading(false));
    }, [params.waveId, select, setBreadcrumbs]);

    // for daily waves
    useEffect(() => {
        if( !loaded || isLoading ){
            return;
        }
        if( !!wave && wave.daily_group_id !== null ) {
            // we prevent the edition of non-active wave (and non-draft)
            if( !wave.is_daily_active && ![IWaveStatusEnum.OPEN, IWaveStatusEnum.DELAYED_LAUNCH].includes(wave.status)) {
                history.push("/npi/"+wave.npi_id);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wave, loaded, isLoading]);


    //Wait for data to load before display
    if( !loaded || isLoading ){
        return !!wave && <Spin style={{position: "absolute", left: "50%", marginTop: 20}}/>
    }

    return <NpiDisplayTabRouter tabs={tabs}/>
});

export default NpiContainerWaveConfig;