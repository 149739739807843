//Direction = impact on status of an answer
import {IKpi} from "./kpi";
import {ICondition} from "./condition";
import {IWTag, ITranslations} from "./wave";


import {INpiUploadImage} from "../components/input/image-uploader";

export enum IQuestionAnswerDirectionEnum {
    POSITIVE = '+',
    NEGATIVE = '-',
    NEUTRAL = '~',
}

//Type of question
export enum IQuestionTypeEnum {
    BOOL= 'BOOL',
    LIST= 'LIST',
    MULTILIST= 'MULTILIST',
    NUMERIC = 'NUMERIC',
    TEXT = 'TEXT',
    DATE = 'DATE',
    IMAGE = 'IMAGE',
}

//Answer as stored inside a question
export interface IQuestionAnswer{
    label: string
    value: string|number
    direction: IQuestionAnswerDirectionEnum
    hidden?: boolean

    //Used in interface only
    hiddenInForm?: boolean
}

//Answer as stored in a form
export interface IFormAnswerByQuestion {
    [qId: number]: IFormAnswer
}
export interface IFormAnswer {
    id?: number
    wave_id?: number
    question_id: number
    pos_id?: number
    value: any
    date_value?: string|null|undefined
}

export interface IFormImagesByQuestion {
    [qId: number]: IFormImagesAnswer
}
export interface IFormImagesAnswer {
    question_id: number,
    images: INpiUploadImage[],
}

export interface IWithQuestions {
    id: number
    name: string
    questions: IQuestion[]
    kpis: IKpi[]
    conditions: ICondition[]
    tags: IWTag[]
    translations: ITranslations
}

//Question as stored in db
export interface IQuestion{
    id: number
    npi_id?: number
    wave_id: number|null
    type: IQuestionTypeEnum
    name: string
    display_name: string
    description: string
    sequence: number
    config: {
        answers?:IQuestionAnswer[]
    }
    is_mandatory: boolean|number
    is_blocking: boolean|number
    is_status_neutral: boolean|number
    is_date_required: boolean|number
    default_value: any

    //Used in interface only
    hiddenInForm?: boolean

    //Used in Select search question
    wave_name?: string
}