import React, {useContext, useMemo, useState} from "react";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import NpiLanguageContext from "../../contexts/language-context";
import {Button, Input, PageHeader} from "antd";
import {NpiDisplayWaveNameTranslation} from "../../components/display/translations";
import styled from "styled-components";
import {SearchOutlined} from "@ant-design/icons/lib";
import {useNpiExternalContext} from "../../contexts/external-context";
import useExternalToken from "../../hooks/use-external-token";
import {ExternalTokenRight} from "../../types/external-token";


interface INpiExternalContainerPosHiddenSearchProps {
    //
}

const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  
  .input-pos-id {
      margin: 50px 20px;
      max-width: 450px;

    // -- reset external style
    .ant-input {
      width: auto;
      background-color: white;
    }
  }
`;

const NpiExternalContainerPosHiddenSearch: React.FC = ({...props}: INpiExternalContainerPosHiddenSearchProps) => {
    // -- translation
    const {t} = useTranslation();
    const {currentLang} = useContext(NpiLanguageContext);

    // -- pos access
    const history = useHistory();
    const {waves} = useNpiExternalContext();
    const {getPosLink, hasRight} = useExternalToken();

    const [searchPosId, setSearchPosId] = useState<string>("");
    const isValidPosId = useMemo(() => !!searchPosId && Number.isInteger(Number.parseInt(searchPosId)), [searchPosId])

    const handleOnChange = (v: string) => {
        const intV = Number(v);
        if( isNaN(intV) ) return;

        setSearchPosId(v);
    }

    const handleOnSearch = () => {
        if( isValidPosId ){
            history.push(getPosLink(searchPosId));
        }
    }

    return <>
        { (waves.length === 1 && !hasRight(ExternalTokenRight.ACCESS_UNIFIED_VIEW)) &&
            <PageHeader style={{minHeight: 0}}>
                <h1 style={{fontSize: 14, textAlign: 'center'}}>
                    <NpiDisplayWaveNameTranslation wave={waves[0]} langId={currentLang?.id}/>
                </h1>
            </PageHeader>
        }

        <StyledDiv>

            <div className="input-pos-id">
                <Input.Group compact size={"large"}>
                    <Input
                        value={searchPosId}
                        onChange={e => handleOnChange(e.target.value)}
                        onPressEnter={handleOnSearch}
                        placeholder={`${t('INTERNAL.CLIENT.COMMON.APPLE_ID')} ... `}
                        inputMode={"numeric"}
                        size={"large"}
                    />

                    <Button
                        onClick={handleOnSearch}
                        disabled={!isValidPosId}
                        icon={<SearchOutlined />}
                        size={"large"}
                        type={"primary"}
                    />
                </Input.Group>
            </div>
        </StyledDiv>
    </>
}

export default NpiExternalContainerPosHiddenSearch;