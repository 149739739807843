import NpiLanguageContext from "../../../contexts/language-context";
import React, {useContext} from "react";
import {Collapse, CollapsePanelProps} from "antd";
import {IWave} from "../../../types/wave";
import {NpiDisplayWaveNameTranslation} from "../../display/translations";


interface INpiExternalWaveCollapsePanelProps extends Omit<CollapsePanelProps, 'header'|'key'>{
    wave: IWave
}

const NpiExternalWaveCollapsePanel = ({wave, ...props}: INpiExternalWaveCollapsePanelProps) => {
    const {currentLang} = useContext(NpiLanguageContext);

    return <Collapse.Panel
        key={wave.id}
        className={"wave-collapse-panel"}
        header={<>
            <NpiDisplayWaveNameTranslation wave={wave} langId={currentLang?.id}/>
            <em> <small> - {wave.npi_name}</small></em>
        </>}
        {...props}
    />
}

export default NpiExternalWaveCollapsePanel;