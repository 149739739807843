import React, {Dispatch, useContext} from "react";
import {Button, Card, Space, Tooltip} from "antd";
import {IQuestion} from "../../types/question";
import {
    ICondition,
    IConditionAction, IConditionActionDisplayEnum,
    IConditionActionTargetEnum,
    IConditionRule,
    IConditionRuleStateEnum,
    IConditionRuleTargetEnum,
    rulesStates,
    rulesTargetTypes
} from "../../types/condition";
import {observer} from "mobx-react";
import ArrowRightBold from '@2fd/ant-design-icons/lib/ArrowRightBold';
import Play from '@2fd/ant-design-icons/lib/Play';
import Eye from '@2fd/ant-design-icons/lib/Eye';
import EyeOff from '@2fd/ant-design-icons/lib/EyeOff';
import _ from "lodash";
import styled from "styled-components";
import colors from "../../services/colors";
import {DeleteFilled, SettingFilled, WarningFilled} from "@ant-design/icons/lib";
import {IWTag} from "../../types/wave";
import {getConditionErrors} from "../../helpers/condition";
import {NpiInternalContext} from "../../contexts/internal-context";
import {Trans, useTranslation} from "react-i18next";
import { posFieldsOptions } from "../input/condition/rule";


const StyledCard = styled(Card)`
  &.error{
    background: ${colors.yellowLighter};
  }
  .warning{
    position: relative;
    top: 2px;
    font-size: 30px;
    color: ${colors.red};
  }
`;

const Blue = styled.span`
    color: ${colors.blue};
    font-weight: bold;
    text-transform: uppercase;
    display: inline-block;
    .anticon{
        font-size: 18px;
        position: relative;
        top: 2px;
        line-height: 22px;()
    }
`;

const DivLine = styled.div`
    line-height: 24px;
    &:hover{
      background-color: ${colors.greySuperLightBackground};
    }
    .block{
        display: inline-block;
        .if{display: none}
        .line{display: inline-block;}
    }
    .logic{
        color: ${colors.purple};
        font-weight: bold;
        text-transform: uppercase;
    }
`;

const {BLOCK, QUESTION, STATUS, RESCHEDULED, TAG, MERCHANDISING_GROUP, POS_FIELD} = IConditionRuleTargetEnum;
const {TAG_EQUAL, TAG_NOT_EQUAL} = IConditionRuleStateEnum;

//When mousing over something, highlight the related question elsewhere on the page (helps to see better a question)
const onMouseOver = (question: IQuestion|undefined) => {
    question && document.querySelectorAll('#question-' + question.id).forEach(node => node.classList.add('highlighted'));
};
//When mousing out, clear the highlighting
const onMouseOut = () => {
    document.querySelectorAll('.npi-question').forEach(node => node.classList.remove('highlighted'));
};

interface INpiDisplayCondition {
    questions:IQuestion[]
    condition: ICondition
    tags?: IWTag[]
    onEdit?: Dispatch<any>
    onRemove?: Dispatch<any>
    customRulingText?:string
}

const NpiDisplayCondition = observer(({questions, condition, tags, customRulingText='', ...props}:INpiDisplayCondition) => {
    const {onEdit, onRemove} = props;
    const { t } = useTranslation();
    delete props.onEdit; delete props.onRemove;

    if( ! condition) return null;

    const errors = getConditionErrors(condition, questions, tags);

    return <StyledCard {...props} className={errors.length>0 ? 'error':''}>
        <Space style={{float: 'right'}}>
            {errors.length>0 && <Tooltip title={<ul>{errors.map((e,k)=><li key={k}>{e}</li>)}</ul>}>
                <WarningFilled className="warning"/>
            </Tooltip>}
            {!!onRemove && <Tooltip title={t('INTERNAL.CLIENT.CONDITION.REMOVE_CONDITION')}><Button type="primary" danger icon={<DeleteFilled />} onClick={() => onRemove(condition)}/></Tooltip>}
            {!!onEdit && <Tooltip title={t('INTERNAL.CLIENT.CONDITION.EDIT_CONDITION')}><Button type="primary" icon={<SettingFilled />} onClick={() => onEdit(condition)}/></Tooltip>}
        </Space>
        {condition.rules?.map((rule, key) => <NpiDisplayConditionRule key={key} rule={rule} questions={questions} tags={tags}/>)}
        {!!condition.ruling && <DivLine>
            <Blue><Play /></Blue>&nbsp;If <Blue>{condition.ruling}</Blue> of the "If" rules are matched, {customRulingText}
        </DivLine>}
        {condition.actions?.map((action, key) => <NpiDisplayConditionAction key={key} action={action} questions={questions}/>)}


    </StyledCard>
});

const NpiDisplayConditionRule = ({rule, questions, tags}:{rule:IConditionRule, questions:IQuestion[], tags?: IWTag[]}) => {
    const question = _.find(questions, {id: rule.questionId}) as IQuestion;
    const {merchandisingGroups} = useContext(NpiInternalContext);
    const {state, target, value} = rule;
    let arrayValue;

    //If not valid, ignore it
    if(target === QUESTION && ! question){
        return <div className="line"/>
    }

    //Display an array value better
    arrayValue = _.isArray(rule.value) ? rule.value.map((a,x)=> x ? <span key={x}><Blue>&nbsp;or&nbsp;</Blue>{a}</span>:<span key={x}>{a}</span>) : rule.value;

    return <DivLine className="line" onMouseOver={() => onMouseOver(question)} onMouseOut={onMouseOut}>
        <Blue className="if"><ArrowRightBold /> IF&nbsp;</Blue>
        {target === BLOCK && _.isArray(value) && <div className="block">
            {value
                .map((r,k) => <NpiDisplayConditionRule key={k} tags={tags} rule={r as IConditionRule} questions={questions}/>)
                .reduce((prev, curr, index) => [prev, <span key={"spacer-" + index} className="logic"> {!!state && rulesStates[state].label} </span>, curr] as any)
            }
        </div>}
        {target === QUESTION && !!state && <span>{question.name} <Blue>{rulesStates[state]?.label}</Blue> {arrayValue}</span>}
        {target === RESCHEDULED && <span>{rulesTargetTypes[target].sentence} <Blue>IS {_.isUndefined(rule.value) ? '' : (rule.value?'Yes':'No')}</Blue></span>}
        {target === STATUS && <span>{rulesTargetTypes[target].sentence} <Blue>IS {['Neutral', 'Negative', 'Positive'][rule.value as number]}</Blue></span>}
        {target === TAG && !!state && <span><Blue>Tag</Blue> {(_.find(tags, {id: rule.value}) as IWTag)?.name} <Blue>{rulesStates[state]?.label}</Blue> {[TAG_EQUAL, TAG_NOT_EQUAL].indexOf(state)>=0 ? rule.tagValue : 'on POS'}</span>}
        {target === MERCHANDISING_GROUP && !!state && <span><Blue>Merchandising Group</Blue> <Blue>{rulesStates[state]?.label}</Blue> {(_.find(merchandisingGroups, {id: rule.value}) as any)?.name}</span>}
        {target === POS_FIELD && !!state && <span><Blue>POS Field</Blue> {(_.find(posFieldsOptions, {value: rule.posField}))?.label} <Blue>{rulesStates[state]?.label}</Blue> {value}</span>}
    </DivLine>
};

const NpiDisplayConditionAction = ({action, questions}:{action:IConditionAction, questions:IQuestion[]}) => {
    const {t} = useTranslation();
    let question:IQuestion|undefined, answer;
    if(action.target === IConditionActionTargetEnum.QUESTION){
        question = _.find(questions, {id: action.questionId});
    }
    else{
        question = _.find(questions, {id: action.answerId?.[0]});
        answer = action.answerId?.[1];
    }

    //If not valid, ignore it
    if( ! question){
        return <div/>
    }

    return <DivLine onMouseOver={() => onMouseOver(question)} onMouseOut={onMouseOut}>
        <Blue>
            {action.display === IConditionActionDisplayEnum.SHOW
                ? <><Eye/> <Trans i18nKey='INTERNAL.CLIENT.COMMON.SHOW' t={t}/></>
                : <><EyeOff/> <Trans i18nKey='INTERNAL.CLIENT.COMMON.HIDE' t={t}/></>
            }
            <span> : </span>
            {action.target === IConditionActionTargetEnum.QUESTION
                ? <Trans i18nKey='INTERNAL.CLIENT.COMMON.QUESTION' t={t}/>
                : <Trans i18nKey='INTERNAL.CLIENT.COMMON.ANSWER' t={t}/>
            }
        </Blue>
        <span> {question.name} {!!answer && ' > ' + answer}</span>
    </DivLine>
};

export default NpiDisplayCondition;