import React, {useContext} from 'react';
import moment, {Moment} from "moment";
import NpiLanguageContext from "../../contexts/language-context";

interface INpiDisplayDate {
    value: string|Moment
    hours?:'none'|'inline'|'block'
    format?: string
    formatHours?: string
    locale?: string
    utcToTimezone?: boolean
}
const NpiDisplayDate = ({value, hours='none', format='DD-MMM-YYYY', formatHours='HH:mm', locale, utcToTimezone=false}:INpiDisplayDate) => {

    const {currentLang} = useContext(NpiLanguageContext);
    const currentLocale = locale ? locale : currentLang.code;

    let timezoneValue;
    if( utcToTimezone ) {
        const utc = moment.tz(value, 'UTC');
        const timezone = moment.tz.guess();
        timezoneValue = utc.clone().tz(timezone);
    } else {
        timezoneValue = moment(value);
    }

    const localeValue = currentLocale !== undefined ? timezoneValue.locale(currentLocale) : timezoneValue;

    return <span>
        {localeValue.format(format)}
        {hours === 'block' ? <br/> : ' '} {hours !== 'none' ? localeValue.format(formatHours) : ''}
    </span>;
};

export default NpiDisplayDate;