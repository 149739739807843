import React, {useContext, useEffect, useState} from 'react';
import {useHistory} from 'react-router';
import {Button, Form, Input, Modal, Space} from "antd";
import {PlusOutlined} from '@ant-design/icons';
import NpiPaginatedTable, {NpiServerPagination} from "../components/paginated-table";
import npiApi from "../services/api";
import {NpiInternalContext} from "../contexts/internal-context";
import NpiDisplayDate from "../components/display/date";
import {DashboardFilled} from "@ant-design/icons/lib";
import {useForm} from "antd/es/form/Form";
import {INpi} from "../types/npi";
import useAcl from "../hooks/use-acl";
import {IRightsEnum} from "../types/user";
import {useRequest} from "ahooks";
import NpiInputSelectNpi from "../components/input/select-npi";
import {REGION_WW} from "../helpers/constants";
import {useTranslation} from "react-i18next";
import useRestrictedToRights from "../hooks/use-restricted-to-rights";

const NpiContainerWwDashboardList = () => {
    useRestrictedToRights([IRightsEnum.VIEW_SENSITIVE_DATA]);
    const history = useHistory();
    const [visible, setVisible] = useState(false);
    const {setBreadcrumbs} = useContext(NpiInternalContext);
    const [form] = useForm();
    const {t} = useTranslation();
    // const [latestChange, setLatestChange] = useState<any>(); //used to trigger table refresh
    const {hasRight} = useAcl();
    const canEdit = hasRight(IRightsEnum.EDIT_NPIS);
    const {runAsync: createDashboard, loading: loadingCreate} = useRequest<INpi,any>(npiApi.internal.wwDashboard.create, {manual: true});
    const {runAsync: fetcher} = useRequest<NpiServerPagination, any>(npiApi.internal.wwDashboard.list, {manual: true});

    //Create the npi and change
    const onClickCreate = () => {
        const values = form.getFieldsValue();
        createDashboard({name: values.name, npi_ids: values.npi_ids}).then(({dashboard}:any) => {
            history.push('/ww-dashboard/' + dashboard.id);
        });
    };

    const columns:any =[
        {title: t('INTERNAL.CLIENT.COMMON.NAME'), dataIndex: 'name', sorter: true, filter: {mode: 'text'}},
        {title: t('INTERNAL.CLIENT.COMMON.CREATED'), dataIndex: 'created_at', sorter: true, render: (t:any) => <NpiDisplayDate value={t}/>, filter: {mode: 'date'}},
        {
            title: <Space>
                {canEdit && <Button type="primary" icon={<PlusOutlined/>} onClick={() => setVisible(true)}>{t('INTERNAL.CLIENT.DASHBOARD.CREATE_DASHBOARD')}</Button>}
            </Space>,
            align: 'right',
            render: (row: any) => <Space>
                <Button icon={<DashboardFilled />} onClick={() => {history.push('/ww-dashboard/' + row.id)}} size="large">{t('INTERNAL.CLIENT.DASHBOARD.VIEW_DASHBOARD')}</Button>
            </Space>,
        },
    ];

    //Set breadcrumbs at top of the page
    useEffect(() => setBreadcrumbs([{url: '/ww-dashboard', name: t('INTERNAL.CLIENT.DASHBOARD.READINESS_DASHBOARD')}]), [setBreadcrumbs, t]);

    return <>
        <NpiPaginatedTable fetcher={fetcher} columns={columns} pagination={{hideOnSinglePage: true, }}/>
        <Modal title={t('INTERNAL.CLIENT.DASHBOARD.CREATE_READINESS_DASHBOARD')} open={visible} onOk={onClickCreate} onCancel={() => setVisible(false)} okButtonProps={{loading: loadingCreate}}>
            <Form form={form} labelCol={{span: 5}}>
                <Form.Item label={t('INTERNAL.CLIENT.COMMON.NAME')} name="name"><Input/></Form.Item>
                <Form.Item label="NPIs" name="npi_ids"><NpiInputSelectNpi mode="multiple" regionIds={[REGION_WW]}/></Form.Item>
            </Form>
        </Modal>
    </>
};

export default NpiContainerWwDashboardList;
