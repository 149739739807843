export interface IUser {
    id: number
    dsid: string
    region_id: number
    region_name: string
    firstname: string
    lastname: string
    email: string
    role: number
    countries: number[]
    photo: string
    rights: IRightsEnum[]
    is_allowed_dashboard: number
}

export enum IRightsEnum {
    ADMIN_USERS = 'ADMIN_USERS',
    EDIT_WAVES = 'EDIT_WAVES',
    EDIT_NPIS = 'EDIT_NPIS',
    EDIT_DASHBOARDS = 'EDIT_DASHBOARDS',
    EDIT_SHIPMENTS = 'EDIT_SHIPMENTS',
    EDIT_TAGS = 'EDIT_TAGS',
    EDIT_WAVE_POS_REPORT = 'EDIT_WAVE_POS_REPORT',
    EXPORT_WW_DASHBOARD = 'EXPORT_WW_DASHBOARD',
    VIEW_SENSITIVE_DATA = 'VIEW_SENSITIVE_DATA',
}

export enum IUserRoleEnum
{
    SUPER_ADMIN = 0,
    REGION_ADMIN = 1,
    USER = 2,
    COUNTRY_USER = 10,
    MERCHANDISING_VENDOR = 20,
}