import React, {useRef} from "react";
import {Card, Progress} from "antd";
import styled, {css} from "styled-components";
import {useSize} from "ahooks";
import _ from "lodash";
import NpiDisplayKpiName from "./kpi-name";
import colors from "../../services/colors";
import chroma from "chroma-js";

const StyledContainer = styled('div')<{ printMode?: boolean }>`
  height: 100%;
  width: 100%;
  
  .ant-card {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .ant-card-head {
    min-height: 0;
    height: fit-content;
    
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-card-head-title {
    white-space: normal;
    padding: 0;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-card-body {
    min-height: calc(100% - 65px);
    flex-grow: 1;
    max-height: 100%;
    
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .card-body-inner {
      width: 100%;
      height: 100%;
      padding: 5px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      .values {
        flex: 40% 1 0;
        text-align: center;
        align-self: center;
        font-size: 14px;
        margin-top: 5px;
      }

      .ant-progress {
        flex: 60% 1 0;
        min-width: 55px;
        height: 100%;

        .ant-progress-inner {
          width: 100% !important;
          height: 100% !important;

          .numbers {
              color: #000;
              .percent {
                font-size: 1.3rem;
              }
          }
          .ant-progress-circle {
            width: 100%;
            height: 100% !important;
          }
        }
      }
    }
  }

  ${props => props.printMode && css`
    .ant-card {
      background: none;
      border: none;

      .ant-card-head {
        padding: 0px 0px 5px;
        align-items: end;
        border-bottom: none;
        .ant-card-head-title {
          .ant-avatar {
            margin-right: 5px;
          }
          .text {
            font-weight: bold;
            margin-left: 0 !important;
          }
        }
      }

      .ant-card-body {
        background: #f5f7fa;
        border-radius: 30px;
        border: 2px solid #e3e3e3;
        .values {
          font-size: 1rem;
          margin-top: 0;
        }
      }
    }
  `};
`;

interface INpiDisplayKpiValue {
    id:number
    data:any
    divideBy?: 'KPI'|'total'|'TAG'|'TAG_VALUE'
    kpiToDivideBy?: number
    tagToDivideBy?: number
    withoutIcon?: boolean
    fontSize?: number
    printMode?: boolean
}

//Using chroma-js to generate our scaling method. Using "classes" to have definite colors and no interpolation
const gradient = chroma.scale([colors.redLighter, colors.yellow, colors.yellowLighter, colors.blue]).classes([0, .5, .75, .9, 1]);
//const gradient = chroma.scale(['red', 'yellow', colors.green]).mode('hsl'); //a true gradient
const getColorFromPercent = (v:number) => gradient(v / 100).hex();

/**
 *
 * @param id
 * @param divideBy
 * @param kpiToDivideBy
 * @param tagToDivideBy
 * @param withoutIcon
 * @param fontSize
 * @param printMode
 * @param data
 * @constructor
 */
const NpiDisplayKpiValue = ({id, divideBy, kpiToDivideBy, tagToDivideBy, withoutIcon, fontSize=16, printMode=false, data}:INpiDisplayKpiValue) => {
    const kpi = _.find(data.kpis, {id})??{};
    const {total} = data.values;
    const value = data.values['kpi' + id];

    const cardRef = useRef(null);
    const { width, height } = useSize(cardRef) as any ?? {};
    const size = printMode
        ? undefined
        : Math.max(100, Math.min(width??0, (height??0)) - 65 /*header*/ - 20 /*some padding*/);

    let valueToDivideBy = total;
    if(divideBy === 'KPI' && kpiToDivideBy){
        valueToDivideBy = data.values['kpi' + kpiToDivideBy];
    }
    else if(divideBy === 'TAG' && tagToDivideBy){
        valueToDivideBy = data.values['tag' + tagToDivideBy];
    }
    else if(divideBy === 'TAG_VALUE' && tagToDivideBy){
        valueToDivideBy = data.values['tagValueForKpi' + id];
    }

    //We use floor when close to 100% to avoid showing 100% on an incomplete KPI
    const exactPercent = value/valueToDivideBy * 100;
    const ceilOrFloor = exactPercent < 99 ? 'round':'floor';
    const percent = Math[ceilOrFloor](exactPercent);

    return <StyledContainer ref={cardRef} printMode={printMode}>
        <Card title={<NpiDisplayKpiName kpi={kpi} withIcon={!withoutIcon} style={{fontSize}}/>}>
            <div className={"card-body-inner"}>
                {printMode && <div className="values">{value}<wbr/>/<wbr/>{valueToDivideBy}</div>}
                <Progress
                    type="circle"
                    width={size}
                    strokeColor={getColorFromPercent(percent) as any}
                    trailColor={colors.greyBatteryStatus}
                    percent={percent}
                    format={(percent) => <div className="numbers">
                        <div className="percent">{percent}%</div>
                        {!printMode && <div className="values">{value}/{valueToDivideBy}</div>}
                    </div>}
                />
            </div>
        </Card>
    </StyledContainer>
};

export default NpiDisplayKpiValue;