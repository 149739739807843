import Asterisk from "@2fd/ant-design-icons/lib/Asterisk";
import NpiExternalPosViewTabGallery from "../../components/external/pos-view/tab-gallery";
import NpiExternalPosViewTabShipment from "../../components/external/pos-view/tab-shipment";
import NpiExternalPosViewTabSurvey from "../../components/external/pos-view/tab-survey";
import PackageVariantClosed from "@2fd/ant-design-icons/lib/PackageVariantClosed";
import React, {useMemo, useState} from 'react';
import styled from "styled-components";
import useExternalToken from "../../hooks/use-external-token";
import {INpiExternalContainerPosViewRouteParams} from "../../types/external-token";
import {PictureOutlined, ProfileOutlined} from "@ant-design/icons";
import {Button, Col, Empty, PageHeader, Row, Spin, Tabs} from "antd";
import {Link} from "react-router-dom";
import {useHistory, useParams} from "react-router";
import {useSize} from "ahooks";
import {useTranslation} from "react-i18next";
import {LeftOutlined} from "@ant-design/icons/lib";
import NpiExternalDisplayPosCard from "../../components/external/pos-card";
import {useNpiExternalContext} from "../../contexts/external-context";
import {NpiExternalPosContextProvider, useNpiExternalPosContext} from "../../contexts/external-pos-context";


// -- Use a context provider to share current POS information
const NpiExternalContainerPosView = () => <NpiExternalPosContextProvider>
    <NpiExternalContainerPosViewInner/>
</NpiExternalPosContextProvider>


const NpiExternalContainerPosViewInner = () => {
    const {t} = useTranslation();
    const history = useHistory();
    const params = useParams<INpiExternalContainerPosViewRouteParams>();

    const {screenSize} = useNpiExternalContext();
    const {hasReturnLink, getHomeLink, getPosLink} = useExternalToken();

    // current POS datas
    const {
        pos,
        posId,
        waves,
        imagesAllowed,
        shipmentsAllowed,
        loading,
        error,
    } = useNpiExternalPosContext();

    // tabs height
    const posViewHeaderSize = useSize(document.getElementById("pos-view-header"));
    const responsiveTabPaneHeight = screenSize.height - (posViewHeaderSize?.height || 0);

    // lock navigation on form Submit (during the loading)
    const [lockNav, setLockNav] = useState<boolean>(false);

    // check if the gallery tab is mandatory
    const areImagesMandatory = useMemo(() => imagesAllowed && !!waves.find(wave => wave.is_images_mandatory), [waves, imagesAllowed]);

    // count displayed tabs
    const countTabs = useMemo<number>(() => [!!pos, imagesAllowed, shipmentsAllowed].filter(x => x).length, [pos, imagesAllowed, shipmentsAllowed]);

    // Callback called when user navigates through tab
    const onChangeTab = (tabType: string) => {
        history.push(getPosLink(posId, tabType));
    }

    return <div id={"pos-view"}>
    {loading
        ? <Spin tip={t('EXTERNAL.CLIENT.POS_VIEW.FETCHING_POS_DETAIL')} spinning={true} style={{margin: '30px auto', display: 'block'}}/>
        : <>
            <div id={"pos-view-header"}>
                <PageHeader>
                    <Row>
                        { hasReturnLink &&
                            <Col xs={2}>
                                <Link to={getHomeLink()}>
                                    <Button disabled={lockNav} type={"text"} icon={<LeftOutlined/>} style={{marginTop: 23}}/>
                                </Link>
                            </Col>
                        }

                        <Col xs={hasReturnLink ? 22 : 24}>
                            { pos &&
                                <NpiExternalDisplayPosCard pos={pos} showStatus={true} showPlanogram={true} cardProps={{style: {border: "unset"}}}/>
                            }

                            { (!loading && !!error) && <Empty description={`#${params.posId} - ${error}`}/>}
                        </Col>
                    </Row>
                </PageHeader>
            </div>

            {/* Table pane */}
            { pos && <StyledResponsiveTab
                id={"tab"}
                displayedTabCount={countTabs}
                activeKey={params.tabType}
                defaultActiveKey={params.tabType ?? "surveys"}
                onChange={onChangeTab}
                items={[

                    {// TAB SURVEY
                        key: "surveys",
                        disabled: lockNav,
                        label: <ProfileOutlined/>,
                        children: <StyledResponsiveTabPane id={"survey-tab-pane"} height={responsiveTabPaneHeight}>
                            <NpiExternalPosViewTabSurvey onSubmitting={setLockNav}/>
                        </StyledResponsiveTabPane>
                    },

                    // TAB IMAGES
                    ...imagesAllowed ? [{
                        key: "images",
                        disabled: lockNav,
                        label: <div style={{position: 'relative'}}><PictureOutlined/> {areImagesMandatory && <StyledAsterisk/>}</div>,
                        children: <StyledResponsiveTabPane height={responsiveTabPaneHeight}>
                            <NpiExternalPosViewTabGallery/>
                        </StyledResponsiveTabPane>
                    }] : [],

                    // TAB SHIPMENTS
                    ...shipmentsAllowed ? [{
                        key: "shipments",
                        disabled: lockNav,
                        label: <PackageVariantClosed style={{fontSize: 17}}/>,
                        children: <StyledResponsiveTabPane height={responsiveTabPaneHeight}>
                            <NpiExternalPosViewTabShipment/>
                        </StyledResponsiveTabPane>,
                    }] : [],
                ]}
            />}
        </>}
    </div>
}


// -- Mobile first responsive tab pane
const StyledResponsiveTabPane = styled(({height: number, ...props}) => <div {...props} />)`
    overflow-y: auto;
    height: calc(${props => props.height ? props.height : "300"}px - 55px);
    padding-bottom: 120px;
`;


// -- Mobile first responsive tab based on displayed tab count
const StyledResponsiveTab = styled(({displayedTabCount: number, ...props}) => <Tabs {...props} />)`
    margin-top: 1px;

    .ant-tabs-tab {
        width: ${props => props.displayedTabCount > 1 ? (100 / props.displayedTabCount) : '0'}%;
        display: ${props => props.displayedTabCount <= 1 ? 'none' : 'inline-flex'}
    }

    .ant-collapse {
        background-color: transparent;
    }
`;

const StyledAsterisk = styled(Asterisk)`
    color: #c32020;
    position: absolute;
    top: 0;
`

export default NpiExternalContainerPosView;