import {IWithQuestions} from "./question";
import IContact from "./contact";
import {ITagNpi} from "./tag";

//Translations has a structure with a first key which is the translation ID, then a list of path => translation
export type ITranslations = Record<string, Record<string, string>>;

export interface IWTag {
    id: number
    name: string
    config: any
    npis: null| ITagNpi[]
}

export enum IWaveStatusEnum {
    OPEN,
    LAUNCHED,
    CLOSED,
    DELAYED_LAUNCH= 10,
    ARCHIVED = 99,
}

export interface IWave extends IWithQuestions{
    id: number
    npi_id: number
    npi_name: string;
    region_id: number
    language_id: number
    shipment_code_id: number|null
    translation_ids: number[]
    translations: ITranslations
    config: any
    name: string
    date_launch: string
    date_deadline: string
    nb_pos: number
    nb_kpis?: number
    status: IWaveStatusEnum
    tags: IWTag[],
    is_images_allowed: boolean,
    is_images_mandatory: boolean,
    images_upload_limit: number,
    delay_launch_at?: any,
    is_available_in_unified_links: boolean,
    created_at?: string,
    updated_at?: string,
    contacts?: IContact[]
    daily_group_id?: number
    is_daily_active: boolean
}