import React from "react";
import {TableProps} from "antd/lib/table";
import {
    SortableContainer,
    SortableElement,
    SortableHandle,
    SortEnd,
    SortEvent,
    SortStart
} from 'react-sortable-hoc';
import {Table} from "antd";
import {MenuOutlined} from "@ant-design/icons";
import _ from "lodash";

// the icon use to drag rows
export const DragHandle = SortableHandle((props:any) => props.children || <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);
const SortableItem = SortableElement((props: JSX.IntrinsicAttributes & React.ClassAttributes<HTMLTableRowElement> & React.HTMLAttributes<HTMLTableRowElement>) => <tr style={{zIndex: 100000, ...props.style}} {...props}/>);
const SortableBody = SortableContainer((props: JSX.IntrinsicAttributes & React.ClassAttributes<HTMLTableSectionElement> & React.HTMLAttributes<HTMLTableSectionElement>) => <tbody {...props} />);

export interface INpiSortTableProps<RecordType> extends TableProps<RecordType>{
    onMoveItem: (oldIndex:number, newIndex:number, item:any) => void
}

/**
    Antd Table with drag & drop rows
 */
const NpiSortTable = ({onMoveItem, ...tableProps}: INpiSortTableProps<any>) => {
    const {dataSource, rowKey} = tableProps;

    // -- Callback when drop row to new position
    const onSortEnd = ({ oldIndex, newIndex }:SortEnd, event:SortEvent) => {
        // we apply change only if newIndex is valid and different from oldIndex
        if (newIndex >= 0 && oldIndex !== newIndex && dataSource ) {
            const item = dataSource[oldIndex] || null ;
            if( item ) {
                onMoveItem(oldIndex, newIndex, item);
            }
        }
    };

    // -- When start dragging
    // eslint-disable-next-line
    const onSortStart = ({}:SortStart, event:SortEvent) => {
        // TODO ABE when start to drag --> collapse all expanded rows
        // TODO ABE add css when dragging on potential target row (border-bottom: ...)
    };


    // -- the <tbody/> element
    const DraggableContainer = (props:any) => <SortableBody
        useDragHandle
        onSortEnd={onSortEnd}
        onSortStart={onSortStart}
        helperclass={'sortable-parent'}
        {...props}
    />;

    // -- the <tr/> rows
    const DraggableBodyRow = ({ className, style, ...restProps }:any) => {
        if( dataSource !== undefined ){
            const index = _.findIndex(dataSource, {[rowKey as string]: restProps['data-row-key']});

            return <SortableItem
                helperclass="sortable-child"
                index={index}
                {...restProps}
            />;
        }
        return null;
      };

    return <Table
        components={{
            body: {
                wrapper: DraggableContainer,
                row: DraggableBodyRow,
            },
        }}
        {...tableProps}
    />
}

export default NpiSortTable;