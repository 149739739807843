import React from 'react';
import styled from "styled-components";
import {useRequest} from "ahooks";
import npiApi from "../../services/api";

const StyledContainer = styled.div`
    background: #F0F2F5;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
`;

interface IOtherTableResponse {
}

const NpiContainerDashboardSyncListTableOthers = () => {

    const {loading, data} = useRequest<IOtherTableResponse, any>(npiApi.internal.dev.synchro.status.otherTables);

    return <>
        {(!loading && data) && <StyledContainer>

        </StyledContainer>}
    </>;
};

export default NpiContainerDashboardSyncListTableOthers;