import React, {Key, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {NpiInternalContext} from "../../contexts/internal-context";
import NpiDisplayLayoutContent from "../../components/display/layout-content";
import {useTranslation} from "react-i18next";
import useRestrictedToAdmin from "../../hooks/use-restricted-to-admin";
import {useRequest} from "ahooks";
import npiApi, {dowloadFromBlob, npiApiDownloadFileCallback} from "../../services/api";
import {ILanguage} from "../../types/language";
import {
    Button,
    Dropdown, Input,
    Menu,
    Modal,
    notification,
    Segmented,
    Select,
    Space,
    Spin,
    Upload
} from "antd";
import TableLanguageTranslation from "../../components/dashboard-translation/table-language-translation";
import TreeTranslationKeys from "../../components/dashboard-translation/tree-translation-keys";
import {DEFAULT_LANG} from "../../contexts/language-context";
import {
    BlockOutlined, BranchesOutlined,
    ClearOutlined,
    CopyOutlined,
    DownOutlined, EditOutlined,
    ExclamationCircleOutlined,
    FlagOutlined,
    ReloadOutlined,
    SearchOutlined,
    SwapOutlined,
    UploadOutlined
} from "@ant-design/icons";
import NpiDropdownEllipsis from "../../components/display/dropdown-ellipsis";
import {getModalValue} from "../../components/input/modal-return-value";
import JSZip from "jszip";


// TODO inline edit (& overwrite default)

type TTree = Record<string, any>;
type TLeaf = Record<string, string|null>;

// find a value in a tree, following a dot string path "a.b.c..."
export const findValueByPath = (path: string, obj: TTree): string|null => {
    if (typeof obj !== 'object' || !path) {
        return null;
    }

    const [key, ...keys] = path.split('.');
    const remainingPath = keys.join('.');

    if (!obj.hasOwnProperty(key)) {
        return null;
    }

    const value = obj[key];
    if (remainingPath === '') {
        if( typeof value === 'string' ) {
            return !!value ? value : null
        }
        return null
    }
    return findValueByPath(remainingPath, value);
};

// -- flat all keys of a tree into a dot array ["a.A", "a.B", "b.A.c"...]
const flatToDotKeys = (obj: TTree): string[] => {
    return Object.entries(obj).reduce((carry: string[], [key, value]) => {
        if (typeof value === 'object' && value !== null) {
            carry.push(...flatToDotKeys(value).map((path) => `${key}.${path}`));
        } else {
            carry.push(key);
        }
        return carry;
    }, []);
}

// -- flat all keys of a tree into a simple dot.key/value object {"a.A": "tradAA", "a.B": "tradBB"}
const flatToDotKeysValues = (obj: TTree, prevKey=''): TLeaf => {
    return Object.entries(obj).reduce((carry: TLeaf, [key, value]) => {
        if (typeof value === 'object' && value !== null) {
            const childValues = flatToDotKeysValues(value);
            const childValuesParsed = Object.assign({}, ...Object.keys(childValues).map(path => ({[`${key}.${path}`]: childValues[path]})))
            carry = {...carry, ...childValuesParsed};
        } else {
            carry[key] = value;
        }
        return carry;
    }, {});
}

const NpiContainerDashboardTranslation = () => {
    useRestrictedToAdmin();
	const {t} = useTranslation();

    // set breadcrumbs
    const {setBreadcrumbs} = useContext(NpiInternalContext);
    useEffect(() => setBreadcrumbs([
        {url: '/dev-dashboard', name: "Dev Dashboards"},
        {url: '/dev-dashboard/translation', name: "Translations"},
    ]), [setBreadcrumbs, t]);

    // -- api
    const {data: translationData, run: reloadTranslations, loading} = useRequest<any, any>(npiApi.internal.dev.translation.languages, );
    const {runAsync: exportSelectionXlsx, loading: loadingDownload} = useRequest(npiApi.internal.file.exportDevTranslationSelection, {manual: true, loadingDelay: 300});
    const {runAsync: importPartialTranslation, loading: loadingUpload} = useRequest(npiApi.internal.file.importDevPartialTranslation, {manual: true});
    const {runAsync: copySelectionTo, loading: loadingCopy} = useRequest(npiApi.internal.dev.translation.copySelectionTo, {manual: true});
    const {runAsync: fillDefault} = useRequest(npiApi.internal.dev.translation.fillDefault, {manual: true});
    const {runAsync: clearDeprecated} = useRequest(npiApi.internal.dev.translation.clearDeprecated, {manual: true});
    const {runAsync: moveKeyTo} = useRequest(npiApi.internal.dev.translation.moveKeyTo, {manual: true});
    const {runAsync: addNewKey} = useRequest(npiApi.internal.dev.translation.addNewKey, {manual: true});
    const {runAsync: updateKey} = useRequest(npiApi.internal.dev.translation.updateKey, {manual: true});

    // -- translation base
    const mapBase = useMemo<TTree>(() =>translationData?.base ?? {}, [translationData]);
    const baseFlatKeys = useMemo<string[]>(() => flatToDotKeys(mapBase), [mapBase]);
    const baseFlatValues = useMemo<TLeaf>(() => flatToDotKeysValues(mapBase), [mapBase]);

    // -- translation langs
    const mapLanguages = useMemo<Record<string, TTree>>(() =>translationData?.translations ?? {}, [translationData]);
    const languagesFlatValues = useMemo<Record<string, TLeaf>>(() => {
        let transValues: Record<string, TLeaf> = {};
        Object.keys(mapLanguages).forEach(l => {
            transValues[l] = flatToDotKeysValues(mapLanguages[l]);
        })
        return transValues
    }, [mapLanguages]);

    // -- languages
    const languages = useMemo<ILanguage[]>(() =>translationData?.languages ?? [], [translationData]);
    const internalLanguages = useMemo<ILanguage[]>(() => languages.filter(l => l.is_enabled_internal), [languages]);
    const externalLanguages = useMemo<ILanguage[]>(() => languages, [languages]);
    const [selectedLangs, setSelectedLangs] = useState<string[]>([]);
    const [currentMode, setCurrentMode] = useState<string>('internal');
    const currentLanguages = useMemo<ILanguage[]>(() => {
        switch (currentMode){
            case 'mix': return languages.filter(l => selectedLangs.includes(l.code));
            case 'external': return externalLanguages;
            default: return internalLanguages;
        }
    }, [selectedLangs, languages, internalLanguages, externalLanguages, currentMode])


    // -- tree checked/selected leafs
    const [checkedKeys, setCheckedKeys] = useState<Key[]>([]);
    const [selectedKeys, setSelectedKeys] = useState<Key[]>([])

    // -- find missing language/translation for all or for selection
    const missingKeys = useMemo(() => {
        return (checkedKeys.length > 0 ? checkedKeys : baseFlatKeys).filter((key) => {
            const mapKeyValues = Object.fromEntries(currentLanguages.map(l =>
                [l.code, findValueByPath(key.toString(), mapLanguages[l.code])]
            ));
            return Object.values(mapKeyValues).filter(v => v === null).length > 0;
        });
    }, [checkedKeys, baseFlatKeys, mapLanguages, currentLanguages]);


    // -- find missing keys between base file and a comparaison language
    const findNewKeys = useCallback(() => {
        getModalValue<string>({
            input: <Select
                defaultValue={'en-US'}
                options={languages?.map(l => ({value: l.code, label: l.name}))}
                placeholder="Select a language..."
                showSearch
                filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                style={{minWidth: 200}}
            />,
            title: "Find new translation keys...",
            description: "Select a language that will be compare to the 'translations.json' file :",
            required: true,
        }).then((compareLang) => {
            if(!!compareLang) {
                const languageKeys = flatToDotKeys(mapLanguages[compareLang] ?? {});
                const diff = baseFlatKeys.filter((item) => !languageKeys.includes(item));
                setSelectedKeys(diff);
            }
        })
    }, [languages, baseFlatKeys, mapLanguages])

    // -- construct a map for a selection of keys: within each key, map each langs with its value (use for the export)
    const mapSelectionLangValues = useCallback((selection: Key[], langs: string[]) => {
        return Object.fromEntries(selection.map(key => {
            const baseValue = findValueByPath(key.toString(), mapBase);

            const mapLangKeyValues = Object.fromEntries(langs.map(l => {
                const languageValue = findValueByPath(key.toString(), mapLanguages[l]);
                return l === DEFAULT_LANG
                    ? [l, !!languageValue ? languageValue : baseValue]
                    : [l, languageValue]
            }));
            return [key, mapLangKeyValues]
        }))
    }, [mapLanguages, mapBase]);

    // -- export selection to Xlsx
    const exportSelection = useCallback((selection: Key[]) => {

        const exportLangs = [DEFAULT_LANG, ...currentLanguages.map(l => l.code).filter(l => l !== DEFAULT_LANG)];
        const selectionMapValues = mapSelectionLangValues(selection, exportLangs);

        // Download an xls file with all lines with errors
        const filename = (currentMode === 'mix' && currentLanguages.length === 1) ? `translation-${currentLanguages[0].code}.xlsx` : `translation-${currentMode}.xlsx`;
        exportSelectionXlsx({translations: selectionMapValues}).then(npiApiDownloadFileCallback(filename));

    }, [exportSelectionXlsx, currentMode, currentLanguages, mapSelectionLangValues]);


    // -- export selection in a Zip (1-Xlsx file/country) - if onlyEmpty === true : for each countries, export only empty keys
    const [loadingZip, setLoadingZip] = useState<boolean>(false);
    const exportSelectionAsZip = useCallback(async (selection: Key[], onlyEmpty?: boolean) => {

        setLoadingZip(true);
        const files = [];
        for(let l of currentLanguages) {

            // if "onlyEmpty" is true, keep only keys that do not have translations yet
            const filterSelection = onlyEmpty ? selection.filter(k => {
                return !findValueByPath(k.toString(), mapLanguages[l.code]);
            }) : selection;

            // export languages that have at least one key
            if( filterSelection.length > 0 ) {
                const exportLangs = l.code === DEFAULT_LANG ? [DEFAULT_LANG] : [DEFAULT_LANG, l.code];
                const selectionMapValues = mapSelectionLangValues(filterSelection, exportLangs);

                try {
                    const response = await exportSelectionXlsx({ translations: selectionMapValues });
                    files.push({
                        filename: `${l.name}.xlsx`,
                        blob: new Blob([response.data], { type: "text/html" }),
                    });
                } catch (error) {
                    console.error(`Error exporting ${l.code}:`, error);
                }
            }
        }

        // if we receive some files, create a Zip file
        if( files.length > 0 ) {
            const zipper = JSZip();
            files.forEach((file) => zipper.file(file.filename, file.blob));
            zipper.generateAsync({type: 'blob'}).then((zipFile: Blob) => {
                const zipName = (currentMode === 'mix' && currentLanguages.length === 1) ? `translation-${currentLanguages[0].code}.zip` : `translation-${currentMode}.zip`;
                dowloadFromBlob(zipFile, zipName);
                setLoadingZip(false);
            });
        } else {
            setLoadingZip(false);
            notification.warn({message: "Nothing to export"});
        }
    }, [exportSelectionXlsx, currentLanguages, currentMode, mapLanguages, mapSelectionLangValues]);


    // -- import partial Xlsx files and update json files
    const [loadingImport, setLoadingImport] = useState<boolean>(false);
    const xlsTypes = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel"];
    const zipTypes = ["application/zip", "application/x-zip", "application/x-zip-compressed"];
    const importFiles = (importedFile: any) => {
        Modal.confirm({
            title: "Update JSON language files ? (all languages/keys in the template will be replaced)",
            icon: <ExclamationCircleOutlined/>,
            onOk: async () => {
                setLoadingImport(true);

                const filesToUpload: File[] = [];

                // xls file
                if( xlsTypes.includes(importedFile.type) ) {

                    filesToUpload.push(importedFile);

                // zip file
                } else if(zipTypes.includes(importedFile.type)) {

                    let cntNotXls = 0;
                    const zipper = new JSZip();
                    const unzippedFiles = await zipper.loadAsync(importedFile);

                    for(const [filename, file] of Object.entries(unzippedFiles.files)) {
                        // Check if the file has a .xls or .xlsx extension
                        if (/\.(xls|xlsx)$/i.test(filename)) {
                            const xlsFileData = await file.async('arraybuffer');
                            // Create a new File object from the binary data
                            filesToUpload.push( new File([xlsFileData], filename, { type: file.dir ? '' : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }) );
                        } else {
                            cntNotXls++;
                        }
                    }

                    if( cntNotXls > 0 ) {
                        notification.warn({message: `Only Xls files will be imported (${cntNotXls} file(s) will be ignored)`})
                    }
                }

                if( filesToUpload.length === 0 ) {
                    notification.warn({message: "Nothing to import"});
                    setLoadingImport(false);
                } else {
                    for(const file of filesToUpload) {
                        try {
                            await importPartialTranslation({file});
                        } catch (error) {
                            console.error(`Error importing ${file.name}:`, error);
                        }
                    }
                    setLoadingImport(false);
                    reloadTranslations();
                }
            },
        });
        return false;
    }


    // -- copy translations from one language to others for a selection of keys
    const [openModalCopy, setOpenModalCopy] = useState<boolean>(false);
    const [copySource, setCopySource] = useState<string>();
    const [copyTargets, setCopyTargets] = useState<string[]>([]);
    const copySelectionToLanguage = useCallback(() => {
        copySelectionTo({source: copySource, targets: copyTargets, keys: checkedKeys}).then(() => {
            reloadTranslations();
            setOpenModalCopy(false);
        });
    }, [copySource, copyTargets, checkedKeys, reloadTranslations, copySelectionTo])


    // -- Fill empty default language values (en-US) with base values
    const onFillDefault = useCallback(() => fillDefault({default_lang: DEFAULT_LANG}).then(response => {
        notification.info({message: `update ${response} missing keys`});
        reloadTranslations();
    }), [fillDefault, reloadTranslations]);


    // -- Clear deprecated keys
    const onClearDeprecated = useCallback(() => clearDeprecated({}).then(response => {
        notification.info({message: `${response} old keys have been removed`});
        reloadTranslations();
    }), [clearDeprecated, reloadTranslations]);

    // -- Clear deprecated keys
    const onMoveKeyTo = useCallback(() => {
        const oldKey = checkedKeys[0]!;
        getModalValue<string>({
            input: <Input placeholder="Enter a new key..." style={{minWidth: 200}}/>,
            title: `Move key "${oldKey}" to...`,
            required: true,
        }).then((newKey) => {
            if(!!newKey) {
                moveKeyTo({newKey, oldKey}).then(response => {
                    notification.info({message: `Key has been moved`});
                    reloadTranslations();
                })
            }
        })
    }, [moveKeyTo, reloadTranslations, checkedKeys]);

    const onAddNewKey = useCallback((newKey: string) => {
        getModalValue<string>({
            input: <Input placeholder={`Enter ${DEFAULT_LANG} translation...`} style={{minWidth: 200}}/>,
            title: `Create new key/value for "${newKey}"`,
            required: true,
        }).then((newValue) => {
            if(!!newValue) {
                addNewKey({key: newKey, value: newValue, default_lang: DEFAULT_LANG}).then(response => {
                    notification.info({message: `Key has been added`});
                    reloadTranslations();
                })
            }
        })
    }, [addNewKey, reloadTranslations]);


    const onUpdateKey = useCallback((lang: ILanguage, key: string) => {
        const currentValue = languagesFlatValues[lang.code]?.[key];
        const isDefault = lang.code === DEFAULT_LANG;

        getModalValue<string>({
            input: <Input placeholder={`New translation...`} style={{minWidth: 200}} defaultValue={currentValue ?? ""}/>,
            title: <>Update <strong>{lang.short_name} ({lang.code})</strong></>,
            description: <>Change <strong>{lang.name}</strong> translation for key : <br/> <strong>{key}</strong>.</>,
            help: isDefault ? <>* changing <strong>{DEFAULT_LANG}</strong> will also update the main translation file.</> : "",
            required: true,
        }).then((newValue) => {
            if(!!newValue && newValue !== currentValue) {
                updateKey({key: key, value: newValue, lang: lang.code, is_default: isDefault}).then(response => {
                    notification.info({message: `Key has been updated`});
                    reloadTranslations();
                })
            }
        })
    }, [updateKey, reloadTranslations, languagesFlatValues])


    // -- Select base keys that have different values in default lang en-US
    const findDiffWithDefault = useCallback(() => {
        const langFlatValues = languagesFlatValues[DEFAULT_LANG];
        let diff = baseFlatKeys.filter(k => baseFlatValues[k] !== langFlatValues[k]);
        setSelectedKeys(diff);

    }, [baseFlatKeys, baseFlatValues, languagesFlatValues])

    return <NpiDisplayLayoutContent>
        { loading
            ? <Spin/>
            : <>
                <div style={{marginBottom: 20, paddingBottom: 15, borderBottom: "1px solid lightgray"}}>
                    <div style={{marginBottom: 10}}>
                        <Space>
                            <FlagOutlined/> Languages :

                            <Segmented value={currentMode} options={[
                                {value: 'internal', label: `Internal (${internalLanguages.length})`},
                                {value: 'external', label: `External (${externalLanguages.length})`},
                                {value: 'mix', label: `Custom (${selectedLangs.length})`, disabled: selectedLangs.length === 0},
                            ]} onChange={v => setCurrentMode(v as string)}/>

                            <Select
                                    value={selectedLangs}
                                    onChange={(v) => {
                                        setSelectedLangs(v);
                                        setCurrentMode(v.length > 0 ? 'mix' : 'internal');
                                    }}
                                    allowClear
                                    mode={"multiple"}
                                    options={languages?.map(l => ({value: l.code, label: l.name}))}
                                    placeholder={`Select languages...`}
                                    showSearch
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    style={{minWidth: 200}}
                                />
                        </Space>
                    </div>

                    <Space>

                        {/*find buttons*/}
                        <Button onClick={findNewKeys} icon={<SearchOutlined />}>Find new keys</Button>
                        <Button onClick={() => setSelectedKeys(missingKeys)} icon={<SearchOutlined />}>Find missing translations ({missingKeys.length})</Button>

                        <Dropdown.Button type="primary"
                                         disabled={checkedKeys.length === 0}
                                         children={<>Export selection ({checkedKeys.length})</>}
                                         loading={loadingDownload || loadingZip}
                                         icon={<DownOutlined/>}
                                         onClick={() => exportSelection(checkedKeys)}
                                         overlay={<Menu items={[
                                             {key: "export-zip", label: "Export as Zip (all)", onClick: () => exportSelectionAsZip(checkedKeys)},
                                             {key: "export-zip-empty", label: "Export as Zip (only empty keys)", onClick: () => exportSelectionAsZip(checkedKeys, true)},
                                         ]}/>}
                        />

                        {/*".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, zip, application/octet-stream, application/zip, application/x-zip, application/x-zip-compressed"*/}
                        <Upload beforeUpload={importFiles} showUploadList={false} accept={[...xlsTypes, ...zipTypes].join(', ')}>
                            <Button type="primary" icon={<UploadOutlined/>} loading={loadingUpload || loadingImport}>Import Partial Files</Button>
                        </Upload>

                        <NpiDropdownEllipsis items={[
                            {key: "reload", icon: <ReloadOutlined/>, label: "Re-load languages", onClick: reloadTranslations},
                            {key: "find-diff", icon: <BranchesOutlined />, label: `Find diff with "${DEFAULT_LANG}"`, onClick: findDiffWithDefault},
                            {key: "fill-default", icon: <SwapOutlined/>, label: `Fill "${DEFAULT_LANG}" with default`, onClick: onFillDefault},
                            {key: "clear-deprecated", icon: <ClearOutlined />, label: `Clean old keys`, onClick: onClearDeprecated},
                            {key: "copy", icon: <CopyOutlined />, label: "Copy selection", onClick: () => setOpenModalCopy(true), disabled: checkedKeys.length === 0},
                            {key: "move", icon: <EditOutlined />, label: "Move selected key", onClick: onMoveKeyTo, disabled: checkedKeys.length !== 1},
                            {key: 'separator', disabled: true},
                            {key: "re-order", disabled: true, icon: <BlockOutlined/>, label: "Reorder keys (todo)", onClick: () => {}},
                            {key: "find-duplicate", disabled: true, icon: <BlockOutlined/>, label: "Find duplicated key (todo)", onClick: () => {}},
                        ]}/>
                    </Space>
                </div>

                <div style={{display: 'flex', gap: 50}}>

                    <div style={{width: 400, overflowY: "auto"}}>
                        <TreeTranslationKeys
                            keys={mapBase}
                            checkedKeys={checkedKeys}
                            setCheckedKeys={setCheckedKeys}
                            selectedKeys={selectedKeys}
                            setSelectedKeys={setSelectedKeys}
                            addNewKey={onAddNewKey}
                            baseFlatValues={baseFlatValues}
                        />
                    </div>

                    <div style={{flexGrow: 1}}>
                        <TableLanguageTranslation base={mapBase} translations={mapLanguages} languages={currentLanguages} keys={selectedKeys} onUpdateKey={onUpdateKey}/>
                    </div>
                </div>
            </>
        }

        <Modal
            open={openModalCopy}
            onCancel={() => setOpenModalCopy(false)}
            title={<>Copy Selection ({checkedKeys.length} keys)...</>}
            okButtonProps={{disabled: !copySource || copyTargets.length === 0}}
            onOk={copySelectionToLanguage}
            confirmLoading={loadingCopy}
        >
            <em>This will fill all empty values in the target languages, with values from the source language.</em>
            <Space>
                <Select
                    value={copySource}
                    onChange={setCopySource}
                    options={languages?.map(l => ({value: l.code, label: l.name}))}
                    placeholder="Copy from..."
                    showSearch
                    filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    style={{minWidth: 200}}
                />
                →
                <Select
                    mode={"multiple"}
                    value={copyTargets}
                    onChange={setCopyTargets}
                    options={languages?.map(l => ({value: l.code, label: l.name}))}
                    placeholder="To..."
                    showSearch
                    filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    style={{minWidth: 200}}
                />
            </Space>

        </Modal>

    </NpiDisplayLayoutContent>
};

export default NpiContainerDashboardTranslation;