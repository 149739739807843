import React, {Dispatch, useEffect, useState} from "react";
import {DatePicker} from "antd";
import {useControllableValue} from "ahooks";
import {DatePickerProps} from "antd/lib/date-picker";
import moment, {Moment} from "moment";
import {RangePickerDateProps} from "antd/lib/date-picker/generatePicker";

//The date format we are using
const dateFormat = 'YYYY-MM-DD HH:mm:ss';

type INpiInputDatePicker = {
    value?: string|undefined|null
    onChange?: Dispatch<string|undefined|null>
    pickerProps?: DatePickerProps
    disabled?: boolean
    useLocalUtc?: boolean
}

type INpiInputDateRangePicker = {
    value?: [Moment, Moment]|undefined|null
    onChange?: Dispatch<string|undefined|null>
    pickerProps?: RangePickerDateProps<Moment>
    disabled?: boolean
}

/**
 * A date picker that accepts our string date format and outputs the same one
 * @param pickerProps
 * @param disabled
 * @param props
 * @constructor
 */
const NpiInputDatePicker = ({pickerProps, useLocalUtc, disabled, ...props}:INpiInputDatePicker) => {
    const [value, setValue] = useControllableValue(props);
    const [momentValue, setMomentValue] = useState<Moment|null>();

    //Change our string when the moment date changes
    const onChange = (newValue:Moment|null) => {
        if(useLocalUtc){
            setValue(newValue?.subtract(moment().utcOffset(), "minutes").format(dateFormat));
        }
        else{
            setValue(newValue?.format(dateFormat));
        }
    };

    //Observe changes to our controlled value to forward it to our picker
    useEffect(() => {
        if(useLocalUtc){
            setMomentValue(value ? moment(value, dateFormat).add(moment().utcOffset(), "minutes") : null)
        }
        else{
            setMomentValue(value ? moment(value, dateFormat) : null)
        }
    }, [value, setMomentValue, useLocalUtc]);

    return <DatePicker value={momentValue as any} onChange={onChange as any} disabled={disabled} format="DD-MMM-YYYY" {...pickerProps}/>
};

/**
 * A date picker that accepts our string date format and outputs the same one
 * @param pickerProps
 * @param disabled
 * @param props
 * @constructor
 */
const NpiInputDateRangePicker = ({pickerProps, disabled, ...props}:INpiInputDateRangePicker) => {
    const [value, setValue] = useControllableValue(props);
    const [momentValue, setMomentValue] = useState<[Moment,Moment]|null>();

    //Change our string when the moment date changes
    const onChange = (newValue:[Moment, Moment]) => {
        if(newValue){
            setValue([newValue[0]?.format(dateFormat), newValue[1]?.format(dateFormat)]);
        }
        else{
            setValue(null)
        }
    };

    //Observe changes to our controlled value to forward it to our picker
    useEffect(() => setMomentValue(value ? [moment(value[0], dateFormat), moment(value[1], dateFormat)]: null), [value, setMomentValue]);

    // @ts-ignore
    return <DatePicker.RangePicker value={momentValue} onChange={onChange} disabled={disabled} {...pickerProps} format="DD-MMM-YYYY"/>
};

export {NpiInputDateRangePicker};

export default NpiInputDatePicker;