import React, {useContext, useMemo} from 'react';
import {Alert, Tooltip} from "antd";
import NpiPaginatedTable from "../paginated-table";
import {ITag, ITagPos} from "../../types/tag";
import {Trans, useTranslation} from "react-i18next";
import {numberFormat} from "../../helpers/toolbox";
import NpiInputSelectCountry from "../input/select-country";
import NpiDisplayCountry from "../display/country";
import {InfoCircleFilled} from "@ant-design/icons";
import NpiDisplayProgram from "../display/program";
import NpiInputEditableTableCell from "../input/editable-table-cell";
import {ColumnType} from "antd/lib/table";
import {useRequest} from "ahooks";
import npiApi from "../../services/api";
import {NpiInternalContext} from "../../contexts/internal-context";
import {CheckCircleFilled, CloseCircleFilled} from "@ant-design/icons/lib";
import colors from "../../services/colors";

interface IAdminTagPosList {
    tags: ITag[],
    loadingTags: boolean,
    posList: ITagPos[],
    loadingPos: boolean,
    fetcher: any
    onFiltersChange: any
    onTotalChange: any
}


//Some const used in the component below
const checkOk = <CheckCircleFilled style={{color: colors.green, fontSize: 16}}/>;
const checkNotOk = <CloseCircleFilled style={{color: colors.red, fontSize: 16}}/>;

const AdminTagPosList = ({tags, loadingTags, posList, loadingPos, fetcher, onFiltersChange, onTotalChange}:IAdminTagPosList) => {
    const {t} = useTranslation();
    const {user, isWW, programs} = useContext(NpiInternalContext);

    const {runAsync: updateTagByPosValue} = useRequest(npiApi.internal.tag.updateTagPosValue, {manual: true});

    //Columns for the POS table
    const columnsPos = useMemo<any>(() => [
        {title: t('INTERNAL.CLIENT.COMMON.APPLE_ID'), dataIndex: 'pos_id', sorter: true, filter: {mode: 'number', strict: true}, fixed:true},
        {title: t('INTERNAL.CLIENT.COMMON.HQ_ID'), dataIndex: 'hq_id', sorter: true, filter: {mode: 'number', strict: true}, fixed:true},
        {title: t('INTERNAL.CLIENT.COMMON.STORE_NAME'), dataIndex: 'pos_name', sorter: true, filter: {mode: 'text'}, fixed:true},
        {title: t('INTERNAL.CLIENT.COMMON.RESELLER_NAME'), dataIndex: 'reseller_name', sorter: true, filter: {mode: 'text'}},
        {title: t('INTERNAL.CLIENT.COMMON.CITY'), dataIndex: 'city', sorter: true, filter: {mode: 'text'}},
        {title: t('INTERNAL.CLIENT.COMMON.COUNTRY'), dataIndex: 'country_id',
            filter:{mode:'custom', input: <NpiInputSelectCountry inRegions={isWW ? [] : [user.region_id]} mode="multiple" dropdownMatchSelectWidth={false}/>},
            render: (v:number) => <NpiDisplayCountry id={v}/>, sorter: true,
        },
        {title: <>{t('INTERNAL.CLIENT.COMMON.PROGRAMS')} <Tooltip title={t('INTERNAL.CLIENT.COMMON.OPEN_PRECLOSING_PROGRAMS')}><InfoCircleFilled/></Tooltip></>, dataIndex: 'programs', sorter: true,
            filter: {mode: 'select', options: programs, fieldKey: 'search_programs', selectInString: true, title: t('INTERNAL.CLIENT.COMMON.PROGRAMS')},
            render: (v:string) => <NpiDisplayProgram value={v}/>,
        },
        {title: t('INTERNAL.CLIENT.TAGS.STATUS'), dataIndex: 'has_required_tags', align: 'center' as 'center', sorter: true,
            filter: {mode: 'select', options: [{label: <span>{checkNotOk} {t('INTERNAL.CLIENT.TAGS.MISSING_REQUIRED_TAGS')}</span>, value: 0}, {label: <span>{checkOk} {t('INTERNAL.CLIENT.TAGS.HAS_REQUIRED_TAGS')}</span>, value: 1}]},
            render: (v:number) => !!v
                ? <Tooltip title={t('INTERNAL.CLIENT.TAGS.POS_HAS_REQUIRED_TAGS')}>{checkOk}</Tooltip>
                : <Tooltip title={t('INTERNAL.CLIENT.TAGS.POS_MISSING_REQUIRED_TAGS')}>{checkNotOk}</Tooltip>
        },
        ...tags.map( (tag:ITag) => {
            const tagRules = tag?.config?.rules || [];
            const listTag = tagRules.find((r:any) => r.type === 'list');
            const optionsTag = listTag ? listTag.values?.map((v:string) => ({label: v, value: v})) : [];
            return {
                title: tag.name,
                className: 'tag',
                align: 'center',
                key: 'tags->'+tag.id,
                filter: {strict: true, fieldKey: `tags_lowercase->${tag.id}`, ...(listTag ? {mode: 'select', options: optionsTag, json: false} : {mode: 'text', json: true})},
                render: (tagPos:ITagPos) => <NpiInputEditableTableCell
                    defaultValue={tagPos.tags?.[tag.id] as any}
                    onSave={(value: string) => updateTagByPosValue(tagPos.pos_id, tag.id, value)}
                    {...(listTag ? {
                            inputType: 'select',
                            options: optionsTag,
                            allowClear: true,
                            style: {minWidth: 60},
                        } : {})}
                />
            } as ColumnType<ITag>;
        }),
    ], [tags, isWW, user.region_id, programs, updateTagByPosValue, t]);

    //Config for the table to display the list of POS with their Tags for the current region
    const configTablePos = useMemo(() => ({
        rowKey: 'id',
        fetcher: fetcher,
        dataSource: posList,
        loading: loadingPos || loadingTags,
        scroll: {x: true as true, y: undefined},
        columns: columnsPos,
        onFiltersChange: onFiltersChange,
        onTotalChange: onTotalChange,
        pagination: {showTotal: (total:number) => <b style={{marginRight: 10}}>{t('INTERNAL.CLIENT.COMMON.TOTAL_ROWS', {nbRows: numberFormat(total)})}</b>},
    }), [fetcher, posList, loadingPos, loadingTags, columnsPos, onFiltersChange, onTotalChange, t]);


    return <>
        <div style={{marginBottom: 8, display: "flex", gap: 8, alignItems: "end", flexWrap: 'wrap'}}>
            <Alert message={<Trans i18nKey="INTERNAL.CLIENT.TAGS.CHANGES_WILL_AUTO_SPREAD" t={t} components={{ bold: <strong /> }}/>} style={{flexGrow: 1}}/>
        </div>
        <NpiPaginatedTable {...configTablePos}/>
    </>;
}

export default AdminTagPosList;