import {useCallback, useMemo} from "react";
import {ExternalTokenRight} from "../types/external-token";
import {useNpiExternalContext} from "../contexts/external-context";
import {generateExternalRoute} from "../helpers/router";

export default function useExternalToken() {

    const {token, tokenStr} = useNpiExternalContext();

    const hasRight = useCallback((right: ExternalTokenRight): boolean => {
        return !!token && token.rights.indexOf(right) >= 0
    }, [token]);

    const getHomeLink = useCallback((posId?:string|number): string => {
        if( hasRight(ExternalTokenRight.ACCESS_POS_LIST) ){
            if( hasRight(ExternalTokenRight.ACCESS_POS_SEARCH) ){
                return generateExternalRoute(`/hidden?token=${tokenStr}`)
            } else {
                return generateExternalRoute(`/list?token=${tokenStr}`)
            }
        } else {
            return generateExternalRoute(`/pos/${posId}?token=${tokenStr}`)
        }
    }, [hasRight, tokenStr]);

    const getPosLink = useCallback((posId:string|number, tabType?:string): string => {
        if(tabType) {
            return `/pos/${posId}/${tabType}?token=${tokenStr}`
        }
        return `/pos/${posId}?token=${tokenStr}`
    }, [tokenStr]);

    const hasReturnLink = useMemo<boolean>(() => hasRight(ExternalTokenRight.ACCESS_POS_LIST), [hasRight]);

    return {token, tokenStr, hasRight, hasReturnLink, getHomeLink, getPosLink};
}