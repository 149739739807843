import React, {useEffect, useState} from 'react';
import {Button, Form, Modal} from "antd";
import {ModalProps} from "antd/lib/modal";
import {FormProps} from "antd/lib/form";
import {useTranslation} from "react-i18next";

interface NpiModalEditType<T> extends ModalProps {
    value: T,
    onChange: (value:T)=>Promise<T>,
    button:any,
    editable?: boolean,
    onClose?: ()=>void,
    footerLeft ?: React.ReactNode,
    formProps?: FormProps,
    children:any,
}

/**
 * This is an editable modal which will update the controllable item when the save has been performed
 * @param request
 * @param postSave
 * @param button
 * @param children
 * @param props
 * @constructor
 */
const NpiModalEdit = ({value, onChange, button, children, editable=true, onClose=()=>{}, footerLeft=<></>, formProps={}, ...props}:NpiModalEditType<any>) => {
    const [form] = Form.useForm();
    const [visible, setVisible] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const {t} = useTranslation();

    const open = () => {
        if(editable){
            form.resetFields();
            form.setFieldsValue(value);
            setVisible(true);
        }
    }

    const onOk = () => {
        const newValue = {...value, ...form.getFieldsValue()};
        setLoading(true);
        onChange(newValue)
            .then(() => setVisible(false))
            .catch((e:any) => {
                // use with Laravel ValidationException
                if( e.status !== undefined && e.status === 422 && e.data.errors !== undefined ) {
                    const errors = Object.entries(e.data.errors).map(([key, value]) => {
                        return {name: key, errors: value as string[]};
                    });
                    form.setFields(errors);
                }
            })
            .finally(() => setLoading(false))
    }

    const onCancel = () => {
        setVisible(false);
        onClose();
    }

    const config = {
        open: visible,
        onOk,
        onCancel,
        maskClosable: false,
        width: 800,
        confirmLoading: loading,
        footer: [
            <div key="additional" style={{display: 'inline-block', float: 'left'}}>{footerLeft}</div>,
            <Button {...props.cancelButtonProps} key="back" onClick={onCancel}>{props.cancelText || t('COMMON.CANCEL')}</Button>,
            <Button type={'primary'} {...props.okButtonProps} loading={loading} key="submit" onClick={onOk}>{props.okText || t('COMMON.OK')}</Button>
        ],
        ...props,
    }

    useEffect(() => {
        form.setFieldsValue(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return <>
        <div onClick={open} style={{cursor: 'pointer'}}>{button}</div>
        <Modal {...config}>
            <Form form={form} labelCol={{span: 4}} wrapperCol={{span: 10}} {...formProps}>
                {children}
            </Form>
        </Modal>
    </>
}

export default NpiModalEdit;