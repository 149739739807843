import React from "react";
import npiApi from "../../services/api";
import SelectDebounced, {IDebounceSelectProps} from "./select-debounced";
import {useRequest} from "ahooks";
import {IWavePos} from "../../types/wave-pos";
import {useTranslation} from "react-i18next";

export interface ISelecPosProps<ValueType, T> extends Omit<IDebounceSelectProps<ValueType, T>, 'fetchOptions'> {
    filters?: any;
    limit ?: number;
}

/**
 * Remote search and select WavePos by their name or id
 * @see WavePos::filterByPayload to check the full list of available filters
 *
 * @param filters - filter wavepos by npiIds, waveIds...
 * @param limit
 * @param props
 *
 * {@see SelectDebounced} for more options and information
 */
const NpiInputSelectWavePos = ({filters, limit, ...props}:ISelecPosProps<any, IWavePos>) => {
    const {t} = useTranslation();
    const {runAsync: fetchWavePos, loading} = useRequest<IWavePos[],any>(npiApi.internal.wavepos.search, {manual: true});

    return (
        <SelectDebounced
            {...props}
            placeholder={props.placeholder || t("INTERNAL.CLIENT.POS.SELECT_POS")}
            loading={props.loading || loading}
            fetchOptions={search => fetchWavePos({search: search, limit: limit || 15, ...(filters || {})})}
            fetchInitial={props.fetchInitial || (value => fetchWavePos({ids: Array.isArray(value) ? value : (value ? [value] : []), ...(filters || {})}))}
            mappedBy={props.mappedBy || (wp => filters.is_partner ? wp.f_contract_id : wp.id)}
            display={props.display || (wp => filters.is_partner ? wp.f_company_name : (wp.pos_name || wp.f_trade_name || wp.f_legal_name || (wp.id+'')))}
        />
    );
}

export default NpiInputSelectWavePos;