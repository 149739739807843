import styled from "styled-components";
import {Input, InputProps, notification, Select} from "antd";
import React, {useCallback, useState} from "react";
import {SyncOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import {useControllableValue} from "ahooks";
import {SelectProps} from "antd/lib/select";

type INpiInputEditableTableCell<T> = {
    onSave: (value: T) => Promise<any>,
} & (({ inputType?: 'input' } & InputProps) | ({ inputType: 'select' } & SelectProps<T, any>));

const StyledEditableTableCellInput = styled(Input)`
    background: transparent;
`;

const StyledEditableTableCellSelect = styled(Select)`
    background: transparent;
`;

/**
 * Editable <Table> antd cell component
 * @param inputType
 * @param onSave
 * @param props InputProps
 * @constructor
 */
const NpiInputEditableTableCell = <T extends any>({inputType='input', onSave, ...props}: INpiInputEditableTableCell<T>) => {
    const [initialState, setInitialState] = useState<T>( (
        props.value !== undefined ? props.value : (props.defaultValue !== undefined ? props.defaultValue : null)
    ) as T);
    const [state, setState] = useControllableValue<T>(props);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const {t} = useTranslation();

    /**
     * Call onSave() callback at input blur
     */
    const handleSave = useCallback((v=null) => {

        let newValue = v;
        if( inputType === 'input' && typeof v === 'string' ) {
            newValue = v.trim() as T;
            if( newValue === '' ){
                newValue = null;
            }
        }

        //no changes from original value, we do nothing
        if(initialState === newValue){
            return;
        }

        setState(newValue)
        setIsSaving(true);

        onSave(newValue)
            .then(() => notification.success({message:t('INTERNAL.CLIENT.NPI.SUCCESSFULLY_CHANGED')}))
            .then(() => setInitialState(newValue))
            .catch(() => setState(initialState))
            .finally(() => setIsSaving(false));

    }, [onSave, setState, initialState, setInitialState, inputType, t]);

    return inputType === 'select'
        ? <StyledEditableTableCellSelect
            value={state}
            onChange={v => handleSave(v as T)}
            suffixIcon={isSaving && <SyncOutlined spin/>}
            dropdownMatchSelectWidth={false}
            {...props as SelectProps<any, any>}
        />

        : <StyledEditableTableCellInput
            value={state as (string | number | readonly string[] | undefined)}
            onChange={e => setState(e.target.value as T)}
            onBlur={e => handleSave(e.target.value as T)}
            suffix={isSaving ? <SyncOutlined spin/> : <span/>}
            {...props as InputProps}
        />
}


export default NpiInputEditableTableCell;