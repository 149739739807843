import React, {useContext, useMemo} from 'react';
import {Tabs, Row, Col, Badge, Alert} from "antd";
import {QuestionCircleOutlined, TranslationOutlined} from '@ant-design/icons';
import {observer} from "mobx-react";
import DirectionsFork from '@2fd/ant-design-icons/lib/DirectionsFork';
import colors from "../../services/colors";
import {INpi} from "../../types/npi";
import NpiConfigFormQuestions from "../config/form-questions";
import npiApi from "../../services/api";
import NpiConfigFormSimulation from "../config/form-simulation";
import {useNpiStore} from "../../contexts/stores";
import NpiConfigFormConditions from "../config/form-conditions";
import NpiConfigFormTranslations from "../config/form-translations";
import {NpiInternalContext} from "../../contexts/internal-context";
import _ from "lodash";
import {useTranslation} from "react-i18next";

const NpiConfigTabForm = observer(({npi}:{npi:INpi}) => {

    const defaultItem = {npi_id: npi.id};
    const {t} = useTranslation();
    const store = useNpiStore();
    const {languages} = useContext(NpiInternalContext);
    const defaultLanguageId = 1;

    //All languages except the default one
    const translationIds = useMemo(() => _.map(languages, 'id').filter(v => defaultLanguageId!==v), [languages]);

    const tabs = [{
        key: '1',
        label: <><QuestionCircleOutlined /> {t('INTERNAL.CLIENT.COMMON.QUESTIONS')}</>,
        children: <NpiConfigFormQuestions item={npi} defaultItem={defaultItem} saveQuestion={npiApi.internal.npi.saveQuestion}/>,
    },{
        key: '2',
        label: <><DirectionsFork /> {t('INTERNAL.CLIENT.COMMON.CONDITIONS')} <Badge count={npi.conditions?.length} style={{backgroundColor: colors.blue}}/></>,
        children: <NpiConfigFormConditions item={npi} store={store}/>
    },{
        key: '3',
        label: <><TranslationOutlined /> {t('INTERNAL.CLIENT.COMMON.TRANSLATIONS')}</>,
        children: <NpiConfigFormTranslations item={npi} languageId={defaultLanguageId} fileImport={npiApi.internal.file.importQuestionTranslationsNpi} translationIds={translationIds} store={store}/>,
    },];

    return <Row gutter={[20, 20]}>
        <Col span={24}>
            <Alert message={<span>{t('INTERNAL.CLIENT.NPI.ON_WAVE_CREATION_QUESTION_CONDITION_WILL_BE_COPIED')}</span>}/>
        </Col>
        <Col span={12}>
            <h1>{t('INTERNAL.CLIENT.COMMON.FORM_CONFIGURATION')}</h1>
            <Tabs size="large" destroyInactiveTabPane items={tabs}/>
        </Col>
        <Col span={12}>
            <h1>{t('INTERNAL.CLIENT.COMMON.FORM_SIMULATION')}</h1>
            <NpiConfigFormSimulation item={npi} npiId={npi.id}/>
        </Col>
    </Row>
});

export default NpiConfigTabForm;