import moment, {Moment} from "moment";
import NpiLanguageContext from "../contexts/language-context";
import {useCallback, useContext} from "react";

interface INpiDisplayDateStr {
    value: string|Moment
    hours?:'none'|'inline'
    format?: string
    formatHours?: string
    locale?: string
    utcToTimezone?: boolean
}

export default function useDisplayDateStr() {
    const {currentLang} = useContext(NpiLanguageContext);

    const formatDate = useCallback(({value, hours='none', format='DD-MMM-YYYY', formatHours='HH:mm', locale, utcToTimezone=false}:INpiDisplayDateStr): string => {
        const currentLocale = locale ? locale : currentLang.code;

        let timezoneValue;
        if( utcToTimezone ) {
            const utc = moment.tz(value, 'UTC');
            const timezone = moment.tz.guess();
            timezoneValue = utc.clone().tz(timezone);
        } else {
            timezoneValue = moment(value);
        }

        const localeValue = currentLocale !== undefined ? timezoneValue.locale(currentLocale) : timezoneValue;

        return localeValue.format(format) + (hours !== 'none' ? ' '+localeValue.format(formatHours) : '')
    }, [currentLang]);

    return {formatDate};
}