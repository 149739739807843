import React, {useContext, useEffect, useMemo, useState} from "react";
import {NpiInternalContext} from "../../contexts/internal-context";
import {Select, TreeSelect} from "antd";
import {useControllableValue} from "ahooks";
import NpiDisplayCountry from "../display/country";
import {SelectProps} from "antd/lib/select";
import _ from "lodash";
import {REGION_WW} from "../../helpers/constants";
import {useTranslation} from "react-i18next";

interface ISelectCountryProps extends SelectProps<any> {
    inRegions?: number|number[]
    inIdsList?: number[]
    grouped?:boolean
    restrict?:boolean
    defaultAllSelected?:boolean
    selectAllOption?:boolean
}


/**
 * Select list of countries
 * Can be restricted by regions
 *
 * @param {number[]|null} inRegions
 * @param inIdsList
 * @param restrict
 * @param grouped
 * @param defaultAllSelected
 * @param selectAllOption
 * @param style
 * @param props
 */
const NpiInputSelectCountry = ({inRegions, inIdsList, restrict=false, grouped=true, defaultAllSelected=false, selectAllOption=false, style, ...props}:ISelectCountryProps) => {
    const {countries, loaded, user} = useContext(NpiInternalContext);
    const [options, setOptions] = useState<any[]>([]);
    const {t} = useTranslation();
    const [state, setState] = useControllableValue<null|number|number[]>(props, {
        defaultValue: [],
    });

    const filterCountries = useMemo(() => {
        let result = countries;

        //Filter out countries which have no region_id
        result = result.filter(c => c.region_id>0);

        // filter countries by a list of region ids
        if(!!inRegions){
            if( Array.isArray(inRegions) && inRegions.length > 0){
                result = result.filter(c => inRegions.includes(c.region_id));
            } else if (!Array.isArray(inRegions)){
                result = result.filter(c => inRegions === c.region_id);
            }
        }
        // filter countries by a list of ids
        if(inIdsList && inIdsList.length){
            result = result.filter(c => inIdsList.includes(c.id));
        }
        //filter countries by the current user's region
        if(restrict && user.region_id !== REGION_WW){
            result = result.filter(c => user.region_id === c.region_id);
        }

        return result;
    }, [countries, user.region_id, restrict, inIdsList, inRegions]);

    useEffect(() => {

        let options = filterCountries.map(c => ({
            countryname: c.name,
            label: <NpiDisplayCountry id={c.id}/>,
            title: <NpiDisplayCountry id={c.id}/>,
            value: c.id,
            pId: c.sub_region,
        }));

        // filter selected options by available countries
        if( Array.isArray(state) ) {
            setState(state.filter((c_id:number) => options.map(c => c.value).includes(c_id)));
        } else if( state ) {
            setState(options.map(c => c.value).includes(state) ? state : null);
        }

        //Group countries (if requested)
        if(grouped){
            const regions = _.chain(filterCountries).map('sub_region').uniq().filter().sort().reverse().value();
            if(regions.length > 1){
                regions.forEach(r => options.unshift({
                    countryname: r,
                    value: r as any,
                    title: r as any,
                    label: r as any,
                    pId: '',
                }));
            }
        }

        //Update all ids list
        const allIds = _.chain(options).map('value').filter(v => _.isInteger(v)).value();
        if(selectAllOption){
            options.unshift({
                title: <span
                    onClick={() => setState(allIds)}
                    style={{
                        display: "inline-block",
                        color: "#286FBE",
                        cursor: "pointer"
                    }}
                >
                  Select all
                </span>,
                value: 'xxx',
                disableCheckbox: true,
                disabled: true
            } as any);
        }

        //Update options list
        setOptions(options);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterCountries]);


    const filterOnChange = (datas:any) => {
        setState(datas);
    };

    let config;
    if( ! options.length){
        return <Select loading disabled style={{minWidth: 150, ...style}}/>
    }
    else if(grouped){
        config = {
            placeholder: props.mode === 'multiple' ? t('INTERNAL.CLIENT.COMMON.COUNTRIES')+'...' : t('INTERNAL.CLIENT.COMMON.COUNTRY')+'...',
            ...props as any,
            treeData: options,
            treeDataSimpleMode: {id:'value'},
            treeCheckable: true,
            treeNodeFilterProp: 'countryname',
            loading: !loaded,
            value: state,
            onChange: filterOnChange,
            multiple: props.mode === 'multiple',
            showSearch: true,
            style: {minWidth: 150, ...style},
            popupClassName: 'hide-disabled-checkbox',
        };
        return <TreeSelect {...config}/>;
    }
    else{
        config = {
            placeholder: props.mode === 'multiple' ? t('INTERNAL.CLIENT.COMMON.COUNTRIES')+'...' : t('INTERNAL.CLIENT.COMMON.COUNTRY')+'...',
            ...props,
            showSearch: true,
            optionFilterProp: "countryname",
            loading: !loaded,
            options,
            value: state,
            onChange: filterOnChange,
            style: {minWidth: 150, ...style},
        };

        return <Select {...config}/>;
    }
};

export default NpiInputSelectCountry;