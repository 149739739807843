import React from "react";
import npiApi from "../../services/api";
import SelectDebounced, {IDebounceSelectProps} from "./select-debounced";
import { IWave } from "../../types/wave";
import {useRequest} from "ahooks";
import {useTranslation} from "react-i18next";

export interface ISelectWaveProps<ValueType, T> extends Omit<IDebounceSelectProps<ValueType, T>, 'fetchOptions'> {
    npis?: number[];
    limit ?: number;
    restrictToUserRegion?: boolean;
}

/**
 * Remote search and select multiple Waves by their name or id
 *
 * @param npis - filterd by NPI ids
 * @param limit
 * @param restrictToUserRegion
 * @param props
 *
 * {@see SelectDebounced} for more options and information
 */
const NpiInputSelectWave = ({npis, limit, restrictToUserRegion=true, ...props}:ISelectWaveProps<any, IWave>) => {

    const {runAsync: fetchWave, loading} = useRequest<IWave[], any>(npiApi.internal.wave.search, {manual: true});
    const {t} = useTranslation();

    return (
        <SelectDebounced
            {...props}
            placeholder={props.placeholder || t("INTERNAL.CLIENT.WAVE.SELECT_WAVE", {count: (props.mode === "multiple" ? 2 : 1)})}
            loading={props.loading || loading}
            fetchOptions={search => fetchWave({restrictToUserRegion, search: search, limit: limit || 15, npis: npis || []})}
            fetchInitial={props.fetchInitial || (value => fetchWave({restrictToUserRegion, npis: npis || [], ids: Array.isArray(value) ? value : (value ? [value] : [])}))}
            mappedBy={props.mappedBy || (wave => wave.id)}
            display={props.display || (wave => wave.name)}
        />
    );
}

export default NpiInputSelectWave;