import React from "react";
import _ from "lodash";
import RocketOutline from '@2fd/ant-design-icons/lib/RocketOutline';
import RocketLaunch from '@2fd/ant-design-icons/lib/RocketLaunch';
import Timer from '@2fd/ant-design-icons/lib/Timer';
import Garage from '@2fd/ant-design-icons/lib/Garage';
import Archive from '@2fd/ant-design-icons/lib/Archive';
import styled from "styled-components";
import colors from "../../services/colors";
import {IWave, IWaveStatusEnum} from "../../types/wave";
import {Tooltip} from "antd";
import NpiDisplayDate from "./date";
import {useTranslation} from "react-i18next";

const {OPEN, LAUNCHED, CLOSED, ARCHIVED, DELAYED_LAUNCH} = IWaveStatusEnum;

const Div = styled.div`
    display: inline-block;
    .anticon{
        font-size: 24px;
        position: relative;
        top: 2px;
    }
`;

// WARNING : the names of the statuses are translation keys to add a name you have to add it to INTERNAL.CLIENT.COMMON. "name"
const statuses = [
    {id: OPEN, name: 'UNDER_CREATION', icon: <RocketOutline/>, color: colors.yellow},
    {id: LAUNCHED, name: 'LAUNCHED', icon: <RocketLaunch/>, color: colors.green},
    {id: CLOSED, name: 'CLOSED', icon: <Garage/>, color: colors.red},
    {id: ARCHIVED, name: 'ARCHIVED', icon: <Archive/>, color: colors.darkred},
    {id: DELAYED_LAUNCH, name: 'AWAITING_LAUNCH', icon: <Timer/>, color: colors.blue},
];

const NpiDisplayWaveStatus = ({id, style={}, wave} : {id:number, style?:{}, wave?:IWave}) => {
    const {t} = useTranslation();
    let message = null;
    const status = _.find(statuses, {id});
    if( ! status){
        return <>???</>;
    }
    if(id === DELAYED_LAUNCH && wave){
        message = <span><NpiDisplayDate value={wave.delay_launch_at} hours="inline"/> UTC</span>;
    }
    const {color, icon, name} = status;
    return <Tooltip title={message}><Div style={{color, ...style}}>{icon} {t(`INTERNAL.CLIENT.COMMON.${name}`)}</Div></Tooltip>;
};

export default NpiDisplayWaveStatus;