import React, {useMemo} from 'react';
import {CheckOutlined, CloseOutlined, WarningOutlined} from "@ant-design/icons";
import {Badge, Modal, Space, Spin, Table, Tooltip} from "antd";
import npiApi, {npiApiDownloadFileCallback} from "../../services/api";
import {useRequest} from "ahooks";
import {useTranslation} from "react-i18next";
import _ from "lodash";


export interface ISyncStatus {
    loading: boolean;
    hasError: boolean;
    error ?: string;
    match: boolean;
    internal?: {count: number, sig: string};
    external?: {count: number, sig: string};
}

const COLOR_ERROR = '#f82d23';
const COLOR_SUCCESS = '#53c42a';

// Display status (success or error) with tooltip for each table
const NpiDashboardSyncTooltipStatus = ({status, simple=false, detailsParams=null}:{ status:ISyncStatus, simple?:boolean, detailsParams?: any }) => {
    const {t} = useTranslation();

    const {runAsync: downloadComparison, loading: loadingFile} = useRequest(npiApi.internal.file.exportSynchronizationChecker, {manual: true});
    const {runAsync: fetchDetail, loading: loadingModal} = useRequest(npiApi.internal.dev.synchro.status.details, {manual: true});

    const {download:detailDownload, table:detailTable, id:waveId} = detailsParams || {};
    const loading = useMemo(() => loadingModal || loadingFile || (status && status.loading), [loadingModal, loadingFile, status]);


    // Download a xls file with all lines with errors
    const exportComparison = () => {
        downloadComparison({waveId, table:detailTable}).then(npiApiDownloadFileCallback('sync-check-'+waveId+'-'+detailTable+'.xlsx'));
    };

    // Open a modal to show all data in DB from Internal & External for one given line.
    const openDetailModal = () => {
        fetchDetail({table:detailTable, id: simple ? waveId : undefined, wave_id: simple ? undefined : waveId})
            .then((data : any) => {
                const syncFields = data.synchronizable_fields;

                Modal.info({
                    title: t('INTERNAL.CLIENT.COMMON.LINE_DETAILS') + ' ' + JSON.stringify(detailsParams),
                    maskClosable: true,
                    width: 1000,
                    content: <>
                        <Space>
                            <Badge color="#FF7875" size="default" text={t('INTERNAL.CLIENT.COMMON.ERROR')} />
                            <Badge color="#B0BCC0" size="default" text={t('INTERNAL.CLIENT.COMMON.MISSING_DATAS')} />
                            <Badge color="#1AB6C0" size="default" text={t('INTERNAL.CLIENT.COMMON.NOT_SYNCHRONIZABLE')} />
                        </Space>
                        <Table
                            style={{marginTop: 15}}
                            size={'small'}
                            rowKey={(r, index) => `${r.fromColumn}-${r.uniqueId}-${index}`}
                            columns={[
                                {title: t('#uniqueId'), dataIndex: 'uniqueId', fixed: 'left', width: 100, render: (v:string, record:any) => ({
                                    children: <b>{v}</b>,
                                    props: record['fromColumn'] === 'internal' ? { rowSpan: 2, style: {borderBottomWidth: 4} } : { rowSpan: 0 }
                                })},
                                {title: t('INTERNAL.CLIENT.COMMON.FROM'), dataIndex: 'fromColumn', fixed: 'left', width: 100, render: (v:string, record:any) => ({
                                    children: <b>{v}</b>,
                                    props: record['fromColumn'] === 'internal' ? {} : {style: {borderBottomWidth: 4}}
                                })},
                                ...data.columns.filter((c:string) => !['uniqueId', 'fromColumn', 'signature'].includes(c)).map((c:any) => (
                                    {title: c, dataIndex: c, width: 150, ellipsis: true, render: (v:any, record: any, index:number) => {

                                        const isSyncField = syncFields.includes(c);
                                        const isInternal = record['fromColumn'] === 'internal';
                                        const res = _.pickBy(data.rows[index], (value, key) => key !== 'fromColumn');
                                        const resOther = _.pickBy(isInternal ? data.rows[index+1] : data.rows[index-1], (value, key) => key !== 'fromColumn');
                                        const isEmpty =  Object.values(res).length === 0;
                                        const isEmptyOther =  Object.values(resOther).length === 0;
                                        const isDifferent = isSyncField && !isEmpty && !isEmptyOther && JSON.stringify(res[c]) !== JSON.stringify(resOther[c]);

                                        return {
                                            children: <div>{v === null ? <span style={{color: 'lightgray'}}>(NULL)</span>: JSON.stringify(v)}</div>,
                                            props: {
                                                style: {
                                                    background: (
                                                        isEmpty ? "#B0BCC054" : (
                                                            !isSyncField ? "#1AB6C054" : (
                                                                isDifferent ? "#FF787554" : "inherit"
                                                            )
                                                        )
                                                    ),
                                                    ...record['fromColumn'] === 'internal' ? {} : {borderBottomWidth: 4}
                                                }
                                            },
                                        }
                                    }}
                                ))
                            ]}
                            dataSource={data.rows}
                            scroll={{ x: 1000 }}
                        />

                    </>
                });
            })
    };

    const propsIcon = (detailsParams ? {onClick: detailDownload ? exportComparison : openDetailModal} : {});

    if( loading ) return <Spin/>;
    if( !status ) return <>-</>;

    if( status.hasError || (!simple && (status.internal === undefined || status.external === undefined))) {
        return <Tooltip title={status.error || t('INTERNAL.CLIENT.COMMON.SOMETHING_IS_WRONG')} {...propsIcon}>
            <WarningOutlined style={{color: COLOR_ERROR}}/>
        </Tooltip>;
    }

    if( simple ) {
        return status.match ? <CheckOutlined style={{color: COLOR_SUCCESS}} {...propsIcon}/> : <CloseOutlined style={{color: COLOR_ERROR}} {...propsIcon}/>;
    }

    if( status.match && status.internal && status.internal.count === 0 ) {
        return <>-</>;
    }

    return <Tooltip placement={'right'} title={<>
        <strong>{t('INTERNAL.CLIENT.COMMON.COUNT')}</strong>
        <ul>
            <li>{t('INTERNAL.CLIENT.COMMON.INTERNAL')} : {status.internal ? status.internal.count : '-'}</li>
            <li>{t('INTERNAL.CLIENT.COMMON.EXTERNAL')} : {status.external ? status.external.count : '-'}</li>
        </ul>
    </>} {...propsIcon}>
        {status.match ? <CheckOutlined style={{color: COLOR_SUCCESS}}/> : <CloseOutlined style={{color: COLOR_ERROR}}/>}
    </Tooltip>
};

export default NpiDashboardSyncTooltipStatus;