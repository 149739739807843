import React, {useEffect, useState} from "react";
import {Space, Tooltip} from "antd";
import {IQuestion} from "../../types/question";
import Asterisk from '@2fd/ant-design-icons/lib/Asterisk';
import Calendar from '@2fd/ant-design-icons/lib/Calendar';
import BlockHelper from '@2fd/ant-design-icons/lib/BlockHelper';
import DirectionsFork from '@2fd/ant-design-icons/lib/DirectionsFork';
import ChartPie from '@2fd/ant-design-icons/lib/ChartPie';
import PowerSleep from '@2fd/ant-design-icons/lib/PowerSleep';
import EyeOff from '@2fd/ant-design-icons/lib/EyeOff';
import colors from "../../services/colors";
import {ICondition} from "../../types/condition";
import styled from "styled-components";
import {IKpi} from "../../types/kpi";
import _ from "lodash";
import {useTranslation} from "react-i18next";

const StyledSpace = styled(Space)`
  .anticon{
    font-size: 18px;
  }
`;

interface INpiDisplayQuestionTags {
    question: IQuestion
    conditions: ICondition[]
    kpis: IKpi[]
}

/**
 * This will display helpful tags about the question
 * @param question
 * @param conditions
 * @param props
 * @constructor
 */
const NpiDisplayQuestionTags = ({question, conditions, kpis} : INpiDisplayQuestionTags) => {
    const {id, is_date_required, is_mandatory, is_blocking, is_status_neutral} = question;
    const [counters, setCounters] = useState({inRules: 0, inActions: 0, kpis: [] as IKpi[]});
    const {t} = useTranslation();

    //Tooltips stored here for clarity
    const tt = {
        rules: t('INTERNAL.CLIENT.TAGS.TOOLTIP.RULES'),
        actions: t('INTERNAL.CLIENT.TAGS.TOOLTIP.ACTIONS'),
        kpis: t('INTERNAL.CLIENT.TAGS.TOOLTIP.KPIS'),
        date_required: t('INTERNAL.CLIENT.TAGS.TOOLTIP.DATE_REQUIRED'),
        mandatory: t('INTERNAL.CLIENT.TAGS.TOOLTIP.MANDATORY'),
        blocking: t('INTERNAL.CLIENT.TAGS.TOOLTIP.BLOCKING'),
        status_neutral: t('INTERNAL.CLIENT.TAGS.TOOLTIP.STATUS_NEUTRAL'),
    };

    //Observe conditions changes to update our tags when necessary
    useEffect(() => {
        const counters = {inRules: 0, inActions: 0, kpis: [] as IKpi[]};
        conditions?.forEach(condition => {
            condition.rules.forEach(rule => {
                counters.inRules+= rule.questionId === id ? 1:0
            });
            condition.actions.forEach(a => {
                counters.inActions+= a.answerId?.[0] === id || a.questionId === id ? 1:0;
            })
        });
        kpis.forEach(kpi => {
            kpi.conditions?.rules.forEach(rule => {
                if(rule.questionId === id){
                    counters.kpis.push(kpi)
                }
            })
        })
        counters.kpis = _.uniqBy(counters.kpis, 'id');

        setCounters(counters);
    }, [conditions, kpis, id]);

    return <StyledSpace>
        {counters.kpis.length > 0 &&
            <Tooltip title={<>{t('INTERNAL.CLIENT.TAGS.QUESTION_USED_IN_KPIS')} <ul>{counters.kpis.map((k,n)=><li key={n}>{k.name}</li>)}</ul></>}>
                <ChartPie style={{color: colors.purple}}/>
            </Tooltip>
        }
        {counters.inRules > 0 && <Tooltip title={tt.rules}><DirectionsFork style={{color: colors.blue}}/></Tooltip>}
        {counters.inActions > 0 && <Tooltip title={tt.actions}><EyeOff style={{color: colors.blue}}/></Tooltip>}
        {!!is_status_neutral && <Tooltip title={tt.status_neutral}><PowerSleep style={{color: colors.greyDisabledText}}/></Tooltip>}
        {!!is_date_required && <Tooltip title={tt.date_required}><Calendar/></Tooltip>}
        {!!is_mandatory && !is_blocking && <Tooltip title={tt.mandatory}><Asterisk style={{color: colors.red}}/></Tooltip>}
        {!!is_blocking && <Tooltip title={tt.blocking}><BlockHelper style={{color: colors.yellow}}/></Tooltip>}
    </StyledSpace>
};

export default NpiDisplayQuestionTags;