import React, {useContext, useEffect, useState} from "react";
import {NpiInternalContext} from "../../contexts/internal-context";
import {Select} from "antd";
import {useControllableValue} from "ahooks";
import NpiDisplayLanguage from "../display/language";
import {SelectProps} from "antd/lib/select";

interface INpiInputSelectLanguage extends SelectProps<any> {
    inRegion?: number|null;
}

const NpiInputSelectLanguage = ({style, inRegion= null, ...props}:INpiInputSelectLanguage) => {
    const {languages, loaded} = useContext(NpiInternalContext);
    const [options, setOptions] = useState<any[]>([]);
    const [state, setState] = useControllableValue<null|number|number[]>(props, {
        defaultValue: [],
    });

    useEffect(() => {
        const options = languages
            .filter(r => !(inRegion && r.regions && r.regions.indexOf(inRegion) < 0)) //filter to show only languages in the region, if restriction is present
            .map(r => ({
                label: <NpiDisplayLanguage id={r.id}/>,
                value: r.id,
                name: r.name,
            }))
                .sort((a,b) => a.name<b.name?-1:1)
            ;
        setOptions(options);
    }, [languages, inRegion]);

    const config = {
        loading: !loaded,
        options,
        value: state,
        onChange: setState,
        filterOption: (search:any, value:any) => value.name.search(new RegExp(search, 'i'))>=0,
        ...props,
        style: {minWidth: 150, ...style},
    };

    return <Select {...config}/>
};

export default NpiInputSelectLanguage;