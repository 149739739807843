import {IFormAnswerByQuestion} from "./question";
import {IWavePosImage} from "./wave-pos-image";
import {IShipment, IShipmentCarrier} from "./shipment";
import {ITagValue} from "./tag";

export interface IWavePos{
    id: number
    status: IWavePosStatusEnum
    pos_id?: number
    wave_id?: number
    pos_name ?: string
    region_id ?: number
    f_trade_name ?: string
    f_legal_name ?: string
    f_company_name ?: string
    f_contract_id ?: number
    country_id?: number
    merchandising_group_id?: number
}

export enum IWavePosStatusEnum{
    NEUTRAL,
    NEGATIVE,
    POSITIVE,
}

/**
 * Interface representing a WavePos in the External POS detail view
 */
export interface IExternalWavePos {
    pos_id: number,
    wave_id: number,
    status: IWavePosStatusEnum,
    tags: ITagValue,
    answers: IFormAnswerByQuestion, //IFormAnswer mapped by question ID
    images: IWavePosImage[],
    imageQuestions: Record<number, IWavePosImage[]>, //mapped by question ID
    dailyHistory?: WavePosDailyHistory[], // default is 10 days of history
    shipments: IShipment[],
    carriers: IShipmentCarrier[],
}

interface WavePosDailyHistory {
    wave_id: number,
    launched_at: string,
    status: IWavePosStatusEnum,
    last_submitted_at?: string,
    images: IWavePosImage[],
}

