import React, {useContext, useEffect} from 'react';
import NpiDisplayLayoutContent from "../../components/display/layout-content";
import {NpiInternalContext} from "../../contexts/internal-context";
import {Alert, Col, Row} from "antd";
import useRestrictedToAdmin from "../../hooks/use-restricted-to-admin";
import {useRequest} from "ahooks";
import npiApi from "../../services/api";
import { InfoCircleFilled } from '@ant-design/icons';
import {useTranslation} from "react-i18next";


const NpiContainerAdminTableauConnect = () => {
    useRestrictedToAdmin();
    const {setBreadcrumbs} = useContext(NpiInternalContext);
    const {t} = useTranslation();
    const {data} = useRequest<any,any>(npiApi.internal.tableau.information);

    //Set navigation
    useEffect(() => setBreadcrumbs([
        {name: t('INTERNAL.CLIENT.COMMON.ADMINISTRATION')},
        {url: '/admin/tableau', name: t('INTERNAL.CLIENT.COMMON.TABLEAU')},
    ]), [setBreadcrumbs, t]);

    return <NpiDisplayLayoutContent>
        <h1>{t('INTERNAL.CLIENT.TABLEAU.TABLEAU_DESKTOP_CONNECTION')}</h1>
        <Row gutter={[20,20]}>
            <Col span={12}><img alt="screenshot" src={process.env.PUBLIC_URL + "/images/tableau-screen.png"} style={{width: '100%'}}/></Col>
            <Col style={{fontSize: 32}}>
                {t('INTERNAL.CLIENT.TABLEAU.FOLLOW_STEPS')}
                <ul>
                    <li>{t('INTERNAL.CLIENT.TABLEAU.OPEN_TABLEAU_DESKTOP')}</li>
                    <li>{t('INTERNAL.CLIENT.TABLEAU.CONNECT_MYSQL_DATABASE')}</li>
                    <li>{t('INTERNAL.CLIENT.TABLEAU.FILL_OUT_FORM')}
                        <ul>
                            <li>{t('INTERNAL.CLIENT.TABLEAU.SERVER')} <b>{data?.host}</b></li>
                            <li>{t('INTERNAL.CLIENT.TABLEAU.PORT')} <b>{data?.port}</b></li>
                            <li>{t('INTERNAL.CLIENT.TABLEAU.DATABASE')} <b>{data?.database}</b></li>
                            <li>{t('INTERNAL.CLIENT.TABLEAU.USERNAME')} <b>{data?.username}</b></li>
                        </ul>
                    </li>
                    <li>{t('INTERNAL.CLIENT.TABLEAU.REQUEST_PASSWORD')}</li>
                </ul>
            </Col>
        </Row>
        <br/>
        <Alert message={<span><InfoCircleFilled/> {t('INTERNAL.CLIENT.TABLEAU.DATA_REFRESHED')}</span>}/>
    </NpiDisplayLayoutContent>
};

export default NpiContainerAdminTableauConnect;