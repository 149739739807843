import React, {Dispatch, useEffect, useState} from "react";
import {IDashboardBlockConfiguration, IDashboardTableModeEnum} from "../../../types/dashboard";
import {Form, Input, Radio, Select, Switch, Table} from "antd";
import {useControllableValue} from "ahooks";
import {useForm} from "antd/es/form/Form";
import {IKpi} from "../../../types/kpi";
import NpiDisplayKpiName from "../../display/kpi-name";
import {EyeInvisibleOutlined, MenuOutlined} from "@ant-design/icons";
import {SortableContainer, SortableElement, SortableHandle} from "react-sortable-hoc";
import {TableProps} from "antd/lib/table";
import {arrayMoveImmutable} from "array-move";
import {orderKpiByDashboardConfig} from "../../display/kpi-table";
import {useTranslation} from "react-i18next";

const {PROGRAM, REGION, COUNTRY, PARTNER} = IDashboardTableModeEnum;

interface INpiInputKpiDashboardBlockConfigPieChart {
    block:IDashboardBlockConfiguration
    kpis:IKpi[]
    onChange: Dispatch<IDashboardBlockConfiguration>
}



const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);
const SortableItem = SortableElement(({style, ...props}:any) => <tr {...props} style={{...style, zIndex: 5000}}/>);
const SortableBody = SortableContainer((props:any) => <tbody {...props} />);


interface INpiInputSortableTable<RecordType> extends TableProps<RecordType>{
    onSortEnd: (value: RecordType[]) => void
}

const NpiInputSortableTable = <RecordType extends object = any>(props: INpiInputSortableTable<RecordType>) => {
    const config = {...props};
    const {dataSource = [], rowKey, onSortEnd} = props;

    config.components = {
        body:{
            wrapper: (props:any) => <SortableBody
                useDragHandle
                disableAutoscroll
                helperClass="row-dragging"
                onSortEnd={onSortFinish}
                {...props}
            />,
            row: ({ className, style, ...restProps }:any) => {
                // function findIndex base on Table rowKey props and should always be a right array index
                const index = dataSource?.findIndex((x:any) => x[rowKey as string] === restProps['data-row-key']);
                return <SortableItem index={index} {...restProps} />;
            },
        },
    };



    //Track changes to sorting
    const onSortFinish = ({ oldIndex, newIndex }:any) => {
        if (oldIndex !== newIndex) {
            const newData = arrayMoveImmutable(dataSource, oldIndex, newIndex).filter(
                (el:any) => !!el,
            );

            onSortEnd(newData);
        }
    };



    return <Table {...config}/>
}


interface IOptionKpi {
    label: string,
    value: number
}

const NpiInputKpiDashboardBlockConfigTable = ({kpis, ...props}:INpiInputKpiDashboardBlockConfigPieChart) => {
    const [block, setBlock] = useControllableValue(props, {valuePropName: "block"});
    const {t} = useTranslation();
    const [optionsKpis, setOptionsKpis] = useState<IOptionKpi[]>(kpis
        .sort((a: IKpi, b: IKpi) => orderKpiByDashboardConfig(a, b, block.config))
        .map(k => ({label: k.name, value: k.id}))
    );
    const [form] = useForm();

    const optionsRadio = [
        {label: t('INTERNAL.CLIENT.COMMON.NUMBER_OF_POS'), value: 'VALUE'},
        {label: t('INTERNAL.CLIENT.COMMON.PERCENT_OF_POS'), value: 'PERCENT'},
    ];

    const optionsMode = [
        {label: t('INTERNAL.CLIENT.COMMON.PROGRAMS'), value: PROGRAM},
        {label: t('INTERNAL.CLIENT.COMMON.REGIONS'), value: REGION},
        {label: t('INTERNAL.CLIENT.COMMON.COUNTRIES'), value: COUNTRY},
        {label: t('INTERNAL.CLIENT.COMMON.PARTNERS'), value: PARTNER},
    ];

    const config = {
        columns: [
            {title: t('INTERNAL.CLIENT.COMMON.SORT'), width: 30, className: 'drag-visible', render: () => <DragHandle/>,},
            {title: t('INTERNAL.CLIENT.NPI.KPI'), render: (row:IOptionKpi)=> <NpiDisplayKpiName kpi={kpis.find(kpi => kpi.id === row.value) as IKpi}/>},
            {title: <>{t('INTERNAL.CLIENT.COMMON.DISPLAY')}</>, render: (row:IOptionKpi) => <Input onBlur={(e) => onChangeDisplayName(row, e.target.value)} defaultValue={block.config?.displayNames?.[row.value]} placeholder={row.label}/>},
            {title: <><EyeInvisibleOutlined/> {t('INTERNAL.CLIENT.COMMON.HIDE')}</>, render: (row:IOptionKpi) => <Switch onChange={(value: boolean) => onChangeHidden(row, value)} checked={block.config.removedKpis?.includes(row.value)}/>, align: 'center' as 'center'}
        ],
        dataSource: optionsKpis,
        pagination: false as false,
        rowKey: 'value'
    };


    /**
     * Called when sort end
     * @param optionsOrders IOptionKpi[]
     */
    const onSortEnd = (optionsOrders: IOptionKpi[]) => {
        const orderedKpisId = optionsOrders.map(optionKpi => optionKpi.value);
        const updateConfig = {...block.config, orderedKpisId};

        setOptionsKpis(optionsOrders);
        setBlock({...block, config: updateConfig});
    }

    //Change visibility of a KPI
    const onChangeHidden = (row:IOptionKpi, value:boolean) => {
        const config = {...block.config};
        //contains id
        let removedKpis: number[] = [...(config.removedKpis??[])];

        if(value){
            removedKpis.push(row.value);
        }
        else{
            removedKpis = removedKpis.filter((kpiId: number) => kpiId !== row.value);
        }

        const updatedConfig = {...config, removedKpis};

        setBlock({...block, config: updatedConfig});
    };

    const onChangeDisplayName = (row:IOptionKpi, value:string) => {
        const config = {...block.config};
        let displayNames: Record<number, string> = {...(config.displayNames??{})};
        if(value) {
            displayNames[row.value] = value;
        }
        else {
            delete displayNames[row.value];
        }
        const updatedConfig = {...config, displayNames};
        setBlock({...block, config: updatedConfig});
    }


    //
    const onValuesChange = (values:any) => {
        const config = {...block.config, ...values.config}
        const newBlock = {...block, config};
        setBlock(newBlock);
    };

    //On load
    useEffect(() => {
        form.setFieldsValue(block);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);




    return <div>
        <Form form={form} onValuesChange={onValuesChange} layout="vertical">
            <Form.Item label={t('INTERNAL.CLIENT.COMMON.VIEW_MODE')} name={["config", "mode"]} initialValue={PROGRAM}>
                <Select options={optionsMode} placeholder={t('INTERNAL.CLIENT.COMMON.SELECT_VIEW_MODE')}/>
            </Form.Item>
            <Form.Item label={t('INTERNAL.CLIENT.COMMON.DISPLAY')} name={["config", "display"]} initialValue="VALUE">
                <Radio.Group options={optionsRadio} optionType="button" buttonStyle="solid"/>
            </Form.Item>
            <div style={{width: "100%", display: "flex", gap: 50}}>
                <Form.Item label={t('INTERNAL.CLIENT.KPI.DASHBOARD.TOTAL_POS')} name={["config", "show_total_pos"]} initialValue={true} valuePropName={"checked"}>
                    <Switch/>
                </Form.Item>
                <Form.Item label={t('INTERNAL.CLIENT.KPI.DASHBOARD.COMPLETION_PERCENT')} name={["config", "show_completion_percent"]} initialValue={true} valuePropName={"checked"}>
                    <Switch/>
                </Form.Item>
                <Form.Item label={t('INTERNAL.CLIENT.COMMON.CHART')} name={["config", "show_completion_chart"]} initialValue={true} valuePropName={"checked"}>
                    <Switch/>
                </Form.Item>
            </div>

            <NpiInputSortableTable onSortEnd={onSortEnd} {...config}/>
        </Form>
    </div>
};

export default NpiInputKpiDashboardBlockConfigTable;