import React from "react";
import {Tree, TreeProps} from "antd";
import {useTranslation} from "react-i18next";
import styled from "styled-components";

/**
 * Stylish the tree
 */
const StyledContainerTreeError = styled.div`
      padding: 10px;
      margin-top: 15px;
      margin-bottom: 100px;
      background-color: #fcf8e3 !important;

      .ant-tree, .ant-tree-node-content-wrapper:hover {
        background-color: #fcf8e3 !important;
      }
`;

/**
 * Display NpiInputForm tree error
 * @param props
 * @constructor
 */
const NpiDisplayNpiInputFormTreeError = (props: TreeProps) => {
    const {t, i18n} = useTranslation();

    return <StyledContainerTreeError>
        <p>{i18n.language ? t('EXTERNAL.CLIENT.POS_VIEW.UNABLE_TO_SAVE_CHANGES') : "Unable to save your changes, please check following error(s) :"}</p>
        <Tree {...props}/>
    </StyledContainerTreeError>
}


export default NpiDisplayNpiInputFormTreeError;