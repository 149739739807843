import React, {useEffect, useState} from 'react';
import {Space, Table, Tooltip} from "antd";
import npiApi from "../../services/api";
import {ISyncWavePos} from "./wave-details";
import questionsConfigurations from "../../services/questions-configurations";
import {IQuestionTypeEnum} from "../../types/question";
import {ClockCircleOutlined} from "@ant-design/icons";
import NpiDisplayDate from "../../components/display/date";
import {useRequest} from "ahooks";
import NpiDashboardSyncTooltipStatus, {ISyncStatus} from "./tooltip-status";
import {useTranslation} from "react-i18next";

export interface IQuestionAnswer {
    id: number;
    valueInternal: string;
    valueExternal: string;
    sync_status: ISyncStatus;
    questionType: IQuestionTypeEnum,
    questionWithDate: boolean,
    dateValueInternal: string,
    dateValueExternal: string,
}

const NpiContainerDashboardSyncPosDetails = ({wavePos}:{wavePos:ISyncWavePos}) => {

    const {t} = useTranslation();
    const [dataSource, setDataSource] = useState<IQuestionAnswer[]>([]);

    const {runAsync: wavePosAnswerStatus, loading} = useRequest<IQuestionAnswer[], any>(npiApi.internal.dev.synchro.status.wavePosAnswer, {
        manual: true,
        onSuccess: setDataSource,
    });

    // Update page when callback changes
    useEffect(() => {
        wavePosAnswerStatus({wavePosId: wavePos.id,}).then(null)
    }, [wavePos.sync_status, wavePos.id, wavePosAnswerStatus]);

    const columns = [
        { title: `#${t('INTERNAL.CLIENT.COMMON.ANSWER')} (w-p-q)`, dataIndex: 'id', render: (id:string, r:IQuestionAnswer) => <Space size={12}>
                {id}
                <Tooltip title={questionsConfigurations[r.questionType].name}>{questionsConfigurations[r.questionType].icon}</Tooltip>
                {r.questionWithDate ? <Tooltip title={t('INTERNAL.CLIENT.DASHBOARD.DATE_QUESTION')}><ClockCircleOutlined/></Tooltip> : ''}

            </Space>},
        { title: t('INTERNAL.CLIENT.DASHBOARD.VALUE_INTERNAL'), dataIndex: 'valueInternal', render: (val:string, r:IQuestionAnswer) => r.questionWithDate
            ? <Tooltip title={<NpiDisplayDate value={r.dateValueInternal}/>}>{val}</Tooltip>
            : val
        },
        { title: t('INTERNAL.CLIENT.DASHBOARD.VALUE_EXTERNAL'), dataIndex: 'valueExternal', render: (val:string, r:IQuestionAnswer) => r.questionWithDate
                ? <Tooltip title={<NpiDisplayDate value={r.dateValueExternal}/>}>{val}</Tooltip>
                : val
        },
        { title: 'Status', render: (answer:IQuestionAnswer) => <NpiDashboardSyncTooltipStatus
                status={answer.sync_status}
                simple={true}
                detailsParams={{table: "answers", id: answer.id}}
            />},
    ];

    return (
        <Table
            dataSource={dataSource}
            loading={loading}
            columns={columns}
            pagination={false}
            size={'small'}
            rowKey="id"
            onRow={((answer:IQuestionAnswer) => {
                return !answer.sync_status.match ? {style: {backgroundColor: '#ff787555'}} : {}
            })}
            style={{paddingBottom: '20px'}}
        />
    );
};

export default NpiContainerDashboardSyncPosDetails;