import React, { FunctionComponent, useMemo, useState } from "react";
import {Button, Tooltip} from "antd";
import NpiDisplayCountry from "./country";
import OpenInNew from '@2fd/ant-design-icons/lib/OpenInNew';
import styled from "styled-components";
import _ from "lodash";
import NpiDisplayBatteryChartElement from "./battery-chart-element";
import {useTranslation} from "react-i18next";

const StyledDiv = styled.div`
    &.has-children>.content{
        cursor: pointer;
        &:hover{
            opacity: 0.75;
        }
    }
    &.loading{
        opacity: .5;
    }
    .content{
        width: 100%;
        margin-bottom: 10px;
        &>div{
            display: inline-block;
        }
        .label{
            text-align: right;
            padding-right: 20px;
            width: 200px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;            
            float: left;
        }
        .battery{
            width: calc(100% - 350px);
        }
        .pos-total{
            padding-left: 20px;
            width: 150px;
            float: right;
        }
    }
`;

/**
 * A battery chart deph element represent a row of a battery chart
 * @param props
 * @constructor
 */
const NpiDepthElement = (props : any) => {
    const { depth, data, filters: parentFilters } = props;
    const { type, wave_id, name } = data;
    const {t} = useTranslation();

    // -- type of elements eg : wave, country, program etc...
    const displayLabel = useMemo(() => {
        switch (type)
        {
            case 'country':
                return <NpiDisplayCountry id={Number(name)}/>;
            default:
                return <Tooltip title={name}><span>{name}</span></Tooltip> ;
        }
    }, [type, name] );

    const batteryWidth : string = useMemo(() => {
        switch (depth) {
                case 1: return "100%";
                case 2: return '80%';
                case 3: return '65%';
                case 4: return '50%';
                default: return '100%';
        }
    }, [depth]);

    const batteryHeight : string = useMemo(() => {
        switch (depth)
        {
            case 1: return '75px';
            case 2: return '50px';
            case 3: return '30px';
            case 4: return '25px';
            default: return '25px';
        }
    }, [depth]);

    const [ showChildren, setShowChildren ] = useState<boolean>(false);
    const {children} = data;

    const onClick = () => {
        children && setShowChildren(!showChildren);
    };

    //Compute the filters for this level
    const filters = useMemo(() => {
        const filters = _.cloneDeep(parentFilters);
        if(type === 'wave'){
            //do nothing
        }
        else if(type === 'program'){
            filters.f_program = [[name]];
        }
        else if(type === 'region'){
            filters.f_region_name = [name];
        }
        else if(type === 'country'){
            filters.country_id = [name];
        }
        else if(type === 'partner'){
            filters.f_company_name = [name];
        }
        return filters;
    }, [parentFilters, name, type]);

    //Url to open the report related to this battery
    const url = useMemo(() => {
        return process.env.REACT_APP_CLIENT_ROOT
            + 'wave/' + wave_id + '/report/'
            + (_.isEmpty(filters) ? '' : JSON.stringify(filters));
    }, [filters, wave_id]);

    return <StyledDiv className={`${props.loading ? 'loading':''} ${children ? 'has-children':''}`}>
        <div className='content' style={{width: batteryWidth, marginLeft: `calc(100% - ${batteryWidth})`}}>
            <div className='label' style={{lineHeight: batteryHeight}}>{displayLabel}</div>
            <div className='battery' onClick={onClick}>
                <NpiDisplayBatteryChartElement height={batteryHeight} positive={data.total_positives} negative={data.total_negatives} neutral={data.total_neutrals} />
            </div>
            <div className='pos-total' style={{lineHeight: batteryHeight}}>
                <b>{data.total??0} {t('INTERNAL.CLIENT.COMMON.POS')}</b>
                <Tooltip title={t('INTERNAL.CLIENT.REPORT.SEE_GREY_POS')}>
                    <Button type="link" href={url} target="_blank" icon={<OpenInNew />} onClick={e => e.stopPropagation()}/>
                </Tooltip>
            </div>
        </div>

        {showChildren && <NpiBatteryChart data={children} depth={depth+1} filters={filters}/>}
    </StyledDiv>;
};

const NpiBatteryChart : FunctionComponent<any> = (props: any) => {
    const { data, depth, loading, filters={} } = props;

    return <>
        {data && Object.entries(data).map(([key, value]) => {
            return <NpiDepthElement key={key} data={value} filters={filters} label={key} depth={depth} loading={loading}/>
        })}
    </>
};

export default NpiBatteryChart;
