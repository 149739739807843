import React, {Dispatch, useEffect, useState} from "react";
import NpiInputQuestion, {INpiInputQuestionError} from "./question";
import {Space, Spin} from "antd";
import {useControllableValue, useUpdateEffect} from "ahooks";
import {
    IFormAnswer, IFormImagesAnswer, IFormImagesByQuestion,
    IQuestion,
    IWithQuestions,
} from "../../types/question";
import _ from "lodash";
import {observer} from "mobx-react";
import {ITagValue} from "../../types/tag";
import { applyConditions } from "../../helpers/form";


interface INpiForm {
    item:IWithQuestions
    languageId: number
    tags?:ITagValue
    fields?:{
        merchandising_group_id?: number|null
    }
    answers?: IFormAnswer[]
    onChange?: Dispatch<IFormAnswer[]>,
    onQuestionsChange?: Dispatch<IQuestion[]>,
    imageQuestions?: IFormImagesByQuestion,
    onImageQuestionsChange?: Dispatch<IFormImagesByQuestion>,
    debug?: boolean,
    errors?: INpiInputQuestionError[],
    questionsRefs?: React.RefObject<any>,
    loading?: boolean,
    disableImageQuestion?: boolean,
}

/**
 * Input the whole form of a wave as a single component
 * Is responsible for:
 * - Mapping question to their answer (question_id, value, date)
 * - Applying conditions (hiding questions & answers)
 * - Applying default values if necessary
 * Is NOT RESPONSIBLE for:
 * - Managing the pos_id or the wave_id
 * - Saving on server
 * @param wave
 * @param props
 * @constructor
 */
const NpiInputForm = observer(({item, tags, fields={}, languageId, debug=false, questionsRefs = undefined, errors = [], onQuestionsChange = undefined, loading = false, disableImageQuestion = false, ...props}:INpiForm) => {
    const [answers, setAnswers] = useControllableValue<IFormAnswer[]>(props,{
        valuePropName: 'answers',
        defaultValue: []
    });

    const [imageQuestions, setImageQuestions] = useControllableValue<IFormImagesByQuestion>(props,{
        valuePropName: 'imageQuestions',
        trigger: 'onImageQuestionsChange',
        defaultValue: {}
    });

    const [questions, setQuestions] = useState<IQuestion[]>([]);
    const [translations, setTranslations] = useState<any>();

    //Allows us to point easily to the correct answer for each question
    const answersById = _.keyBy(answers, 'question_id');

    const formApplyCondition = (applyOnAnswers: IFormAnswer[]) => {
        //We apply our conditions to the original set of questions
        const result = applyConditions(item, applyOnAnswers, tags, fields);
        if( result ){
            const {questions: newQuestions, answers: newAnswers} = result;
            //Set our questions to manage
            setQuestions(newQuestions);
            setAnswers(newAnswers);
        }
    };

    //When an answer in the form changes, update the resulting array
    const onFormAnswerChange = (question_id:number, answer:IFormAnswer) => {
        const found = _.find(answers, {question_id});
        if(found){
            //If answer exists extend it, keeping all other values provided (wave_id, pos_id...)
            _.extend(found, answer);
        }
        else{
            //Push the new answer on the array
            answers.push({...answer, question_id})
        }

        //We apply our conditions to the original set of questions
        formApplyCondition(answers);
    };

    //When images are added or removed in the form changes, update the list of imageQuestion
    const onFormImageQuestionsChange = (imageAnswers:IFormImagesAnswer) => {
        _.set(imageQuestions, [imageAnswers.question_id], imageAnswers);
        setImageQuestions({...imageQuestions});
    };


    //If wave questions change, we need to redraw the form
    useEffect(() => {
        formApplyCondition(answers);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item.questions, tags, fields]);

    useEffect(() => {
        const result = {};
        _.forEach(item.translations, (terms, tId) => {
            _.forEach(terms, (value, path) => {
                _.set(result, tId+'.'+path, value);
            })
        });
        setTranslations(result);
    }, [item.translations]);

    /**
     * Dispatch questions manipulations changes (hiddenInForm)
     */
    useUpdateEffect(() => {
        if(!onQuestionsChange){
            return;
        }

        onQuestionsChange(questions);
    }, [questions]);

    return <Spin spinning={loading}>
        <Space direction="vertical" style={{width: '100%'}}>
            {questions.map(question => ( ! question.hiddenInForm || debug) &&
                <div key={question.id}
                     ref={(ref: HTMLDivElement) => questionsRefs ? questionsRefs.current[question.id] = ref : null}>
                    <NpiInputQuestion
                        key={question.id}
                        question={question}
                        answer={answersById[question.id]}
                        error={errors?.find(error => error.question_id === question.id)}
                        onChange={a => onFormAnswerChange(question.id, a)}
                        translation={translations?.['T'+languageId]?.['Q'+question.id]}
                        imageAnswers={imageQuestions[question.id] ?? {}}
                        onImageAnswersChange={onFormImageQuestionsChange}
                        disableImageQuestion={disableImageQuestion}
                    />
                </div>
            )}
        </Space>
    </Spin>;
});

export default NpiInputForm;