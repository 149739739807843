import React, {Dispatch} from "react";
import {useControllableValue} from "ahooks";
import {Button, Col, Row} from "antd";
import {
    IConditionRule,
    IConditionRuleStateEnum, IConditionRuleTargetEnum, rulesStates
} from "../../../types/condition";
import {IQuestion} from "../../../types/question";
import styled from "styled-components";
import NpiDisplayValidatableCard from "../../display/validatable-card";
import NpiInputConditionRule from "./rule";
import {MinusOutlined, PlusOutlined} from "@ant-design/icons/lib";
import {IWTag} from "../../../types/wave";

const StyledCard = styled(NpiDisplayValidatableCard)`
    background: rgba(0,0,0, .025);
    border-color: rgba(0,0,0, .1);
    &>.ant-card-body>.ant-row:not(:last-child){
        margin-bottom: 12px;
    }
    &>.ant-card-body>.ant-row>.ant-col:first-child, &>.ant-card-body>.ant-row>.ant-col:last-child{
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding-right: 12px; //to recenter
    }
`;

const {QUESTION} = IConditionRuleTargetEnum;

interface INpiInputConditionBlock  {
    questions:IQuestion[]
    logic: IConditionRuleStateEnum.BLOCK_LOGIC_OR|IConditionRuleStateEnum.BLOCK_LOGIC_AND
    rulesTargets : IConditionRuleTargetEnum[]
    rules: IConditionRule[]
    onChange:Dispatch<any>
    tags?: IWTag[]
}

const NpiInputConditionBlock = ({questions, tags, rulesTargets, logic, ...props}:INpiInputConditionBlock) => {
    const [rules, setRules] = useControllableValue<IConditionRule[]>(props, {
        valuePropName: 'rules'
    });

    //Add a rule to the block
    const addRule = () => {
        setRules([...rules, {
            questionId: undefined,
            target: QUESTION,
            state: IConditionRuleStateEnum.EMPTY,
        }]);
    };

    //Remove a rule
    const removeRule = (key:number) => {
        rules.splice(key, 1);
        setRules(rules);
    };

    //Change a rule
    const onChangeRule = (newRule:IConditionRule, oldRule:IConditionRule) => {
        rules.splice(rules.indexOf(oldRule), 1, newRule);
        setRules(rules);
    };

    return <StyledCard size="small">
        {rules.map((rule, k) => <Row key={k}>
            <Col span={2}>{ !!k && rulesStates[logic].label}</Col>
            <Col span={20}>
                <NpiInputConditionRule questions={questions} tags={tags} rulesTargets={rulesTargets} value={rule} onChange={(newRule:IConditionRule) => onChangeRule(newRule, rule)}/>
            </Col>
            <Col span={2}>
                {!k && <Button icon={<PlusOutlined />} onClick={addRule}/>}
                {!!k && <Button icon={<MinusOutlined />} onClick={() => removeRule(k)}/>}
            </Col>
        </Row>)}
    </StyledCard>
};

export default NpiInputConditionBlock;