import React, {Dispatch, useRef} from 'react';
import {useControllableValue, useSize} from "ahooks";
import _ from "lodash";
import GridLayout, {Layout} from "react-grid-layout";
import NpiDisplayKpiValue from "../display/kpi-value";
import NpiDisplayKpiPieChart from "../display/kpi-pie-chart";
import {
    IDashboardBlockConfiguration,
    IDashboardBlockType,
    IDashboardConfiguration,
    IDashboardData
} from "../../types/dashboard";
import useAcl from "../../hooks/use-acl";
import {IRightsEnum} from "../../types/user";
import NpiKpiDashboardBlockContainer from "./block-container";
import NpiDisplayKpiSpace from "../display/kpi-space";

interface INpiKpiDashboardGrid {
    data: IDashboardData
    configuration?: any
    onConfigurationChange?: Dispatch<any>
    onClickConfigureBlock?: Dispatch<IDashboardBlockConfiguration>
    printMode?: boolean
}

const {KPI, PIE_CHART, TABLE, SPACE, TITLE} = IDashboardBlockType;

const NpiKpiDashboardGrid = ({data, printMode=false, ...props}:INpiKpiDashboardGrid) => {

    const [configuration, setConfiguration] = useControllableValue<IDashboardConfiguration>(props, {
        valuePropName: 'configuration',
        trigger: 'onConfigurationChange',
        defaultValue: {blocks: []},
    });
    const {onClickConfigureBlock=()=>{}} = props;
    const containerRef = useRef(null);
    const {width} = useSize(containerRef) as any ?? {};
    const {blocks} = configuration??{blocks:[]};
    const isLockedMode = !props.onConfigurationChange;
    const canEdit = useAcl().hasRight(IRightsEnum.EDIT_DASHBOARDS) && !isLockedMode;
    const finalWidth = width ?? 1120;
    const blocksInGrid = _.filter(blocks, b => ![TABLE, TITLE].includes(b.type));

    //Shortcut to update configuration
    const updateConfiguration = (configuration:IDashboardConfiguration) =>  setConfiguration(_.cloneDeep(configuration));

    /**
     * Event on deleting block (in fact just hiding it)
     * @param key
     */
    const onDeleteBlock = (key:string) => {
        const found = _.find(blocks, {key});
        if(found){
            found.deleted = true;
            updateConfiguration(configuration);
        }
    };

    //Track changes to layout to store them in our configuration
    const onLayoutChange = (layouts:Layout[]) => {
        layouts.forEach(layout => {
            const found = _.find(blocks, {key: layout.i});
            if(found){
                _.extend(found.grid, layout);
            }
        });
        updateConfiguration(configuration);
    };

    return <div ref={containerRef} style={printMode ? {height: "100%"} : {}}>
        {!!finalWidth && <GridLayout rowHeight={42} cols={24} width={finalWidth} margin={[20, 20]} onLayoutChange={onLayoutChange} isDraggable={canEdit} isResizable={canEdit} style={printMode ? {height: "100%"} : {}}>
            {blocksInGrid?.map((block) => {
                const {key, type, grid, visible, deleted, config} = block;
                return visible && !deleted
                    && <NpiKpiDashboardBlockContainer
                        block={block}
                        canEdit={canEdit}
                        onDelete={onDeleteBlock}
                        onClickConfigure={onClickConfigureBlock}
                        key={key} data-grid={{...grid, minW:1, minH:1}}
                        style={{
                            zIndex: type === SPACE ? 0 : 1,
                    }}
                    >
                        {type === KPI && <NpiDisplayKpiValue {...config} data={data} printMode={printMode}/>}
                        {type === PIE_CHART && <NpiDisplayKpiPieChart chartKey={key} data={data} printMode={printMode} {...config}/>}
                        {type === SPACE && <NpiDisplayKpiSpace/>}
                    </NpiKpiDashboardBlockContainer>
            })}
        </GridLayout>}
    </div>
};

export default NpiKpiDashboardGrid;