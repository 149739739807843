import React, {useCallback, useContext, useState} from 'react';
import NpiKpiDashboardMain from "../components/kpi-dashboard/main";
import {useParams} from "react-router";
import NpiKpiDashboardPrintPreview from "../components/kpi-dashboard/print-preview";
import {useMount} from "ahooks";
import {
    IDashboardBlockType,
    IDashboardConfiguration,
} from "../types/dashboard";
import {NpiInternalContext} from "../contexts/internal-context";
import _ from "lodash";
import useAcl from "../hooks/use-acl";
import {IRightsEnum} from "../types/user";
import {Button, Space, Spin} from "antd";
import {CloseOutlined, FontSizeOutlined, PlusOutlined, PrinterFilled, SaveFilled} from "@ant-design/icons/lib";
import CircleOutline from "@2fd/ant-design-icons/lib/CircleOutline";
import ChartPie from "@2fd/ant-design-icons/lib/ChartPie";
import TableLarge from "@2fd/ant-design-icons/lib/TableLarge";
import NpiKpiDashboardHeader from "../components/kpi-dashboard/header";
import {useKpiDashboardStore} from "../contexts/stores";
import {observer} from "mobx-react";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {ColumnWidthOutlined} from "@ant-design/icons";
import useRestrictedToRights from "../hooks/use-restricted-to-rights";

const {KPI, PIE_CHART, TABLE, SPACE, TITLE} = IDashboardBlockType;

const StyledSpace = styled(Space)`
    @media print{display: none};
`;

/**
 * Dashboard showing for an NPI
 * @constructor
 */
const NpiContainerKpiDashboard = observer(() => {
    useRestrictedToRights([IRightsEnum.VIEW_SENSITIVE_DATA]);

    const {mode} = useParams() as any;
    const [isEditorMode, setIsEditorMode] = useState(mode === 'editor');
    const {compute, loading, dashboardData, configuration, setConfiguration, saveDashboard, isDirty, resetStore} = useKpiDashboardStore();
    const {waveId, npiId} = useParams() as any;
    const history = useHistory();
    const {t} = useTranslation();
    const {setBreadcrumbs} = useContext(NpiInternalContext);
    const deletedBlocks = configuration?.blocks?.filter(b => {
        if(b.type === KPI && !_.find(dashboardData.kpis, {id: b.config.id})) return false; //KPI no longer exists
        return b.deleted;
    });
    const hasTitle = configuration?.blocks?.find(b => b.type === 'TITLE' && !b.deleted)
    const canEdit = useAcl().hasRight(IRightsEnum.EDIT_DASHBOARDS);
    const enablePrintPreview = () => setIsEditorMode(true);
    const onPrint = () => {
        try {
            // Print for Safari browser
            document.execCommand('print', false, '');
        } catch {
            window.print()
        }
    };

    //
    const [lists, setLists] = useState({waves: [], countries: []});
    const [filters, setFilters] = useState<any>({});
    const [previewMode, setPreviewMode] = useState<boolean>(false);

    //Add a block on the dashboard
    const onAddBlock = ({key}:{key:string}) => {
        const found = _.find(configuration?.blocks, {key});
        if(found){
            found.deleted = false;
            setConfiguration({...configuration as IDashboardConfiguration});
        }
    };

    //Add a new Space block
    const onAddSpace = () => {
        const key = 't-' + Date.now();
        configuration.blocks.push({
            config: {},
            grid: {x:0, y:0, w:4, h:4},
            key,
            type: SPACE,
            visible: true,
            deleted: false,
        });
        setConfiguration({...configuration});
    }

    //Add a new Title block
    const onAddTitle = () => {
        const key = "block-title";
        const found = _.find(configuration?.blocks, {key});
        if(found){
            found.deleted = false;
            setConfiguration({...configuration as IDashboardConfiguration});
        } else {
            configuration.blocks.unshift({
                config: {value: "Dashboard title", fontSize: 30},
                grid: {x:0, y:0, w:0, h:0},
                key,
                type: TITLE,
                visible: true,
                deleted: false,
            });
            setConfiguration({...configuration});
        }
    }

    //Add a new table
    const onAddBlockTable = () => {
        const key = 't-' + Date.now();
        configuration.blocks.push({
            config: {},
            grid: {x:0, y:0, w:0, h:0},
            key,
            type: TABLE,
            visible: true,
            deleted: false,
        });

        const firstTable = _.find(dashboardData.tables);
        if(firstTable){
            dashboardData.tables[key] = _.cloneDeep(firstTable);
        }
        setConfiguration({...configuration});
    };

    //Update breadcrumbs when data changes and set the filters if this the first call
    const afterRun = useCallback((data:any, filters, firstCall: boolean = false) => {
        const crumbs = [
            {url: '', name: t('INTERNAL.CLIENT.COMMON.DASHBOARD')},
            {url: '/npi/' + data.npi.id, name: data.npi.name},
        ];
        if(data.selectedWavesIds.length === 1){
            crumbs.push({
                url: '/kpi-dashboard/wave/' + data.selectedWavesIds[0],
                name: _.find(data.wavesOptions, {value: data.selectedWavesIds[0]})?.label,
            })
        }

        setBreadcrumbs(crumbs);
        setLists({waves: data.wavesOptions, countries: data.countries});
        if (firstCall && data.dashboards[0]) { // if this is the first call and there is a dashboard saved for this NPI we set the countries saved on the configuration if there are any
            setFilters({...filters, wavesIds: data.selectedWavesIds, countries: data.dashboards[0].configuration.filters?.countries});
        } else {
            setFilters({...filters, wavesIds: data.selectedWavesIds});
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setBreadcrumbs, filters, t]);

    const updateData = (filters:any, firstCall: boolean = false) => compute(filters, firstCall).then((data:any) => afterRun(data, filters, firstCall));

    const onChangeFilters = (filters:any) => {
        setFilters(filters);
        configuration.filters = filters; // we set filters on the configuration
        setConfiguration({...configuration as IDashboardConfiguration}); // we set the new configuration, that way we can save the data
        updateData(filters).then(null);
    };

    const onSaveDashboard = () => saveDashboard().then((data: any) => {

        // we move from a wave view to npi (multiple waves) view
        if( waveId && filters.wavesIds?.length > 1 ) {
            history.push(`/kpi-dashboard/npi/${data.npi_id}`);
        }
    });

    //Menu to add deleted block back into the dashboard
    const menuOfDeletedBlocks = [
        {key: 'blocks', icon:<PlusOutlined/>, label: t('INTERNAL.CLIENT.DASHBOARD.ADD_BLOCK'), children: [
            ...(deletedBlocks?.map(b => {
                switch (b.type) {
                    case KPI: return {key: 'block-'+b.key, label: <><CircleOutline/> {_.find(dashboardData.kpis, {id: b.config.id})?.name}</>, onClick: () => onAddBlock(b)}
                    case PIE_CHART: return {key: 'block-'+b.key, label: <><ChartPie/> {t('INTERNAL.CLIENT.COMMON.PIE_CHART')}</>, onClick: () => onAddBlock(b)}
                    case TABLE: return {key: 'block-'+b.key, label: <><TableLarge/> {t('INTERNAL.CLIENT.COMMON.TABLE')}</>, onClick: () => onAddBlock(b)}
                }
                return {};
            }) ?? []),
            {key: 'block-table', label: <><TableLarge/> {t('INTERNAL.CLIENT.COMMON.TABLE')}</>, onClick:onAddBlockTable,},
            {key: 'block-space', label: <><ColumnWidthOutlined/> {t('INTERNAL.CLIENT.COMMON.SPACE')}</>, onClick:onAddSpace},
            ...hasTitle ? [] : [
                {key: 'block-title', label: <><FontSizeOutlined/> {t('INTERNAL.CLIENT.COMMON.HEADER')}</>, onClick:onAddTitle},
            ]
        ]},
    ];

    //On load, display the dashboard based on the URL parameters
    useMount(() => {
        setBreadcrumbs([{url: '', name: t('INTERNAL.CLIENT.COMMON.DASHBOARD')}]);
        resetStore(); //must reset store to avoid issues when loading a dashboard after another was already loaded.
        //the second parameter "firstCall" is set to true only on mount
        if(waveId){
            updateData({wavesIds: [waveId]}, true).then(null);
        }
        else if(npiId){
            updateData({npiId}, true).then(null);
        }
    });

    return <>

        { ! isEditorMode && <>
            <NpiKpiDashboardHeader loading={loading} lists={lists} filters={filters} onPrint={enablePrintPreview} onPreview={setPreviewMode} onChangeFilters={onChangeFilters}
                                   extra={[canEdit && <Button key="save-dashboard" icon={<SaveFilled/>} type="primary" disabled={!isDirty} onClick={onSaveDashboard}>{t('COMMON.SAVE')}</Button>,]}
                                   extraMenus={canEdit ? menuOfDeletedBlocks : []}/>
            {loading
                ? <div style={{backgroundColor: "white", paddingTop: 50, textAlign: "center"}}><Spin/></div>
                : <NpiKpiDashboardMain filters={filters} previewMode={previewMode}/> }
        </>}

        { isEditorMode && <>
            <StyledSpace style={{position: 'absolute', right: 20, top: 16}}>
                <Button onClick={() => setIsEditorMode(false)} icon={<CloseOutlined/>}>{t('INTERNAL.CLIENT.DASHBOARD.EXIT_PREVIEW_MODE')}</Button>
                <Button onClick={onSaveDashboard} type="primary" disabled={!isDirty} icon={<SaveFilled/>}>{t('INTERNAL.CLIENT.DASHBOARD.SAVE_LAYOUT')}</Button>
                <Button onClick={onPrint} type="primary" icon={<PrinterFilled/>}>{t('INTERNAL.CLIENT.COMMON.PRINT')}</Button>
            </StyledSpace>
            <NpiKpiDashboardPrintPreview loading={loading}/>
        </>}
    </>
});

export default NpiContainerKpiDashboard;