import React from 'react';
import styled from "styled-components";
import {Spin} from "antd";

const StyledDiv = styled.div`
  padding: 20px;
  background: #fff;
`;

interface INpiDisplayLayoutContent {
    loading?:boolean
    children?:any
    style?: any

}

const NpiDisplayLayoutContent = ({loading, children, ...props}:INpiDisplayLayoutContent) => {
    if(loading){
        return <StyledDiv style={{textAlign: 'center'}}>
            <Spin size="large"/>
        </StyledDiv>
    }
    else{
        return <StyledDiv {...props}>{children}</StyledDiv>;
    }
}

export default NpiDisplayLayoutContent;