import React, {useMemo, useState} from 'react';
import {ITranslations} from "../../types/wave";
import {IWithQuestions} from "../../types/question";
import {ISaveableStore} from "../../types/store";
import {observer} from 'mobx-react';
import {Alert, Button, notification, Space, Tooltip} from "antd";
import NpiInputTranslations from "../input/translations";
import {SaveFilled} from "@ant-design/icons/lib";
import Translate from '@2fd/ant-design-icons/lib/Translate';
import NpiInputTranslationsUploader from "../input/translations-uploader";
import {useRequest} from "ahooks";
import npiApi from "../../services/api";
import {generateTerms} from "../../helpers/translation";
import _ from "lodash";
import {useTranslation} from "react-i18next";

interface INpiConfigFormTranslations<T extends IWithQuestions> {
    item: T
    translationIds?: number[]
    store: ISaveableStore<T>
    languageId: number
    fileImport: (params:any) => Promise<ITranslations>
}

const NpiConfigFormTranslations = observer(function<T extends IWithQuestions>({item, store, languageId, translationIds, fileImport}:INpiConfigFormTranslations<T>) {
    const {t} = useTranslation();
    const [translations, setTranslations] = useState(item.translations??{});
    const [originalTranslations, setOriginalTranslations] = useState(item.translations??{});
    const isDirty = useMemo(() => !_.isEqual(translations, originalTranslations), [originalTranslations, translations]);
    const {runAsync: runAutoFill, loading: loadingAutoFill} = useRequest(npiApi.internal.translation.translateFromExisting, {manual: true});
    const [loading, setLoading] = useState(false);
    const {save} = store;
    const hasTranslations = translationIds && translationIds.length > 0;

    //Save translations in the wave
    const onSave = () => {
        item.translations = translations;
        setLoading(true);
        save(item)
            .then(() => {
                notification.success({message: t('INTERNAL.CLIENT.TRANSLATION.TRANSLATION_SAVED')})
                setOriginalTranslations(translations);
            })
            .finally(() => setLoading(false))
        ;
    };

    const onAutoComplete = () => {
        runAutoFill({terms: generateTerms(item), translationIds}).then(autoTranslations => {
            setTranslations(_.merge((Array.isArray(autoTranslations) && autoTranslations.length === 0 ? {} : autoTranslations), translations));
        })
    };

    const onUploadTranslations = (translations:ITranslations) => {
        item.translations = translations;
        setTranslations(translations);
    };

    if( ! hasTranslations){
        return <Alert message={t('INTERNAL.CLIENT.TRANSLATION.NO_TRANSLATION_TO_MANAGE')}/>;
    }

    return <>
        <Space style={{float: 'right', marginBottom: 20}}>
            <NpiInputTranslationsUploader item={item} translations={item.translations} translationIds={translationIds} fileImport={fileImport} onChange={onUploadTranslations}/>
            <Tooltip title={t('INTERNAL.CLIENT.TRANSLATION.NO_TRANSLATION_TO_MANAGE')}>
                <Button icon={<Translate/>} loading={loadingAutoFill} disabled={loading || loadingAutoFill} onClick={onAutoComplete}>{t('INTERNAL.CLIENT.COMMON.AUTOFILL')}</Button>
            </Tooltip>
            {/*<div><Checkbox checked={autocomplete} onChange={e => setAutocomplete(e.target.checked)}/> Auto-complete identical strings</div>*/}
            <Button icon={<SaveFilled/>} type="primary" onClick={onSave} loading={loading} disabled={loading || loadingAutoFill || !isDirty}>{t('COMMON.SAVE')}</Button>
        </Space>
        <NpiInputTranslations /*autocomplete={autocomplete}*/ item={item} languageId={languageId??1} translations={translations} translationIds={translationIds} onChange={setTranslations}/>
    </>
});

export default NpiConfigFormTranslations;