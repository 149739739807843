import React, {useContext} from 'react';
import {Breadcrumb} from "antd";
import {Link} from "react-router-dom";
import {NpiInternalContext} from "../contexts/internal-context";

const NpiBreadCrumbContext = React.createContext({});
const NpiBreadCrumbContextProvider = NpiBreadCrumbContext.Provider;

const NpiBreadcrumb = () => {
    const {breadcrumbs} = useContext(NpiInternalContext);

    return <Breadcrumb style={{padding: 20, minHeight: 62}}>
        {breadcrumbs.map(({url, name}, key) => <Breadcrumb.Item key={key}>
            {!!url && <Link to={url}>{name}</Link>}
            {!url && <span>{name}</span>}
        </Breadcrumb.Item>)}
    </Breadcrumb>;
}

export default NpiBreadcrumb;
export {NpiBreadCrumbContextProvider};