import Asterisk from '@2fd/ant-design-icons/lib/Asterisk';
import React from 'react';
import {Collapse, Empty} from "antd";
import {useTranslation} from "react-i18next";
import NpiExternalWaveCollapsePanel from "./wave-collapse-panel";
import NpiTabGalleryWaveContent from "./tab-gallery-wave-content";
import useExternalToken from "../../../hooks/use-external-token";
import {ExternalTokenRight} from "../../../types/external-token";
import {IWave} from "../../../types/wave";
import colors from "../../../services/colors";
import {useNpiExternalPosContext} from "../../../contexts/external-pos-context";


interface INpiExternalPosViewTabGalleryProps {
    //
}

/**
 * External POS Tab: Images gallery
 */
const NpiExternalPosViewTabGallery = (props: INpiExternalPosViewTabGalleryProps) => {
    const {t} = useTranslation();

    // get current POS context
    const {waves} = useNpiExternalPosContext();

    // we render only wave with images allowed requirement
    const wavesWithImages = waves.filter(wave => wave.is_images_allowed);

    // single or multiple waves view
    const isUnifiedView = useExternalToken().hasRight(ExternalTokenRight.ACCESS_UNIFIED_VIEW);


    // -- Render a single Wave gallery content
    const renderWaveGalleryContent = (wave: IWave): React.ReactNode => <NpiTabGalleryWaveContent wave={wave}/>;


    return <div id={"tab-images-gallery"} style={{width: '100%'}}>

        {/* Displaying images by wave */}
        { !wavesWithImages || !wavesWithImages.length

            ? <Empty style={{paddingTop: 20}} description={t('EXTERNAL.CLIENT.POS_VIEW.NO_WAVE_AVAILABLE')}/>

            : ( !isUnifiedView
                    ? renderWaveGalleryContent(wavesWithImages[0])
                    : <Collapse accordion>
                        { wavesWithImages.map((wave: IWave) =>
                            <NpiExternalWaveCollapsePanel key={wave.id} wave={wave} extra={wave.is_images_mandatory ? <Asterisk style={{color: colors.red}}/> : null}>
                                {renderWaveGalleryContent(wave)}
                            </NpiExternalWaveCollapsePanel>
                        )}
                    </Collapse>
            )
        }
    </div>
}

export default NpiExternalPosViewTabGallery;