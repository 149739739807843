import React, {useEffect, useState} from 'react';
import {Form, Input, Button, Row, Col, notification, Select,} from "antd";
import _ from "lodash";
import {observer} from "mobx-react";
import {SaveFilled} from "@ant-design/icons/lib";
import {useNpiStore} from "../../contexts/stores";
import {INpi, lobs} from "../../types/npi";
import {useTranslation} from "react-i18next";

const NpiConfigTabInformation = observer(({npi}:{npi: INpi}) => {
    const [form] = Form.useForm();
    const {t} = useTranslation();
    const {save, saving} = useNpiStore();
    const [isDirty, setIsDirty] = useState(false);

    const onSave = async () => {
        try{
            await save({...npi, ...form.getFieldsValue()});
            notification.success({message: t('INTERNAL.CLIENT.NPI.INFORMATION_SAVED')});
            setIsDirty(false);
        }
        catch{
        }
    };

    const onChange = () => {
        setIsDirty(form.isFieldsTouched(true));
    };

    useEffect(() => {
        form.setFieldsValue({
            translation_ids: [], //defaults
            ..._.pickBy(npi, v => v!==null), //get everything from the wave without null values
        });
    }, [npi, form]);

    return <Row>
        <Col span={14} offset={5}>
            <Form form={form} labelCol={{span: 4}} onFieldsChange={onChange}>
                <Form.Item label={t('INTERNAL.CLIENT.COMMON.NAME')} name="name"><Input/></Form.Item>
                <Form.Item label={t('INTERNAL.CLIENT.COMMON.LOB')} name="lob">
                    <Select options={lobs.map(lob => ({...lob, label: t(lob.label)}))}/>
                </Form.Item>

                <Button style={{float: 'right'}} type="primary" onClick={onSave} icon={<SaveFilled/>} loading={saving} disabled={!isDirty}>{t('COMMON.SAVE')}</Button>
            </Form>
        </Col>
    </Row>
});

export default NpiConfigTabInformation;