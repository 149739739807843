import { IShipmentChart } from "../../types/shipment";
import React, { useMemo } from "react";
import { Cell, Legend, Pie, PieChart, Tooltip } from "recharts";

const NpiPieChart = ({ kpis, status } : IShipmentChart) : JSX.Element => {

    const { colors } = kpis
    const colorSet   = colors[status];

    // -- raw data in pie chart not filtered by any asset type
    const dataSet = useMemo(() => {
        const dataset = kpis[status].all;
        return Object.keys(dataset).map((name: string, index : number) => ({ name , value : dataset[name] }));
    }, [kpis, status])

    // -- trads should come from server
    return  (
        <PieChart width={500} height={350} margin={{ top: 40, right: 0, bottom: 0, left: 0 }}>
            <Pie
                data={dataSet}
                cx={250}
                cy={130}
                innerRadius={50}
                outerRadius={120}
                fill="#8884d8"
                paddingAngle={0}
                dataKey="value"
            >
                {dataSet.map(({name, value}, index : number) => {
                    return  <Cell key={`cell-${index}`} fill={colorSet[name]} />
                })}
            </Pie>
            <Tooltip />
            <Legend />
        </PieChart>
    );
}

export default NpiPieChart;