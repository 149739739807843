import React, {Dispatch, useMemo} from 'react';
import {Card, Checkbox, Input, InputNumber, Radio, Space, Tooltip} from "antd";
import {IQuestionTypeEnum as Types, IQuestion, IFormAnswer, IFormImagesAnswer} from "../../types/question";
import styled from "styled-components";
import Asterisk from '@2fd/ant-design-icons/lib/Asterisk';
import Information from '@2fd/ant-design-icons/lib/Information';
import questionsConfigurations from "../../services/questions-configurations";
import colors from "../../services/colors";
import {useControllableValue} from "ahooks";
import NpiInputDatePicker from "./date-picker";
import _ from "lodash";
import moment from "moment";
import NpiInputImageUploader, {INpiUploadImage} from "./image-uploader";
import {WarningOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";

const StyledCard = styled(Card)`
    transition: background-color 500ms;
    .ant-card-head-title .anticon{
        font-size: 20px;
        position: relative;
        top: 3px;
        margin-right: 5px;
    }
    .ant-radio-wrapper, .ant-checkbox-group, .ant-checkbox-wrapper{
        width: 100%;
        display: block;
    }
    .ant-checkbox-inner{
        display: inline-block;
    }
    .icons{
        float: right;
        .mandatory{
            color: ${colors.red};
        }
        .information{
            color: #666;
        }
    }
    &.hidden-question{
        opacity: 0.5;
    }
    &.highlighted{
        background-color: ${colors.yellowLighter};
    }
    .ant-radio-wrapper-disabled, .ant-checkbox-wrapper-disabled{
        text-decoration: line-through;
    }
    .error-message{
        color: red;
        font-weight: bold;
        font-size: 12px;
        text-align: center;
        margin-top: 10px;
    }
`;

interface INpiInputQuestion {
    question: IQuestion
    answer?: IFormAnswer
    error?: INpiInputQuestionError,
    onChange?: Dispatch<IFormAnswer>,
    imageAnswers?: IFormImagesAnswer,
    onImageAnswersChange?: Dispatch<IFormImagesAnswer>,
    translation?: any,
    disableImageQuestion?: boolean,
}

export interface INpiInputQuestionError {
    question_id: number,
    message: string
}

//Shortcut for translation
const translate = (defaultTerm: string, path: string, translation: any) => _.get(translation, path, defaultTerm);

/**
 * This component displays a question but does not allow any input. A button is present to open a modal to configure the question
 * @param question
 * @param translation
 * @param error
 * @param disableImageQuestion
 * @param props
 * @constructor
 */
const NpiInputQuestion = ({question, translation, error = undefined, disableImageQuestion = false,...props}: INpiInputQuestion) => {
    const {t} = useTranslation();
    const [answer, setAnswer] = useControllableValue<IFormAnswer>(props, {
        valuePropName: 'answer',
        defaultValue: {
            question_id: question.id,
            value: null,
        },
    });

    const [imageAnswers, setImageAnswers] = useControllableValue<IFormImagesAnswer>(props, {
        valuePropName: 'imageAnswers',
        trigger: 'onImageAnswersChange',
        defaultValue: {
            question_id: question.id,
            images: [],
        },
    });

    const {type, config, display_name, description, is_mandatory, is_date_required, hiddenInForm} = question;
    const typeConfig = questionsConfigurations[type];
    const disabled = hiddenInForm;
    const answerValue = answer ? answer.value : (Types.MULTILIST === type ? [] : null);
    const answerDateValue = answer?.date_value;
    const className = 'npi-question ' + (hiddenInForm ? 'hidden-question':'');

    //On change value directly
    const onChangeValue = (value:any) => {
        //automatically fill the date, if not provided
        const date_value = answer?.date_value ?? moment().format('YYYY-MM-DD');

        setAnswer({...answer, value, date_value});
    };

    const onChangeValueImageAnswers = (list: INpiUploadImage[]) => {
        setImageAnswers({question_id: question.id, images: list});
    }

    //On change extracting value from an event
    const onChangeValueEvent = (e:any) => onChangeValue(e.target.value);

    //On change date value
    const onChangeAnswerDateValue = (d:any) => setAnswer({...answer, date_value: d});

    const multiListOptions = useMemo(() => type !== Types.MULTILIST ? [] : config.answers
        ?.filter(a => !a.hidden)
        .map((a, key) => ({
            label: translate(a.label, 'answers[' + key + ']', translation),
            value: a.value,
            disabled: !!a.hiddenInForm,
        })), [config.answers, type, translation]);

	//Card title
    const title = <span>
        <Tooltip title={typeConfig.tooltip}>{typeConfig.icon}</Tooltip>
        {translate(display_name, 'display_name', translation)}
        <Space className="icons">
            {!!disableImageQuestion && Types.IMAGE === type && <div className="information"><Tooltip title={t('INTERNAL.CLIENT.QUESTION.IMAGE_CANT_BE_ANSWERED')} placement="left"><WarningOutlined/></Tooltip></div>}
            {!!description && <div className="information"><Tooltip title={translate(description, 'description', translation)} placement="left"><Information/></Tooltip></div>}
            {!!is_mandatory && <div className="mandatory"><Tooltip title={t('INTERNAL.CLIENT.QUESTION.MUST_BE_ANSWERED')} placement="left"><Asterisk/></Tooltip></div>}
        </Space>
    </span>;

    //Question Card and its input depending on the type
    return <StyledCard title={title} size="small" bordered={true} className={className} id={"question-" + question.id}>
        {[Types.BOOL, Types.LIST].indexOf(type)>=0 &&
            <Radio.Group value={answerValue} onChange={onChangeValueEvent} disabled={disabled}>
                {config.answers?.map((a, key) =>
                    !a.hidden && <Radio key={key} value={a.value} disabled={a.hiddenInForm}>{translate(a.label, 'answers['+key+']', translation)}</Radio>
                )}
            </Radio.Group>
        }

        {Types.DATE === type && <NpiInputDatePicker value={answerValue} onChange={onChangeValue} disabled={disabled}/>}

        {Types.TEXT === type && <Input.TextArea value={answerValue} onChange={onChangeValueEvent} disabled={disabled}/>}

        {Types.NUMERIC === type && <InputNumber value={answerValue} onChange={onChangeValue} disabled={disabled} {...config}/>}

        {Types.MULTILIST === type && <Checkbox.Group value={answerValue} onChange={onChangeValue} disabled={disabled} options={multiListOptions}/>}

        {Types.IMAGE === type && <NpiInputImageUploader value={answerValue} onChange={onChangeValue} images={imageAnswers.images} onImagesChange={onChangeValueImageAnswers} disabled={disabled || disableImageQuestion} isMandatory={!!question.is_mandatory} onDownload={false} {...config}/>}

        {/*Display the date if requested*/}
        {!!is_date_required && <NpiInputDatePicker value={answerDateValue} onChange={onChangeAnswerDateValue} pickerProps={{style: {float: 'right'}}}/>}

        {/* Display error message if provided */}
        {
            error && <p className={"error-message"}>
                {error.message}
            </p>
        }

    </StyledCard>
};

export default NpiInputQuestion;