import React, {Dispatch, useCallback, useMemo} from 'react';
import {observer} from "mobx-react";
import {DownloadOutlined, ExclamationCircleOutlined, UploadOutlined} from "@ant-design/icons/lib";
import {Alert, Button, Modal, Space, Upload} from "antd";
import {useRequest} from "ahooks";
import {npiApiDownloadFileCallback} from "../../services/api";
import {useTranslation} from "react-i18next";


interface ITemplateUploader {
    onChange: Dispatch<void>
    textContent?: any
    textConfirm: any
    axiosUpload: (params: any) => Promise<any>
    axiosDownload: (params: any) => Promise<any>
    filename: string
    textDownloadButton?: string
    textUploadButton?: string
    disableUpload?: boolean
}

/**
 * This component is shown in a modal and is used to download/upload template of tags for a wave
 */
const NpiModalTemplateUploader = observer(({onChange, textConfirm, textContent, axiosDownload, axiosUpload, filename, textDownloadButton, textUploadButton, disableUpload=false}:ITemplateUploader) => {

    const {t} = useTranslation();

    const {runAsync: download, loading: loadingDownload} = useRequest(axiosDownload, {manual: true});
    const {runAsync: upload, loading: loadingUpload} = useRequest(axiosUpload, {manual: true});

    //Download the template
    const onDownload = useCallback(() => download({}).then(npiApiDownloadFileCallback(filename)), [download, filename]);

    //Upload file properties
    const FileUploadProps = useMemo(() => ({
        name: "file",
        accept: ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
        showUploadList: false,
        beforeUpload : (file: any) => {
            Modal.confirm({
                title: textConfirm,
                icon: <ExclamationCircleOutlined />,
                onOk: () => upload({file}).then(onChange),
            });
            return false;
        }
    }), [upload, onChange, textConfirm]);

    return <div>
        {textContent && <Alert message={textContent}/>}
        <Space style={{marginTop: 20}}>
            <Button type="primary" icon={<DownloadOutlined/>} loading={loadingDownload} onClick={onDownload}>{textDownloadButton ? textDownloadButton : t('INTERNAL.CLIENT.DASHBOARD.DOWNLOAD_TEMPLATE')}</Button>
            <Upload {...FileUploadProps}>
                <Button type="primary" icon={<UploadOutlined/>} loading={loadingUpload} disabled={disableUpload}>{textUploadButton ? textUploadButton : t('INTERNAL.CLIENT.DASHBOARD.UPLOAD_TEMPLATE')}</Button>
            </Upload>
        </Space>
    </div>
});

export default NpiModalTemplateUploader;