import React, {ReactElement, ReactNode} from "react";
import {DragHandle} from "../sort-table";
import {DragOutlined} from "@ant-design/icons";
import styled from "styled-components";

export interface IDragHandle {
    children?: ReactNode | undefined,
    icon?: ReactElement | null,
}

const StyledDrag = styled.div`
    cursor: grab;
`;

const NpiDragHandle = ({children, icon}: IDragHandle) => {
    const renderIcon = (icon === null) ? null : React.cloneElement<any>(icon ?? <DragOutlined/>, {className:'drag-icon'})

    return <DragHandle children={<StyledDrag>
        {renderIcon} {children}
    </StyledDrag>}/>
}

export default NpiDragHandle;