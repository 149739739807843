const colors = {
    white: '#fff',
    blue: '#1177d6',
    red: '#c32020',
    redLighter: 'red',
    darkred: 'darkred',
    redBackground: 'rgb(255, 242, 240)',
    redBorder: 'rgb(255, 204, 199)',
    purple: '#A32CC4',
    green: 'green',
    greenBright: '#52c41a',
    yellow: '#faad14',
    yellowLighter: '#ffe58f',
    greyBorder: '#f0f0f0',
    greyBatteryStatus: 'rgb(209, 218, 222)',
    greyDisabledText: 'rgba(0,0,0,0.25)',
    greyMutedText: 'rgba(0,0,0,0.5)',
    greyLightBackground: '#f0f2f5',
    greySuperLightBackground: '#fafafc',
}

export default colors;