import React, {Dispatch, useState} from 'react';
import {
    Button,
    Input,
    Modal,
    ModalProps,
    notification,
    Space,
    Table,
} from "antd";
import npiApi from "../../services/api";
import {
    CarOutlined,
    CloseOutlined,
    DeleteOutlined,
    EditOutlined,
    PlusOutlined,
    SaveOutlined
} from "@ant-design/icons";
import {useRequest} from "ahooks";
import _ from "lodash";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react";
import {ColumnProps} from "antd/es/table";
import {IShipmentCarrier} from "../../types/shipment";

interface IModalEditShipmentCarriers extends ModalProps {
    onChangeShipmentCarriers?: Dispatch<void>,
}

const ModalEditShipmentCarriers = observer(({onChangeShipmentCarriers, ...props}: IModalEditShipmentCarriers) => {
    const {t} = useTranslation();

    const {loading: fetching} = useRequest(npiApi.internal.shipments.carrier.list, {
        onSuccess: (data : IShipmentCarrier[]) => setShipmentCarriers(data)
    });

    const {loading: creating, runAsync: runCreate} = useRequest(npiApi.internal.shipments.carrier.create, {
        manual: true,
        onSuccess: (createdCarrier:IShipmentCarrier) => {
            onChangeShipmentCarriers?.();
            setShipmentCarriers([createdCarrier, ...shipmentCarriers]);
            setNewCarrierName('');
            setNewCarrierUrl('');
        }
    });

    const {loading: saving, runAsync: runUpdate} = useRequest(npiApi.internal.shipments.carrier.update, {
        manual: true,
        onSuccess: (updatedCarrier:IShipmentCarrier) => {
            onChangeShipmentCarriers?.();
            // update config list with new datas
            const found = _.find(shipmentCarriers, {id: updatedCarrier.id});
            if(found){
                shipmentCarriers.splice(shipmentCarriers.indexOf(found), 1, updatedCarrier);
                setShipmentCarriers([...shipmentCarriers]);
            }
            switchEditRow(null)
        }
    });

    const [deleting, setDeleting] = useState<number|null>(null);
    const {runAsync: runDelete} = useRequest(npiApi.internal.shipments.carrier.delete, {
        manual: true,
        onBefore: (params) => setDeleting(params[0]),
        onFinally: () => setDeleting(null),
        onSuccess: (deletedCarrier:IShipmentCarrier) => {
            onChangeShipmentCarriers?.();
            setShipmentCarriers(shipmentCarriers.filter((c) => c.id !== deletedCarrier.id));
        }
    });


    const [shipmentCarriers, setShipmentCarriers] = useState<IShipmentCarrier[]>( [] );
    const [newCarrierName, setNewCarrierName] = useState<string>('');
    const [newCarrierUrl, setNewCarrierUrl] = useState<string>('');
    const [editingKey, setEditingKey] = useState<number|null>(null);
    const [editingUrl, setEditingUrl] = useState<string>('');

    // Edit row
    const isEditing = (id:number) => id === editingKey;
    const switchEditRow = (id:number|null, defaultValue:string = '') => {
        setEditingKey(id);
        setEditingUrl(defaultValue);
    }

    const createRow = () => {
        runCreate({carrier_name: newCarrierName, tracking_url: newCarrierUrl})
            .then(() => notification.success({message: t('INTERNAL.CLIENT.SHIPMENT.TEMPLATE_SETTINGS_LINE_CREATED')}))
    }
    const updateRow = (id:number) => {
        runUpdate(id, {tracking_url: editingUrl})
            .then(() => notification.success({message: t('INTERNAL.CLIENT.SHIPMENT.TEMPLATE_SETTINGS_LINE_UPDATED')}))
    }
    const deleteRow = (id:number, carrierName: string) => {
        Modal.confirm({
            title: t('INTERNAL.CLIENT.SHIPMENT.CARRIER_CONFIRM_DELETE', {carriername: carrierName}),
            icon: <DeleteOutlined/>,
            okText: t('INTERNAL.CLIENT.COMMON.YES'), okButtonProps: {danger: true},
            onOk: () => runDelete(id)
                .then(() => notification.success({message: t('INTERNAL.CLIENT.SHIPMENT.TEMPLATE_SETTINGS_LINE_REMOVED')}))
        })
    }


    // Table columns
    const columns:ColumnProps<IShipmentCarrier>[] = [
        {title: t('INTERNAL.CLIENT.SHIPMENT.CARRIER'), width: 130, dataIndex: 'carrier_name'},
        // inline editing values
        {title: <>{t('INTERNAL.CLIENT.SHIPMENT.TRACKING_URL')}
                <br/>
                <em><small>{t('INTERNAL.CLIENT.SHIPMENT.TRACKING_PLACEHOLDER', {placeholder: '%code%'})}</small></em>
            </>,
            render: (carrier:IShipmentCarrier) => {
                return <>
                    {isEditing(carrier.id)
                        ? <Input placeholder={t('INTERNAL.CLIENT.SHIPMENT.TRACKING_URL')+'...'}
                            defaultValue={carrier.tracking_url}
                            onChange={(e) => setEditingUrl(e.target.value)}
                            style={{width: '100%'}}/>
                        : carrier.tracking_url
                    }
                </>
            }},
        {
            width: 100,
            align: 'center',
            render: (carrier:IShipmentCarrier) => {
                // -- switch editing mode
                return <>
                    {isEditing(carrier.id)
                        // SAVE or CANCEL
                        ? <Space size={'middle'}>
                            <Button onClick={() => updateRow(carrier.id)} title={t('COMMON.SAVE')} icon={<SaveOutlined/>} size={'small'} type={'primary'} loading={saving} disabled={!editingUrl}/>
                            <Button onClick={() => switchEditRow(null)} title={t('COMMON.CANCEL')} icon={<CloseOutlined/>} size={'small'}/>
                        </Space>
                        // EDIT or DELETE
                        : <Space size={'middle'}>
                            <Button onClick={() => switchEditRow(carrier.id, carrier.tracking_url)} title={t('INTERNAL.CLIENT.COMMON.EDIT')} icon={<EditOutlined/>} size={'small'}/>
                            <Button onClick={() => deleteRow(carrier.id, carrier.carrier_name)} title={t('COMMON.DELETE')} icon={<DeleteOutlined/>} size={'small'} type={'primary'} danger loading={deleting === carrier.id}/>
                        </Space>
                    }
                </>
            },
        },
    ];

    const configModal = {
        title: t('INTERNAL.CLIENT.COMMON.CARRIER_SETTINGS'),
        icon: <CarOutlined/>,
        footer: null,
        width: 1000,
        ...props,
    }

    return <Modal {...configModal}>
        {/* -- create new line */}
        <Input.Group compact style={{ width: 'fit-content', marginLeft: 'auto', marginBottom: '20px' }}>
            <Input style={{ width: '200px' }} placeholder={t('INTERNAL.CLIENT.SHIPMENT.CARRIER_NAME')} value={newCarrierName} onChange={(e) => setNewCarrierName(e.target.value)}/>
            <Input style={{ width: '200px' }} placeholder={t('INTERNAL.CLIENT.SHIPMENT.TRACKING_URL')} value={newCarrierUrl} onChange={(e) => setNewCarrierUrl(e.target.value)}/>
            <Button onClick={createRow} type={"primary"} icon={<PlusOutlined/>} loading={creating} disabled={creating || !newCarrierName || !newCarrierUrl}>{t('INTERNAL.CLIENT.COMMON.CREATE')}</Button>
        </Input.Group>

        <Table
            rowKey="id"
            dataSource={shipmentCarriers}
            columns={columns}
            pagination={false}
            loading={fetching}
            locale={{emptyText: <>{t('INTERNAL.CLIENT.SHIPMENT.CARRIER_EMPTY')}</>}}
            size={'small'}
        />
    </Modal>
});

export default ModalEditShipmentCarriers;
