import React from "react";
import {ITag} from "../../types/tag";
import {Space, Tag} from "antd";
import {useTranslation} from "react-i18next";

/**
 * Displays tag rules as label with a tooltip
 * @param value
 * @param tags
 * @param max (use -1 to show all without ellipsis)
 * @constructor
 */
const NpiDisplayTagRules = ({tag}:{tag: ITag}) => {
    const {t} = useTranslation();
    return <Space size={[5,5]}>
        {tag.config?.rules?.map((rule:any, key:number) => <div key={key}>
            {rule.type === 'list' && <Tag>{t('INTERNAL.CLIENT.TAGS.MUST_BE_IN', {tagList: rule?.values?.join(', ')}) }</Tag>}
            {rule.type === 'required' && <Tag>{t('INTERNAL.CLIENT.TAGS.MUST_BE_FILLED')}</Tag>}
        </div>)}
    </Space>
};

export default NpiDisplayTagRules;