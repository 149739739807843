import { RadioGroupProps } from "antd";
import { useControllableValue } from "ahooks";
import React from "react";
import { RadioChangeEvent } from "antd/es";
import { ShipmentFeedback } from "../../types/shipment";
import styled from "styled-components";
import { Group } from "antd/es/radio";
import {useTranslation} from "react-i18next";

interface INpiFeedbacksStatus  extends RadioGroupProps {
    onChangeValue : (value: ShipmentFeedback) => void;
}

const StyledRadio = styled(Group)`
    .ant-radio-button-wrapper {
        width: 160px;
        text-align : center;
     }
`
const NpiFeedbacksStatus = (props: INpiFeedbacksStatus): JSX.Element => {

    const {t} = useTranslation();

    const [ state, setState ] = useControllableValue<ShipmentFeedback>( props, {
        defaultValue: ShipmentFeedback.VENDOR,
        trigger: 'onChangeValue'
    } );

    const options = [
        { label: t('INTERNAL.CLIENT.SHIPMENT.FEEDBACKS.VENDOR'), value: ShipmentFeedback.VENDOR },
        { label: t('INTERNAL.CLIENT.SHIPMENT.FEEDBACKS.POS'), value: ShipmentFeedback.POS },
    ];

    const onChange = (e: RadioChangeEvent) => {
        setState( e.target.value );
    };

    return (
        <StyledRadio
            disabled={props.disabled}
            style={{width: '100%'}}
            optionType="button"
            buttonStyle="solid"
            options={options}
            onChange={onChange}
            value={state} />
    );
};

export default  NpiFeedbacksStatus;