import React, {Dispatch, Key, useCallback, useEffect, useMemo, useState} from 'react';
import {Button, Checkbox, Dropdown, Menu, Segmented, Space, Tree} from "antd";
import Search from "antd/es/input/Search";
import {useControllableValue, useDebounceFn} from "ahooks";
import {DataNode} from "antd/es/tree";
import {FileSearchOutlined, FilterOutlined, PlusOutlined, UnorderedListOutlined} from "@ant-design/icons";

interface ITreeTranslationKeysProps {
    keys: Record<string, any>
    baseFlatValues: Record<string, any>
    addNewKey: Dispatch<string>
    checkedKeys: Key[]
    setCheckedKeys: Dispatch<Key[]>
    selectedKeys: Key[]
    setSelectedKeys: Dispatch<Key[]>
}

const TreeTranslationKeys = ({keys, baseFlatValues, addNewKey,...props}:ITreeTranslationKeysProps) => {

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [checkedLeafKeys, setCheckedLeafKeys] = useControllableValue<Key[]>(props, {
        valuePropName: 'checkedKeys',
        trigger: 'setCheckedKeys'
    });
    const [selectedKeys, setSelectedKeys] = useControllableValue<Key[]>(props, {
        valuePropName: 'selectedKeys',
        trigger: 'setSelectedKeys'
    });

    const [searchValue, setSearchValue] = useState<string>('');
    const [searchMode, setSearchMode] = useState<string>('multiple');
    const [checkedKeys, setCheckedKeys] = useState<Key[]>([]);
    const [expandedKeys, setExpandedKeys] = useState<Key[]>([]);
    const [autoExpandParent, setAutoExpandParent] = useState(false);


    const treeData: DataNode[] = useMemo(() => {
        const constructRecursiveLeaf = (data: Record<string, any>, parentKey: string): DataNode[] => {
            const nodes: DataNode[] = [];
            for (const key in data) {
                const value = data[key];
                const fullKey = parentKey ? `${parentKey}.${key}` : key;
                const isLeaf = value === null || typeof value !== 'object';
                const isSelected = selectedKeys.includes(fullKey);

                let node: DataNode = {
                    title: isSelected ? <strong>{key}</strong> : key,
                    key: fullKey,
                    isLeaf: isLeaf,
                };

                if (!isLeaf) {
                    node.children = constructRecursiveLeaf(value, fullKey)
                }

                nodes.push(node);
            }
            return nodes;
        };

        return constructRecursiveLeaf(keys, '');
    }, [keys, selectedKeys]);


    // all keys
    const flatKeys = useMemo<Key[]>(() => treeData.flatMap(function loop (node): Key[] {
        if (node.children) return [node.key, ...node.children.flatMap(loop)]
        else return [node.key]
    }),[treeData]);

    // all keys that are leaf
    const flatLeafKeys = useMemo<Key[]>(() => treeData.flatMap(function loop (node): Key[] {
        if (node.children) return [...node.children.flatMap(loop)]
        else return [node.key]
    }),[treeData]);

    const isLeaf = useCallback((key: Key) => flatLeafKeys.includes(key), [flatLeafKeys]);
    const onlyLeafs = useCallback((keys: Key[]) => keys.filter(k => isLeaf(k)), [isLeaf]);

    // get only checked leafs
    useEffect(() => {
        setCheckedLeafKeys(onlyLeafs(checkedKeys))
    }, [checkedKeys, onlyLeafs, setCheckedLeafKeys]);


    const onExpand = (keys: Key[], info:{expanded: boolean, node: DataNode}) => {
        if( !info.expanded ){
            const childrenKeys = keys.filter(k => k.toString().startsWith(info.node.key.toString()));
            setExpandedKeys(keys.filter(k => !childrenKeys.includes(k)));
        } else {
            setExpandedKeys(keys);
        }
        setAutoExpandParent(false);
    }

    const onCheck = (values: Key[]|{checked: Key[]; halfChecked: Key[]}) => {
        setCheckedKeys(Array.isArray(values) ? values : values.checked);
    };

    const onSelect = (selectedKeysValue: Key[], info:any) => {
        const selectValue = selectedKeysValue.length === 1
            ? selectedKeysValue[0]
            : (selectedKeysValue.length === 0 && info?.node?.selected === true ? info.node.key : null)

        if( selectValue ){
            const isLeafValue = isLeaf(selectValue);
            if( isLeafValue ){
                setSearchMode('single')
            }
            setSearchValue(selectValue.toString());
            findFromSearch(selectValue.toString(), true, !isLeafValue);
        }
    };

    const toggleAllCheck = (isChecked: boolean) => {
        if( isChecked ) {
            setCheckedKeys(flatKeys);
            setSelectedKeys(flatLeafKeys);
        }
        else {
            setCheckedKeys([]);
            setSelectedKeys([]);
        }
    }

    useEffect(() => {
        if( !!searchValue ) {
            findFromSearch(searchValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchMode])

    const findFromSearch = (search: string, absoluteSearch=false, searchChildren=false) => {
        const lowerSearch = search.toLowerCase().replace(/\.$/, '');
        const partSearch = lowerSearch.split('.');
        const lastPartSearch = partSearch[partSearch.length-1]
        setAutoExpandParent(true);

        if( !lowerSearch || lowerSearch.length === 0 ) {
            setSelectedKeys([]);
            setExpandedKeys([]);
        } else {
            let newExpandedKeys: React.Key[] = [];
            if( searchMode === 'values') {
                // search in default lang values, not in keys
                newExpandedKeys = Object.keys(baseFlatValues).filter(k => {
                    return baseFlatValues[k].toLowerCase()?.includes(search.toLowerCase());
                })
            }
            else {
                newExpandedKeys = flatKeys.filter(item => {
                    const lowerItem = item.toString().toLowerCase();


                    if( absoluteSearch ) {
                        return searchChildren
                            ? lowerItem.startsWith(lowerSearch)
                            : lowerItem === lowerSearch
                    }

                    if( searchMode === 'single') {
                        return lowerItem === lowerSearch;
                    }

                    if(!lowerItem.includes(lowerSearch) || !lastPartSearch || lastPartSearch.length === 0)
                        return false;
                    const partItem = lowerItem.split('.');
                    const lastPartItem = partItem[partItem.length-1]
                    return lastPartItem.includes(lastPartSearch);
                });
            }


            setExpandedKeys(newExpandedKeys);
            setSelectedKeys(onlyLeafs(newExpandedKeys));
        }
    }
    const {run: debounceSearch} = useDebounceFn(findFromSearch, {wait: 500});

    return <>
        <div style={{marginBottom: 10, height: '80px', borderBottom: "1px solid lightgray"}}>
            <div style={{display: 'flex',  gap: 8, marginBottom: 5}}>
                <div style={{flexGrow: 1}}>
                    <Search placeholder="Search keys" value={searchValue} onChange={e => {
                        setSearchValue(e.target.value);
                        debounceSearch(e.target.value);
                    }}/>
                </div>
                <div>
                    <Segmented value={searchMode} onChange={v => setSearchMode(v as string)} options={[
                        {value: 'multiple', icon: <UnorderedListOutlined />, title: "Partial search"},
                        {value: 'single', icon: <FilterOutlined />, title: "Absolute search"},
                        {value: 'values', icon: <FileSearchOutlined />, title: "Search in base values"},
                    ]}/>
                </div>
                <div>
                    <Button disabled={!searchValue || expandedKeys.length > 0} icon={<PlusOutlined/>} onClick={() => addNewKey(searchValue)}/>
                </div>
            </div>
            <Space>
                <Checkbox id="select-all" checked={flatKeys.length === checkedKeys.length} onChange={e => toggleAllCheck(e.target.checked)}>
                    <em>Check all</em>
                </Checkbox>

                <Dropdown.Button overlay={<Menu items={[
                    {key: "check-search", label: <>Check current search <strong> ({selectedKeys.length})</strong></>, onClick: () => setCheckedKeys(selectedKeys), disabled: selectedKeys.length === 0},
                    {key: "search-checked", label: <>Show current selection <strong> ({checkedLeafKeys.length})</strong></>, onClick: () => setSelectedKeys(checkedLeafKeys), disabled: checkedLeafKeys.length === 0},
                ]}/>}>
                    <>Quick actions</>
                </Dropdown.Button>
            </Space>
        </div>

        <Tree
            onExpand={onExpand}
            expandedKeys={expandedKeys}
            autoExpandParent={autoExpandParent}
            checkable
            onCheck={onCheck}
            checkedKeys={checkedKeys}
            onSelect={onSelect}
            selectedKeys={selectedKeys}
            treeData={treeData}
        />
    </>
};

export default TreeTranslationKeys;