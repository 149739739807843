import React, {useContext, useEffect, useMemo} from 'react';
import {useParams} from 'react-router';
import {Alert, Spin} from "antd";
import {useNpiStore} from "../contexts/stores";
import {observer} from "mobx-react";
import {NpiInternalContext} from "../contexts/internal-context";
import NpiConfigTabInformation from "../components/npi-config/tab-information";
import NpiDisplayTabRouter from "../components/display/tab-router";
import NpiConfigTabForm from "../components/npi-config/tab-form";
import npiApi from "../services/api";
import NpiConfigKpis from "../components/config/kpis";
import NpiConfigTabLaunch from "../components/npi-config/tab-launch";
import {useTranslation} from "react-i18next";
import useRestrictedToRights from "../hooks/use-restricted-to-rights";
import {IRightsEnum} from "../types/user";

const NpiContainerNpiConfig = observer(() => {
    useRestrictedToRights([IRightsEnum.EDIT_NPIS]);
    const {npi, select, loaded} = useNpiStore();
    const {setBreadcrumbs} = useContext(NpiInternalContext);
    const {t} = useTranslation();
    const params:any = useParams();

    //Tabs to display
    const tabs = useMemo(() => [
        {
            label: t('INTERNAL.CLIENT.COMMON.INFORMATION'),
            component: <NpiConfigTabInformation npi={npi}/>,
            url: 'information',
        },
        {
            label: t('INTERNAL.CLIENT.COMMON.FORM'),
            component: <NpiConfigTabForm npi={npi}/>,
            url: 'form',
        },
        {
            label: t('INTERNAL.CLIENT.NPI.KPIS'),
            component: <span>
                <Alert message={<span>{t('INTERNAL.CLIENT.NPI.NPI_CONFIG_KPI_ALERT')}</span>}/>
                <NpiConfigKpis item={npi} saveKpi={npiApi.internal.kpi.npiSave} defaultItem={{npi_id: npi.id, region_id: npi.region_id}}/>
            </span>,
            url: 'kpis',
        },
        {
            label: t('INTERNAL.CLIENT.COMMON.STATUS'),
            component: <NpiConfigTabLaunch npi={npi}/>,
            url: 'status',
        },
    ], [npi, t]);

    //Select our wave based on url param
    useEffect(() => {
        select(params.npiId).then(({npi}:any) => {
            setBreadcrumbs([
                {url: '/npi', name: 'NPI'},
                {url: '/npi/' + npi.id, name: npi.name},
                {url: '/npi/' + npi.id + '/config', name: t('INTERNAL.CLIENT.COMMON.CONFIGURE')},
            ]);
        });
    }, [params.npiId, select, setBreadcrumbs, t]);

    //Wait for data to load before display
    if( ! npi || ! loaded){
        return <Spin/>
    }

    return <NpiDisplayTabRouter tabs={tabs}/>
});

export default NpiContainerNpiConfig;