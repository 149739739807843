import {useContext, useEffect} from "react";
import { NpiInternalContext } from "../contexts/internal-context";
import useAcl from "./use-acl";
import {useHistory} from "react-router";
import {IRightsEnum} from "../types/user";

/**
 * Will return the user to the npi page if he has not the correct rights
 */
export default function useRestrictedToRights(rights: IRightsEnum[]) {
    const {loaded} = useContext(NpiInternalContext);
    const {hasRight} = useAcl();
    const history = useHistory();

    const hasAccess = rights.reduce((prev, curR) => prev && hasRight(curR), true);

    //Redirect elsewhere if we do not have access rights
    useEffect(() => {
        if( ! hasAccess && loaded){
            history.push('/npi');
        }
    }, [loaded, hasAccess, history]);

    return;
}