import React, {useState} from "react";
import {useControllableValue} from "ahooks";
import Comment from '@2fd/ant-design-icons/lib/Comment';
import CommentOutline from '@2fd/ant-design-icons/lib/CommentOutline';
import {Button, Input, Popover, Space} from "antd";
import styled from "styled-components";
import {useTranslation} from "react-i18next";

const StyledPopover = styled(Popover)`
.anticon{
  font-size: 20px !important;
  position:relative;
  top: 3px;
}
`;

const StyledPopoverContent = styled.div`
    min-height: 240px;
  .ant-input{
    min-height: 200px;
    width: 400px;
    resize: none;
  }
.ant-space{
  float: right;
  margin-top: 10px;
}
`;

/**
 * A date picker that accepts our string date format and outputs the same one
 * @param pickerProps
 * @param disabled
 * @param props
 * @constructor
 */
const NpiInputCommentIcon = ({ ...props}) => {
    const {t} = useTranslation();
    const [value, setValue] = useControllableValue(props);
    const [localValue, setLocalValue] = useState(value);
    const [open, setOpen] = useState(false);

    //Change our string when the moment date changes
    const onChange = (e:any) => {
        setLocalValue(e.target.value);
    };

    const onCancel = () => setOpen(false);

    const onSave = () => {setValue(localValue); setOpen(false)};

    const configPopover = {
        open,
        content: <StyledPopoverContent>
            <Input.TextArea defaultValue={value} onChange={onChange}/>
            <div>
                <Space>
                    <Button danger onClick={onCancel}>{t("COMMON.CANCEL")}</Button>
                    <Button type="primary" onClick={onSave}>{t("COMMON.SAVE")}</Button>
                </Space>
            </div>
        </StyledPopoverContent>,
        trigger: "click",
        placement: "right" as "right",
        destroyTooltipOnHide: true,
    };

    return <StyledPopover {...configPopover}>
        <Button icon={!value ? <CommentOutline/> : <Comment/>}  onClick={()=>setOpen(!open)} type="link"/>
    </StyledPopover>
};

export default NpiInputCommentIcon;
