import React, {Dispatch, useEffect, useMemo} from "react";
import {IDashboardBlockConfiguration} from "../../../types/dashboard";
import {IKpi} from "../../../types/kpi";
import {Form, Select, Slider, Switch} from "antd";
import {useControllableValue} from "ahooks";
import {useForm} from "antd/es/form/Form";
import _ from "lodash";
import {IWTag} from "../../../types/wave";
import useAcl from "../../../hooks/use-acl";
import {observer} from "mobx-react";
import NpiInputSelectTagValue from "../select-tag-value";
import {useTranslation} from "react-i18next";

interface INpiInputKpiDashboardBlockConfigKpi {
    block:IDashboardBlockConfiguration
    kpis:IKpi[]
    tags:IWTag[]
    waveIds: number[]
    onChange: Dispatch<IDashboardBlockConfiguration>
}

const defaultOptionsDivideBy = [
    {label: 'INTERNAL.CLIENT.KPI.DASHBOARD.TOTAL_POS', value: 'total'},
    {label: 'INTERNAL.CLIENT.KPI.DASHBOARD.ANOTHER_KPI', value: 'KPI'},
    {label: 'INTERNAL.CLIENT.KPI.DASHBOARD.POS_COUNT_WITH_SPECIFIC_TAG', value: 'TAG'},
    {label: 'INTERNAL.CLIENT.KPI.DASHBOARD.POS_COUNT_WITH_SPECIFIC_TAG_VALUE', value: 'TAG_VALUE'},
];

const DEFAULT_TITLE_SIZE_VALUE = 16;

const NpiInputKpiDashboardBlockConfigKpi = observer(({kpis, tags, waveIds, ...props}:INpiInputKpiDashboardBlockConfigKpi) => {
    const {t} = useTranslation();
    const [block, setBlock] = useControllableValue(props, {valuePropName: "block"});
    const [form] = useForm();
    const hasTags = useAcl().hasRegionFeatureAccess("has_tags_access");

    //
    const onValuesChange = (values:any) => {
        setBlock({..._.merge(block, values)});
    };

    //Retrieve our KPI
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const kpi = useMemo(() => _.find(kpis, {id: block.config.id}) as IKpi, [kpis]);

    //Retrieve the list of all other KPIs
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const optionsOtherKpis = useMemo(() => _.chain(kpis).filter(k => k.id !== block.config.id).map(k => ({label: k.name, value: k.id})).value(), [kpis]);
    const optionsTags = useMemo(() => _.chain(tags).map(k => ({label: k.name, value: k.id})).value(), [tags]);
    const optionsDivideBy = useMemo(() => defaultOptionsDivideBy.filter(v => hasTags || ['TAG', 'TAG_VALUE'].indexOf(v.value)<0), [hasTags]);

    //On load
    useEffect(() => {
        form.setFieldsValue(block);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <div>
        <h3>{kpi.name}</h3>
        <Form form={form} initialValues={{config: {divideBy: 'total'}}} onValuesChange={onValuesChange} labelCol={{span: 6}}>
            <Form.Item label={t('INTERNAL.CLIENT.KPI.DASHBOARD.DIVIDE_BY')} name={["config", "divideBy"]}>
                <Select
                    options={
                        optionsDivideBy
                            .map(option => ({...option, label: t(option.label)}))
                    }
                />
            </Form.Item>
            {block.config?.divideBy === 'KPI' && <Form.Item label={t('INTERNAL.CLIENT.KPI.DASHBOARD.KPI_TO_DIVIDE_BY')} name={["config", "kpiToDivideBy"]}>
                <Select options={optionsOtherKpis}/>
            </Form.Item>}
            {['TAG', 'TAG_VALUE'].indexOf(block.config?.divideBy)>=0 && <Form.Item label={t('INTERNAL.CLIENT.KPI.DASHBOARD.TAG_TO_DIVIDE_BY')} name={["config", "tagToDivideBy"]}>
                <Select options={optionsTags} placeholder={t('INTERNAL.CLIENT.TAGS.SELECT_YOUR_TAG')}/>
            </Form.Item>}
            {block.config?.divideBy === 'TAG_VALUE' && !!block.config?.tagToDivideBy && <Form.Item label={t('INTERNAL.CLIENT.TAGS.TAG_VALUE')} name={["config", "tagToDivideByValue"]}>
                <NpiInputSelectTagValue tagId={block.config.tagToDivideBy} waveIds={waveIds}/>
            </Form.Item>}
            <Form.Item label={t('INTERNAL.CLIENT.KPI.DASHBOARD.HIDE_ICON')} name={["config", "withoutIcon"]} valuePropName="checked">
                <Switch/>
            </Form.Item>
            <Form.Item label={t('INTERNAL.CLIENT.KPI.DASHBOARD.TITLE_SIZE')} name={["config", "fontSize"]} tooltip={t('INTERNAL.CLIENT.KPI.DASHBOARD.DEFAULT_VALUE', {value: DEFAULT_TITLE_SIZE_VALUE})}>
                <Slider min={6} max={32} defaultValue={DEFAULT_TITLE_SIZE_VALUE} marks={{6:6, 11:11, 16:16, 21:21, 26:26, 32:32}}/>
            </Form.Item>
        </Form>
        {/*<NpiPlaceholder>Could have gradient color configuration, or threshold configuration</NpiPlaceholder>*/}
    </div>
});

export default NpiInputKpiDashboardBlockConfigKpi;