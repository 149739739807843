import React, {ReactNode, useEffect, useMemo, useRef, useState} from "react";
import {Select, Spin} from "antd";
import { SelectProps } from 'antd/es/select';
import debounce from 'lodash/debounce';
import {useTranslation} from "react-i18next";


/**
  Select with remote search
    usage : <SelectDebounced fetchOptions: (search: string) => Promise<SelectOption[]> {...otherSelectProps}/>
*/

// extends Select params (but remove options and children properties because directly fetched from remote)
export interface IDebounceSelectProps<ValueType = any, T = any>
  extends Omit<SelectProps<ValueType>, 'options' | 'children'> {
  fetchOptions: (search: string) => Promise<T[]>;
  fetchInitial?: (value: ValueType|null|undefined) => Promise<T[]>;
  mappedBy?: (res: T) => any;
  display?: (res: T) => ReactNode|string;
  debounceTimeout?: number;
}

/**
 * Remote search and select (single/multiple)
 *
 * @param fetchOptions - promise that return list of options
 * @param fetchInitial - promise that return list of options to get initial Labels
 * @param mappedBy - callback to defined options values (default = value)
 * @param display - callback to defined options labels (default = label)
 * @param debounceTimeout - timeout to prevent to many server call when typing in search bar
 * @param props
 *
 * {@see SelectDebounced} for more options and information
 */
const SelectDebounced = <
  ValueType extends { key?: string; label: ReactNode|string; value: any } = any, T extends unknown = any
>({ fetchOptions, fetchInitial, mappedBy, display, debounceTimeout = 800, ...props }: IDebounceSelectProps<ValueType, T>) => {

  const [loaded, setLoaded] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState<ValueType[]>([]);
  const fetchRef = useRef(0);
  const {t} = useTranslation();

  const parseOption = (res: any): ValueType => {
    return {
      value: mapValue(res),
      label: displayLabel(res),
    } as ValueType;
  };

  const mapValue = (res: any): any => {
    return mappedBy?.(res) ?? res.value ?? res;
  };

  const displayLabel = (res: any): ReactNode|string => {
    return display?.(res) ?? res.label ?? res;
  };


  // Set options return by fetch function
  const callbackOptions = (fetchId: number, newOptions: T[]) => {
    if (fetchId !== fetchRef.current) {
      // for fetch callback order
      return;
    }

    // set new options
    setOptions(newOptions.map(o => parseOption(o)));
    setFetching(false);
  }

  // fetch new option from search value
  const loadOptions = (value: string) => {
    // keep order between different server call
    fetchRef.current += 1;
    const fetchId = fetchRef.current;

    // reset options
    setOptions([]);
    setFetching(true);

    fetchOptions(value).then(newOptions => callbackOptions(fetchId, newOptions));
  };

  // fetch options with delay (timeout)
  const debounceFetcher = useMemo(() => {
    // delay next fetch call
    return debounce(loadOptions, debounceTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchOptions, debounceTimeout]);


  // Get labels for initials values
  useEffect(() => {

    // TODO instead of "!loaded" check if exist value(s) that have no label or matching options
    if(!loaded && fetchInitial !== undefined ) {
      if( props.value === undefined || props.value === null || (Array.isArray(props.value) && props.value.length === 0) ){
        return;
      }

      // keep order between different server call
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setFetching(true);

      fetchInitial(props.value)
          .then(newOptions => callbackOptions(fetchId, newOptions))
          .then(() => setLoaded(true));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded, props.value]);

  return (
    <Select<ValueType>
      filterOption={false}
      onFocus={() => loadOptions('')}
      showSearch
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      placeholder={t("INTERNAL.CLIENT.COMMON.START_TYPING")}
      style={{minWidth: '200px', maxWidth: '500px'}}
      {...props}
      options={options}
    />
  );
}

export default SelectDebounced;