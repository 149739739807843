import React, {useCallback, useContext, useEffect, useState} from 'react';
import {useHistory} from 'react-router';
import {Badge, Button, Dropdown, Form, Input, Menu, Modal, Space, Switch, Tag, Tooltip} from "antd";
import {PlusOutlined, ExportOutlined} from '@ant-design/icons';
import Waves from '@2fd/ant-design-icons/lib/Waves'
import Infinity from '@2fd/ant-design-icons/lib/Infinity'
import HandPointingRight from '@2fd/ant-design-icons/lib/HandPointingRight'
import Archive from '@2fd/ant-design-icons/lib/Archive'
import NpiPaginatedTable from "../components/paginated-table";
import npiApi, {npiApiDownloadFileCallback} from "../services/api";
import {NpiInternalContext} from "../contexts/internal-context";
import NpiDisplayRegion from "../components/display/region";
import NpiDisplayDate from "../components/display/date";
import NpiDisplayNumber from "../components/display/number";
import {BarChartOutlined, DashboardFilled, DeleteFilled, DownOutlined, SettingFilled} from "@ant-design/icons/lib";
import {useForm} from "antd/es/form/Form";
import NpiInputSelectRegion from "../components/input/select-region";
import {INpi, INpiStatusEnum, lobs} from "../types/npi";
import colors from "../services/colors";
import styled from "styled-components";
import useAcl from "../hooks/use-acl";
import {IRightsEnum} from "../types/user";
import {useRequest} from "ahooks";
import {Link} from "react-router-dom";
import ImageMultipleOutline from "@2fd/ant-design-icons/lib/ImageMultipleOutline";
import GoogleAnalytics from "@2fd/ant-design-icons/lib/GoogleAnalytics";
import useArchive from "../hooks/use-archive";
import moment from "moment";
import {useTranslation} from "react-i18next";
import NpiInputWaveTree from "../components/input/wave-tree";
import {nowFormat} from "../helpers/date";

const StyledArchivedDiv = styled.label`
    cursor: pointer;
    margin-right: 20px;
    button{
        position: relative;
        top: -2px;
    }
`;

const StyledTag = styled(Tag)`
    margin: auto;
`;

const StyledMenu = styled(Menu)`
   font-size: 16px;
   .anticon{ font-size: 16px;}
`;

//NPI statuses
const {DRAFT, PUBLISH, ARCHIVED} = INpiStatusEnum;

type NpiCreateForm = Pick<INpi, 'name'|'region_id'|'is_daily'>

const NpiContainerNpiList = () => {
    const history = useHistory();
    const {t} = useTranslation();
    const [visible, setVisible] = useState(false);
    const [exportVisible, setExportVisible] = useState(false);
    const {setBreadcrumbs, user, isWW} = useContext(NpiInternalContext);
    const [showArchived, setShowArchived] = useState(false);
    const [form] = useForm<NpiCreateForm>();
    const {archiveNpi, deleteNpi} = useArchive();
    const [latestChange, setLatestChange] = useState<any>(); //used to trigger table refresh
    const {hasRegionAccess, hasRight} = useAcl();
    const canEdit = hasRight(IRightsEnum.EDIT_NPIS);
    const canViewSensitive = hasRight(IRightsEnum.VIEW_SENSITIVE_DATA);
    const [checkedKeys, setCheckedKeys] = useState<number[]>([]);
    const {runAsync: createNpi, loading: loadingCreate} = useRequest<INpi,any>(npiApi.internal.npi.create, {manual: true});
    const {runAsync: downloadWaveTree, loading: loadingDownload} = useRequest(npiApi.internal.file.exportWaveTree, {manual: true});

    //Fetcher to display the list. We manage the archived status outside of the table
    const fetcher = useCallback((params) => {
        return npiApi.internal.npi.list({...params, showArchived});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showArchived, latestChange]);

    //Create the npi and change
    const onClickCreate = () => createNpi(form.getFieldsValue()).then((npi:INpi) => {
        history.push('/npi/' + npi.id + '/config');
    });

    //On click action
    const onClickAction = (row:INpi, {key}:any) => {
        if(key === 'archive') archiveNpi(row, () => setLatestChange(moment()));
        if(key === 'delete') deleteNpi(row, () => setLatestChange(moment()));
    };

    //On check wave tree item
    const onCheck = (checkedKeysValue: number[]) => {
        setCheckedKeys(checkedKeysValue);
    };

    //Export wave tree list
    const onClickExport = () => {
        downloadWaveTree({filters: {waveIds: checkedKeys}})
            .then(npiApiDownloadFileCallback(`multi-waves-pos-${nowFormat()}.xlsx`));
    };

    const menuItemsAnalyze = (row:any) => [
        !!user.is_allowed_dashboard && {key:'dashboard', icon: <DashboardFilled/>, label: <Link to={"/kpi-dashboard/npi/"+row.id}>{t('INTERNAL.CLIENT.COMMON.DASHBOARD')}</Link>, disabled: ! row.nb_kpis || ! row.waves_total},
        {key: 'gallery', icon: <ImageMultipleOutline/>, label: <Link to={"/gallery/npi/"+row.id}>{t('INTERNAL.CLIENT.COMMON.GALLERY')}</Link>},
        {key: 'battery', icon: <BarChartOutlined/>, label: <Link to={"/battery-chart/"+row.id}>{t('INTERNAL.CLIENT.COMMON.CHART')}</Link>},
    ];

    const menuItemsActions = (row:any) => [
        {key:'dashboard', icon: <SettingFilled/>, label: <Link to={"/npi/"+row.id+"/config"}>{t('INTERNAL.CLIENT.COMMON.CONFIGURE')}</Link>},
        {key: 'archive', icon: <Archive/>, label: row.status === ARCHIVED ? t('INTERNAL.CLIENT.COMMON.UNARCHIVE'):t('INTERNAL.CLIENT.COMMON.ARCHIVE')},
        {key: 'delete', icon: <DeleteFilled/>, label: t('COMMON.DELETE')},
    ];

    const columns:any =[
        {title: '#', dataIndex: 'id', sorter: true, defaultSortOrder: 'descend', filter: {mode: 'number', strict: true}},
        {title: t('INTERNAL.CLIENT.COMMON.NAME'), dataIndex: 'name', sorter: true, filter: {mode: 'text'}},
        {title: t('INTERNAL.CLIENT.COMMON.DAILY'), dataIndex: 'is_daily', sorter: true, filter: {mode: 'bool'}, render: (d: boolean) => d ? <Infinity /> : <></> },
        {title: t('INTERNAL.CLIENT.COMMON.REGION'), dataIndex: 'region_id', render: (id:number) => <NpiDisplayRegion id={id}/>, filter: {mode: 'custom', input: <NpiInputSelectRegion restrict restrictWithWW/>}},
        // {title: t('INTERNAL.CLIENT.COMMON.START_DATE'), dataIndex: 'dateStart', render: (t:moment.Moment) => t.format('L')},
        // {title: t('INTERNAL.CLIENT.COMMON.END_DATE'), dataIndex: 'dateEnd', render: (t:moment.Moment) => t.format('L')},
        {title: t('INTERNAL.CLIENT.COMMON.LOB'), dataIndex: 'lob', sorter: true, filter: {mode: 'select', options:  lobs.map(lob => ({...lob, label: t(lob.label)}))}},
        {title: t('INTERNAL.CLIENT.NPI.WAVES'), align: 'center', render: (row:any) => <Space>
                {row.waves_launched>0   && <Tooltip title={t('INTERNAL.CLIENT.NPI.LAUNCHED_WAVES')}><Badge count={row.waves_launched} style={{backgroundColor: 'green'}}/></Tooltip>}
                {row.waves_closed>0     && <Tooltip title={t('INTERNAL.CLIENT.NPI.CLOSED_WAVES')}><Badge count={row.waves_closed}/></Tooltip>}
                {row.waves_draft>0      && <Tooltip title={t('INTERNAL.CLIENT.NPI.WAVES_UNDER_CREATION')}><Badge count={row.waves_draft} style={{backgroundColor: 'grey'}}/></Tooltip>}
        </Space>},
        {title: '#'+t('INTERNAL.CLIENT.NPI.KPIS'), dataIndex: 'nb_kpis', align: 'center', render: (n:any)=><NpiDisplayNumber value={n}/>},
        {title: t('INTERNAL.CLIENT.COMMON.CREATED'), dataIndex: 'created_at', sorter: true, render: (t:any) => <NpiDisplayDate value={t}/>, filter: {mode: 'date'}},
        {title: t('INTERNAL.CLIENT.COMMON.STATUS'), dataIndex: 'status', align: 'center',
            render: (s:number, row:any) => <>
                {s === DRAFT && <StyledTag color={colors.greyDisabledText}>{t('INTERNAL.CLIENT.COMMON.DRAFT')}</StyledTag>}
                {s === PUBLISH && <StyledTag color={colors.blue}>{t('INTERNAL.CLIENT.COMMON.PUBLISHED')}</StyledTag>}
                {s === ARCHIVED && <StyledTag color={colors.darkred}>{t('INTERNAL.CLIENT.COMMON.ARCHIVED')}</StyledTag>}
            </>,
        },
        {
            title: <Space>
                <StyledArchivedDiv><Switch size="small" checked={showArchived} onChange={setShowArchived}/>{t('INTERNAL.CLIENT.COMMON.SHOW_ARCHIVED')}</StyledArchivedDiv>
                {canEdit && <Button type="primary" icon={<PlusOutlined/>} onClick={() => setVisible(true)}>{t('INTERNAL.CLIENT.NPI.CREATE_NPI')}</Button>}
                {canViewSensitive && <Button type="primary" icon={<ExportOutlined/>} onClick={() => setExportVisible(true)}>{t('COMMON.EXPORT')}</Button>}
            </Space>,
            align: 'right',
            render: (row: any) => <Space>
                {canEdit && hasRegionAccess(row.region_id) && <Dropdown overlay={<StyledMenu onClick={e=>onClickAction(row, e)} items={menuItemsActions(row) as any}/>}>
                    <Button icon={<HandPointingRight style={{fontSize: 19}}/>} size="large">
                        {t('COMMON.ACTIONS')} <DownOutlined />
                    </Button>
                </Dropdown>}
                <Button icon={<Waves />} onClick={() => {history.push('/npi/' + row.id)}} disabled={row.status === DRAFT} size="large">{t('INTERNAL.CLIENT.NPI.VIEW_WAVES')}</Button>
                {canViewSensitive && <Dropdown disabled={row.status === DRAFT} overlay={<StyledMenu items={menuItemsAnalyze(row) as any}/>}>
                    <Button icon={<GoogleAnalytics/>} size="large">
                        {t('INTERNAL.CLIENT.COMMON.ANALYZE')} <DownOutlined/>
                    </Button>
                </Dropdown>}
            </Space>,
        },
    ];

    //Set breadcrumbs at top of the page
    useEffect(() => setBreadcrumbs([{url: '/npi', name: 'NPI'}]), [setBreadcrumbs]);

    return <>
        <NpiPaginatedTable fetcher={fetcher} columns={columns} pagination={{hideOnSinglePage: true, }}/>
        <Modal title={t('INTERNAL.CLIENT.NPI.CREATE_NPI')} open={visible} onOk={onClickCreate} onCancel={() => setVisible(false)} okButtonProps={{loading: loadingCreate}}>
            <Form form={form}>
                <Form.Item label={t('INTERNAL.CLIENT.COMMON.NAME')} name="name"><Input/></Form.Item>
                <Form.Item label={t('INTERNAL.CLIENT.COMMON.REGION')} name="region_id"><NpiInputSelectRegion restrict/></Form.Item>
                {isWW
                    && <Form.Item label={t('INTERNAL.CLIENT.NPI.RECURRING_ACTIVITY')} name="is_daily"><Switch/></Form.Item>
                }
            </Form>
        </Modal>

        <Modal title={t('INTERNAL.CLIENT.NPI.EXPORT_WAVES')} open={exportVisible} onOk={onClickExport} confirmLoading={loadingDownload} onCancel={() => setExportVisible(false)}>
            <NpiInputWaveTree onChange={onCheck} value={checkedKeys}/>
        </Modal>
    </>
};

export default NpiContainerNpiList;
