import * as React from "react";
import { Table } from "antd";
import "antd/dist/antd.css";
import dragula from "dragula";
import "dragula/dist/dragula.css";
import {TableProps} from "antd/lib/table";
import {useCallback, useMemo, useRef} from "react";
import {useControllableValue} from "ahooks";
import styled from "styled-components";
import ReorderHorizontal from "@2fd/ant-design-icons/lib/ReorderHorizontal";

export interface INpiTableDragula<RecordType> extends TableProps<RecordType>{
}

const StyledTable = styled(Table)`
    .drag-icon{
        cursor: grab;
    }
`;

const getIndexInParent = (el:any) => Array.from(el.parentNode.children).indexOf(el);

export default function NpiTableDragula({columns, dataSource, ...props}:INpiTableDragula<any>) {

    const ref = useRef(null);
    const [data, setData] = useControllableValue({...props, dataSource}, {
        valuePropName: 'dataSource',
    });

    const handleReorder = useCallback((dragIndex:any, draggedIndex:any) => {
        setData((oldState: any) => {
            const newState = [...oldState];
            const item = newState.splice(dragIndex, 1)[0];
            newState.splice(draggedIndex, 0, item);
            return newState;
        });
    }, [setData]);

    //Local columns have an additional dragging icon
    const myColumns = useMemo(() => [
        {render: () => <ReorderHorizontal className="draggable drag-icon" type="swap" />},
        ...columns as any
    ], [columns]);

    React.useEffect(() => {
        let start:any;
        let end;
        const container = (ref.current as any).querySelector("tbody");
        console.log(container)
        const drake = dragula([container] as any, {
            moves: (el:any) => {
                start = getIndexInParent(el);
                return true;
            },
        });

        drake.on("drop", (el:any) => {
            end = getIndexInParent(el);
            handleReorder(start, end);
        });
    }, [handleReorder]);

    return <StyledTable ref={ref} columns={myColumns} dataSource={data} className="thetable" {...props}/>;
}
