import React, {Key, useContext, useEffect, useMemo, useState} from 'react';
import npiApi from "../../services/api";
import {NpiInternalContext} from "../../contexts/internal-context";
import {useParams} from "react-router";
import {useWaveStore} from "../../contexts/stores";
import {Button, Empty, Modal, Select, Space, Spin, Table} from "antd";
import {LinkOutlined, ReloadOutlined, SyncOutlined} from "@ant-design/icons";
import NpiContainerDashboardSyncPosDetails from "./pos-details";
import NpiDisplayLayoutContent from "../../components/display/layout-content";
import NpiDashboardSyncTooltipStatus, {ISyncStatus} from "./tooltip-status";
import NpiDashboardSyncLogs from "./logs";
import {ColumnProps} from "antd/es/table";
import { Link } from 'react-router-dom';
import {useRequest} from "ahooks";
import {useTranslation} from "react-i18next";
import NpiDisplayRegion from "../../components/display/region";
import {IWavePos} from "../../types/wave-pos";
import styled from "styled-components";
import NpiDashboardSyncAlertStatus from "./alert-status";
import NpiInputSelectWavePos from "../../components/input/select-wavepos";
import useRestrictedToAdmin from "../../hooks/use-restricted-to-admin";


export interface ISyncWavePos extends IWavePos {
    address: string;
    sync_status: ISyncStatus;
}

enum SyncDirection {
    Ext2Int = 'EXTERNAL',
    Int2Ext = 'INTERNAL',
}

const StyledSyncInfos = styled(Space)`
  position: absolute !important;
  top: 15px;
  right: 20px;
`;

const NpiContainerDashboardSyncWaveDetails = () => {
    useRestrictedToAdmin();
    const {setBreadcrumbs} = useContext(NpiInternalContext);
    const params:any = useParams();
    const {wave, select, loaded} = useWaveStore();
    const {t} = useTranslation();

    //Select our wave based on url param
    useEffect(() => {
        select(params.waveId).then(({wave, npi}:any) => {
            setBreadcrumbs([
                {url: '/dev-dashboard', name: "Dev Dashboards"},
                {url: '/dev-dashboard/sync', name: t('INTERNAL.CLIENT.DASHBOARD.DASHBOARD_SYNCHRO')},
                {url: '/dev-dashboard/sync?npis='+npi.id, name: npi.name},
                {url: '/dev-dashboard/sync/wave/' + wave.id, name: wave.name},
            ]);
        });
    }, [params.waveId, select, setBreadcrumbs, t]);


    // list of wave_pos
    const [listPos, setListPos] = useState<ISyncWavePos[]>([]);
    const {runAsync: fetchWavePos, loading: loadingPos} = useRequest<ISyncWavePos[],any>(npiApi.internal.dev.synchro.status.wave, {manual: true, onSuccess: (list) => setListPos(list)});
    const {runAsync: refreshWavePos, loading: isRefreshing} = useRequest(npiApi.internal.dev.synchro.status.wavePos, {manual: true});
    const {runAsync: resyncPos, loading: isSynchronizing} = useRequest(npiApi.internal.dev.synchro.resyncPos, {manual: true});

    //filters
    const [filterPos, setFilterPos] = useState<number[]>([]);
    const [filterColumns, setFilterColumns] = useState<string[]>([]);

    // checkbox selection of pos
    const [wavePosSelected, setWavePosSelected] = useState<ISyncWavePos[]>([]);
    const onChangeSelection = (selectedRowKeys: Key[], selectedRows: ISyncWavePos[]) => {
        setWavePosSelected(selectedRows);
    };


    // Fetch list of POS/status for current wave
    const refreshWave = () => {
        if( loaded && !!wave ) {
            fetchWavePos(wave.id,  {posIds: filterPos, filterColumns: filterColumns}).then(null)
        }
    };

    useEffect(() => {
        refreshWave();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wave, filterPos, filterColumns]);


    // refresh some lines Status
    const markLinesAsLoading = (list:ISyncWavePos[], isLoading: boolean) => {
        list.forEach((pos) => {
            pos.sync_status.loading = isLoading;
        });
        setListPos([...listPos]);
    }
    const refreshLines = (listToRefresh:&ISyncWavePos[]) => {
        if( listToRefresh.length > 0 ) {
            markLinesAsLoading(listToRefresh, true);

            refreshWavePos({wavePosIds: listToRefresh.map(wavePos => wavePos.id)})
                .then((results:any) => listToRefresh.forEach((pos) => pos.sync_status = results[pos.id].sync_status))
                .finally(() => {
                    markLinesAsLoading(listToRefresh, false);
                    setWavePosSelected(wavePosSelected.filter((p) => !p.sync_status.match));
                })
        }
    }

    // resynchronize some lines
    const [isModalSync, setIsModalSync] = useState(false);
    const [listToResync, setListToResync] = useState<&ISyncWavePos[]>([]);
    const openModalSync = (list:&ISyncWavePos[]) => {
        setIsModalSync(true);
        setListToResync(list);
    }

    const closeModalSync = () => {
        setIsModalSync(false);
        setListToResync([]);
    }

    const resyncLines = (direction:SyncDirection) => {
       if( listToResync.length > 0 ) {
            listToResync.forEach((pos) => {
                pos.sync_status.loading = true;
            });
            setListPos([...listPos]);

            resyncPos({...params, source: direction, wavePosIds: listToResync.map(pos => pos.id)})
                .then((results:any) => listToResync.forEach((pos) => pos.sync_status = results[pos.id].sync_status))
                .finally(() => {
                    listToResync.forEach((pos) => {
                        pos.sync_status.loading = false;
                    })
                    setListPos([...listPos]);
                    setWavePosSelected(wavePosSelected.filter((p) => !p.sync_status.match));
                    closeModalSync();
                })
        }
    }


    const refreshSelectionButtons = useMemo(() => <>
        <Button icon={<ReloadOutlined />} onClick={() => refreshLines(wavePosSelected)} disabled={isRefreshing || wavePosSelected.length === 0}>
            {t('INTERNAL.CLIENT.COMMON.REFRESH')} ({wavePosSelected.length})
        </Button>

        <Button icon={<SyncOutlined />} type={'primary'} onClick={() => openModalSync(wavePosSelected)} disabled={isSynchronizing || wavePosSelected.length === 0}>
            {t('INTERNAL.CLIENT.COMMON.RESYNC')} ({wavePosSelected.length})
        </Button>
    </>,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isRefreshing, isSynchronizing, wavePosSelected]);


    // table POS
    const columns:ColumnProps<ISyncWavePos>[] = [
        { title: '#'+t('INTERNAL.CLIENT.COMMON.POS'), dataIndex: 'pos_id', sorter: true },
        { title: t('INTERNAL.CLIENT.COMMON.STORE_NAME'), dataIndex: 'pos_name', sorter: true },
        { title: t('INTERNAL.CLIENT.COMMON.REGION'), dataIndex: 'region_id', sorter: true, render: (region_id) => <NpiDisplayRegion id={region_id}/>},
        { title: t('INTERNAL.CLIENT.COMMON.ADDRESS'), dataIndex: 'address' },
        { title: t('INTERNAL.CLIENT.COMMON.STATUS'), render: (pos:ISyncWavePos) => <NpiDashboardSyncTooltipStatus status={pos.sync_status}/> },
        {title: <Space>{refreshSelectionButtons}</Space>, align: "right",
            render: (pos:ISyncWavePos) => {
                return <Space>
                    <Button onClick={() => refreshLines([pos])} icon={<ReloadOutlined />} size={'small'} type={"default"} disabled={pos.sync_status.loading}>{t('INTERNAL.CLIENT.COMMON.REFRESH')}</Button>
                    <Button onClick={() => openModalSync([pos])} icon={<SyncOutlined />} size={'small'} type={"primary"} disabled={pos.sync_status.loading || pos.sync_status.match}>{t('INTERNAL.CLIENT.COMMON.RESYNC')}</Button>
                </Space>;
            }
        },
    ];


    return !loaded ? <Spin/>
    : (!wave ? <Empty/> :
        <NpiDisplayLayoutContent>

            <StyledSyncInfos size={"large"}>
                <NpiDashboardSyncAlertStatus list={listPos} />
                <Link to={'/wave/' + wave.id+'/report'} target="_blank"><Button icon={<LinkOutlined />}>{t('INTERNAL.CLIENT.NPI.WAVE_REPORT')}</Button></Link>
                <NpiDashboardSyncLogs/>
            </StyledSyncInfos>

            <Space style={{marginBottom: '10px'}}>
                <NpiInputSelectWavePos value={filterPos} onChange={setFilterPos}
                                       mappedBy={wp => wp.pos_id}
                                       display={wp => `${wp.pos_id} - ${wp.f_trade_name || wp.f_legal_name}`}
                                       filters={{waves: [wave.id], group_by_pos: true}}
                                       mode={"multiple"}
                                       allowClear={true} />
                <Select value={filterColumns} onChange={setFilterColumns} placeholder={t('INTERNAL.CLIENT.DASHBOARD.SYNCHRO_COLUMN_FILTER')} mode={"tags"} allowClear={true} style={{width: '350px'}}/>
                <Button icon={<ReloadOutlined />} onClick={refreshWave} disabled={isRefreshing}>{t('INTERNAL.CLIENT.COMMON.REFRESH_ALL')}</Button>
            </Space>

            <Modal title={t('INTERNAL.CLIENT.COMMON.RESYNCHRONIZE_POS')} visible={isModalSync} confirmLoading={isSynchronizing} onCancel={closeModalSync} footer={[
                <Button key="back" onClick={closeModalSync}>{t('INTERNAL.CLIENT.COMMON.CLOSE')}</Button>
            ]}>
                {t('INTERNAL.CLIENT.NPI.HOW_TO_RESYNCHRONIZE_POS', {nbList: listToResync.length})}
                <Space style={{width: "100%", justifyContent: "center", marginTop: "20px"}} size={'large'}>
                    <Button type={"primary"} disabled={isSynchronizing} onClick={() => resyncLines(SyncDirection.Ext2Int)}>{t('INTERNAL.CLIENT.NPI.EXTERNAL_TO_INTERNAL')}</Button>
                    <Button type={"primary"} disabled={isSynchronizing} onClick={() => resyncLines(SyncDirection.Int2Ext)}>{t('INTERNAL.CLIENT.NPI.INTERNAL_TO_EXTERNAL')}</Button>
                </Space>
            </Modal>

            <Table
                loading={loadingPos}
                onChange={(d:any) => setListPos(d)}
                dataSource={listPos}
                columns={columns}
                size={'middle'}
                pagination={{hideOnSinglePage: true, pageSize: 500}}
                rowKey="id"
                onRow={((pos:ISyncWavePos) => !pos.sync_status.match ? {style: {backgroundColor: '#ff787555'}} : {})}
                rowSelection={{type: 'checkbox', onChange:onChangeSelection, selectedRowKeys: wavePosSelected.map((p) => p.id), getCheckboxProps: (pos) => ({disabled: pos.sync_status.match, key: pos.id})}}
                expandable={{expandedRowRender: (wavePos:ISyncWavePos) => <NpiContainerDashboardSyncPosDetails wavePos={wavePos}/>}}
                footer={() => <Space>
                    {refreshSelectionButtons}
                </Space>}
            />
        </NpiDisplayLayoutContent>
    )
};

export default NpiContainerDashboardSyncWaveDetails;