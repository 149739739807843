import React, {useContext} from "react";
import {NpiInternalContext} from "../../contexts/internal-context";
import _ from "lodash";
import {Tooltip} from "antd";

interface NpiDisplayLanguageTypes {
    id:number,
    short?:boolean,
}

// -- TODO TRAD BACKEND
const NpiDisplayLanguage = ({id, short=false, ...rest} : NpiDisplayLanguageTypes) => {
    const {languages} = useContext(NpiInternalContext);
    const language = _.find(languages, {id});

    if(language){
        if(short){
            return <Tooltip title={language.name}>{language.short_name}</Tooltip>
        }
        else{
            return <span>{language.name}</span>
        }
    }
    else{
        return <>???</>
    }
}

export default NpiDisplayLanguage;