import React, {Dispatch, useCallback, useMemo} from 'react';
import {observer} from "mobx-react";

import npiApi from "../../services/api";
import NpiModalTemplateUploader from "../display/modal-template-uploader";
import {notification} from "antd";
import {numberFormat} from "../../helpers/toolbox";
import _ from "lodash";
import {Trans, useTranslation} from "react-i18next";
import {nowFormat} from "../../helpers/date";

interface IManageTags {
    onChange: Dispatch<void>
    posFilters: any
    tagIdFilters?: number[]
    total: number
    waveId?: number
}

/**
 * This component is shown in a modal and is used to download/upload template of tags (for a wave)
 */
const NpiModalManageTags = observer(({onChange, total, posFilters, tagIdFilters, waveId}:IManageTags) => {
    const {t} = useTranslation();

    //Export POS tags must use filters
    const exportPosTags = useCallback((params:any) => npiApi.internal.file.exportPosTags({...params, posFilters: posFilters, tagIdFilters: tagIdFilters ?? [], waveId}), [posFilters, waveId, tagIdFilters]);
    const importPosTags = useCallback((params:any) => npiApi.internal.file.importPosTags({...params, waveId}), [waveId]);
    const hasFilters = _.filter(posFilters).length>0;

    const message = <div>
        {t('INTERNAL.CLIENT.TAGS.HOW_TO_MANAGE_TAGS')}
        <ol>
            <li>{t('INTERNAL.CLIENT.WAVE.TAB_POS.DOWNLOAD_TEMPLATE')}</li>
            <li>
                {t('INTERNAL.CLIENT.TAGS.Edit_template')}
                <br/>
                <em>{t('INTERNAL.CLIENT.TAGS.SAME_TAG_USED')}</em>
            </li>
            <li>
                {t('INTERNAL.CLIENT.WAVE.TAB_POS.UPLOAD_TEMPLATE')}
                <ul>
                    <li><em><Trans i18nKey="INTERNAL.CLIENT.TAGS.MERGE_WITH_EXISTING_TAGS" t={t} components={{ bold: <strong /> }}/></em></li>
                    <li><em><Trans i18nKey="INTERNAL.CLIENT.TAGS.EMPTY_CELLS" t={t} components={{ bold: <strong /> }}/></em></li>
                    <li><em><Trans i18nKey="INTERNAL.CLIENT.TAGS.POS_NOT_IN_FILE" t={t} components={{ bold: <strong /> }}/></em></li>
                    <li><em><Trans i18nKey="INTERNAL.CLIENT.TAGS.TAGS_NOT_IN_FILE" t={t} components={{ bold: <strong /> }}/></em></li>
                </ul>
            </li>
            <li>{t('INTERNAL.CLIENT.TAGS.CLOSE_THIS_MODAL')}</li>
        </ol>
        <em><Trans i18nKey="INTERNAL.CLIENT.TAGS.CHANGES_WILL_AUTO_SPREAD" t={t} components={{ bold: <strong /> }}/></em>
    </div>;

    const onUploadSuccess = () => {
        notification.success({message: t('INTERNAL.CLIENT.COMMON.FILE_UPLOADED_SUCCESSFULLY')});
        onChange?.();
    };

    const filename = useMemo(() => waveId
            ? `template-pos-tags-${nowFormat()}-wave-${waveId}.xlsx`
            : `template-pos-tags-${nowFormat()}.xlsx`
        , [waveId]);

    return <NpiModalTemplateUploader
        textContent={message}
        textConfirm={t('INTERNAL.CLIENT.TAGS.FILE_WILL_OVERRIDE_ALL_TAGS')}
        textDownloadButton={hasFilters ? t('INTERNAL.CLIENT.TAGS.DOWNLOAD_FILTERED_TEMPLATE', {total: numberFormat(total)}) : ''}
        axiosDownload={exportPosTags}
        axiosUpload={importPosTags}
        onChange={onUploadSuccess}
        filename={filename}
    />
});

export default NpiModalManageTags;