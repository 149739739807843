import {makeAutoObservable} from "mobx";
import npiApi from "../services/api";
import {ISaveableStore} from "../types/store";
import {IWavePos} from "../types/wave-pos";

export class WavePosStore implements ISaveableStore<IWavePos>{
    wavepos:IWavePos = {} as IWavePos;
    loaded = false;
    saving = false;

    constructor(){
        makeAutoObservable(this);
        // makeObservable(this, {
        //     wave: observable,+
        //     loaded: observable,
        //     select: action,
        // });
    }

    select = async (id:number):Promise<{wavepos:any, wave: any, npi: any}> => {
        const {wavepos, wave, npi} = await npiApi.internal.wavepos.detail(id);
        this.wavepos = wavepos;
        this.loaded = true;
        return {wavepos, wave, npi};
    }

    selectFromWaveAndPos = async (wave_id:number, pos_id:number):Promise<{wavepos:any, wave: any, npi: any}> => {
        const {wavepos, wave, npi} = await npiApi.internal.wavepos.detailWP(wave_id, pos_id);
        this.wavepos = wavepos;
        this.loaded = true;
        return {wavepos, wave, npi};
    }

    save = async (wavepos:IWavePos) => {
        this.saving = true;
        const data = await npiApi.internal.wavepos.save(wavepos);
        this.wavepos = {...this.wavepos, ...data};
        this.saving = false;
        return wavepos;
    }
}