import {useCallback, useContext, useMemo} from "react";
import { NpiInternalContext, IRegionType } from "../contexts/internal-context";
import {IRightsEnum, IUserRoleEnum} from "../types/user";
import {REGION_WW} from "../helpers/constants";

export default function useAcl() {

    const {user, regions} = useContext(NpiInternalContext);

    /**
     * TRUE if the user is an Admin (RegionAdmin or SuperAdmin
     */
    const isAdmin = useMemo(() => {
        if ([IUserRoleEnum.SUPER_ADMIN, IUserRoleEnum.REGION_ADMIN].indexOf(user.role) >= 0) {
            return true;
        }
        return false;
    }, [user])

    /**
     * Check if the current user belongs to a region and if this region has access to a permission
     * @param permission
     */
    const hasRegionFeatureAccess = useCallback((permission: keyof IRegionType): boolean => {
        const region: IRegionType | undefined = regions.find(r => r.id === user.region_id)
        let hasPermission: boolean = false;

        if (region && region[permission]) {
            hasPermission = true;
        }

        return hasPermission;
    }, [user, regions]);

    //Return TRUE if region is accessible
    const hasRegionAccess = useCallback((regionId: number) => {
        if (user.region_id === REGION_WW) return true;
        return regionId === user.region_id;
    }, [user]);

    //Returns TRUE it the user has the required rights
    const hasRight = (right: IRightsEnum) => user.rights.indexOf(right) >= 0;

    return {hasRight, hasRegionFeatureAccess, isAdmin, hasRegionAccess};
}