import React, {useMemo} from "react";
import {
    IShipment,
    IShipmentCarrier,
    ShipmentPackageQuickActionsEnum,
    ShipmentPosStatusEnum
} from "../../../types/shipment";
import {IWave} from "../../../types/wave";
import {useTranslation} from "react-i18next";
import _ from "lodash";
import {Badge, Button, Collapse, Radio, RadioChangeEvent, Tag, Tooltip} from "antd";
import ArrowTopRightThinCircleOutline from "@2fd/ant-design-icons/lib/ArrowTopRightThinCircleOutline";
import {CheckOutlined, CloseOutlined, InfoCircleOutlined, MinusOutlined} from "@ant-design/icons";
import NpiDisplayDate from "../../display/date";
import NpiInputShipmentPackageQuickActionSelect from "../../input/select-shipment-package-quick-action";


interface INpiTabShipmentWaveContentProps {
    wave: IWave,
    shipments: IShipment[],
    carriers: IShipmentCarrier[],
    onChange: (waveId: number, shipments: IShipment[]) => void
}

const NpiTabShipmentWaveContent = ({wave, shipments, carriers, onChange, ...props}: INpiTabShipmentWaveContentProps) => {
    const {t} = useTranslation();

    const shipmentsByTrackingId = useMemo(() => _.groupBy(shipments, (shipment: IShipment) => [shipment.carrier, shipment.tracking_id]), [shipments]);

    // -- When user select choose quick action
    const onSelectQuickAction = (tracking_id: string, action: ShipmentPackageQuickActionsEnum): void => {
        const updatedShipments = _.cloneDeep(shipments);
        updatedShipments.filter(x => x.tracking_id === tracking_id).map((shipment: IShipment) => {

            //@ts-ignore
            switch (ShipmentPackageQuickActionsEnum[action]) {
                case ShipmentPackageQuickActionsEnum.ALL_ASSETS_RECEIVED:
                    shipment.status = ShipmentPosStatusEnum.Received;
                    break;
                case ShipmentPackageQuickActionsEnum.ALL_ASSETS_DAMAGED:
                    shipment.status = ShipmentPosStatusEnum.Damaged;
                    break;
                case ShipmentPackageQuickActionsEnum.ALL_ASSETS_MISSING:
                    shipment.status = ShipmentPosStatusEnum.Missing;
                    break;
                case ShipmentPackageQuickActionsEnum.ALL_ASSETS_INCORRECT:
                    shipment.status = ShipmentPosStatusEnum.Incorrect;
                    break;
                case ShipmentPackageQuickActionsEnum.SHIPMENT_NOT_RECEIVED:
                    shipment.status = ShipmentPosStatusEnum.NotReceived;
                    break;
                default:
                    throw new Error(`Unhandled ${action} action.`);
            }

            return shipment;
        });

        //dispatch changes to parent component
        onChange(wave.id, updatedShipments);
    }


    // -- Change shipment status when changing the package status
    const onPackageStatusChange = (shipment: IShipment, status: ShipmentPosStatusEnum): void => {
        const updatedShipments = _.cloneDeep(shipments);

        const index = updatedShipments.findIndex(s => s.id === shipment.id);

        if (index < 0) {
            return;
        }

        updatedShipments[index].status = status;

        //dispatch changes to parent component
        onChange(wave.id, updatedShipments);
    }

    return <div>
        <Collapse defaultActiveKey={0} accordion>
            {Object.values(shipmentsByTrackingId).map((shipments: IShipment[], index: number) => {
                //find the last updated one
                const shipInfo = _.maxBy(shipments, 'created_at')!;

                const shipTrackingId = shipInfo.tracking_id;
                const shipCarrierName = shipInfo.carrier;
                const carrier = _.find(carriers, {carrier_name: shipCarrierName});
                const carrierLink = !!carrier ? carrier.tracking_url.replace('%code%', shipTrackingId) : '';
                const shipTrackingStatus = shipInfo.receipt_status_converted;
                const shipTrackingLastUpdateAt = shipInfo.created_at;


                let statusColor = 'red';
                switch (shipTrackingStatus) {
                    case 'Awaiting dispatch':
                        statusColor = 'geekblue';
                        break;
                    case 'Delivered':
                        statusColor = 'green';
                        break;
                    case 'Shipped':
                        statusColor = 'orange';
                        break;
                    case 'No Vendor Feedback':
                        statusColor = 'default';
                        break;
                }

                return  <Collapse.Panel
                    key={index}
                    header={<>
                        <Tooltip title={t('INTERNAL.CLIENT.SHIPMENT.SHIPMENT_STATS.TOTAL_PACKAGE')}>
                            <Badge count={shipments.length} style={{backgroundColor: '#1890ff', marginRight: 20}} title={''}/>
                        </Tooltip>

                        <strong>{shipCarrierName}</strong>
                        <strong style={{margin: '0 10px'}}> - </strong>
                        <strong>{t('COMMON.TRACKING_ID')} : </strong>
                        {shipTrackingId}

                        {!!carrierLink
                            && <Tooltip title={carrierLink}>
                                <Button href={carrierLink} icon={<ArrowTopRightThinCircleOutline/>} type="link" target={"_blank"} rel={"noreferrer"}/>
                            </Tooltip>}

                        <Tooltip title={<>
                            {t('INTERNAL.CLIENT.SHIPMENT.CARRIER_TRACKING_STATUS')}
                            <br/>
                            <NpiDisplayDate value={shipTrackingLastUpdateAt} format={"L LT"}/>
                        </>}>
                            <Tag color={statusColor} style={{marginLeft: 15}}>{shipTrackingStatus}</Tag>
                        </Tooltip>
                    </>}
                >

                    {shipments.length > 1 && <div style={{marginBottom: 20, paddingBottom: 20, borderBottom: '0.5px solid lightgray'}}>
                        <strong>{t('EXTERNAL.CLIENT.SHIPMENT.QUICK_PACKAGE_ACTION_PLACEHOLDER')} :</strong>
                        <NpiInputShipmentPackageQuickActionSelect shipments={shipments} onChange={(action: ShipmentPackageQuickActionsEnum) => onSelectQuickAction(shipTrackingId, action)}/>
                    </div>}

                    {shipments.map((shipment: IShipment) =>
                        <div key={shipment.id} style={{marginBottom: 20}}>

                            <div style={{marginBottom: 5}}>

                                <span style={{marginRight: 10}}>{shipment.material_desc}</span>

                                <Tooltip title={<ul>
                                    <li><strong>{t('INTERNAL.CLIENT.SHIPMENT.MATERIAL_NUMBER')} :</strong> <em>{shipment.material_number}</em></li>
                                    {(shipment.material_quantity && parseInt(shipment.material_quantity) > 0)
                                        ? <li><strong>{t('INTERNAL.CLIENT.SHIPMENT.MATERIAL_QUANTITY')} :</strong> {shipment.material_quantity}</li> : ''}
                                </ul>}>
                                    <InfoCircleOutlined />
                                </Tooltip>
                            </div>

                            {/* Build status from ShipmentPosStatusEnum enum */}
                            <Radio.Group value={shipment.status} onChange={(e: RadioChangeEvent) => onPackageStatusChange(shipment, e.target.value as ShipmentPosStatusEnum)}>
                                {(Object.keys(ShipmentPosStatusEnum) as Array<keyof typeof ShipmentPosStatusEnum>).map((key) => {

                                    let color: string = 'orange';
                                    let icon: React.ReactElement = <MinusOutlined/>;
                                    if( ShipmentPosStatusEnum[key] === ShipmentPosStatusEnum.Received ) {
                                        color = 'green';
                                        icon = <CheckOutlined/>;
                                    } else if( ShipmentPosStatusEnum[key] === ShipmentPosStatusEnum.Damaged ) {
                                        color = 'red';
                                        icon = <CloseOutlined/>;
                                    }

                                    return <Radio.Button key={key} style={{color}} value={ShipmentPosStatusEnum[key]}>
                                        {icon} {t(`EXTERNAL.CLIENT.SHIPMENT.STATUS.${ShipmentPosStatusEnum[key].toUpperCase()}`)}
                                    </Radio.Button>
                                })}
                            </Radio.Group>
                        </div>
                    )}
                </Collapse.Panel>
            })}
        </Collapse>
    </div>
}

export default NpiTabShipmentWaveContent;