import {IKpi} from "../../types/kpi";
import _ from "lodash";
import NpiDisplayNumber from "../display/number";
import React from "react";
import colors from "../../services/colors";
import styled from "styled-components";
import {Table} from "antd";
import NpiWwDashboardKpi from "./kpi";
import NpiContainerWwDashboardProgress from "./progress";
import {useTranslation} from "react-i18next";

const printWidth = 1422;

const StyledTable = styled(Table)`
    margin-bottom: 20px;
    .ant-table table{
        min-width: 99% !important;
    }
    .ant-progress-circle .ant-progress-text{
      font-size: 10px;
      color: #000;
    }
    thead th{
        border: 1px solid ${colors.greyBorder};
        &:not(:first-child){border-left: 0;}
        &:before{content: none !important;}
    }
    tbody tr:not(.ant-table-measure-row) td{
        padding: 4px 4px !important;
    }
    .sub_line .sub_region{
        font-style: italic;
        padding-left: 30px !important;
        line-height: 12px;
    }
    .sub_line, .muted {
        font-style: italic;
        color: ${colors.greyMutedText};
    }
    .total .sub_region{
        font-weight: bold;
    }
    .grand_total{
        font-weight: bold;
    }
    
    .percent{
        font-size: 8px;
    }
    .ant-table-cell-fix-left{
        z-index: 100; //to go over the progress bar
    }
    
    //Remove the "WW" from the second column in print mode and outside print mode
    .grand_total .sub_region:nth-child(3), .grand_total .sub_region:nth-child(2){
        font-size: 0;
    }
    
    &.print{
        font-family: "SF Hello";
        padding: 10px;
        width: ${printWidth}px;
        
        //Layout change (width, padding & borders)
        table{
            width: ${printWidth}px;
        }
        thead .ant-table-cell{
            border: 0;
        }
        tbody .ant-table-cell{
            padding: 2px 8px !important;
        }
        
        //font size changes
        .title{
            font-size: 22px;
        }
        thead tr:nth-child(2) .ant-table-cell{
            font-size: 6px;
        }
        thead .region, thead .sub_region{
            font-size: 0 !important;
        }
    
        //background
        .ant-table-cell{
            background: #f6f6f6;
            border: 0;
        }
        
        //Lines to separate regions
        tbody .ant-table-cell.region-first-row{
            //border-top: 2px solid #8c8c8c;
            padding: 8px 8px 2px 8px !important;
            position: relative;
            &:after{
                content: ' ';
                width: 100%;
                height: 2px;
                background-color: #8c8c8c;
                position: absolute;
                z-index: 10;
                left: 0;
                top: 2px;
            }
        }
        .sub_line .sub_region{
            padding-left: 30px !important;
        }
        
        //gaps
        .gap-white{
            background-color: #fff;
        }
        
        //Table border-radius
        thead .ant-table-cell.rounded-left{
            border-top-left-radius: 15px;
        }
        thead .ant-table-cell.rounded-right{
            border-top-right-radius: 15px;
        }
        .grand_total .ant-table-cell.rounded-left{
            border-bottom-left-radius: 15px;
        }
        .grand_total .ant-table-cell:last-child, .grand_total .ant-table-cell.rounded-right{
            border-bottom-right-radius: 15px;
        }
        
        //Table padding
        .ant-table-tbody > tr.grand_total > td{
            padding-bottom: 10px !important;
        }
    }
`;

/**
 * The table showing the WW data
 * @param data
 * @param kpis
 * @param configuration
 * @param printMode
 * @constructor
 */
const NpiWwDashboardTable = ({data, kpis=[], configuration=[], printMode=false}:{data:any, kpis?: IKpi[], configuration?:any, printMode?:boolean}) => {

	//Remove removedKpis
    const removedKpis = configuration?.removedKpis??[];
    const localKpis = _.filter(kpis, (kpi) => removedKpis.indexOf(kpi.id)<0);
    const barWidth = 265;
    const {t} = useTranslation();

    //Computes the rowspan of the ww_region_name column
    const rowSpanCompute = (row: any, index: number) => {
        if(index && data[index-1].ww_region_name === row.ww_region_name){
            return {rowSpan: 0};
        }
        return {
            rowSpan: _.filter(data, (r:any) => r.ww_region_name === row.ww_region_name).length,
            className: 'region-first-row',
        };
    };

    //Compute class for some rows
    const regionClassNameCompute = (row: any, index:number) => {
        if(index && data[index-1].ww_region_name === row.ww_region_name){
            return {};
        }
        else{
            return {
                className: 'region-first-row',
            };
        }
    };

    const config = {
        dataSource: data,
        rowKey: 'ww_sub_region_name',
        className: printMode ? 'print':'',
        pagination: false as false,
        scroll: {} as any,
        size: 'small' as 'small',
        rowClassName: (record:any) => record.class_name,
        columns: [
            {title: t('INTERNAL.CLIENT.COMMON.REGION'), dataIndex: "ww_region_name", className: 'region', width: 70, onCell: rowSpanCompute as any, fixed: !printMode},
            {title: t('INTERNAL.CLIENT.COMMON.SUB_REGION'), dataIndex: "ww_sub_region_name", className: 'sub_region', width: 130, onCell: regionClassNameCompute as any, fixed: !printMode},
            {title: t('INTERNAL.CLIENT.COMMON.BRANDED'), className: 'title', children: [
                    {title: "#"+t('INTERNAL.CLIENT.COMMON.POS'), render: (row:any) => <NpiDisplayNumber value={row.branded.pos_count??0}/>, width: 55, align: 'right' as 'right', onCell: regionClassNameCompute as any},
                    {title: t('INTERNAL.CLIENT.DASHBOARD.TOTAL_READINESS'), render: (row:any) => <NpiContainerWwDashboardProgress data={row.branded} kpis={kpis} configuration={configuration}/>, width: barWidth, align: 'center' as 'center', onCell: regionClassNameCompute},
                    ...(localKpis??[]).map((kpi:IKpi) => ({
                        title: <span>{kpi.name}</span>,
                        align: 'center' as 'center',
                        render: (row:any) => <NpiWwDashboardKpi data={row.branded} kpi={kpi}/>,
                        width: 55,
                        onCell: regionClassNameCompute,
                    })),
                ]},
            {title:  t('INTERNAL.CLIENT.COMMON.NON_BRANDED'), className: 'title', children: [
                    {title:  "#"+t('INTERNAL.CLIENT.COMMON.POS'), render: (row:any) => !row.non_branded.pos_count ? '':<NpiDisplayNumber value={Number(row.non_branded.pos_count)}/>, width: 55, align: 'right' as 'right', onCell: regionClassNameCompute as any},
                    {title: t('INTERNAL.CLIENT.DASHBOARD.TOTAL_READINESS'), width: barWidth, align: 'center' as 'center', onCell: regionClassNameCompute, render: (row:any) => <>
                        {row.non_branded.grand_total[0]>0 && <NpiContainerWwDashboardProgress data={row.non_branded} kpis={kpis} configuration={configuration}/>}
                        {! row.non_branded.grand_total[0] && <span className="muted">No report for Non Branded</span>}
                    </>},
                ]},
        ],
    };

    //In print mode, add a gap between branded & non-branded
    if(printMode){
        config.columns.splice(0, 0, {className: 'rounded-left', width: 20} as any); //padding+rounded-radius
        config.columns.splice(4, 0, {
            width: printWidth - 20*4 /*padding*/ - 70 /*region*/ - 130*2 /*subregion*/ - 55*2 - barWidth*2 - 20 /*gap-white*/ - localKpis.length*55,
            className: 'rounded-right',
        } as any,); //grey gap+rounded-radius
        config.columns.splice(5, 0, {
            className: 'gap-white',
            width: 20,
        } as any,); //spacing in white
        config.columns.splice(6, 0, {className: 'rounded-left', width: 20} as any); //padding+rounded-radius
        (config.columns[7] as any).children.splice(0, 0, {..._.cloneDeep(config.columns[2])}); //sub-region added inside non-branded
        config.columns.splice(config.columns.length, 0, {className: 'rounded-right', width: 20} as any); //padding+rounded-radius at the end
    }
    else{
        //Set scrolling
        const x = 70 + 130 + 55*2 + barWidth*2 + localKpis.length*55;
        config.scroll = {x};
    }


    return <StyledTable {...config}/>;
};


export default NpiWwDashboardTable;