import React, {useMemo} from "react";
import styled from "styled-components";
import {Avatar, Button, Card, CardProps} from "antd";
import {PushpinFilled} from "@ant-design/icons/lib";
import Apple from "@2fd/ant-design-icons/lib/Apple";
import {IWavePosStatusEnum} from "../../types/wave-pos";
import {useTranslation} from "react-i18next";
import Store from "@2fd/ant-design-icons/lib/Store";
import NpiDisplayPosStatus from "../display/pos-status";
import NpiDisplayProgram from "../display/program";
import {IExternalPos} from "../../types/pos";

// -- Determine color for pos by given apple id
export const getPosColorByAppleId = (appleId: number): string => {
    const avatarColors = [
        "#356ec9",
        "#5856d6",
        "#555555",
        "#138326",
        "#827030",
        "#4b7887",
        "#9b3648",
        "#8060b3",
        "#127d77",
        "#80167c"
    ];

    return avatarColors[appleId % 10];
}


interface INpiExternalDisplayPosCardProps {
    pos: IExternalPos,
    showStatus?: boolean,
    showPlanogram?: boolean,
    cardProps?: CardProps
}

// return the global POS status from the different WavePos status
const computePosStatus = (wavePosStatus: IWavePosStatusEnum[]): IWavePosStatusEnum => {
    if(wavePosStatus.includes(IWavePosStatusEnum.NEUTRAL)){
        return IWavePosStatusEnum.NEUTRAL;
    }
    if(wavePosStatus.includes(IWavePosStatusEnum.NEGATIVE)){
        return IWavePosStatusEnum.NEGATIVE;
    }
    return IWavePosStatusEnum.POSITIVE;
}

/**
 * Pos detail information card
 */
const NpiExternalDisplayPosCard = ({pos, showStatus = false, showPlanogram = false, cardProps = undefined, ...props}: INpiExternalDisplayPosCardProps) => {
    const {t} = useTranslation();

    const status = useMemo<IWavePosStatusEnum>(() => computePosStatus(pos.status), [pos.status]);

    return <StyledCard {...cardProps}>
        <div style={{display: 'flex', gap: 15, justifyContent: "space-between", flexWrap: "wrap"}}>
            <div style={{display: 'flex', gap: 15, justifyContent: "space-between", flexWrap: "nowrap", flexGrow: 1}}>

                {/* -- POS infos   */}
                <div style={{display: 'flex', gap: 15}}>
                    <Avatar style={{backgroundColor: getPosColorByAppleId(pos.pos_id), minWidth: 32}}>
                        <Apple/>
                    </Avatar>
                    <div>
                        <h2>{pos.store_name}</h2>
                        <p><b>{t('INTERNAL.CLIENT.COMMON.APPLE_ID')}</b>: {pos.pos_id} - <b>{t('INTERNAL.CLIENT.COMMON.PROGRAM')}</b>: <NpiDisplayProgram value={pos.program_name}/></p>
                        <p><PushpinFilled/> {pos.city}, {pos.zip}</p>
                    </div>

                </div>

                {/* -- POS status   */}
                {showStatus && <div style={{marginLeft: 'auto'}}>
                    <NpiDisplayPosStatus status={status} style={{fontSize: 15}}/>
                </div>}
            </div>

            {/* -- Planogram Button */}
            {showPlanogram && <div style={{flexGrow: 10, textAlign: "right"}}>
                <Button type={"primary"}>
                    <a href={`https://posplano.com/${pos?.pos_id}`} target="_blank"
                       rel="noopener noreferrer">
                        <Store/> Planogram
                    </a>
                </Button>
            </div>}
        </div>
    </StyledCard>
}

const StyledCard = styled(Card)`
    font-family: "Montserrat", sans-serif;
    border-radius: 5px;
    margin-bottom: 10px;
    margin-left: 5px;
    margin-right: 5px;

    .ant-card-body {
        padding: 10px;

        .ant-avatar {
            margin: 11px 4px 4px;
            display: block;
        }

        h2 {
            margin: 0;
            padding: 0;
            font-size: 0.9em;
            font-weight: bold;
        }

        p {
            margin: 0;
            font-size: 0.8em;
            color: #8C8989;
        }
    }
`;

export default NpiExternalDisplayPosCard;