import {makeAutoObservable} from "mobx";
import npiApi from "../services/api";
import {IWave} from "../types/wave";
import _ from "lodash";
import {ISaveableStore} from "../types/store";

export class WaveStore implements ISaveableStore<IWave>{
    wave:IWave = {} as IWave;
    loaded = false;
    saving = false;

    constructor(){
        makeAutoObservable(this);
        // makeObservable(this, {
        //     wave: observable,+
        //     loaded: observable,
        //     select: action,
        // });
    }

    select = async (id:number):Promise<{wave:IWave, npi: any}> => {
        this.loaded = false;
        const {wave, npi} = await npiApi.internal.wave.detail(id);
        wave.questions = _.sortBy(wave.questions, 'sequence');
        this.wave = wave;
        this.loaded = true;
        return {wave, npi};
    }

    save = async (wave:IWave) => {
        this.saving = true;
        const data = await npiApi.internal.wave.save(wave).finally(() => this.saving = false);
        this.wave = {...this.wave, ...data};
        return wave;
    }
}