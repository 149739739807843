import React from "react";
import {Select} from "antd";
import {useControllableValue} from "ahooks";
import {useTranslation} from "react-i18next";

const status = [
    {value: "Pre-Closing", label: "PRE_CLOSING"},
    {value: "Pre-Opening", label: "PRE_OPENING"},
    {value: "Open", label: "OPEN"},
    {value: "Cancelled", label: "CANCELLED"},
    {value: "Closed", label: "CLOSED"},
]

/**
 * Npi pos status filter
 * @constructor
 */
const NpiInputSelectStatus = (props: any) => {
    const {t} = useTranslation();
    const [state, setState] = useControllableValue<null|number|number[]>(props, {
        defaultValue: [],
    });

    return (
        <Select
            options={status.map(statut => {
                return {...statut, label: t(`INTERNAL.CLIENT.POS.STATUS.${statut.label}`)};
            })}
            mode="multiple"
            value={state}
            onChange={setState}
            placeholder={`${t('INTERNAL.CLIENT.COMMON.SELECT_ONE_OR_MORE')} ${t('INTERNAL.CLIENT.COMMON.POS_STATUSES')}`}
        />
    );
}

export default NpiInputSelectStatus;