import React, {useState} from 'react';
import {Alert, Button, Card, Modal, Space} from "antd";
import {CloseOutlined, PlusCircleFilled, SaveOutlined} from "@ant-design/icons/lib";
import NpiInputCondition from "../input/condition";
import {
    ICondition, IConditionActionDisplayEnum,
    IConditionActionTargetEnum,
    IConditionRuleTargetEnum,
    IConditionRulingEnum
} from "../../types/condition";
import NpiDisplayCondition from "../display/condition";
import _ from "lodash";
import {observer} from 'mobx-react';
import NpiSwapper from "../display/swapper";
import {IWithQuestions} from "../../types/question";
import {ISaveableStore} from "../../types/store";
import useAcl from "../../hooks/use-acl";
import {Trans, useTranslation} from "react-i18next";

//Condition loaded by default, or when adding a new condition
const defaultCondition = {
    id: null,
    rules: [{target: IConditionRuleTargetEnum.QUESTION, questionId: undefined, state: undefined, errors: ['Empty']}],
    actions: [{display: IConditionActionDisplayEnum.HIDE, target: IConditionActionTargetEnum.QUESTION, errors: ['Empty']}],
    ruling: IConditionRulingEnum.ALL,
};

const {QUESTION, TAG, MERCHANDISING_GROUP, POS_FIELD} = IConditionRuleTargetEnum;

interface INpiConfigFormConditions<T> {
    item: IWithQuestions
    store: ISaveableStore<T>
}

const NpiConfigFormConditions = function <T>({item, store}: INpiConfigFormConditions<T>) {
    const {t} = useTranslation();
    const [condition, setCondition] = useState<any>(defaultCondition);
    const [page, setPage] = useState<number>(0);
    const {save: saveItem, saving} = store;
    const [disabledSave, setDisabledSave] = useState(false);
    const {conditions} = item;
    const hasTags = useAcl().hasRegionFeatureAccess("has_tags_access");
    const hasMerchandisingGroup = useAcl().hasRegionFeatureAccess("has_merchandising_groups_access");
    const rulesTargets = [QUESTION, POS_FIELD];
    if (hasTags) rulesTargets.push(TAG);
    if (hasMerchandisingGroup) rulesTargets.push(MERCHANDISING_GROUP);


    //Return TRUE if condition is valid
    const isValid = (condition: ICondition) => {
        return _.filter(condition.rules, r => r.errors?.length).length === 0 && _.filter(condition.actions, a => a.errors?.length).length === 0;
    };

    //Add a new condition
    const onAdd = () => {
        onChangeCondition(_.cloneDeep(defaultCondition));
        setPage(1);
    };

    //Open a condition to edit it
    const onEdit = (condition: ICondition) => {
        onChangeCondition(condition);
        setPage(1);
    };

    //Remove a condition
    const onRemove = (condition: ICondition) => {
        console.log('REMOVE', condition);
        Modal.confirm({
            title: t('INTERNAL.CLIENT.COMMON.ARE_YOU_SURE'),
            onOk: () => {
                const position = conditions.indexOf(condition);
                if (position >= 0) {
                    conditions.splice(position, 1);
                    saveItem({...item, conditions} as any);
                }
            }
        })
    };

    //On change condition
    const onChangeCondition = (condition: ICondition) => {
        setCondition(condition);
        setDisabledSave(!isValid(condition));
    };

    //Save current edited condition
    const onSave = () => {
        if (!isValid(condition)) {
            return;
        }
        let newConditions = conditions ?? [];
        if (!condition.id) {
            //Insert a new condition: compute id, and push it
            condition.id = newConditions.length ? Number(_.maxBy(newConditions, 'id')?.id) + 1 : 1;
            newConditions = [...newConditions, condition];
        } else {
            //Existing condition, find it and replace with the new configuration
            const found = _.find(newConditions, {id: condition.id});
            if (found) {
                newConditions.splice(newConditions.indexOf(found), 1, condition);
                newConditions = [...newConditions];
            }
        }
        saveItem({...item, conditions: newConditions} as any).then(onClose);
    };

    //Upon closing go to original slide
    const onClose = () => {
        setPage(0);
    };

    return <NpiSwapper page={page} style={{minHeight: 800}}>
        <div>
            <Button block onClick={onAdd} type="primary" icon={<PlusCircleFilled/>} size="large">
                {t('INTERNAL.CLIENT.CONDITION.ADD_NEW_CONDITION')}
            </Button>
            <Space direction="vertical" style={{width: '100%', marginTop: 10}}>
                {conditions?.map(c =>
                    <NpiDisplayCondition key={c.id} condition={c} questions={item.questions} tags={item.tags}
                                         onEdit={onEdit} onRemove={onRemove}/>
                )}
            </Space>
            {
                !conditions?.length && <Alert message={
                    <Trans
                        i18nKey="INTERNAL.CLIENT.CONDITION.EMPTY_CONDITION"
                        t={t}
                        components={{button: <Button onClick={onAdd} type="link" icon={<PlusCircleFilled/>}/>}}
                    />
                } style={{margin: 20}}/>
            }

        </div>
        <Card title={t('INTERNAL.CLIENT.CONDITION.EDIT_CONDITION')}
              extra={<Button type="text" icon={<CloseOutlined/>} onClick={onClose} size="large"/>}>
            {condition !== null && <NpiInputCondition questions={item.questions} tags={item.tags} condition={condition}
                                                      onChange={onChangeCondition} rulesTargets={rulesTargets}/>}
            <Button type="primary" icon={<SaveOutlined/>} onClick={onSave} style={{marginTop: 20, float: 'right'}}
                    loading={saving} disabled={disabledSave}>{t('COMMON.SAVE')}</Button>
        </Card>
    </NpiSwapper>
};

export default observer(NpiConfigFormConditions);