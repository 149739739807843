import {useContext, useEffect} from "react";
import { NpiInternalContext } from "../contexts/internal-context";
import useAcl from "./use-acl";
import {useHistory} from "react-router";

/**
 * Will return the user to the npi page if he is not an admin
 */
export default function useRestrictedToAdmin() {
    const {loaded} = useContext(NpiInternalContext);
    const {isAdmin} = useAcl();
    const history = useHistory();

    //Redirect elsewhere if we do not have access rights
    useEffect(() => {
        if( ! isAdmin && loaded){
            history.push('/npi');
        }
    }, [loaded, isAdmin, history]);

    return;
}