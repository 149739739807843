import ToggleSwitch from "@2fd/ant-design-icons/lib/ToggleSwitch";
import OrderBoolAscending from "@2fd/ant-design-icons/lib/OrderBoolAscending";
import OrderBoolAscendingVariant from "@2fd/ant-design-icons/lib/OrderBoolAscendingVariant";
import CalendarMonth from "@2fd/ant-design-icons/lib/CalendarMonth";
import TextBoxOutline from "@2fd/ant-design-icons/lib/TextBoxOutline";
import FileImageOutline from "@2fd/ant-design-icons/lib/FileImageOutline";
import Counter from "@2fd/ant-design-icons/lib/Counter";
import React from "react";
import {Trans} from "react-i18next";

const questionsConfigurations = {
    BOOL: {
        icon: <ToggleSwitch />,
        // name: 'Boolean switch',
        // tooltip: 'Boolean switch',
        name: <Trans i18nKey="INTERNAL.CLIENT.QUESTION.TYPE.BOOL"/>,
        tooltip: <Trans i18nKey="INTERNAL.CLIENT.QUESTION.TYPE.BOOL"/>,
    },
    LIST: {
        icon: <OrderBoolAscending />,
        // name: 'List with one value selectable',
        // tooltip: 'List with one value selectable',
        name: <Trans i18nKey="INTERNAL.CLIENT.QUESTION.TYPE.LIST"/>,
        tooltip: <Trans i18nKey="INTERNAL.CLIENT.QUESTION.TYPE.LIST"/>,
    },
    MULTILIST: {
        icon: <OrderBoolAscendingVariant />,
        // name: 'List with multiple values selectable',
        // tooltip: 'List with multiple values selectable',
        name: <Trans i18nKey="INTERNAL.CLIENT.QUESTION.TYPE.MULTILIST"/>,
        tooltip: <Trans i18nKey="INTERNAL.CLIENT.QUESTION.TYPE.MULTILIST"/>,
    },
    DATE: {
        icon: <CalendarMonth />,
        // name: 'Date field',
        // tooltip: 'Date field',
        name: <Trans i18nKey="INTERNAL.CLIENT.QUESTION.TYPE.DATE"/>,
        tooltip: <Trans i18nKey="INTERNAL.CLIENT.QUESTION.TYPE.DATE"/>,
    },
    TEXT: {
        icon: <TextBoxOutline />,
        // name: 'Comment box',
        // tooltip: 'Comment box',
        name: <Trans i18nKey="INTERNAL.CLIENT.QUESTION.TYPE.TEXT"/>,
        tooltip: <Trans i18nKey="INTERNAL.CLIENT.QUESTION.TYPE.TEXT"/>,
    },
    NUMERIC:{
        icon: <Counter />,
        // name: 'Number field',
        // tooltip: 'Number field',
        name: <Trans i18nKey="INTERNAL.CLIENT.QUESTION.TYPE.NUMERIC"/>,
        tooltip: <Trans i18nKey="INTERNAL.CLIENT.QUESTION.TYPE.NUMERIC"/>,
    },
    IMAGE:{
        icon: <FileImageOutline />,
        // name: 'Image field',
        // tooltip: 'Image field',
        name: <Trans i18nKey="INTERNAL.CLIENT.QUESTION.TYPE.IMAGE"/>,
        tooltip: <Trans i18nKey="INTERNAL.CLIENT.QUESTION.TYPE.IMAGE"/>,
    },
}

export default questionsConfigurations;