import React from 'react';
import {Spin, Tooltip} from "antd";
import CheckCircle from "@2fd/ant-design-icons/lib/CheckCircle";
import Circle from "@2fd/ant-design-icons/lib/Circle";
import AlertCircle from "@2fd/ant-design-icons/lib/AlertCircle";
import colors from "../../services/colors";
import styled from "styled-components";
import {IWavePosStatusEnum} from "../../types/wave-pos";

const {NEUTRAL, NEGATIVE, POSITIVE} = IWavePosStatusEnum;

const Container = styled.div`
  width: 30px;
  text-align: center;
  display: inline-block;
  .anticon{
    margin: 2px;
    font-size: 26px;
  }
`;

//Tooltips
const tt = {
    [NEUTRAL]: 'The form is incomplete, some questions have not been answered.',
    [NEGATIVE]: 'The form has been completed, but some answers are negative.',
    [POSITIVE]: 'The form has been completed.',
};

/**
 * Displays the status of the POS
 * @param status
 * @param loading
 * @param msg
 * @param style
 * @constructor
 */
const NpiDisplayPosStatus = ({status, loading, msg, style = {}}:{status: IWavePosStatusEnum, loading?:boolean, msg?:string, style?: React.CSSProperties}) => {

    let content;
    if(loading){
        content = <Spin/>;
    }
    else if(status === NEUTRAL){
        content = <Circle style={{color: colors.greyDisabledText, ...style}}/>
    }
    else if(status === NEGATIVE){
        content = <AlertCircle style={{color: colors.yellow, ...style}}/>
    }
    else if(status === POSITIVE){
        content = <CheckCircle style={{color: colors.blue, ...style}}/>
    }
    else{
        content = <span>{status}</span>
    }

    return <Tooltip title={loading ? null : (msg ?? tt[status])} placement="left">
        <Container>{content}</Container>
    </Tooltip>
};

export default NpiDisplayPosStatus;