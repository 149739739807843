import {Modal} from "antd";
import React, {ReactElement, ReactNode} from "react";
import ReactDOM from 'react-dom';
import destroyFns from "antd/lib/modal/destroyFns";
import {ModalProps} from "antd/lib/modal";

type ConfigUpdate = ModalProps | ((prevConfig: ModalProps) => ModalProps);

export type ModalFunc = (props: ModalProps) => {
  destroy: () => void;
  update: (configUpdate: ConfigUpdate) => void;
};


/**
 * Allow to open a custom modal programmatically :
 * "onCancel" is call by default and can be overridden,
 * to close the modal "onOk" you have to call the destroy method manually.
 *
 * const {destroy, update} = NpiModal.display(<MyFullModal
 *    myCallbackOk={destroy}
 * />);
 */
const NpiModal = {

  create: (content: ReactNode, modalProps: ModalProps = {}) => {
    const modale = <Modal {...modalProps}>
      {content}
    </Modal>;
    return NpiModal.display(modale);
  },


  display: (modale: ReactElement<ModalProps>) => {

    const container = document.createDocumentFragment();

    let currentConfig = {
      open: true,
      onCancel: modale.props.onCancel || close,
    } as ModalProps;

    // render the current modal
    function render(renderProps:ModalProps) {
      const modaleClone = React.cloneElement<ModalProps>(modale, renderProps);
      setTimeout(() => {
        ReactDOM.render(modaleClone, container);
      });
    }

    function destroy(...args: any[]) {
      ReactDOM.unmountComponentAtNode(container);

      destroyFns.forEach((fn, i) => {
        if (fn === close) {
          destroyFns.splice(i, 1);
        }
      });
    }

    function close(...args: any[]) {
      currentConfig = {
        ...currentConfig,
        open: false,
        afterClose: () => {
          if (typeof modale.props.afterClose === 'function') {
            modale.props.afterClose();
          }
          // @ts-ignore
          destroy.apply(this, args);
        },
      };

      render(currentConfig);
    }

    function update(configUpdate: ConfigUpdate) {
      if (typeof configUpdate === 'function') {
        currentConfig = configUpdate(currentConfig);
      } else {
        currentConfig = {
          ...currentConfig,
          ...configUpdate,
        };
      }
      render(currentConfig);
    }

    render(currentConfig);
    destroyFns.push(close);

    return {
      destroy: close,
      update,
    };
  }

};

export default NpiModal;