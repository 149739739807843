import React, {useEffect, useState} from "react";
import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import {ILanguage} from "../types/language";
import {notification} from "antd";
import axios from "../services/axios";

/**
 * Default lang of the system
 */
export const DEFAULT_LANG = 'en-US';

export const loadTransFile = async (lang: string) => {
    return await require(`../../../translations/lang/${lang}.json`)
}

/**
 * Language context
 */
const NpiLanguageContext = React.createContext({
    currentLang: {} as ILanguage,
    isInitialized: false,
    availableLangs: [] as ILanguage[],
    initializeI18n: async (languages: ILanguage[]): Promise<void> => {
    },
    changeCurrentLang: (selectedLangCode: string): void => {
    },
});

/**
 * Get preferred user client language from localStorage or navigator preference, and check if required translation file is provided
 */
const getPreferredUserClientLangCode = async(): Promise<string> => {
    let currentUserLang = localStorage.getItem('USER_LANG') || window.navigator.language;

    //convert old browser (Safari, Firefox) to RFC 5646 (Safari prior to 10.2, convert country code in lowercase)
    if (currentUserLang.match(new RegExp("([a-z]{1,3})-([a-z]{1,3})", 'i'))) {
        currentUserLang = currentUserLang.substring(0, currentUserLang.indexOf('-')) + currentUserLang.substring(currentUserLang.indexOf('-')).toUpperCase();
    }


    const parentCode = currentUserLang.substring(0, 2);


    //check if preferred lang has translation file, if not check if parent lang as it
    try {
        await loadTransFile(currentUserLang);
    } catch (e) {
        //if preferred lang is not available, we need to take parent lang (fr.json instead of fr-CA.json in example)

        try {
            await loadTransFile(parentCode);
            currentUserLang = parentCode;
        } catch (e) {
            //if parent not available we peek the DEFAULT_LANG

            currentUserLang = DEFAULT_LANG;
        }

    }

    return currentUserLang;
}

/**
 * Application language context provider
 * @param children
 * @constructor
 */
const NpiLanguageContextProvider = ({children}: any) => {
    const [currentLang, setCurrentLang] = useState<ILanguage>({
        code: DEFAULT_LANG,
        short_name: '',
        name: '',
        id: 0,
        is_rtl: false,
        is_enabled_internal: true,
    });
    const [availableLangs, setAvailableLangs] = useState<ILanguage[]>([]);
    const [isInitialized, setIsInitialized] = useState<boolean>(false);

    /**
     * Filled only the code for the moment, because initializeI18n comes after "interfaces" internal api call.
     */
    useEffect(() => {
        getPreferredUserClientLangCode().then((langCode: string) => {
            //at this moment we can only set "code", we don't have languages list
            setCurrentLang((currentLang: ILanguage) => ({...currentLang, code: langCode}));

            setIsInitialized(true);
        });
    }, [])

    /**
     * Add language to all http request at header
     */
    useEffect(() => {
        if (!currentLang) {
            return;
        }

        //persist change in localStorage and state
        localStorage.setItem('USER_LANG', currentLang?.code);

        //add it to axios default header
        axios.defaults.headers['language'] = currentLang?.code;
    }, [currentLang])

    /**
     * Initialize i18n service (available languages, initializing i18n service)
     * @param languages ILanguage[]
     */
    const initializeI18n = async (languages: ILanguage[]): Promise<void> => {
        let resources: any = {};
        let availableLangs: ILanguage[] = [];

        //prevent behavior (hot loader) to re init
        if (i18n.isInitialized) {
            return;
        }

        // check that language is enabled for internal / external
        const filterLanguages = process.env.REACT_APP_VIEW_MODE === 'INTERNAL'
            ? languages.filter(lang => lang.is_enabled_internal)
            : languages
        ;

        //load json by dynamic import resources for loading it at i18n initialisation
        for (const lang of filterLanguages) {

            try {
                const langJson = await loadTransFile(lang.code);

                resources[lang.code] = {
                    translation: langJson
                }


                availableLangs.push(lang);

            } catch (e) {
                notification.warn({
                    message: `Lang file "../../../translations/lang/${lang.code}.json" is missing.`
                });
            }
        }

        //get default selected lang from user (localStorage, default lang, or available lang)
        const defaultSelectLang = await getDefaultSelectedLang(availableLangs);

        if (!defaultSelectLang) {
            notification.error({
                message: "No language available for this wave, please contact us."
            });
            return;
        }


        //initialize i18next library
        i18n
            .use(initReactI18next)
            .init({
                load: 'currentOnly',
                debug: (process.env?.REACT_APP_DEV_MODE || false) as boolean,
                resources,
                //in future retrieve it by localStorage
                lng: defaultSelectLang?.code,
                fallbackLng: Object.keys(resources),
                returnNull: false,
                returnEmptyString: false,
                interpolation: {
                    //react is secured by default against XSS
                    escapeValue: false
                },
            });


        setAvailableLangs(availableLangs);
        setCurrentLang(defaultSelectLang);
    };

    /**
     * Retrieve default selected lang which has in availableLangs
     * @param availableLangs : ILanguage[]
     */
    const getDefaultSelectedLang = async(availableLangs: ILanguage[]): Promise<ILanguage | undefined> => {
        if (!availableLangs.length) {
            return;
        }

        const preferredUserClientLangCode = await getPreferredUserClientLangCode();
        //see if preferred lang is available
        let defaultSelectedLang = availableLangs.find(
            lang =>
                lang.code === preferredUserClientLangCode
        );

        //if current lag is not available, we need to take parent lang or first available langs
        if (!defaultSelectedLang) {
            //peek parent language, in case of selected lang is not available
            defaultSelectedLang = availableLangs.find(lang => lang.code.startsWith(preferredUserClientLangCode.substring(0, 2)));


            if (!defaultSelectedLang) {
                //take DEFAULT_LANG if present in waves languages, or peek the first languages in the waves
                defaultSelectedLang = availableLangs.find(lang => lang.code === DEFAULT_LANG) ?? availableLangs[0];
            }
        }

        return defaultSelectedLang;
    }

    /**
     * Change current app lang, due to an user interaction
     * @param selectedLangCode
     */
    const changeCurrentLang = (selectedLangCode: string): void => {
        const selectedLang = availableLangs.find(lang => lang.code === selectedLangCode);

        //check if lang is available
        if (!i18n.languages.includes(selectedLangCode) || !selectedLang) {
            notification.error({
                message: `The lang ${selectedLangCode} is not available in our system.`
            });
            return;
        }

        i18n.changeLanguage(selectedLangCode);

        setCurrentLang(selectedLang);
    };


    return <NpiLanguageContext.Provider value={{
        currentLang,
        isInitialized,
        availableLangs,
        initializeI18n,
        changeCurrentLang
    }}>
        {children}
    </NpiLanguageContext.Provider>
}


export default NpiLanguageContext;
export {NpiLanguageContextProvider};


