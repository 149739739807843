import React from 'react';
import {Card, Table, Tabs} from "antd";
import {useRequest} from "ahooks";
import {CardProps} from "antd/lib/card";
import npiApi from "../../services/api";


interface IServerStatus {
    internal: Record<string, any>,
    external: Record<string, any>,
}

const NpiDashboardDebugInfos = (props: CardProps) => {

    const {loading, data: dataServer} = useRequest<IServerStatus, any>(npiApi.internal.dev.debugInfos, {
        cacheKey: "debug-infos"
    });

    const dataClient = {
        'REACT_APP_DEV_MODE': process.env.REACT_APP_DEV_MODE,
        'REACT_APP_VIEW_MODE': process.env.REACT_APP_VIEW_MODE,
        'REACT_APP_SERVER_ROOT': process.env.REACT_APP_SERVER_ROOT,
        'REACT_APP_CLIENT_ROOT': process.env.REACT_APP_CLIENT_ROOT,
        'REACT_APP_EXTERNAL_CLIENT_ROOT': process.env.REACT_APP_EXTERNAL_CLIENT_ROOT,
        'REACT_APP_EXTERNAL_SERVER_ROOT': process.env.REACT_APP_EXTERNAL_SERVER_ROOT,
        'HOST': process.env.HOST,
        'HTTPS': process.env.HTTPS,
        'PUBLIC_URL': process.env.PUBLIC_URL,
    };

    return <Card loading={loading} title={"DEBUG"} {...props}>
        <Tabs items={[
            {key: 'server-internal', label: "Server Internal", children: <TableInfos data={dataServer?.internal}/>},
            {key: 'server-external', label: "Server External", children: <TableInfos data={dataServer?.external}/>},
            {key: 'client-internal', label: "Client Internal", children: <TableInfos data={dataClient}/>},
        ]}/>
    </Card>;
};

const TableInfos = ({data}:{ data?: Record<string, any> }) => {
    const parseData = (obj?: Record<string, any>) => !!obj ? Object.entries(obj).map(([k, v]) => ({key: k, value: v})) : [];

    return <Table dataSource={parseData(data)}
                  pagination={false}
                  showHeader={false}
                  size={"small"}
                  bordered={true}
                  columns={[
                    {dataIndex: "key", render: k => <strong>{k}</strong>},
                    {dataIndex: "value"}
                  ]}
                  onRow={(({key}) => key.startsWith("###") ? {style: {
                      backgroundColor: '#b4b4b4',
                      fontWeight: 'bold',
                      textTransform: 'uppercase',
                  }} : {})}
    />
}

export default NpiDashboardDebugInfos;