import React, {useContext, useEffect, useState} from 'react';
import {useSize} from "ahooks";
import npiApi from "../services/api";
import {IWave} from "../types/wave";
import axios from '../services/axios';
import NpiLanguageContext from './language-context';
import {IExternalToken} from "../types/external-token";
import {IExternalPos} from "../types/pos";

interface IPaginatedPosList {
    list: IExternalPos[],
    left: number,
    nextAvailablePage: number|null,
    searchedText: string
}

interface INpiExternalContext {
    token: IExternalToken|null,
    tokenStr: string,
    paginatedPosList: IPaginatedPosList,
    waves: IWave[],
    isLoading: boolean,
    isInitialized: boolean,
    screenSize: { width: number, height: number },

    initDefault(): void,
    getInterface(token: string): void,
    setPaginatedPosList(paginatedPosList: IPaginatedPosList): void,
}

/**
 * Context that provides
 */
const NpiExternalContext = React.createContext<INpiExternalContext>({
    token: null,
    tokenStr: '',
    paginatedPosList: {
        list: [],
        left: 0,
        nextAvailablePage: null,
        searchedText: ''
    },
    waves: [],
    isLoading: true,
    isInitialized: false,
    screenSize: {width: 0, height: 0},

    initDefault: (): void => {},
    getInterface: (tokenStr: string): void => {},
    setPaginatedPosList: (paginatedPosList: IPaginatedPosList): void => {},
});



/**
 * Npi External context provider, with some business logic in order to authenticate the user
 * @param children
 * @constructor
 */
const NpiExternalContextProvider = ({children}: any) => {
    const {initializeI18n} = useContext(NpiLanguageContext);

    const [token, setToken] = useState<IExternalToken|null>(null);
    const [tokenStr, setTokenStr] = useState<string>("");
    const [paginatedPosList, setPaginatedPosList] = useState<IPaginatedPosList>({
        list: [],
        left: 0,
        nextAvailablePage: null,
        searchedText: ''
    });
    const [waves, setWaves] = useState<IWave[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isInitialized, setIsInitialized] = useState<boolean>(false);

    const bodySize = useSize(document.querySelector('body'));
    const screenSize = {width: bodySize?.width || 375, height: bodySize?.height || 450};

    /**
     * Watch when token change, set it to axios service
     */
    useEffect(() => {
        axios.defaults.headers.common['token'] = tokenStr;
    }, [tokenStr])

    /**
     * initialize languages
     */
    const initDefault = (): void => {
        initializeI18n([{
            id: 1,
            name: "English (US)",
            short_name: "English",
            code: "en-US",
            is_rtl: false,
            is_enabled_internal: true,
        }]).then(() => setIsLoading(false));
    }

    /**
     * Get interface from token
     */
    const getInterface = (tokenParam: string): void => {

        if (isInitialized) {
            return;
        }

        npiApi.external.getInterface(tokenParam)
            .then(async ({waves = [], languages = [], token = null}: any) => {

                setTokenStr(tokenParam);
                setToken(token);
                setWaves(waves);

                await initializeI18n(languages);
                setIsInitialized(true);
                setIsLoading(false);

            })
            .catch(async () => {
                await initializeI18n([{
                    id: 1,
                    name: "English (US)",
                    short_name: "English",
                    code: "en-US",
                    is_rtl: false,
                    is_enabled_internal: true,
                }]);
                setIsLoading(false)
            })
    };

    return <NpiExternalContext.Provider value={{
        token,
        tokenStr,
        paginatedPosList: paginatedPosList,
        waves,
        isLoading,
        isInitialized,
        screenSize: screenSize,

        initDefault,
        getInterface,
        setPaginatedPosList: setPaginatedPosList,
    }}>
        {children}
    </NpiExternalContext.Provider>
};

// Create a custom hook to easily access the NpiExternalPosContext
const useNpiExternalContext = (): INpiExternalContext => useContext(NpiExternalContext);

export {NpiExternalContextProvider, NpiExternalContext, useNpiExternalContext};