import {Alert, Tooltip} from "antd";
import {AlertTwoTone} from "@ant-design/icons";
import {useTranslation} from "react-i18next";

/**
 * Display alert on page if errors
 * @param list to check on if error
 * @constructor
 */
const NpiDashboardSyncAlertStatus = ({list}:{ list:any }) => {
    const {t} = useTranslation();

    if( !list || !list.length )
        return <></>;
    const countErrors = list.reduce((prev:number, curr:any) => (prev + (curr.sync_status && !curr.sync_status.match ? 1 : 0)), 0);
    if( countErrors <= 0 )
        return <></>;

    return <Tooltip title={<Alert message={t('INTERNAL.CLIENT.COMMON.TOOLTIP_ALERT_MESSAGE', {countErrors: countErrors, nbLines: list.length})} type="error" showIcon />}>
        <AlertTwoTone twoToneColor="rgb(247, 44, 34)" style={{fontSize: '1.5rem'}} />
    </Tooltip>
};

export default NpiDashboardSyncAlertStatus;