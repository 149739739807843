import React, {useEffect, useState} from "react";
import npiApi from "../../services/api";
import {SelectProps} from "antd/lib/select";
import {Select, Tooltip} from "antd";
import {useControllableValue, useRequest} from "ahooks";
import {useTranslation} from "react-i18next";

export interface SelectTagValue extends SelectProps<any> {
    tagId: number
    waveIds?: number[]
    npiIds?: number[]
}

/**
 * Remote search for tag available values
 *
 * @param {number} tagId
 * @param {number[]} waveIds
 * @param {number[]} npiIds
 * @param props
 */
const NpiInputSelectTagValue = ({tagId, waveIds=[], npiIds=[], ...props}:SelectTagValue) => {

    const [value, onChange] = useControllableValue(props, {
        defaultValue: null,
    });
    const [tooltip, setTooltip] = useState('');
    const [identifier, setIdentifier] = useState<string>('');
    const {t} = useTranslation();
    const {data: tagValues, run: fetchTagValues, loading} = useRequest<any[],any>(npiApi.internal.tag.values, {
        manual: true,
        onSuccess: tagValues => {
            //If the current value cannot be found in the new set, clear it
            if( ! tagValues.length){
                setTooltip(t('INTERNAL.CLIENT.TAGS.EMPTY_VALUE_LIST_DUE_TO_MISSING_TAG_ON_POS'));
            }
            else{
                setTooltip('');
            }
        }
    });

    //Observe change to tag ids & waveIds to update tag list
    useEffect(() => {
        const newIdentifier = JSON.stringify({tagId, waveIds, npiIds});
        if(identifier !== newIdentifier){
            fetchTagValues({tagId, waveIds, npiIds});
            setIdentifier(newIdentifier);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tagId, waveIds, npiIds]);

    const config = {
        value,
        loading,
        disabled: loading,
        options: tagValues??[],
        optionFilterProp: 'label',
        // mode: "multiple" as "multiple",
        onChange,
        placeholder: t("INTERNAL.CLIENT.COMMON.SELECT_VALUE"),
    };

    return <Tooltip title={tooltip}><Select {...config} {...props}/></Tooltip>
};

export default NpiInputSelectTagValue;