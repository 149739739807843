import {useRequest} from "ahooks";
import {notification} from "antd";
import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import npiApi from "../../../services/api";
import {IWave} from "../../../types/wave";
import {IWavePosImage} from "../../../types/wave-pos-image";
import {useNpiExternalPosContext} from "../../../contexts/external-pos-context";
import NpiDisplayDate from "../../display/date";
import NpiInputImageUploader, {convertWpiToUploadImage, INpiUploadImage} from "../../input/image-uploader";
import { EditOutlined } from "@ant-design/icons";
import NpiExternalModalImageEdit from "../modal-image-edit";
import NpiExternalDisplayImage from "../display-image";
import styled from "styled-components";


interface INpiTabGalleryWaveContentProps {
    wave: IWave
}

const NpiTabGalleryWaveContent = ({wave, ...props}: INpiTabGalleryWaveContentProps) => {
    const {t} = useTranslation();

    // get current POS context
    const {posId, wavePosMappedByWaveId, setWavePosMappedByWaveId} = useNpiExternalPosContext();

    // track image questions files
    const [images, setImages] = useState<INpiUploadImage[]>([]);

    const history = useMemo(() => wavePosMappedByWaveId[wave.id]?.dailyHistory, [wave, wavePosMappedByWaveId]);

    const {runAsync: uploadImageRequest, loading: uploading} = useRequest<IWavePosImage[], [INpiUploadImage[]]>((files) => npiApi.external.image.uploadPosWaveImage(posId, wave.id, files), {
        manual: true
    });

    //edit image modal
    const [editImage, setEditImage] = useState<IWavePosImage|null>(null);

    // convert images into the UploadImage format
    useEffect(() => {
        let newImages: INpiUploadImage[] = [];
        const updateImages = wavePosMappedByWaveId[wave.id].images.map(convertWpiToUploadImage);
        for(const im of updateImages) {
            const found = images.find(i =>i.uid === im.uid);
            newImages.push(!!found ? {...im, ...found} : im)
        }
        setImages(newImages);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wavePosMappedByWaveId[wave.id]])


    const onUploadImagesChange = (uploaderFiles: INpiUploadImage[]) => {
        setImages(uploaderFiles);

        const newImages: INpiUploadImage[] = uploaderFiles.filter(im => !im.wpiId);

        // if new images are added, we directly upload them and refresh the list:
        if(newImages.length > 0) {
            uploadImageRequest(newImages).then((uploadedImages: IWavePosImage[]) => {
                notification.success({message: t('EXTERNAL.CLIENT.POS_VIEW.IMAGES_UPLOAD_SUCCESS')});

                // reload all images with newly uploaded file
                const newWaveMapping = wavePosMappedByWaveId[wave.id];
                newWaveMapping.images = [...newWaveMapping.images, ...uploadedImages];
                setWavePosMappedByWaveId({
                    ...wavePosMappedByWaveId,
                    [wave.id]: {...newWaveMapping}
                })
            })
        }
    }

    const onClickEdit = (im: INpiUploadImage) => {
        const image = wavePosMappedByWaveId[wave.id].images.find(i => i.id === im.wpiId);
        if( !!image ){
            setEditImage(image);
        }
    }

    // reload all images with newly updated file
    const onUpdateImage = (updated: IWavePosImage) => {
        const newWaveImages = wavePosMappedByWaveId[wave.id].images;
        const imIndex = newWaveImages.findIndex(i => i.id === updated.id);
        if (imIndex > -1) {
            newWaveImages[imIndex] = {...newWaveImages[imIndex], ...updated};
            setWavePosMappedByWaveId({
                ...wavePosMappedByWaveId,
                [wave.id]: {...wavePosMappedByWaveId[wave.id], images: newWaveImages}
            })
        }
    }

    return <div className={"images-gallery-content"} style={{padding: "10px"}}>

        <NpiInputImageUploader
            loading={uploading}
            images={images}
            onImagesChange={onUploadImagesChange}
            isMandatory={wave.is_images_mandatory}
            max={wave.images_upload_limit}
            onRemove={false}
            onDownload={onClickEdit}
            actionIcons={{downloadIcon: <EditOutlined title={"Edit"}/>}}
        />

        <NpiExternalModalImageEdit image={editImage} setImage={setEditImage} onUpdate={onUpdateImage}/>

        {(!!history && history.length > 0) && <>
            <br/><br/><hr/>

            <div>
                {history.map(hist => {
                    return <div key={hist.wave_id} style={{margin: "20px auto"}}>

                        <small style={{fontWeight: "bold", fontStyle: "italic", textDecoration: "underline"}}>
                            <NpiDisplayDate value={hist.launched_at} format={"L"}/>
                        </small>

                        <StyledGalleryImage>
                            { hist.images.map((image: IWavePosImage) => <div key={image.id} className={"display-image-container"}>
                                <NpiExternalDisplayImage image={image} />
                            </div> )}
                        </StyledGalleryImage>
                    </div>
                })}
            </div>
        </>}
    </div>
}

const StyledGalleryImage = styled.div`
    margin-top: 8px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    .display-image-container {
        width: 100px;
        height: 100px;
        display: inline-block;
    }
`
export default NpiTabGalleryWaveContent;