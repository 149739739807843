import React, {Key, useEffect, useMemo, useState} from 'react';
import {ILanguage} from "../../types/language";
import {Button, Card, Empty, Table} from "antd";
import {TableProps} from "antd/lib/table";
import {findValueByPath} from "../../containers/dev-dashboard/dashboard-translation";
import {DEFAULT_LANG} from "../../contexts/language-context";
import {LeftOutlined, RightOutlined} from "@ant-design/icons/lib";
import {EditOutlined} from "@ant-design/icons";

interface ITableLanguageTranslationProps {
    base: Record<string, any>, // translations of reference
    translations: Record<string, any>, // map all translations by language code
    languages: ILanguage[], // list of language we want to display
    keys: Key[], // translation keys to displayed
    onUpdateKey: (lang: ILanguage, key: string) => void
}

const TableLanguageTranslation = ({base, translations, languages, keys, onUpdateKey, ...props}:ITableLanguageTranslationProps) => {

    const [currentIndex, setCurrentIndex] = useState<number>(0);
    useEffect(() => {
        setCurrentIndex(0);
    }, [keys]);

    const path = useMemo(() => {
        return keys[currentIndex] ? keys[currentIndex].toString() : ''
    }, [currentIndex, keys]);

    const baseValue = useMemo(() => findValueByPath(path, base), [path, base]);

    const mapPathValues = useMemo(() => Object.fromEntries(languages.map(l => {
        const languageValue = findValueByPath(path, translations[l.code]);
        return [l.code, languageValue]
    })), [languages, path, translations]);

    const missingCount = useMemo(() => Object.values(mapPathValues).reduce((prev, v) => !v ? prev+1 : prev, 0), [mapPathValues])

    const moveTo = (newIndex: number) => {
        if( newIndex < 0 || newIndex >= keys.length ) return;
        setCurrentIndex(newIndex);
    }

    const columns:TableProps<ILanguage>['columns'] = useMemo(() => [
        {
            dataIndex: 'name',
            width: 250,
            fixed: true,
            title: 'Language',
        }, {
            dataIndex: 'code',
            width: 100,
            fixed: true,
            title: 'Code'
        }, {
            title: <>Translation{missingCount > 0 ? ` (${missingCount} missing)` : ''}</>,
            render: (l:ILanguage) => {
                const lValue = mapPathValues[l.code];
                return <>
                    {lValue}
                    {(l.code === DEFAULT_LANG && lValue !== baseValue)
                        && <em> (default="{baseValue}")</em>
                    }
                </>
            }
        }, {
            width: 30,
            render: (l:ILanguage) => <Button icon={<EditOutlined/>} type={"primary"} ghost={true} onClick={() => onUpdateKey(l, path)}/>
        }
    ], [mapPathValues, missingCount, baseValue, onUpdateKey, path]);

    return <Card title={<div style={{display: "flex", justifyContent: "space-between", alignItems: "center", paddingTop: 3, width: '100%'}}>
        <Button icon={<LeftOutlined />} disabled={currentIndex === 0} onClick={() => moveTo(currentIndex-1)}/>
        <span>{path}</span>
        <Button icon={<RightOutlined />} disabled={currentIndex >= keys.length - 1} onClick={() => moveTo(currentIndex+1)}/>
        {keys.length > 0 && <small style={{position: 'absolute', right: '50%', top: 5, transform: "translateX(50%)", color: "darkgray"}}>{currentIndex+1}/{keys.length}</small>}
    </div>}>
        {!!path
            ? <Table
                size={"small"}
                pagination={false}
                columns={columns}
                dataSource={languages}
                rowKey={'id'}
                onRow={l => {
                    const rowStyle: React.CSSProperties = {
                        ...!mapPathValues[l.code] ? {backgroundColor: '#ff787555'} : {},
                        ...l.code === DEFAULT_LANG ? {fontWeight: 'bold'} : {},
                    };
                    return {style: rowStyle} as React.HTMLAttributes<any>
                }}
            />
            : <Empty description={"select a key to see its translations"}/>}
    </Card>
}

export default TableLanguageTranslation;