import {IKpi} from "../../types/kpi";
import React, {useMemo} from "react";
import NpiDisplayNumber from "../display/number";
import {Tooltip} from "antd";
import {IDataRowDetail} from "../../containers/ww-dashboard";
import NpiWwDashboardColoredProgressCircle from "./colored-progress-circle";
import {useTranslation} from "react-i18next";

/**
 * KPI to display a percentage value of readiness
 * @param data
 * @param kpi
 * @constructor
 */
const NpiWwDashboardKpi = ({data, kpi}:{data:IDataRowDetail, kpi:IKpi}) => {
    const prefix = 'kpi' + kpi.id + '_';
    const value = data[prefix + 'sum'];
    const total = data[prefix + 'total'];
    const ignored = data[prefix + 'ignored'];
    const exactPercent = value/total * 100;
    const ceilOrFloor = exactPercent < 99 ? 'round':'floor';
    const percent = Math[ceilOrFloor](exactPercent);
    const {t} = useTranslation();

    //Tooltip message to list
    const tooltip = useMemo(() => <ul style={{margin: 0}}>
        <li><NpiDisplayNumber value={value}/> {t('INTERNAL.CLIENT.DASHBOARD.POS_VALIDATED_KPI')}</li>
        <li>{t('INTERNAL.CLIENT.DASHBOARD.KPI_APPLICABLE_TO')} <NpiDisplayNumber value={total}/> {t('INTERNAL.CLIENT.COMMON.POS')}</li>
        {ignored>0 && <li>{t('INTERNAL.CLIENT.DASHBOARD.KPI_NOT_APPLICABLE_TO')} <NpiDisplayNumber value={ignored}/> {t('INTERNAL.CLIENT.COMMON.POS')}</li>}
    </ul>, [ignored, total, value, t]);

    return <Tooltip title={tooltip} placement="right" overlayStyle={{minWidth: 300}}>
        {total > 0 && <NpiWwDashboardColoredProgressCircle percent={percent}/>}
        {total <=0 && <div style={{height: 35, paddingTop: 7}}>N/A</div>}
    </Tooltip>
};

export default NpiWwDashboardKpi;