import React, {useCallback, useContext, useMemo, useState} from 'react';
import {Button, ConfigProvider, Empty, Modal, notification, Space} from "antd";
import {IWave} from "../../types/wave";
import {observer} from "mobx-react";
import {DeleteOutlined, FileExcelFilled, PlusOutlined, SettingFilled} from "@ant-design/icons/lib";
import npiApi, {npiApiDownloadFileCallback} from "../../services/api";
import NpiPaginatedTable from "../paginated-table";
import moment, {Moment} from "moment";
import NpiWaveTabPosSearchModal from "./tab-pos-search-modal";
import NpiDisplayDate from "../display/date";
import {useRequest} from "ahooks";
import NpiDisplayPosTags from "../display/pos-tags";
import useAcl from "../../hooks/use-acl";
import NpiDisplayCountry from "../display/country";
import NpiInputSelectCountry from "../input/select-country";
import {NpiInternalContext} from "../../contexts/internal-context";
import NpiDisplayProgram from "../display/program";
import StoreCog from '@2fd/ant-design-icons/lib/StoreCog';
import Tag from "@2fd/ant-design-icons/lib/Tag";
import TagPlus from "@2fd/ant-design-icons/lib/TagPlus";
import {Trans, useTranslation} from "react-i18next";
import NpiInputEditableTableCell from "../input/editable-table-cell";
import {IWavePos} from "../../types/wave-pos";
import {nowFormat} from "../../helpers/date";
import NpiDropdownEllipsis from "../display/dropdown-ellipsis";
import {EditOutlined, TagOutlined} from "@ant-design/icons";
import NpiModalManageTags from "../tags/modal-manage-tags";
import {numberFormat} from "../../helpers/toolbox";
import NpiModalManageMerchandisingGroups from "./modal-manage-merchandising-groups";
import NpiModalEditPosTags from "./modal-edit-pos-tags";
import {REGION_WW} from "../../helpers/constants";

const NpiWaveTabPos = observer(({wave}:{wave:IWave}) => {
    const {runAsync: exportWavePos, loading: loadingExport} = useRequest(npiApi.internal.file.exportWavePos, {manual: true});
    const {runAsync: updateTags, loading: loadingTags} = useRequest(npiApi.internal.tag.updateWave, {manual: true});
    const {runAsync: saveWavePos} = useRequest(npiApi.internal.wavepos.save, {manual: true});
    const loading = useMemo(() => loadingExport || loadingTags, [loadingExport, loadingTags]);

    const {t} = useTranslation();
    const {hasRegionFeatureAccess} = useAcl();
    const hasTags = hasRegionFeatureAccess("has_tags_access");
    const hasMerchGroupAccess = hasRegionFeatureAccess("has_merchandising_groups_access");

    const [total, setTotal] = useState<number>(0);
    const [filters, setFilters] = useState<any>({}); //track changes in filters use to download tags template
    const [latestChange, setLatestChange] = useState<Moment>(moment());
    const [visibleModalAdd, setVisibleModalAdd] = useState(false);
    const {programs, merchandisingGroups} = useContext(NpiInternalContext);


    //When the list of POS is changed, update the latest change time to trigger a new fetchList, which will refresh the table
    const onChangePos = useCallback(() => {
        setLatestChange(moment());
    }, [setLatestChange]);

    //Remove a POS directly from the list
    const onClickRemovePos = useCallback((row:any) => {
        Modal.confirm({
            title: t('INTERNAL.CLIENT.WAVE.TAB_POS.REMOVE_POS'),
            content: <span>
                <Trans i18nKey="INTERNAL.CLIENT.WAVE.TAB_POS.REMOVE_POS_CONFIRMATION" t={t} values={{ posId: row.pos_id, posName: row.pos_name}} components={{ bold: <strong /> }}/>
             </span>,
            onOk: () => npiApi.internal.wave.modifyPos({wave_id: wave.id, addedSfoIds: [], deletedAppleIds:[row.pos_id]}).then(onChangePos)
        })
    }, [wave.id, onChangePos, t]);

    //Export POS list of this wave
    const onExport = useCallback(() => {
        exportWavePos({waveId: wave.id}).then(npiApiDownloadFileCallback(`pos-wave-${wave.id}-${nowFormat()}.xlsx`));
    }, [wave, exportWavePos]);

    //Open Modal to manage tags for this wave
    const openManageTags = useCallback(() => {
        Modal.info({
            width: 800,
            icon: <TagOutlined/>,
            title: t('INTERNAL.CLIENT.TAGS.MANAGE'),
            content: <NpiModalManageTags onChange={onChangePos} posFilters={filters} total={total} waveId={wave.id}/>,
        })
    }, [t, onChangePos, filters, total, wave]);

    const openEditPosTags = useCallback((row) => {
        Modal.info({
            width: 800,
            closable: true,
            icon: <TagOutlined/>,
            title: `${t('INTERNAL.CLIENT.TAGS.MANAGE')} - #${row.pos_id} - ${row.pos_name}`,
            content: <NpiModalEditPosTags pos={row} npiId={wave.npi_id}/>,
            afterClose: onChangePos
        })
    }, [t, onChangePos, wave]);

    //Open modal to confirm tag update
    const onConfirmTagsRefresh = useCallback(() => {
        Modal.confirm({
            width: 600,
            icon: <Tag/>,
            title: t('INTERNAL.CLIENT.COMMON.UPDATE_TAGS'),
            content: <div>{t('INTERNAL.CLIENT.WAVE.TAB_POS.REFRESH_TAGS_FOR_ALL_POS')}
                        <br/>{t('INTERNAL.CLIENT.WAVE.TAB_POS.REFRESH_REMINDERS')}
                        <ul>
                            <li>{t('INTERNAL.CLIENT.WAVE.TAB_POS.REMINDER_1', {total: total})}</li>
                            <li>{t('INTERNAL.CLIENT.WAVE.TAB_POS.REMINDER_2')}</li>
                        </ul>
                     </div>,
            cancelText: t('INTERNAL.CLIENT.COMMON.NO'),
            okText: t('INTERNAL.CLIENT.COMMON.YES'),
            onOk: () => updateTags({id: wave.id}).then((data:any) => {
                notification.success({message: t('INTERNAL.CLIENT.WAVE.TAB_POS.NB_POS_WERE_UPDATED', {nbPos: data.count})});
                onChangePos()
            }),
        })
    }, [total, updateTags, wave.id, onChangePos, t]);

    //On change merchandising groups, update list and notify user
    const onChangeMerchandisingGroups = useCallback(() => {
        setLatestChange(moment());
        notification.success({
            message: t('INTERNAL.CLIENT.WAVE.TAB_POS.MERCHANDISING_GROUPS_UPDATED'),
        });
    }, [t]);

    //Open modal to upload/download merchandising groups
    const openModalMerchandisingGroups = useCallback(() => {
        Modal.info({
            icon: <StoreCog />,
            width: 800,
            title: t('INTERNAL.CLIENT.WAVE.TAB_POS.MANAGE_MERCHANDISING_GROUPS'),
            content: <NpiModalManageMerchandisingGroups waveId={wave.id} onChange={onChangeMerchandisingGroups} merchandisingGroups={merchandisingGroups}/>
        })
    }, [onChangeMerchandisingGroups, merchandisingGroups, wave.id, t]);

    //Columns
    const columns: any = useMemo(() => [
        {title: t('INTERNAL.CLIENT.COMMON.APPLE_ID'), dataIndex: 'pos_id', sorter: true, filter: {mode: 'number', strict: true}},
        {title: t('INTERNAL.CLIENT.COMMON.STORE_NAME'), dataIndex: 'pos_name', sorter: true, filter: {mode: 'text'}},
        {title: t('INTERNAL.CLIENT.COMMON.COUNTRY'), dataIndex: 'country_id', sorter: true,
            render: (v:number) => <NpiDisplayCountry id={v}/>,
            filter:{mode:'custom', input: <NpiInputSelectCountry inRegions={wave.region_id === REGION_WW ? undefined : [wave.region_id]} mode="multiple" dropdownMatchSelectWidth={false}/>}
        },
        {title: t('INTERNAL.CLIENT.COMMON.PROGRAM'), dataIndex: 'programs', sorter: true, filter: {mode: 'select', options: programs}, render: (v:string) => <NpiDisplayProgram value={v}/>},
        {title: t('INTERNAL.CLIENT.COMMON.TAGS'), dataIndex: 'tags', hidden: !hasTags,
            render: (value:any, row:any) => <Space size={'middle'}>
                <NpiDisplayPosTags value={value} tags={wave.tags}/>
                <Button onClick={() => openEditPosTags(row)} title={t('INTERNAL.CLIENT.COMMON.EDIT')} icon={<EditOutlined/>} size={'small'}/>
            </Space>,
            filter: {mode: 'tags', inputProps: {waves: [wave.id], npiId: wave.npi_id}},
        },
        {title: <Space>{t('INTERNAL.CLIENT.COMMON.MERCHANDISING_GROUP')} <Button icon={<SettingFilled/>} type="primary" onClick={openModalMerchandisingGroups}/></Space>,
            dataIndex: 'merchandising_group_id', sorter: true, hidden: !hasMerchGroupAccess, align: 'center',
            render: (_:any, row:IWavePos) => <NpiInputEditableTableCell
                defaultValue={row.merchandising_group_id}
                onSave={(newMgId:(number|null)) => saveWavePos({pos_id: row.pos_id, wave_id: wave.id, merchandising_group_id: newMgId})}
                inputType={'select'}
                options={merchandisingGroups.map(v => ({value: v.id, label: v.name}))}
                allowClear={true}
            />
        },
        {title: t('INTERNAL.CLIENT.COMMON.ADDED_ON'), sorter: true, dataIndex: 'created_at', render: (v:string) => <NpiDisplayDate value={v}/>},
        {
            title: <Space>
                <Button icon={<PlusOutlined/>} type="primary" onClick={() => setVisibleModalAdd(true)} loading={loading} title={t('INTERNAL.CLIENT.WAVE.TAB_POS.ADD_POS_TO_WAVE')}>{t('INTERNAL.CLIENT.WAVE.TAB_POS.ADD_POS_NOW')}</Button>

                <NpiDropdownEllipsis disabled={loading} items={[
                    {key: "export_all_pos", onClick:onExport, disabled: !total, icon: <FileExcelFilled/>, label: t('INTERNAL.CLIENT.WAVE.TAB_POS.EXPORT_ALL_POS')},
                    ...hasTags ? [
                        {key: "manage_tags", onClick:openManageTags, disabled: !total, icon: <TagPlus />, label: t('INTERNAL.CLIENT.TAGS.MANAGE')},
                        {key: "refresh_tags", onClick:onConfirmTagsRefresh, disabled: !total, icon: <Tag/>, label: t('INTERNAL.CLIENT.WAVE.TAB_POS.REFRESH_TAGS_INFORMATION')},
                    ] : [],
                ]}/>
            </Space>,
            align: 'right',
            render: (_:any, row:any) => <Button danger type="primary" icon={<DeleteOutlined/>} onClick={() => onClickRemovePos(row)}/>,
        },
    ].filter(v => !v.hidden), [setVisibleModalAdd, onClickRemovePos, wave, programs, hasTags, hasMerchGroupAccess, total, onExport, loading, openModalMerchandisingGroups, openManageTags, openEditPosTags, merchandisingGroups, saveWavePos, onConfirmTagsRefresh, t]);

    //Fetch POS data
    const fetchList = useCallback((params) => {
        return npiApi.internal.wave.listPos({waveId: wave.id, ...params})
            .then((data:any) => {
                setTotal(data.total);
                wave.nb_pos = data.total;
                return data;
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wave.id, latestChange]);

    //Custom message when no data
    const noData = useCallback(() =><span>
        <Empty description={null} image={Empty.PRESENTED_IMAGE_SIMPLE}/>
        This wave does not have any POS
        <br/><br/>
        <Button size="large" onClick={() => setVisibleModalAdd(true)} type="primary">{t('INTERNAL.CLIENT.WAVE.TAB_POS.ADD_POS_NOW')}</Button>
        <br/><br/>
    </span>, [setVisibleModalAdd, t]);

    return <>
        <ConfigProvider renderEmpty={noData}>
            <NpiPaginatedTable
                fetcher={fetchList}
                columns={columns}
                onFiltersChange={setFilters}
                onTotalChange={setTotal}
                pagination={{
                    defaultPageSize: 50,
                    showTotal: (total: number) => <b style={{marginRight: 10}}>{numberFormat(total)} {t('INTERNAL.CLIENT.COMMON.POS')}</b>
                }}
                size={"small"}
            />
        </ConfigProvider>
        <NpiWaveTabPosSearchModal wave={wave} open={visibleModalAdd} onCancel={() => setVisibleModalAdd(false)} onChange={onChangePos}/>
    </>
});

export default NpiWaveTabPos;