import React, {useContext, useEffect, useMemo, useState} from 'react';
import {Alert, Button, Card, Col, DatePicker, Modal, Row, Space, Table, TimePicker, Tooltip, Typography} from "antd";
import {CheckOutlined, CloseOutlined, InfoCircleFilled, PlaySquareOutlined, PoweroffOutlined} from '@ant-design/icons';
import EmailFast from "@2fd/ant-design-icons/lib/EmailFast";
import Timer from "@2fd/ant-design-icons/lib/Timer";
import TimerOff from "@2fd/ant-design-icons/lib/TimerOff";
import {observer} from "mobx-react";
import {IWave, IWaveStatusEnum} from "../../types/wave";
import _ from "lodash";
import {generateTerms} from "../../helpers/translation";
import NpiDisplayLanguage from "../display/language";
import NpiDisplayNumber from "../display/number";
import NpiDisplayWaveStatus from "../display/wave-status";
import LinkVariant from "@2fd/ant-design-icons/lib/LinkVariant";
import npiApi from "../../services/api";
import {useRequest} from "ahooks";
import {OrderedListOutlined, RocketOutlined} from "@ant-design/icons/lib";
import {openModalExternalLinks} from "../display/modal-wave-links";
import NpiDisplayListEllipsis from "../display/list-ellipsis";
import NpiDisplayCountry from "../display/country";
import useArchive from "../../hooks/use-archive";
import {getConditionErrors} from "../../helpers/condition";
import {makeUtcStringFromDate, timeZone} from "../../helpers/date";
import moment from "moment";
import NpiDisplayDate from "../display/date";
import {Trans, useTranslation} from "react-i18next";
import NpiLanguageContext from "../../contexts/language-context";

const {LAUNCHED, ARCHIVED, DELAYED_LAUNCH} = IWaveStatusEnum;
type TextProps = {children: React.ReactNode};
const Success = ({children}: TextProps) => <Typography.Text type="success"><CheckOutlined/> {children}</Typography.Text>;
const Warning = ({children}: TextProps) => <Typography.Text type="warning"><CloseOutlined/> {children}</Typography.Text>;
const Danger = ({children}: TextProps) => <Typography.Text type="danger"><CloseOutlined/> {children}</Typography.Text>;

/**
 *
 */
const NpiWaveTabLaunch = observer(({wave}:{wave: IWave}) => {
    const currentLocale = useContext(NpiLanguageContext).currentLang.code;
    const [checks, setChecks] = useState<any[]>([]);
    const [contacts, setContacts] = useState<any[]>([]);
    const {t} = useTranslation();
    const [selectedContacts, setSelectedContacts] = useState<React.Key[]>([]);
    const nbInvalidChecks = _.filter(checks, {isValid: false}).length;
    const {status} = wave;
    const isLaunchedOrDelayed = status === LAUNCHED || status === DELAYED_LAUNCH;
    const {archiveWave, loadingArchiveWave} = useArchive();
    const [delayedLaunch, setDelayedLaunch] = useState({
        visible: false,
        timestamp: null as any,
    });

    //
    const afterStatusChange = ({wave: newWave}:{wave:IWave}) => _.extend(wave, newWave);
    const {runAsync: requestLaunch, loading: loadingLaunch} = useRequest(() => npiApi.internal.wave.launch(wave), {manual: true, onSuccess: afterStatusChange});
    const {runAsync: onClose, loading: loadingStop} = useRequest(() => npiApi.internal.wave.close(wave), {manual: true, onSuccess: afterStatusChange});
    const {runAsync: requestNotify, loading: loadingNotify} = useRequest(npiApi.internal.wave.notify, {manual: true});
    const {runAsync: requestDelayedLaunch} = useRequest(npiApi.internal.wave.delayedLaunch, {manual: true, onSuccess: afterStatusChange});
    const {runAsync: requestCancelDelayedLaunch} = useRequest(npiApi.internal.wave.cancelDelayedLaunch, {manual: true, onSuccess: afterStatusChange});
    const loading = loadingLaunch || loadingStop || loadingArchiveWave;
    const toggleArchive = () => archiveWave(wave, afterStatusChange);

    //Launch the wave, then display the contacts modal
    const onLaunch = () => {
        //Open contact list, if any
        requestLaunch().then(({contacts}:any) => {
            setContacts(contacts)
            setSelectedContacts(_.map(contacts, 'id'));
        });
    };

    //Open the Delayed launch modal
    const onDelayedLaunch = () => {
        const date = moment();
        const remainder = 5 - (date.minute() % 5);
        const timestamp = moment(date).add(remainder, 'minutes'); //forces increment of 5 minutes
        setDelayedLaunch({
            timestamp,
            visible: true
        });
    };

    const onCancelDelayedLaunch = () => {
        Modal.confirm({
            title: t('INTERNAL.CLIENT.WAVE.TAB_LAUNCH.CANCEL_SCHEDULED_LAUNCH'),
            content: <div>
                <Trans
                    i18nKey='INTERNAL.CLIENT.WAVE.TAB_LAUNCH.CANCEL_DELAYED_LAUNCH'
                    t={t}
                    components={{ bold: <strong />, DisplayDate: <NpiDisplayDate value={wave.delay_launch_at} hours="inline" locale={currentLocale}/>}}
                />
                {(timeZone !== 'UTC') && <>
                    <br/>
                    <em><NpiDisplayDate value={wave.delay_launch_at} hours="inline" utcToTimezone={true} locale={currentLocale}/> {timeZone}</em>
                </>}
            </div>,
            okText: t('INTERNAL.CLIENT.COMMON.YES'),
            cancelText: t('INTERNAL.CLIENT.COMMON.NO'),
            onOk: () => requestCancelDelayedLaunch(wave.id),
        });
    }

    //Prepare the check list on load
    useEffect(() => {
        const checks:any[] = [{
            title: <><NpiDisplayNumber value={wave.nb_pos}/> {t('INTERNAL.CLIENT.COMMON.POS')}</>,
            isValid: wave.nb_pos > 0,
        },{
            title: t('INTERNAL.CLIENT.WAVE.TAB_LAUNCH.NB_QUESTIONS', {count: wave.questions?.length}),
            isValid: wave.questions?.length > 0,
        },];

        //KPIs
        const mandatoryKpis = _.filter(wave.kpis, {is_mandatory: true});
        if(mandatoryKpis.length){
            const counter = _.reduce(mandatoryKpis, (n, kpi) => kpi.conditions?.rules?.length>0 ? n+1:n, 0);
            checks.push({
                title: t('INTERNAL.CLIENT.WAVE.TAB_LAUNCH.MANDATORY_KPIS_CONFIGURED', {counter: counter, nbMandatoryKpis: mandatoryKpis.length}),
                isValid: counter === mandatoryKpis.length,
            });
        }
        const optionalKpis = _.filter(wave.kpis, k=>!k.is_mandatory);
        if(optionalKpis.length){
            const counter = _.reduce(optionalKpis, (n, kpi) => kpi.conditions?.rules?.length>0 ? n+1:n, 0);
            checks.push({
                title: t('INTERNAL.CLIENT.WAVE.TAB_LAUNCH.OPTIONAL_KPIS_CONFIGURED', {counter: counter, nbOptionalKpis: optionalKpis.length}),
                isWarning: counter < optionalKpis.length,
                isValid: true,
            });
        }

        //Translations
        const terms = generateTerms(wave);
        wave.translation_ids?.forEach(tId => {
            const count = _.toArray(wave.translations?.['T'+tId]).length;
            checks.push({
                title: <span>
                            <Trans
                                i18nKey='INTERNAL.CLIENT.WAVE.TAB_LAUNCH.TRANSLATION_PERCENTAGE'
                                t={t}
                                values={{percentage: Math.round(count/terms.length*100)}}
                                components={{ bold: <strong />, DisplayLanguage: <NpiDisplayLanguage id={tId}/>}}
                            />
                       </span>,
                isWarning: count < terms.length,
                isValid: true,
            });
        });

        //Check if Wave Form has error in condition settings
        if(wave.conditions?.length){
            let hasErrors = false;
            wave.conditions.forEach(condition => {
                if(condition && getConditionErrors(condition, wave.questions, wave.tags).length){
                    hasErrors = true
                }
            });
            if(hasErrors){
                checks.push({
                    title: <span>{t('INTERNAL.CLIENT.COMMON.FORM_CONDITIONS_HAVE_ERRORS')}</span>,
                    isWarning: true,
                    isValid: false,
                });
            }
        }

        //Check if KPIs have errors in condition settings
        let hasErrors = false;
        wave.kpis.forEach(kpi => {
            if(kpi.conditions && getConditionErrors(kpi.conditions, wave.questions, wave.tags).length){
                hasErrors = true
            }
        });
        if(hasErrors){
            checks.push({
                title: <span>{t('INTERNAL.CLIENT.WAVE.TAB_LAUNCH.KPI_CONFIGURATION_ERRORS')}</span>,
                isWarning: true,
                isValid: false,
            });
        }

        setChecks(checks);
    },[setChecks, wave, t]);

    //Modal displaying contacts to notify
    const configModalContacts = {
        open: contacts.length>0,
        title: <><EmailFast /> Notify Contacts for {wave.name}</>,
        okText: <>Notify {selectedContacts.length} {selectedContacts.length>1?t('INTERNAL.CLIENT.COMMON.CONTACTS'):t('INTERNAL.CLIENT.COMMON.CONTACT')}</>,
        okButtonProps: {disabled: ! selectedContacts.length, loading: loadingNotify},
        cancelText: t('INTERNAL.CLIENT.COMMON.DO_NOT_NOTIFY'),
        cancelButtonProps: {disabled: loadingNotify},
        maskClosable: false,
        closable: false,
        onCancel: () => setContacts([]),
        onOk: () => requestNotify({id: wave.id, contacts: selectedContacts as number[]}).then(() => {
            setContacts([]) //hide the modal
        })
    };

    //Modal displaying contacts to notify
    const configModalDelayedLaunch = {
        width: 600,
        open: delayedLaunch.visible,
        title: <><Timer /> {t('INTERNAL.CLIENT.WAVE.SCHEDULED_LAUNCH_WAVE', {waveName: wave.name})}</>,
        okText: <>{t('INTERNAL.CLIENT.WAVE.DELAY_LAUNCH')}</>,
        okButtonProps: {disabled: ! delayedLaunch.timestamp, loading},
        cancelButtonProps: {disabled: loading},
        maskClosable: false,
        closable: false,
        onCancel: () => setDelayedLaunch({...delayedLaunch, visible: false}),
        onOk: () => requestDelayedLaunch({id: wave.id, delayedLaunch: makeUtcStringFromDate(delayedLaunch.timestamp)}).then(() => {
            setDelayedLaunch({...delayedLaunch, visible: false});
        }),
    };

    //Table displaying contacts to notify
    const configTableContacts = useMemo(() =>({
        rowKey: 'id',
        dataSource: contacts,
        pagination: false as false,
        columns: [
            {dataIndex: 'name', title: t('INTERNAL.CLIENT.COMMON.NAME')},
            {dataIndex: 'email', title: t('INTERNAL.CLIENT.COMMON.EMAIL')},
            {dataIndex: 'countries', title: t('INTERNAL.CLIENT.COMMON.COUNTRIES'), align: 'center' as 'center',
                render: (countries:any)=> <NpiDisplayListEllipsis>{countries?.map((c:any) => <NpiDisplayCountry key={c} id={c} style={{marginLeft: 5}} short/>)}</NpiDisplayListEllipsis>
            },
        ],
        rowSelection: {
            onChange: setSelectedContacts,
            selectedRowKeys: selectedContacts,
        }
    }), [contacts, selectedContacts, t]);

    return <div>
        <Row gutter={[20,20]}>
            <Col span={6} offset={6} style={{minHeight: '100%'}}>
                <Card title={<h3><OrderedListOutlined /> {t('INTERNAL.CLIENT.COMMON.CHECK_LIST')}</h3>} style={{minHeight: '100%'}}>
                    <Space direction="vertical">
                        {checks.map(({isWarning, isValid, title}, key) =>
                            <span key={key}>
                                {isValid && !isWarning && <Success>{title}</Success>}
                                {isValid && isWarning && <Warning>{title}</Warning>}
                                { ! isValid && <Danger>{title}</Danger>}
                            </span>
                        )}
                    </Space>
                </Card>
            </Col>
            <Col span={6} style={{minHeight: '100%'}}>
                <Card title={<h3><RocketOutlined /> {t('COMMON.ACTIONS')}</h3>} style={{minHeight: '100%'}}>
                    <Space direction="vertical" style={{width: '100%'}}>
                        <div>
                            <Trans
                                i18nKey='INTERNAL.CLIENT.WAVE.TAB_LAUNCH.DISPLAY_WAVE_STATUS'
                                t={t}
                                values={{waveName: wave.name}}
                                components={{ bold: <strong />, DisplayWaveStatus: <NpiDisplayWaveStatus id={status} style={{display: 'inline-block'}}/>}}
                            />
                            {status === DELAYED_LAUNCH && <>
                                &nbsp;<Trans
                                    i18nKey='INTERNAL.CLIENT.WAVE.TAB_LAUNCH.PLANNED_FOR'
                                    t={t}
                                    components={{ bold: <strong />, DisplayDate: <NpiDisplayDate value={wave.delay_launch_at} hours="inline"/>}}
                                />
                                &nbsp;
                                {(timeZone !== 'UTC') &&
                                    <Tooltip title={<><NpiDisplayDate value={wave.delay_launch_at} hours="block" utcToTimezone={true}/> {timeZone}</>}>&nbsp;<InfoCircleFilled/></Tooltip>}
                            </>}
                        </div>
                        {status !== ARCHIVED && ! isLaunchedOrDelayed && <Row gutter={[20, 20]}>
                            <Col span={12}><Button type="primary" size="large" block disabled={nbInvalidChecks>0} onClick={onLaunch} loading={loading}><PlaySquareOutlined/> {t('INTERNAL.CLIENT.COMMON.LAUNCH')}</Button></Col>
                            <Col span={12}><Button type="primary" size="large" block disabled={nbInvalidChecks>0} onClick={onDelayedLaunch} loading={loading}><Timer/> {t('INTERNAL.CLIENT.COMMON.SCHEDULED_LAUNCH')}</Button></Col>
                        </Row>}
                        {status !== ARCHIVED && status === DELAYED_LAUNCH &&
                            <Button block icon={<TimerOff />} onClick={onCancelDelayedLaunch} size="large" danger>{t('INTERNAL.CLIENT.WAVE.TAB_LAUNCH.CANCEL_SCHEDULED_LAUNCH')}</Button>
                        }
                        {status !== ARCHIVED && status === LAUNCHED && <>
                            <Button size="large" block onClick={onClose} loading={loading}><PoweroffOutlined /> {t('INTERNAL.CLIENT.COMMON.CLOSE')}</Button>
                            <Tooltip title={t('INTERNAL.CLIENT.COMMON.LINK.TOOLTIP')}>
                                <Button block icon={<LinkVariant />} onClick={() => openModalExternalLinks(wave)} size="large">{t('INTERNAL.CLIENT.COMMON.LINKS')}</Button>
                            </Tooltip>
                        </>}
                        {status !== ARCHIVED && <Button block size="large" loading={loading} onClick={toggleArchive} disabled={nbInvalidChecks>0}>{t('INTERNAL.CLIENT.COMMON.ARCHIVE')}</Button>}
                        {status === ARCHIVED && <Button block size="large" loading={loading} onClick={toggleArchive}>{t('INTERNAL.CLIENT.COMMON.UNARCHIVE')}</Button>}
                    </Space>
                </Card>
            </Col>
        </Row>

        {/*Notification Modal*/}
        <Modal {...configModalContacts}>
            <Alert type="success" 
                   message={<>
                        <Trans
                            i18nKey='INTERNAL.CLIENT.WAVE.TAB_LAUNCH.WAVE_LAUNCHED'
                            t={t}
                            components={{ bold: <strong />}}
                        />
                   </>}
            />
            <br/>
            <Table {...configTableContacts}/>
        </Modal>

        {/**/}
        <Modal {...configModalDelayedLaunch}>
            <Alert message={<div>
                <Trans
                    i18nKey='INTERNAL.CLIENT.WAVE.TAB_LAUNCH.SETUP_DELAYED_LAUNCH'
                    t={t}
                    values={{waveName: wave.name, timeZone: timeZone}}
                    components={{ bold: <strong />}}
                />

                <br/><br/>{t('INTERNAL.CLIENT.COMMON.PLEASE_NOTE_THAT')}
                <ul>
                    <li>{t('INTERNAL.CLIENT.WAVE.TAB_LAUNCH.ALL_RELATED_CONTACTS_WILL_BE_NOTIFIED')}</li>
                    <li>{t('INTERNAL.CLIENT.WAVE.TAB_LAUNCH.YOU_CAN_CANCEL_DELAYED_LAUNCH')}</li>
                </ul>
            </div>} style={{marginBottom: 20}}/>
            <Space>
                <DatePicker value={delayedLaunch.timestamp} format="DD-MMM-YYYY" onChange={timestamp => setDelayedLaunch({...delayedLaunch, timestamp})}/>
                <TimePicker format={'HH:mm'} value={delayedLaunch.timestamp} onChange={timestamp => setDelayedLaunch({...delayedLaunch, timestamp})} minuteStep={5}/>
                <i>{t('INTERNAL.CLIENT.WAVE.TAB_LAUNCH.TIME_ZONE', {timeZone: timeZone})}</i>
            </Space>
        </Modal>
    </div>
    ;
});

export default NpiWaveTabLaunch;