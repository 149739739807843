import React, {useEffect, useMemo, useState} from 'react';
import Modal, {ModalProps} from "antd/lib/modal";
import {Alert, Form, Input, notification, Switch} from "antd";
import NpiInputDatePicker from "../input/date-picker";
import npiApi from "../../services/api";
import {useRequest} from "ahooks";
import {useTranslation} from "react-i18next";

interface INpiWaveTableReportModalReschedule extends ModalProps {
    waveId: number
    row: any
    filters: any
    total: number
    onClose: (row?: any) => void
}

const NpiWaveTableReportModalReschedule = ({waveId, row, filters, total, onClose, ...props}:INpiWaveTableReportModalReschedule) => {

    const [form] = Form.useForm();
    const [enabled, setEnabled] = useState(false);
    const {t} = useTranslation();
    const {runAsync: saveRows, loading} = useRequest(npiApi.internal.wavepos.bulkReschedule, {manual: true});

    // traduction
    const nbPos : number = useMemo(() => {
        if (!!row) {
            return 1;
        } else {
            return total
        }
    }, [row, total]);

    // define text depending on number of rows to postponed
    const rowsModalInfos = useMemo(() => ({
        modalTitle: (!!row)
            ? <span>{t('INTERNAL.CLIENT.REPORT.RESCHEDULE_MODAL.RESCHEDULE_POS', {posId: row.pos_id, posName: row.name})}</span>
            : <span>{t('INTERNAL.CLIENT.REPORT.RESCHEDULE_MODAL.RESCHEDULE_TOTAL_FILTERED_POS', {total: total})}</span>,
        modalText: (!!row)
            ? <>
                <li>{t('INTERNAL.CLIENT.REPORT.RESCHEDULE_MODAL.POSTPONE_POS_ONE')}</li>
                <li>{t('INTERNAL.CLIENT.REPORT.RESCHEDULE_MODAL.POSTPONED_POS_ONE')}</li>
            </>
            : <>
                <li>{t('INTERNAL.CLIENT.REPORT.RESCHEDULE_MODAL.POSTPONE_POS_MANY')}</li>
                <li>{t('INTERNAL.CLIENT.REPORT.RESCHEDULE_MODAL.POSTPONED_POS_MANY')}</li>
            </>
    }), [row, total, t]);


    //Look for enabled/disabled changes to hide other fields
    const onChange = (_:any, values:any) => {
        setEnabled(!!values.reschedule_enabled);
    };

    //Modal configuration
    const config = {
        title: rowsModalInfos.modalTitle,
        width: 600,
        ...props,
        onOk: () => {
            const values = form.getFieldsValue();

            // Get Value from Form (Null all values if not enabled)
            let valuesParse = {
                reschedule_enabled: values.reschedule_enabled ?? false,
                reschedule_date_start: values.reschedule_enabled ? (values.reschedule_date_start ?? null) : null,
                reschedule_date_end: values.reschedule_enabled ? (values.reschedule_date_end ?? null) : null,
                reschedule_reason: values.reschedule_enabled ? (values.reschedule_reason ?? null) : null,
            };

            //If only one POS and nothing changed, to do anything
            if( !!row ) {
                if( row.reschedule_enabled === valuesParse.reschedule_enabled
                    && row.reschedule_date_start === valuesParse.reschedule_date_start
                    && row.reschedule_date_end === valuesParse.reschedule_date_end
                    && row.reschedule_reason === valuesParse.reschedule_reason
                ){
                    onClose();
                    return;
                }
            }

            //Save the row and close the modal
            return saveRows({
                waveId: waveId,
                filters: (!!row) ? {pos_id: [row.pos_id, {strict: true}]} : filters,
                reschedule: valuesParse
            }).then((data:any) => {

                //Notify user
                if( !data.updated) {
                    notification.warn({message: <span>{t('INTERNAL.CLIENT.REPORT.RESCHEDULE_MODAL.NO_POS_UPDATED')}</span>})
                } else {
                    notification.success({
                        message: (!!row)
                            ? <span>{t('INTERNAL.CLIENT.REPORT.RESCHEDULE_MODAL.RESCHEDULE_POS_SUCCESSFUL', {posId: row.pos_id, posName: row.name})}</span>
                            : <span>{t('INTERNAL.CLIENT.REPORT.RESCHEDULE_MODAL.RESCHEDULE_TOTAL_FILTERED_POS', {total: data.updated})}</span>
                    })
                    if( data.send !== data.updated ) {
                        const countError = data.send - data.updated;
                        notification.warn({message: <span>{t('INTERNAL.CLIENT.REPORT.RESCHEDULE_MODAL.POS_NOT_RESCHEDULED', {count: countError})}</span>})
                    }
                }

                // Close the modal
                onClose(row ?? data.updated);
            });
        },
        okButtonProps: {loading},
        maskClosable: false,
        onCancel: () => onClose(),
    };

    //Observe change to row to update our form
    useEffect(() => {
        if( !!row ){
            form.setFieldsValue(row);
            setEnabled(!!(row.reschedule_enabled));
        }
    }, [row, form]);

    return <Modal {...config}>
        <Alert style={{marginBottom: 20}} message={<ul style={{margin: 0}}>
            {rowsModalInfos.modalText}
        </ul>}/>
        <Form form={form} labelCol={{span: 6}} onValuesChange={onChange}>
            <Form.Item label={t('INTERNAL.CLIENT.REPORT.RESCHEDULE_MODAL.POSTPONE_NB_POS', {count: nbPos})} name="reschedule_enabled" valuePropName="checked"><Switch/></Form.Item>
            {enabled && <Form.Item label={t('INTERNAL.CLIENT.COMMON.START_DATE')} name="reschedule_date_start"><NpiInputDatePicker pickerProps={{allowClear: true}}/></Form.Item>}
            {enabled && <Form.Item label={t('INTERNAL.CLIENT.COMMON.END_DATE')} name="reschedule_date_end"><NpiInputDatePicker pickerProps={{allowClear: true}}/></Form.Item>}
            {enabled && <Form.Item label={t('INTERNAL.CLIENT.COMMON.REASON')} name="reschedule_reason"><Input.TextArea/></Form.Item>}
        </Form>
    </Modal>
};

export default NpiWaveTableReportModalReschedule;