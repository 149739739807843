import React from "react";
import styled, {css} from "styled-components";

const StyledContainer = styled('div')<{ printMode?: boolean }>`
  height: 100%;
  width: 100%;
  ${props => !props.printMode && css`
    background-color: white;  
  `};
`;

/**
 * @param printMode
 * @constructor
 */
const NpiDisplayKpiSpace = ({printMode=false}:any) => <StyledContainer printMode={printMode}/>;
export default NpiDisplayKpiSpace;