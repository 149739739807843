import {makeAutoObservable} from "mobx";
import npiApi from "../services/api";
import {
    IDashboard,
    IDashboardBlockConfiguration,
    IDashboardBlockType,
    IDashboardConfiguration,
    IDashboardData
} from "../types/dashboard";
import _ from "lodash";

const {KPI, PIE_CHART, TABLE} = IDashboardBlockType;

export class KpiDashboardStore {

    loading = true;
    dashboard = {} as IDashboard;
    dashboardData = {} as IDashboardData;
    configuration = {blocks: []} as IDashboardConfiguration;
    prevConfiguration = null as any;
    isDirty = false;

    constructor(){
        makeAutoObservable(this);
    }

    compute = (filters:any, firstCall: boolean = false) => {
        this.loading = true;
        return npiApi.internal.kpiDashboard.compute({...filters, firstCall: firstCall})
            .then((data:any) => {
                this.dashboardData = data;

                if(!this.dashboard.id && data.dashboards.length){
                    this.configuration = updateDashboardBlocks({...data.dashboards[0].configuration}, data);
                    this.dashboard = data.dashboards[0];
                }
                else{
                    this.configuration = updateDashboardBlocks(this.configuration??{blocks:[]}, data);
                }

                return data;
            })
            .finally(() => this.loading = false)
    };

    //This method will save the dashboard
    saveDashboard = () => {
        return npiApi.internal.kpiDashboard.save({
            ...this.dashboard,
            npi_id: this.dashboardData.npi.id,
            configuration: this.configuration,
        }).then((newDashboard:any) => {
            this.isDirty = false;
            this.dashboard = newDashboard;
            return newDashboard;
        });
    };

    //Save the configuration to the current dashboard
    setConfiguration = (configuration:IDashboardConfiguration) => {
        if( ! _.isEqual(this.prevConfiguration, configuration)){
            this.configuration = configuration;
            if(this.prevConfiguration){
                //mark dirty if we had a prev config (to avoid dirty state on first load)
                this.isDirty = true;
            }
            //Save prev config to track future changes
            this.prevConfiguration = _.cloneDeep(configuration);
        }
    };

    //Reset the store. This is needed so that switching dashboard does not causes issues
    resetStore = () => {
        this.loading = true;
        this.dashboard = {} as IDashboard;
        this.dashboardData = {} as IDashboardData;
        this.configuration = {blocks: []};
        this.prevConfiguration = null;
        this.isDirty = false;
    };
}

/**
 * This method will ensure we are not missing any blocks in our dashboard configuration
 * @param configuration
 * @param data
 */
const updateDashboardBlocks = (configuration:IDashboardConfiguration, data:IDashboardData) => {
    const {blocks} = configuration;

    let count = 0;
    //Make all kpi blocks not visible
    blocks.forEach(b => {
        if(b.type === KPI) {
            b.visible = false;
            count++;
        }
    });

    //Update our configuration with missing blocks
    data.kpis?.forEach((kpi) => {
        const found = _.find(blocks, {key: 'k'+kpi.id}) as IDashboardBlockConfiguration;
        if( ! found){
            blocks.push({
                key: 'k'+kpi.id,
                type: KPI,
                visible: true,
                deleted: false,
                config: {id: kpi.id},
                grid: {x:(count++)%3*4, y:0, w:4, h:4},
            });
        }
        else{
            found.visible = true;
        }
    });

    //If there is no chart block (not even a deleted one) add it
    if( ! _.find(blocks, {type: PIE_CHART})){
        blocks.push({
            key: 'c1',
            type: PIE_CHART,
            visible: true,
            deleted: false,
            config: {},
            grid: {x:12, y:0, w:12, h:8},
        })
    }

    //If there is no table block (not even a deleted one) add it
    if( ! _.find(blocks, {type: TABLE})){
        blocks.push({
            key: 't1',
            type: TABLE,
            visible: true,
            deleted: false,
            config: {},
            grid: {x:0, y:10, w:12, h:2},
        })
    }

    return configuration;
};