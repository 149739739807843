import React, {useContext, useEffect, useState} from "react";
import {NpiInternalContext} from "../../contexts/internal-context";
import {Select} from "antd";
import {useControllableValue} from "ahooks";
import {SelectProps} from "antd/lib/select";
import useAcl from "../../hooks/use-acl";
import {IUserRoleEnum} from "../../types/user";
import {REGION_WW} from "../../helpers/constants";

interface ISelectRoleProps extends SelectProps<any> {
  restrict?: boolean;
}


/**
 * Select between list of user's roles.
 * Can be restricted (with restrict=true) to the list of roles inferior of the current logged user. (= roles in its role's hierarchy)
 *
 * @param {boolean} restrict
 * @param props
 */
const NpiInputSelectRole = ({restrict, ...props}:ISelectRoleProps) => {
    const {style} = props;
    const {roles, loaded, user} = useContext(NpiInternalContext);
    const [options, setOptions] = useState<any[]>([]);
    const hasMerchGroupAccess = useAcl().hasRegionFeatureAccess("has_merchandising_groups_access");
    const [state, setState] = useControllableValue<null|number|number[]>(props, {
        defaultValue: [],
    });


    useEffect(() => {

        let restrictedRoles = Object.values(roles);

        // restrict list of roles to the ones allowed for logged user
        if( restrict === true ) {
            if( user ) {
                const hierarchy = roles[user.role].hierarchy;
                restrictedRoles = restrictedRoles.filter((r) => hierarchy.includes(r.id));
            } else {
                restrictedRoles = [];
            }
        }

        //Remove merchandiser role if not available
        if( ! hasMerchGroupAccess && user.region_id !== REGION_WW){
            restrictedRoles = restrictedRoles.filter(v => v.id !== IUserRoleEnum.MERCHANDISING_VENDOR)
        }

        // set select options from filtered roles
        setOptions(restrictedRoles.map(r => ({
            label: r.name,
            value: r.id,
        })))

    }, [roles, restrict, user, hasMerchGroupAccess]);

    return <Select loading={!loaded} options={options} value={state} onChange={setState} {...props} style={{minWidth: 150, ...style}}/>
};

export default NpiInputSelectRole;