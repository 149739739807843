import React, { useCallback } from 'react';
import {Alert, Spin} from "antd";
import {Masonry} from "masonic";
import NpiContainerGalleryMasonryImage from "./masonry-image";
import styled from "styled-components";
// @ts-ignore
import InfiniteScroll from 'react-infinite-scroller';
import {IWavePosImage} from "../../types/wave-pos-image";
import {useTranslation} from "react-i18next";


// distance to the bottom of the gallery before triggering the next fetch
const SCROLL_THRESHOLD_PX = 600;

// width of a masonry card/column
const COLUMN_WIDTH = 220;


const StyledInfiniteScroll = styled(InfiniteScroll)`
  padding: 10px 30px;

  .masonry-container {
    min-height: 100vh;
    width: 100%;

    .masonry-inner {
      width: 100%;
      max-width: 100%;
    }
  }
`;

interface MasonryContainerProps {
    startIndex?: number,
    listImages: IWavePosImage[],
    fetchMore: () => Promise<any>,
    loading: boolean,
    hasMore: boolean,
    totalImgs: number,
    onClickCard: (id:number) => void,
}

const NpiContainerGalleryMasonryContainer = ({startIndex, listImages, fetchMore, loading, hasMore, totalImgs, onClickCard}: MasonryContainerProps) => {

    const MasonryImageWithClick = useCallback(
        props => <NpiContainerGalleryMasonryImage  {...props} onClickCard={onClickCard}/>,
        [onClickCard]
    );
    const {t} = useTranslation();

    return <StyledInfiniteScroll
        pageStart={startIndex || 1}
        initialLoad={false}
        hasMore={hasMore && !loading}
        loadMore={fetchMore}
        threshold={SCROLL_THRESHOLD_PX}
        loader={<div className="loader" key={0}><Spin/></div>}
    >
        <div className={'masonry-container'}>
            <div className={'masonry-inner'}>
                <Masonry
                     items={listImages}
                     render={MasonryImageWithClick}
                     columnGutter={15}
                     columnWidth={COLUMN_WIDTH}
                     overscanBy={5} // Infinity
                     itemHeightEstimate={600}
                     scrollToIndex={startIndex}
                />

                {loading
                    ? <div className="loader" key={0}><Spin/></div>
                    : !hasMore && <Alert message={t('INTERNAL.CLIENT.COMMON.NO_DATA')} type="info" style={{textAlign: "center", marginBottom: '5px'}}/>
                }
            </div>
        </div>
    </StyledInfiniteScroll>
}

export default NpiContainerGalleryMasonryContainer;