import React, {useMemo, useState} from 'react';
import NpiInputSelectNpi from "./select-npi";
import NpiInputSelectWave from "./select-wave";
import {Alert, Button, Form, Input, notification, Popover, Space, Switch, Table, Tooltip} from "antd";
import styled from "styled-components";
import {InfoCircleFilled, PlusOutlined, SearchOutlined} from "@ant-design/icons/lib";
import npiApi from "../../services/api";
import {useRequest} from "ahooks";
import questionsConfigurations from "../../services/questions-configurations";
import {IQuestion, IQuestionTypeEnum} from "../../types/question";
import Eye from "@2fd/ant-design-icons/lib/Eye";
import NpiInputQuestion from "./question";
import {Trans, useTranslation} from "react-i18next";


const StyledForm = styled(Form)`
  .ant-select {
    max-width: 100% !important;
  }
`;


export enum INpiInputSearchQuestionType {
    ADD_EXISTING_QUESTION = 0,
    REPLACE_QUESTIONS_WAVE = 1
}

interface INpiInputSearchQuestion {
    onAdd?: (q: IQuestion) => Promise<any>,
    onReplace?: (waveId: number) => Promise<any>|void,
    searchType?: INpiInputSearchQuestionType
}


const NpiInputSearchQuestion = (props: INpiInputSearchQuestion) => {
    const [form] = Form.useForm();
    const [npiIds, setNpiIds] = useState<number[]>([]);
    const {runAsync: launchSearch, loading, data} = useRequest<any,any>(npiApi.internal.question.search, {manual: true});
    const [selectedWaveId, setSelectedWaveId] = useState<number|null>(null);
    const [disabledQuestions, setDisabledQuestions] = useState<number[]>([]);
    const [loadingAdd, setLoadingAdd] = useState(false);
    const {t} = useTranslation();

    const {onAdd = null, onReplace = null, searchType = INpiInputSearchQuestionType.ADD_EXISTING_QUESTION} = props;

    const replaceAllQuestionsWaveButtonIsVisible = useMemo(() =>
        searchType === INpiInputSearchQuestionType.REPLACE_QUESTIONS_WAVE &&
        !!selectedWaveId, [searchType, selectedWaveId]
    );

    const onChange = ({npi, wave}: any) => {
        if (npi) {
            setNpiIds([npi]);
            form.setFieldsValue({wave: null});
            setSelectedWaveId(null);
        }
        else{
            setSelectedWaveId(wave || null);
        }
    };

    //Click on search button
    const onSearch = () => launchSearch(form.getFieldsValue());

    //Add a question
    const onAddQuestion = (question: IQuestion) => {

        //needed props in order to work
        if(!onAdd){
            throw Error(t("INTERNAL.CLIENT.QUESTION.SEARCH.PROVIDE_ONADD_PROPS"));
        }

        setDisabledQuestions([...disabledQuestions, question.id]);
        setLoadingAdd(true);

        onAdd(question).then(() => {
            notification.success({message: t("INTERNAL.CLIENT.QUESTION.SEARCH.QUESTION_ADDED")});
        }).finally(() => setLoadingAdd(false));
    };

    //Replace questions from selected waveid
    const onReplaceQuestionsFromSelectedWaveId = () => {

        //avoid unwanted behavior, we need selectedWaveId to do replace
        if(!selectedWaveId){
            return;
        }

        //needed props in order to work
        if(!onReplace){
            throw Error(t("INTERNAL.CLIENT.QUESTION.SEARCH.PROVIDE_ONREPLACE_PROPS"));
        }

        setLoadingAdd(true);

        onReplace(selectedWaveId)?.then(() => {
            notification.success({message: t("INTERNAL.CLIENT.QUESTION.SEARCH.QUESTIONS_REPLACED")});
        }).finally(() => setLoadingAdd(false));
    };

    const tableConfig = {
        loading,
        dataSource: data?.questions,
        pagination:{
            hideOnSinglePage: true
        },
        columns: [
            {
                title: t("INTERNAL.CLIENT.COMMON.QUESTION"), dataIndex: 'display_name',
                render: (v: string, row: any) =>
                    <span>{questionsConfigurations[row.type as IQuestionTypeEnum].icon} {v}</span>
            },
            {
                align: 'right' as 'right', render: (row: any) => <Space>
                    <Popover content={<NpiInputQuestion question={row}/>}>
                        <Button icon={<Eye/>}>{t("INTERNAL.CLIENT.COMMON.PREVIEW")}</Button>
                    </Popover>
                    {
                        searchType === INpiInputSearchQuestionType.ADD_EXISTING_QUESTION &&
                        <Button icon={<PlusOutlined/>} type="primary" onClick={() => onAddQuestion(row)}
                                disabled={disabledQuestions.indexOf(row.id) >= 0}
                                loading={loadingAdd}>{t("INTERNAL.CLIENT.COMMON.ADD")}</Button>
                    }
                </Space>
            },
        ],
    };

    return <div>
        <StyledForm form={form} onValuesChange={onChange} labelCol={{span: 4}} wrapperCol={{span: 16}}>
            <Form.Item label="NPI" name="npi">
                <NpiInputSelectNpi allowClear/>
            </Form.Item>

            <Form.Item label={t("INTERNAL.CLIENT.NPI.WAVE")} name="wave">
                <NpiInputSelectWave
                    npis={npiIds}
                    disabled={!npiIds.length}
                    allowClear
                />
            </Form.Item>

            {
                searchType === INpiInputSearchQuestionType.ADD_EXISTING_QUESTION && <>
                        <Form.Item label={t("INTERNAL.CLIENT.COMMON.QUESTION")} name="text">
                            <Input
                                placeholder={t("INTERNAL.CLIENT.QUESTION.SEARCH.TEXT_SEARCH_FIELDS")}
                            />
                        </Form.Item>
                        <Form.Item
                            label={
                                <span> {t("INTERNAL.CLIENT.COMMON.ARCHIVED")}
                                <Tooltip
                                    title={t("INTERNAL.CLIENT.QUESTION.SEARCH.IF_CHECKED_LOOK_IN_ARCHIVED_WAVES")}><InfoCircleFilled/>
                                </Tooltip>
                            </span>
                            }
                            name="is_archived"
                            valuePropName="checked">
                            <Switch/>
                        </Form.Item>
                    </>
            }

            <Form.Item wrapperCol={{offset: 4, span: 16}}>
                <Button disabled={searchType === INpiInputSearchQuestionType.REPLACE_QUESTIONS_WAVE && !selectedWaveId} icon={<SearchOutlined/>} type="primary" onClick={onSearch} loading={loading}>{t("INTERNAL.CLIENT.COMMON.SEARCH")}</Button>
            </Form.Item>

            {
                searchType === INpiInputSearchQuestionType.REPLACE_QUESTIONS_WAVE &&
                <Alert
                    style={{width: '66.66%', margin: 'auto', marginBottom: 20}}
                    message="Warning"
                    description={
                    <p>
                        <Trans
                            i18nKey="INTERNAL.CLIENT.QUESTION.SEARCH.COPY_QUESTIONS_ALERT"
                            t={t}
                            components={{ bold: <strong />, br: <br /> }}
                        />
                    </p>}
                    type="warning"
                    showIcon
                />
            }
        </StyledForm>

        <Table {...tableConfig}/>


        {
            replaceAllQuestionsWaveButtonIsVisible &&
            <Button
                onClick={onReplaceQuestionsFromSelectedWaveId} style={{margin: 'auto', marginTop: 10, display: 'block'}}
                type="primary"
            >
                {t("INTERNAL.CLIENT.QUESTION.SEARCH.REPLACE_QUESTIONS_CONDITIONS")}
            </Button>
        }
    </div>
};

export default NpiInputSearchQuestion;