import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Table} from "antd";
import {TablePaginationConfig, TableProps, ColumnType} from "antd/lib/table";
import {useControllableValue, useDebounceFn} from 'ahooks';
import NpiInputTableFilterDropdown, {INpiTableFilter} from "./input/table-filter-dropdown";
import _ from "lodash";

export interface NpiServerPagination {data:[], per_page: number, total: number, current_page: number}

type INpiPaginatedTableColumn<RecordType> = ColumnType<RecordType> & {
    filter?: INpiTableFilter
}

export interface INpiTableServerPaginatedProps<RecordType> extends TableProps<RecordType>{
    fetcher: (params:object) => Promise<NpiServerPagination>
    columns?: INpiPaginatedTableColumn<RecordType>[]
    defaultFilters?: Record<string, [any, any]>
    onFiltersChange?: (filters:any)=>void
    onTotalChange?: (total:number)=>void
}

interface NpiTableSorter{
    field?: string,
    order?: sortDirection,
}

enum sortDirection {
    ASC = 'ascend',
    DESC = 'descend',
}

/**
 * Expects a promise returning the data & pagination setup, and will display the data. Otherwise works exactly like an Antd Table
 * @param fetcher
 * @param pagination
 * @param defaultFilters
 * @param tableProps
 * @constructor
 */
const NpiPaginatedTable = ({fetcher, pagination, defaultFilters, ...tableProps} : INpiTableServerPaginatedProps<any>) => {

    const [dataSource, setDataSource] = useControllableValue(tableProps, {
        valuePropName: 'dataSource'
    });

    const [localPagination, setPagination] = useState<TablePaginationConfig>({
        current: 1,
        pageSize: pagination && pagination.defaultPageSize ? pagination.defaultPageSize : 15,
        pageSizeOptions: ['15', '30', '50', '100', '500', '1000'],
        ...pagination,
    });
    const [sorter, setSorter] = useState<NpiTableSorter>({});
    const [filters, setFilters] = useControllableValue<NpiTableSorter>(tableProps, {
        valuePropName: 'filters',
        trigger: 'onFiltersChange',
        defaultValue: defaultFilters ?? {},
    });
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [total, setTotal] = useControllableValue<number>(tableProps, {
        valuePropName: 'total',
        trigger: 'onTotalChange',
    });
    const [loading, setLoading] = useState(false);

    //Debounce our fetcher on a very short interval to prevent multi calls
    const {run: runFetcher} = useDebounceFn(params => {
        fetcher(params).then(({data, total, current_page}) => {
            setDataSource(data);
            setPagination({
                ...localPagination,
                current: current_page,
                total,
                ...pagination,
            });
            setTotal(total);
        }).finally(() => {
            //Always stop loading at the end
            setLoading(false);
        });
    }, {wait: 50});

    //useCallback to update the callback only when something changes
    const fetchPage = useCallback(() => {
        const params = {
            page: localPagination.current,
            per_page: localPagination.pageSize,
            sorter,
            filters,
        };

        setLoading(true);
        runFetcher(params);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[localPagination.current, localPagination.pageSize, sorter.field, sorter.order, filters, fetcher]);

    //Track change to the table
    const onChange = useCallback((pagination: TablePaginationConfig, filters: any, sorter: any) => {
        setSorter({field: sorter.field, order: sorter.order});
        setDataSource(dataSource.slice(0, pagination.pageSize)); //we do this to avoid error message when reducing pageSize
        setPagination(pagination);
        setFilters(filters);
    }, [dataSource, setDataSource, setFilters]);

    //Read the defaultSorter of the columns and apply it if required
    const columns = useMemo(() => {
        tableProps?.columns?.forEach(column => {
            const {defaultSortOrder, dataIndex, filter} = column;
            if(defaultSortOrder){
                setSorter({field: dataIndex as string, order: defaultSortOrder as sortDirection});
            }
            if(filter){
                if(!_.isString(column.dataIndex) && !_.isString(column.key) ) throw new Error('dataIndex or key must be a column name for a server filter to work');
                filter.title = filter.title ?? column.title;
                const filterIdx = (column.key || column.dataIndex) as string;
                if(defaultFilters && defaultFilters[filterIdx]){
                    column.defaultFilteredValue = defaultFilters[filterIdx];
                }
                column.filterDropdown = props => <NpiInputTableFilterDropdown {...props} {...filter}/>;
            }
        });

        return tableProps.columns;
    }, [tableProps?.columns, defaultFilters]);

    //Update page when callback changes
    useEffect(() => {
        fetchPage()
    }, [fetchPage]);

    return <Table {...tableProps} columns={columns} onChange={onChange} rowKey="id" dataSource={dataSource} pagination={localPagination} loading={loading || tableProps?.loading}/>
};

export default NpiPaginatedTable;