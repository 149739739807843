import React from "react";
import {Avatar} from "antd";
import {IKpi} from "../../types/kpi";
import styled from "styled-components";
import {AvatarProps} from "antd/lib/avatar";

const StyledSpan = styled.span`
  display: flex;
  align-items: center;
  .icon {
    flex: 0 0 32px;
  }
  .text{
    display: inline-block;
  }
`;

interface INpiDisplayKpiName {
    kpi:IKpi
    text?:boolean
    withIcon?:boolean
    avatarProps?: AvatarProps
    style?: any
}

const NpiDisplayKpiName = ({kpi, text=true, withIcon=true, avatarProps, style}:INpiDisplayKpiName) => {
    return <StyledSpan style={style}>
        {withIcon && <Avatar className="icon" style={{backgroundColor: kpi.color}} {...avatarProps}>{kpi.code}</Avatar>}
        {!!text && <div className="text" style={{marginLeft: withIcon ? 10:5}}>{kpi.name}</div>}
    </StyledSpan>
};

export default NpiDisplayKpiName;