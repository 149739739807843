import React from 'react';
import {Row, Col, Space, Button, Card, Tag, Alert} from "antd";
import {observer} from "mobx-react";
import _ from "lodash";
import npiApi from "../../services/api";
import {useRequest} from "ahooks";
import {RocketOutlined} from "@ant-design/icons/lib";
import {INpi, INpiStatusEnum} from "../../types/npi";
import colors from "../../services/colors";
import { useHistory } from 'react-router-dom';
import useArchive from "../../hooks/use-archive";
import {Trans, useTranslation} from "react-i18next";

const {DRAFT, PUBLISH, ARCHIVED} = INpiStatusEnum;

/**
 *
 */
const NpiConfigTabLaunch = observer(({npi}:{npi: INpi}) => {
    const {status} = npi;
    const {t} = useTranslation();
    const history = useHistory();
    const {archiveNpi, loadingArchiveNpi} = useArchive();
    const afterStatusChange = ({npi: newNpi}:{npi:INpi}) => {
        _.extend(npi, newNpi);
        if(newNpi.status === PUBLISH){
            history.push('/npi/' + newNpi.id);
        }
    };
    const {runAsync: runChangeStatus, loading: loadingChangeStatus} = useRequest(npiApi.internal.npi.changeStatus, {manual: true, onSuccess: afterStatusChange});
    const loading = loadingChangeStatus || loadingArchiveNpi;
    const toggleArchive = () => archiveNpi(npi, afterStatusChange);

    return <Row gutter={[20,20]}>
        <Col offset={8} span={8} style={{minHeight: '100%'}}>
            <Card title={<h3><RocketOutlined /> {t('COMMON.ACTIONS')}</h3>} style={{minHeight: '100%'}}>
                <Space direction="vertical" style={{width: '100%'}}>
                    <div>
                        <Trans
                            t={t}
                            i18nKey={"INTERNAL.CLIENT.NPI.NPI_IS_CURRENTLY"}
                            values={{name: npi.name}}
                            components={{b: <b></b>}}
                        />&nbsp;
                        {status === DRAFT && <Tag color={colors.greyDisabledText}>{t('INTERNAL.CLIENT.COMMON.DRAFT')}</Tag>}
                        {status === PUBLISH && <Tag color={colors.blue}>{t('INTERNAL.CLIENT.COMMON.READY')}</Tag>}
                        {status === ARCHIVED && <Tag color={colors.darkred}>{t('INTERNAL.CLIENT.COMMON.ARCHIVED')}</Tag>}
                    </div>

                    {status === DRAFT && <Alert message={
                        <Trans
                            t={t}
                            i18nKey={"INTERNAL.CLIENT.NPI.DRAFT_MODE_ALERT_MESSAGE"}
                            components={
                                {
                                    ul: <ul></ul>,
                                    li: <li></li>
                                }
                            }
                        />
                    } />}

                    {status === DRAFT    && <Button block loading={loading} size="large" onClick={() => runChangeStatus({...npi, status: PUBLISH})} type="primary">{t('INTERNAL.CLIENT.NPI.PUBLISH')}</Button>}
                    {status === PUBLISH  && <Button block loading={loading} size="large" onClick={() => runChangeStatus({...npi, status: DRAFT})}>{t('INTERNAL.CLIENT.NPI.BACK_TO_DRAFT')}</Button>}
                    {status !== ARCHIVED && <Button block loading={loading} size="large" onClick={toggleArchive} type="default">{t('INTERNAL.CLIENT.COMMON.ARCHIVE')}</Button>}
                    {status === ARCHIVED && <Button block loading={loading} size="large" onClick={toggleArchive} type="default">{t('INTERNAL.CLIENT.COMMON.UNARCHIVE')}</Button>}
                </Space>
            </Card>
        </Col>
    </Row>;
});

export default NpiConfigTabLaunch;