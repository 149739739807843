import React, {ReactNode, useCallback, useContext, useMemo} from "react";
import { Select} from "antd";
import NpiLanguageContext from "../contexts/language-context";
import styled, {css} from "styled-components";
import {useTranslation} from "react-i18next";
import {ILanguage} from "../types/language";
import {getNodeText} from "../helpers/toolbox";
import {SelectProps} from "antd/lib/select";


/**
 * Change the current lang of interface. (use in both external and internal)
 * (different from input/select-language.tsx that just do a simple selection of languages)
 */
interface INpiLanguageSelectorProps extends SelectProps {
    onChanged?: (langCode: string) => void,
    darkMode?: boolean,
    inMenu?: boolean,
    prefixIcon?: ReactNode,
    hideEmpty?: boolean,
    display?: 'short'|'medium'|'long',
    style?: any,
}

const StyledLanguageSelector = styled.div<{darkMode: boolean, display: string}>`

  .ant-select {
    background: transparent; 
    
    .ant-select-selection-item {
      cursor: pointer;
      margin-right: 10px;
    }

    .ant-select-arrow > span[role="img"] {
      font-size: 16px;
    }

    .ant-select-arrow, .ant-select-selector {
      transition: ease 0.15s;
      color: #1890ff;
      cursor: pointer;
    }

    .ant-select-selector {
      height: 30px;
      border: unset !important;
      padding: 0 10px;
      background: transparent !important;
      font-weight: 400;
      box-shadow: unset !important;
    }
  }
  
  ${props => props.darkMode && css`
    .ant-select-arrow, .ant-select-selector {
      color: rgba(255, 255, 255, 0.65) !important;
    }
    
    &:hover, &:active {
      .ant-select-arrow, .ant-select-selector {
        color: rgba(255, 255, 255, 0.95) !important;
        * {
          color: rgba(255, 255, 255, 0.95) !important;
        }
      }
    }
    
    .ant-select.ant-select-focused {
      .ant-select-arrow, .ant-select-selector {
        color: rgba(255, 255, 255, 0.95) !important;
        * {
          color: rgba(255, 255, 255, 0.95) !important;
        }
      }
    }
  `}
`;

const StyledDropdown = styled.div<{darkMode?: boolean}>`
     ${props => props.darkMode && css`
       background-color: #001529;
       .ant-select-item {
         color: rgba(255, 255, 255, 0.65);
         padding: 8px 16px;
         height: 40px;
         &:hover, &.ant-select-item-option-active {
           color: white;
           background-color: inherit !important;
         }
         &.ant-select-item-option-selected {
           background-color: #1890ff !important;
         }
       }
       .rc-virtual-list-scrollbar-thumb {
         background-color: rgba(255, 255, 255, 0.75) !important;
       }
     `}
`;

/**
 * Component for switching language translation from NpiLanguageContext
 * @constructor
 */
const NpiLanguageSelector = ({onChanged, hideEmpty=true, darkMode=false, inMenu=false, prefixIcon, style, display="long", ...props}: INpiLanguageSelectorProps) => {
    const {t} = useTranslation();

    const {availableLangs, currentLang, changeCurrentLang} = useContext(NpiLanguageContext);

    const displayOption = useCallback((lang: ILanguage): string => {
        switch (display) {
            case 'short': return lang.code;
            case 'medium': return lang.short_name;
            default: return lang.name;
        }
    }, [display]);


    //sort the langages by display label
    const sortLangs = useMemo(() => availableLangs.sort((a, b) =>
        (b === currentLang) ? 1 : (
            (a === currentLang || getNodeText(displayOption(a)) < getNodeText(displayOption(b))) ? -1 : 1
        )
    ), [availableLangs, currentLang, displayOption]);

    const otherLangAvailable = useMemo(() => !!availableLangs.filter(lang => lang !== currentLang).length, [availableLangs, currentLang]);

    /**
     * At user change lang, dispatch it to context + callback
     * @param langCode string
     */
    const onChangeLanguage = (langCode: string) => {
        changeCurrentLang(langCode);

        //call our onChanged callback from parent
        onChanged?.(langCode);
    }


    // if no lang available, hide component
    if (hideEmpty && !otherLangAvailable) {
        return <></>
    }

    return <StyledLanguageSelector darkMode={darkMode} display={display} style={style}>
            <Select
                placeholder={t('INTERNAL.CLIENT.COMMON.SELECT_LANGUAGE')}
                optionFilterProp="children"
                onChange={onChangeLanguage}
                value={currentLang?.code}
                optionLabelProp={'label'}
                placement={'bottomRight'}
                dropdownRender={menu => <StyledDropdown darkMode={darkMode}>{menu}</StyledDropdown>}
                dropdownStyle={{minWidth: display === 'short' ? 80 : 180, ...(darkMode ? {padding: 0} : {}), ...props.dropdownStyle}}
                dropdownAlign={inMenu ? {offset: [0, 20]} : props.dropdownAlign}
                {...props}
            >
                {sortLangs.map((lang, index) => (
                    <Select.Option key={index} value={lang.code} label={<>{prefixIcon} {display !== 'long' ? lang.code : lang.name}</>}>{displayOption(lang)}</Select.Option>
                ))}
            </Select>
        </StyledLanguageSelector>
}

export default NpiLanguageSelector;