import React from "react";
import {IWTag} from "../../types/wave";
import {Space, Tag, Tooltip} from "antd";
import colors from "../../services/colors";
import _ from "lodash";
import styled from "styled-components";
import {ITagValue} from "../../types/tag";
import {WarningFilled} from "@ant-design/icons/lib";
import {useTranslation} from "react-i18next";

const StyledTag = styled(Tag)`
  padding-right: 2px;
  margin-bottom: 1px;
  
  .tag-value{
      display: inline-block;
      border-radius: 2px;
      padding: 0 5px;
      background: ${colors.white};
      color: #000;
      height: 16px;
      line-height: 16px;
      margin-left: 5px;
  }
`;

const StyledWarningFilled = styled(WarningFilled)`
  color: ${colors.yellow};
  font-size: 19px;
  position: relative;
  top: 2px;
`;

/**
 * Displays POS tags. Will display a limited number and then the rest as a tooltip
 * @param value
 * @param tags
 * @param max (use -1 to show all without ellipsis)
 * @param wrap
 * @constructor
 */
const NpiDisplayPosTags = ({value, tags, max=2, wrap=false}:{value: ITagValue, tags:IWTag[], max?:number, wrap?: boolean}) => {
    const tagsById = _.keyBy(tags, 'id');
    const posTags = _.keys(value).map(v => parseInt(v)).filter(v => !!tagsById[v]) as number[];
    const localMax = max<0 ? posTags.length : max;
    const missingRequiredTags = tags.filter(tag => !_.has(value, tag.id) && _.find(tag.config?.rules, {type: 'required'}));
    const {t} = useTranslation();
    return <Space size={[0,0]} wrap={wrap}>
        {posTags.length>0 && <Space size={[0,0]} wrap={wrap}>
            {/*/!*Display tags, up to max*!/*/}
            {posTags.slice(0, localMax).map(tId => !!tagsById[tId] && <StyledTag key={tId} color={colors.blue}>{tagsById[tId]?.name} <div className="tag-value">{value[tId]}</div></StyledTag>)}

            {/*Display remaining tags in a tooltip*/}
            {posTags.length > localMax && <Tooltip title={<ul style={{margin: 0, paddingLeft: 20}}>
                {posTags.slice(localMax).map(tId => <li key={tId}>{tagsById[tId]?.name}: {value[tId]}</li>)}
            </ul>}>
                <Tag key="more" color={colors.blue}>{max>0 ? '+':''}{posTags.length - localMax}</Tag>
            </Tooltip>}
        </Space>}
        {missingRequiredTags.length>0 &&
            <Tooltip title={<>{`${t('INTERNAL.CLIENT.TAGS.MISSING_FOLLOWING')}:`} <ul>{missingRequiredTags.map(t => <li key={t.id}>{t.name}</li>)}</ul></>}>
                <StyledWarningFilled/>
            </Tooltip>
        }
    </Space>
};

export default NpiDisplayPosTags;