import React, {useMemo} from 'react';
import {Button, Modal, Space, Table} from "antd";
import NpiDisplayCountry from "./country";
import {NpiInternalContextProvider} from "../../contexts/internal-context";
import {IWave} from "../../types/wave";
import npiApi from "../../services/api";
import {LockOutlined, ReloadOutlined, UserOutlined} from "@ant-design/icons";
import {Trans, Translation, useTranslation} from "react-i18next";
import {useRequest} from "ahooks";
import {ExternalTokenType, IExternalLink} from "../../types/external-token";
import useAcl from "../../hooks/use-acl";
import styled from "styled-components";

export const openModalExternalLinks = (wave: IWave) => {
    Modal.info({
        title: <span>{wave.name} <Translation>{(t, { i18n }) => t('INTERNAL.CLIENT.COMMON.LINKS')}</Translation></span>,
        content: <NpiInternalContextProvider>
            <NpiDisplayModalWaveLinks wave={wave}/>
        </NpiInternalContextProvider>
    })
};


interface IWaveLink {
    external_links: IExternalLink
    contactName?: string
    countryId?: number
}
const NpiDisplayModalWaveLinks = ({wave}:{wave: IWave}) => {
    const {t} = useTranslation();
    const { isAdmin, hasRegionAccess } = useAcl();

    const canResetLinks = useMemo(() => {
        return isAdmin && hasRegionAccess(wave.region_id)
    }, [isAdmin, hasRegionAccess, wave]);


    const {runAsync: fetchLinks, data: links, loading} = useRequest<IWaveLink[], [number]>(npiApi.internal.wave.links, {
        defaultParams: [wave.id],
    });

    const {runAsync: resetLinks, loading: loadingReset} = useRequest(npiApi.internal.wave.resetLinks, {
        manual: true,
        onSuccess: () => fetchLinks(wave.id),
    });

    const onClickReset = () => {
        Modal.confirm({
            title: t('INTERNAL.CLIENT.WAVE.LINKS.RESET'),
            content: <>
                <Trans t={t} i18nKey={'INTERNAL.CLIENT.WAVE.LINKS.RESET_WARNING'} components={{strong: <strong></strong>}}/>
                <br/><br/>
                <Trans t={t} i18nKey={'INTERNAL.CLIENT.COMMON.LINK.RESET_WARNING_INFO'}/>
                <br/><br/>
                <em>
                    <Trans t={t} i18nKey={'INTERNAL.CLIENT.COMMON.LINK.RESET_WARNING_CONFIRM'}/>
                </em>
            </>,
            onOk: () => resetLinks(wave.id).then(null)
        })
    }

    return <>
        <Table
            loading={loading}
            rowKey={r => r.external_links.url}
            columns={[
                { render:(row: IWaveLink) => {
                        switch (row.external_links.type) {
                            case ExternalTokenType.RESTRICT_BY_COUNTRY_AND_WAVE:
                                return <NpiDisplayCountry id={row.countryId!}/>
                            case ExternalTokenType.RESTRICT_BY_CONTACT_AND_WAVE:
                                return <> <UserOutlined/> {row.contactName!}</>
                        }
                    }
                },
                { render:(row: IWaveLink) => <Space>
                        <Button href={row.external_links.url} target="_blank">{t('INTERNAL.CLIENT.COMMON.VIEW')}</Button>

                        {!!row.external_links.hiddenUrl
                            && <Button href={row.external_links.hiddenUrl} target="_blank" icon={<LockOutlined/>}/>
                        }
                </Space>, align: 'right'},
            ]}
            dataSource={links}
            scroll={(!!links && links.length > 8) ? {y: 400}:{}}
            pagination={false}
            showHeader={false}
            size="small"
        />

        { (canResetLinks && !!links && links.length > 0) &&
            <StyledButtonReset icon={<ReloadOutlined />} onClick={onClickReset} loading={loadingReset}>
                {t('INTERNAL.CLIENT.COMMON.LINK.RESET')}
            </StyledButtonReset>
        }
    </>
};

const StyledButtonReset = styled(Button)`
    position: absolute;
    bottom: 24px;
`

export default NpiDisplayModalWaveLinks;