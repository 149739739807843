import _ from "lodash";
import {IQuestionTypeEnum, IWithQuestions} from "../types/question";

const {LIST, MULTILIST, BOOL} = IQuestionTypeEnum;

/**
 * This method will return the all the terms to translate for a list of questions
 * @param item
 */
export const generateTerms = (item: IWithQuestions) => {
    const terms:any[] = [];

    //Add name if we are in a wave
    if(_.has(item, 'npi_id')){
        terms.push({
            path: 'W' + item.id + '.name',
            term: item.name,
        })
    }

    item.questions.forEach(q => {
        //Default field that are always translated
        const paths = {
            [`Q${q.id}.display_name`]: q.display_name,
            [`Q${q.id}.description`]: q.description,
        };

        //Answers field that are sometimes here
        if([LIST, MULTILIST, BOOL].indexOf(q.type)>=0){
            q.config.answers?.forEach((a, key) => paths[`Q${q.id}.answers.${key}`] = a.label)
        }

        _.forEach(paths, (term, path) => term && terms.push({path, term}));
    });

    return terms;
}