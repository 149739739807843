import React, { useContext, useEffect, useState } from 'react';
import {Button, Col, Form, Input, InputNumber, notification, Popconfirm, Row, Switch, Tabs, Tooltip,} from "antd";
import {IRegionType, NpiInternalContext } from "../../contexts/internal-context";
import NpiInputSelectLanguage from "../input/select-language";
import _ from "lodash";
import {IWave} from "../../types/wave";
import {observer} from "mobx-react";
import {FlagOutlined, InfoCircleFilled, MailOutlined, SaveFilled, SettingOutlined} from "@ant-design/icons/lib";
import {useWaveStore} from "../../contexts/stores";
import NpiInputDatePicker from "../input/date-picker";
import npiApi from "../../services/api";
import NpiDisplayRegion from "../display/region";
import NpiInputSelectShipmentCode from "../input/select-shipment-code";
import {useTranslation} from "react-i18next";
import {REGION_WW} from "../../helpers/constants";

//Tooltips for the form, [translation] these are keys for translations to add a tooltip text you have to add it to the translations file
const tooltips = {
    accessibleAtUnifiedCountryExternalLink: "INTERNAL.CLIENT.WAVE.TAB_INFORMATION.TOOLTIP.ACCESSIBLE_AT_UNIFIED_COUNTRY_EXTERNAL_LINK",
    tableauIdentifier: "INTERNAL.CLIENT.WAVE.TAB_INFORMATION.TOOLTIP.TABLEAU_IDENTIFIER",
    images: "INTERNAL.CLIENT.WAVE.TAB_INFORMATION.TOOLTIP.IMAGES",
    weight: "INTERNAL.CLIENT.WAVE.TAB_INFORMATION.TOOLTIP.WEIGHT",
    mandatoryImages: "INTERNAL.CLIENT.WAVE.TAB_INFORMATION.TOOLTIP.MANDATORY_IMAGES",
    limitImages: "INTERNAL.CLIENT.WAVE.TAB_INFORMATION.TOOLTIP.LIMIT_IMAGES",
    shipments: "INTERNAL.CLIENT.WAVE.TAB_INFORMATION.TOOLTIP.SHIPMENTS",
};

const Note = ({text}: { text: string }) => {
    return <Tooltip title={text}>&nbsp;<InfoCircleFilled/></Tooltip>;
};

const NpiWaveTabInformation = observer(({wave}: { wave: IWave }) => {
    const [form] = Form.useForm();
    const {save, saving} = useWaveStore();
    const [previewNotificationMail, setPreviewNotificationMail] = useState<string>("");
    const [isDirty, setIsDirty] = useState<boolean>(false);
    const [hasShipmentCodeSelection, setHasShipmentCodeSelection] = useState<boolean>(false);
    const {regions, env} = useContext(NpiInternalContext);
    const [showMandatoryImages, setShowMandatoryImages] = useState(wave.is_images_allowed);
    const {t} = useTranslation();
    const onSave = async () => {
        const params = {...wave, ...form.getFieldsValue()};
        try {
            await save(params);
            notification.success({message: t('INTERNAL.CLIENT.WAVE.WAVE_INFORMATION_SAVED')});
            setIsDirty(false);
        } catch {
        }
    };

    //Observe changes to the form
    const onChange = (changes: any) => {
        setIsDirty(!!_.find(changes, {touched: true}));
        const {shipment_code_id, is_images_allowed} = form.getFieldsValue();

        //Undefined does not get sent: we must fix by forcing "NULL" when clearing
        if (!shipment_code_id) {
            form.setFieldsValue({shipment_code_id: null});
        }

        //Automate mandatory images behavior based on if we have images
        if (!is_images_allowed) {
            form.setFieldsValue({is_images_mandatory: false});
            setShowMandatoryImages(false);
        } else {
            setShowMandatoryImages(true);
        }
    };

    useEffect(() => {
        if (wave) {
            const region = regions.find((item: IRegionType) => item.id === wave.region_id)
            if (region && region.has_shipment_access) {
                setHasShipmentCodeSelection(true);
            }
        }
    }, [wave, regions]);


    useEffect(() => {
        form.setFieldsValue({
            translation_ids: [], //defaults
            ..._.pickBy(wave, v => v !== null), //get everything from the wave without null values
        });
    }, [wave, form]);


    /**
     * When user preview the mail
     */
    const onPreviewMail = () => {
        const {notifications} = form.getFieldValue('config');
        npiApi.internal.wave.previewNotification(wave.id, previewNotificationMail, notifications.launch_mail);
    };

    /**
     * Tabs definition
     */
    const tabs = [{
        key: '1',
        label: <><SettingOutlined/> {t('INTERNAL.CLIENT.COMMON.GENERAL')}</>,
        children: <>
            {!!wave.daily_group_id && <Form.Item label={t('INTERNAL.CLIENT.WAVE.DAILY_GROUP_ID')} name="daily_group_id">#{wave.daily_group_id}</Form.Item>}
            <Form.Item label={t('INTERNAL.CLIENT.COMMON.NAME')} name="name"><Input/></Form.Item>
            <Form.Item label={t('INTERNAL.CLIENT.COMMON.REGION')} name="region_id"><NpiDisplayRegion id={wave.region_id}/></Form.Item>
            <Form.Item
                label={<>{t('INTERNAL.CLIENT.COMMON.UNIFIED_EXTERNAL_LINK')} <Note text={t(tooltips.accessibleAtUnifiedCountryExternalLink)}/></>}
                name="is_available_in_unified_links"
                valuePropName="checked"
            >
                <Switch />
            </Form.Item>
            <Form.Item
                label={<>{t('INTERNAL.CLIENT.COMMON.TABLEAU_IDENTIFIER')} <Note text={t(tooltips.tableauIdentifier)}/></>}
                name="tableau_id"
            >
                <Input style={{width: 150}} />
            </Form.Item>
            <Form.Item label={t('INTERNAL.CLIENT.COMMON.LAUNCH_DATE')} name="date_launch"><NpiInputDatePicker/></Form.Item>
            <Form.Item label={t('INTERNAL.CLIENT.COMMON.END_DATE')} name="date_deadline"><NpiInputDatePicker/></Form.Item>
            <Form.Item label={<>{t('INTERNAL.CLIENT.COMMON.IMAGES')} <Note text={t(tooltips.images)}/></>} name="is_images_allowed"
                       valuePropName="checked"><Switch/></Form.Item>
            <Form.Item style={{display: showMandatoryImages ? 'block' : 'none'}}
                       label={<>{t('INTERNAL.CLIENT.COMMON.MANDATORY_IMAGES')} <Note text={t(tooltips.mandatoryImages)}/></>}
                       name="is_images_mandatory" valuePropName="checked"><Switch/></Form.Item>
            <Form.Item style={{display: showMandatoryImages ? 'block' : 'none'}}
                       label={<>{t('INTERNAL.CLIENT.COMMON.IMAGES_LIMIT')} <Note text={t(tooltips.limitImages)}/></>}
                       name="images_upload_limit"><InputNumber placeholder={t('INTERNAL.CLIENT.COMMON.MAX_IMAGES')} style={{width: 156}} min={1}/></Form.Item>
            <Form.Item
                label={<>{t('INTERNAL.CLIENT.COMMON.WEIGHT')} <Note text={t(tooltips.weight)}/></>}
                name="weight"><InputNumber /></Form.Item>

            <Form.Item label={t('INTERNAL.CLIENT.COMMON.MAIN_LANGUAGE')} name="language_id"><NpiInputSelectLanguage inRegion={wave.region_id === REGION_WW ? undefined : wave.region_id}/></Form.Item>
            <Form.Item label={t('INTERNAL.CLIENT.COMMON.TRANSLATIONS')} name="translation_ids"><NpiInputSelectLanguage mode="multiple" inRegion={wave.region_id === REGION_WW ? undefined : wave.region_id}/></Form.Item>

            { hasShipmentCodeSelection && <Form.Item label={<>{t('INTERNAL.CLIENT.DASHBOARD.SHIPMENT_CODE')} <Note text={t(tooltips.shipments)}/></>}
                       name="shipment_code_id">
                <NpiInputSelectShipmentCode regionId={wave.region_id} allowClear/>
            </Form.Item>}
        </>
    },{
        key: '2',
        label: <><FlagOutlined/> {t('INTERNAL.CLIENT.COMMON.NOTIFICATION')}</>,
        children: <>
            <p>{t('INTERNAL.CLIENT.COMMON.MAIL_DETAIL')}</p>

            <Form.Item
                label={t('INTERNAL.CLIENT.COMMON.SENDER_NAME')}
                name={["config", "notifications", "launch_mail", "sender_name"]}
                initialValue={`${env.mail.from_name}`}
            >
                <Input/>
            </Form.Item>
            <Form.Item
                label={t('INTERNAL.CLIENT.COMMON.MAIL_SUBJECT')}
                name={["config", "notifications", "launch_mail", "subject"]}
                initialValue={t('INTERNAL.CLIENT.WAVE.NEW_WAVE_LAUNCHED')}
            >
                <Input/>
            </Form.Item>

            <p>{t('INTERNAL.CLIENT.COMMON.MAIL_CONTENT')}</p>

            <Form.Item
                label={t('INTERNAL.CLIENT.COMMON.HEADER')}
                name={["config", "notifications", "launch_mail", "header"]}
                initialValue={`${env.app_name}`}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label={t('INTERNAL.CLIENT.COMMON.CONTENT')}
                name={["config", "notifications", "launch_mail", "content"]}
                initialValue={t('INTERNAL.CLIENT.WAVE.A_WAVE_LAUNCHED')}
            >
                <Input.TextArea/>
            </Form.Item>
            <Form.Item
                label={t('INTERNAL.CLIENT.COMMON.FOOTER')}
                name={["config", "notifications", "launch_mail", "footer"]}
                initialValue={t('INTERNAL.CLIENT.COMMON.FOOTER_THANKS', {sign: env.app_name})}
            >
                <Input.TextArea/>
            </Form.Item>



            <Popconfirm okText={t('INTERNAL.CLIENT.COMMON.SEND')} icon={<SettingOutlined />} cancelButtonProps={{hidden: true}} cancelText={null} title={
                <>
                    {t('INTERNAL.CLIENT.COMMON.WRITE_MAIL_TO_PREVIEW')}
                    <Input
                        placeholder="notification@apple.com"
                        value={previewNotificationMail}
                        onChange={(e) => setPreviewNotificationMail(e.target.value)}
                    />
                </>
            } onConfirm={onPreviewMail}>
                <Button>
                    <MailOutlined/> {t('INTERNAL.CLIENT.COMMON.PREVIEW_MAIL')}
                </Button>
            </Popconfirm>
        </>
    }];

    return <Row>
        <Col span={12} offset={5}>
            <Form form={form} labelCol={{span: 8}} onFieldsChange={onChange}>
                <Tabs tabPosition="left" items={tabs}/>
                <Button style={{float: 'right'}} type="primary" onClick={onSave} icon={<SaveFilled/>} loading={saving}
                        disabled={!isDirty}>{t('COMMON.SAVE')}</Button>
            </Form>
        </Col>
    </Row>
});

export default NpiWaveTabInformation;
