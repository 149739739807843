import {ProgressProps} from "antd/lib/progress";
import React, {useMemo} from "react";
import {Progress} from "antd";
import styled from "styled-components";
import colors from "../../services/colors";

const StyledProgress = styled(Progress)`
    &.zero .ant-progress-text{
        color: ${colors.redLighter} !important;
    }
`;

/**
 * Colored progress circle
 * @param percent
 * @param props
 * @constructor
 */
const NpiWwDashboardColoredProgressCircle = ({percent, ...props}:ProgressProps) => {

    //Color for the KPIs
    const color = useMemo(() => {
        if((percent??0)>=80) return '#6aa97d';
        if((percent??0)>=60) return '#f9e372';
        return '#ed6e57';
    }, [percent]);

    const isZero = useMemo(() => percent === 0, [percent]);

    return <StyledProgress
        type="circle"
        className={isZero ? 'zero':''}
        width={35}
        strokeColor={color}
        strokeWidth={12}
        trailColor={'#e0e0e0'}
        percent={percent}
        format={(percent) => <div className="numbers">
            <div className="percent">{percent}%</div>
        </div>}
        {...props}
    />;
};

export default NpiWwDashboardColoredProgressCircle;