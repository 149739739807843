import React from "react";

import {IWave, IWaveStatusEnum} from "../types/wave";
import {Alert, Modal} from "antd";
import {useRequest} from "ahooks";
import npiApi from "../services/api";
import {INpi, INpiStatusEnum} from "../types/npi";
import {DeleteFilled} from "@ant-design/icons/lib";
import colors from "../services/colors";
import {Trans, useTranslation} from "react-i18next";

const {ARCHIVED} = INpiStatusEnum;
const {ARCHIVED: WAVE_ARCHIVED} = IWaveStatusEnum;

export default function useArchive() {

    const {t} = useTranslation();

    const {runAsync: runDeleteNpi, loading: loadingDeleteNpi} = useRequest<any,any>(npiApi.internal.npi.delete, {manual: true});
    const {runAsync: runDeleteWave, loading: loadingDeleteWave} = useRequest<any,any>(npiApi.internal.wave.delete, {manual: true});
    const {runAsync: runArchiveNpi, loading: loadingArchiveNpi} = useRequest<any,any>(npiApi.internal.npi.archive, {manual: true});
    const {runAsync: runArchiveWave, loading: loadingArchiveWave} = useRequest<any,any>(npiApi.internal.wave.archive, {manual: true});

    //Toggle to Un/Archive NPI
    const archiveNpi = (npi:INpi, callback=({npi}:{npi:INpi})=>{}) => {
        const waves = npi.waves?.filter(w => w.status !== IWaveStatusEnum.ARCHIVED) ?? null;

        if(npi.status !== ARCHIVED){
            Modal.confirm({
                title: <span>{t("INTERNAL.CLIENT.COMMON.ARCHIVE")} '{npi.name}'</span>,
                width: 600,
                content: <div>
                    {t("INTERNAL.CLIENT.NPI.ARCHIVE")}

                    {waves?.length>0 && <div>
                        <Trans i18nKey="INTERNAL.CLIENT.NPI.ARCHIVE_DETAIL_SOME" t={t} components={{ strong: <strong></strong> }}/>
                        <ul>
                            {waves.map(w => <li key={w.id}>{w.name}</li>)}
                        </ul>
                    </div>}
                    {waves === null && <div><Trans i18nKey="INTERNAL.CLIENT.NPI.ARCHIVE_DETAIL_ALL" t={t} components={{ strong: <strong></strong> }}/></div>}
                </div>,
                onOk: () => runArchiveNpi(npi).then(callback),
            });
        }
        else{
            Modal.confirm({
                title: <span>{t("INTERNAL.CLIENT.COMMON.UNARCHIVE")} '{npi.name}'</span>,
                width: 600,
                onOk: () => runArchiveNpi(npi).then(callback),
            });
        }
    };

    //Toggle to Un/Archive Wave
    const archiveWave = (wave:IWave, callback=({wave}:{wave:IWave})=>{}) => {
        if(wave.status !== WAVE_ARCHIVED){
            Modal.confirm({
                title: <span>{t("INTERNAL.CLIENT.COMMON.ARCHIVE")} '{wave.name}'</span>,
                width: 600,
                content: <div>
                    {t('INTERNAL.CLIENT.WAVE.ARCHIVE')}
                    {!!wave.daily_group_id && <div><em>{t('INTERNAL.CLIENT.WAVE.ARCHIVE_DAILY')}</em></div>}
                </div>,
                onOk: () => runArchiveWave(wave).then(callback),
            });
        }
        else{
            Modal.confirm({
                title: <span>{t("INTERNAL.CLIENT.COMMON.UNARCHIVE")} '{wave.name}'</span>,
                width: 600,
                onOk: () => runArchiveWave(wave).then(callback),
            });
        }
    };


    //Delete an NPI
    const deleteNpi = (npi:INpi, callback=({npi}:{npi:INpi})=>{}) => {
        Modal.confirm({
            title: <span>{t("COMMON.DELETE")} '{npi.name}'</span>,
            icon: <DeleteFilled style={{color: colors.red}}/>,
            width: 600,
            content: <div>
                <Trans i18nKey="INTERNAL.CLIENT.NPI.DELETE" t={t} components={{ strong: <strong></strong> }}/>
                <div><Trans i18nKey="INTERNAL.CLIENT.NPI.DELETE_DETAIL" t={t} components={{ strong: <strong></strong> }}/></div>

                <Alert type="error" message={t('INTERNAL.CLIENT.COMMON.IRREVERSIBLE_OPERATION')} style={{marginTop: 20}}/>
            </div>,
            okText: t("COMMON.DELETE"),
            cancelText: t("COMMON.CANCEL"),
            okType: "danger",
            onOk: () => runDeleteNpi(npi).then(callback),
        });
    };

    //Delete a Wave
    const deleteWave = (wave:IWave, callback=({wave}:{wave:IWave})=>{}) => {
        const isDailyDelete = !!wave.daily_group_id && !!wave.is_daily_active;
        Modal.confirm({
            title: <span>{t("COMMON.DELETE")} '{wave.name}'</span>,
            icon: <DeleteFilled style={{color: colors.red}}/>,
            width: 600,
            content: <div>
                <Trans i18nKey="INTERNAL.CLIENT.WAVE.DELETE" t={t} components={{ strong: <strong></strong> }}/>
                {isDailyDelete && <div><br/><em>{t('INTERNAL.CLIENT.WAVE.DELETE_DAILY')}</em></div>}
                <Alert type="error" message={t('INTERNAL.CLIENT.COMMON.IRREVERSIBLE_OPERATION')} style={{marginTop: 20}}/>
            </div>,
            okText: t("COMMON.DELETE"),
            cancelText: t("COMMON.CANCEL"),
            okType: "danger",
            onOk: () => runDeleteWave(wave).then(callback),
        });
    };

    return { archiveNpi, loadingArchiveNpi, archiveWave, loadingArchiveWave, deleteNpi, loadingDeleteNpi , deleteWave, loadingDeleteWave};
}