import React, {useState} from 'react';
import {IWavePosImage} from "../../types/wave-pos-image";
import { Swiper, SwiperSlide } from "swiper/react";
import NpiGalleryImageDescription from "./image-description";
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.min.css";
import styled from "styled-components";
import {FreeMode, Keyboard, Lazy, Manipulation, Navigation, Thumbs, Virtual} from "swiper";
import {Button, Spin} from "antd";
import {CloseOutlined, LoadingOutlined} from "@ant-design/icons";
import NpiGalleryImage from "./image";

const StyledCarousel = styled.div<{ visible?: boolean }>`
  position: fixed;
  top: 0; left: 0; bottom: 0; right: 0;
  background: #000;
  
  .swiper-button-next, .swiper-button-prev {
    color: #fff;
    &:hover {
      color: #A6ADB4;
    }
  }

  .swiper {
    margin-left: auto;
    margin-right: auto;
      
    .swiper-slide {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      
      img {
        display: block;
        max-height: 100%;
        max-width: 100%;
        width: 100%;
        height: auto;
        object-fit: contain;
      }
    } 
    
    &.swiperFull {
      height: 80%;
      max-height: calc(100vh - 60px);
      width: 100%;
      
      .swiper-slide {
        flex-direction: column;
        justify-content: flex-end;
        flex-wrap: nowrap;
        padding: 5px 0;
        
        .img-container {
          flex-grow: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 0;
        }
        .img-description {
          color: #fff;
          margin-top: 10px;
        }
      }
    }
    &.swiperThumb {
        height: 20%;
        min-height: 60px;
        padding: 10px 0;
        .swiper-slide {
          height: 100%;
          opacity: 0.4; 
          &.swiper-slide-thumb-active {
            opacity: 1;              
          }
          img {
            object-fit: cover;
          }
        }
        .swipper-slide-loading {
          .ant-spin {
            color: white;
          }
        }
    }
  }
`;

const StyledCloseButton = styled(Button)`
  position: fixed;
  top: 10px;
  right: 15px;
  z-index: 10;
  color: white;
  background: transparent;
  border: 0;
  outline: 0;
  &:focus,
  &:hover {
    background: transparent;
    color: #bbb;
  }
`;

interface CarouselContainerProps {
    startIndex?: number,
    listImages: IWavePosImage[],
    hasMore: boolean,
    fetchMore: () => Promise<any>,
    onClose: (index?:number) => void,
    visible?: boolean,
}

const WINDOW_SIZE = 10;
const Key = {
    ESCAPE: 27,
}

const NpiContainerGalleryCarouselContainer = ({startIndex, listImages, hasMore, fetchMore, onClose, visible}: CarouselContainerProps) => {

    const [mainSwiperRef, setMainSwiperRef] = useState<any>(null);
    const [thumbsSwiperRef, setThumbsSwiperRef] = useState<any>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    // Close Slideshow and return current slide
    const closeCarousel = () => {
        onClose(mainSwiperRef ? mainSwiperRef.activeIndex : undefined);
    }

    // onSlide image, check if we need to fetch more
    const onSlideChange = (newIndex:number) => {
        if( hasMore && newIndex > listImages.length - WINDOW_SIZE ) {
            if( thumbsSwiperRef ) {
                setIsLoading(true);
            }
            fetchMore().then(() => {
                if(mainSwiperRef){
                    mainSwiperRef.update();
                }
                if(thumbsSwiperRef){
                    setIsLoading(false);
                    thumbsSwiperRef.update();
                }
            })
        }
    }

    return <StyledCarousel visible={visible}>
        <StyledCloseButton onClick={closeCarousel} icon={<CloseOutlined/>}/>
        <Swiper
            className="swiperFull"
            onSwiper={setMainSwiperRef}
            virtual
            initialSlide={startIndex}
            onActiveIndexChange={sw => onSlideChange(sw.activeIndex)}
            navigation={{enabled: true, hideOnClick: true}}
            keyboard={true}
            freeMode={true}
            thumbs={{ swiper: thumbsSwiperRef }}
            modules={[Virtual, Thumbs, Navigation, Keyboard, FreeMode]}
            onKeyPress={(sw, key) => (Number.parseInt(key) === Key.ESCAPE) && closeCarousel() }
        >
            {listImages.map((wp, index) => (
                <SwiperSlide key={wp.id} virtualIndex={index}>
                    <NpiGalleryImage image={wp} withLinks={true}/>
                    {wp.pos_name || wp.created_at ? <NpiGalleryImageDescription image={wp} full={true} className={"img-description"}/> : ''}
                </SwiperSlide>
            ))}
        </Swiper>
        <Swiper
            className="swiperThumb"
            onSwiper={setThumbsSwiperRef}
            virtual={true} // slidesPerView must be set for virtualization to work
            initialSlide={startIndex}
            spaceBetween={5}
            slidesPerView={3}
            breakpoints={{
                350: {slidesPerView: 4 },
                700: {slidesPerView: 8 },
                950: {slidesPerView: 10 },
                1300: {slidesPerView: 12 },
            }}
            watchSlidesProgress={true}
            centeredSlides={true}
            slideToClickedSlide={true}
            freeMode={true}
            modules={[Virtual, Lazy, FreeMode, Manipulation]}
        >
            { listImages.map((wp, index) => (
                <SwiperSlide key={'thumb-'+wp.id} virtualIndex={index}>
                    <NpiGalleryImage image={wp} isThumb={true}/>
                </SwiperSlide>
            ))}
            { isLoading
                ? <SwiperSlide className={"swipper-slide-loading"}><Spin size={'large'} indicator={<LoadingOutlined spin />}/></SwiperSlide>
                : '' }
        </Swiper>
    </StyledCarousel>;
}

export default NpiContainerGalleryCarouselContainer;