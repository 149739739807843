import React, {useContext, useEffect, useMemo} from 'react';
import NpiDisplayLayoutContent from "../../components/display/layout-content";
import {NpiInternalContext} from "../../contexts/internal-context";
import {Button, Form, notification, Switch} from "antd";
import {SaveFilled} from "@ant-design/icons/lib";
import npiApi from "../../services/api";
import _ from "lodash";
import {convertUtcTimeToLocalTime} from "../../helpers/date";
import {Trans, useTranslation} from "react-i18next";


const NpiContainerAdminUserPreferences = () => {
    //get context
    const {setBreadcrumbs, user, regions} = useContext(NpiInternalContext);
    const {t} = useTranslation();
    const [form] = Form.useForm();
    const dailyNotificationLocalTime = useMemo(() => convertUtcTimeToLocalTime(regions[user.region_id]?.daily_notification_utc_time), [user, regions]);
    const onSave = () => npiApi.internal.user.savePreferences(_.merge(user, form.getFieldsValue()))
        .then(() => notification.success({message: t('INTERNAL.CLIENT.PREFERENCES.PREFERENCES_SAVED')}));

    //Set navigation
    useEffect(() => {
        setBreadcrumbs([{name: t('INTERNAL.CLIENT.COMMON.PREFERENCES')}])
        form.setFieldsValue(user);
    }, [setBreadcrumbs, user, form, t]);

    return <NpiDisplayLayoutContent>

        <Form form={form}>
            <Form.Item name={['notifications', 'daily']} label={t('INTERNAL.CLIENT.PREFERENCES.DAILY_NOTIFICATIONS')} valuePropName="checked">
                <Switch/>
            </Form.Item>
            <p style={{fontSize: 12, color: 'gray'}}>
                <Trans
                    i18nKey="INTERNAL.CLIENT.PREFERENCES.NOTIFIED_AT"
                    t={t}
                    values={{ notificationTime: dailyNotificationLocalTime}}
                    components={{ bold: <strong /> }}
                />
            </p>
            <Button type="primary" onClick={onSave}><SaveFilled/> {t('COMMON.SAVE')}</Button>
        </Form>

    </NpiDisplayLayoutContent>
};

export default NpiContainerAdminUserPreferences;