import React, {useState} from "react";
import {IShipment, ShipmentPackageQuickActionsEnum} from "../../types/shipment";
import {Select, SelectProps} from "antd";
import {CheckOutlined, CloseOutlined, MinusOutlined} from "@ant-design/icons/lib";
import {useTranslation} from "react-i18next";

interface INpiInputShipmentPackageQuickActionSelect extends SelectProps<any> {
    shipments: IShipment[],
    onChange: (value: ShipmentPackageQuickActionsEnum) => void
}

/**
 * Display shipments package quick action select
 * @param props INpiDisplayShipmentPackageQuickActionSelect
 * @constructor
 */
const NpiInputShipmentPackageQuickActionSelect = (props: INpiInputShipmentPackageQuickActionSelect) => {
    const {t} = useTranslation();
    const [value, setValue] = useState<ShipmentPackageQuickActionsEnum | undefined>();

    const {onChange} = props;


    /**
     * On change select value we informed user action change, and reset the value
     * @param value
     */
    const onChangeSelectValue = (value: ShipmentPackageQuickActionsEnum): void => {
        setValue(value);
        onChange(value);
    }


    /**
     * Get shipments style from ShipmentPackageQuickActionsEnum
     * @param key ShipmentPackageQuickActionsEnum
     * @return {color: string, icon: React.ReactElement}
     */
    const getShipmentQuickActionStyle = (key: ShipmentPackageQuickActionsEnum): { color: string, icon: React.ReactElement } => {
        let color: string = 'orange';
        let icon: React.ReactElement = <MinusOutlined/>;

        switch (key) {
            case ShipmentPackageQuickActionsEnum.ALL_ASSETS_RECEIVED:
                color = 'green';
                icon = <CheckOutlined/>;
                break;
            case ShipmentPackageQuickActionsEnum.ALL_ASSETS_DAMAGED:
                color = 'red';
                icon = <CloseOutlined/>;
                break;
            default:
                break;
        }

        return {
            color, icon
        }
    }


    return (
        <Select {...props} value={value} onChange={onChangeSelectValue} placeholder={t('EXTERNAL.CLIENT.SHIPMENT.QUICK_PACKAGE_ACTION_PLACEHOLDER')}
                style={{width: '100%', marginTop: 5}}>
            {
                (Object.keys(ShipmentPackageQuickActionsEnum) as Array<keyof typeof ShipmentPackageQuickActionsEnum>)
                    .map((key) => {
                        const {color, icon} = getShipmentQuickActionStyle(ShipmentPackageQuickActionsEnum[key]);

                        return <Select.Option
                            key={key}
                            style={{color}}
                            value={key}>{icon} {t(`EXTERNAL.CLIENT.SHIPMENT.QUICK_ACTIONS.${key}`)}</Select.Option>
                    })
            }
        </Select>
    );
}


export default NpiInputShipmentPackageQuickActionSelect;