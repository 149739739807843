import {useForm} from "antd/es/form/Form";
import React, {useEffect, useMemo, useState} from "react";
import {useRequest} from "ahooks";
import npiApi from "../../services/api";
import {Alert, Badge, Col, Form, Input, InputNumber, Modal, notification, Row} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import NpiInputSelectRegion from "../input/select-region";
import NpiInputSelectNumericalTags from "../input/select-numerical-tags";
import NpiInputSelectCountry from "../input/select-country";
import NpiPosStatusFilterFormItem from "../input/select-status";
import NpiInputSelectProgram from "../input/select-program";
import IContact, {IContactPosAccessConstraints} from "../../types/contact";
import {useTranslation} from "react-i18next";
import NpiInputSelectPartner from "../input/select-partner";

/**
 * Represent the Form Data object
 */
interface INpiEditContactModalFormData {
    id?: number,
    region_id: number,
    name: string | null
    email: string
    pos_access_constraints: IContactPosAccessConstraints
}

interface INpiEditContactModalProps {
    open: boolean,
    title: React.ReactNode,
    contact?: IContact
    onChangeVisibility: (visible: boolean) => void,
    onSubmit: (data: INpiEditContactModalFormData) => Promise<boolean>
}

/**
 * Modal used for create/edit contact at contact-list
 * @constructor
 */
const NpiCreateEditContactModal = (props: INpiEditContactModalProps) => {
    const {t} = useTranslation();
    const {title, open: visible, onChangeVisibility, onSubmit, contact = undefined} = props;

    const [form] = useForm<INpiEditContactModalFormData>();
    const [posAccessCount, setPosAccessCount] = useState<number>(0);

    const currentRegionId = Form.useWatch("region_id", form);
    const currentCountries = Form.useWatch(["pos_access_constraints", "country_id"], form);

    const {
        runAsync: search,
        loading: loadingSearch,
        data: searchResult
    } = useRequest<any, any>(npiApi.internal.contact.searchPos, {manual: true, debounceWait: 500});


    /**
     * Determine ok button text
     */
    const okText = useMemo(() => loadingSearch ?
        <LoadingOutlined spin/> :
        <>{!!contact ? t('INTERNAL.CLIENT.COMMON.EDIT') : t('INTERNAL.CLIENT.COMMON.CREATE')}</>,
        [contact, loadingSearch, t]
    );


    /**
     * Changes pos access count
     */
    useEffect(() => {
        if (!searchResult || !visible) {
            return;
        }

        setPosAccessCount(searchResult?.count ?? 0);
    }, [searchResult, visible]);


    /**
     * Load values to form at visibility change
     */
    useEffect(() => {
        if (contact) {
            form.setFieldsValue(contact);
        }

        //clean up
        return () => {
            form.resetFields();
            setPosAccessCount(0);
        }
    }, [visible, contact, form]);

    /**
     * Handle when POS search filters values changed
     * @param data
     */
    const handleValuesChanges = (data: INpiEditContactModalFormData) => {
        //only when search pos values changed
        if (!data.pos_access_constraints) {
            return;
        }

        const searchPosAccessFieldsValue = form.getFieldValue("pos_access_constraints");

        if (!Object.values(searchPosAccessFieldsValue).find((fieldValue: any) => !!fieldValue && !!fieldValue.length)) {
            setPosAccessCount(0);
            return;
        }

        search(searchPosAccessFieldsValue);
    }

    /**
     * Handle when submit form
     * @param data
     */
    const handleSubmit = async (data: INpiEditContactModalFormData) => {
        if (loadingSearch) {
            notification.error({message: t('INTERNAL.CLIENT.CONTACTS.CREATE_EDIT_MODAL.ERROR_WAIT_END_OF_POS_SEARCH')});
            return;
        }

        if (!posAccessCount) {
            notification.error({message: t('INTERNAL.CLIENT.CONTACTS.CREATE_EDIT_MODAL.ERROR_POS_ACCESS_REQUIRED')});
            return;
        }

        //persist data
        if (!await onSubmit(data)) {
            return;
        }

        //hide modal if successfully submitted to sever
        onChangeVisibility(false);
    }

    return (
        <Modal
            title={title}
            open={visible}
            okText={okText}
            okButtonProps={{disabled: loadingSearch || !posAccessCount}}
            onOk={form.submit}
            onCancel={() => onChangeVisibility(false)}
            width={"60%"}
            destroyOnClose={true}
        >

            <Form
                form={form}
                layout={"vertical"}
                onValuesChange={handleValuesChanges}
                onFinish={handleSubmit}
            >

                <h2>{t('INTERNAL.CLIENT.COMMON.INFORMATION')}</h2>

                <Form.Item key={0} name="id" hidden>
                    <InputNumber/>
                </Form.Item>
                <Form.Item key={1} label={t('INTERNAL.CLIENT.COMMON.REGION')} name="region_id" required>
                    <NpiInputSelectRegion restrict/>
                </Form.Item>
                <Form.Item key={2} label={t('INTERNAL.CLIENT.COMMON.NAME')} name="name" required>
                    <Input/>
                </Form.Item>
                <Form.Item key={3} label={t('INTERNAL.CLIENT.COMMON.EMAIL')} name="email" rules={[{type: 'email'}]}
                           required>
                    <Input/>
                </Form.Item>


                {/* POS to which the user has access, based on pos_access_constraints columns */}
                <h2>
                    {t('INTERNAL.CLIENT.CONTACTS.CREATE_EDIT_MODAL.POS_ACCESS')}
                    <Badge
                        style={{marginLeft: 5}}
                        count={posAccessCount}
                        showZero
                        overflowCount={999}
                    />
                </h2>

                <Alert
                    style={{margin: 10}}
                    type={"warning"}
                    message={t('INTERNAL.CLIENT.CONTACTS.CREATE_EDIT_MODAL.POS_ACCESS_INFO')}
                />


                <Row justify={"space-between"}>
                    <Col span={7}>
                        <Form.Item label={t('INTERNAL.CLIENT.COMMON.APPLE_ID')}
                                   name={["pos_access_constraints", "f_pos_apple_id"]}>
                            <NpiInputSelectNumericalTags
                                mode="tags"
                                placeholder={`${t('INTERNAL.CLIENT.COMMON.SEARCH_BY')} ${t('INTERNAL.CLIENT.COMMON.APPLE_ID')}`}
                                maxTagCount={5}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={7}>
                        <Form.Item label={t('INTERNAL.CLIENT.COMMON.HQ_ID')}
                                   name={["pos_access_constraints", "f_hq_apple_id"]}>
                            <NpiInputSelectNumericalTags
                                placeholder={`${t('INTERNAL.CLIENT.COMMON.SEARCH_BY')} ${t('INTERNAL.CLIENT.COMMON.HQ_ID')}`}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={7}>
                        <Form.Item label={t('INTERNAL.CLIENT.COMMON.NAME')} name={["pos_access_constraints", "name"]}>
                            <Input
                                placeholder={`${t('INTERNAL.CLIENT.COMMON.SEARCH_BY')} ${t('INTERNAL.CLIENT.COMMON.NAME')}`}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={7}>
                        <Form.Item label={t('INTERNAL.CLIENT.COMMON.COUNTRIES')}
                                   name={["pos_access_constraints", "country_id"]}>
                            <NpiInputSelectCountry
                                mode="multiple"
                                allowClear={true}
                                placeholder={t('INTERNAL.CLIENT.COUNTRIES.SELECT_COUNTRIES')}
                                inRegions={currentRegionId}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={7}>
                        <Form.Item label={t('INTERNAL.CLIENT.COMMON.STATUS')}
                                   name={["pos_access_constraints", "f_sfo_status_group"]}>
                            <NpiPosStatusFilterFormItem/>
                        </Form.Item>
                    </Col>

                    <Col span={7}>
                        <Form.Item label={t('INTERNAL.CLIENT.COMMON.PROGRAMS')}
                                   name={["pos_access_constraints", "f_program"]}>
                            <NpiInputSelectProgram
                                withNoProgram
                                mode="multiple"
                                allowClear={true}
                                placeholder={`${t('INTERNAL.CLIENT.COMMON.SELECT_ONE_OR_MORE')} ${t('INTERNAL.CLIENT.COMMON.PROGRAMS')}`}
                            />
                        </Form.Item>
                    </Col>

                    {!!currentRegionId &&
                        <Col span={7}>
                            <Form.Item label={t('INTERNAL.CLIENT.COMMON.PARTNERS')}
                                       name={["pos_access_constraints", "f_company_name"]}>
                                <NpiInputSelectPartner
                                    mode="multiple"
                                    allowClear={true}
                                    regionId={currentRegionId}
                                    countries={currentCountries}
                                />
                            </Form.Item>
                        </Col>
                    }
                </Row>
            </Form>
        </Modal>
    );
}

export default NpiCreateEditContactModal;
export type {INpiEditContactModalProps, INpiEditContactModalFormData};