import React from 'react';
import {useTranslation} from "react-i18next";
import {SearchOutlined} from "@ant-design/icons/lib";

/**
 * 404 Not Found external app view
 * @constructor
 */
const NpiExternalErrorNoSurvey = () => {
    const {t} = useTranslation();

    return(
        <div style={{textAlign: 'center', marginTop: '20vh'}}>
            <h1><SearchOutlined/></h1>
            <h2>{t('EXTERNAL.CLIENT.NO_SURVEY.TITLE')}</h2>
            <p>{t('EXTERNAL.CLIENT.NO_SURVEY.EXPLAIN')}</p>
        </div>
    );
}


export default NpiExternalErrorNoSurvey;