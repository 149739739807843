import React, {useContext, useEffect} from 'react';
import {NpiInternalContext} from "../../contexts/internal-context";
import {Space, Tabs} from "antd";
import NpiDisplayLayoutContent from "../../components/display/layout-content";
import NpiDashboardSyncLogs from "../../components/dashboard-synchro/logs";
import styled from "styled-components";
import NpiContainerDashboardSyncListWaveRelated from "../../components/dashboard-synchro/list-table-wave-related";
import NpiContainerDashboardSyncListOthers from "../../components/dashboard-synchro/list-others";
import {useTranslation} from "react-i18next";
import useRestrictedToAdmin from "../../hooks/use-restricted-to-admin";


const StyledSyncInfos = styled(Space)`
  position: absolute !important;
  top: 15px;
  right: 20px;
`;

const NpiContainerDashboardSync = () => {
    useRestrictedToAdmin();
	const {t} = useTranslation();

    // set breadcrumbs
    const {setBreadcrumbs} = useContext(NpiInternalContext);
    useEffect(() => setBreadcrumbs([
        {url: '/dev-dashboard', name: "Dev Dashboards"},
        {url: '/dev-dashboard/sync', name: t('INTERNAL.CLIENT.DASHBOARD.DASHBOARD_SYNCHRO')},
    ]), [setBreadcrumbs, t]);


    return <NpiDisplayLayoutContent>
        <StyledSyncInfos size={"large"}>
            <NpiDashboardSyncLogs/>
        </StyledSyncInfos>

        <Tabs items={[
            {key: "wave-related", label: t('INTERNAL.CLIENT.DASHBOARD.TAB_WAVE_RELATED'), children: <NpiContainerDashboardSyncListWaveRelated/>},
            {key: "other", label: t('INTERNAL.CLIENT.DASHBOARD.TAB_OTHERS'), children: <NpiContainerDashboardSyncListOthers/>},
        ]}/>

    </NpiDisplayLayoutContent>
};

export default NpiContainerDashboardSync;