import {ViewModeEnum} from "../types/view";
import React from "react";
import {Link} from "react-router-dom";

/**
 * Prefix provided in case of REACT_APP_VIEW_MODE === ViewModeEnum.DEV to give devs the possibility to see both (internal, external)
 */
const EXTERNAL_ROUTE_DEV_PREFIX = "/ext"

/**
 * Generate external route by adding prefix in dev mode
 * @param {string} path
 */
const generateExternalRoute = (path: string) => {
    return process.env.REACT_APP_VIEW_MODE === ViewModeEnum.DEV ? `${EXTERNAL_ROUTE_DEV_PREFIX}${path}` : path;
}

/**
 * Interface for ExternalLink props component
 */
interface IExternalLinkProps {
    to: string,
    children: React.ReactNode
}

/**
 * Link for access to external client
 * @param props
 * @constructor
 */
const ExternalLink = (props: IExternalLinkProps) => {
    const {to, children} = props;
    const path = generateExternalRoute(to);

    return <Link to={{
        pathname: process.env.REACT_APP_VIEW_MODE !== ViewModeEnum.DEV ? `${process.env.REACT_APP_EXTERNAL_CLIENT_ROOT}${path}` : path
    }} target={"_blank"}>
        {children}
    </Link>
}

export {generateExternalRoute, ExternalLink, EXTERNAL_ROUTE_DEV_PREFIX};