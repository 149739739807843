import React, {ChangeEvent, useCallback, useMemo, useState} from "react";
import {FilterDropdownProps} from "antd/lib/table/interface";
import {Button, Card, Input, InputNumber, Select, Switch, Tooltip} from "antd";
import {useControllableValue} from "ahooks";
import {ClearOutlined, FilterFilled} from "@ant-design/icons/lib";
import styled from "styled-components";
import _ from "lodash";
import {NpiInputDateRangePicker} from "./date-picker";
import NpiInputSelectTagsAndValues from "./select-tags-and-values";
import {useTranslation} from "react-i18next";

const StyledCard = styled(Card)`
    .filter{
        margin-bottom: 10px;
    }
    .ant-input{
        width: calc(100% - 88.7px);
    }
    .ant-card-body>:not(:last-child){
        margin-bottom: 10px;
    }
    .ant-select-selection-placeholder .anticon{
        display: none;
    }
`;

export interface INpiTableFilter{
    title?: any
    mode: 'text'|'select'|'number'|'date'|'tags'|'bool'|'custom'

    options?: {label: any, value: any}[]
    strict?: boolean
    json?: boolean
    input?: React.ReactElement<any>
    fieldKey?: string
    selectInString?: boolean //can be used when the select is a value in a search string with '|' as separators
    inputProps?:any
}

interface INpiInputTableFilterDropdown extends FilterDropdownProps, INpiTableFilter{
// clearFilters: ƒ onReset()
// confirm: ƒ doFilter()
// filters: undefined
// prefixCls: "ant-dropdown-custom"
// selectedKeys: []
// setSelectedKeys: ƒ setSelectedKeys(selectedKeys)
// visible: false
}

/**
 */
const NpiInputTableFilterDropdown = ({title, mode, options, filters, confirm, clearFilters, ...props}:INpiInputTableFilterDropdown) => {
    const {strict, json, selectInString, fieldKey, inputProps} = props;
    const {t} = useTranslation();

    //The Column property returns us the following props which we can use to have a controllable value
    const [filter, setFilter] = useControllableValue(props, {
        valuePropName: 'selectedKeys',
        trigger: 'setSelectedKeys',
    });

    //Default configuration
    const [configuration, setConfiguration] = useState(filter[1] ?? {
        strict,
        json,
        tags: mode === 'tags',
        number: mode === 'number',
        date: mode === 'date',
        bool: mode === 'bool',
        selectInString,
        fieldKey,
    });

    const logics = useMemo(() => {
        return {
            text: [
                {label: t("INTERNAL.CLIENT.FILTERS.CONTAINS"), value:"LIKE"},
                {label: t("INTERNAL.CLIENT.FILTERS.EQUALS"), value:"EQUAL"},
                {label: t("INTERNAL.CLIENT.FILTERS.STARTS_WITH"), value:"START_WITH"},
                {label: t("INTERNAL.CLIENT.FILTERS.END_WITH"), value:"END_WITH"},
            ],
            number: [
                {label: t("INTERNAL.CLIENT.FILTERS.EQUALS"), value:"EQUAL"},
                {label: t("INTERNAL.CLIENT.FILTERS.GREATER_THAN"), value:"GREATER"},
                {label: t("INTERNAL.CLIENT.FILTERS.LESS_THAN"), value:"LESSER"},
                {label: t("INTERNAL.CLIENT.FILTERS.BETWEEN"), value:"BETWEEN"},
            ],
        }
    }, [t]);

    //Confirm changes and launch filtering
    const onConfirm = useCallback(() => {
        confirm();
    }, [confirm]);

    //On changing value, update
    const onChangeValue = useCallback((value:any, apply=false) => {
        const newFilter = [value, configuration];
        setFilter(newFilter);
        if(apply){onConfirm()}
    }, [configuration, setFilter, onConfirm]);

    //On changing number, update
    const onChangeNumber = useCallback((value:number, position:number) => {
        const newFilter = filter[0] ? [...filter[0]]:[];
        newFilter[position] = value;
        setFilter([newFilter, configuration]);
    }, [filter, configuration, setFilter]);

    //Observe change to additional configuration
    const onChangeConfiguration = useCallback((value: any) => {
        const newConfig = {...configuration, ...value};
        setConfiguration(newConfig);
        setFilter([filter[0], newConfig]);
    }, [filter, configuration, setFilter]);

    //Clear filters
    const onClearFilters = () => {
        clearFilters?.();
        onConfirm();
    };

    //Build our input depending on the mode selected
    const input = useMemo(() => {
        if(mode === 'select' && options?.length){
            return <Select mode="multiple" value={filter[0]} onChange={v => onChangeValue(v)} options={options} style={{width: 200}} dropdownMatchSelectWidth={false} placeholder={<>{t("INTERNAL.CLIENT.COMMON.SELECT_TITLE", {title: title})}</>} {...inputProps}/>
        }
        else if(mode === 'text'){
            return <Input value={filter[0]} onChange={(e:ChangeEvent<HTMLInputElement>) => onChangeValue(e.target.value)} onPressEnter={(e:any)=> onChangeValue(e.target.value, true)} placeholder={t("INTERNAL.CLIENT.COMMON.SEARCH_BY_TITLE", {title: title})} {...inputProps}/>
        }
        else if(mode === 'number'){
            return <>
                <InputNumber value={filter[0] ? filter[0][0] : null} onChange={(v:number) => onChangeNumber(v, 0)} {...inputProps}/>
                {configuration.logic === 'BETWEEN' && <InputNumber value={filter[0] ? filter[0][1] : null} onChange={(v:number) => onChangeNumber(v, 1)} {...inputProps}/>}
            </>
        }
        else if(mode === 'date'){
            return <>
                <NpiInputDateRangePicker value={filter[0]} onChange={onChangeValue} {...inputProps}/>
            </>
        }
        else if(mode === 'tags'){
            return  <NpiInputSelectTagsAndValues value={filter[0]} onChange={v => onChangeValue(v)} style={{minWidth: 200, maxWidth: 350}} dropdownMatchSelectWidth={false} {...inputProps}/>
        }
        else if(mode === 'bool'){
            return <div style={{marginRight: '10px', verticalAlign: 'middle'}}>
                <Switch checked={filter[0]} onChange={v => onChangeValue(v)} {...inputProps}/>
            </div>
        }
        else if(mode === 'custom' && props.input){
            return React.cloneElement(props.input, {
                value: filter[0],
                onChange: (v:any) => onChangeValue(v),
            });
        }
    }, [filter, configuration.logic, onChangeNumber, onChangeValue, mode, options, title, props.input, inputProps, t]);

    //More configuration if necessary
    const more = useMemo(() => {
        if(! strict && _.has(logics, mode)){
            // @ts-ignore
            return <Select options={logics[mode]} style={{width: '100%'}} defaultValue={logics[mode][0].value} onChange={logic => onChangeConfiguration({logic})}/>
        }
    }, [mode, strict, onChangeConfiguration, logics]);

    return <StyledCard title={<span>{t("INTERNAL.CLIENT.COMMON.FILTER_BY_TITLE", {title: title})}</span>} size="small" extra={<Tooltip title={t("INTERNAL.CLIENT.COMMON.CLEAR_FILTER")}><Button type="link" icon={<ClearOutlined/>} onClick={onClearFilters}/></Tooltip>}>
        {more}
        <Input.Group compact>
            {input}
            <Button type="primary" onClick={onConfirm} icon={<FilterFilled/>} disabled={filter[0] === undefined || filter[0] === null || filter[0] === ''}>{t("COMMON.APPLY")}</Button>
        </Input.Group>
    </StyledCard>
};

export default NpiInputTableFilterDropdown;