import React, {ReactNode, useEffect, useState} from "react";
import NpiDisplayLayoutContent from "./layout-content";
import colors from "../../services/colors";
import {generatePath, RouteComponentProps, useParams, withRouter} from "react-router";
import {Steps} from "antd";
import _ from "lodash";

interface ITabRouterConfig {
    label: string
    component: ReactNode
    url: string
}

interface INpiDisplayRouter extends RouteComponentProps {
    tabs:ITabRouterConfig[]
}

/**
 * This component will display tabs, and change the :step param in the route to match the selected tab
 * On load, it will select the correct tab based on the url
 */
const NpiDisplayTabRouter = withRouter(({match, history, tabs}:INpiDisplayRouter) => {
    const [step, setStep] = useState<number>(0);
    const params:any = useParams();

    //Update step & url when moving around
    const onChangeStep = (step:number) => {
        const key = tabs[step].url;
        const path = generatePath(match.path, {...params, step: key});
        history.push(path);
        setStep(step);
    };

    useEffect(() => {
        if(params.step){
            const found = _.find(tabs, {url: params.step});
            if(found){
                onChangeStep(tabs.indexOf(found));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.step]);

    return <>
        <Steps type="navigation" style={{background: '#FFF'}} onChange={onChangeStep} current={step}>
            {tabs.map(tab => <Steps.Step key={tab.url} title={tab.label}/>)}
        </Steps>
        <NpiDisplayLayoutContent style={{backgroundColor: colors.greySuperLightBackground}}>
            {tabs.map((tab, key) => <span key={tab.url}>
                {key === step && tab.component}
            </span>)}
        </NpiDisplayLayoutContent>
    </>
});

export default NpiDisplayTabRouter;