import React, {useState} from "react";
import {Tree, TreeProps} from "antd";
import type { DataNode } from 'antd/es/tree';
import {useControllableValue, useRequest} from "ahooks";
import npiApi from "../../services/api";
import {parseInt} from "lodash";

interface INpiInputWaveTree extends TreeProps {
    value?: number[],
    onChange?: (value: number[]) => void,
    filters?: any,
}

const NpiInputWaveTree = ({filters, ...props}: INpiInputWaveTree) => {
    const {data, loading} = useRequest(npiApi.internal.wave.getWavesAsTree, {
        defaultParams: [filters],
        onSuccess: () => setExpandedKeys([])
    });

    const [value, setValue] = useControllableValue<number[]>(props,{
        defaultValue: []
    });

    const [autoExpandParent, setAutoExpandParent] = useState<boolean>(true);
    const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
    const onExpand = (expandedKeysValue: React.Key[]) => {
        setExpandedKeys(expandedKeysValue);
        setAutoExpandParent(false);
    };

    return <Tree checkable
              disabled={loading}
              selectable={false}
              autoExpandParent={autoExpandParent}
              onExpand={onExpand}
              expandedKeys={expandedKeys}
              onCheck={(x) => setValue((x as React.Key[]).filter(key => !key.toString().startsWith('npi_')).map(key => parseInt(key.toString())))}
              checkedKeys={value}
              treeData={data as DataNode[]}>
            ...props
        </Tree>

}

export default NpiInputWaveTree;