import React, {Dispatch, useEffect, useMemo, useState} from "react";
import {Image, Modal, notification, Skeleton, Spin, Upload} from "antd";
import {IFormAnswer} from "../../types/question";
import {InboxOutlined, LoadingOutlined} from "@ant-design/icons";
import {useControllableValue, useDebounceEffect} from "ahooks";
import {UploadProps} from "antd/lib/upload/Upload";
import {UploadFile} from "antd/lib/upload/interface";
import {RcFile} from "antd/lib/upload";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {IWavePosImage} from "../../types/wave-pos-image";
import npiApi, {dowloadFromBlob} from "../../services/api";
import NpiModal from "../display/modal-function";
const { Dragger } = Upload;
const infiniteSign = "\u221E";


interface INpiInputImageUploaderProps{
    value?: number,
    onChange?: Dispatch<IFormAnswer>,
    images?: INpiUploadImage[],
    onImagesChange?: Dispatch<INpiUploadImage[]>,
    disabled?: boolean,
    loading?: boolean,
    isMandatory?: boolean,
    max ?: number,
    mimes?: string[],
    onRemove?: false|Dispatch<INpiUploadImage>,
    onPreview?: false|Dispatch<INpiUploadImage>,
    onDownload?: false|Dispatch<INpiUploadImage>,
    actionIcons?: {
        removeIcon?: React.ReactNode | ((file: UploadFile) => React.ReactNode);
        downloadIcon?: React.ReactNode | ((file: UploadFile) => React.ReactNode);
        previewIcon?: React.ReactNode | ((file: UploadFile) => React.ReactNode);
    },
}

export interface INpiUploadImage extends UploadFile {
    wpiId?: number,
    loading?: boolean,
    wpi_thumbnail_route?: string,
    wpi_image_route?: string,
    wpi_mime?: string,
}

export const convertWpiToUploadImage = (wpi: IWavePosImage): INpiUploadImage => {
    return {
        loading: true,
        status: "uploading",
        wpiId: wpi.id,
        uid: `${wpi.id}`,
        name: wpi.original_name,
        wpi_thumbnail_route: wpi.thumbnail_route,
        wpi_image_route: wpi.image_route,
        wpi_mime: wpi.mime,
    };
}
// Load the file in base64
export const loadThumb = async (im: INpiUploadImage): Promise<INpiUploadImage> => {
    if( !im.thumbUrl && !!im.wpi_thumbnail_route ){
        try {
            const b64Thumb = await npiApi.external.image.getImageBase64FromRoute(im.wpi_thumbnail_route);
            return {...im, loading: false, status: "done", thumbUrl: `data:${im.wpi_mime};base64,${b64Thumb}`};
        } catch(error) {
            return {...im, loading: false, status: "error"};
        }
    }
    return im;
}
// Load the file in base64
export const loadLarge = async (im: INpiUploadImage): Promise<INpiUploadImage> => {
    if( !im.url && !!im.wpi_image_route ){
        try {
            const b64 = await npiApi.external.image.getImageBase64FromRoute(im.wpi_image_route);
            return {...im, loading: false, status: "done", url: `data:${im.wpi_mime};base64,${b64}`};
        } catch(error) {
            return {...im, loading: false, status: "error"};
        }
    }
    return im;
}
// load a list of thumbs
export const loadListThumbs = async (toLoad: INpiUploadImage[]): Promise<INpiUploadImage[]> => {
    return await Promise.all(toLoad.map(loadThumb))
}

/**
 * Image uploader display
 */
const NpiInputImageUploader = ({disabled, loading, isMandatory, max,...props}:INpiInputImageUploaderProps) => {
    const {t} = useTranslation();

    //handle actions
    const {onRemove, onPreview, onDownload, actionIcons} = props;

    // allowed formats
    const {mimes=['image/jpeg', 'image/png']} = props;
    const mimesStr = useMemo(() => mimes.join(', '), [mimes])

    // value is the number of files uploaded
    const [, setState] = useControllableValue<number|null>(props, {defaultValue: 0});

    // images is the list of files uploaded
    const [images, setImages] = useControllableValue<INpiUploadImage[]>(props, {
        valuePropName: 'images',
        trigger: 'onImagesChange',
        defaultValue: [],
    });

    // use to debounce the upload
    const [fileList, setFileList] = useState<INpiUploadImage[]>(images ?? []);

    // status of the uploader
    const countImages = useMemo<number>(() => images?.length ?? 0, [images]);
    const isMandatoryOk = useMemo<boolean>(() => !isMandatory || countImages > 0, [isMandatory, countImages]);
    const isLimitOk = useMemo<boolean>(() => !max || countImages <= max, [max, countImages]);
    const statusOk = useMemo<boolean>(() => isLimitOk && isMandatoryOk, [isLimitOk, isMandatoryOk]);


    // debounce to avoid multiple call to onChange callback when multiple files are uploaded at once
    // fileList is used as a temporary list, to allow this delay
    useDebounceEffect(() => {
        setState(fileList.length > 0 ? fileList.length : null); // set null for mandatory validation
        setImages(fileList);
    }, [fileList], {wait: 200});


    // when reset from external
    useEffect(() => {
        // if there are images to load
        if( (images ?? []).filter(im => !im.thumbUrl && im.status !== "error").length > 0 ){
            loadListThumbs(images).then(loaded => {
                setFileList(loaded)
            })
        } else {
            setFileList(images ?? []);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [images])


    const beforeUpload = (file:UploadFile, listUpload:UploadFile[]) => {

        // we stop uploaded when MAX count is reached
        if ( !!max && (countImages + listUpload.length) > max ) {
            const idx = listUpload.indexOf(file) + 1;
            const maxIdx = max - countImages;
            if( idx > maxIdx ){
                notification.warn({message: t('EXTERNAL.CLIENT.POS_VIEW.IMAGE_UPLOAD.ERROR_EXCEED_LIMIT')});

                return Upload.LIST_IGNORE;
            }
        }

        // allow only an allowed format
        const isFormatAllowed = !!file.type && mimes.includes(file.type);
        if (!isFormatAllowed) {
            notification.warn({message: t('EXTERNAL.CLIENT.POS_VIEW.IMAGE_UPLOAD.FORMAT_ALLOWED')});
            return Upload.LIST_IGNORE;
        }

        // add url & thumbUrl to have a preview of the uploaded file before the real server upload
        const reader = new FileReader();
        reader.readAsDataURL(file as RcFile);
        reader.onload = e => {
            if( e.target ){
                file.url = e.target.result as string;
                file.thumbUrl = e.target.result as string;
                images.push(file);
                setFileList([...images])
            }
        };

        // we don't want to upload file to server directly
        return false;
    }


    // handle actions (custom or default) on thumbs
    const handleAction = (file:UploadFile, action: false|Dispatch<INpiUploadImage>) => {
        const im = images.find((f) => f.uid === file.uid);
        if( action === false || !im ) return;
        action(im);
    }
    const handleRemove = (file:UploadFile) => {
        handleAction(file, onRemove ?? ((im: INpiUploadImage) => {
            Modal.confirm({
                title: t('EXTERNAL.CLIENT.POS_VIEW.IMAGE_UPLOAD.REMOVE_IMAGE'),
                content: <div style={{width: 'calc(100% - 38px)', textAlign: "center"}}>
                    <Image src={im.thumbUrl} height={120} preview={false}/>
                </div>,
                okText: t('INTERNAL.CLIENT.COMMON.YES'),
                cancelText: t('INTERNAL.CLIENT.COMMON.NO'),
                onOk: () => setFileList(images.filter((f) => f.uid !== im.uid)),
            });
        }));
    }
    const handlePreview = async (file:UploadFile) => {
        handleAction(file, onPreview ?? ((im: INpiUploadImage) => {
            const {update} = NpiModal.create(
                <Spin/>,
                {footer: null}
            );
            loadLarge(im).then(loaded => {
                update(prevConfig => ({
                    ...prevConfig,
                    children: <div style={{width: '100%', textAlign: "center"}}><Image src={loaded.url} preview={false}/></div>,
                }))
            })
        }));
    }
    const handleDownload = async (file:UploadFile) => {
        handleAction(file, onDownload ?? ((im: INpiUploadImage) => {
            loadLarge(im).then(loaded => {
                if( !!loaded.url ) {
                    fetch(loaded.url).then(res => {
                        res.blob().then(blob => {
                            return dowloadFromBlob(blob, im.name, true);
                        })
                    })
                }
            })
        }));
    }

    const draggerProps:UploadProps = {
        name: 'file',
        multiple: true,
        accept: mimesStr,
        disabled: disabled || !isLimitOk || loading,
        fileList: images,
        listType: 'picture-card',

        onRemove: handleRemove,
        onPreview: handlePreview,
        onDownload: handleDownload,
        showUploadList: {
            ...actionIcons,
            showPreviewIcon: onPreview !== false,
            showRemoveIcon: onRemove !== false,
            showDownloadIcon: onDownload !== false,
        },
        beforeUpload: beforeUpload,
        itemRender: (originNode:any, file: INpiUploadImage, currFileList:any) => (file.loading ? <StyledSkeletonImage/> : originNode)
    };

    return <StyledDraggerContainer>
        <Dragger {...draggerProps}>
            <p className="ant-upload-drag-icon">
                <InboxOutlined />
            </p>

            <p className="ant-upload-text">{t('EXTERNAL.CLIENT.POS_VIEW.IMAGE_UPLOAD.DRAG_MSG')}</p>
            <p className="ant-upload-hint">{t('EXTERNAL.CLIENT.POS_VIEW.IMAGE_UPLOAD.BULK_MSG')}</p>
            <p style={{marginTop: "20px", color: !statusOk ? "red" : (countImages > 0 ? "darkgreen" : "inherit")}}>
                {loading && <LoadingOutlined style={{marginRight: 5}}/>}
                {t('EXTERNAL.CLIENT.POS_VIEW.IMAGE_UPLOAD.UPLOADED_IMAGES')} : {countImages} / {!!max ? max : infiniteSign}
            </p>
        </Dragger>
    </StyledDraggerContainer>;
}

const StyledDraggerContainer = styled.div`
  .ant-upload.ant-upload-drag {
    margin-bottom: 12px;
  }
  .ant-upload-list.ant-upload-list-picture-card {
    margin-bottom: -12px;
    .ant-upload-list-picture-card-container {
      margin: 0 12px 12px 0 !important;
    }
    .ant-upload-list-item-actions {
        .anticon {
            z-index: 10;
            width: 16px;
            margin: 0 4px;
            color: rgba(255, 255, 255, 0.85);
            font-size: 16px;
            cursor: pointer;
            transition: all 0.3s;
        }
    }
  }
`;



const StyledSkeletonImage = styled(Skeleton.Image)`
    background: linear-gradient(90deg,rgba(190,190,190,.2) 25%,rgba(129,129,129,.24) 37%,rgba(190,190,190,.2) 63%);
    background-size: 400% 100%;
    animation: ant-skeleton-loading 1.4s ease infinite;
`;

export default NpiInputImageUploader;