import React, {Dispatch} from 'react';
import {ITranslations} from "../../types/wave";
import {observer} from 'mobx-react';
import {Button, Upload, notification, Dropdown, Menu} from "antd";
import {DownloadOutlined, UploadOutlined} from '@ant-design/icons';
import npiApi, {npiApiDownloadFileCallback} from "../../services/api";
import {useControllableValue} from "ahooks";
import {generateTerms} from "../../helpers/translation";
import {IWithQuestions} from "../../types/question";
import FileTable from '@2fd/ant-design-icons/lib/FileTable';
import {useTranslation} from "react-i18next";
import {nowFormat} from "../../helpers/date";

interface ITranslationPayload {
    terms: any[]
    languages: number[]
    translations: ITranslations
}

interface INpiWaveTabFormTranslations {
    item: IWithQuestions
    translationIds: number[]
    translations: ITranslations
    onChange: Dispatch<ITranslations>
    fileImport: (params:any) => Promise<ITranslations>
}

const NpiInputTranslationsUploader = observer(({item, translationIds, fileImport, ...props}:INpiWaveTabFormTranslations) => {
    const {t} = useTranslation();
    const [translations, setTranslations] = useControllableValue<ITranslations>(props, {
        valuePropName: 'translations',
    });

    const handleUpload = (file :any) => {
        if (window.confirm(t('INTERNAL.CLIENT.TRANSLATION.FILE_OVERRIDE_CURRENT_TRANSLATION')))
        {
            fileImport({id: item.id, file: file})
                .then((translations) => {
                    notification.success({message: t('INTERNAL.CLIENT.TRANSLATION.UPLOADED_SUCCESSFULLY', {fileName: file.name})});
                    setTranslations(translations);
                })
        }
    };

    const FileUploadProps = {
        name: 'file',
        beforeUpload : (file: any) => {
            handleUpload(file);
            // -- block the default upload to handle it ourselves
            return false;
        },
        progress: {
            strokeColor: {
                '0%': '#108ee9',
                '100%': '#87d068',
            },
            strokeWidth: 3,
            format: (percent : any) => `${parseFloat(percent.toFixed(2))}%`,
        },
    };

    const onClickDownload = () => {
        let payload : ITranslationPayload = {
            terms: generateTerms(item),
            languages: translationIds,
            translations,
        };

        npiApi
            .internal
            .file
            .exportTranslations(payload)
            .then(npiApiDownloadFileCallback(`template-translations-${nowFormat()}.xlsx`))
    };

return <Dropdown overlay={<Menu items={[
    {key: '1', label: <div onClick={onClickDownload}><DownloadOutlined/> {t('INTERNAL.CLIENT.TRANSLATION.DOWNLOAD_TRANSLATIONS')}</div>},
    {key: '2', label: <Upload showUploadList={false} {...FileUploadProps} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
        <UploadOutlined /> {t('INTERNAL.CLIENT.TRANSLATION.UPLOAD_TRANSLATIONS')}
    </Upload>},
]}/>} placement="bottomLeft">
        <Button><FileTable /> {t('INTERNAL.CLIENT.COMMON.TEMPLATE')}</Button>
    </Dropdown>
});

export default NpiInputTranslationsUploader;