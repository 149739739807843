import {Layout, Spin} from "antd";
import NpiDisplayMenu from "./components/display/menu";
import NpiBreadcrumb from "./components/breadcrumb";
import {Redirect, Route, Switch} from "react-router-dom";
import NpiContainerWaveConfig from "./containers/wave-config";
import NpiContainerNpiConfig from "./containers/npi-config";
import NpiContainerWaveList from "./containers/wave-list";
import NpiContainerNpiList from "./containers/npi-list";
import NpiContainerKpiDashboard from "./containers/kpi-dashboard";
import NpiContainerDashboardSync from "./containers/dev-dashboard/dashboard-synchro";
import NpiContainerDashboardSyncWaveDetails from "./components/dashboard-synchro/wave-details";
import RegionProtectedRoute from "./components/route/RegionProtectedRoute";
import NpiContainerDashboardShipment from "./containers/dashboard-shipment";
import NpiContainerAdminUserList from "./containers/admin/user-list";
import React from "react";
import {StoresContext} from "./contexts/stores";
import {WaveStore} from "./stores/wave";
import {NpiStore} from "./stores/npi";
import NpiContainerWaveTableReport from "./containers/wave-table-report";
import NpiContainerGallery from "./containers/gallery";
import NpiBatteryChartContainer from "./containers/battery-chart";
import NpiContainerAdminContactList from "./containers/admin/contact-list";
import NpiContainerAdminCountryList from "./containers/admin/country-list";
import {WavePosStore} from "./stores/wavepos";
import NpiContainerAdminTableauConnect from "./containers/admin/tableau-connect";
import {KpiDashboardStore} from "./stores/kpi-dashboard";
import NpiContainerAdminUserPreferences from "./containers/admin/user-preferences";
import NpiContainerAdminTags from "./containers/admin/tags";
import NpiContainerAdminPosList from "./containers/admin/pos-list";
import NpiContainerWwDashboard from "./containers/ww-dashboard";
import NpiContainerWwDashboardList from "./containers/ww-dashboard-list";
import i18n from "i18next";
import NpiContainerDevDashboard from "./containers/dev-dashboard/dev-dashboard";
import NpiContainerDashboardTranslation from "./containers/dev-dashboard/dashboard-translation";

const wavePosStore = new WavePosStore();
const waveStore = new WaveStore();
const npiStore = new NpiStore();
const kpiDashboardStore = new KpiDashboardStore();

const NpiInternalApp = () => {

    // Wait initialization of i18n lib
    if(!i18n.isInitialized){
        return <div style={{textAlign: 'center', padding: '30px 50px'}}>
            <Spin tip={<>Loading NPI interface...</>}/>
        </div>
    }

    return (
        <StoresContext.Provider value={{wavePosStore, waveStore, npiStore, kpiDashboardStore}}>
                <Layout id="npi-app" className="layout">
                    <Layout.Header style={{padding: 0}}>
                        <NpiDisplayMenu/>
                    </Layout.Header>
                    <Layout.Content>
                        <NpiBreadcrumb/>
                        <Switch>
                            <Route path="/npi/:npiId/wave/:waveId/config/:step?" component={NpiContainerWaveConfig}/>
                            <Route path="/wave/:waveId/config/:step?" component={NpiContainerWaveConfig}/>

                            <Route path="/npi/:npiId/wave/:waveId/report/:filters?" component={NpiContainerWaveTableReport}/>
                            <Route path="/wave/:waveId/report/:filters?" component={NpiContainerWaveTableReport}/>

                            <Route path="/npi/:npiId/config/:step?" component={NpiContainerNpiConfig}/>
                            <Route path="/npi/:npiId" component={NpiContainerWaveList}/>
                            <Route path="/npi" component={NpiContainerNpiList}/>
                            <Route path="/kpi-dashboard/wave/:waveId/:mode?" component={NpiContainerKpiDashboard}/>
                            <Route path="/kpi-dashboard/npi/:npiId/:mode?" component={NpiContainerKpiDashboard}/>
                            <Route exact path="/ww-dashboard" component={NpiContainerWwDashboardList}/>
                            <Route path="/ww-dashboard/:dashboardId" component={NpiContainerWwDashboard}/>
                            <Route path="/battery-chart/:npiId" component={NpiBatteryChartContainer}/>

                            <Route path="/dev-dashboard/sync/wave/:waveId" component={NpiContainerDashboardSyncWaveDetails}/>
                            <Route path="/dev-dashboard/sync" component={NpiContainerDashboardSync}/>
                            <Route path="/dev-dashboard/translation" component={NpiContainerDashboardTranslation}/>
                            <Route path="/dev-dashboard" component={NpiContainerDevDashboard}/>

                            <Route path="/gallery/npi/:npiId" component={NpiContainerGallery}/>
                            <Route path="/gallery/wave/:waveId/:posId?" component={NpiContainerGallery}/>
                            <Route path="/gallery" component={NpiContainerGallery}/>

                            <RegionProtectedRoute regionPermissions="has_shipment_access" path="/shipments" component={NpiContainerDashboardShipment}/>
                            <Route path="/admin/preferences" component={NpiContainerAdminUserPreferences}/>
                            <Route path="/admin/users" component={NpiContainerAdminUserList}/>
                            <Route path="/admin/countries" component={NpiContainerAdminCountryList}/>
                            <Route path="/admin/contacts" component={NpiContainerAdminContactList}/>
                            <Route path="/admin/tableau" component={NpiContainerAdminTableauConnect}/>
                            <RegionProtectedRoute regionPermissions="has_tags_access" path="/admin/tags" component={NpiContainerAdminTags}/>
                            <Route path="/admin/pos" component={NpiContainerAdminPosList}/>

                            <Route exact path="/"><Redirect to="/npi"/></Route>
                            <Route path="*"><Redirect to="/npi"/></Route>

                            {/*<Route path="*" element={<Navigate to="/404" />} />*/}
                        </Switch>
                    </Layout.Content>
                </Layout>
        </StoresContext.Provider>

    );
}

export default NpiInternalApp;
