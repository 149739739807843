import React, {Dispatch, useContext, useEffect, useMemo, useState} from "react";
import {IExternalWavePos} from "../types/wave-pos";
import {IWave} from "../types/wave";
import {useNpiExternalContext} from "./external-context";
import npiApi from "../services/api";
import {useParams} from "react-router";
import {INpiExternalContainerPosViewRouteParams} from "../types/external-token";
import {IExternalPos} from "../types/pos";

// -- getPosDetail HTTP web response interface
export interface IExternalPosViewDetailResponse {
    pos?: IExternalPos,
    wavePosMappedByWaveId: IWavePosMappedByWaveId
}

// -- List of WavePos mapped by WaveId
export interface IWavePosMappedByWaveId {
    [wId: number]: IExternalWavePos
}

// -- Context interface
interface INpiExternalPosContext {
    // data retrieve from server
    pos?: IExternalPos,
    wavePosMappedByWaveId: IWavePosMappedByWaveId
    posId: number // trick to avoid using "pos!.pos_id" everytime when we know POS is set for sure.

    // compute data for POS
    waves: IWave[]
    imagesAllowed: boolean
    shipmentsAllowed: boolean

    // global status for POS
    loading: boolean
    error?: string

    // setters
    setNewData: Dispatch<IExternalPosViewDetailResponse>
    setWavePosMappedByWaveId: Dispatch<IWavePosMappedByWaveId>
}

const NpiExternalPosContext = React.createContext<INpiExternalPosContext>({
    posId: -1,
    wavePosMappedByWaveId: {},
    waves: [],
    imagesAllowed: false,
    shipmentsAllowed: false,
    loading: true,

    setNewData: () => {},
    setWavePosMappedByWaveId: () => {},
});

const NpiExternalPosContextProvider = ({children}: any) => {
    const {posId: paramPosId} = useParams<INpiExternalContainerPosViewRouteParams>();
    const {waves: allTokenWaves, paginatedPosList, setPaginatedPosList} = useNpiExternalContext();

    const [pos, setPos] = useState<IExternalPos>();
    const [wavePosMappedByWaveId, setWavePosMappedByWaveId] = useState<IWavePosMappedByWaveId>({});

    // re-set fresh data
    const setNewData = (data: IExternalPosViewDetailResponse) => {
        setPos(data.pos);
        setWavePosMappedByWaveId(data.wavePosMappedByWaveId);

        // update the POS status in the paginatedPosList (if token allows POS view)
        if (!!data.pos && !!paginatedPosList?.list?.length) {
            let posToUpdate = paginatedPosList.list.find(p => p.pos_id === data.pos!.pos_id);
            if (!!posToUpdate) {
                posToUpdate.status = data.pos.status;
                setPaginatedPosList({...paginatedPosList, list: [...paginatedPosList.list]});
            }
        }
    }

    const posId = useMemo(() => pos?.pos_id ?? -1, [pos]);

    // display only waves for the current POS
    const waves = useMemo(() => {
        return allTokenWaves.filter(w => Object.keys(wavePosMappedByWaveId).includes(w.id+""))
    }, [wavePosMappedByWaveId, allTokenWaves])

    // information on current POS
    const imagesAllowed = useMemo(() => !!waves.find(wave => wave.is_images_allowed), [waves]);
    const shipmentsAllowed = useMemo(() => !!waves.find(wave =>
        wave.shipment_code_id && (wavePosMappedByWaveId?.[wave.id]?.shipments || []).length > 0
    ), [waves, wavePosMappedByWaveId]);

    // POS loading status
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>();



    // -- Fetch POS detail at component init
    useEffect(() => {
        if(!!paramPosId) {
            npiApi.external.getPosDetail(parseInt(paramPosId))
                .then(setNewData)
                .catch((e:any) => {
                    setError(e?.data?.message)
                })
                .finally(() => setLoading(false))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paramPosId])



    // Create the context value with all the accessible properties
    const contextValue: INpiExternalPosContext = {
        pos,
        posId,
        wavePosMappedByWaveId,
        waves,
        imagesAllowed,
        shipmentsAllowed,
        error,
        loading,
        setNewData,
        setWavePosMappedByWaveId,
    };

    return <NpiExternalPosContext.Provider value={contextValue}>{children}</NpiExternalPosContext.Provider>
};

// Create a custom hook to easily access the NpiExternalPosContext
const useNpiExternalPosContext = (): INpiExternalPosContext => useContext(NpiExternalPosContext);

export {NpiExternalPosContextProvider, NpiExternalPosContext, useNpiExternalPosContext};