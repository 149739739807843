import React, {useContext, useEffect, useMemo, useState} from "react";
import {NpiInternalContext} from "../../contexts/internal-context";
import {Select} from "antd";
import {SelectProps} from "antd/lib/select";
import {cloneDeep} from 'lodash';
import {useTranslation} from "react-i18next";

interface ISelectProgramProps extends SelectProps<any> {
    inRegions?: number[];
    inList?: string[];
    allowAll?: boolean;
    withNoProgram?: boolean;
}

/**
 * Select between a list of POS program
 */
const NpiInputSelectProgram = ({value, inList, withNoProgram, onChange, placeholder, style, ...props}:ISelectProgramProps) => {

    const {programs, loaded} = useContext(NpiInternalContext);
    const [options, setOptions] = useState<any[]>([]);
    const {t} = useTranslation();

    const filteredPrograms = useMemo(() => {
        let result = cloneDeep(programs);
        if(inList && inList.length){
            result = programs.filter(p => inList.includes(p.value))
        }
        if(withNoProgram){
            result.push({label: <i>{t("INTERNAL.CLIENT.COMMON.NO_PROGRAM")}</i> as any, value: null as any})
        }

        return result;
    }, [programs, withNoProgram, inList, t]);

    const [state, setState] = useState<any>(value);
    useEffect(() => {
        if( value !== undefined ) {
            setState(value);
        }
    }, [value]);

    useEffect(() => {
        setOptions(filteredPrograms)
    }, [filteredPrograms]);

    return <Select {...props} loading={!loaded} options={options} value={state} onChange={(newValue, option) => {
        setState(newValue);
        onChange?.(newValue, option);
    }} style={{minWidth: 150, ...style}} placeholder={placeholder || t("INTERNAL.CLIENT.COMMON.SELECT_PROGRAM", {count: (props.mode === "multiple" ? 2 : 1)})}/>
}

export default NpiInputSelectProgram;