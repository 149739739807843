import { notification, Modal } from 'antd';
import _ from 'lodash';
import DOMPurify from 'dompurify';
import ReactHtmlParser from 'react-html-parser';
import Tools from '@2fd/ant-design-icons/lib/Tools';
import { Cancel } from 'axios';

const axiosClient = require('axios');

const axios = axiosClient.create({
    baseURL: process.env.REACT_APP_SERVER_ROOT,
    mode: 'no-cors',
    withCredentials: true,
    headers:{
        // accept: 'application/ld+json',
    }
})

/**
 * Intercept axios errors to notify everything
 */
axios.interceptors.response.use(response => {
    if(response.config.rawResponse) return response;
    else return response.data;
}, function (error) {

    if(error instanceof Cancel){
        //Canceled by token do not do anything
    }
    else if( ! error.response){
        notification.error({message: "Undefined error", duration: 15});
        console.error("AXIOS RESPONSE UNDEFINED ERROR", error);
    }
    else if ( error.request.responseType === 'blob' && error.response.data instanceof Blob && error.response.data.type ) {
        error.response.data.text()
            .then(text => {
                const blobError = JSON.parse(text);
                const {status=500, message = 'Unknown Blob Error'} = blobError;
                const messageElement = ReactHtmlParser(DOMPurify.sanitize(message + ' (' + status +')'));
                notification.error({message: messageElement, duration: 15});
            })
            .catch(() => notification.error({message: 'Unknown Blob Error', duration: 15}));
    }
    else{
        const {status=500, data: {message=null, errors}, statusText = "Unknown Error"} = error.response;
        const devMode = process.env?.REACT_APP_DEV_MODE;
        console.error("AXIOS RESPONSE RESPONSE ERROR", error.response);
        const parseMessage = message ? message : statusText;
        const messageElement = ReactHtmlParser(DOMPurify.sanitize(parseMessage));
        //log the error
        if(status === 400){
            notification.warning({message: messageElement, duration: 15})
        }
        else if(status === 422 && errors){
            notification.warning({message: <ul>
                 {_.chain(errors).toArray().flatten().map((text, key) => <li key={key}>{text}</li>).value()}
            </ul>, duration: 15})
        }
        else if(status === 401 || (status === 419 && parseMessage === 'CSRF token mismatch.')){
            //detect if request is on external api
            const isExternalRequest = error.response.config.url.startsWith("external/");

            //Unauthenticated: redirect to login page
            if(!isExternalRequest){
                window.location = process.env.REACT_APP_SERVER_ROOT + '/internal/login?intended=' + window.location.href;
            }
            else{
                notification.error({message: messageElement, duration: 15});
            }
        }
        else if(status === 503){
            notification.destroy(); //avoid multiple time the same notification

            Modal.destroyAll();
            Modal.info({
                closable: false,
                icon: <Tools/>,
                title: 'Maintenance',
                okButtonProps: {style:{display: 'none'}},
                content: <div>
                    The NPI tool is in maintenance, please check the #proj-global-npi-tracker Slack channel for latest information.
                </div>
            });

            //Reload page after 60s
            setTimeout(function(){
                window.location.reload();
            }, 60000);
        }
        else if(status >= 400){
            notification.error({message: devMode ? <div>{messageElement} ({status})</div> : messageElement, duration: 15});
        }
    }
    return Promise.reject(error.response ? error.response:{});
});

//Loading indicator
axios.interceptors.request.use(
    (request) => {
        // InterfaceStore.loadingRequests++;
        return request;
        },
    (error) => {
        // InterfaceStore.loadingRequests--;
        return Promise.reject(error);
    }
)
axios.interceptors.response.use(
    (response) => {
        // InterfaceStore.loadingRequests--;
        return response;
    },
    (error) => {
        // InterfaceStore.loadingRequests--;
        return Promise.reject(error);
    }
)

export default axios;