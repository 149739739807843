import React, {useState} from 'react';
import {Alert, Button, Form, Input, Modal, Radio, Table, Tooltip} from "antd";
import npiApi, {npiApiDownloadFileCallback} from "../../services/api";
import _ from "lodash";
import {useRequest} from "ahooks";
import {FileExcelFilled, SaveFilled, SettingFilled} from "@ant-design/icons/lib";
import {IQuestionTypeEnum} from "../../types/question";
import {useForm} from "antd/lib/form/Form";
import {useWaveStore} from "../../contexts/stores";
import {observer} from "mobx-react";
import styled from "styled-components";
import {INpi} from "../../types/npi";
import {useTranslation} from "react-i18next";
import {nowFormat} from "../../helpers/date";

const StyledDiv = styled.div`
    .ant-form-item{
        margin-bottom: 5px;
    }
    .answer-form>div:last-child .ant-form-item{
        margin-bottom: 0;
    }
`;

enum IExportTypeEnum {
    LABELS = "labels",
    VALUES = "values",
    RO = "ro",
}

const {BOOL, MULTILIST, LIST} = IQuestionTypeEnum;

const NpiWaveTableReportModalExport = observer(({npi}:{npi?:INpi}) => {

    const {wave, save: saveConfig, saving} = useWaveStore();
    const {t} = useTranslation();
    const [form] = useForm();
    const [exportModal, setExportModal] = useState<any>({
        visible: false,
        configRo: false,
        exportType: IExportTypeEnum.LABELS
    });

    const {runAsync: exportWavePosReport, loading: loadingExport} = useRequest(npiApi.internal.file.exportWavePosReport, {manual: true});

    const onExport = () => exportWavePosReport({waveId: wave.id, type: exportModal.exportType})
        .then(npiApiDownloadFileCallback(`report-wave-${wave.id}-${nowFormat()}.xlsx`))
        .then(() => setExportModal({...exportModal, visible: false}))
    ;

    //Show configuration
    const onShowRoConfig = () => {
        setExportModal({...exportModal, configRo:!exportModal.configRo});
        form.setFieldsValue(_.cloneDeep(wave.config));
    };

    //Save configuration
    const onSaveRoConfig = () => {
        const newConfig = {...wave.config, ...form.getFieldsValue()};
        saveConfig({...wave, config: newConfig}).then(() => {
            setExportModal({...exportModal, configRo: false});
            wave.config = newConfig;
        });
    };

    const exportModalConfig = {
        open: exportModal.visible,
        width: 800,
        title: <span>{t('INTERNAL.CLIENT.REPORT.EXPORT_ALL_ROWS')}</span>,
        confirmLoading: loadingExport,
        onCancel: () => {
            setExportModal({
                visible: false,
                exportType: IExportTypeEnum.LABELS
            })
        },
        cancelText: t('INTERNAL.CLIENT.COMMON.CLOSE'),
        onOk: onExport,
        okText: t('INTERNAL.CLIENT.COMMON.DOWNLOAD'),

    };

    const tableConfig = {
        rowKey: 'id',
        pagination: false as false,
        size: 'small' as 'small',
        columns: [
            {title: t('INTERNAL.CLIENT.COMMON.QUESTION'), dataIndex: 'name'},
            {
                title: <>{t('INTERNAL.CLIENT.COMMON.CUSTOM_VALUE')}&nbsp;
                    <Button icon={<SaveFilled/>} type="primary" onClick={onSaveRoConfig} loading={saving} disabled={false}>{t('COMMON.SAVE')}</Button>
                </>,
                align: 'right' as 'right',
                render: (q:any) => <div className="answer-form">
                    {[BOOL, LIST, MULTILIST].indexOf(q.type)>=0 && q.config.answers.map((a:any) => <div key={a.value}>
                        <Form.Item label={a.label} name={['ro', q.id+'', a.value+'']}><Input/></Form.Item>
                    </div>)}
                    {[BOOL, LIST, MULTILIST].indexOf(q.type)<0 && '-'}
                </div>
            }
        ],
        dataSource: wave.questions,
    };

    return <>

        <Button type="primary" icon={<FileExcelFilled/>} onClick={() => setExportModal({...exportModal, visible: true})} loading={loadingExport}>{t('INTERNAL.CLIENT.REPORT.EXPORT_ALL_ROWS')}</Button>

        <Modal {...exportModalConfig} >
            <Alert
                type="info"
                message={t('INTERNAL.CLIENT.REPORT.EXPORT_MODAL_INFO')}
                style={{marginBottom: 20}}
            />

            <StyledDiv>
                <Radio.Group
                    style={{display: 'table', margin: 'auto', padding: 10}}
                    onChange={(e: any) =>
                        setExportModal({...exportModal, exportType: e.target.value})
                    }
                    value={exportModal.exportType}>
                    <Radio value={IExportTypeEnum.LABELS}>{t('INTERNAL.CLIENT.COMMON.LABELS')}</Radio>
                    <Radio value={IExportTypeEnum.VALUES}>{t('INTERNAL.CLIENT.COMMON.VALUES')}</Radio>
                    <Radio value={IExportTypeEnum.RO}>
                        {t('INTERNAL.CLIENT.REPORT.CUSTOM_EXPORT')}&nbsp;
                        <Tooltip title={t('INTERNAL.CLIENT.REPORT.CONFIGURE_CUSTOM_EXPORT')}>
                            <Button type="link" icon={<SettingFilled/>} onClick={onShowRoConfig}/>
                        </Tooltip>
                    </Radio>
                </Radio.Group>

                {exportModal.configRo && <Form form={form} labelCol={{span: 12}} size="small">
                    <Table {...tableConfig}/>
                </Form>}
            </StyledDiv>
        </Modal>
    </>
});

export default NpiWaveTableReportModalExport;