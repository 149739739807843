import React, {useCallback} from 'react';
import {useTranslation} from "react-i18next";
import {Button, Dropdown, Menu, Modal, Space} from "antd";
import {IWave, IWaveStatusEnum} from "../../types/wave";
import NpiPaginatedTable, {INpiTableServerPaginatedProps, NpiServerPagination} from "../paginated-table";
import NpiBatteryChartElement from "../display/battery-chart-element";
import NpiDisplayWaveStatus from "../display/wave-status";
import {
    DashboardFilled,
    DownOutlined,
    TableOutlined
} from "@ant-design/icons";
import GoogleAnalytics from "@2fd/ant-design-icons/lib/GoogleAnalytics";
import NpiDisplayDate from "../display/date";
import {Link} from "react-router-dom";
import styled from "styled-components";
import {ModalProps} from "antd/lib/modal";
import {useRequest} from "ahooks";
import npiApi from "../../services/api";


interface INpiModalDailyHistory extends ModalProps {
    lastWave: IWave|null
}

/**
 * This component is shown in a modal for a single POS to update its tags
 */
const NpiModalDailyHistory = ({lastWave, ...props}:INpiModalDailyHistory) => {
    const {t} = useTranslation();


    const {runAsync: fetchHistory, loading} = useRequest<NpiServerPagination, any>(npiApi.internal.wave.dailyHistory, {
        manual: true,
    });

    const fetcher = useCallback((params: any) => fetchHistory({...params, daily_group_id: lastWave?.daily_group_id!}),[lastWave, fetchHistory]);

    const menuItemsAnalyze = (r:IWave) => [
        {key:'dashboard', icon: <DashboardFilled/>, label: <Link to={"/kpi-dashboard/wave/"+r.id}>{t('INTERNAL.CLIENT.COMMON.DASHBOARD')}</Link>, disabled: !r.nb_kpis},
        {key: 'battery', icon: <TableOutlined/>, label: <Link to={"/npi/"+r.npi_id+"/wave/"+r.id+"/report"} target={"_blank"}>{t('INTERNAL.CLIENT.COMMON.REPORT')}</Link>},
    ];

    const columns:INpiTableServerPaginatedProps<IWave>['columns'] = [
        {title: '#', dataIndex: 'id', sorter: true},
        {title: t('INTERNAL.CLIENT.COMMON.DATE'), dataIndex: 'launched_at', align: 'center', render: (n:any)=><NpiDisplayDate value={n} format={"L"}/>, sorter: true},
        {title: t('INTERNAL.CLIENT.COMMON.POS_STATUSES'), width: 150, render: (row:any) => <NpiBatteryChartElement height={'24px'} width={'150px'} positive={row.nb_pos_positive} negative={row.nb_pos_negative} neutral={row.nb_pos_neutral} noText/>},
        {title: t('INTERNAL.CLIENT.COMMON.STATUS'), dataIndex: 'status', sorter: true, render: (id:number, row:IWave) => <span><NpiDisplayWaveStatus id={id} wave={row}/></span>,},
        { align: 'right', width: 260, render: (r:any) => <Space>
            <Dropdown disabled={r.status === IWaveStatusEnum.OPEN} overlay={<StyledMenu items={menuItemsAnalyze(r) as any}/>}>
                <Button icon={<GoogleAnalytics/>} size="large">
                    {t('INTERNAL.CLIENT.COMMON.ANALYZE')} <DownOutlined />
                </Button>
            </Dropdown>
        </Space>},
    ];


    const configTable = {
        rowKey: 'id',
        fetcher: fetcher,
        loading: loading,
        columns: columns,
    };

    return <Modal width={650} open={!!lastWave} title={lastWave?.name} footer={null} {...props}>
        <NpiPaginatedTable {...configTable}/>
    </Modal>
};

const StyledMenu = styled(Menu)`
   font-size: 16px;
   .anticon{ font-size: 16px;}
`;

export default NpiModalDailyHistory;