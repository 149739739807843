import {WaveStore} from "../stores/wave";
import React, {useContext} from "react";
import {NpiStore} from "../stores/npi";
import {WavePosStore} from "../stores/wavepos";
import {KpiDashboardStore} from "../stores/kpi-dashboard";

const contextValue = {
    wavePosStore: new WavePosStore(),
    waveStore: new WaveStore(),
    npiStore: new NpiStore(),
    kpiDashboardStore: new KpiDashboardStore(),
};

export const StoresContext = React.createContext(contextValue);

export const useStores = () => {
    return useContext(StoresContext);
};

export const useWavePosStore = () => {
    return useStores().wavePosStore;
};

export const useWaveStore = () => {
    return useStores().waveStore;
};

export const useNpiStore = () => {
    return useStores().npiStore;
};

export const useKpiDashboardStore = () => {
    return useStores().kpiDashboardStore;
};