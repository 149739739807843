import React, {Key, useCallback, useMemo, useState} from 'react';
import {useLocation} from "react-router";
import NpiDashboardSyncTooltipStatus, {ISyncStatus} from "./tooltip-status";
import {IWave} from "../../types/wave";
import {useRequest} from "ahooks";
import {INpi} from "../../types/npi";
import npiApi from "../../services/api";
import NpiPaginatedTable, {NpiServerPagination} from "../../components/paginated-table";
import {Button, Select, Space} from "antd";
import {DashboardFilled, LinkOutlined, ReloadOutlined} from "@ant-design/icons";
import {ColumnProps} from "antd/es/table";
import NpiDisplayRegion from "../../components/display/region";
import NpiDisplayWaveStatus from "../../components/display/wave-status";
import NpiDisplayDate from "../../components/display/date";
import {Link} from "react-router-dom";
import NpiInputSelectNpi from "../../components/input/select-npi";
import NpiInputSelectWave from "../../components/input/select-wave";
import NpiDashboardSyncAlertStatus from "./alert-status";
import {useTranslation} from "react-i18next";

interface ISyncWave extends IWave {
    npi_name: string;
    sync_status: ISyncStatus;
    sync_tables: {[key: string]: ISyncStatus};
}

const NpiContainerDashboardSyncListWaveRelated = () => {

    // find wich NPIs ore Waves we are looking for (default all)
    const urlNpis = new URLSearchParams(useLocation().search).getAll("npis");
    const [filterNpis, setFilterNpis] = useState<number[]>([]);
    const [filterWaves, setFilterWaves] = useState<number[]>([]);
    const {t} = useTranslation();

    // remove selected column for status calculation
    const [filterColumns, setFilterColumns] = useState<string[]>([]);

    // list of displayed waves
    const [waves, setWaves] = useState<ISyncWave[]>([]);


    // if npis in URL, we find infos on them
    const {loading} = useRequest<INpi[], any>(npiApi.internal.npi.search, {
        manual: urlNpis.length <= 0,
        defaultParams: [{ids: urlNpis}],
        onSuccess: npis => setFilterNpis(npis.map(npi => npi.id)),
    });
    const {runAsync: listNpisWaves} = useRequest<NpiServerPagination, any>(npiApi.internal.dev.synchro.listNpisWaves, {manual: true, onSuccess: (paginate) => setWaves(paginate.data)});
    const {runAsync: tablesStatus, loading: isRefreshing} = useRequest<any, any>(npiApi.internal.dev.synchro.status.waveRelatedTables, {manual: true});

    // load Waves for selected Npis
    const wavesFetcher = useCallback((params: any) => listNpisWaves({
        npiIds: filterNpis,
        waveIds: filterWaves,
        filterColumns: filterColumns,
        ...params
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }),[filterNpis, filterWaves, filterColumns]);


    // checkbox selection of waves
    const [waveSelected, setWaveSelected] = useState<ISyncWave[]>([]);
    const onChangeSelection = (selectedRowKeys: Key[], selectedRows: ISyncWave[]) => {
        setWaveSelected(selectedRows);
    };

    // refresh some lines Status
    const refreshLines = (listToRefresh:&ISyncWave[]) => {

        if( listToRefresh.length > 0 ) {
            listToRefresh.forEach((wave) => {
                wave.sync_status.loading = true;
                Object.values(wave.sync_tables).forEach((table) => table.loading = true);
            });
            setWaves([...waves]);

            tablesStatus({waveIds: listToRefresh.map(wave => wave.id), filters: filterColumns})
                .then((results:any) => listToRefresh.forEach((wave) => {
                    wave.sync_status = results[wave.id].sync_status;
                    wave.sync_tables = results[wave.id].sync_tables;
                }))
                .finally(() => {
                    listToRefresh.forEach((wave) => {
                        wave.sync_status.loading = false;
                        Object.values(wave.sync_tables).forEach((table) => table.loading = false);
                    });
                    setWaves([...waves]);
                })
        }
    };

    const refreshSelectionButton = useMemo(() => <Button icon={<ReloadOutlined />} onClick={() => refreshLines(waveSelected)} disabled={isRefreshing || waveSelected.length === 0}>
            {t('INTERNAL.CLIENT.COMMON.REFRESH')} ({waveSelected.length})
        </Button>,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isRefreshing, waveSelected]);


    // table columns definition
    const columns:ColumnProps<ISyncWave>[] = [
        {title: t('INTERNAL.CLIENT.NPI.NPI'), render: (wave:ISyncWave) => <><b>{wave.npi_id}</b> - {wave.npi_name}</>, fixed: 'left'},
        {title: t('INTERNAL.CLIENT.NPI.WAVE'), render: (wave:ISyncWave) => <><b>{wave.id}</b> - {wave.name}</>, fixed: 'left'},
        {title: t('INTERNAL.CLIENT.COMMON.REGION'), render: (wave:ISyncWave) => <NpiDisplayRegion id={wave.region_id}/>, fixed: 'left'},
        {title: t('INTERNAL.CLIENT.COMMON.STATUS'), render: (wave:ISyncWave) => <NpiDisplayWaveStatus id={wave.status}/>, fixed: 'left'},
        {title: t('INTERNAL.CLIENT.COMMON.UPDATED_AT'), dataIndex: 'updated_at', render: (date:string) => <NpiDisplayDate value={date} hours={"inline"}/>, fixed: 'left'},
        ...['waves', 'questions', 'answers', 'wave_pos', 'wave_pos_images'].map(t => ({
            title: `Table ${t}`, render: (wave:ISyncWave) => <NpiDashboardSyncTooltipStatus status={wave.sync_tables[t]} simple={t === 'waves'} detailsParams={{table: t, id: wave.id}}/>
        })),
        {title: <Space>{refreshSelectionButton}</Space>, fixed: 'right', align: "right",
            render: (wave:ISyncWave) => {
                return <Space>
                    <Button onClick={() => refreshLines([wave])} icon={<ReloadOutlined />} size={'small'} disabled={wave.sync_status ? wave.sync_status.loading : false}>{t('INTERNAL.CLIENT.COMMON.REFRESH')}</Button>
                    <Link to={'/dev-dashboard/sync/wave/' + wave.id}><Button icon={<DashboardFilled />} size={'small'}>{t('INTERNAL.CLIENT.COMMON.DETAILS')}</Button></Link>
                    <Link to={'/wave/' + wave.id+'/report'} target="_blank"><Button icon={<LinkOutlined />} size={'small'}>{t('INTERNAL.CLIENT.COMMON.REPORT')}</Button></Link>
                </Space>;
            }
        },
    ];

    return <>
        <Space style={{marginBottom: '10px'}}>
            <NpiInputSelectNpi value={filterNpis} onChange={v => {
                setFilterNpis(v);
                setFilterWaves([]);
            }} mode={"multiple"} allowClear={true}/>
            <NpiInputSelectWave value={filterWaves} onChange={setFilterWaves} npis={filterNpis} mode={"multiple"} allowClear={true}/>
            <Select value={filterColumns} onChange={setFilterColumns} placeholder={t('INTERNAL.CLIENT.DASHBOARD.SYNCHRO_COLUMN_FILTER')} mode={"tags"} allowClear={true} style={{width: '350px'}}/>
            <NpiDashboardSyncAlertStatus list={waves} />
        </Space>

        <NpiPaginatedTable
            loading={loading}
            fetcher={wavesFetcher}
            columns={columns}
            scroll={{x: true as true, y: undefined}}
            pagination={{hideOnSinglePage: true, pageSize: 10}}
            onRow={((wave:ISyncWave) => (wave.sync_status && !wave.sync_status.match) ? {style: {backgroundColor: '#ff787555'}} : {})}
            rowSelection={{type: 'checkbox', onChange:onChangeSelection}}
            footer={() => <Space>
                {refreshSelectionButton}
            </Space>}
        />
    </>;
};

export default NpiContainerDashboardSyncListWaveRelated;