import {IWithQuestions} from "./question";
import {IKpi} from "./kpi";
import {ITranslations, IWave} from "./wave";

export enum ILobEnum {
    IPHONE = 'IPHONE',
    IPAD = 'IPAD',
    MAC = 'MAC',
    WATCH = 'WATCH',
    OTHER = 'OTHER',
}

export enum INpiStatusEnum {
    DRAFT=0,
    PUBLISH=1,
    ARCHIVED=99,
};

const {IPHONE, IPAD, MAC, WATCH, OTHER} = ILobEnum;

export const lobs = [
    {label: 'INTERNAL.CLIENT.LOB.IPHONE', value: IPHONE},
    {label: 'INTERNAL.CLIENT.LOB.IPAD', value: IPAD},
    {label: 'INTERNAL.CLIENT.LOB.MAC', value: MAC},
    {label: 'INTERNAL.CLIENT.LOB.WATCH', value: WATCH},
    {label: 'INTERNAL.CLIENT.LOB.OTHER', value: OTHER},
];

export interface INpi extends IWithQuestions {
    id: number
    region_id: number
    language_id: number
    name: string
    status: INpiStatusEnum
    translations: ITranslations
    kpis: IKpi[]
    waves: IWave[]
    is_daily: boolean
}