import React, {useContext, useEffect, useState} from 'react';
import NpiDisplayLayoutContent from "../../components/display/layout-content";
import {NpiInternalContext} from "../../contexts/internal-context";
import npiApi from "../../services/api";
import {Button, Modal, notification, Space, Table} from "antd";
import {useRequest} from "ahooks";
import NpiDisplayRegion from "../../components/display/region";
import {DeleteFilled, EditFilled} from "@ant-design/icons/lib";
import useRestrictedToAdmin from "../../hooks/use-restricted-to-admin";
import {useTranslation} from "react-i18next";
import NpiCreateEditContactModal, {
    INpiEditContactModalFormData
} from "../../components/contact/create-edit-contact-modal";
import {PlusOutlined} from "@ant-design/icons";
import IContact from "../../types/contact";


const NpiContainerAdminContactList = () => {
    useRestrictedToAdmin();


    const {setBreadcrumbs} = useContext(NpiInternalContext);
    const {runAsync: fetchContacts, loading, data: contacts} = useRequest<IContact[], []>(npiApi.internal.contact.list);
    const {runAsync: deleteContact} = useRequest(npiApi.internal.contact.delete, {manual: true});
    const {t} = useTranslation();

    const [contactModal, setContactModal] = useState({
        isVisible: false,
        title: <></>,
        contact: {} as undefined | IContact,
    });

    //Set navigation
    useEffect(() => setBreadcrumbs([
        {name: t('INTERNAL.CLIENT.COMMON.ADMINISTRATION')},
        {url: '/admin/contacts', name: t('INTERNAL.CLIENT.COMMON.CONTACTS')},
    ]), [setBreadcrumbs, t]);


    //On Delete a row
    const onDelete = (row: any) => {
        const {name} = row;

        Modal.confirm({
            title: <>{t('INTERNAL.CLIENT.CONTACTS.DELETE_CONTACT', {name})}</>,
            content: t('INTERNAL.CLIENT.COMMON.ARE_YOU_SURE'),
            onOk: () => deleteContact(row.id).then(fetchContacts).then(() => notification.success({message: t('INTERNAL.CLIENT.CONTACTS.CONTACT_DELETED')})),
            okType: 'danger',
            okText: t('COMMON.DELETE'),
        })
    };


    /**
     * Handle contact creation or edition
     * @param data
     */
    const onCreateOrEditModalSubmit = async (data: INpiEditContactModalFormData): Promise<boolean> => {

        try {
            await npiApi.internal.contact.save(data);

            notification.success({message: t('INTERNAL.CLIENT.CONTACTS.CONTACT_SAVED')});
            fetchContacts();
        } catch (e) {
            return false;
        }

        return true;
    }

    /**
     * Show contact modal in edition mode
     * @param row
     */
    const onClickEditContactButton = (row: IContact) => {
        const {email} = row;
        setContactModal({
            isVisible: true,
            title: <span>{t('INTERNAL.CLIENT.CONTACTS.EDIT_CONTACT', {email})}</span>,
            contact: row
        })
    }

    /**
     * Show contact modal in creation mode
     */
    const onClickCreateContactButton = () => {
        setContactModal({
            isVisible: true,
            title: <>{t('INTERNAL.CLIENT.CONTACTS.CREATE_CONTACT')}</>,
            contact: undefined
        })
    }

    const columns = [
        {
            title: t('INTERNAL.CLIENT.COMMON.REGION'),
            dataIndex: 'region_id',
            render: (id: number) => <NpiDisplayRegion id={id}/>,
            sorter: true
        },
        {title: t('INTERNAL.CLIENT.COMMON.NAME'), dataIndex: 'name', sorter: true},
        {title: t('INTERNAL.CLIENT.COMMON.EMAIL'), dataIndex: 'email', sorter: true},
        {title: t('INTERNAL.CLIENT.CONTACTS.CONTACT_POS_ACCESS_COUNT'), dataIndex: 'pos_access_count'},
        {
            align: 'right' as 'right',
            //Edit
            title: <Button icon={<PlusOutlined/>}
                           onClick={onClickCreateContactButton}
                           type="primary">
                {t('INTERNAL.CLIENT.COMMON.CREATE')}
            </Button>,
            //Edit
            render: (row: IContact) => <Space>
                <Button icon={<EditFilled/>} type="primary"
                        onClick={() => onClickEditContactButton(row)}> {t('INTERNAL.CLIENT.COMMON.EDIT')}</Button>
                <Button icon={<DeleteFilled/>} danger type="primary" onClick={() => onDelete(row)}></Button>
            </Space>
        },
    ];

    const tableConfig = {
        rowKey: 'id',
        dataSource: contacts as any,
        columns,
        loading,
        pagination: false as false,
    };


    return <NpiDisplayLayoutContent>
        <NpiCreateEditContactModal
            title={contactModal.title}
            open={contactModal.isVisible}
            onChangeVisibility={(visible: boolean) => setContactModal({...contactModal, isVisible: visible})}
            contact={contactModal.contact}
            onSubmit={onCreateOrEditModalSubmit}
        />
        <Table {...tableConfig}/>
    </NpiDisplayLayoutContent>
};

export default NpiContainerAdminContactList;