import React, {Dispatch, useEffect, useState} from "react";
import {IWithQuestions} from "../../types/question";
import _ from "lodash";
import {useControllableValue, useDebounceFn} from "ahooks";
import {Alert, Form, Input, Progress, Table} from "antd";
import NpiDisplayLanguage from "../display/language";
import styled from "styled-components";
import useWindowSize from "../../hooks/use-window-size";
import {ITranslations} from "../../types/wave";
import {generateTerms} from "../../helpers/translation";
import {useForm} from "antd/lib/form/Form";
import {filterUndefinedObjectPropertiesRecursive} from "../../helpers/toolbox";
import {useTranslation} from "react-i18next";

const StyledColumnTitle = styled.span`
    .ant-progress{
      width: 100px;
      margin-left: 10px;
    }
`;

const StyledTable = styled(Table)`
  .ant-row{ margin: 0}
`;

//Title of a column
const NpiInputTranslationsColumnTitle = ({tId, counters, terms}:any) => <StyledColumnTitle>
    <NpiDisplayLanguage id={Number(tId.replace('T', ''))}/>
    <Progress percent={Math.round(counters[tId]/terms.length*100)} size="small"  steps={10}/>
</StyledColumnTitle>;

interface INpiInputTranslations {
    languageId: number
    item: IWithQuestions
    translations: ITranslations
    translationIds: number[]
    onChange?: Dispatch<ITranslations>
    autocomplete?: boolean
}

/**
 * This input controls the translation object that is used to configure translations in a wave
 * @param languageId
 * @param questions
 * @param translationIds
 * @param autocomplete
 * @param props
 * @constructor
 */
const NpiInputTranslations = ({languageId, item, translationIds, autocomplete = false, ...props}:INpiInputTranslations) => {
    const [translations, setTranslations] = useControllableValue(props, {
        valuePropName: 'translations',
    });
    const {t} = useTranslation();
    const [form] = useForm();
    const [terms, setTerms] = useState<any[]>([]);
    const [counters, setCounters] = useState<any>({});
    const columnWidth = 300;
    const {height} = useWindowSize();
    const hasTranslations = translationIds?.length > 0;
    const {questions} = item;

    //Set our counters
    const updateCounters = (translations:ITranslations) => setCounters(_.mapValues(translations, v => _.keys(v).length));

    //Track changes on translations
    const {run: onChange} = useDebounceFn((fields) => {
        //Identify the field changed
        const field = fields[0];
        const value = field.value;
        const translationId = field.name[0]; //with the "T"
        const originalText = _.find(terms, {path: field.name[1]})?.term;

        //If we are in autocomplete, update all terms which have an identical string
        if(autocomplete){
            const newValues = {};
            _.filter(terms, {term: originalText}).forEach(term => {
                _.set(newValues, [translationId, term.path], value);
            });
            form.setFieldsValue(newValues);
        }

        //Get our translation, clearing all empty fields
        const translations = _.chain(form.getFieldsValue()).mapValues(t => _.pickBy(t, v => !!v)).value();
        //Propagate our change
        setTranslations({...translations});
    }, {wait: 500, trailing: true});

    //Create the columns of our table
    //Term column
    const columns:any[] = [
        {
            dataIndex: 'term',
            title: <NpiDisplayLanguage id={languageId}/>,
            fixed: 'left',
            width: columnWidth,
            ellipsis: true,
        }
    ];

    //On column for each language
    translationIds?.forEach(tId => {
        columns.push({
            dataIndex: 'path',
            title: <NpiInputTranslationsColumnTitle tId={'T'+tId} counters={counters} terms={terms}/>,
            width: columnWidth,
            render: (path:string) => {
                return <Form.Item name={['T'+tId, path]}><Input/></Form.Item>
            },
        });
    });

    //Prepare the rows of the translation table & clean up the translation object
    useEffect(() => {
        setTerms(generateTerms(item));
        form.setFieldsValue(translations);
        //TODO: clean up the translation object by removing unused terms
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [questions]);

    //Observe changes to translation to update our form (use case: upload of a translation)
    useEffect(() => {
        const formValues = filterUndefinedObjectPropertiesRecursive(form.getFieldsValue());
        if( ! _.isEqual(formValues, translations)){
            form.resetFields(); // in case of a value that was fill but that a new uploaded file empty it -> input is not clear without this line
            form.setFieldsValue(translations);
        }
        updateCounters(translations);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [translations]);

    if( ! hasTranslations){
        return <Alert message={t('INTERNAL.CLIENT.TRANSLATION.NO_TRANSLATION_TO_MANAGE')}/>
    }
    else{
        return <Form form={form} onFieldsChange={onChange}>
            <StyledTable dataSource={terms} rowKey="path" columns={columns} scroll={{x: (translationIds.length+1)*columnWidth, y: height-400}} pagination={false} size="small"/>
        </Form>;
    }
};

export default NpiInputTranslations;