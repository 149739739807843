import React, {useMemo, useState} from 'react';
import {observer} from "mobx-react";
import npiApi, {IBase64WithInfo, npiApiDownloadBase64Callback} from "../../services/api";
import {Alert, Button, Input, Space, Upload} from "antd";
import {UploadOutlined} from "@ant-design/icons";
import {useRequest} from "ahooks";
import {RcFile} from "antd/lib/upload";
import {useTranslation} from "react-i18next";

/**
 * Build tag file from a given file and return output+converted file
 */
const NpiModalBuildTags = observer(() => {

    const {t} = useTranslation();
    const {runAsync: upload, loading: loadingUpload} = useRequest<any, any>(npiApi.internal.tag.buildTagsFile, {manual: true});

    const [firstTagColumn, setFirstTagColumn] = useState<string>('');
    const [output, setOutput] = useState<string>('');

    //Upload file properties
    const FileUploadProps = useMemo(() => ({
        name: "file",
        width: 600,
        accept: ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
        showUploadList: false,
        beforeUpload : (file: RcFile) => {
            setOutput('');
            upload({file: file, 'first-tag-column': firstTagColumn})
                .then((data: {output: string, file: IBase64WithInfo}) => {
                    setOutput(data.output)
                    npiApiDownloadBase64Callback(data.file).then(null)
                });
            return false;
        }
    }), [upload, firstTagColumn]);

    return <>
        <Alert message={t('INTERNAL.CLIENT.TAGS.HOW_TO_BUILD')}/>

        <Space style={{marginTop: 20}}>
            <Input placeholder={t('INTERNAL.CLIENT.TAGS.BUILD_INPUT_COLUMN')} value={firstTagColumn} onChange={(e) => setFirstTagColumn(e.target.value.toUpperCase())}/>

            <Upload {...FileUploadProps}>
                <Button disabled={!firstTagColumn || loadingUpload} type="primary" icon={<UploadOutlined/>} loading={loadingUpload}>{t('INTERNAL.CLIENT.TAGS.BUILD_UPLOAD')}</Button>
            </Upload>
        </Space>

        {!!output && <div style={{marginTop: 20}}>
            <em>{t('INTERNAL.CLIENT.TAGS.BUILD_OUTPUT')} :</em><br/>
            <pre style={{padding: 10, border: "1px solid lightgray"}}>{output}</pre>
        </div>}
    </>
});

export default NpiModalBuildTags;