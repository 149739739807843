import React, {useCallback, useContext, useMemo, useState} from 'react';
import {useDebounceFn, useMount, useRequest} from "ahooks";
import npiApi, {npiApiDownloadFileCallback} from "../services/api";
import {IKpi} from "../types/kpi";
import {
    Alert,
    Button,
    Col,
    Form,
    Modal, notification,
    Row, Select,
    Space, Switch, Tooltip
} from "antd";
import {useParams} from "react-router";
import NpiDisplayLayoutContent from "../components/display/layout-content";
import {NpiInternalContext} from "../contexts/internal-context";
import NpiInputSelectTagsAndValues from "../components/input/select-tags-and-values";
import {REGION_WW} from "../helpers/constants";
import NpiInputSelectRegion from "../components/input/select-region";
import NpiInputSelectCountry from "../components/input/select-country";
import {SettingFilled, UploadOutlined} from "@ant-design/icons/lib";
import NpiModalTemplateUploader from "../components/display/modal-template-uploader";
import NpiWwDashboardTable from "../components/ww-dashboard/table";
import NpiWwDashboardColoredProgressCircle from "../components/ww-dashboard/colored-progress-circle";
import _ from "lodash";
import Title from "antd/es/typography/Title";
import {INpi} from "../types/npi";
import NpiInputColorPickerDetailed from "../components/input/color-picker-detailed";
import {defaultColors} from "../components/ww-dashboard/progress";
import NpiWwDashboardInputSelectSnapshot from "../components/ww-dashboard/select-snapshot";
import {useTranslation} from "react-i18next";
import NpiInputSelectWave from "../components/input/select-wave";
import NpiSortTable from "../components/sort-table";
import {ColumnProps} from "antd/lib/table";
import NpiDragHandle from "../components/display/drag-handle";
import {nowFormat} from "../helpers/date";
import ReorderHorizontal from "@2fd/ant-design-icons/lib/ReorderHorizontal";
import FileExcelIcon from "@2fd/ant-design-icons/lib/FileExcel";
import useAcl from "../hooks/use-acl";
import {IRightsEnum} from "../types/user";
import useRestrictedToRights from "../hooks/use-restricted-to-rights";
import MoonWaningCrescent from '@2fd/ant-design-icons/lib/MoonWaningCrescent';
import WhiteBalanceSunny from '@2fd/ant-design-icons/lib/WhiteBalanceSunny';
import PrinterOutline from '@2fd/ant-design-icons/lib/PrinterOutline';
import ViewGridOutline from '@2fd/ant-design-icons/lib/ViewGridOutline';
import {useNpiUiContext} from "../contexts/ui-context";
import styled, {css} from "styled-components";

interface IDataRow {
    ww_region_name: string
    ww_sub_region_name: string
    branded: IDataRowDetail
    non_branded: IDataRowDetail
    is_ignored_data?: boolean
    class_name?: string
}

export interface IDataRowDetail {
    total: number
    [key: string]: any
}

/**
 * Dashboard showing for an NPI
 * @constructor
 */
const NpiContainerWwDashboard = () => {
    useRestrictedToRights([IRightsEnum.VIEW_SENSITIVE_DATA]);

    const [form] = Form.useForm();
    const [modalBulkVisible, setModalBulkVisible] = useState(false);
    const [modalConfigureVisible, setModalConfigureVisible] = useState(false);
    const canExport = useAcl().hasRight(IRightsEnum.EXPORT_WW_DASHBOARD);
    const {dashboardId} = useParams() as any;
    const {t} = useTranslation();

    const exportTemplate = useCallback(() => npiApi.internal.file.exportWwDashboardBulk({wwDashboardId: dashboardId}), [dashboardId]);
    const importTemplate = useCallback((params) => npiApi.internal.file.importWwDashboardBulk({wwDashboardId: dashboardId, ...params}), [dashboardId]);
    const {runAsync: saveConfiguration, loading: loadingSave} = useRequest<any,any>(npiApi.internal.wwDashboard.save, {manual: true});
    const {data: fetchedData, runAsync:fetchData, loading} = useRequest<any,any>(npiApi.internal.wwDashboard.compute, {manual: true, onSuccess: (data) => setKpis(data.kpis)});
    const {runAsync: exportPartnerReadiness, loading: loadingExportPartnerReadiness} = useRequest<any,any>(npiApi.internal.file.exportWwDashboardPartnerReadiness, {manual: true});

    const [kpis, setKpis] = useState<IKpi[]>([]);
    const {data=[], npi, snapshots=[], dashboard={}}:{data:IDataRow[], npi:INpi, kpis:IKpi[], snapshots:any[], snapshotId:number|undefined, dashboard:any} = fetchedData ?? {};
    const {setBreadcrumbs, isAllowedRegion} = useContext(NpiInternalContext);
    const isWW = isAllowedRegion(REGION_WW);
    const {isSuperAdmin} = useContext(NpiInternalContext);
    const {darkMode, setDarkMode} = useNpiUiContext();
    const isSuperAdminWW = useMemo(() => isWW && isSuperAdmin, [isWW, isSuperAdmin]);
    const [configuration, setConfiguration] = useState<any>({});
    const [printMode, setPrintMode] = useState<boolean>(false);


    //Manage dirty state of form and display of tooltip
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [showFormTooltip, setShowFormTooltip] = useState(false);
    const {run: showFormTooltipAfterSomeTime, cancel: cancelShowFormTooltipAfterSomeTime} = useDebounceFn(() => setShowFormTooltip(true), {wait: 3*1000});

    //Reset configuration
    const resetConfiguration = useCallback((newDashboard?:any) => setConfiguration({
        colorBars: defaultColors,
        order: kpis.map(k => k.id),
        ..._.cloneDeep(newDashboard?.config??dashboard.config)
    }), [dashboard.config, kpis]);

    //When form changes, show a tooltip after so time with no apply
    const onFormSetDirty = useCallback((value:boolean) => {
        setIsFormDirty(value);
        setShowFormTooltip(false);
        cancelShowFormTooltipAfterSomeTime();
        if(value){
            showFormTooltipAfterSomeTime();
        }
    }, [cancelShowFormTooltipAfterSomeTime, showFormTooltipAfterSomeTime]);

    //Apply filters
    const onApplyFilters = useCallback(({filters}:any, firstCall=false) => {
        const formData = form.getFieldsValue();

        //Validation
        if(formData.snapshots.length > 3){
            notification.error({message: t('INTERNAL.CLIENT.DASHBOARD.SNAPSHOT_LIMIT_ERROR')});
            return Promise.reject();
        }

        cancelShowFormTooltipAfterSomeTime();
        return fetchData(dashboardId, {
            ...formData,
            ...filters,
            firstCall: firstCall,
        }).then((result:any) => {
            resetConfiguration(result.dashboard);
            form.setFieldsValue(result.parameters)
            onFormSetDirty(false);
            return result;
        });
    }, [fetchData, dashboardId, form, onFormSetDirty, cancelShowFormTooltipAfterSomeTime, resetConfiguration, t]);

    //Save filters in configuration
    const onSaveFilters = useCallback(() => {
        const formData = form.getFieldsValue();
        const newConfiguration = {...configuration, filters: formData};
        setConfiguration(newConfiguration);
        saveConfiguration(dashboardId, {configuration: newConfiguration})
            .then(() => onApplyFilters({}))
            .then(() => notification.success({message: t('INTERNAL.CLIENT.DASHBOARD.WW_CONFIGURATION_SAVED')}))
        ;
    }, [dashboardId, configuration, form, saveConfiguration, onApplyFilters, t]);

    //On change for removed configurations
    const onChangeRemoved = useCallback((kpiId:number, field:'removedFromTotalReadiness'|'removedKpis') => {
        if( ! configuration[field]) configuration[field] = [];
        if(configuration[field].indexOf(kpiId)>=0) configuration[field].splice(configuration[field].indexOf(kpiId), 1);
        else configuration[field].push(kpiId);
        setConfiguration({...configuration});
    }, [configuration]);

    //Change the configuration
    const onChangeColor = useCallback((color : any, position: number) => {
        const colorBars = [...configuration.colorBars];
        colorBars[position] = color;
        setConfiguration({...configuration, colorBars});
    },[configuration]);

    //Change the order of kpis
    const onChangeOrder = useCallback((oldIndex: number, newIndex: number, kpi: IKpi) => {
        kpis.splice(oldIndex, 1);
        kpis.splice(newIndex, 0, kpi);
        setKpis([...kpis]);
        setConfiguration({...configuration, order: kpis.map(k => k.id)});
    }, [configuration, kpis])

    //Export partner readiness
    const onExportPartnerReadiness = () => {
        if( ! loadingExportPartnerReadiness){
            exportPartnerReadiness({wwDashboardId: dashboardId, params: form.getFieldsValue()})
                .then(npiApiDownloadFileCallback(`partner-readiness-${npi.id}-${nowFormat()}.xlsx`))
            ;
        }
    }

    //Observe change to the id
    useMount(() => {
        if(dashboardId) {
            onApplyFilters({}, true).then(({dashboard}) => {
                setBreadcrumbs([
                    {url: '/ww-dashboard', name: t('INTERNAL.CLIENT.DASHBOARD.READINESS_DASHBOARD')},
                    {url: '/ww-dashboard/' + dashboardId, name: dashboard.name},
                ]);
            });
        }
    });

    //
    const totalPercentBranded = useMemo(() => {
        let value = 0;
        let total = 0;
        data.forEach(row => {
            if ( ! row.is_ignored_data) {
                value += parseInt(row.branded.grand_sum)??0;
                total += parseInt(row.branded.grand_total)??0;
            }
        });

        return Math.round(value/total * 100);
    }, [data]);

    //Form for filtering
    const formConfig = {
        form,
        layout: 'inline' as 'inline',
        style: {marginBottom: 20},
        onFieldsChange: () => onFormSetDirty(form.isFieldsTouched()),
    };

    //KPi configuration modal
    const tableConfigureKpisConfig = useMemo(() => ({
        dataSource: kpis,
        rowKey: 'id',
        columns: [
            {title: t('INTERNAL.CLIENT.NPI.KPI'), dataIndex: 'name', render: (_, row) => <NpiDragHandle children={row.name} icon={<ReorderHorizontal/>}/>},
            {title: t('INTERNAL.CLIENT.COMMON.VISIBLE'), align: 'right' as 'right', render: (row:any) =>
                 <Switch
                     checked={(configuration?.removedKpis??[]).indexOf(row.id)<0}
                     onChange={() => onChangeRemoved(row.id, 'removedKpis')}
                 />
            },
            {title: t('INTERNAL.CLIENT.DASHBOARD.INCLUDE_TOTAL_READINESS'), align: 'right' as 'right', render: (row:any) =>
                 <Switch
                     checked={(configuration?.removedFromTotalReadiness??[]).indexOf(row.id)<0 && (configuration?.removedKpis??[]).indexOf(row.id)<0} //must have not removed
                     onChange={() => onChangeRemoved(row.id, 'removedFromTotalReadiness')}
                     disabled={(configuration?.removedKpis??[]).indexOf(row.id)>=0}
                 />
            },
        ] as ColumnProps<IKpi>[],
        scroll: {y: 300},
        pagination: false as false,
        onMoveItem: onChangeOrder,
    }), [kpis, configuration, onChangeRemoved, onChangeOrder, t]);

    //Modal ton bulk upload
    const modalBulkConfig = {
        title: <span><UploadOutlined/> {t('INTERNAL.CLIENT.DASHBOARD.BULK_UPLOAD_DATA')}</span>,
        footer: null,
        open: modalBulkVisible,
        onCancel: () => setModalBulkVisible(false),
    };

    //Modal to configure the report
    const modalConfigureConfig = useMemo(() => ({
        title: <span><SettingFilled/> {t('INTERNAL.CLIENT.DASHBOARD.CONFIGURE_DASHBOARD')}</span>,
        open: modalConfigureVisible,
        destroyOnClose: true, //force reset when no save
        okText: t('COMMON.APPLY'),
        onCancel: () => {resetConfiguration(); setModalConfigureVisible(false);},
        onOk: () => {
            return saveConfiguration(dashboardId, {configuration})
                .then(() => onApplyFilters({}))
                .then(() => setModalConfigureVisible(false))
            ;
        },
        okButtonProps: {loading: loading || loadingSave},
    }), [modalConfigureVisible, loadingSave, loading, dashboardId, configuration, onApplyFilters, saveConfiguration, resetConfiguration, t]);

    const optionsBulk = [{label: t('INTERNAL.CLIENT.DASHBOARD.ALL_DATA'), value: 'include'}, {label:  t('INTERNAL.CLIENT.DASHBOARD.POS_DATA'), value: 'exclude'}, {label: t('INTERNAL.CLIENT.DASHBOARD.BULK_COUNTRY_DATA'), value: 'only'}];
    //const optionsBuild = [{label: t('INTERNAL.CLIENT.DASHBOARD.BTF'), value: 'BTF'}, {label: t('INTERNAL.CLIENT.DASHBOARD.BTO'), value: 'BTO'}, {label: t('INTERNAL.CLIENT.DASHBOARD.BTF_BTO'), value: 'BTF+BTO'}];

    return <NpiDisplayLayoutContent>
        {<div style={{display: loading?'none':'block'}}>
            <Space style={{float: 'right'}}>
                {isWW && <Space>
                    {t('INTERNAL.CLIENT.DASHBOARD.PRINT_MODE')}<Switch checked={printMode} onChange={setPrintMode} checkedChildren={<PrinterOutline/>} unCheckedChildren={<ViewGridOutline />} title={t('INTERNAL.CLIENT.DASHBOARD.PRINT_MODE')}/>
                    <Switch checked={darkMode} onChange={setDarkMode} checkedChildren={<MoonWaningCrescent/>} unCheckedChildren={<WhiteBalanceSunny/>} title={t('INTERNAL.CLIENT.DASHBOARD.DARK_MODE')}/>
                </Space>}
                {isSuperAdminWW && <Button type="primary" onClick={() => setModalConfigureVisible(true)} icon={<SettingFilled/>}>{t('INTERNAL.CLIENT.COMMON.CONFIGURE')}</Button>}
                {!isWW && <Button type="primary" onClick={() => setModalBulkVisible(true)} icon={<UploadOutlined/>}>{t('INTERNAL.CLIENT.DASHBOARD.BULK_UPLOAD')}</Button>}
                {canExport && <Button type="primary" onClick={onExportPartnerReadiness} icon={<FileExcelIcon/>} loading={loadingExportPartnerReadiness}>{t('INTERNAL.SERVER.WW_DASHBOARD.PARTNER_METRICS_EXPORT')}</Button>}
            </Space>
            <Title>
                {npi?.name}
            </Title>
            <Form {...formConfig}>
                <Form.Item name="regions" label={t('INTERNAL.CLIENT.COMMON.REGIONS')} >
                    <NpiInputSelectRegion mode="multiple" style={{minWidth: 150}} placeholder={t('INTERNAL.CLIENT.COMMON.SELECT_ONE_OR_MORE')} allowClear={true} disabled={loading}/>
                </Form.Item>
                <Form.Item name="countries" label={t('INTERNAL.CLIENT.COMMON.COUNTRIES')}>
                    <NpiInputSelectCountry mode="multiple" style={{minWidth: 150}} placeholder={t('INTERNAL.CLIENT.COMMON.SELECT_ONE_OR_MORE')} maxTagCount={5} allowClear={true} disabled={loading} dropdownMatchSelectWidth={false} defaultAllSelected={true} selectAllOption={true}/>
                </Form.Item>
                <Form.Item name="waves" label={t('INTERNAL.CLIENT.NPI.WAVES')}>
                    <NpiInputSelectWave npis={[npi?.id]} mode="multiple" restrictToUserRegion={false} disabled={!npi} allowClear showSearch={false} onSearch={undefined} dropdownMatchSelectWidth={false} limit={1000} style={{minWidth: 150}}/>
                </Form.Item>
                <Form.Item name="tags" label={t('INTERNAL.CLIENT.COMMON.TAGS')}>
                    <NpiInputSelectTagsAndValues regionId={REGION_WW} npis={[npi?.id]} style={{minWidth: 150}} dropdownMatchSelectWidth={false} allowClear={true} disabled={loading} placeholder={t('INTERNAL.CLIENT.COMMON.SELECT_ONE_OR_MORE')}/>
                </Form.Item>
                {/*<Form.Item name="btoOrBtf" label={t('INTERNAL.CLIENT.COMMON.BUILD')} initialValue="BTF">*/}
                {/*    <Select options={optionsBuild} dropdownMatchSelectWidth={false}/>*/}
                {/*</Form.Item>*/}
                <Form.Item name="bulkData" label={t('INTERNAL.CLIENT.DASHBOARD.SCOPE_OF_DATA')} initialValue="include">
                    <Select options={optionsBulk} dropdownMatchSelectWidth={false}/>
                </Form.Item>
                <Form.Item name="snapshots" label={t('INTERNAL.CLIENT.DASHBOARD.SNAPSHOTS')} initialValue={[0]}>
                    <NpiWwDashboardInputSelectSnapshot snapshots={snapshots} style={{minWidth: 150}}/>
                </Form.Item>

                <Space>
                    <Tooltip title={t('INTERNAL.CLIENT.COMMON.APPLY_TOOLTIP')} open={showFormTooltip} placement="right">
                        <Button type="primary" onClick={onApplyFilters} disabled={loading || !isFormDirty}>{t('COMMON.APPLY')}</Button>
                    </Tooltip>
                    {isSuperAdminWW && <Tooltip title={t('INTERNAL.CLIENT.DASHBOARD.WW_SAVE_CONFIG')}>
                        <Button type="primary" onClick={onSaveFilters} loading={loadingSave}>{t('COMMON.SAVE')}</Button>
                    </Tooltip>}
                </Space>
            </Form>
        </div>}
        <StyledNpiDisplayLayoutContent loading={loading} darkMode={darkMode}>
            <Row>
                <Col lg={printMode ? 24:21} md={24}>
                    <NpiWwDashboardTable data={data} kpis={kpis} configuration={configuration} printMode={printMode}/>
                </Col>
                { ! printMode && <Col lg={3} md={24} style={{textAlign: 'center'}}>
                    {/*<Statistic value={"94%"}/>*/}
                    <Title level={4}>{t('INTERNAL.CLIENT.DASHBOARD.BRANDED_READINESS')}</Title>
                    <NpiWwDashboardColoredProgressCircle
                        percent={totalPercentBranded}
                        strokeWidth={10}
                        width={200}
                    />
                </Col>}
            </Row>
        </StyledNpiDisplayLayoutContent>

        {/*Modal to bulk upload*/}
        <Modal {...modalBulkConfig}>
            {!isWW && <NpiModalTemplateUploader
                textContent={<div>
                    {t('INTERNAL.CLIENT.DASHBOARD.ACCEPTED_COLUMN_CONTENT')}:
                    <ul>
                        <li><b>{t('INTERNAL.CLIENT.COMMON.HQ_ID')}</b>: {t('INTERNAL.CLIENT.DASHBOARD.OPTIONAL_COLUMN')}</li>
                        <li><b>{t('INTERNAL.CLIENT.COMMON.PARTNER_NAME')}</b>: {t('INTERNAL.CLIENT.DASHBOARD.OPTIONAL_COLUMN')}</li>
                        <li><b>{t('INTERNAL.CLIENT.COMMON.COUNTRY_NAME')}</b>: {t('INTERNAL.CLIENT.DASHBOARD.OPTIONAL_COLUMN')}</li>
                        <li><b>{t('INTERNAL.CLIENT.COMMON.COUNTRY_CODE')}</b>: {t('INTERNAL.CLIENT.DASHBOARD.COUNTRY_CODE_COLUMN')}</li>
                        <li><b>{t('INTERNAL.CLIENT.COMMON.BRANDED')}</b>: {t('INTERNAL.CLIENT.DASHBOARD.BRANDED_COLUMN')}</li>
                        <li><b>{t('INTERNAL.CLIENT.NPI.KPI')}</b>: {t('INTERNAL.CLIENT.DASHBOARD.KPI_COLUMN')}</li>
                        <li><b>{t('INTERNAL.CLIENT.DASHBOARD.TOTAL_STORES')}</b>: {t('INTERNAL.CLIENT.DASHBOARD.TOTAL_STORES_COLUMN')}</li>
                        <li><b>{t('INTERNAL.CLIENT.DASHBOARD.STORES_READY')}</b>: {t('INTERNAL.CLIENT.DASHBOARD.STORES_READY_COLUMN')}</li>
                        <li><b>{t('INTERNAL.CLIENT.DASHBOARD.BTC')}</b>: {t('INTERNAL.CLIENT.DASHBOARD.BTC_COLUMN')}</li>
                    </ul>
                </div>}
                textConfirm={t('INTERNAL.CLIENT.DASHBOARD.UPLOAD_TEXT_CONFIRM')}
                textDownloadButton={t('INTERNAL.CLIENT.DASHBOARD.DOWNLOAD_TEMPLATE')}
                axiosDownload={exportTemplate}
                axiosUpload={importTemplate}
                onChange={() => onApplyFilters({})}
                filename={`template-bulk-ww-dashboard-${dashboardId}-${nowFormat()}.xlsx`}
            />}
            {isWW && <Alert type="error" message={t('INTERNAL.CLIENT.DASHBOARD.FEATURE_NOT_FOR_WW')}/>}
        </Modal>

        <Modal {...modalConfigureConfig}>
            <Form labelCol={{span: 12}}>
                <Form.Item label={t('INTERNAL.CLIENT.DASHBOARD.TOTAL_READINESS_COLORS')}>
                    <NpiInputColorPickerDetailed value={configuration.colorBars?.[0]} onChange={(color:any) => onChangeColor(color, 0)}/>
                {/*</Form.Item>*/}
                {/*<Form.Item label="Total readiness comparison color 1">*/}
                    <NpiInputColorPickerDetailed value={configuration.colorBars?.[1]} onChange={(color:any) => onChangeColor(color, 1)}/>
                {/*</Form.Item>*/}
                {/*<Form.Item label="Total readiness comparison color 2">*/}
                    <NpiInputColorPickerDetailed value={configuration.colorBars?.[2]} onChange={(color:any) => onChangeColor(color, 2)}/>
                </Form.Item>
            </Form>
            <NpiSortTable {...tableConfigureKpisConfig} />
        </Modal>
    </NpiDisplayLayoutContent>
};

// darkMode
const StyledNpiDisplayLayoutContent = styled(NpiDisplayLayoutContent)<{ darkMode?: boolean }>`
  padding: 0;

  ${props => !!props.darkMode && css`
    background: #000;
    color: white;

    .ant-table-wrapper {
      .ant-table {
        color: #fff;
        background: #000;

        .gap-white.ant-table-cell {
          background-color: #000 !important;
        }
        
        .ant-table-cell, .ant-empty {
          color: #fff;
        }
        
        .sub_line .ant-table-cell, .muted {
          color: rgba(255,255,255, 0.7);
        }

        .ant-table-thead .ant-table-cell {
          background: #222;
        }
        .ant-table-tbody {
            .ant-table-cell, .ant-empty, .ant-progress {
              background: #000;
              .progress-container .trail {
                background: rgba(255,255,255, 0.15);
              }
            }
        }
      }
      
      &.print {
        .ant-table-tbody {
            .ant-table-cell, .ant-empty, .ant-progress {
              background: #222;
              .progress-container .trail {
                background: rgba(255,255,255, 0.06);
              }
            }
        }
      }
    }

    .ant-typography {
      color: #fff;
    }

    .ant-progress {
      background: #000;
      .ant-progress-text {
        color: #fff;
      }
    }
  `};
`

export default NpiContainerWwDashboard;