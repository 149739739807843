import React from "react";
import styled, {css} from "styled-components";

const StyledContainer = styled('div')<{ printMode?: boolean }>`
  min-height: 40px;
  height: auto;
  width: 100%;
  ${props => !props.printMode && css`
    background-color: white;  
  `};
  .title {
    text-align: center;
    min-height: 100%;
  }
`;

/**
 * @param value
 * @param fontSize
 * @param printMode
 * @constructor
 */
const NpiDisplayKpiTitle = ({value="Dashboard Title", fontSize=30, printMode=false}:any) => {
  return <StyledContainer printMode={printMode}>
    <div className={"title"} style={{fontSize: `${fontSize}px`}}>{value}</div>
  </StyledContainer>
};
export default NpiDisplayKpiTitle;