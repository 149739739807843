import {
    IFormAnswer,
    IQuestion,
    IQuestionAnswer,
    IQuestionAnswerDirectionEnum,
    IQuestionTypeEnum,
    IWithQuestions
} from "../types/question";
import _, {isArray, isNaN, lowerCase} from "lodash";
import {
    ICondition,
    IConditionActionDisplayEnum,
    IConditionActionTargetEnum, IConditionRule,
    IConditionRuleStateEnum,
    IConditionRuleTargetEnum,
    IConditionRulingEnum
} from "../types/condition";
import {ITagValue} from "../types/tag";
import {toJS} from "mobx";


interface IValidateWaveAnswersResponse {
    requiredAnswers: IQuestion[],
    requiredDates: IQuestion[]
}

const {LIST, MULTILIST, BOOL} = IQuestionTypeEnum;

/**
 * Validating the form by checking if all mandatory requirements are correctly filled and returning questions in error
 */
export const validateFormQuestionAnswered = (questions: IQuestion[], answers: IFormAnswer[]): IValidateWaveAnswersResponse => {

    //check if all mandatory questions is fully filled
    const mandatoryQuestions = questions.filter(question => question.is_mandatory);

    //get all answered questions ids
    const answeredQuestionsIds = answers
        .filter(answer => (
            answer.value !== null
            && answer.value !== '' //these we consider empty too
            && !isNaN(answer.value) //empty numerical value
            && (Array.isArray(answer.value) ? !!answer.value.length : true)
        ))
        .map(answer => answer.question_id);

    //retrieve all mandatory questions without any answers
    const questionsMissingRequiredAnswers = mandatoryQuestions.filter(question => !answeredQuestionsIds.includes(question.id));

    //retrieve all answered questions with empty date value
    const questionsMissingRequiredDates = questions.filter(question =>
        question.is_date_required &&
        answers.find(answer => answer.question_id === question.id && !answer.date_value)
    );


    return {
        requiredAnswers: questionsMissingRequiredAnswers,
        requiredDates: questionsMissingRequiredDates
    }
}

// do this ugly trick due to Answer PHP model not containing custom cast in order to handle both JSON and string cast
export const parseAnswersFormQuestion = (answers: IFormAnswer[], questions: IQuestion[]): IFormAnswer[] => {
    return answers.map((answer: IFormAnswer) => {

        const question = _.find(questions, {id: answer.question_id});

        if (question) {
            switch (question?.type) {
                case IQuestionTypeEnum.NUMERIC:
                    answer.value = parseInt(answer.value);
                    break;
                case IQuestionTypeEnum.BOOL:
                    const parsedIntValue = parseInt(answer.value);

                    if (!isNaN(parsedIntValue)) {
                        answer.value = parsedIntValue;
                    }
                    break;
                case IQuestionTypeEnum.MULTILIST:
                    try {
                        answer.value = isArray(answer.value) ? answer.value : JSON.parse(answer.value);
                    } catch (e) {
                        console.error(e);
                    }

                    break;
                default:
                    break;
            }
        }
        return answer;
    })
}



const {ALL, ANY} = IConditionRulingEnum;
const {EMPTY, EQUAL, GREATER_THAN, LOWER_THAN, NEGATIVE, NEUTRAL, NOT_EMPTY, NOT_EQUAL, POSITIVE, TAG_PRESENT, TAG_NOT_PRESENT, TAG_EQUAL, TAG_NOT_EQUAL, MERCHANDISING_GROUP_EQUAL, MERCHANDISING_GROUP_NOT_EQUAL, POS_FIELD_EQUAL, POS_FIELD_NOT_EQUAL, POS_FIELD_CONTAIN, POS_FIELD_NOT_CONTAIN} = IConditionRuleStateEnum;
const {ANSWER, QUESTION} = IConditionActionTargetEnum;
const {TAG:RULE_TARGET_TAG, QUESTION:RULE_TARGET_QUESTION, MERCHANDISING_GROUP:RULE_TARGET_MERCHANDISING_GROUP, POS_FIELD:RULE_TARGET_POS_FIELD} = IConditionRuleTargetEnum;

//Shortcuts for comparison, makes it more readable in the component
const comparator = {
    equals: (aValue:any, rValue:any) => (_.isArray(aValue) ?  _.intersection(rValue, aValue).length>0 : rValue.indexOf(aValue)>=0),
    empty: (aValue:any) => aValue === null,
    direction: (aValue:any, answers:IQuestionAnswer[], direction:IQuestionAnswerDirectionEnum) => {
        return _.find(answers, {value: aValue})?.direction === direction;
    },
    greater: (aValue:any, rValue:any) => aValue > rValue,
    lower: (aValue:any, rValue:any) => aValue < rValue,
};

/**
 * This method is responsible for all the logic of display:
 * - Hiding questions because of conditions
 * - Hiding answers because of conditions
 * - Hiding questions because of a blocking question
 * - TODO: applying default_value when necessary (might be put elsewhere)
 * @param item
 * @param answers
 * @param tags
 * @param fields
 */
export const applyConditions = (item: IWithQuestions, answers:IFormAnswer[], tags ?: ITagValue, fields?:any) => {

    const questions: IQuestion[] = toJS(item.questions);
    const conditions: ICondition[] = toJS(item.conditions);

    if( ! questions.length) return false;

    //Ref questions by their id for easier reading
    const questionsById = _.keyBy(questions, 'id');
    const answersById = _.keyBy(answers, 'question_id');

    //Reset stored hidden answers
    _.forEach(questionsById, (q) => {
        //We must check if the currently selected answer is hidden, in which case we have to unselect it for "Select" type input
        if(answersById[q.id]) {
            //If single list, we clear the hidden
            const answer = answersById[q.id];
            if([LIST, BOOL].indexOf(q.type)>=0 && _.find(q.config.answers, {value: answer.value})?.hidden === true){
                answer.value = null;
            }
            //If multi list, we clear the one that would be hidden
            else if([MULTILIST].indexOf(q.type) >=0 && _.isArray(answer.value)){
                answer.value = answer.value.filter(value => _.find(q.config.answers, {value})?.hidden !== true);
            }
        }
    });

    // !! Fix an issue in some specific cases, when some questions have a "default value" when hidden by a condition.
    // If this condition is applied AFTER other conditions which depend on this question value,
    // then those other conditions will be tested on the wrong value (on "empty value", instead of "default value")
    // To fix it, the idea is to re-test the conditions while a hidden state has changed. (with a limit to prevent infinite loop)
    let run = 0;
    let defaultValueHasChanged = false;
    const maxRuns = 10;
    do {
        run++;
        defaultValueHasChanged = false;

        //Reset hidden questions, hidden answers
        _.forEach(questionsById, (q) => {
            q.hiddenInForm = false;

            //Clearing the hidden in form status
            if( q.config.answers ){
                _.forEach(q.config.answers, (a) => a.hiddenInForm = false)
            }
        });


        //Loop on each condition to apply it
        conditions?.forEach(condition => { //toJS for easier debugging but not necessary

            const match = isConditionMatch(condition, questionsById, answersById, fields, tags);

            // Apply our hide/show actions
            condition.actions.forEach(({target, questionId, answerId, display = IConditionActionDisplayEnum.HIDE}) => {
                const doHide = (match && display === IConditionActionDisplayEnum.HIDE) || (!match && display === IConditionActionDisplayEnum.SHOW);
                if( doHide ) {
                    //We need to hide a question
                    if(target === QUESTION && questionId && questionsById[questionId]) {
                        questionsById[questionId].hiddenInForm = true;

                        // -- Apply default value
                        const previousAnswer = answersById[questionId] ?? undefined;
                        if(questionsById[questionId].default_value) {

                            if( ! answersById[questionId]){
                                const newAnswer = {
                                    value: questionsById[questionId].default_value,
                                    date_value: null,
                                    question_id: questionId,
                                };
                                answersById[questionId] = newAnswer;
                                answers.push(newAnswer);
                            }
                            else{
                                answersById[questionId].value = questionsById[questionId].default_value;
                            }

                        } else if(answersById[questionId]){
                            //Or reset the answer
                            answersById[questionId].value = null;
                        }
                        if (previousAnswer !== answersById[questionId]) {
                            defaultValueHasChanged = true;
                        }
                    }

                    //We need to hide an answer
                    if(target === ANSWER && answerId){
                        const [qId, aValue] = answerId;
                        if(questionsById[qId] && questionsById[qId].config.answers){
                            const foundQuestionAnswer = _.find(questionsById[qId].config.answers, {value: aValue});
                            if(foundQuestionAnswer){
                                foundQuestionAnswer.hiddenInForm = true;

                                //Now we clear the answer if it was selected
                                if(answersById[qId] && answersById[qId].value === aValue){
                                    answersById[qId].value = null;
                                }
                            }
                        }
                    }
                }
            })
        });

    } while(defaultValueHasChanged && run < maxRuns)

    let blockedAt = null;
    //Loop on each question to apply the blocking, if any
    for(let i=0;i<questions.length;i++){
        if(questions[i].is_blocking && !questions[i].hiddenInForm && (
            ! answersById[questions[i].id]
            || answersById[questions[i].id].value === null
            || answersById[questions[i].id].value.length === 0
        )){
            blockedAt = i+1;
            break;
        }
    }

    return {questions: [...(blockedAt === null ? questions : questions.slice(0, blockedAt))], answers: [...answers]};
};

/**
 * Check if a condition is matching
 */
const isConditionMatch = (
    condition: ICondition,
    questionsById: _.Dictionary<IQuestion>,
    answersById: _.Dictionary<IFormAnswer>,
    fields: any,
    tags: ITagValue | undefined,
): boolean => {
    const {ruling, rules} = condition;

    const passed:number[] = [];
    rules.forEach((rule) => {
        const rulePassed = isRulePassed(rule, questionsById, answersById, fields, tags);
        if (rulePassed !== null) {
            passed.push(rulePassed);
        }
    });

    //Check if the ruling is passing
    const sumPassed = _.sum(passed);

    let match = true;
    if(ruling === ALL && rules.length !== sumPassed) match = false;
    if(ruling === ANY && sumPassed === 0) match = false;
    return match;
}

/**
 * Return 1 if a single condition's rule passed, 0 if it failed
 * Return null when bad configuration
 */
const isRulePassed = (
    rule: IConditionRule,
    questionsById: _.Dictionary<IQuestion>,
    answersById: _.Dictionary<IFormAnswer>,
    fields: any,
    tags: ITagValue | undefined,
): null|0|1 => {

    const {target, state, questionId, value:ruleValue, tagValue, posField} = rule;

    if( target === RULE_TARGET_QUESTION) {
        if(! questionId || !questionsById[questionId]) return null;

        //Retrieve everything needed: type of question, value of answer (or null if no answer) and value to compare to (ruleValue)
        const {type, config} = questionsById[questionId];
        const {value:answerValue} = answersById[questionId] ?? {value: null};

        //Compare rules
        if(state === EQUAL && _.isArray(ruleValue)) return comparator.equals(answerValue, ruleValue) ? 1 : 0;
        if(state === NOT_EQUAL && _.isArray(ruleValue)) return ! comparator.equals(answerValue, ruleValue) ? 1 : 0;
        if(state === EMPTY) return comparator.empty(answerValue) ? 1 : 0;
        if(state === NOT_EMPTY) return  ! comparator.empty(answerValue) ? 1 : 0;
        if(state === POSITIVE && config.answers) return comparator.direction(answerValue, config.answers, IQuestionAnswerDirectionEnum.POSITIVE) ? 1 : 0;
        if(state === NEGATIVE && config.answers) return comparator.direction(answerValue, config.answers, IQuestionAnswerDirectionEnum.NEGATIVE) ? 1 : 0;
        if(state === NEUTRAL && config.answers) return comparator.direction(answerValue, config.answers, IQuestionAnswerDirectionEnum.NEUTRAL) ? 1 : 0;
        if(state === GREATER_THAN) return comparator.greater(answerValue, ruleValue) ? 1 : 0;
        if(state === LOWER_THAN) return comparator.lower(answerValue, ruleValue) ? 1 : 0;

        console.warn('Wrong setup for the question condition', {questionId, state, type, answerValue, ruleValue})
        return null;
    }
    else if(target === RULE_TARGET_TAG){
        const noTags = _.isEmpty(tags);
        const tId = Number(ruleValue);

        if(state === TAG_PRESENT) return tags && tags[tId] ? 1 : 0;
        if(state === TAG_NOT_PRESENT) return noTags || (tags && !tags[tId]) ? 1 : 0;
        if(state === TAG_EQUAL) return tags && tags[tId] === tagValue ? 1 : 0;
        if(state === TAG_NOT_EQUAL) return noTags || (tags && tags[tId] !== tagValue) ? 1 : 0;

        console.warn('Wrong setup for the tag condition', {tags, ruleValue});
        return null;
    }
    else if(target === RULE_TARGET_MERCHANDISING_GROUP){
        const itemGroupId = fields.merchandising_group_id;
        const gId = Number(ruleValue);

        if(state === MERCHANDISING_GROUP_EQUAL) return itemGroupId === gId ? 1 : 0;
        if(state === MERCHANDISING_GROUP_NOT_EQUAL) return itemGroupId !== gId ? 1 : 0;

        console.warn('Wrong setup for the merchandising group condition', {itemGroupId, ruleValue});
        return null;
    }
    else if(target === RULE_TARGET_POS_FIELD){
        const fieldValue = (posField && _.isString(fields[posField]) ? lowerCase(fields[posField]) : null) as any;
        const lcRuleValue = lowerCase(ruleValue as string);

        if(state === POS_FIELD_EQUAL) return fieldValue === lcRuleValue ? 1:0;
        if(state === POS_FIELD_NOT_EQUAL) return fieldValue !== lcRuleValue ? 1:0;
        if(state === POS_FIELD_CONTAIN) return fieldValue?.includes(lcRuleValue) ? 1:0;
        if(state === POS_FIELD_NOT_CONTAIN) return !fieldValue?.includes(lcRuleValue) ? 1:0;

        console.warn('Wrong setup for the pos field condition', {posField, ruleValue, fieldValue, state});
        return null;
    }

    console.warn('Wrong target for the condition');
    return null;
}