import {useTranslation} from "react-i18next";
import {validateFormQuestionAnswered} from "../helpers/form";
import React, {Dispatch, Key, ReactNode, useCallback} from "react";
import {IFormAnswer, IQuestion} from "../types/question";
import {INpiInputQuestionError} from "../components/input/question";

interface SurveyErrors {
    tree: {key: Key, title: ReactNode, selectable?: boolean}[],
    form: INpiInputQuestionError[]
}

export default function useSurveyErrors() {
    const {t} = useTranslation();

    const getFormErrors = useCallback((nonHiddenQuestions: IQuestion[], answers: IFormAnswer[], onErrorClick?: Dispatch<IQuestion>): SurveyErrors => {
        const {requiredAnswers, requiredDates} = validateFormQuestionAnswered(nonHiddenQuestions, answers);

        return {
            // return tree errors
            tree: [
                ...requiredAnswers.map(question => ({
                    key: `${question.wave_id}-${question.id}`,
                    selectable: false,
                    title: <p onClick={() => onErrorClick?.(question)}>
                        {question.display_name} <br/>
                        <small style={{color: "red", fontWeight: 'bold'}}>{t('EXTERNAL.CLIENT.POS_VIEW.ERROR_MANDATORY_QUESTION')}</small>
                    </p>,
                })),
                ...requiredDates.map(question => ({
                    key: `${question.wave_id}-${question.id}`,
                    selectable: false,
                    title: <p onClick={() => onErrorClick?.(question)}>
                        {question.display_name} <br/>
                        <small style={{color: "red", fontWeight: 'bold'}}>{t('EXTERNAL.CLIENT.POS_VIEW.ERROR_DATE_REQUIRED_QUESTION')}</small>
                    </p>,
                }))
            ],

            // form errors
            form: [
                ...requiredAnswers.map(question => ({
                    question_id: question.id,
                    wave_id: question.wave_id,
                    message: t('EXTERNAL.CLIENT.POS_VIEW.ERROR_MANDATORY_QUESTION')
                })),
                ...requiredDates.map(question => ({
                    question_id: question.id,
                    wave_id: question.wave_id,
                    message: t('EXTERNAL.CLIENT.POS_VIEW.ERROR_DATE_REQUIRED_QUESTION')
                })),
            ],
        };
    }, [t])

    return {getFormErrors};
}