/**
 * Enum representing the possible value for REACT_APP_VIEW_MODE env variable
 */
enum ViewModeEnum {
    DEV = "DEV",
    EXTERNAL = "EXTERNAL",
    INTERNAL = "INTERNAL"
}

/**
 * Enum that represent the possible views
 */
enum ViewTypeEnum {
    NONE = "NONE",
    EXTERNAL = "EXTERNAL",
    INTERNAL = "INTERNAL"
}

export {
    ViewModeEnum,
    ViewTypeEnum
}