import React, {useEffect, useState} from 'react';
import {FilterOutlined} from "@ant-design/icons";
import NpiInputSelectNpi from "../input/select-npi";
import NpiInputSelectWave from "../input/select-wave";
import NpiInputSelectProgram from "../input/select-program";
import styled from "styled-components";
import {Button, Drawer, Form, Space} from "antd";
import NpiInputSelectWavePos from "../input/select-wavepos";
import NpiInputSelectQuestion from "../input/select-question";
import NpiInputSelectCountry from "../input/select-country";
import {useRequest} from "ahooks";
import npiApi from "../../services/api";
import {IWavePos} from "../../types/wave-pos";
import NpiInputSelectTagsAndValues from "../input/select-tags-and-values";
import {useTranslation} from "react-i18next";
import useAcl from "../../hooks/use-acl";
import {IRightsEnum} from "../../types/user";

const StyledFilters = styled(Drawer)`
  .ant-row {
    margin-bottom: 15px;
    .ant-form-item-label {
      font-weight: bold;
    }
  }
`;

export interface IImageFilter {
    npis: number[],
    waves: number[],
    pos: number[],
    programs: number[],
    countries: number[],
    partners: number[],
    tags: any[],
    questions: number[],
    only_answers: boolean,
}

export const defaultImageFilters:IImageFilter = {
    npis: [], waves: [], pos: [], programs: [], countries: [], partners: [], tags: [], questions: [], only_answers: false
}

export const areEmptyFilters = (filters:IImageFilter) : boolean => {
    let empty = true;
    Object.entries(filters).forEach(([key, value]) => {
        if( key === 'only_answers' ) {
            if( value ){
                empty = false;
            }
        } else if( value.length !== 0) {
            empty = false;
        }
    });
    return empty;
};

const NpiImageFilters = ({value, onSubmit, ...props}:any) => {

    const {t} = useTranslation();
    const canViewSensitive = useAcl().hasRight(IRightsEnum.VIEW_SENSITIVE_DATA);

    // we don't want our gallery images to refresh on every change but only on submit (so no controllableValue)
    const [localState, setLocalState] = useState<IImageFilter>(value);

    // TODO region list except WW (from npis or waves) -> use to filters program or country list
    // const [regionIds, setRegionIds] = useState<number[]>([]);

    // use for partner and POS filter init
    const {runAsync: fetchWavePos} = useRequest<IWavePos[],any>(npiApi.internal.wavepos.search, {manual: true});

    // Display lateral filters panel
    const [showFilters, setShowFilters] = useState(false);
    const [allEmpty, setAllEmpty] = useState<boolean>(areEmptyFilters(localState));

    useEffect(() => {
        setAllEmpty(areEmptyFilters(localState));
    }, [localState, setAllEmpty]);


    useEffect(() => {
        setLocalState(value)
    }, [value, setLocalState]);

    // Click on Submit button
    const submitFilters = () => {
        if( !allEmpty ){
            onSubmit?.(localState);
        }
    }

    // Reset all fitlers
    const resetFilters = () => {
        setLocalState(defaultImageFilters);
        setAllEmpty(areEmptyFilters(defaultImageFilters));
    }

    // Update Npi : reset wave and wave_pos
    const changeFilterNpi = (value: any) => {
        const newState = {
            ...localState,
            npis: [value],
            waves: [],
            partners: [],
            pos: [],
            questions: [],
        };
        setLocalState(newState);
        setAllEmpty(areEmptyFilters(newState));
    }

    // Update Wave : reset wave_pos
    const changeFilterWaves = (value: any[]) => {
        const newState = {
            ...localState,
            waves: value,
            partners: [],
            pos: [],
            questions: [],
        };
        setLocalState(newState);
        setAllEmpty(areEmptyFilters(newState));
    }

    // Update other filters
    const changeFilterByKey = (value: any, key: keyof IImageFilter) => {
        if( key === 'only_answers' ){
            localState[key] = value as boolean;
        } else {
            localState[key] = value as any[];
        }
        const newState = {...localState};
        setLocalState(newState);
        setAllEmpty(areEmptyFilters(newState));
    }

    const multiSelectProps : any = {
        mode: 'multiple',
        allowClear: true,
        dropdownMatchSelectWidth: false,
    };

    return <>
        <Button type="primary" onClick={() => setShowFilters(true)} icon={<FilterOutlined />}>{t('INTERNAL.CLIENT.GALLERY.SHOW_FILTERS')}</Button>

        <StyledFilters title={t('INTERNAL.CLIENT.COMMON.FILTERS')} placement="right" onClose={() => setShowFilters(false)} open={showFilters} width={400}>

            <Form layout="vertical">

                <Form.Item label={t('INTERNAL.CLIENT.NPI.NPI')} required>
                    <NpiInputSelectNpi value={localState.npis} onChange={changeFilterNpi}/>
                </Form.Item>

                <Form.Item label={t('INTERNAL.CLIENT.NPI.WAVES')}>
                    <NpiInputSelectWave value={localState.waves} onChange={changeFilterWaves} npis={localState.npis} {...multiSelectProps}
                                        display={(wave) => `${wave.id} - ${wave.name}`}
                                        disabled={localState.npis.length === 0}/>
                </Form.Item>

                <Form.Item label={t('INTERNAL.CLIENT.COMMON.PROGRAMS')}>
                    <NpiInputSelectProgram value={localState.programs} onChange={(v) => changeFilterByKey(v, 'programs')} {...multiSelectProps}/>
                </Form.Item>

                <Form.Item label={t('INTERNAL.CLIENT.COMMON.COUNTRIES')}>
                    <NpiInputSelectCountry value={localState.countries} onChange={(v) => changeFilterByKey(v, 'countries')} restrict {...multiSelectProps}/>
                </Form.Item>

                <Form.Item label={t('INTERNAL.CLIENT.COMMON.PARTNERS')}>
                    <NpiInputSelectWavePos value={localState.partners} onChange={(v) => changeFilterByKey(v, 'partners')}
                                           fetchInitial={value => fetchWavePos({partners: value})}
                                           filters={{
                                               is_partner: true,
                                               npis: localState.npis,
                                               waves: localState.waves,
                                               programs: localState.programs,
                                               countries: localState.countries,
                                               tags: localState.tags,
                                           }}
                                           placeholder={t('INTERNAL.CLIENT.COMMON.SELECT_PARTNERS')}
                                           {...multiSelectProps}/>
                </Form.Item>

                {canViewSensitive && <Form.Item label={t('INTERNAL.CLIENT.COMMON.TAGS')}>
                    <NpiInputSelectTagsAndValues value={localState.tags} onChange={(v) => changeFilterByKey(v, 'tags')}
                                                 waves={localState.waves} npis={localState.npis} allowClear/>
                </Form.Item>}

                <Form.Item label={t('INTERNAL.CLIENT.COMMON.POS')}>
                    <NpiInputSelectWavePos value={localState.pos} onChange={(v) => changeFilterByKey(v, 'pos')}
                                           mappedBy={wp => wp.pos_id}
                                           display={wp => `${wp.pos_id} - ${wp.f_trade_name || wp.f_legal_name}`}
                                           fetchInitial={value => fetchWavePos({group_by_pos: true, pos: value})}
                                           filters={{
                                               npis: localState.npis,
                                               waves: localState.waves,
                                               programs: localState.programs,
                                               countries: localState.countries,
                                               partners: localState.partners,
                                               tags: localState.tags,
                                               group_by_pos: true,
                                           }}
                                           {...multiSelectProps}/>
                </Form.Item>

                <Form.Item label={t('INTERNAL.CLIENT.COMMON.QUESTIONS')}>
                    <NpiInputSelectQuestion value={localState.questions} onChange={(v) => changeFilterByKey(v, 'questions')}
                                            npis={localState.npis}
                                            waves={localState.waves}
                                            disabled={localState.waves.length === 0}
                                            {...multiSelectProps}/>
                </Form.Item>

                {/*<Form.Item label="Only images that are answers">*/}
                {/*    <Switch checked={localState.only_answers} onChange={(v) => changeFilterByKey(v, 'only_answers')}/>*/}
                {/*</Form.Item>*/}

                <Form.Item>
                    <Space>
                        <Button type={'primary'} onClick={submitFilters} disabled={allEmpty}>{t('INTERNAL.CLIENT.GALLERY.SUBMIT_FILTERS')}</Button>
                        <Button type={'default'} onClick={resetFilters} disabled={allEmpty}>{t('INTERNAL.CLIENT.GALLERY.CLEAR_FILTERS')}</Button>
                    </Space>
                </Form.Item>

            </Form>

        </StyledFilters>
    </>
}

export default NpiImageFilters;