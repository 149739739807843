import React, {useCallback, useState} from 'react';
import Modal, {ModalProps} from "antd/lib/modal";
import npiApi from "../../services/api";
import NpiPaginatedTable from "../paginated-table";
import {Select, Form, Space, Button, notification} from "antd";
import {CloseOutlined, EditOutlined, SaveOutlined} from "@ant-design/icons";
import _ from "lodash";
import {IShipment, IShipmentCarrier} from "../../types/shipment";
import NpiDisplayDate from "../display/date";
import {useTranslation} from "react-i18next";
import {useRequest} from "ahooks";
import ArrowTopRightThinCircleOutline from "@2fd/ant-design-icons/lib/ArrowTopRightThinCircleOutline";

const { Option } = Select;

interface INpiWaveTableReportModalShipment extends ModalProps {
    row: any
    onClose: (row: any) => void
    regionId: number
}

const NpiWaveTableReportModalShipment = ({row, onClose, regionId, ...props}:INpiWaveTableReportModalShipment) => {
    const {t} = useTranslation();

    const {data: carriers} = useRequest<IShipmentCarrier[], any>(npiApi.internal.shipments.carrier.list, {defaultParams: [{region_id: regionId}]});

    const [loadingFetch, setLoadingFetch] = useState<boolean>(false);
    const [shipmentCode, setShipmentCode] = useState<string>('');
    const [shipments, setShipments] = useState<IShipment[]>([]);

    // EDIT Row
    const [saving, setSaving] = useState<boolean>(false);
    const [editingKey, setEditingKey] = useState<number|null>(null);
    const [form] = Form.useForm();
    const isEditing = (id:number) => id === editingKey;
    const switchEditRow = (shipment: IShipment|null) => {
        if(shipment){
            setEditingKey(shipment.id);
            form.setFieldsValue(shipment);
        } else {
            setEditingKey(null);
        }
    };

    const saveRow = (id:number) => {
        const values = form.getFieldsValue();
        setSaving(true);
        npiApi.internal.shipments.updateFromInternal({id: id, ...values})
            .then((ship:IShipment) => {
                setEditingKey(null);
                // update list with new datas
                const found = _.find(shipments, {id: ship.id});
                if(found){
                    shipments.splice(shipments.indexOf(found), 1, ship);
                    setShipments([...shipments]);
                }
            })
            .then(() => notification.success({message:t('INTERNAL.CLIENT.COMMON.LINE_UPDATED')}))
            .finally(() => setSaving(false))
    }


    const fetcher = useCallback((params) => {
        setLoadingFetch(true);
        return npiApi.internal.wavepos.shipments({id: row.id, ...params})
            .then((data: {shipment_code:string, shipments:any}) => {
                setShipmentCode(data.shipment_code || '');
                setShipments(data.shipments.data);
                return data.shipments;
            })
            .finally(() => setLoadingFetch(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [row]);

    const tableColumns = [
        {title: t('COMMON.TRACKING_ID'), dataIndex: 'tracking_id', render: (trackingId: string, row: any) => {
            const carrier = _.find(carriers, {carrier_name: row.carrier});
            return <>
                {trackingId}
                {!!carrier
                    && <Button href={carrier.tracking_url.replace('%code%', row.tracking_id)} type="link" icon={<ArrowTopRightThinCircleOutline/>} target={"_blank"} rel={"noreferrer"}/>}
            </>;
        }},
        {title: t('INTERNAL.CLIENT.SHIPMENT.CARRIER'), dataIndex: 'carrier'},
        {title: t('INTERNAL.CLIENT.SHIPMENT.MATERIAL_NUMBER'), dataIndex: 'material_number'},
        {title: t('INTERNAL.CLIENT.SHIPMENT.MATERIAL_QUANTITY'), dataIndex: 'material_quantity'},
        {title: t('INTERNAL.CLIENT.COMMON.STATUS'), dataIndex: 'status', render: (status:string, shipment:IShipment) => !isEditing(shipment.id) ? status : <Form.Item name="status" label={t('INTERNAL.CLIENT.COMMON.STATUS')} style={{ margin: 0 }}>
            <Select>
                <Option value="received">{t('INTERNAL.CLIENT.SHIPMENT.SHIPMENT_STATUS.RECEIVED')}</Option>
                <Option value="not_received">{t('INTERNAL.CLIENT.SHIPMENT.SHIPMENT_STATUS.NOT_RECEIVED')}</Option>
                <Option value="missing">{t('INTERNAL.CLIENT.SHIPMENT.SHIPMENT_STATUS.MISSING')}</Option>
                <Option value="damaged">{t('INTERNAL.CLIENT.SHIPMENT.SHIPMENT_STATUS.DAMAGED')}</Option>
                <Option value="wrong">{t('INTERNAL.CLIENT.SHIPMENT.SHIPMENT_STATUS.WRONG')}</Option>
            </Select>
        </Form.Item>},
        {title: 'Status Updated At', dataIndex: 'status_updated_at', render: (updated_at:string) => !!updated_at && <NpiDisplayDate value={updated_at} hours={'inline'}/>},

        {title: t('INTERNAL.CLIENT.COMMON.EDIT'), render: (shipment:IShipment) => isEditing(shipment.id)
            // SAVE or CANCEL
            ? <Space size={'middle'}>
                <Button onClick={() => saveRow(shipment.id)} title={t('COMMON.SAVE')} icon={<SaveOutlined/>} size={'small'} type={'primary'} loading={saving}/>
                <Button onClick={() => switchEditRow(null)} title={t('COMMON.CANCEL')} icon={<CloseOutlined/>} size={'small'}/>
            </Space>
            // EDIT
            : <Button onClick={() => switchEditRow(shipment)} title={t('INTERNAL.CLIENT.COMMON.EDIT')} icon={<EditOutlined/>} size={'small'}/>
        },
    ];

    //Table configuration
    const tableConfig = {
        loading: loadingFetch,
        dataSource: shipments,
        fetcher,
        columns: tableColumns,
        pagination: {
            hideOnSinglePage: true,
            pageSizeOptions: ['30', '50', '100', '200'],
            defaultPageSize: 30,
        },
    };

    //Modal configuration
    const modalConfig = {
        title: <span>{t('INTERNAL.CLIENT.COMMON.SHIPMENTS')} - {shipmentCode} - {row.name} (#{row.pos_id})</span>,
        width: 1000,
        footer: null,
        onCancel: () => onClose(row),
        ...props,
    };

    return <Modal {...modalConfig}>
        <Form form={form} component={false}>
            <NpiPaginatedTable {...tableConfig}/>
        </Form>
    </Modal>
};

export default NpiWaveTableReportModalShipment;