import moment from "moment";
import React, {useMemo} from "react";
import {Select} from "antd";
import {useControllableValue} from "ahooks";
import {SelectProps} from "antd/lib/select";
import {timeZone} from "../../helpers/date";
import {useTranslation} from "react-i18next";


interface INpiWwDashboardInputSelectSnapshot extends SelectProps<any> {
    snapshots:any[]
    restrict?: boolean;
}

const NpiWwDashboardInputSelectSnapshot = ({snapshots, ...props}:INpiWwDashboardInputSelectSnapshot) => {
    const {value: propsValue, onChange: propsOnChange, ...remainingProps} = props;
    const [value, setValue] = useControllableValue(props);
    const {t} = useTranslation();

    const options = useMemo(() => {
        const result = [...snapshots.map(s => ({
            value: s.id,
            //TODO: change locale from en to current selected language
            label: moment(s.created_at).locale('en').format('ddd DD-MMM hh:00a') + ' (' + timeZone + ')',
        }))];

        result.push({value: 0, label: t('INTERNAL.CLIENT.COMMON.NOW')});

        return result;
    }, [snapshots, t]);

    if( ! snapshots.length){
        //If no snapshot available, nothing to display
        return <span/>;
    }
    return <Select {...remainingProps} mode="multiple" value={value} onChange={setValue} options={options} dropdownMatchSelectWidth={false} placeholder={t('INTERNAL.CLIENT.COMMON.SELECT_ONE_OR_MORE')}/>
};

export default NpiWwDashboardInputSelectSnapshot;