import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {BrowserRouter as Router} from "react-router-dom";
import reportWebVitals from './reportWebVitals';

import './App.css';
import { NpiLanguageContextProvider } from "./contexts/language-context";

ReactDOM.render(
  <React.StrictMode>
      <Router basename={process.env.PUBLIC_URL}>
          <NpiLanguageContextProvider>
              <App />
          </NpiLanguageContextProvider>
      </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
