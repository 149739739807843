import {IQuestionTypeEnum} from "./question";

export enum IConditionRulingEnum{
    ANY='ANY',
    ALL='ALL',
}

export enum IConditionActionDisplayEnum{
    HIDE='HIDE',
    SHOW='SHOW',
}

export enum IConditionActionTargetEnum{
    QUESTION='QUESTION',
    ANSWER='ANSWER',
}

export enum IConditionRuleTargetEnum{
    QUESTION='QUESTION',
    STATUS='STATUS',
    RESCHEDULED='RESCHEDULED',
    TAG='TAG', //would be enabled later
    MERCHANDISING_GROUP='MERCHANDISING_GROUP',
    BLOCK='BLOCK', //a block of rules
    POS_FIELD='POS_FIELD', //a specific field from the wave_pos table
}

export enum IConditionRuleStateEnum{
    EQUAL='EQUAL',
    NOT_EQUAL='NOT_EQUAL',
    POSITIVE='POSITIVE',
    NEUTRAL='NEUTRAL',
    NEGATIVE='NEGATIVE',
    EMPTY='EMPTY',
    NOT_EMPTY='NOT_EMPTY',
    GREATER_THAN='GREATER_THAN',
    LOWER_THAN='LOWER_THAN',
    TAG_PRESENT='TAG_PRESENT',
    TAG_NOT_PRESENT='TAG_NOT_PRESENT',
    TAG_EQUAL='TAG_EQUAL',
    TAG_NOT_EQUAL='TAG_NOT_EQUAL',
    MERCHANDISING_GROUP_EQUAL='MERCHANDISING_GROUP_EQUAL',
    MERCHANDISING_GROUP_NOT_EQUAL='MERCHANDISING_GROUP_NOT_EQUAL',
    BLOCK_LOGIC_OR='BLOCK_LOGIC_OR',
    BLOCK_LOGIC_AND='BLOCK_LOGIC_AND',
    POS_FIELD_EQUAL='POS_FIELD_EQUAL',
    POS_FIELD_NOT_EQUAL='POS_FIELD_NOT_EQUAL',
    POS_FIELD_CONTAIN='POS_FIELD_CONTAIN',
    POS_FIELD_NOT_CONTAIN='POS_FIELD_NOT_CONTAIN',
}

const {LIST, MULTILIST, BOOL, NUMERIC, DATE, TEXT} = IQuestionTypeEnum;
const {BLOCK_LOGIC_OR, BLOCK_LOGIC_AND, EQUAL, LOWER_THAN, EMPTY, GREATER_THAN, NEGATIVE, NEUTRAL, NOT_EMPTY, NOT_EQUAL, POSITIVE, TAG_PRESENT, TAG_NOT_PRESENT, TAG_EQUAL, TAG_NOT_EQUAL, MERCHANDISING_GROUP_EQUAL, MERCHANDISING_GROUP_NOT_EQUAL, POS_FIELD_CONTAIN, POS_FIELD_EQUAL, POS_FIELD_NOT_CONTAIN, POS_FIELD_NOT_EQUAL} = IConditionRuleStateEnum;
const {BLOCK, QUESTION, RESCHEDULED, STATUS, TAG, MERCHANDISING_GROUP, POS_FIELD} = IConditionRuleTargetEnum;

export const rulesTargetTypes = {
    BLOCK: {
        value: BLOCK,
        label: 'Block',
        sentence: 'block',
    },
    QUESTION: {
        value: QUESTION,
        label: 'Question',
        sentence: 'question',
    },
    RESCHEDULED: {
        value: RESCHEDULED,
        label: 'Rescheduled',
        sentence: 'POS has been rescheduled',
    },
    STATUS: {
        value: STATUS,
        label: 'POS Status',
        sentence: 'POS Status',
    },
    TAG: {
        value: TAG,
        label: 'Tag',
        sentence: 'POS Tag',
    },
    MERCHANDISING_GROUP: {
        value: MERCHANDISING_GROUP,
        label: 'Merchandising Group',
        sentence: 'Merchandising Group',
    },
    POS_FIELD: {
        value: POS_FIELD,
        label: 'POS Field',
        sentence: 'POS Field',
    },
}

export const rulesStates = {
    EQUAL: {
        state: EQUAL,
        label: 'Is Equal',
        hasValue: true,
        types: [LIST, MULTILIST, BOOL]
    },
    NOT_EQUAL: {
        state: NOT_EQUAL,
        label: 'Is Not Equal',
        hasValue: true,
        types: [LIST, MULTILIST, BOOL]
    },
    POSITIVE: {
        state: POSITIVE,
        label: 'Is Positive',
        hasValue: false,
        types: [LIST, BOOL]
    },
    NEUTRAL: {
        state: NEUTRAL,
        label: 'Is Neutral',
        hasValue: false,
        types: [LIST]
    },
    NEGATIVE: {
        state: NEGATIVE,
        label: 'Is Negative',
        hasValue: false,
        types: [LIST, BOOL]
    },
    EMPTY: {
        state: EMPTY,
        label: 'Is Empty',
        hasValue: false,
        types: [LIST, MULTILIST, BOOL, TEXT, NUMERIC, DATE]
    },
    NOT_EMPTY: {
        state: NOT_EMPTY,
        label: 'Is Not Empty',
        hasValue: false,
        types: [LIST, MULTILIST, BOOL, TEXT, NUMERIC, DATE]
    },
    GREATER_THAN: {
        state: GREATER_THAN,
        label: 'Is Greater Than',
        hasValue: true,
        types: [NUMERIC]
    },
    LOWER_THAN: {
        state: LOWER_THAN,
        label: 'Is Lower Than',
        hasValue: true,
        types: [NUMERIC]
    },
    TAG_PRESENT: {
        state: TAG_PRESENT,
        label: 'Is present',
        hasValue: false,
        types: [] as IQuestionTypeEnum[]
    },
    TAG_NOT_PRESENT: {
        state: TAG_NOT_PRESENT,
        label: 'Is not present',
        hasValue: false,
        types: [] as IQuestionTypeEnum[]
    },
    TAG_EQUAL: {
        state: TAG_EQUAL,
        label: 'Is equal',
        hasValue: true,
        types: [] as IQuestionTypeEnum[]
    },
    TAG_NOT_EQUAL: {
        state: TAG_NOT_EQUAL,
        label: 'Is not equal',
        hasValue: true,
        types: [] as IQuestionTypeEnum[]
    },
    MERCHANDISING_GROUP_EQUAL: {
        state: MERCHANDISING_GROUP_EQUAL,
        label: 'Is equal',
        hasValue: false,
        types: [] as IQuestionTypeEnum[]
    },
    MERCHANDISING_GROUP_NOT_EQUAL: {
        state: MERCHANDISING_GROUP_NOT_EQUAL,
        label: 'Is not equal',
        hasValue: false,
        types: [] as IQuestionTypeEnum[]
    },
    BLOCK_LOGIC_OR: {
        state: BLOCK_LOGIC_OR,
        label: 'OR',
        hasValue: false,
        types: [] as IQuestionTypeEnum[]
    },
    BLOCK_LOGIC_AND: {
        state: BLOCK_LOGIC_AND,
        label: 'AND',
        hasValue: false,
        types: [] as IQuestionTypeEnum[]
    },
    POS_FIELD_EQUAL: {
        state: POS_FIELD_EQUAL,
        label: 'is equal',
        hasValue: false,
        types: [] as IQuestionTypeEnum[]
    },
    POS_FIELD_NOT_EQUAL: {
        state: POS_FIELD_NOT_EQUAL,
        label: 'is not equal',
        hasValue: false,
        types: [] as IQuestionTypeEnum[]
    },
    POS_FIELD_CONTAIN: {
        state: POS_FIELD_CONTAIN,
        label: 'contains',
        hasValue: false,
        types: [] as IQuestionTypeEnum[]
    },
    POS_FIELD_NOT_CONTAIN: {
        state: POS_FIELD_NOT_CONTAIN,
        label: 'does not contain',
        hasValue: false,
        types: [] as IQuestionTypeEnum[]
    },
};

export interface IConditionRule {
    target: IConditionRuleTargetEnum
    questionId: number| undefined
    state?: IConditionRuleStateEnum | undefined
    value?: string|number|string[]|IConditionRule[]
    tagValue?: string,
    posField?: string,
    errors?: string[]
}

export interface IConditionAction {
    display?: IConditionActionDisplayEnum //default if empty is "HIDE" (because of legacy)
    target?: IConditionActionTargetEnum
    questionId?: number
    answerId?: [number, string] //questionId followed by answer value
    errors?: string[]
}

export interface ICondition{
    id: number|null,
    rules: IConditionRule[]
    ruling: IConditionRulingEnum
    actions: IConditionAction[]
}