import {ModalProps} from "antd/lib/modal";
import {Button, Modal} from "antd";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import useExternalToken from "../../hooks/use-external-token";
import {useControllableValue} from "ahooks";
import {Dispatch} from "react";
import {useNpiExternalPosContext} from "../../contexts/external-pos-context";

interface IModalSurveySubmitSuccessProps extends ModalProps {
    imagesAllowed?: boolean
    setOpen?: Dispatch<boolean>
}
const ModalSurveySubmitSuccess = ({imagesAllowed, ...props}: IModalSurveySubmitSuccessProps) => {
    const {t} = useTranslation();
    const {hasReturnLink, getHomeLink, getPosLink} = useExternalToken();
    const {posId} = useNpiExternalPosContext();

    const [open, setOpen] = useControllableValue<boolean>(props, {
        valuePropName: 'open',
        trigger: 'setOpen',
        defaultValue: false,
    });

    const modalProps: ModalProps = {
        ...props,
        className: "npi-external-mobile-modal-full-screen",
        footer: null,
        open,
        onCancel: () => setOpen(false)
    }

    return <Modal {...modalProps}>
        <div id={"success-survey-modal-body"}>
            <h1>
                {t('EXTERNAL.CLIENT.POS_VIEW.THANK_YOU')} <br/>
            </h1>

            <div className={"list-after-submit-links"}>
                { hasReturnLink
                    && <Button block type={"primary"}>
                        <Link to={getHomeLink()}>{t('EXTERNAL.CLIENT.POS_VIEW.RETURN_TO_POS_LIST')}</Link>
                    </Button>
                }

                { imagesAllowed &&
                    <Button block type={"primary"} onClick={() => setOpen(false)}>
                        <Link to={getPosLink(posId, 'images')}>
                            {t('EXTERNAL.CLIENT.POS_VIEW.VISIT_IMAGES_AREA')}
                        </Link>
                    </Button>
                }

                <Button block type={"default"} onClick={() => setOpen(false)}>
                    {t('EXTERNAL.CLIENT.POS_VIEW.RETURN_TO_WAVE')}
                </Button>
            </div>

        </div>
    </Modal>
}

export default ModalSurveySubmitSuccess;