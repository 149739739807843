import React, {Dispatch, useContext, useState} from "react";

// Define the shape of the context
interface NpiUiContextType {
    darkMode: boolean;
    setDarkMode: Dispatch<boolean>
    toggleDarkMode: Dispatch<void>;
}

// Create the initial context value
const initialNpiUiContextValue: NpiUiContextType = {
    darkMode: false,
    setDarkMode: () => {},
    toggleDarkMode: () => {},
};

// Create the UiContext
const NpiUiContext = React.createContext<NpiUiContextType>(initialNpiUiContextValue);

// Create a custom hook to easily access the UiContext
const useNpiUiContext = (): NpiUiContextType => useContext(NpiUiContext);

const NpiUiContextProvider = ({children}:any) => {

    const [darkMode, setDarkMode] = useState<boolean>(false);
    const toggleDarkMode = () => setDarkMode((oldValue) => !oldValue)

    // Create the context value with the updated state and toggle function
    const uiNpiContextValue: NpiUiContextType = {
        darkMode: darkMode,
        setDarkMode: setDarkMode,
        toggleDarkMode: toggleDarkMode,
    };

    return <NpiUiContext.Provider value={uiNpiContextValue}>{children}</NpiUiContext.Provider>
};

export {NpiUiContextProvider, NpiUiContext, useNpiUiContext};