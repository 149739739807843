import React, {useCallback, useContext} from 'react';
import {Tag, Button, Form, Input, Switch, Modal, Table, Space, Divider, Tooltip, notification} from "antd";
import {observer} from "mobx-react";
import {IKpi} from "../../types/kpi";
import npiApi from "../../services/api";
import NpiModalEdit from "../modal-edit";
import _ from "lodash";
import {DeleteFilled, EditFilled, FileExcelFilled, PlusOutlined, SettingFilled} from "@ant-design/icons/lib";
import {NpiInternalContext} from "../../contexts/internal-context";
import NpiInputCondition from "../input/condition";
import NpiDisplayCondition from "../display/condition";
import styled from "styled-components";
import {ICondition, IConditionRuleTargetEnum} from "../../types/condition";
import NpiDisplayKpiName from "../display/kpi-name";
import {IWithQuestions} from "../../types/question";
import NpiInputColorPicker from "../input/color-picker";
import {toJS} from "mobx";
import {Trans, useTranslation} from "react-i18next";
import {getConditionErrors} from "../../helpers/condition";
import {ClearOutlined, PieChartOutlined} from "@ant-design/icons";
import i18next from "i18next";
import NpiDropdownEllipsis from "../display/dropdown-ellipsis";
import NpiModalManageKpis from "../wave/modal-manage-kpis";

;

const StyledNpiDisplayCondition = styled.div`
    .btn-clear {
      position: absolute;
      right: 0;
      z-index: 2;
      transition: 0.5s ease;
      opacity: 0;
    }
    &:hover {
      .btn-clear {
        opacity: 1;
      }
    }
    .ant-card{
        background-color: transparent;
        border: 0;
        .ant-card-body{
            padding: 5px;
        }
    }
`;

//Rules to use in conditions
const {QUESTION, STATUS, RESCHEDULED, TAG, BLOCK} = IConditionRuleTargetEnum;

interface INpiConfigKpis {
    item: IWithQuestions
    saveKpi: (itemId: number, kpiId: number, conditions: ICondition, conditions_not_applicable: ICondition) => Promise<IWithQuestions>
    defaultItem: any,
    displayExportMenu?: boolean,
}

const NpiConfigKpis = observer(({item, defaultItem, saveKpi, displayExportMenu=false}:INpiConfigKpis) => {

    //Text shown in the ruling
    const customRulingTextPositive = i18next.t("INTERNAL.CLIENT.KPI.CUSTOM_RULING_TEXT_POSITIVE");
    const customRulingTextNotApplicable = i18next.t("INTERNAL.CLIENT.KPI.CUSTOM_RULING_TEXT_NOT_APPLICABLE");

    const {isAllowedRegion} = useContext(NpiInternalContext);
    const {t} = useTranslation();

    //Form to edit the KPI
    const formKpi = [
        <Form.Item key="0" label={t('INTERNAL.CLIENT.COMMON.NAME')} name="name"><Input/></Form.Item>,
        <Form.Item key="1" label={t('INTERNAL.CLIENT.COMMON.CODE')} name="code"><Input/></Form.Item>,
        <Form.Item key="2" label={t('INTERNAL.CLIENT.COMMON.COLOR')} name="color"><NpiInputColorPicker/></Form.Item>,
        <Form.Item key="3" label={t('INTERNAL.CLIENT.COMMON.DESCRIPTION')} name="description"><Input.TextArea/></Form.Item>,
        <Form.Item key="4" label={t('INTERNAL.CLIENT.COMMON.MANDATORY')} name="is_mandatory" valuePropName="checked"><Switch/></Form.Item>,
    ];

//Form to edit the item-KPI
    const NpiFormItemKpiConditions = ({questions, tags}:any) => <>
        <h2>{t("INTERNAL.CLIENT.KPI.CONDITIONS_TO_BE_POSITIVE")}</h2>
        <Form.Item name="conditions" valuePropName="condition">
            <NpiInputCondition questions={questions} tags={tags} withActions={false} customRulingText={customRulingTextPositive} rulesTargets={[QUESTION, TAG, STATUS, RESCHEDULED, BLOCK]}/>
        </Form.Item>
        <Divider/>
        <h2>{t("INTERNAL.CLIENT.KPI.CONDITIONS_TO_BE_NOT_APPLICABLE")}</h2>
        <Form.Item name="conditions_not_applicable" valuePropName="condition">
            <NpiInputCondition questions={questions} tags={tags} withActions={false} customRulingText={customRulingTextNotApplicable} rulesTargets={[QUESTION, TAG, STATUS, RESCHEDULED, BLOCK]}/>
        </Form.Item>
    </>;

    // const deleteConfirmMatch = "delete";
    const deleteConfirmMatch = t('INTERNAL.CLIENT.KPI.DELETE_CONFIRMATION_WORD');

    // Clear all conditions rules for a KPI
    const clearConditions = (kpi:IKpi) => {
        Modal.confirm({
            title: t('INTERNAL.CLIENT.KPI.CLEAR_ALL_RULES_POSITIVE'),
            onOk: () => saveKpiConditions(kpi, null as any, kpi.conditions_not_applicable),
        });
    }

    // Clear all conditions_not_applicable rules for a KPI
    const clearConditionsNA = (kpi:IKpi) => {
        Modal.confirm({
            title: t('INTERNAL.CLIENT.KPI.CLEAR_ALL_RULES_NA'),
            onOk: () => saveKpiConditions(kpi, kpi.conditions, null as any),
        });
    }

    // Save a Kpi conditions
    const saveKpiConditions = (kpi:IKpi, conditions: ICondition, conditions_not_applicable: ICondition) => {
        return saveKpi(item.id, kpi.id, conditions, conditions_not_applicable).then((data:any) => {

            const found = _.find(item.kpis, {id: kpi.id});
            if(found){
                found.conditions = data.conditions;
                found.conditions_not_applicable = data.conditions_not_applicable;
                item.kpis = [...item.kpis];
            }
            return data;
        });
    }

    //Creating / Updating a KPI information
    const onChange = (kpi:IKpi) => npiApi.internal.kpi.save(kpi).then((data:IKpi) => {
        const found = _.find(item.kpis, {id: data.id});
        if(found){
            _.extend(found, data);
            item.kpis = _.cloneDeep(item.kpis);
        }
        else{
            item.kpis = [...item.kpis, data];
        }
        return data;
    });

    //Remove a KPI from the NPI
    const onDelete = (kpi:IKpi) => {

        const modal= Modal.confirm({
            title: t('INTERNAL.CLIENT.KPI.DELETE_KPI'),
            icon: <DeleteFilled/>,
            okButtonProps: {disabled: true},
            content: <span>
                <Trans t={t} i18nKey={'INTERNAL.CLIENT.KPI.KPI_DELETION_CONFIRM'} values={{name: kpi.name, deleteConfirmMatch}} components={{span: <span></span>, strong: <b></b>, em: <em></em>, br: <br />}} />

                <Input placeholder={deleteConfirmMatch}  onChange={(e) => {
                    modal.update({ okButtonProps: { disabled: e.target.value !== deleteConfirmMatch }});
                }}/>
            </span>,
            onOk: () => npiApi.internal.kpi.delete(kpi).then(() => {
                notification.success({message: <Trans t={t} i18nKey={'INTERNAL.CLIENT.KPI.DELETE_KPI_SUCCESS'} values={{name: kpi.name}} components={{strong: <strong></strong>}}/>})
                _.remove(item.kpis, {id: kpi.id});
                item.kpis = [...item.kpis];
            }),
        })
    };

    //Save conditions
    const onChangeCondition = (kpi:IKpi) => {
        //Clear conditions not applicable if not valid
        let {conditions_not_applicable} = kpi;
        const errors = getConditionErrors(conditions_not_applicable, item.questions, item.tags);
        if(errors.length){
            conditions_not_applicable = null as any;
        }

        return saveKpiConditions(kpi, kpi.conditions, conditions_not_applicable);
    };

    //Open Modal to manage upload/download of kpis template
    const openManageKpis = useCallback(() => {
        Modal.info({
            icon: <PieChartOutlined />,
            width: 500,
            title: t('INTERNAL.CLIENT.KPI.MANAGE_KPIS'),
            content: <NpiModalManageKpis waveId={item.id}/>,
            okText: t('INTERNAL.CLIENT.COMMON.CLOSE')
        })
    }, [item, t]);

    //Table columns
    const columns = [
        {title: t('INTERNAL.CLIENT.COMMON.CODE'), dataIndex: 'code', render: (_:any, k:IKpi)=> <NpiDisplayKpiName kpi={k} text={false}/>, width: 75},

        //Title, with a button to edit KPI values (at NPI level)
        {
            title: t('INTERNAL.CLIENT.COMMON.NAME'),
            dataIndex: 'name',
            render: (_:any, k:IKpi)=>
                <NpiModalEdit button={<span>{k.name} {isAllowedRegion(k.region_id) && <EditFilled/>}</span>} title={t('INTERNAL.CLIENT.KPI.EDIT_KPI')} value={k} onChange={onChange} editable={isAllowedRegion(k.region_id)}>
                    {formKpi}
                </NpiModalEdit>
        },
        {title:  t('INTERNAL.CLIENT.COMMON.STATUS'), dataIndex: 'is_mandatory', render: (_:any, k:IKpi)=> <>
            {k.is_mandatory && !k.conditions && <Tag color="red">{t('INTERNAL.CLIENT.KPI.STATUS.MUST_BE_CONFIGURED')}</Tag>}
            {!!k.conditions && <Tag color="green">{t('INTERNAL.CLIENT.COMMON.READY')}</Tag>}
            {!k.conditions && !k.is_mandatory && <Tag color="blue">{t('INTERNAL.CLIENT.KPI.STATUS.IGNORED')}</Tag>}
        </>},

        //Rules display
        {title: <span>{t('INTERNAL.CLIENT.COMMON.RULES')} ({t('INTERNAL.CLIENT.COMMON.POSITIVE')})</span>, width: '33%', render: (kpi:IKpi)=> <StyledNpiDisplayCondition>
                <Tooltip title={t('INTERNAL.CLIENT.KPI.CLEAR_ALL_RULES')}><Button className={'btn-clear'} icon={<ClearOutlined />} size={"small"} type={"link"} danger onClick={() => clearConditions(kpi)}/></Tooltip>
                    <NpiDisplayCondition questions={item.questions} condition={kpi.conditions} tags={item.tags} customRulingText={customRulingTextPositive}/>
            </StyledNpiDisplayCondition>
        },
        {title: <span>{t('INTERNAL.CLIENT.COMMON.RULES')} (N/A)</span>, width: '33%', render: (kpi:IKpi)=> <StyledNpiDisplayCondition>
                <Tooltip title={t('INTERNAL.CLIENT.KPI.CLEAR_ALL_RULES')}><Button className={'btn-clear'} icon={<ClearOutlined />} size={"small"} type={"link"} danger onClick={() => clearConditionsNA(kpi)}/></Tooltip>
                <NpiDisplayCondition questions={item.questions} condition={kpi.conditions_not_applicable} tags={item.tags} customRulingText={customRulingTextNotApplicable}/>
            </StyledNpiDisplayCondition>
        },

        //Description of the KPI
        {title: t('INTERNAL.CLIENT.COMMON.DESCRIPTION'), dataIndex: 'description', width: '10%'},

        //Edition of conditions, & creation of NPI
        {
            //KPI creation at the NPI level for the current region
            title: <Space>
                <NpiModalEdit button={<Button icon={<PlusOutlined/>} type="primary">{t('INTERNAL.CLIENT.KPI.CREATE_KPI')}</Button>} value={defaultItem} onChange={onChange} title={t('INTERNAL.CLIENT.KPI.CREATE_KPI')}>{formKpi}</NpiModalEdit>

                { displayExportMenu ? <NpiDropdownEllipsis items={[
                    ...displayExportMenu ? [{key: "manage_kpis", icon: <FileExcelFilled/>, label: t('INTERNAL.CLIENT.KPI.MANAGE_KPIS'), onClick:openManageKpis}] : [],
                ]}/> : ''}
            </Space>,
            align: 'right' as 'right',

            //Condition edition (at wave level) & delete
            render: (_:any, kpi:IKpi) =><Space>
                {/* delete */}
                {isAllowedRegion(kpi.region_id) && <Button type="link" onClick={() => onDelete(kpi)} icon={<DeleteFilled/>} danger/>}

                {/* edit */}
                <NpiModalEdit button={<Button type="link" icon={<SettingFilled/>}/>} title={`${t('INTERNAL.CLIENT.COMMON.EDIT')} ${t('INTERNAL.CLIENT.COMMON.RULES')}`} value={toJS(kpi)} onChange={onChangeCondition} editable={true} formProps={{wrapperCol: {span: 24}}} width={1000}>
                    <NpiFormItemKpiConditions questions={item.questions} tags={item.tags}/>
                </NpiModalEdit>
            </Space>,
        }
    ];

    return <Table columns={columns} dataSource={item.kpis} pagination={false} rowKey="id"/>;
});

export default NpiConfigKpis;