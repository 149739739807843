import React, {useRef, useState} from 'react';
import {Modal} from "antd";
import _ from "lodash";
import styled, {css} from "styled-components";
import NpiKpiDashboardGrid from "../kpi-dashboard/grid";
import {
    IDashboardBlockConfiguration,
    IDashboardBlockType,
    IDashboardConfiguration,
} from "../../types/dashboard";
import NpiInputKpiDashboardBlockConfigKpi from "../input/kpi-dashboard/block-config-kpi";
import NpiInputKpiDashboardBlockConfigPieChart from "../input/kpi-dashboard/block-config-pie-chart";
import NpiInputKpiDashboardBlockConfigTable from "../input/kpi-dashboard/block-config-table";
import useAcl from "../../hooks/use-acl";
import {IRightsEnum} from "../../types/user";
import NpiKpiDashboardBlockContainer from "./block-container";
import NpiDisplayKpiTable from "../display/kpi-table";
import {useKpiDashboardStore} from "../../contexts/stores";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import NpiInputKpiDashboardBlockConfigTitle from "../input/kpi-dashboard/block-config-title";
import NpiDisplayKpiTitle from "../display/kpi-title";

const StyledContainer = styled('div')<{ preview?: boolean }>`

  ${props => props.preview && css`
    width: 29.7cm;
    margin-left: auto;
    margin-right: auto;
    border-right: 1px dashed lightgray;
    border-left: 1px dashed lightgray;
    margin-top: 10px;
  `};
  
    .react-grid-layout{
        margin-top: -20px;
    }
    .ant-card{
        transition: opacity 250ms;
    }
    &.loading{
        opacity: 0.25;
    }
`;

const StyledTableContainerDiv = styled.div`
    margin: 0 20px 20px;
`;

const {KPI, PIE_CHART, TABLE, TITLE} = IDashboardBlockType;

/**
 * Dashboard showing for an NPI
 * @constructor
 */
const NpiKpiDashboardMain = observer(({filters, previewMode}:any) => {
    const {t} = useTranslation();
    const {compute, loading, dashboardData, configuration, setConfiguration, saveDashboard} = useKpiDashboardStore();
    const [editBlock, setEditBlock] = useState<IDashboardBlockConfiguration|null>();
    const containerRef = useRef(null);
    const {blocks} = configuration??{blocks:[]};
    const tableBlocks = _.filter(blocks, {type: TABLE});
    const titleBlock = _.find(blocks, {type: TITLE});
    const canEdit = useAcl().hasRight(IRightsEnum.EDIT_DASHBOARDS);

    //Shortcut to update configuration
    const updateConfiguration = (configuration:IDashboardConfiguration) =>  setConfiguration(_.cloneDeep(configuration));

    //When configuration changes, track them
    const onConfigurationChange = (newConfig:any) => {
        setConfiguration(newConfig)
    };

    //Open the modal ton configure a block
    const onClickConfigureBlock = (block:IDashboardBlockConfiguration) => {
        if(_.isArray(block.config)){
            block.config = {};
        }
        setEditBlock(_.cloneDeep(block));
    };

    /**
     * Event on deleting block (in fact just hiding it)
     * @param key
     */
    const onDeleteBlock = (key:string) => {
        const found = _.find(blocks, {key});
        if(found && configuration){
            found.deleted = true;
            updateConfiguration(configuration);
        }
    };

    //Apply the changes to the currently edited block, and close the modal. In specific cases it might refresh the data
    const applyEditBlock = () => {
        const found = _.find(configuration?.blocks, {key: editBlock?.key});
        const original = _.cloneDeep(found);
        const refresh = () => saveDashboard().then((dashboard:any) => compute({...filters, dashboardId: dashboard.id}));
        if(found && configuration?.blocks){
            configuration.blocks.splice(configuration.blocks.indexOf(found), 1, editBlock as IDashboardBlockConfiguration);
            setConfiguration({...configuration});
            setEditBlock(null); //close the modal
            if(found.type === PIE_CHART){
                //PIE_CHART require a refresh of the data after save
                refresh().then(null);
            }
            if(found.type === TABLE && original?.config?.mode !== editBlock?.config?.mode){
                //If we changed display mode, refresh data
                refresh().then(null);
            }
            if(found.type === KPI && editBlock?.config?.divideBy === 'TAG_VALUE'){
                //If we divide by a tag value, requires refresh
                refresh().then(null);
            }
        }
    };

    return <StyledContainer preview={previewMode} ref={containerRef} className={loading? t('INTERNAL.CLIENT.COMMON.LOADING'):''}>

        {titleBlock && titleBlock.visible && !titleBlock.deleted && <StyledTableContainerDiv>
            <NpiKpiDashboardBlockContainer block={titleBlock} canEdit={canEdit} onDelete={onDeleteBlock} onClickConfigure={onClickConfigureBlock} key={titleBlock.key}>
                <NpiDisplayKpiTitle {...titleBlock.config} printMode={previewMode}/>
            </NpiKpiDashboardBlockContainer>
        </StyledTableContainerDiv>}

        <NpiKpiDashboardGrid data={dashboardData} configuration={configuration} onConfigurationChange={onConfigurationChange} onClickConfigureBlock={onClickConfigureBlock}/>

        <StyledTableContainerDiv>
            {tableBlocks.map(block => {
                const {key, visible, deleted, config} = block;
                return visible && !deleted && <NpiKpiDashboardBlockContainer block={block} canEdit={canEdit} onDelete={onDeleteBlock} onClickConfigure={onClickConfigureBlock} key={key}>
                    <NpiDisplayKpiTable tableKey={key} data={dashboardData} config={config} printMode={previewMode}/>
                </NpiKpiDashboardBlockContainer>
            })}
        </StyledTableContainerDiv>

        {/*Modal to edit a block*/}
        <Modal open={!!editBlock} onCancel={() => setEditBlock(null)} title={t('INTERNAL.CLIENT.KPI.DASHBOARD.EDIT_BLOCK')} destroyOnClose={true} onOk={applyEditBlock} width={600}>
            {editBlock?.type === KPI && <NpiInputKpiDashboardBlockConfigKpi block={editBlock} tags={dashboardData.tags} kpis={dashboardData.kpis} onChange={setEditBlock} waveIds={filters.wavesIds}/>}
            {editBlock?.type === PIE_CHART && <NpiInputKpiDashboardBlockConfigPieChart block={editBlock} kpis={dashboardData.kpis} onChange={setEditBlock}/>}
            {editBlock?.type === TABLE && <NpiInputKpiDashboardBlockConfigTable block={editBlock} kpis={dashboardData.kpis} onChange={setEditBlock}/>}
            {editBlock?.type === TITLE && <NpiInputKpiDashboardBlockConfigTitle block={editBlock} onChange={setEditBlock}/>}
        </Modal>
    </StyledContainer>
});

export default NpiKpiDashboardMain;