import {ICondition, IConditionActionTargetEnum, IConditionRuleTargetEnum} from "../types/condition";
import {IQuestion} from "../types/question";
import _ from "lodash";
import {IWTag} from "../types/wave";

/**
 * Return an array of errors in the setup of a condition
 * @param condition
 * @param questions
 * @param tags
 */
export const getConditionErrors = (condition: ICondition, questions: IQuestion[], tags: IWTag[] = []) => {
    const errors = [] as string[];

    try{
        condition.rules?.forEach(rule => {
            if(rule.target === IConditionRuleTargetEnum.BLOCK){
                errors.push(...getConditionErrors({rules: rule.value} as ICondition, questions, tags));
            }
            if(rule.target === IConditionRuleTargetEnum.POS_FIELD && (!rule.state || !rule.value || !rule.posField)){
                errors.push(`A rules's POS field details are incomplete`);
            }
            if(rule.target === IConditionRuleTargetEnum.QUESTION && (!rule.questionId || !_.find(questions, {id: rule.questionId}))) {
                errors.push(`A rule's target question is undefined`); //question must exist
            }
            if(rule.target === IConditionRuleTargetEnum.TAG && (!rule.value || !tags.find(tag => tag.id === rule.value))){
                errors.push(`A rule's target tag is undefined`); //tag must exist
            }
            if(rule.target === IConditionRuleTargetEnum.QUESTION && (!rule.state)){
                errors.push(`A rule state is undefined`)
            }
            if(rule.target === IConditionRuleTargetEnum.RESCHEDULED && (_.isUndefined(rule.value))){
                errors.push(`The rescheduling value is not set`)
            }
            if(rule.target === IConditionRuleTargetEnum.STATUS && (_.isUndefined(rule.value))){
                errors.push(`The status value is not set`)
            }
        });

        condition.actions?.forEach(action => {
            if(action.target === IConditionActionTargetEnum.QUESTION && (!action.questionId || !_.find(questions, {id: action.questionId}))){
                errors.push(`An action's target question is undefined`); //question must exist
            }
            if(action.target === IConditionActionTargetEnum.ANSWER && (!action.answerId || ! action.answerId[0] || !_.find(questions, {id: action.answerId[0]}))){
                errors.push(`An action's target question is undefined`); //question must exist
            }
            else if(action.target === IConditionActionTargetEnum.ANSWER && action.answerId){
                const question = _.find(questions, {id: action.answerId[0]}) as IQuestion;
                if(! question || ! _.find(question.config.answers, {value: action.answerId[1]})){
                    errors.push(`An action's target answer is undefined`); //answer must exist
                }
            }
        });
    }catch(e){
        console.error(e);
        errors.push('Unknown error');
    }

    return errors;
};