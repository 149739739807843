import React, {useContext, useEffect, useMemo, useState} from 'react';
import NpiDisplayLayoutContent from "../../components/display/layout-content";
import {NpiInternalContext} from "../../contexts/internal-context";
import {Alert, Button, Dropdown, Menu, Modal, Space, Spin, Switch, Table, Tooltip} from "antd";
import NpiDisplayRegion from "../../components/display/region";
import NpiDisplayCountryFlag from "../../components/display/country-flag";
import useRestrictedToAdmin from "../../hooks/use-restricted-to-admin";
import {REGION_WW} from "../../helpers/constants";
import {Trans, useTranslation} from "react-i18next";
import {useRequest} from "ahooks";
import npiApi, {npiApiDownloadFileCallback} from "../../services/api";
import {TableProps} from "antd/lib/table";
import NpiDropdownEllipsis from "../../components/display/dropdown-ellipsis";
import {ClockCircleOutlined, EyeOutlined, LockOutlined, ReloadOutlined} from "@ant-design/icons";
import {ICountry} from "../../types/country";
import {FlagOutlined, LinkOutlined} from "@ant-design/icons/lib";
import _ from "lodash";
import {nowFormat} from "../../helpers/date";
import styled from "styled-components";


const NpiContainerAdminCountryList = () => {
    useRestrictedToAdmin();

    const {t} = useTranslation();

    const {user, loaded, isAllowedRegion, getRegionName, setBreadcrumbs} = useContext(NpiInternalContext);
    const regionName = useMemo(() => getRegionName(user.region_id), [user, getRegionName]);
    const isWW = isAllowedRegion(REGION_WW);


    const {data: countries, loading: loadingCountries, mutate: setCountries} = useRequest<ICountry[], any>(npiApi.internal.country.list);
    const {runAsync: downloadCountryLinks, loading: loadingDownload} = useRequest(npiApi.internal.file.exportCountryLinks, {manual: true});
    const {runAsync: resetLinks} = useRequest(npiApi.internal.country.resetLinks, {
        manual: true,
        onSuccess: (newCountry: Pick<ICountry, 'id'|'external_links'>) => {
            const newList = _.cloneDeep(countries)!;
            const idx = newList.findIndex(c => c.id === newCountry.id);
            newList[idx] = {...newList[idx], ...newCountry};
            setCountries(newList);
        },
    });

    //Cannot init with !isWW: context is not fully loaded at component mount :(
    const [showOnlyCountriesInUserRegion, setShowOnlyCountriesInUserRegion] = useState<boolean>(false);

    const filteredCountries = useMemo(() => {
        return !showOnlyCountriesInUserRegion
            ? countries
            : countries?.filter(country => country.region_id === user.region_id)
    }, [showOnlyCountriesInUserRegion, countries, user])

    const onClickReset = (countryId: number) => {
        Modal.confirm({
            title: t('INTERNAL.CLIENT.COUNTRIES.LINKS.RESET'),
            content: <>
                <Trans t={t} i18nKey={'INTERNAL.CLIENT.COUNTRIES.LINKS.RESET_WARNING'}/>
                <br/><br/>
                <Trans t={t} i18nKey={'INTERNAL.CLIENT.COMMON.LINK.RESET_WARNING_INFO'}/>
                <br/><br/>
                <em>
                    <Trans t={t} i18nKey={'INTERNAL.CLIENT.COMMON.LINK.RESET_WARNING_CONFIRM'}/>
                </em>
            </>,
            onOk: () => resetLinks(countryId).then(null)
        })
    }

    //Set navigation
    useEffect(() => setBreadcrumbs([
        {name: t('INTERNAL.CLIENT.COMMON.ADMINISTRATION')},
        {url: '/admin/countries', name: t('INTERNAL.CLIENT.COMMON.COUNTRIES')},
    ]), [setBreadcrumbs, t]);

    const onDownload = () => {
        downloadCountryLinks({onlyUserCountry: showOnlyCountriesInUserRegion})
            .then(npiApiDownloadFileCallback(`country-links-${nowFormat()}.xlsx`));
    }

    /**
     * Persist the user region
     */
    useEffect(() => {
        setShowOnlyCountriesInUserRegion(!isWW);
    }, [isWW])

    const columns = [
        {title: '#', dataIndex: 'id', width: 60,},
        {title: t('INTERNAL.CLIENT.COMMON.REGION'), dataIndex: 'region_id', width: 120, render: (id: any) => <NpiDisplayRegion id={id}/>},
        {title: t('INTERNAL.CLIENT.COMMON.FLAG'), dataIndex: 'code', width: 60, render: (code: any) => <NpiDisplayCountryFlag code={code}/>},
        {title: t('INTERNAL.CLIENT.COMMON.CODE'), dataIndex: 'code', width: 60, align: 'center' as 'center'},
        {title: t('INTERNAL.CLIENT.COMMON.NAME'), dataIndex: 'name'},
        {title: t('INTERNAL.CLIENT.COMMON.EXTERNAL_ACCESS'), render: (country: ICountry) => <Space>

            <Dropdown overlay={<Menu items={[
                ...!!country.external_links?.url ? [
                    {key:'view', icon: <LinkOutlined/>, label: <a href={country.external_links?.url} target={"_blank"} rel={"noreferrer"}>{t('INTERNAL.CLIENT.COMMON.LINK.VIEW_CLASSIC')}</a>},
                ] : [],
                ...(!!country.external_links?.dailyUrl && isWW) ? [
                    {key:'view-daily', icon: <ClockCircleOutlined/>, label: <a href={country.external_links?.dailyUrl} target={"_blank"} rel={"noreferrer"}>{t('INTERNAL.CLIENT.COMMON.LINK.VIEW_DAILY')}</a>},
                ] : [],
                ...!!country.external_links?.hiddenUrl ? [
                    {key:'view-hidden', icon: <LockOutlined/>, label: <a href={country.external_links?.hiddenUrl} target={"_blank"} rel={"noreferrer"}>{t('INTERNAL.CLIENT.COMMON.LINK.VIEW_HIDDEN')}</a>},
                ] : [],
            ]}/>}>
                <Tooltip title={t('INTERNAL.CLIENT.COMMON.LINK.TOOLTIP')}>{<Button type={"primary"} icon={<EyeOutlined/>}>{t('INTERNAL.CLIENT.COMMON.VIEW')}</Button>}</Tooltip>
            </Dropdown>


            <NpiDropdownEllipsis items={[
                {key: "reset-link", icon: <ReloadOutlined/>, label: t('INTERNAL.CLIENT.COMMON.LINK.RESET'), onClick:() => onClickReset(country.id)},
            ]}/>
        </Space>},
    ];

    if (!loaded) {
        return <Spin/>
    }

    const tableConfig: TableProps<ICountry>= {
        rowKey: 'id',
        dataSource: filteredCountries,
        loading: loadingCountries,
        columns,
        pagination: false,
    };

    return <NpiDisplayLayoutContent>

        <StyledOptions>
            <Space size={"large"}>
                {/* Switch for displaying only the countries in the user regions, only available for non-WW user */}
                {!isWW && <>
                    {t('INTERNAL.CLIENT.COUNTRIES.REGION_COUNTRIES_ONLY', {regionName: regionName})}
                    <Switch checked={showOnlyCountriesInUserRegion} onChange={setShowOnlyCountriesInUserRegion}/>
                </> }
                <Button icon={<FlagOutlined />} onClick={onDownload} type={"primary"} loading={loadingDownload}> {t('INTERNAL.CLIENT.COMMON.DOWNLOAD')}</Button>
            </Space>
        </StyledOptions>

        <Alert message={t('INTERNAL.CLIENT.COUNTRIES.COUNTRY_LIST_ALERT_MESSAGE')}/>
        <br/>
        <Table {...tableConfig}/>

    </NpiDisplayLayoutContent>
};

const StyledOptions = styled.div`
    position: absolute;
    z-index: 100;
    top: 15px;
    right: 20px;
`;


export default NpiContainerAdminCountryList;