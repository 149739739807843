import React, {useContext} from "react";
import {NpiInternalContext} from "../../contexts/internal-context";
import {Select} from "antd";
import {SelectProps} from "antd/lib/select";
import {useTranslation} from "react-i18next";

interface ISelectMerchandisingGroupProps extends SelectProps<any> {
    regionId?: number;
}

/**
 * Select between a list of POS program
 */
const NpiInputSelectMerchandisingGroup = ({regionId, ...props}:ISelectMerchandisingGroupProps) => {

    const {t} = useTranslation();

    const merchandisingGroups = useContext(NpiInternalContext).merchandisingGroups;

    const merchandisingGroupsOptions = merchandisingGroups
            .filter(m => !regionId || m.region_id === regionId)
            .map(m => ({value: m.id, label: m.name}));

    return <Select options={merchandisingGroupsOptions} placeholder={t('INTERNAL.CLIENT.COMMON.MERCHANDISING_GROUP')} style={{minWidth: 150, ...props.style}} {...props}/>
}

export default NpiInputSelectMerchandisingGroup;