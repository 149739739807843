import React from "react";
import npiApi from "../../services/api";
import SelectDebounced, {IDebounceSelectProps} from "./select-debounced";
import {useRequest} from "ahooks";
import {INpi} from "../../types/npi";
import {useTranslation} from "react-i18next";

export interface INpiSelectProps<ValueType, T> extends Omit<IDebounceSelectProps<ValueType, T>, 'fetchOptions'> {
	regionIds?: number[];
    limit ?: number;
}

/**
 * Remote search and select (single/multiple) NPIs by their name or id
 *
 * @param regionIds - filtered by Region ids
 * @param limit
 * @param props
 *
 * {@see SelectDebounced} for more options and information
 */
const NpiInputSelectNpi = ({limit, regionIds, ...props}:INpiSelectProps<any, INpi>) => {

    const {runAsync: fetchNpi, loading} = useRequest<INpi[], any>(npiApi.internal.npi.search, {manual: true});
    const {t} = useTranslation();

    return (
        <SelectDebounced<any, INpi>
            {...props}
            placeholder={props.placeholder || t(props.mode === "multiple" ? 'INTERNAL.CLIENT.COMMON.SELECT_NPIS' : 'INTERNAL.CLIENT.COMMON.SELECT_NPI')}
            loading={props.loading || loading}
            fetchOptions={search => fetchNpi({search: search, limit: limit || 15, regionIds})}
            fetchInitial={props.fetchInitial || (value => fetchNpi({ids: Array.isArray(value) ? value : (value ? [value] : []), regionIds}))}
            mappedBy={props.mappedBy || (npi => npi.id)}
            display={props.display || (npi => npi.name)}
        />
    );
}

export default NpiInputSelectNpi;