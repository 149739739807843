export interface IExternalToken {
    type: ExternalTokenType
    rights: ExternalTokenRight[]
    isHidden: boolean
    isDaily: boolean
}

export enum ExternalTokenType {
    RESTRICT_BY_POS = 'RESTRICT_BY_POS',
    RESTRICT_BY_COUNTRY = 'RESTRICT_BY_COUNTRY',
    RESTRICT_BY_COUNTRY_AND_WAVE = 'RESTRICT_BY_COUNTRY_AND_WAVE',
    RESTRICT_BY_CONTACT_AND_WAVE = 'RESTRICT_BY_CONTACT_AND_WAVE',
    RESTRICT_FOR_IMAGES = 'RESTRICT_FOR_IMAGES',
}

export enum ExternalTokenRight {
    ACCESS_EXTERNAL_VIEW = 'ACCESS_EXTERNAL_VIEW',
    ACCESS_POS_LIST = 'ACCESS_POS_LIST',
    ACCESS_UNIFIED_VIEW = 'ACCESS_UNIFIED_VIEW',
    ACCESS_CLOSED_WAVES = 'ACCESS_CLOSED_WAVES',
    ACCESS_POS_SEARCH = 'ACCESS_POS_SEARCH',
    ACCESS_DAILY_VIEW = 'ACCESS_DAILY_VIEW',
}

export interface IExternalLink {
    type: ExternalTokenType
    url: string
    hiddenUrl?: string
    dailyUrl?: string
}

export interface INpiExternalContainerPosViewRouteParams {
    posId: string,
    tabType: string
}