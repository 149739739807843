import React from 'react';
import NpiKpiDashboardGrid from "./grid";
import {IDashboardBlockType} from "../../types/dashboard";
import {Rnd} from "react-rnd";
import styled from "styled-components";
import _ from "lodash";
import NpiDisplayKpiTable from "../display/kpi-table";
import {observer} from "mobx-react";
import {useKpiDashboardStore} from "../../contexts/stores";
import colors from "../../services/colors";
import {useTranslation} from "react-i18next";
import NpiDisplayKpiTitle from "../display/kpi-title";

const StyledPrintArea = styled.div`
    position: relative;
    //background-color: ${colors.greyLightBackground};
    background-color: white;
    //height: calc((20.98cm - 20mm) * 10);
    //width: calc(29.7cm - 10mm);
    height: calc(21cm * 10);
    width: 29.7cm;
    margin: auto;
    box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.57);
    .react-draggable-dragging{
        z-index: 1000;
    }
    &>.react-draggable{
        &:before{
            content: '';
            position: absolute;
            display: block;
            height: 100%;
            width: 100%;
            border: 2px dotted #AAA;
            z-index: 1000;
        }
    }
    @media print {
        box-shadow: none;
        height: auto;
        margin: inherit;
        &>.react-draggable:before{
            border: 0;
        }
    }
    @page {
        margin: 0.5cm 0.5cm 0.5cm 1.5cm !important;
    }
`;

const StyledPageLine = styled.div`
    position: absolute;
    width: 100%;
    border-top: 1px dashed #AAA;
    top: calc(${(props:any) => props.page} * 21cm);
    
    @media print{
        display: none;
    }
`;

const PageLine = (props:any) => <StyledPageLine {...props}/>

/**
 * Dashboard showing for an NPI
 * @constructor
 */
const NpiKpiDashboardPrintPreview = observer(({loading}:{loading:boolean})=>{
    const defaults = {
        bounds: "parent",
        resizeGrid: [10, 10] as [number, number],
        dragGrid: [10, 10] as [number, number],
    };

    const defaultPosition = {x:0, y: 0, width: '80%', height: 'auto'};

    const {t} = useTranslation();
    const {dashboardData, configuration, setConfiguration} = useKpiDashboardStore();
    const tableBlocks = _.filter(configuration?.blocks, {type: IDashboardBlockType.TABLE});
    const titleBlock = _.find(configuration?.blocks, {type: IDashboardBlockType.TITLE});

    const printConfig = configuration?.print ?? {};

    const onDrag = (id:string, drag:any) => {
        printConfig[id] = {...printConfig[id], x: isNaN(drag.x) ? 1 : drag.x, y: isNaN(drag.y) ? 1 : drag.y};
        setConfiguration({...configuration, print: printConfig});
    };

    const onResize =  (id:string, ref:any) => {
        printConfig[id] = {...printConfig[id], width: ref.style.width, height: ref.style.height};
        setConfiguration({...configuration, print: printConfig});
    };

    if(loading){
        return <span>{t('INTERNAL.CLIENT.COMMON.LOADING')}</span>;
    }

    return <>
        {/*Force landscape print*/}
        <style type="text/css" media="print">{"\
            @page {size: A4 landscape; margin: 0mm; border: 0}\
            html, body {background-color: white;}\
        "}</style>

        <StyledPrintArea>
            {titleBlock && titleBlock.visible && !titleBlock.deleted
                && <Rnd {...defaults} style={{zIndex: 2}} key={titleBlock.key} default={printConfig[titleBlock.key] ?? {...defaultPosition, x:0, y:0}} onDragStop={(e,d) => onDrag(titleBlock.key, d)} onResizeStop={(e,d,ref) => onResize(titleBlock.key, ref)}>
                        <NpiDisplayKpiTitle {...titleBlock.config} printMode={true}/>
                </Rnd>
            }

            <Rnd {...defaults} default={printConfig.grid ?? defaultPosition} onDragStop={(e,d) => onDrag('grid', d)} onResizeStop={(e,d,ref) => onResize('grid', ref)}>
                <NpiKpiDashboardGrid data={dashboardData} configuration={configuration} printMode={true}/>
            </Rnd>

            {tableBlocks.map((block,k) => {
                const {key, visible, deleted, config} = block;
                return visible && !deleted
                    && <Rnd {...defaults} key={block.key} default={printConfig[key] ?? {...defaultPosition, x:(k+1)*30, y:(k+1)*30}} onDragStop={(e,d) => onDrag(key, d)} onResizeStop={(e,d,ref) => onResize(key, ref)}>
                        <NpiDisplayKpiTable tableKey={key} data={dashboardData} config={config} printMode={true}/>
                    </Rnd>
            })}
            <PageLine page={1}/>
            <PageLine page={2}/>
            <PageLine page={3}/>
            <PageLine page={4}/>
            <PageLine page={5}/>
            <PageLine page={6}/>
            <PageLine page={7}/>
            <PageLine page={8}/>
            <PageLine page={9}/>
        </StyledPrintArea>
    </>
});

export default NpiKpiDashboardPrintPreview;