import React, {useMemo} from "react";
import {Table} from "antd";
import styled, {css} from "styled-components";
import {IDashboardData} from "../../types/dashboard";
import NpiDisplayCountry from "./country";
import colors from "../../services/colors";
import NpiDisplayNumber from "./number";
import NpiDisplayPercentage from "./percentage";
import NpiDisplayPosStatus from "./pos-status";
import {IWavePosStatusEnum} from "../../types/wave-pos";
import NpiBatteryChartElement from "./battery-chart-element";
import {IKpi} from "../../types/kpi";
import {useTranslation} from "react-i18next";
import {ColumnsType} from "antd/es/table";

const StyledContainer = styled('div')<{ printMode?: boolean }>`
    height: 100%;
    width: 100%;
    background-color: ${colors.white};
    
    .sub_subtotal{ td:first-child {font-style: italic;} }
    .subtotal, .sub_subtotal{ background-color: ${colors.greySuperLightBackground}; }
    .total{ background-color: ${colors.greyLightBackground}; }
    
    table{
      border: 5px solid white;
      tr>td:first-child{
        font-weight: bold;
      }

    }
    
    .ant-table-wrapper, .ant-spin-nested-loading, .ant-spin-container, .ant-table, .ant-table-container, .ant-table-content, table {
        height: 100%;
    }

    ${props => props.printMode && css`
      .ant-table-cell {
        padding: 1px 8px !important;
      }
    `};
`;

const titles = {
    PROGRAM: {
        title: 'Program',
        dataIndex: 'key',
    },
    REGION: {
        title: 'Regions',
        dataIndex: 'key',
    },
    COUNTRY: {
        title: 'Countries',
        dataIndex: 'key',
        render: (key:string) => key==='Total' ? 'Total' : <NpiDisplayCountry id={Number(key)} flag={false}/>,
    },
    PARTNER: {
        title: 'Partners',
        dataIndex: 'key',
    },
};

interface INpiDisplayKpiTable {
    tableKey: string
    data: IDashboardData
    config: INpiDisplayKpiTableConfig
    printMode?: boolean
}

interface INpiDisplayKpiTableConfig {
    removedKpis?: number[]
    mode?: 'PROGRAM'|'REGION'|'COUNTRY'|'PARTNER',
    display?: 'PERCENT'|'VALUE',
    orderedKpisId?: number[],
    displayNames?: Record<number, string>,
    show_total_pos?: boolean
    show_completion_percent?: boolean
    show_completion_chart?: boolean
}

/**
 * Order Kpi from config key "orderedKpisId"
 * @param a IKpi
 * @param b IKpi
 * @param config INpiDisplayKpiTableConfig
 */
export const orderKpiByDashboardConfig = (a: IKpi, b: IKpi, config: INpiDisplayKpiTableConfig) => {
    const {orderedKpisId} = config;

    const aOrder = orderedKpisId?.indexOf(a.id) ?? 1000;
    const bOrder = orderedKpisId?.indexOf(b.id) ?? 1000;

    return aOrder - bOrder;
}

/**
 *
 * @param tableKey
 * @param data
 * @param config
 * @param printMode
 * @constructor
 */
const NpiDisplayKpiTable = ({tableKey, data, config, printMode=false}:INpiDisplayKpiTable) => {
    const {rows, mode} = data.tables[tableKey];
    const {removedKpis, display} = config;
    const {t} = useTranslation();
    const titleWidth = "50px";
    const columnWidth = useMemo(() => {
        const countColumns = data.kpis.filter(kpi => (removedKpis?.indexOf(kpi.id)??-1) <0 ).length
            + ((config?.show_total_pos ?? true) ? 1 : 0)
            + ((config?.show_completion_percent ?? true) ? 1 : 0)
            // + ((config?.show_completion_chart ?? true) ? 1 : 0)
        if( config?.show_completion_chart ?? true ) {
            return `calc(calc(100%-130px)/${countColumns})`;
        } else {
            return `calc(100%/${countColumns})`;
        }
    }, [data.kpis, config, removedKpis])
    //
    const columns:ColumnsType<any> = useMemo(() => [
        {
            ...titles[mode],
            // equivalent of width: "min-content"
            width: titleWidth,
            onHeaderCell: () => ({style: {minWidth: titleWidth, whiteSpace: 'nowrap', overflow: "visible"}}),
            onCell: () => ({style: {minWidth: titleWidth, whiteSpace: 'nowrap', overflow: "visible"}}),
        },
        ...(config?.show_total_pos ?? true) ? [{
            title: t('INTERNAL.CLIENT.KPI.DASHBOARD.TOTAL_POS'),
            dataIndex: 'total',
            render: (value:any) => display === 'PERCENT' ? <NpiDisplayPercentage value={value/data.values.total} fractionDigits={1}/> : <NpiDisplayNumber value={value}/>,
            align: 'center' as 'center',
            width: columnWidth,

        }] : [],
        ...data.kpis
            .filter(kpi => (removedKpis?.indexOf(kpi.id)??-1) <0 ) //remove KPIs marked as removed in table config
            .sort((a: IKpi, b: IKpi) => orderKpiByDashboardConfig(a, b, config))
            .map(kpi => ({
                title: config?.displayNames?.[kpi.id] ?? kpi.name,
                dataIndex: 'kpi' + kpi.id,
                render: (value:any, row:any) => display === 'PERCENT' ? <NpiDisplayPercentage value={value/row.total} fractionDigits={1}/> : <NpiDisplayNumber value={value}/>,
                align: 'center' as 'center',
                width: columnWidth,
            }))
        ,
        ...(config?.show_completion_percent ?? true) ? [{
            title: <NpiDisplayPosStatus status={IWavePosStatusEnum.POSITIVE} msg={t('INTERNAL.CLIENT.KPI.DASHBOARD.COMPLETION_PERCENT')}/>,
            dataIndex: 'status_positive',
            render: (value:any, row:any) => <NpiDisplayPercentage value={Number(value) / row.total}/>,
            align: 'center' as 'center',
            width: columnWidth,
        }] : [],
        ...(config?.show_completion_chart ?? true) ? [{
            render: (row:any) => <NpiBatteryChartElement height={'14px'} width={'100%'} positive={row.status_positive} negative={row.status_negative} neutral={row.status_neutral} noText/>,
            width: '130px',
            onCell: () => ({style: {minWidth: '130px'}}),
        }] : [],
    ], [mode, data, removedKpis, display, config, t, columnWidth, titleWidth]);

    const tableConfig = {
        columns,
        dataSource: rows,
        pagination: false as false,
        size: "small" as "small",
        rowClassName: (row:any) => row.key === 'Total' ? 'total' : row.className,
    };

    return <StyledContainer printMode={printMode}>
        <Table {...tableConfig}/>
    </StyledContainer>
};

export default NpiDisplayKpiTable;