import React from "react";
import {Select} from "antd";
import NpiInputSelectShipmentCode from "../input/select-shipment-code";
import { useTranslation } from "react-i18next";
import {useControllableValue} from "ahooks";
import styled from "styled-components";
import {IShipmentComputedDropdown, IShipmentFilters} from "../../types/shipment";

const StyledForm = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  .filter-item {
    flex: 25% 0 0;
    display: flex;
    margin-bottom: 0;
    padding-right: 8px;
    flex-direction: column;
    align-items: start;
    .ant-select {
      width: 100%;
    }
  }
`

interface INpiDashboardShipmentFilters {
    filters: IShipmentFilters
    onChange: (v:any) => void
    options?: IShipmentComputedDropdown
}


const NpiDashboardShipmentFilters = ({options, ...props} : INpiDashboardShipmentFilters) => {
    const {t} = useTranslation();

    const [filters, setFilters] = useControllableValue<IShipmentFilters>(props, {valuePropName: 'filters'});

    const onChangeShipmentCode = (id?:number) => {
        setFilters({shipment_code_id: id ?? null, country: [], program: [], carrier: []});
    }

    return <StyledForm>
        <div className={'filter-item'} key={'shipment_code_id'}>
            <span className={'label'}>{t('INTERNAL.CLIENT.DASHBOARD.SHIPMENT_CODE')}</span>
            <NpiInputSelectShipmentCode placeholder={t('INTERNAL.CLIENT.DASHBOARD.SHIPMENT_CODE') + '...'} value={filters.shipment_code_id} onChange={ onChangeShipmentCode } allowClear showSearch optionFilterProp="label"/>
        </div>

        <div className={'filter-item'} key="country">
            <span className={'label'}>{t('INTERNAL.CLIENT.COMMON.COUNTRIES')}</span>
            <Select
                placeholder={t('INTERNAL.CLIENT.COMMON.COUNTRIES') + '...'}
                mode="multiple"
                value={filters?.country}
                onChange={ (v:any) => setFilters( {...filters, country: v} ) }
                disabled={ !options?.country }
                allowClear
                options={options?.country?.map(c => ({key: c.code, value: c.code ?? '', label: c.label}))}
                optionFilterProp="label"
            />
        </div>

        <div className={'filter-item'} key="program">
            <span className={'label'}>{t('INTERNAL.CLIENT.COMMON.PROGRAM')}</span>
            <Select placeholder={t('INTERNAL.CLIENT.COMMON.PROGRAM') + '...'}
                          value={filters?.program}
                          onChange={ (v:any) => setFilters( {...filters, program: v} ) }
                          disabled={ !options?.program } allowClear optionFilterProp="children" showSearch
                          options={options?.program?.map((v, i) => ({key: i, value: v, label: v}))}
            />
        </div>

        <div className={'filter-item'} key="carrier">
            <span className={'label'}>{t('INTERNAL.CLIENT.COMMON.CARRIER')}</span>
            <Select placeholder={t('INTERNAL.CLIENT.COMMON.CARRIER') + '...'}
                          mode="multiple"
                          value={filters?.carrier}
                          onChange={ (v:any) => setFilters( {...filters, carrier: v} ) }
                          disabled={ !options?.carrier } allowClear optionFilterProp="children"
                          options={options?.carrier?.map((v, i) => ({key: i, value: v, label: v}))}
            />
        </div>
    </StyledForm>
    ;
}

export default NpiDashboardShipmentFilters;