import React from "react";
//@ts-ignore
import ReactCountryFlag from "react-country-flag";

const NpiDisplayCountryFlag = ({style, code, ...rest} : {style?:object, code?:string}) => {
    const finalCode = code === 'UK' ? 'GB': (code === 'AN' ? 'NL' : code);
    if( ! finalCode){
        return <div style={{backgroundColor: '#AAA', color: '#FFF', textAlign: "center", height: 20, width: 18, position: 'relative', top:-2, ...style}}>?</div>
    }
    else{
        return <ReactCountryFlag countryCode={finalCode} svg style={{height: 20, width: 18, position: 'relative', top:-1, ...style}} {...rest}/>
    }
}

export default NpiDisplayCountryFlag;