import React, {useContext, useMemo} from "react";
import {Tag} from "antd";
import _ from "lodash";
import {NpiInternalContext} from "../../contexts/internal-context";
import colors from "../../services/colors";
import {useTranslation} from "react-i18next";


/**
 * Displays POS tags. Will display a limited number and then the rest as a tooltip
 * @param value
 * @param tags
 * @param max (use -1 to show all without ellipsis)
 * @constructor
 */
const NpiDisplayMerchandisingGroup = ({value}:{value: number|null}) => {
    const {t} = useTranslation();

    const {merchandisingGroups} = useContext(NpiInternalContext);
    const displayedValue = useMemo(() => {
        const found = _.find(merchandisingGroups, {id: value}) as any;
        if(found) return found.name;
    }, [merchandisingGroups, value]);


    return value ? <Tag>{displayedValue}</Tag> : <Tag color={colors.red}>{t('INTERNAL.CLIENT.COMMON.NO_GROUP')}</Tag>
};

export default NpiDisplayMerchandisingGroup;