import React, {useContext, useEffect, useMemo, useState} from 'react';
import NpiDisplayLayoutContent from "../../components/display/layout-content";
import {NpiInternalContext} from "../../contexts/internal-context";
import npiApi, {npiApiDownloadFileCallback} from "../../services/api";
import {Button, Dropdown, Menu, Modal, Space, Tooltip} from "antd";
import {useRequest} from "ahooks";
import useRestrictedToAdmin from "../../hooks/use-restricted-to-admin";
import NpiPaginatedTable, {NpiServerPagination} from "../../components/paginated-table";
import NpiDisplayCountry from "../../components/display/country";
import styled from "styled-components";
import {
    ClockCircleOutlined,
    EyeOutlined,
    LinkOutlined,
    ReloadOutlined,
    ShopOutlined
} from "@ant-design/icons";
import {REGION_WW} from "../../helpers/constants";
import NpiInputSelectCountry from "../../components/input/select-country";
import {numberFormat} from "../../helpers/toolbox";
import {IPos} from "../../types/pos";
import {Trans, useTranslation} from "react-i18next";
import {nowFormat} from "../../helpers/date";
import NpiDropdownEllipsis from "../../components/display/dropdown-ellipsis";
import _ from "lodash";

const NpiContainerAdminPosList = () => {
    useRestrictedToAdmin();

    const {loaded, setBreadcrumbs, user, isAllowedRegion} = useContext(NpiInternalContext);
    const {t} = useTranslation();
    const [posList, setPosList] = useState<IPos[]>([]);
    const [filters, setFilters] = useState<any>({}); //track changes to filters to display in the template modal
    const [total, setTotal] = useState<number>(0); //track changes to total to display in the template modal
    const {region_id: regionId} = user;
    const isWW = isAllowedRegion(REGION_WW);

    const {runAsync: fetchPos, loading: loadingPos} = useRequest<NpiServerPagination, any>(npiApi.internal.pos.list, {manual: true});
    const {runAsync: downloadPosLinks, loading: loadingDownload} = useRequest(npiApi.internal.file.exportPosLinks, {manual: true});
    const {runAsync: resetLinks} = useRequest(npiApi.internal.pos.resetLinks, {
        manual: true,
        onSuccess: (newPos: Pick<IPos, 'pos_id'|'external_links'>) => {
            const newList = _.cloneDeep(posList)!;
            const idx = newList.findIndex(p => p.pos_id === newPos.pos_id);
            newList[idx] = {...newList[idx], ...newPos};
            setPosList(newList);
        },
    });

    //Set navigation
    useEffect(() => setBreadcrumbs([
        {name: t('INTERNAL.CLIENT.COMMON.ADMINISTRATION')},
        {url: '/admin/pos', name: t('INTERNAL.CLIENT.COMMON.POS')},
    ]), [setBreadcrumbs, t]);


    const onDownload = () => {
         downloadPosLinks({filters: filters}).then(npiApiDownloadFileCallback(`pos-links-${nowFormat()}.xlsx`));
    }

    const onClickReset = (posId: number) => {
        Modal.confirm({
            title: t('INTERNAL.CLIENT.POS.LINKS.RESET'),
            content: <>
                <Trans t={t} i18nKey={'INTERNAL.CLIENT.POS.LINKS.RESET_WARNING'}/>
                <br/><br/>
                <Trans t={t} i18nKey={'INTERNAL.CLIENT.COMMON.LINK.RESET_WARNING_INFO'}/>
                <br/><br/>
                <em>
                    <Trans t={t} i18nKey={'INTERNAL.CLIENT.COMMON.LINK.RESET_WARNING_CONFIRM'}/>
                </em>
            </>,
            onOk: () => resetLinks(posId).then(null)
        })
    }

    //Columns for the POS table
    const columnsPos = useMemo<any>(() => [
        {title: t('INTERNAL.CLIENT.COMMON.APPLE_ID'), dataIndex: 'pos_id', sorter: true, fixed:true, filter: {mode: 'number', strict: true}},
        {title: t('INTERNAL.CLIENT.COMMON.STORE_NAME'), dataIndex: 'pos_name', sorter: true,  fixed:true, filter: {mode: 'text'}},
        {title: t('INTERNAL.CLIENT.COMMON.HQ_ID'), dataIndex: 'hq_id', sorter: true, filter: {mode: 'number', strict: true}},
        {title: t('INTERNAL.CLIENT.COMMON.RESELLER_NAME'), dataIndex: 'reseller_name', sorter: true,  filter: {mode: 'text'}},
        {title: t('INTERNAL.CLIENT.COMMON.CITY'), dataIndex: 'city', sorter: true,  filter: {mode: 'text'}},
        {title: t('INTERNAL.CLIENT.COMMON.ZIP_CODE'), dataIndex: 'postal_code', sorter: true,  filter: {mode: 'text'}},
        {title: t('INTERNAL.CLIENT.COMMON.COUNTRY'), dataIndex: 'country_id',
            render: (v:number) => <NpiDisplayCountry id={v}/>, sorter: true,
            filter:{mode:'custom', input: <NpiInputSelectCountry inRegions={regionId===REGION_WW?[]:[regionId]} mode="multiple" dropdownMatchSelectWidth={false}/>}
        },

        {title: t('INTERNAL.CLIENT.COMMON.EXTERNAL_ACCESS'), render: (pos: IPos) => <Space>

            <Dropdown overlay={<Menu items={[
                ...!!pos.external_links?.url ? [
                    {key:'view', icon: <LinkOutlined/>, label: <a href={pos.external_links?.url} target={"_blank"} rel={"noreferrer"}>{t('INTERNAL.CLIENT.COMMON.LINK.VIEW_CLASSIC')}</a>},
                ] : [],
                ...(!!pos.external_links?.dailyUrl && isWW) ? [
                    {key:'view-daily', icon: <ClockCircleOutlined/>, label: <a href={pos.external_links?.dailyUrl} target={"_blank"} rel={"noreferrer"}>{t('INTERNAL.CLIENT.COMMON.LINK.VIEW_DAILY')}</a>},
                ] : [],
            ]}/>}>
                <Tooltip title={t('INTERNAL.CLIENT.COMMON.LINK.TOOLTIP')}>{<Button type={"primary"} icon={<EyeOutlined/>}>{t('INTERNAL.CLIENT.COMMON.VIEW')}</Button>}</Tooltip>
            </Dropdown>

            <NpiDropdownEllipsis items={[
                {key: "reset-link", icon: <ReloadOutlined/>, label: t('INTERNAL.CLIENT.COMMON.LINK.RESET'), onClick:() => onClickReset(pos.pos_id)},
            ]}/>
        </Space>},

        // eslint-disable-next-line react-hooks/exhaustive-deps
    ], [regionId, t]);

    //Config for the table to display the list of POS with their Links for the current region
    const configTablePos = {
        rowKey: 'id',
        fetcher: fetchPos,
        dataSource: posList,
        onChange: (d:any) => setPosList(d),
        loading: loadingPos,
        scroll: {x: true as true, y: undefined},
        columns: columnsPos,
        onFiltersChange: setFilters,
        onTotalChange: setTotal,
        pagination: {showTotal: (total:number) => <b style={{marginRight: 20}}>{t('INTERNAL.CLIENT.COMMON.TOTAL_ROWS', {nbRows: numberFormat(total)})}</b>},
    };

    return <NpiDisplayLayoutContent>
        {loaded && <>
            <StyledOptions>
                <Button icon={<ShopOutlined />} onClick={onDownload} type={"primary"} loading={loadingDownload}> {t('INTERNAL.CLIENT.POS.LINKS.DOWNLOAD', {nbPOS: numberFormat(total)})}</Button>
            </StyledOptions>

            <NpiPaginatedTable {...configTablePos}/>
        </>}
    </NpiDisplayLayoutContent>
};

const StyledOptions = styled.div`
    position: absolute;
    z-index: 100;
    top: 15px;
    right: 20px;
`;

export default NpiContainerAdminPosList;