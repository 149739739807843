import React from 'react';
import {CheckCircleFilled, CloseCircleFilled} from "@ant-design/icons/lib";
import styled from "styled-components";
import colors from "../../services/colors";

const StyledSpan = styled.span`
    font-size: 20px;
    color: ${colors.greyDisabledText};
    &.true{
        color: ${colors.green};
    }
`;

const NpiDisplayBoolean = ({value}:{value:boolean}) => {
    return <StyledSpan className={value === true ? 'true':''}>
        {value === true?<CheckCircleFilled/>:<CloseCircleFilled/>}
    </StyledSpan>;
};

export default NpiDisplayBoolean;