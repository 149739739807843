import React, {Dispatch, useEffect, useState} from "react";
import {useControllableValue} from "ahooks";
import {Cascader, Col, Row, Select} from "antd";
import {IConditionActionTargetEnum, IConditionAction, IConditionActionDisplayEnum} from "../../../types/condition";
import {IQuestion} from "../../../types/question";
import styled from "styled-components";
import NpiDisplayValidatableCard from "../../display/validatable-card";
import {useTranslation} from "react-i18next";

const StyledCard = styled(NpiDisplayValidatableCard)`
    .ant-select, .ant-input-number, .ant-cascader-picker{
        width: 100%
    }
    label{
        line-height: 32px;
    }
`

const {QUESTION, ANSWER} = IConditionActionTargetEnum;
const {SHOW, HIDE} = IConditionActionDisplayEnum;

const NpiInputConditionAction = ({questions, ...props}:{questions:IQuestion[], value: IConditionAction, onChange:Dispatch<any>}) => {
    const [action, setAction] = useControllableValue<IConditionAction>(props)
    const [questionsOptions, setQuestionsOptions] = useState<any>();
    const [answersOptions, setAnswersOptions] = useState<any>();
    const {t} = useTranslation();

    const targetOptions = [{label: t('INTERNAL.CLIENT.COMMON.QUESTION'), value: QUESTION}, {label: t('INTERNAL.CLIENT.COMMON.ANSWER'), value: ANSWER}];
    const displayOptions = [{label: t('INTERNAL.CLIENT.COMMON.SHOW'), value: SHOW}, {label: t('INTERNAL.CLIENT.COMMON.HIDE'), value: HIDE}];

    //Apply action
    const apply = (action:IConditionAction) => {
        validate(action);
        setAction(action);
    }
    
    //Validate our action
    const validate = (action:IConditionAction) => {
        action.errors = [];
        if( action.target === QUESTION && ! action.questionId) action.errors.push(t('INTERNAL.CLIENT.CONDITION.SELECT_QUESTION'));
        if( action && action.target === ANSWER && ! action.answerId) action.errors.push(t('INTERNAL.CLIENT.CONDITION.SELECT_ANSWER'));

        if( ! action.errors.length){
            delete action.errors;
        }
    }

    //Set action
    const onChangeDisplay = (display:IConditionActionDisplayEnum) => {
        action.display = display;
        apply(action);
    }

    //Set target
    const onChangeTarget = (target:IConditionActionTargetEnum) => {
        action.target = target;
        apply(action);
    }

    //Set the selected answer
    const onChangeAnswerId = (answerId:any) => {
        delete action.questionId;
        action.answerId = answerId;
        apply(action);
    }

    //Set the selected question
    const onChangeQuestionId = (questionId:number) => {
        delete action.answerId;
        action.questionId = questionId;
        apply(action);
    }

    //Create a select of questions & another cascade for answers on first load
    useEffect(() => {
        const options = questions
            .filter(q => !!q.config.answers?.length) //only questions with answers
            .map(q => ({
                label: q.display_name,
                value: q.id,
                children: q.config.answers,
            }))

        setQuestionsOptions(questions.map(q => ({label: q.display_name, value: q.id})));
        setAnswersOptions(options)
        validate(action);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [questions]);

    return <StyledCard size="small" errors={action.errors}>
        <Row gutter={[10,10]}>

            {/************ ACTION SELECTION *********/}
            <Col span={4}><Select options={displayOptions} value={action.display} onChange={onChangeDisplay} defaultValue={HIDE}/></Col>

            {/************ TARGET SELECTION *********/}
            <Col span={10}><Select options={targetOptions} value={action.target} onChange={onChangeTarget}/></Col>

            {/************ QUESTION SELECTION *********/}
            {action.target === QUESTION && <Col span={20} offset={4}>
                <Select options={questionsOptions} value={action.questionId} onChange={onChangeQuestionId} dropdownMatchSelectWidth={false}/>
            </Col>}

            {/************ ANSWER SELECTION *********/}
            {action.target === ANSWER && <Col span={20} offset={4}>
                <Cascader options={answersOptions} value={action.answerId} displayRender={l=>l.join(' > ')} onChange={onChangeAnswerId}/>
            </Col>}
        </Row>
    </StyledCard>
}

export default NpiInputConditionAction;