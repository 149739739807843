import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {Button, Checkbox, Dropdown, Menu, Modal, notification, Select, Space, Spin, Tooltip} from "antd";
import {useParams} from 'react-router';
import NpiPaginatedTable, {INpiTableServerPaginatedProps} from "../components/paginated-table";
import npiApi from "../services/api";
import {NpiInternalContext} from "../contexts/internal-context";
import NpiDisplayPosTags from "../components/display/pos-tags";
import NpiDisplayDate from "../components/display/date";
import useAcl from "../hooks/use-acl";
import {useWaveStore} from "../contexts/stores";
import useWindowSize from "../hooks/use-window-size";
import styled from "styled-components";
import NpiDisplayPosStatus from "../components/display/pos-status";
import PackageVariantClosed from '@2fd/ant-design-icons/lib/PackageVariantClosed';
import FileImageOutline from '@2fd/ant-design-icons/lib/FileImageOutline';
import ClockTimeThree from '@2fd/ant-design-icons/lib/ClockTimeThree';
import DatabaseRemove from '@2fd/ant-design-icons/lib/DatabaseRemove';
import ArrowTopRightThinCircleOutline from '@2fd/ant-design-icons/lib/ArrowTopRightThinCircleOutline';
import NpiDisplayReportAnswerCell from "../components/display/report-answer-cell";
import {EditFilled} from "@ant-design/icons/lib";
import _ from "lodash";
import NpiInputForm from "../components/input/form";
import {useRequest} from "ahooks";
import moment from "moment";
import NpiDisplayNumber from "../components/display/number";
import NpiInputSelectCountry from "../components/input/select-country";
import NpiDisplayCountry from "../components/display/country";
import {IWavePosStatusEnum} from "../types/wave-pos";
import {IQuestion, IQuestionTypeEnum} from "../types/question";
import {IRightsEnum} from "../types/user";
import {IWaveStatusEnum} from "../types/wave";
import {INpi} from "../types/npi";
import NpiWaveTableReportModalReschedule from "../components/wave-table-report/modal-reschedule";
import {DownOutlined, FilterOutlined, StopOutlined} from "@ant-design/icons";
import NpiWaveTableReportModalExport from "../components/wave-table-report/modal-export";
import NpiDisplayProgram from "../components/display/program";
import NpiWaveTableReportModalShipment from "../components/wave-table-report/modal-shipments";
import {Link} from 'react-router-dom';
import {toJS} from "mobx";
import NpiDisplayMerchandisingGroup from "../components/display/merchandising-group";
import {parseAnswersFormQuestion} from "../helpers/form";
import {useTranslation} from "react-i18next";
import NpiInputCommentIcon from "../components/input/comment-icon";
import NpiInputSelectMerchandisingGroup from "../components/input/select-merchandising-group";
import useDisplayDateStr from "../hooks/use-display-date";

const {POSITIVE, NEUTRAL, NEGATIVE} = IWavePosStatusEnum;

const StyledQuestionTitle = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;
const StyledButtonBulk = styled(Dropdown)`
  position: absolute !important;
  top: 15px;
  right: 15px;
`;
const ButtonWithIcon = styled(Button)`.anticon{font-size: 18px; position: relative; top: 2px;}`;
const optionsStatus = [
    {label: <NpiDisplayPosStatus status={POSITIVE}/>, value: POSITIVE},
    {label: <NpiDisplayPosStatus status={NEUTRAL}/>, value: NEUTRAL},
    {label: <NpiDisplayPosStatus status={NEGATIVE}/>, value: NEGATIVE},
];

const makeQuestionFilter = (q: IQuestion) => {
    if ([IQuestionTypeEnum.LIST, IQuestionTypeEnum.BOOL].indexOf(q.type) >= 0) {
        return {title: q.name, mode: 'select', options: q.config.answers?.map(a => ({label: a.value, value: a.value}))};
    } else if (q.type === IQuestionTypeEnum.MULTILIST) {
        return {
            json: true,
            title: q.name,
            mode: 'select',
            options: q.config.answers?.map(a => ({label: a.value, value: a.value}))
        };
    } else {
        return {title: q.name, mode: 'text'};
    }
};

const NpiContainerWaveTableReport = () => {
    const params: any = useParams();
    const {wave, select, loaded} = useWaveStore();
    const {hasRegionFeatureAccess, isAdmin} = useAcl();
    const {formatDate} = useDisplayDateStr();
    const hasTags = hasRegionFeatureAccess("has_tags_access");
    const hasShipments = hasRegionFeatureAccess("has_shipment_access") && wave.shipment_code_id;
    const hasMerchGroups = hasRegionFeatureAccess("has_merchandising_groups_access");
    const {setBreadcrumbs, programs} = useContext(NpiInternalContext);
    const {t} = useTranslation();
    const {height} = useWindowSize();
    const [editAnswers, setEditAnswers] = useState<any>();
    const [total, setTotal] = useState<number>(0);
    const [editTags, setEditTags] = useState<any>();
    const [editFields, setEditFields] = useState<any>({});
    const [visible, setVisible] = useState<any>();
    const [latestChange, setLatestChange] = useState<any>(); //used to trigger table refresh
    const [selectedRowsKeys, setSelectedRowsKeys] = useState<any[]>([]);
    const [tableRows, setTableRows] = useState<any[]>([]);
    const [npi, setNpi] = useState<INpi>();
    const [modalVisible, setModalVisible] = useState({shipments: false, images: false, reschedule: false, export: false});
    const [shipmentRow, setShipmentRow] = useState<any>({});
    const [rescheduleRow, setRescheduleRow] = useState<any>(null);
    const [filters, setFilters] = useState<any>({}); //track changes to filters to display in the template modal
    const hasTableRows = !!tableRows?.length;
    const canEdit = useAcl().hasRight(IRightsEnum.EDIT_WAVE_POS_REPORT);
    const canViewSensitive = useAcl().hasRight(IRightsEnum.VIEW_SENSITIVE_DATA);
    const {runAsync: saveMultiPosAnswers, loading: loadingSave} = useRequest(npiApi.internal.wave.saveMultiPosAnswers, {manual: true});
    const {runAsync: resetPos} = useRequest(npiApi.internal.wave.resetPos, {manual: true});
    const {runAsync: updatedAuditedPos} = useRequest(npiApi.internal.wave.updateAuditedPos, {manual: true});
    // const [exportRowType, setExportRowType] = useState<"labels"|"value">(DEFAULT_EXPORT_TYPE);

    //Select our wave based on url param
    useEffect(() => {
        select(params.waveId).then(({wave, npi}: any) => {
            setBreadcrumbs([
                {url: '/npi', name: 'NPI'},
                {url: '/npi/' + npi.id, name: npi.name},
                {url: '/wave/' + wave.id + '/report', name: wave.name + (wave.daily_group_id ? ` (${formatDate({value: wave.launched_at, format: "L"})})` : '')},
                {name: t('INTERNAL.CLIENT.COMMON.REPORT')},
            ]);
            setNpi(npi);
        });
    }, [params.waveId, select, setBreadcrumbs, t, formatDate]);


    //Open the shipment modal
    const openModalShipments = useCallback((row) => {
        setShipmentRow(row);
        setModalVisible({...modalVisible, shipments: true});
    }, [modalVisible]);

    //Close reschedule modal
    const closeModalShipment = useCallback((row) => {
        setModalVisible({...modalVisible, shipments: false});
    }, [modalVisible]);

    //Open reschedule modal
    const openModalReschedule = useCallback((row:any) => {
        if( !row && total === 0 ) {
            notification.warn({message: t('INTERNAL.CLIENT.REPORT.NO_POS_RESCHEDULE')});
            return;
        }
        setRescheduleRow(row);
        setModalVisible({...modalVisible, reschedule: true});
    }, [modalVisible, total, t]);

    //Close reschedule modal
    const closeModalReschedule = useCallback((row?:any) => {
        setModalVisible({...modalVisible, reschedule: false});
        if( !!row ) {
            setLatestChange(moment());
        }
    }, [modalVisible]);

    //Change audited checkbox
    const onChangeAudited = useCallback(({wave_id, merchandising_group_is_audited, pos_id}:any, e:any) => {
        updatedAuditedPos({wave_id, pos_id, merchandising_group_is_audited: e.target.checked} as any);
    }, [updatedAuditedPos]);

    //Change audited comment
    const onChangeAuditedComment = useCallback(({wave_id, merchandising_group_comment, pos_id}:any, value:string) => {
        updatedAuditedPos({wave_id, pos_id, merchandising_group_comment: value} as any);
    }, [updatedAuditedPos]);

    //Open the shipment modal
    const openModalReset = useCallback((row) => {
        Modal.confirm({
            title: <>{t('INTERNAL.CLIENT.REPORT.RESET_POS', {pos_id:  row.pos_id, name:  row.name})}</>,
            content: <div>
                {t('INTERNAL.CLIENT.REPORT.RESET_POS_CONFIRMATION')} <br/>
                {t('INTERNAL.CLIENT.COMMON.INFORMATION_ERASED_WARNING')}
                <ul>
                    <li>{t('INTERNAL.CLIENT.COMMON.ANSWERS')}</li>
                    <li>{t('INTERNAL.CLIENT.COMMON.IMAGES')}</li>
                    <li>{t('INTERNAL.CLIENT.REPORT.STATUS_CHANGES_HISTORY')}</li>
                    <li>{t('INTERNAL.CLIENT.REPORT.UPLOADED_KPIS_VALUES')}</li>
                </ul>
                <b>{t('INTERNAL.CLIENT.COMMON.IRREVERSIBLE_OPERATION')}</b>
            </div>,
            width: 500,
            okType: "danger",
            okText: t('INTERNAL.CLIENT.COMMON.RESET'),
            onOk: () => resetPos({wave_id: row.wave_id, resetAppleIds: [row.pos_id]})
                .then(() => {
                    setLatestChange(moment());
                    notification.success({message: <>{t('INTERNAL.CLIENT.REPORT.RESET_POS_SUCCESS', {pos_id:  row.pos_id, name:  row.name})}</>})
                })
            ,
        });
    }, [resetPos, t]);

    //Opens a modal to edit answers
    const openModalEdit = useCallback(() => {
        const selectedRows: any = tableRows.filter(row => selectedRowsKeys.indexOf(row.id) >= 0);

        //Check tags
        const hasTags = !_.isEmpty(toJS(wave.tags));
        const tags = selectedRows[0].tags;
        if (hasTags && selectedRows.filter((r: any) => !_.isEqual(r.tags, tags)).length) {
            notification.error({message: t('INTERNAL.CLIENT.REPORT.UNABLE_EDIT_POS_TAGS')});
            return;
        }

        //
        const merchandisingGroupId = selectedRows[0].merchandising_group_id;
        if(selectedRows.filter((r:any) => !_.isEqual(r.merchandising_group_id, merchandisingGroupId)).length){
            notification.error({message: t('INTERNAL.CLIENT.REPORT.UNABLE_EDIT_POS_MERCHANDISING_GROUP')});
            return;
        }

        //Prepare the answers object
        const answers: any = [];
        wave.questions.forEach(q => {
            const field = 'q' + q.id;
            answers.push({
                question_id: q.id,
                value: _.reduce(selectedRows, (value, row) => row[field] !== value ? null : value, selectedRows[0][field]),
                date_value: _.reduce(selectedRows, (value, row) => row['date_' + field] !== value ? null : value, selectedRows[0]['date_' + field]),
            });
        });

        setEditTags(tags);
        setEditFields({merchandising_group_id: merchandisingGroupId});
        setEditAnswers(answers);
        setVisible(true);
    }, [selectedRowsKeys, tableRows, wave, t]);

    //Columns
    const columns = useMemo(() => ([
            //{render: (_:any, row:any) => <Button type="link" icon={<EditFilled/>}/>, fixed: true},
            {
                title: t('INTERNAL.CLIENT.COMMON.APPLE_ID'),
                dataIndex: 'pos_id',
                width: 110,
                ellipsis: true,
                fixed: hasTableRows,
                filter: {mode: 'text', strict: true}
            },
            {
                title: t('INTERNAL.CLIENT.COMMON.STORE_NAME'),
                dataIndex: 'name',
                width: 400,
                ellipsis: true,
                filter: {mode: 'text'},
                fixed: hasTableRows,
                render: (name: string, row: any) => {
                    let posLink = null;
                    if(wave.is_available_in_unified_links) {
                        if( !!wave.daily_group_id ){
                           posLink = row.external_links?.dailyUrl ?? null;
                       } else if( IWaveStatusEnum.LAUNCHED ) {
                           posLink = row.external_links?.url ?? null;
                       }
                    }
                    return <span>
                        {name}
                        {!!posLink && <Button type="link" icon={<ArrowTopRightThinCircleOutline/>} href={posLink} target={"_blank"}/>}
                    </span>
                }
            },
            {title: t('INTERNAL.CLIENT.COMMON.PARTNER'), dataIndex: 'f_company_name', width: 170, ellipsis: true, filter: {mode: 'text'}},
            {
                title: t('INTERNAL.CLIENT.COMMON.MERCH_VENDOR_AUDITED'),
                dataIndex: 'merchandising_group_is_audited', width: 170,
                filter: {mode: 'bool'},
                hidden: !hasMerchGroups,
                render: (name: string, row:any) => <div>
                    <Checkbox defaultChecked={row.merchandising_group_is_audited} onChange={(checked) => onChangeAudited(row, checked)}/>
                    <NpiInputCommentIcon defaultValue={row.merchandising_group_comment} onChange={(value:string)=>onChangeAuditedComment(row, value)}/>
                </div>,
            },
            {title: t('INTERNAL.CLIENT.COMMON.REGION'), dataIndex: 'f_region_name', width: 170, ellipsis: true, filter: {mode: 'text'}},
            {
                title: t('INTERNAL.CLIENT.COMMON.COUNTRY'), dataIndex: 'country_id', width: 170, ellipsis: true,
                render: (v: number, row: any) => <NpiDisplayCountry id={v}/>,
                filter: {
                    mode: 'custom',
                    input: <NpiInputSelectCountry inRegions={[wave.region_id]} mode="multiple" dropdownMatchSelectWidth={false}/>
                },
            },
            // {title: 'RTM', dataIndex: 'f_rtm', width: 160, ellipsis: true, filter: {mode: 'text'}},
            {
                title: t('INTERNAL.CLIENT.COMMON.PROGRAM'),
                dataIndex: 'f_program',
                width: 180,
                ellipsis: true,
                filter: {mode: 'select', options: programs},
                render: (v:string) => <NpiDisplayProgram value={v}/>,
            },
            {
                title: t('INTERNAL.CLIENT.COMMON.TAGS'), dataIndex: 'tags', width: 350, hidden: !hasTags || !canViewSensitive, ellipsis: true,
                render: (value: any) => <NpiDisplayPosTags value={value} tags={wave.tags} wrap={true}/>,
                filter: {mode: 'tags', inputProps: {waves: [wave.id], npiId: wave.npi_id}},
            },
            {
                title: t('INTERNAL.CLIENT.COMMON.MERCHANDISING_GROUP'), dataIndex: 'merchandising_group_id', width: 180, hidden: !hasMerchGroups, ellipsis: true, align: 'center',
                render: (value: any) => <NpiDisplayMerchandisingGroup value={value}/>,
                filter: {
                    mode: 'custom',
                    input: <NpiInputSelectMerchandisingGroup regionId={wave.region_id} mode="multiple" dropdownMatchSelectWidth={false}/>
                },
            },
            {
                title: t('INTERNAL.CLIENT.COMMON.ADDED_ON'), dataIndex: 'created_at', width: 120, ellipsis: true,
                render: (v: string) => <NpiDisplayDate value={v}/>,
                filter: {mode: 'date'},
            },
            {
                title: t('INTERNAL.CLIENT.COMMON.STATUS'), dataIndex: 'status', width: 90, align: 'center', ellipsis: true,
                render: (v: number) => <NpiDisplayPosStatus status={v}/>,
                filter: {mode: 'custom', input: <Select options={optionsStatus} dropdownMatchSelectWidth={false}/>},
            },
            {
                title: t('INTERNAL.CLIENT.COMMON.RESCHEDULE'), width: 165, ellipsis: true, dataIndex: 'reschedule_enabled', align: 'center',
                render: (v: any, row: any) => row.nb_answers === 0 ?
                    <Tooltip title={t('INTERNAL.CLIENT.REPORT.NO_ANSWER_STORED_RESCHEDULE')}>
                        <Button icon={<ClockTimeThree/>} onClick={() => openModalReschedule(row)} block
                                type={v ? "primary" : "default"} danger={v}>{v ? t('INTERNAL.CLIENT.COMMON.RESCHEDULED') : t('INTERNAL.CLIENT.COMMON.RESCHEDULE')}</Button>
                    </Tooltip> : '-',
                filter: {mode: 'number'},
            },
            {
                title: t('INTERNAL.CLIENT.COMMON.IMAGES'), width: 100, ellipsis: true, dataIndex: 'nb_images', align: 'center',
                render: (v: any, row: any) => (!!v
                    ? <Link to={"/gallery/wave/" + wave.id + "/" + row.pos_id}><ButtonWithIcon block>{v}<FileImageOutline/></ButtonWithIcon></Link>
                    : (wave.is_images_allowed ? '-' : <StopOutlined/>)),
            },
            //{title: 'Reschedule', width: 80},
            {
                title: t('INTERNAL.CLIENT.COMMON.SHIPMENTS'),
                dataIndex: 'nb_shipments',
                width: 120,
                ellipsis: true,
                align: 'center',
                hidden: !hasShipments,
                render: (v: any, row: any) => !!v ? <ButtonWithIcon block onClick={() => openModalShipments(row)}>{v}
                    <PackageVariantClosed/></ButtonWithIcon> : '-',
                filter: {mode: 'number'},
            },
        ] as any[])
            //Add questions columns
            .concat(wave.questions?.map(q => ({
                title: <Tooltip title={q.name}><StyledQuestionTitle>{q.name}</StyledQuestionTitle></Tooltip>,
                dataIndex: 'q' + q.id,
                render: (_: any, row: any) => <NpiDisplayReportAnswerCell value={row['q' + q.id]}
                                                                          question={q}
                                                                          dateValue={row['date_q' + q.id]}
                                                                          linkGallery={q.type === IQuestionTypeEnum.IMAGE ? "/gallery/wave/" + wave.id + "/" + row.pos_id + "?q=" + q.id : null}
                />,
                align: 'center',
                width: 180,
                ellipsis: true,
                filter: makeQuestionFilter(q),
            })))
            //Add action buttons
            .concat([
                {
                    width: 100, align: 'right', hidden: !isAdmin,
                    render: (row: any) => <Space>
                        <Button icon={<DatabaseRemove/>} danger type="primary" size="small"
                                onClick={() => openModalReset(row)}>{t('INTERNAL.CLIENT.COMMON.RESET')}</Button>
                    </Space>
                }
            ])
            //Remove hidden columns
            .filter(v => !v?.hidden)
        , [wave, hasTags, hasMerchGroups, hasShipments, hasTableRows, openModalShipments, openModalReset, openModalReschedule, programs, isAdmin, t, onChangeAudited, onChangeAuditedComment, canViewSensitive]);

    //Fetch POS data
    const fetcher = useCallback((params) => {
        return npiApi.internal.wave.reportPos({waveId: wave.id, ...params}).then((data: any) => {
            setTotal(data.total);
            return data;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wave.id, latestChange]);

    //Selection
    const rowSelection = useMemo(() => ({
        fixed: hasTableRows,
        onChange: (selectedRowsKeys: any) => {
            setSelectedRowsKeys(selectedRowsKeys);
        },
    }), [hasTableRows]);

    //Summary of table
    const footer = useCallback(() => {
        return <>
            <Space>
                {canEdit && <Button type="primary" icon={<EditFilled/>} onClick={openModalEdit} disabled={!selectedRowsKeys.length}>
                    {t('INTERNAL.CLIENT.COMMON.EDIT_NB_SELECTED_ROWS', {nbSelectedRows: selectedRowsKeys.length })}
                </Button>}
                <NpiWaveTableReportModalExport npi={npi}/>
            </Space>
            <b style={{float: 'right'}}><NpiDisplayNumber value={total}/> {t('INTERNAL.CLIENT.COMMON.POS')}</b>
        </>
    }, [openModalEdit, selectedRowsKeys, total, canEdit, npi, t]);

    //Table configuration
    const tableConfig: INpiTableServerPaginatedProps<any> = useMemo(() => {
        const config = {
            fetcher,
            columns,
            sticky: true,
            footer,
            defaultFilters: params.filters ? JSON.parse(params.filters) as any : {},
            onFiltersChange: setFilters,
            onChange: (d: any) => setTableRows(d),
            //bordered: true,

            pagination: {
                hideOnSinglePage: true,
                pageSizeOptions: ['30', '50', '100', '200'],
                defaultPageSize: 30,
            },
            tableLayout: 'fixed',
            scroll: {
                x: _.sumBy(columns, 'width'),
                y: height - 62/*header*/ - 64/*breadcrumbs*/ - 55/*table header*/ - 64/*pagination*/ - 64/*table footer*/,
            },
        } as INpiTableServerPaginatedProps<any>;

        if (canEdit) {
            config.rowSelection = rowSelection;
        }

        return config;
    }, [columns, fetcher, footer, height, params.filters, rowSelection, canEdit, setFilters]);

    //Modal configuration
    const modalConfig = {
        open: visible,
        width: 800,
        title: <span>{t('INTERNAL.CLIENT.REPORT.EDITING_NBANSWERS_POS', {nbAnswers: selectedRowsKeys.length})}</span>,
        confirmLoading: loadingSave,
        onCancel: () => setVisible(false),
        onOk: () => saveMultiPosAnswers({
            wave_id: wave.id,
            answers: editAnswers,
            tags: editTags,
            pos_ids: _.chain(tableRows).filter((r: any) => selectedRowsKeys.indexOf(r.id) >= 0).map('pos_id').value(),
        }).then(({wavePos, nbChanges, nbPosChanged}: any) => {
            notification.success({
                message: <ul>
                    <li>{t('INTERNAL.CLIENT.REPORT.NBPOS_POS_MODIFIED', {nbPos: nbPosChanged})}</li>
                    <li>{t('INTERNAL.CLIENT.REPORT.NBANSWERS_ANSWERS_MODIFIED', {nbAnswers: nbChanges})}</li>
                </ul>
            });
            setVisible(false);
            setLatestChange(moment());
        }),
    };

    if (!loaded) {
        return <Spin/>;
    }

    return <>
        <StyledButtonBulk disabled={!total} trigger={["click"]} overlay={
            <Menu items={[
                {key:"reschedule", label: <><ClockTimeThree/> {t('INTERNAL.CLIENT.COMMON.RESCHEDULE')}</>, onClick: () => openModalReschedule(null)},
            ]}/>
        }>

            <Button type={"primary"} icon={<FilterOutlined />} title={t('INTERNAL.CLIENT.COMMON.PERFORM_ACTIONS_ON_FILTERED')}>
                {t('INTERNAL.CLIENT.REPORT.BULK_ACTIONS_TOTAL_POS', {nbTotal: total})} <DownOutlined/>
            </Button>
        </StyledButtonBulk>
        <NpiPaginatedTable {...tableConfig}/>
        <NpiWaveTableReportModalReschedule waveId={wave.id} row={rescheduleRow} filters={filters} total={total} open={modalVisible.reschedule} onClose={closeModalReschedule}/>
        <NpiWaveTableReportModalShipment row={shipmentRow} regionId={wave.region_id} open={modalVisible.shipments} onClose={closeModalShipment}/>
        <Modal {...modalConfig}>
            <NpiInputForm item={wave} tags={editTags} fields={editFields} languageId={wave.language_id} answers={editAnswers ? parseAnswersFormQuestion(editAnswers, wave.questions) : editAnswers} onChange={setEditAnswers} disableImageQuestion={true}/>,
        </Modal>
    </>
};

export default NpiContainerWaveTableReport;