import React, {useCallback} from 'react';
import {observer} from "mobx-react";
import npiApi from "../../services/api";
import NpiModalTemplateUploader from "../display/modal-template-uploader";
import {notification} from "antd";
import {Trans, useTranslation} from "react-i18next";
import {nowFormat} from "../../helpers/date";
import styled from "styled-components";

interface IManageKpis {
    waveId: number
}

const StyledInvalidValues = styled.span`
    display: inline-block;
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
`;

/**
 * This component is shown in a modal and is used to download/upload template of kpis for a wave
 */
const NpiModalManageKpis = observer(({waveId}:IManageKpis) => {
    const {t} = useTranslation();

    const exportKpis = useCallback((params:any) => npiApi.internal.file.exportWavePosKpis({...params, waveId}), [waveId]);
    const importKpis = useCallback((params:any) => npiApi.internal.file.importWavePosKpis({...params, waveId}), [waveId]);
    const onUploadSuccess = (response:any) => {
        const hasErrors = response.invalid_kpis.length > 0 || response.invalid_pos.length > 0 || response.invalid_value.length > 0 ;

        notification.open({
            duration: null,
            type: hasErrors ? 'warning' : 'success',
            message: <>
                <Trans i18nKey="INTERNAL.CLIENT.WAVE.NB_VALUES_SAVED" t={t} values={{ nbValues: response.import}} components={{ bold: <strong /> }}/>
            </>,
            description: <ul>
                <li>
                    <Trans i18nKey="INTERNAL.CLIENT.WAVE.FOUND_KPIS_AND_POS" t={t} values={{ nbKpis: response.kpis, nbPos: response.pos}} components={{ bold: <strong /> }}/>
                </li>
                {response.invalid_kpis.length > 0 ? <li>
                    <Trans i18nKey="INTERNAL.CLIENT.WAVE.UNKNOWN_KPIS" t={t} values={{ nbInvalidKpis: response.invalid_kpis.length}} components={{ bold: <strong /> }}/>
                    <StyledInvalidValues>{response.invalid_kpis.join(', ')}</StyledInvalidValues>
                </li> : '' }
                {response.invalid_pos.length > 0 ? <li>
                    <Trans i18nKey="INTERNAL.CLIENT.WAVE.UNKNOWN_POS" t={t} values={{ nbPos: response.invalid_pos.length}} components={{ bold: <strong /> }}/>
                    <StyledInvalidValues>{response.invalid_pos.join(', ')}</StyledInvalidValues>
                </li> : '' }
                {response.invalid_value.length > 0 ? <li>
                    <Trans i18nKey="INTERNAL.CLIENT.WAVE.WRONG_VALUES" t={t} values={{ nbValues: response.invalid_value.length}} components={{ bold: <strong /> }}/>
                    <StyledInvalidValues>{response.invalid_value.join(', ')}</StyledInvalidValues>
                </li> : '' }
            </ul>,
        });
    };

    return <NpiModalTemplateUploader
        textConfirm={t('INTERNAL.CLIENT.WAVE.KPI_UPLOAD_WARNING')}
        textDownloadButton={t('INTERNAL.CLIENT.DASHBOARD.DOWNLOAD_TEMPLATE')}
        textUploadButton={t('INTERNAL.CLIENT.WAVE.UPLOAD_KPIS_VALUES')}
        axiosDownload={exportKpis}
        axiosUpload={importKpis}
        onChange={onUploadSuccess}
        filename={`template-pos-kpis-wave-${waveId}-${nowFormat()}.xlsx`}
    />
});

export default NpiModalManageKpis;