import React from "react";
import CalendarMonthOutline from '@2fd/ant-design-icons/lib/CalendarMonthOutline';
import FileImageOutline from '@2fd/ant-design-icons/lib/FileImageOutline';
import {Button, Tooltip} from "antd";
import NpiDisplayDate from "./date";
import styled from "styled-components";
import colors from "../../services/colors";
import {IQuestionTypeEnum} from "../../types/question";


const ButtonWithIcon = styled(Button)`
  width: auto !important;
  .anticon{font-size: 18px; position: relative; top: 2px;}
`;

const StyledSpanContainer = styled.span`
  .anticon{
    color: ${colors.greyDisabledText};
  }
`;

/**
 * This will display helpful tags about the question
 * @param question
 * @param conditions
 * @constructor
 */
const NpiDisplayReportAnswerCell = ({value, question, dateValue, linkGallery=null}:any) => {
    let finalValue = value;
    if(question.type === IQuestionTypeEnum.MULTILIST){
        finalValue = value ? JSON.parse(value)?.join(', ') : value;
    }
    return <>
        {!!linkGallery && value
            ? <ButtonWithIcon block href={linkGallery}>
                {value}<FileImageOutline/>
            </ButtonWithIcon>
            : <StyledSpanContainer><Tooltip title={finalValue}>{finalValue}</Tooltip></StyledSpanContainer>
        }
        <StyledSpanContainer>
            {!!dateValue && <Tooltip title={<NpiDisplayDate value={dateValue}/>}>&nbsp;<CalendarMonthOutline/></Tooltip>}
        </StyledSpanContainer>
    </>
};

export default NpiDisplayReportAnswerCell;