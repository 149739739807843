import React from 'react';
import {Tabs, Row, Col, Badge} from "antd";
import {QuestionCircleOutlined, TranslationOutlined} from '@ant-design/icons';
import {IWave} from "../../types/wave";
import {observer} from "mobx-react";
import DirectionsFork from '@2fd/ant-design-icons/lib/DirectionsFork';
import colors from "../../services/colors";
import npiApi from "../../services/api";
import NpiConfigFormQuestions from "../config/form-questions";
import NpiConfigFormSimulation from "../config/form-simulation";
import {useWaveStore} from "../../contexts/stores";
import NpiConfigFormConditions from "../config/form-conditions";
import NpiConfigFormTranslations from "../config/form-translations";
import {useTranslation} from "react-i18next";

const NpiWaveTabForm = observer(({wave}:{wave:IWave}) => {
    const store = useWaveStore();
    const {t} = useTranslation();
    const tabs = [{
        key: '1',
        label: <><QuestionCircleOutlined /> {t('INTERNAL.CLIENT.COMMON.QUESTIONS')}</>,
        children: <NpiConfigFormQuestions
            item={wave}
            defaultItem={{wave_id: wave.id}}
            saveQuestion={npiApi.internal.wave.saveQuestion}
            replaceQuestionsByWaveId={npiApi.internal.wave.replaceQuestionsByWaveId}
        />,
    },{
        key: '2',
        label: <><DirectionsFork /> {t('INTERNAL.CLIENT.COMMON.CONDITIONS')} <Badge count={wave.conditions?.length} style={{backgroundColor: colors.blue}}/></>,
        children: <NpiConfigFormConditions item={wave} store={store}/>,
    },{
        key: '3',
        label: <><TranslationOutlined /> {t('INTERNAL.CLIENT.COMMON.TRANSLATIONS')}</>,
        children: <NpiConfigFormTranslations item={wave} languageId={wave.language_id} translationIds={wave.translation_ids} store={store} fileImport={npiApi.internal.file.importQuestionTranslationsWave} />,
    },];

    return <Row gutter={[20, 20]}>
        <Col span={12}>
            <h1>{t('INTERNAL.CLIENT.COMMON.FORM_CONFIGURATION')}</h1>
            <Tabs size="large" destroyInactiveTabPane items={tabs}/>
        </Col>
        <Col span={12}>
            <h1>{t('INTERNAL.CLIENT.COMMON.FORM_SIMULATION')}</h1>
            <NpiConfigFormSimulation item={wave} npiId={wave.npi_id}/>
        </Col>
    </Row>
});

export default NpiWaveTabForm;