import React, {useLayoutEffect, useRef} from "react";
import {Carousel} from "antd";
import {CarouselProps, CarouselRef} from "antd/lib/carousel";
import {useDebounceFn} from "ahooks";

interface NpiSwapperType extends CarouselProps{
    page: number
    delay?: number
}

const NpiSwapper = ({page, delay= 0, children, ...props}:NpiSwapperType) => {
    const slider = useRef<CarouselRef|null>();
    const goTo = (dotPosition:number) => slider.current?.goTo(dotPosition);
    const {run: goToDelayed} = useDebounceFn(goTo, {wait: delay, trailing: true});

    useLayoutEffect(() => {
        delay ? goToDelayed(page):goTo(page);
    }, [delay, goToDelayed, page])

    return <Carousel ref={ref => slider.current = ref} dots={false} {...props}>
        {children}
    </Carousel>
}

export default NpiSwapper;