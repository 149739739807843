import React, {FunctionComponent, useCallback, useState} from 'react';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import npiApi, {npiApiDownloadFileCallback} from "../../services/api";
import { IServerSideDatasource } from "ag-grid-community";
import NpiModalEdit from "../modal-edit";
import {Button, Col, notification, Row, Select, Tooltip} from "antd";
import { DeleteOutlined, EditOutlined, FileExcelOutlined } from "@ant-design/icons/lib";
import FormEditShipment from "./form-edit-status";
import { ICellRendererParams } from "ag-grid-community/dist/lib/rendering/cellRenderers/iCellRenderer";
import { useControllableValue } from "ahooks";
import {useTranslation} from "react-i18next";
import {nowFormat} from "../../helpers/date";
import {IShipmentCarrier} from "../../types/shipment";
import _ from 'lodash';

const { Option } = Select;

interface IshipmentTableProps {
    shipmentCode?: number,
    carriers?: IShipmentCarrier[],
    onGridChange: () => void,
    filters? : any
    gridFilters: any,
    onGridFiltersChange: (gridFilters: any) => void,
}

type Props = IshipmentTableProps;

// TODO ABE why ag-grid and not antd paginated table ?

const NpiShipmentTable: FunctionComponent<Props> = ({ shipmentCode, carriers, onGridChange, filters, ...props }) => {

    const { t } = useTranslation();

    const [gridApi, setGridApi] = useState<any>(null);
    const [columnApi, setColumnApi] = useState<any>(null);
    const [pagination, setPagination] = useState<number>(30);

    const [gridFilters, setGridFilters] = useControllableValue<{ [key: string]:  any}>(props, {
        defaultValue: {},
        valuePropName: 'gridFilters',
        trigger: 'onGridFiltersChange'
    } );

    const onGridReady = ({api, columnApi} : any ) => {
        setGridApi(api);
        setColumnApi(columnApi);
    }

    const defaultColDef = {
        filter: 'agTextColumnFilter',
        sortable: true,
        filterParams: {
            applyButton: true,
            resetButton: true,
        }
    };

    const dataSource : IServerSideDatasource  = {
        // -- called by the grid when rows are required
        getRows: (params : any) => {
            const { startRow = 0, sortModel, filterModel } = params.request;
            const pageId = startRow / pagination + 1;

            // -- get data from server
           return npiApi.internal.shipments
                .list( { filters, filterModel, sortModel, linesPerPage : pagination }, pageId )
                .then( (r: any) => {
                    params.success( { rowData : r.data, rowCount: r.total });
                } ).catch(() => params.fail());
        }
    };

    /**
     * Export data to xlsx file with all filtered data
     */
    const [exportLoading, setExportLoading] = useState<boolean>(false);
    const exportData = () => {

        if( !shipmentCode ){
            return;
        }

        setExportLoading(true); // FIXME ABE unwanted reloading table

        const names: string[] = [];
        const fields : string[] = [];

        const columns : any[] = columnApi.getAllColumns();

        columns.forEach(({colDef}) => {
            const { headerName, field } = colDef;
            if( !!field ) {
                names.push(headerName);
                fields.push(field);
            }
        });

        const payload = {filters, filterModel: gridFilters, names, fields}

        npiApi
            .internal
            .file
            .exportShipments(payload)
            .then(npiApiDownloadFileCallback(`shipments-${shipmentCode}-${nowFormat()}.xlsx`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'))
            .catch(e => console.error(e))
            .finally(() => setExportLoading(false))
    }

    const onPaginationChange = (value: number) => {
        setPagination(value);
        gridApi.paginationSetPageSize( value )
    };

    const cellWithTooltipRenderer = (params: ICellRendererParams) : JSX.Element => {
        const { value } = params;
        return (<Tooltip placement="topLeft" title={ value }> {value} </Tooltip>);
    }

    /**
     * Remove a Shipment
     */
    const onClickDeleteShipment = (id: any, api:any) => {
        if (window.confirm('Are you sure you want to delete this shipment ?')) {
            const payload = { shipments : [id]};
            npiApi.internal.shipments.delete(payload)
                .then((countDeleted) => {
                    notification.warning({message: countDeleted+" shipments deleted."});
                    // reload table
                    api.refreshServerSideStore({});
                    // update charts
                    onGridChange();
                })
        }
    }
    const cellDeleteRenderer = (params: ICellRendererParams) : JSX.Element => {
        const { data, api } = params;
        return (
            <Tooltip placement="topLeft" title="Delete" >
                <Button onClick={() => onClickDeleteShipment(data.id, api)} type="primary" danger icon={<DeleteOutlined />} size="small" />
            </Tooltip>
        );
    }

    const cellTrackingUrlRenderer = useCallback((params: ICellRendererParams) : JSX.Element => {
        const { data } = params;
        const carrier = _.find(carriers, {carrier_name: data.carrier});
        if( !carrier ){
            return <></>;
        }
        const url = carrier.tracking_url.replace('%code%', data.tracking_id);
        return <a href={url} target={"_blank"} rel={"noreferrer"}>{url}</a>
    }, [carriers]);


    /**
     * Edit a Shipment
     */
    const cellEditRenderer = (params : ICellRendererParams) : JSX.Element => {
        const { data, api } = params;
        return (
           <NpiModalEdit key={data.id}
                          title="Shipment"
                          value={data}
                          formProps={{layout: 'horizontal'}}
                          onChange={(value) => npiApi.internal.shipments.updateFromInternal(value)
                              .then(() => {
                                  notification.success({message: "Status updated."});
                                  // reload table
                                  api.refreshServerSideStore({});
                                  // update charts
                                  onGridChange();
                              })
                          }
                          button={<Button type="primary" icon={<EditOutlined  />} size="small" />} >
                <FormEditShipment />
            </NpiModalEdit>
        );
    }

    const reactComponents = {
        cellEditRenderer,
        cellWithTooltipRenderer,
        cellDeleteRenderer,
        cellTrackingUrlRenderer,
    };

  return (
      <Row gutter={ [ 16, 5 ] }>
          <Col span={12}>
              <Tooltip placement="right" title="Export table as .xlsx">
                  <Button onClick={exportData} loading={exportLoading} shape="circle" type="primary" icon={<FileExcelOutlined  />} />
              </Tooltip>
          </Col>
          <Col style={{textAlign: 'right'}} span={12}>
              <Select defaultValue={pagination} style={{ width: 120 }} onChange={onPaginationChange}>
                  <Option value={30}>30</Option>
                  <Option value={50}>50</Option>
                  <Option value={100}>100</Option>
                  <Option value={500}>500</Option>
              </Select>
          </Col>
          <Col span={24}>
              <div className="ag-theme-alpine" style={{height: 600, width: "100%"}}>
                  <AgGridReact
                      onFilterChanged={({api}) => {
                          setGridFilters(api.getFilterModel());
                      }}
                      getRowNodeId={data => data.id}
                      frameworkComponents={reactComponents}
                      rowModelType={'serverSide'}
                      serverSideStoreType={'partial'}
                      serverSideDatasource={dataSource}
                      blockLoadDebounceMillis={500} // prevent too many server calls
                      onGridReady={onGridReady}
                      pagination={true}
                      paginationPageSize={pagination}
                      cacheBlockSize={pagination}
                      animateRows={true}
                      defaultColDef={defaultColDef}>
                      <AgGridColumn field="" headerName="" cellRenderer={'cellEditRenderer'} width={50}/>
                      <AgGridColumn field="receipt_status_converted" headerName={t('INTERNAL.CLIENT.SHIPMENT.SHIPMENT_TABLE.VENDOR_STATUS')}/>
                      <AgGridColumn field="status" headerName={t('INTERNAL.CLIENT.SHIPMENT.SHIPMENT_TABLE.STATUS')}/>
                      <AgGridColumn field="status_updated_at" headerName={t('INTERNAL.CLIENT.SHIPMENT.SHIPMENT_TABLE.STATUS_DATE')}/>
                      <AgGridColumn field="f_sfo_store_id" headerName={t('INTERNAL.CLIENT.COMMON.SFO_ID')}/>
                      <AgGridColumn field="apple_id" headerName={t('INTERNAL.CLIENT.COMMON.APPLE_ID')}/>
                      <AgGridColumn field="pos_name" headerName={t('INTERNAL.CLIENT.COMMON.STORE_NAME')} cellRenderer={'cellWithTooltipRenderer'}/>
                      <AgGridColumn field="f_city" headerName={t('INTERNAL.CLIENT.COMMON.CITY')}/>
                      <AgGridColumn field="f_country" headerName={t('INTERNAL.CLIENT.COMMON.COUNTRY')}/>
                      <AgGridColumn field="f_program" headerName={t('INTERNAL.CLIENT.COMMON.PROGRAM')}/>
                      <AgGridColumn field="asset_type" headerName={t('INTERNAL.CLIENT.SHIPMENT.SHIPMENT_TABLE.ASSET_TYPE')} width={85}/>
                      <AgGridColumn field="asset_vendor" headerName={t('INTERNAL.CLIENT.SHIPMENT.SHIPMENT_TABLE.ASSET_VENDOR')}/>
                      <AgGridColumn field="material_desc" headerName={t('INTERNAL.CLIENT.SHIPMENT.SHIPMENT_TABLE.PART_DESC')} cellRenderer={'cellWithTooltipRenderer'}/>
                      <AgGridColumn field="material_number" headerName={t('INTERNAL.CLIENT.SHIPMENT.SHIPMENT_TABLE.PART_NUMBER')} />
                      <AgGridColumn field="material_quantity" headerName={t('INTERNAL.CLIENT.SHIPMENT.QUANTITY')}/>
                      <AgGridColumn field="carrier" headerName={t('INTERNAL.CLIENT.SHIPMENT.CARRIER')}/>
                      <AgGridColumn field="tracking_id" headerName={t('COMMON.TRACKING_ID')}/>
                      {!!carriers &&
                          <AgGridColumn field="" headerName={t('INTERNAL.CLIENT.SHIPMENT.TRACKING_URL')} cellRenderer={'cellTrackingUrlRenderer'}/>
                      }
                      <AgGridColumn field="dispatch_date" headerName={t('INTERNAL.CLIENT.SHIPMENT.SHIPMENT_TABLE.DISPATCH_DATE')}/>
                      <AgGridColumn field="pod_date" headerName={t('INTERNAL.CLIENT.SHIPMENT.SHIPMENT_TABLE.DELIVERY_DATE')}/>
                      <AgGridColumn field="address_1" headerName={t('INTERNAL.CLIENT.COMMON.ADDRESS')}/>
                      <AgGridColumn field="address_2" headerName={t('INTERNAL.CLIENT.COMMON.ADDRESS_2')}/>
                      <AgGridColumn field="address_3" headerName={t('INTERNAL.CLIENT.COMMON.ADDRESS_3')}/>
                      <AgGridColumn field="address_city" headerName={t('INTERNAL.CLIENT.COMMON.CITY')}/>
                      <AgGridColumn field="address_country" headerName={t('INTERNAL.CLIENT.COMMON.COUNTRY')}/>
                      <AgGridColumn field="contact" headerName={t('INTERNAL.CLIENT.COMMON.CONTACT')} />
                      <AgGridColumn field="phone_number" headerName={t('INTERNAL.CLIENT.COMMON.CONTACT')}/>
                      <AgGridColumn field="updated_at" headerName={t('INTERNAL.CLIENT.COMMON.UPDATED_AT')}/>
                      <AgGridColumn field="created_at" headerName={t('INTERNAL.CLIENT.COMMON.CREATED_AT')}/>
                      <AgGridColumn field="" headerName="" cellRenderer={'cellDeleteRenderer'} width={50}/>
                  </AgGridReact>
              </div>
          </Col>
      </Row>
  );
};

export default NpiShipmentTable;
