import React, {useContext, useState} from 'react';
import {Button, Form, Input, Modal, notification, Space, Tabs, Tag} from 'antd';
import {observer} from "mobx-react";
import NpiInputForm from "../input/form";
import {IFormAnswer, IFormImagesByQuestion, IQuestion, IWithQuestions} from "../../types/question";
import {BugOutlined, FormOutlined, PlusOutlined, RedoOutlined} from "@ant-design/icons/lib";
import NpiInputSelectLanguage from "../input/select-language";
import NpiDisplayDebugJson from "../display/debug-json";
import {NpiInternalContext} from "../../contexts/internal-context";
import npiApi from "../../services/api";
import _ from "lodash";
import {useRequest} from "ahooks";
import useAcl from "../../hooks/use-acl";
import {parseAnswersFormQuestion} from "../../helpers/form";
import {INpiInputQuestionError} from "../input/question";
import NpiDisplayNpiInputFormTreeError from "../display/form-tree-errors";
import NpiDisplayPosStatus from "../display/pos-status";
import {ITagValue} from "../../types/tag";
import {useTranslation} from "react-i18next";
import NpiInputSelectTagsAndValues from "../input/select-tags-and-values";
import {posFieldsOptions} from "../input/condition/rule";
import styled from "styled-components";
import NpiDisplayMerchandisingGroup from "../display/merchandising-group";
import NpiInputSelectMerchandisingGroup from "../input/select-merchandising-group";
import useSurveyErrors from "../../hooks/use-survey-errors";

const StyledSpace = styled(Space)`
    .ant-tag{
        font-size: 14px;
        height: 32px; 
        line-height: 32px; 
    }
`;

/**
 * Display & manage the simulation of the form
 */
const NpiConfigFormSimulation = observer(({item, npiId}: { item: IWithQuestions, npiId?:number }) => {
    const {t} = useTranslation();
    const [form] = Form.useForm();
    const {getFormErrors} = useSurveyErrors();
    const [value, setValue] = useState<IFormAnswer[]>([]);
    const [images, setImages] = useState<IFormImagesByQuestion>({});
    const [nonHiddenQuestions, setNonHiddenQuestions] = useState<IQuestion[]>([]);
    const [languageId, setLanguageId] = useState<number>(1);
    const {isSuperAdmin} = useContext(NpiInternalContext);
    const [status, setStatus] = useState(0);
    const [treeErrors, setTreeErrors] = useState<any[]>([]);
    const [formQuestionErrors, setFormQuestionErrors] = useState<INpiInputQuestionError[]>([]);
    const [selectedTags, setSelectedTags] = useState<any>([]);
    const [formattedTags, setFormattedTags] = useState<ITagValue>({});
    const [selectedFields, setSelectedFields] = useState<any>({});
    const [modalVisible, setModalVisible] = useState<any>(false);
    const hasTags = useAcl().hasRegionFeatureAccess("has_tags_access");
    const hasMerchandisingGroups = useAcl().hasRegionFeatureAccess("has_merchandising_groups_access");
    const {runAsync: fetchStatus, loading: loadingStatus} = useRequest(npiApi.internal.wave.simulateStatus, {
        manual: true,
        onSuccess: (data: any) => {
            setStatus(data.status);
        }
    });

    //Apply changes
    const onChange = (a: IFormAnswer[]) => {
        setValue(a);
        fetchStatus({...item, questionsIds: _.map(item.questions, 'id'), answers: a, tags: formattedTags, fields: selectedFields}).then(null);
    };

    /**
     * Handle when user answer hide/show an questions
     * @param questions IQuestion[]
     */
    const onQuestionsChange = (questions: IQuestion[]) => setNonHiddenQuestions(questions.filter(x => !x.hiddenInForm));

    /**
     * Handle when user submit NpiInputForm
     */
    const onSubmitForm = () => {
        const formErrors = getFormErrors(nonHiddenQuestions, value);
        //display tree errors
        setTreeErrors(formErrors.tree);
        //add to error messages in order to display messages in NpiInputForm
        setFormQuestionErrors(formErrors.form);
    };

    //Reset everything
    const onReset = () => {
        setValue([]);
        setSelectedTags([]);
        setFormattedTags({});
        setSelectedFields({});
        setImages({});
    };

    //Add a tag & close modal
    const onCustomize = () => {
        const {tags=[], ...fields} = form.getFieldsValue(true);

        //Convert tags to formatted
        const formattedTags = {} as any;
        tags.forEach((txt:string) => {
            const v = JSON.parse(txt);
            if(formattedTags[v[0]]){
                notification.warn({message: t('INTERNAL.CLIENT.COMMON.CUSTOMIZE')})
            }
            formattedTags[v[0]] = v[1];
        });
        
        setSelectedTags(tags);
        setFormattedTags(formattedTags);
        setSelectedFields(fields);
        setModalVisible(false);
    };

    //Space
    const extra = {
        right: <StyledSpace>
            {hasTags && <>
                {selectedTags.length>0 && <NpiInputSelectTagsAndValues value={selectedTags} disabled/>}
                {_.map(selectedFields, (v, k) => _.find(posFieldsOptions, {value:k}) && <Tag>{_.find(posFieldsOptions, {value:k})?.label}: {v}</Tag>)}
                {!!selectedFields.merchandising_group_id && <NpiDisplayMerchandisingGroup value={selectedFields.merchandising_group_id}/>}
                <Button type="primary" icon={<PlusOutlined/>} onClick={() => setModalVisible(true)}>{t('INTERNAL.CLIENT.COMMON.CUSTOMIZE')}</Button>
            </>}
            <NpiInputSelectLanguage value={languageId} onChange={setLanguageId}/>
            <Button onClick={onReset} icon={<RedoOutlined/>}>{t('INTERNAL.CLIENT.COMMON.RESET')}</Button>
            <NpiDisplayPosStatus status={status} loading={loadingStatus}/>
        </StyledSpace>
    };

    //Tabs
    const tabs = [{
        key: '0',
        label: <><FormOutlined/> {t('INTERNAL.CLIENT.COMMON.SIMULATION')}</>,
        children: <>
            <NpiInputForm
                item={item}
                answers={value ? parseAnswersFormQuestion(value, item.questions) : value}
                tags={formattedTags}
                fields={selectedFields}
                errors={formQuestionErrors}
                onChange={onChange}
                onQuestionsChange={onQuestionsChange}
                languageId={languageId}
                imageQuestions={images}
                onImageQuestionsChange={setImages}
                debug
            />


            {
                !!treeErrors.length &&
                <NpiDisplayNpiInputFormTreeError
                    treeData={treeErrors}
                />

            }

            <Button
                type={"primary"}
                style={{margin: 'auto', marginTop: 5, display: 'block'}}
                onClick={onSubmitForm}
            >
                {t('COMMON.SUBMIT')}
            </Button>
        </>,
    },];

    //Add Debug tab for superadmins
    if(isSuperAdmin){
        tabs.push({
            key: '1',
            label: <><BugOutlined/> {t('INTERNAL.CLIENT.COMMON.DEBUG')}</>,
            children: <>
                <b>Values :</b>
                <NpiDisplayDebugJson src={value} collapsed={false}/>
                <br/>
                <b>Non Hidden Questions :</b>
                <NpiDisplayDebugJson src={nonHiddenQuestions} collapsed={false}/>
            </>,
        });
    }

    //
    return <>
        <Tabs tabBarExtraContent={extra} size="large" items={tabs}/>

        {/*Modal to add a tag to simulate*/}
        <Modal open={modalVisible} onCancel={() => setModalVisible(false)} width={700} title={t('INTERNAL.CLIENT.COMMON.CUSTOMIZE_SIMULATION')} onOk={onCustomize}>
            <Form form={form} labelCol={{span: 6}}>
                <Form.Item label="Tags" name='tags'>
                    <NpiInputSelectTagsAndValues npis={!!npiId ? [npiId] : []} style={{width: '100%'}} onlyValues allowClear/>
                </Form.Item>
                {hasMerchandisingGroups && <>
                    <Form.Item label={t('INTERNAL.CLIENT.COMMON.MERCHANDISING_GROUP')} name='merchandising_group_id'>
                        <NpiInputSelectMerchandisingGroup allowClear={true} dropdownMatchSelectWidth={false}/>
                    </Form.Item>
                </>}
                {posFieldsOptions.map(f => <Form.Item key={f.value} label={f.label} name={f.value}>
                    <Input allowClear/>
                </Form.Item>)}
            </Form>
        </Modal>
    </>
});

export default NpiConfigFormSimulation;