import React, {useMemo, useState} from 'react';
import {Button, Collapse, Empty, notification} from "antd";
import _ from 'lodash';
import {useTranslation} from "react-i18next";
import useExternalToken from "../../../hooks/use-external-token";
import {IShipment} from "../../../types/shipment";
import {ExternalTokenRight} from "../../../types/external-token";
import npiApi from "../../../services/api";
import {IWave} from "../../../types/wave";
import NpiTabShipmentWaveContent from "./tab-shipment-wave-content";
import NpiExternalWaveCollapsePanel from "./wave-collapse-panel";
import styled from "styled-components";
import {useNpiExternalPosContext} from "../../../contexts/external-pos-context";


export interface IShipmentByWaveId {
    [waveId: number]: IShipment[]
}


interface INpiExternalPosViewTabShipmentProps {
    //
}

/**
 * External POS Tab: Shipments
 */
const NpiExternalPosViewTabShipment = (props: INpiExternalPosViewTabShipmentProps) => {
    const {t} = useTranslation();

    // get current POS context
    const {posId, wavePosMappedByWaveId, waves, setNewData} = useNpiExternalPosContext();

    // we render only wave with shipments
    const wavesWithShipment = useMemo(() => waves.filter(wave => wave.shipment_code_id && wavePosMappedByWaveId[wave.id]?.shipments.length), [waves, wavePosMappedByWaveId]);

    // single or multiple waves view
    const isUnifiedView = useExternalToken().hasRight(ExternalTokenRight.ACCESS_UNIFIED_VIEW);

    const [shipmentsChangesByWaveId, setShipmentsChangesByWaveId] = useState<IShipmentByWaveId>({});


    // -- Callback on shipments' update
    const onShipmentsChange = (waveId: number, shipments: IShipment[]) => {
        const updatedShipmentsChangesByWaveId = _.cloneDeep(shipmentsChangesByWaveId);

        updatedShipmentsChangesByWaveId[waveId] = shipments;

        setShipmentsChangesByWaveId(updatedShipmentsChangesByWaveId);
    }

    // -- Callback called when user saves shipments' changes
    const onSaveShipmentsChanges = () => {
        npiApi.external.saveShipmentsChange(posId, shipmentsChangesByWaveId)
            .then((data: any) => {
                notification.success({message: t('EXTERNAL.CLIENT.POS_VIEW.SHIPMENT_SUCCESSFULLY_CHANGED')})

                // update current POS context with new data
                setNewData(data);

                //clean latest shipments changes
                setShipmentsChangesByWaveId({});
            })
    }

    // -- Render a single Wave shipment content
    const renderWaveShipmentContent = (wave: IWave): React.ReactNode => <NpiTabShipmentWaveContent
        wave={wave}
        shipments={shipmentsChangesByWaveId[wave.id] ?? wavePosMappedByWaveId[wave.id].shipments}
        carriers={wavePosMappedByWaveId[wave.id].carriers}
        onChange={onShipmentsChange}
    />;

    return <>
        {/* Displaying shipments by wave */}
        { !wavesWithShipment.length

            ? <Empty style={{paddingTop: 20}} description={t('EXTERNAL.CLIENT.POS_VIEW.NO_SHIPMENT_AVAILABLE')}/>

            : <Collapse accordion>
                { !isUnifiedView
                    ? renderWaveShipmentContent(wavesWithShipment[0])
                    : wavesWithShipment.map((wave: IWave) =>
                        <NpiExternalWaveCollapsePanel key={wave.id} wave={wave}>
                            {renderWaveShipmentContent(wave)}
                        </NpiExternalWaveCollapsePanel>
                    )
                }
            </Collapse>
        }

        {/* Display submit button on bottom, only when user do some answers changes*/}
        { !!Object.keys(shipmentsChangesByWaveId).length &&
            <StyledSubmitDiv>
                <Button type={"primary"} onClick={onSaveShipmentsChanges} style={{margin: "auto", display: 'block', padding: '4px 10px'}}>
                    {t('COMMON.SAVE')}
                </Button>
            </StyledSubmitDiv>
        }
    </>
}


const StyledSubmitDiv = styled.div`
    position: fixed;
    border-top: 1px solid #a7a6a685;
    height: 38px;
    padding: 2px;
    background-color: white;
    bottom: 0;
    z-index: 500;
    width: 100%;
`

export default NpiExternalPosViewTabShipment;