import React, {useCallback, useContext, useState} from 'react';
import {Button, ConfigProvider, Dropdown, Empty, Form, Input, Menu, Modal, Space, Tooltip} from "antd";
import {useHistory, useParams} from 'react-router';
import {PlusOutlined, DashboardFilled, HistoryOutlined, CopyFilled} from '@ant-design/icons';
import ImageMultipleOutline from "@2fd/ant-design-icons/lib/ImageMultipleOutline";
import GoogleAnalytics from "@2fd/ant-design-icons/lib/GoogleAnalytics";
import LinkVariant from "@2fd/ant-design-icons/lib/LinkVariant";
import NpiPaginatedTable, {INpiTableServerPaginatedProps} from "../components/paginated-table";
import npiApi, {npiFillFormErrors} from "../services/api";
import {NpiInternalContext} from "../contexts/internal-context";
import NpiDisplayRegion from "../components/display/region";
import NpiDisplayLanguage from "../components/display/language";
import NpiDisplayWaveStatus from "../components/display/wave-status";
import NpiDisplayNumber from "../components/display/number";
import {DeleteFilled, DownOutlined, SettingFilled, TableOutlined} from "@ant-design/icons/lib";
import NpiInputSelectRegion from "../components/input/select-region";
import {IWave, IWaveStatusEnum} from "../types/wave";
import {useForm} from "antd/lib/form/Form";
import NpiDisplayListEllipsis from '../components/display/list-ellipsis';
import NpiDisplayCountry from "../components/display/country";
import useAcl from "../hooks/use-acl";
import {IRightsEnum} from "../types/user";
import {useRequest} from "ahooks";
import {Link} from "react-router-dom";
import styled from "styled-components";
import {openModalExternalLinks} from "../components/display/modal-wave-links";
import {INpi} from "../types/npi";
import NpiBatteryChartElement from "../components/display/battery-chart-element";
import Archive from "@2fd/ant-design-icons/lib/Archive";
import HandPointingRight from "@2fd/ant-design-icons/lib/HandPointingRight";
import moment from "moment";
import useArchive from "../hooks/use-archive";
import {Trans, useTranslation} from "react-i18next";
import {REGION_WW} from "../helpers/constants";
import ModalDailyHistory from "../components/wave/modal-daily-history";

const {OPEN, ARCHIVED, LAUNCHED} = IWaveStatusEnum;

const StyledMenu = styled(Menu)`
   font-size: 16px;
   .anticon{ font-size: 16px;}
`;

type WaveCreateForm = Pick<IWave, 'npi_id'|'name'|'region_id'>

const NpiContainerWaveList = () => {
    const history = useHistory();
    const {npiId}:any = useParams();
    const [visible, setVisible] = useState(false);
    const [latestChange, setLatestChange] = useState<any>(); //used to trigger table refresh
    const {setBreadcrumbs, user} = useContext(NpiInternalContext);
    const [form] = useForm<WaveCreateForm>();
    const {t} = useTranslation();
    const {hasRegionAccess, hasRight} = useAcl();
    const {archiveWave, deleteWave} = useArchive();
    const [npi, setNpi] = useState<INpi>({} as INpi);
    const canEditWave = hasRight(IRightsEnum.EDIT_WAVES);
    const canEditNpi = hasRight(IRightsEnum.EDIT_NPIS);
    const canViewSensitive = hasRight(IRightsEnum.VIEW_SENSITIVE_DATA);
    const {runAsync: createWave, loading: loadingCreate} = useRequest<IWave, [WaveCreateForm]>(npiApi.internal.wave.create, {manual: true});
    const {runAsync: listWaves, loading: loadingWaves} = useRequest(npiApi.internal.npi.listWaves, {manual: true});
    const [openDailyHistory, setOpenDailyHistory] = useState<IWave|null>(null);
    const {runAsync: dailyCopy, loading: loadingCopy} = useRequest(npiApi.internal.wave.dailyCopy, {manual: true});

    const closeHistoryModal = () => setOpenDailyHistory(null);

    //Create the npi and change
    const onClickCreate = useCallback(() => createWave({...form.getFieldsValue(), npi_id: Number(npiId), ...(npi.is_daily ? {region_id: REGION_WW} : {})}).then((wave:IWave) => {
        history.push('/wave/' + wave.id + '/config');
    }).catch(npiFillFormErrors(form)), [createWave, form, history, npiId, npi]);

    //On click action
    const onClickAction = (row:IWave, {key}:any) => {
        if(key === 'archive') archiveWave(row, () => setLatestChange(moment()));
        if(key === 'delete') deleteWave(row, () => setLatestChange(moment()));
        if(key === 'daily-copy') dailyCopy(row.daily_group_id!).then(() => setLatestChange(moment()));
    };

    //Custom message when no data
    const noData = useCallback(() =><span>
        <Empty description={null} image={Empty.PRESENTED_IMAGE_SIMPLE}/>
        {t('INTERNAL.CLIENT.NPI.NPI_NO_WAVES')}
        <br/><br/>
        {canEditWave && !loadingWaves && <Button size="large" onClick={() => setVisible(true)} type="primary">{t('INTERNAL.CLIENT.NPI.CREATE_WAVE')}</Button>}
    </span>, [setVisible, canEditWave, loadingWaves, t]);

    //useCallback necessary to avoid rerender loop: context change re-renders our component, which changes the fetcher which triggers a new fetcher call
    //With useCallback, the method is the same regardless of re-renders
    const fetcher = useCallback((params: any) => listWaves({...params, id: npiId}).then(({npi, waves}:any) => {
        setBreadcrumbs([
            {url: '/npi', name: 'NPI'},
            {url: '/npi/' + npiId, name: npi.name},
        ]);
        setNpi(npi);
        return waves;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }),[npiId, setBreadcrumbs, listWaves, latestChange]);

    const menuItemsAnalyze = (r:any) => [
        !!r.daily_group_id && {key:'daily-history', icon: <HistoryOutlined/>, label: t('INTERNAL.CLIENT.COMMON.HISTORY'), onClick: () => setOpenDailyHistory(r)},
        (canViewSensitive && !!user.is_allowed_dashboard) && {key:'dashboard', icon: <DashboardFilled/>, label: <Link to={"/kpi-dashboard/wave/"+r.id}>{t('INTERNAL.CLIENT.COMMON.DASHBOARD')}</Link>, disabled: ! r.nb_kpis},
        canViewSensitive && {key: 'gallery', icon: <ImageMultipleOutline/>, label: <Link to={"/gallery/wave/"+r.id}>{t('INTERNAL.CLIENT.COMMON.GALLERY')}</Link>},
        {key: 'battery', icon: <TableOutlined/>, label: <Link to={"/npi/"+npiId+"/wave/"+r.id+"/report"}>{t('INTERNAL.CLIENT.COMMON.REPORT')}</Link>},
    ];

    const menuItemsActions = (r:any) => [
        {key: 'configure', icon: <SettingFilled/>, label: <Link to={"/npi/"+npiId+"/wave/"+r.id+"/config"}>{t('INTERNAL.CLIENT.COMMON.CONFIGURE')}</Link>},
        (!!r.daily_group_id && r.is_daily_active && r.status === LAUNCHED) &&
            {key: 'daily-copy', icon: <CopyFilled />, label: t('INTERNAL.CLIENT.WAVE.COPY_DAILY')},
        (!r.daily_group_id || r.is_daily_active) &&
            {key: 'archive', icon: <Archive/>, label: r.status === ARCHIVED ? t('INTERNAL.CLIENT.COMMON.UNARCHIVE'):t('INTERNAL.CLIENT.COMMON.ARCHIVE')},
        {key: 'delete', icon: <DeleteFilled/>, label: t('COMMON.DELETE')},
    ];

    const columns:INpiTableServerPaginatedProps<IWave>['columns'] = [
        ...npi.is_daily
            ? [{title: `#${t('INTERNAL.CLIENT.COMMON.GROUP')}`, dataIndex:'daily_group_id', sorter: true}]
            : [{title: '#', dataIndex: 'id', sorter: true}],
        {title: t('INTERNAL.CLIENT.COMMON.NAME'), dataIndex: 'name', sorter: true},
        {title: t('INTERNAL.CLIENT.COMMON.LANGUAGE'), dataIndex: 'language_id', render: (id:number) => <NpiDisplayLanguage id={id}/>},
        {title: t('INTERNAL.CLIENT.COMMON.REGION'), dataIndex: 'region_id', render: (id:number) => <NpiDisplayRegion id={id}/>, sorter: true},
        {title: '#'+t('INTERNAL.CLIENT.COMMON.POS'), dataIndex: 'nb_pos', align: 'center', render: (n:any)=><NpiDisplayNumber value={n}/>, sorter: true},
        {title: t('INTERNAL.CLIENT.COMMON.POS_STATUSES'), width: 150, render: (row:any) => <NpiBatteryChartElement height={'24px'} width={'150px'} positive={row.nb_pos_positive} negative={row.nb_pos_negative} neutral={row.nb_pos_neutral} noText/>},
        {title: '#'+t('INTERNAL.CLIENT.COMMON.QUESTIONS'), dataIndex: 'nb_questions', align: 'center', render: (n:any)=><NpiDisplayNumber value={n}/>, sorter: true},
        {title: '#'+t('INTERNAL.CLIENT.NPI.KPIS'), dataIndex: 'nb_kpis', align: 'center', render: (n:any)=><NpiDisplayNumber value={n}/>, sorter: true},
        {title: t('INTERNAL.CLIENT.COMMON.COUNTRIES'), align: 'center', sorter: true,
            render: (row:any)=> <NpiDisplayListEllipsis>{row.countries?.map((c:any) => <NpiDisplayCountry key={c} id={c} style={{marginLeft: 5}} short/>)}</NpiDisplayListEllipsis>
        },
        {title: t('INTERNAL.CLIENT.COMMON.STATUS'), dataIndex: 'status', sorter: true,
            render: (id:number, row:IWave) => <span>
                <NpiDisplayWaveStatus id={id} wave={row}/>
            </span>,
        },
        {
            title: <Space>
                {canEditNpi && hasRegionAccess(npi.region_id) && <Button type="primary" icon={<SettingFilled/>} onClick={() => history.push("/npi/"+npiId+"/config")}>{t('INTERNAL.CLIENT.NPI.CONFIGURE_NPI')}</Button>}
                {canEditWave && <Button type="primary" icon={<PlusOutlined />} onClick={() => setVisible(true)}>{t('INTERNAL.CLIENT.NPI.CREATE_WAVE')}</Button>}
            </Space>,
            align: 'right',
            width: 260,
            render: (r:any) => <Space>
                {r.status === IWaveStatusEnum.LAUNCHED && <Tooltip title={t('INTERNAL.CLIENT.COMMON.LINK.TOOLTIP')}>
                    <Button icon={<LinkVariant />} onClick={() => openModalExternalLinks(r)} size="large">{t('INTERNAL.CLIENT.COMMON.LINKS')}</Button>
                </Tooltip>}

                {canEditWave && <Dropdown overlay={<StyledMenu onClick={e=>onClickAction(r, e)} items={menuItemsActions(r)}/>}>
                    <Button icon={<HandPointingRight style={{fontSize: 19}}/>} size="large" loading={loadingCopy}>
                        {t('COMMON.ACTIONS')} <DownOutlined />
                    </Button>
                </Dropdown>}

                <Dropdown disabled={r.status === OPEN} overlay={<StyledMenu items={menuItemsAnalyze(r) as any}/>}>
                    <Button icon={<GoogleAnalytics/>} size="large">
                        {t('INTERNAL.CLIENT.COMMON.ANALYZE')} <DownOutlined />
                    </Button>
                </Dropdown>
            </Space>
        },
    ];


    return <>
        <ConfigProvider renderEmpty={noData}>
            <NpiPaginatedTable fetcher={fetcher} columns={columns} pagination={{hideOnSinglePage: true}}/>
        </ConfigProvider>
        <Modal title={t(npi.is_daily ? 'INTERNAL.CLIENT.NPI.CREATE_DAILY_WAVE' : 'INTERNAL.CLIENT.NPI.CREATE_WAVE')} open={visible} onOk={onClickCreate} onCancel={() => setVisible(false)} okButtonProps={{loading: loadingCreate}}>

            <Form form={form} labelCol={{span: 5}}>
                <Form.Item label={t('INTERNAL.CLIENT.COMMON.NAME')} name="name"><Input/></Form.Item>
                {!npi.is_daily && <Form.Item label={t('INTERNAL.CLIENT.COMMON.REGION')} name="region_id"><NpiInputSelectRegion restrict withoutWW/></Form.Item>}
            </Form>

            {!!npi.is_daily && <p style={{textAlign: "justify", marginLeft: 40}}>
                <Trans t={t} i18nKey={'INTERNAL.CLIENT.NPI.CREATE_DAILY_WAVE_INFO'} components={{ul: <ul></ul>, li: <li></li>, b: <b></b>, em: <em></em>, br: <br/>}} />
            </p>}
        </Modal>

        <ModalDailyHistory lastWave={openDailyHistory} onCancel={closeHistoryModal}/>
    </>
};

export default NpiContainerWaveList;