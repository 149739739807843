import React from 'react';
import {observer} from "mobx-react";
import {Trans, useTranslation} from "react-i18next";
import {ITag, ITagPos} from "../../types/tag";
import {Alert, Spin, Table} from "antd";
import NpiInputEditableTableCell from "../input/editable-table-cell";
import {useRequest} from "ahooks";
import npiApi from "../../services/api";

interface IEditPosTags {
    pos: ITagPos
    onChange?: (data:ITagPos) => void
    npiId?: number
}

/**
 * This component is shown in a modal for a single POS to update its tags
 */
const NpiModalEditPosTags = observer(({pos, onChange, npiId}:IEditPosTags) => {
    const {t} = useTranslation();

    const {runAsync: updateTagByPosValue} = useRequest<ITagPos, any>(npiApi.internal.tag.updateTagPosValue, {manual: true});
    const {data: tags, loading: loadingTags} = useRequest<ITag[], any>(npiApi.internal.tag.list, {
        defaultParams: [{npis: (!!npiId ? [npiId] : [])}],
    });

    return loadingTags
        ? <Spin/>
        : <>
            <br/>
            <Alert message={<Trans i18nKey="INTERNAL.CLIENT.TAGS.CHANGES_WILL_AUTO_SPREAD" t={t} components={{ bold: <strong /> }}/>}/>
            <br/>
            <Table rowKey={'id'} dataSource={tags} pagination={false} columns={[
                {title: '#', dataIndex: 'id'},
                {title: t('INTERNAL.CLIENT.COMMON.NAME'), dataIndex: 'name'},
                {title: t('INTERNAL.CLIENT.COMMON.VALUES'), render: (tag:ITag) => {
                    const tagRules = tag?.config?.rules || [];
                    const listTag = tagRules.find((r:any) => r.type === 'list');
                    const optionsTag = listTag ? listTag.values?.map((v:string) => ({label: v, value: v})) : [];
                    return <NpiInputEditableTableCell
                        defaultValue={pos.tags?.[tag.id] as any}
                        onSave={(value: string) => updateTagByPosValue(pos.pos_id, tag.id, value).then(onChange)}
                        {...(listTag ? {
                            inputType: 'select',
                            options: optionsTag,
                            allowClear: true,
                            style: {width: '100%'},
                        } : {})}
                    />
                }},
            ]}/>
        </>
});

export default NpiModalEditPosTags;