import React from 'react';
import {Tooltip} from "antd";

interface INpiDisplayListEllipsis {
    children: any[] | null
    max?: number
    ellipsis?: (v:number) => any
}

const defaultRenderEllipsis = (value:number) => <span>&nbsp;+{value}</span>;

/**
 * Displays a list of elements with an ellipsis
 * @param max
 * @param children
 * @param ellipsis
 * @constructor
 */
const NpiDisplayListEllipsis = ({max= 3, children, ellipsis= defaultRenderEllipsis}:INpiDisplayListEllipsis) => {

    if( ! children?.map){
        return <></>;
    }

    return <>
        {children.slice(0, max)}
        {children.length>max && <Tooltip title={children.slice(max)}>
            {ellipsis(children.length - max)}
        </Tooltip>}
    </>;
};

export default NpiDisplayListEllipsis;