export const filterUndefinedObjectPropertiesRecursive = (obj:any) => {
    let newObj = {} as any;
    Object.keys(obj).forEach((key) => {
        if (obj[key] === Object(obj[key])) newObj[key] = filterUndefinedObjectPropertiesRecursive(obj[key]);
        else if (obj[key] !== undefined) newObj[key] = obj[key];
    });
    return newObj;
};

export const numberFormat = (value:number, fractionDigits=0) => {
    return value.toLocaleString('en-US', {minimumFractionDigits:fractionDigits, maximumFractionDigits:fractionDigits});
};

export const percentageFormat = (value:number, fractionDigits=0) => {
    return isNaN(value) ? '-' : numberFormat(value*100, fractionDigits) + '%';
};

export const getNodeText = (node: any): string|number => {
    if( typeof node === 'string' || typeof node === 'number') {
        return node;
    }
    if (node instanceof Array) {
        return node.map(getNodeText).join('')
    }
    if (typeof node === 'object' && node) {
        return getNodeText(node.props.children)
    }
    return '';
}

export const collisionCircleSegment = (radius: number, cx: number, cy: number, ax:number, ay:number, bx: number, by: number): boolean => {

    var dist;
    const v1x = bx - ax;
    const v1y = by - ay;
    const v2x = cx - ax;
    const v2y = cy - ay;
    // get the unit distance along the line of the closest point to
    // circle center
    const u = (v2x * v1x + v2y * v1y) / (v1y * v1y + v1x * v1x);


    // if the point is on the line segment get the distance squared
    // from that point to the circle center
    if(u >= 0 && u <= 1){
        dist  = (ax + v1x * u - cx) ** 2 + (ay + v1y * u - cy) ** 2;
    } else {
        // if closest point not on the line segment
        // use the unit distance to determine which end is closest
        // and get dist square to circle
        dist = u < 0 ?
            (ax - cx) ** 2 + (ay - cy) ** 2 :
            (bx - cx) ** 2 + (by - cy) ** 2;
    }
    return dist < radius * radius;

    // const side1 = Math.sqrt(Math.pow(cx - ax,2) + Math.pow(cy - ay,2));
    // const side2 = Math.sqrt(Math.pow(cx - bx,2) + Math.pow(cy - by,2));
    // const base = Math.sqrt(Math.pow(bx - ax,2) + Math.pow(by - ay,2));
    //
    // if(r > side1 || r > side2){
    //     return true;
    // }
    //
    // const angle1 = Math.atan2( bx - ax,by - ay ) - Math.atan2( cx - ax,cy - ay );
    // const angle2 = Math.atan2( ax - bx,ay - by ) - Math.atan2( cx - bx,cy - by );
    //
    // // Making sure if any angle is an obtuse one and Math.PI / 2 = 90 deg
    // if(angle1 > Math.PI / 2 || angle2 > Math.PI / 2) {
    //     return false;
    // }
    //
    // // Now if none are true then
    // const semiperimeter = (side1 + side2 + base) / 2;
    // const areaOfTriangle = Math.sqrt( semiperimeter * (semiperimeter - side1) * (semiperimeter - side2) * (semiperimeter - base) ); // Heron's formula for the area
    // const height = 2*areaOfTriangle/base;
    // return height < r;

}