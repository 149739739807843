export interface IShipmentFiltersPayload {
    filters: IShipmentFilters;
    filterModel: Object;
}

export interface IShipmentCountry {
    label: string;
    code: string;
}

export interface IShipmentFilters {
    carrier: string[];
    country: string[];
    program: string[];
    shipment_code_id: number|null;
}

export interface IShipmentCode {
    id: number;
    code: string;
    is_archived: boolean;
    region_id: number;
}

export interface IShipmentCarrier {
    id: number;
    region_id: number;
    carrier_name: string;
    tracking_url: string;
}

export interface IShipmentConfig {
    id: number,
    col_name: string,
    header_names: string[],
    is_active: boolean,
    is_required: boolean,
    must_be_filled: boolean,
}

export interface IShipmentChart {
    kpis : any;
    status: ShipmentFeedback;
}

export interface IShipmentComputedDropdown {
    carrier: string[];
    country: IShipmentCountry[];
    program: string[];
}

export type PosStatistics = { [K in ShipmentPosStatusEnum] : number };
export type VendorStatistics = { [K in VendorStatus] : number };
export type PosStatusColor = {[K in ShipmentPosStatusEnum] : string };
export type VendorStatusColor = {[K in VendorStatus] : string };
export type VendorDataset = {[K in ShipmentAssetType] : VendorStatistics};
export type PosDataset = {[K in ShipmentAssetType] : PosStatistics};


export interface IShipment {
    id: number;
    region_id: number;
    shipment_code_id: number;
    apple_id: string;
    apple_id_numerical: number;
    tracking_id: string;
    carrier: string;
    material_number: string;
    material_desc: string;
    material_quantity: string;
    asset_type: string;
    asset_vendor: string;
    address_1: string;
    address_2: string;
    address_3: string;
    address_city: string;
    address_country: string;
    contact: string;
    phone_number: string;
    dispatch_date: string;
    pod_date: string;
    pod_name: string;
    exception: string;
    returned: string;
    status: string;
    receipt_status: string;
    receipt_status_converted: string;
    status_updated_at: string;
    vendor_email: string;
    sync_to_be_sent: number;
    sync_has_been_sent: number;
    sync_to_be_deleted: number;
    sync_source?: any;
    sync_user_id?: any;
    created_at: string;
    updated_at: string;
}

export interface IShipmentKpis {
    total_lines : number;
    total_lines_merged : number;
    total_assets: number;
    total_packages : number;
    total_awaiting_dispatch: number;
    total_received_pos: number;
    total_received_vendor: number;
    update_date: string;
    colors: PosDataset;
    receipt_status_converted : VendorDataset;
    status:  PosDataset;
}

export enum ShipmentAssetType {
    '2D' = '2D',
    '3D' = '3D',
    'ALL' = 'all'
}
export enum ShipmentFeedback {
    VENDOR = 'receipt_status_converted',
    POS = 'status'
}

export enum VendorStatus {
    AwaitingDispatch = 'Awaiting dispatch',
    Delivered = 'Delivered',
    Exception = 'Exception',
    NoVendorFeedback = 'No Vendor Feedback',
    Shipped = 'Shipped'
}

export enum ShipmentPackageQuickActionsEnum {
    ALL_ASSETS_RECEIVED = "ALL_ASSETS_RECEIVED",
    SHIPMENT_NOT_RECEIVED = "SHIPMENT_NOT_RECEIVED",
    ALL_ASSETS_MISSING = "ALL_ASSETS_MISSING",
    ALL_ASSETS_INCORRECT = "ALL_ASSETS_INCORRECT",
    ALL_ASSETS_DAMAGED = "ALL_ASSETS_DAMAGED"
}

export enum ShipmentPosStatusEnum {
    Received = 'received',
    NotReceived = 'not_received',
    Missing = 'missing',
    Incorrect = 'incorrect',
    NoPosFeedback = 'no_pos_feedback',
    Damaged = 'damaged'
}
