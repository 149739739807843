import React, {ReactElement, ReactNode} from "react";
import {Modal} from "antd";

interface GetModalValueParams {
  input: ReactElement;
  trigger?: string;
  valuePropsName?: string;
  required?: boolean
  title?: ReactNode;
  description?: ReactNode;
  help?: ReactNode;
}


export const getModalValue = <T extends any=any>({input, trigger = 'onChange', valuePropsName = 'value', required, title, description, help}: GetModalValueParams): Promise<T|undefined> => {

  return new Promise((resolve, reject) => {

    let inputValue:T|undefined = input.props.defaultValue;
    let disabled: boolean = !!required && !inputValue;

    const handleInputChange = (e:any) => {
        inputValue = e.target !== undefined ? e.target[valuePropsName] : e;
        disabled = !!required && !inputValue;
        update({okButtonProps: {disabled: disabled}});
    };

    const inputElement = React.cloneElement(input as React.ReactElement, {
      [trigger]: handleInputChange,
    });

    const {update} = Modal.confirm({
      title: title,
      onOk: () => resolve(inputValue),
      onCancel: () => reject(),
      okButtonProps: {disabled: disabled},
      content: <>
        { description && <p>{description}</p>}
        <div>
            {inputElement}
        </div>
        { help && <small>{help}</small>}
      </>
    })
  });
}