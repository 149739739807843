import React, {useCallback} from 'react';
import {observer} from "mobx-react";
import npiApi from "../../services/api";
import NpiModalTemplateUploader from "../display/modal-template-uploader";
import {Trans, useTranslation} from "react-i18next";
import {nowFormat} from "../../helpers/date";
import {IMerchandisingGroupType} from "../../contexts/internal-context";

interface IManageMerchandisingGroups {
    waveId: number,
    onChange: (response:any) => void
    merchandisingGroups: IMerchandisingGroupType[]
}

/**
 * This component is shown in a modal and is used to download/upload template of kpis for a wave
 */
const NpiModalManageMerchandisingGroups = observer(({waveId, merchandisingGroups, onChange}:IManageMerchandisingGroups) => {
    const {t} = useTranslation();

    const exportMg = useCallback((params:any) => npiApi.internal.file.exportWavePosMerchandisingGroups({...params, waveId}), [waveId]);
    const importMg = useCallback((params:any) => npiApi.internal.file.importWavePosMerchandisingGroups({...params, waveId}), [waveId]);

    const textContent = <div>
        {t('INTERNAL.CLIENT.WAVE.TAB_POS.HOW_MANAGE_MERCHANDISING_GROUPS')}
        <ol>
            <li>{t('INTERNAL.CLIENT.WAVE.TAB_POS.DOWNLOAD_TEMPLATE')}</li>
            <li>
                {t('INTERNAL.CLIENT.WAVE.TAB_POS.EDIT_TEMPLATE')}
                <br/>
                <em>
                    <Trans i18nKey="INTERNAL.CLIENT.WAVE.TAB_POS.PROVIDE_VALID_MERCHANDISING_GROUP" t={t} components={{ bold: <strong /> }} />
                </em>
                <br/>
                <em>
                    <Trans i18nKey="INTERNAL.CLIENT.WAVE.TAB_POS.LIST_OF_AVAILABLE_GROUPS" t={t} values={{ merchandisingGroups: merchandisingGroups.map(v=>v.name).join(', ')}} components={{ bold: <strong /> }}/>
                </em>
            </li>
            <li>
                {t('INTERNAL.CLIENT.WAVE.TAB_POS.UPLOAD_TEMPLATE')}
                <br/><em>{t('INTERNAL.CLIENT.WAVE.TAB_POS.CLEAR_ALL_GROUPS')}</em>
                <br/>
                <em>
                    <Trans i18nKey="INTERNAL.CLIENT.WAVE.TAB_POS.ALL_POS_MUST_BE_IN_FILE" t={t} components={{ bold: <strong /> }}/>
                </em>
            </li>
            <li>{t('INTERNAL.CLIENT.WAVE.TAB_POS.CLOSE_THIS_MODAL')}</li>
        </ol>
        <ul></ul>
    </div>

    return <NpiModalTemplateUploader
        textConfirm={t('INTERNAL.CLIENT.WAVE.TAB_POS.REPLACE_MERCHANDISING_GROUP_INFORMATION')}
        textContent={textContent}
        axiosDownload={exportMg}
        axiosUpload={importMg}
        onChange={onChange}
        filename={`template-pos-merchandising-groups-wave-${waveId}-${nowFormat()}.xlsx`}
    />
});

export default NpiModalManageMerchandisingGroups;