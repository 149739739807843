import styled from "styled-components";
import React, {FunctionComponent, useMemo} from "react";
import {IBatteryChartElement, IBatteryColor} from "../../types/battery";
import {Tooltip} from "antd";

/**
 * Battery chart container
 * basically a 100width battery grey
 */
const BatteryChartElement = styled('div')<{width?: string|number, height: string|number}>`
    overflow: hidden;
    border-radius: 7px;
    height: ${props => props.height};
    width: ${props => props.width || '100%'};
    background-color: rgb(209, 218, 222);
`;

/**
 * Partial width of a battery to be filled with a label and a color
 */
const NpiBatteryPartial = styled('div')<{color?: string; width?: string;}>`
    float: left;
    height: 100%;
    position: relative;
    background-color: ${props => props.color + '!important'};
    width: ${props => props.width};
    transition: all 0.5s;
`;

/**
 * Label for each part of a battery
 */
const NpiBatteryPartialLabel = styled('span')<{color?: string;}>`
    color: ${props => props.color || '#666'};
    position: absolute;
    top: 55%;
    left: 5px;
    margin: 0;
    margin-top: -12px;
`;

export interface IBatteryChartElementProps {
    height: number|string
    width?: number|string
    positive?: number
    negative?: number
    neutral?: number
    noText?: boolean
}

/**
 * basically one row of a battery chart without prefix nor suffix
 * @param width
 * @param depth
 * @param elements
 * @constructor
 */
const NpiDisplayBatteryChartElement : FunctionComponent<IBatteryChartElementProps> = ({width, height, noText=false, positive=0, negative=0, neutral=0}) => {
    const elements : IBatteryChartElement[] = useMemo(() => {
        const fractionDigits = 0;
        const elements = [] as IBatteryChartElement[];
        const positives = Number(positive);
        const negatives = Number(negative);
        const neutrals = Number(neutral);
        const total = positives+negatives+neutrals;


        // because we round values, the bar could be overflowing and be hidden
        let percentPositivesVal = (total ? Number(positives * 100 / total) : 0);
        let percentNegativesVal = (total ? Number(negatives * 100 / total) : 0);
        let percentNeutralsVal  = (total ? Number(neutral  * 100 / total) : 0);
        const totalPercent = percentPositivesVal + percentNegativesVal + percentNeutralsVal;
        if( totalPercent > 100 ) {
            const overflow = totalPercent - 100;
            if( percentNeutralsVal > overflow ) {
                percentNeutralsVal -= overflow;
            } else {
                percentNegativesVal -= overflow;
            }
        }

        const percentPositives = percentPositivesVal.toFixed(fractionDigits);
        const percentNegatives = percentNegativesVal.toFixed(fractionDigits);
        const percentNeutrals  = percentNeutralsVal.toFixed(fractionDigits);

        positives>0 && elements.push( {color: IBatteryColor.POSITIVE, value: positives, label: `${percentPositives}%`, width: `${percentPositivesVal}%`, labelStyle: { color: IBatteryColor.WHITE}});
        negatives>0 && elements.push( {color: IBatteryColor.NEGATIVE, value: negatives, label: `${percentNegatives}%`, width: `${percentNegativesVal}%`, labelStyle: { color: IBatteryColor.WHITE}});
        elements.push( {color: IBatteryColor.NEUTRAL, value: neutrals, label: `${percentNeutrals}%`, width: `${percentNeutralsVal}%`, labelStyle: { color: IBatteryColor.GREY}});

        return elements;

    }, [positive, negative, neutral]);

    return (
        <BatteryChartElement width={width} height={height} className="battery-bar" >
            {elements.map(({label, value, labelStyle, ...rest}: IBatteryChartElement, index: number) => {
                return (
                    <Tooltip key={index} title={<>{label} - {value} POS</>}>
                        <NpiBatteryPartial {...rest} >
                            {!noText && label && <NpiBatteryPartialLabel {...labelStyle}>{label}</NpiBatteryPartialLabel> }
                        </NpiBatteryPartial>
                    </Tooltip>
                )
            })}
        </BatteryChartElement>
    );
};

export default NpiDisplayBatteryChartElement;
