import styled from "styled-components";
import colors from "../../services/colors";
import {IDashboardBlockConfiguration} from "../../types/dashboard";
import {Button, Tooltip} from "antd";
import {DeleteFilled, SettingFilled} from "@ant-design/icons/lib";
import React from "react";
import { useTranslation } from "react-i18next";

const StyledDiv = styled.div`
    position: relative;
    margin-bottom: 20px;
    .configure, .delete{
      opacity: 0;
      position: absolute;
      transition: opacity 500ms;
    }
    .delete{
      bottom: 5px;
      left: 5px;
    }
    .configure{
      .anticon{font-size: 34px;}
      height: 40px !important;
      top: 13px;
      right: 13px;
      background-color: ${colors.white};
      border-radius: 20px;  
    }
    &:hover .delete, &:hover .configure{
      opacity: 1;
    }
`;

const NpiKpiDashboardBlockContainer = React.forwardRef(({block, canEdit, onDelete, onClickConfigure, children, ...props}:any, ref) => {
    const {t} = useTranslation();

    //Displays edit buttons for a block
    const buttons = (block:IDashboardBlockConfiguration) => <>
        {canEdit && <Tooltip title={t('INTERNAL.CLIENT.KPI.DASHBOARD.REMOVE_BLOCK')}><Button className="delete" type="link" icon={<DeleteFilled/>} danger onClick={() => onDelete(block.key)}/></Tooltip>}
        {canEdit && <Tooltip title={t('INTERNAL.CLIENT.KPI.DASHBOARD.CONFIGURE_BLOCK')} placement="left"><Button className="configure" type="link" icon={<SettingFilled/>} onClick={() => onClickConfigure(block)}/></Tooltip>}
    </>;

    return <StyledDiv ref={ref} {...props}>
        {children}
        {buttons(block)}
    </StyledDiv>
});

export default NpiKpiDashboardBlockContainer;