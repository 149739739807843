import React, {Dispatch, useCallback, useContext, useEffect, useMemo, useState} from "react";
import {useControllableValue} from "ahooks";
import {Col, Input, InputNumber, Row, Select} from "antd";
import {
    IConditionRule,
    IConditionRuleStateEnum,
    IConditionRuleTargetEnum,
    rulesStates,
    rulesTargetTypes
} from "../../../types/condition";
import {IQuestion, IQuestionTypeEnum} from "../../../types/question";
import _ from "lodash";
import styled from "styled-components";
import NpiDisplayValidatableCard from "../../display/validatable-card";
import {IWTag} from "../../../types/wave";
import NpiInputSelectTagValue from "../select-tag-value";
import {NpiInternalContext} from "../../../contexts/internal-context";
import {Trans, useTranslation} from "react-i18next";

const StyledCard = styled(NpiDisplayValidatableCard)`
    .ant-select, .ant-input-number{
        width: 100%
    }
    label{
        line-height: 32px;
    }
`;

const {TAG_PRESENT, TAG_NOT_PRESENT, TAG_EQUAL, TAG_NOT_EQUAL, MERCHANDISING_GROUP_EQUAL, MERCHANDISING_GROUP_NOT_EQUAL, POS_FIELD_NOT_CONTAIN, POS_FIELD_CONTAIN, POS_FIELD_NOT_EQUAL, POS_FIELD_EQUAL} =  IConditionRuleStateEnum;
const {BLOCK, QUESTION, STATUS, RESCHEDULED, TAG, MERCHANDISING_GROUP, POS_FIELD} = IConditionRuleTargetEnum;
const {LIST, MULTILIST, BOOL, NUMERIC} = IQuestionTypeEnum;

interface INpiInputConditionRule {
    questions:IQuestion[]
    value: IConditionRule
    onChange:Dispatch<any>
    tags?: IWTag[]
    rulesTargets?:IConditionRuleTargetEnum[]
}

//Target rescheduled options
const rescheduledOptions = [
    {label: <Trans i18nKey='INTERNAL.CLIENT.COMMON.YES'/>, value: 1},
    {label: <Trans i18nKey='INTERNAL.CLIENT.COMMON.NO'/>, value: 0},
];

//Used when selecting a rule based on status
const statusOptions = [
    {label: <Trans i18nKey='INTERNAL.CLIENT.CONDITION.POSITIVE'/>, value: 2},
    {label: <Trans i18nKey='INTERNAL.CLIENT.CONDITION.NEGATIVE'/>, value: 1},
    {label: <Trans i18nKey='INTERNAL.CLIENT.CONDITION.NEUTRAL'/>, value: 0},
];

//Used when selecting a rule based on tags
const tagsStateOptions = [
    rulesStates[TAG_PRESENT],
    rulesStates[TAG_NOT_PRESENT],
    rulesStates[TAG_EQUAL],
    rulesStates[TAG_NOT_EQUAL],
].map(v => ({label: v.label, value: v.state}));

//Used when selecting a rule based on tags
const merchandisingGroupStateOptions = [
    rulesStates[MERCHANDISING_GROUP_EQUAL],
    rulesStates[MERCHANDISING_GROUP_NOT_EQUAL],
].map(v => ({label: v.label, value: v.state}));

//POS fields allowed
export const posFieldsOptions = [
    {label: <Trans i18nKey='INTERNAL.CLIENT.COMMON.PROGRAM'/>, value: 'f_program'},
    {label: <Trans i18nKey='INTERNAL.CLIENT.COMMON.POS_NAME'/>, value: 'pos_name'},
    {label: <Trans i18nKey='INTERNAL.CLIENT.COMMON.RESELLER_NAME'/>, value: 'f_company_name'},
];

//POS field possible states
const posFieldsStateOptions = [
    rulesStates[POS_FIELD_EQUAL],
    rulesStates[POS_FIELD_NOT_EQUAL],
    rulesStates[POS_FIELD_CONTAIN],
    rulesStates[POS_FIELD_NOT_CONTAIN],
].map(v => ({label: v.label, value: v.state}));

const NpiInputConditionRule = ({questions, rulesTargets, tags, ...props}:INpiInputConditionRule) => {
    const [rule, setRule] = useControllableValue<IConditionRule>(props);
    const [question, setQuestion] = useState<IQuestion>();
    const [questionsOptions, setQuestionsOptions] = useState<any>();
    const [targetsOptions, setTargetsOptions] = useState<any>();
    const [tagsOptions, setTagsOptions] = useState<any>();
    const [stateOptions, setStateOptions] = useState<any>();
    const [state, setState] = useState<any>();
    const {t} = useTranslation();
    const {target} = rule;
    const hasTags = rulesTargets && rulesTargets.indexOf(TAG)>=0 && !!tags?.length;
    const hasValue = useMemo(() => state?.hasValue, [state]);
    const optionsAnswers = useMemo(() => question?.config?.answers?.map(v => _.pick(v, 'value', 'label')), [question]);

    //Prepare merchandising groups
    const merchandisingGroupsOptions = useContext(NpiInternalContext).merchandisingGroups.map(m => ({value: m.id, label: m.name}));

    //Validate errors
    const validate = useCallback((rule:IConditionRule) => {
        rule.errors = [];
        if( [RESCHEDULED, STATUS].indexOf(target)>=0 && rule.value===undefined) rule.errors.push(t('INTERNAL.CLIENT.CONDITION.SELECT_VALUE'));
        if( target === QUESTION && ! rule.questionId) rule.errors.push(t('INTERNAL.CLIENT.CONDITION.SELECT_QUESTION'));
        if( ! rule.state && [RESCHEDULED, STATUS].indexOf(target)<0) rule.errors.push(t('INTERNAL.CLIENT.CONDITION.SELECT_LOGIC'));
        if( question && rule.state && rulesStates[rule.state]?.hasValue && _.isEmpty(rule.value) && !_.isInteger(rule.value)){
            //_.isEmpty(1); => true, we must check for numbers separately
            rule.errors.push(t('INTERNAL.CLIENT.CONDITION.SELECT_VALUE'), );
        }
        if(rule.target === POS_FIELD && (!rule.state || !rule.value || !rule.posField)){
            rule.errors.push(t('INTERNAL.CLIENT.CONDITION.MUST_FILL_ALL_FIELDS'));
        }

        if( ! rule.errors.length){
            delete rule.errors;
        }
    }, [question, target, t]);

    const apply = useCallback((rule:IConditionRule) => {
        validate(rule);
        setRule(rule);
    }, [setRule, validate]);

    //Change target selected
    const onChangeTarget = useCallback((value:IConditionRuleTargetEnum) => {
        rule.target = value;
        delete rule.questionId;
        delete rule.value;
        delete rule.tagValue;
        delete rule.state;
        setQuestion(undefined);
        apply(rule);
    }, [apply, rule, setQuestion]);

    //Change question id
    const onChangeQuestionId = useCallback((questionId:number|undefined) => {
        rule.questionId = questionId;
        const question = questions.filter(q => q.id === rule.questionId)[0];
        if(question){
            setQuestion(question);

            //Change available states
            const options = _.filter(rulesStates, s => s.types.indexOf(question.type)>=0).map(v => ({label: v.label, value: v.state}));
            setStateOptions(options);

            //Check if our rule state is still valid, otherwise clear it
            if( ! _.find(options, {value: rule.state})){
                delete rule.state;
                delete rule.value;
            }
        }
        else{
            setQuestion(undefined);
        }
        apply(rule);
    }, [apply, rule, questions]);

    //Change state
    const onChangeState = useCallback((state:IConditionRuleStateEnum) => {
        rule.state = state;
        delete rule.tagValue
        if(rule.target !== TAG) delete rule.value;
        setState(rulesStates[state]);
        apply(rule);
    }, [apply, rule]);

    //Change value selected
    const onChangeValue = useCallback((value:string|number) => {
        rule.value = value;
        delete rule.tagValue;
        apply(rule);
    }, [apply, rule]);

    //Change tag value selected
    const onChangeTagValue = useCallback((value:any) => {
        rule.tagValue = value;
        apply(rule);
    }, [rule, apply]);

    //Change field value selected
    const onChangePosField = useCallback((value:any) => {
        rule.posField = value;
        apply(rule);
    }, [rule, apply]);

    //Prepare everything on first load
    useEffect(() => {
        setQuestionsOptions(questions.map(q => ({label: q.display_name, value: q.id})));

        //Remove tags option if no tag present
        const filteredRulesTarget = rulesTargets?.filter(r => (r !== TAG || hasTags) && r !== BLOCK);

        //Retrieve the full options info
        const options = filteredRulesTarget?.map(v => rulesTargetTypes[v]);

        setTargetsOptions(options);
        setTagsOptions(tags?.map(tag => ({value: tag.id, label: tag.name})));
        if(rule.state){
            setState(rulesStates[rule.state]);
        }
        onChangeQuestionId(rule.questionId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [questions, rule, hasTags]);

    // Validate once on first load
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => validate(rule), []);

    return <StyledCard size="small" errors={rule.errors}>
        <Row gutter={[10,10]}>
            {/************ QUESTION SELECTION *********/}
            <Col span={4}><label>{t('INTERNAL.CLIENT.CONDITION.IF')}</label></Col>
            <Col span={5}><Select options={targetsOptions} value={target} onChange={onChangeTarget} dropdownMatchSelectWidth={false}/></Col>

            {/************ QUESTION SELECTION *********/}
            {target === QUESTION && <Col span={15}><Select options={questionsOptions} value={rule.questionId} onChange={onChangeQuestionId} dropdownMatchSelectWidth={false} placeholder={t('INTERNAL.CLIENT.CONDITION.SELECT_QUESTION_PLACEHOLDER')}/></Col>}
            {target === QUESTION && <Col span={4}><label>{t('INTERNAL.CLIENT.COMMON.ANSWER')}</label></Col>}
            {!! question && <Col span={10}><Select options={stateOptions} value={rule.state} onChange={onChangeState} placeholder={t('INTERNAL.CLIENT.CONDITION.SELECT_LOGIC_PLACEHOLDER')}/></Col>}

            {/************ TAG SELECTION *********/}
            {target === TAG && <>
                <Col span={hasValue?5:8}><Select options={tagsOptions} value={rule.value as number} onChange={onChangeValue} dropdownMatchSelectWidth={false} placeholder={t('INTERNAL.CLIENT.CONDITION.SELECT_TAG_PLACEHOLDER')} showSearch optionFilterProp="label"/></Col>
                <Col span={hasValue?5:7}><Select options={tagsStateOptions} value={rule.state} onChange={onChangeState} placeholder={t('INTERNAL.CLIENT.CONDITION.SELECT_LOGIC_PLACEHOLDER')}/></Col>
                {hasValue && <Col span={5}><NpiInputSelectTagValue tagId={rule.value as number} waveIds={[questions[0]?.wave_id as any]} npiIds={[questions[0]?.npi_id as any]} value={rule.tagValue} onChange={onChangeTagValue}/></Col>}
            </>}

            {/************ POS FIELD SELECTION *********/}
            {target === POS_FIELD && <>
                <Col span={5}><Select options={posFieldsOptions} value={rule.posField} onChange={onChangePosField} dropdownMatchSelectWidth={false} placeholder={t('INTERNAL.CLIENT.CONDITION.SELECT_POS_FIELD')} showSearch optionFilterProp="label"/></Col>
                {!!rule.posField && <Col span={5}><Select options={posFieldsStateOptions} value={rule.state} onChange={onChangeState} dropdownMatchSelectWidth={false} placeholder={t('INTERNAL.CLIENT.CONDITION.SELECT_LOGIC_PLACEHOLDER')} showSearch optionFilterProp="label"/></Col>}
                {!!rule.state && <Col span={5}><Input value={rule.value as any} onChange={e => onChangeValue(e.target.value)} placeholder={t('INTERNAL.CLIENT.CONDITION.SPECIFY_VALUE')}/></Col>}
            </>}

            {/************ MERCH GROUP SELECTION *********/}
            {target === MERCHANDISING_GROUP && <>
                <Col span={7}><Select options={merchandisingGroupStateOptions} value={rule.state} onChange={onChangeState} placeholder={t('INTERNAL.CLIENT.CONDITION.SELECT_LOGIC_PLACEHOLDER')}/></Col>
                <Col span={8}><Select options={merchandisingGroupsOptions} value={rule.value as number} onChange={onChangeValue} dropdownMatchSelectWidth={false} placeholder={t('INTERNAL.CLIENT.CONDITION.SELECT_GROUP_PLACEHOLDER')}/></Col>
            </>}

            {/************ RESCHEDULED & STATUS SELECTION *********/}
            {[RESCHEDULED, STATUS].indexOf(target)>=0 && <Col span={4} style={{textAlign: 'center'}}><label>IS</label></Col>}
            {target === RESCHEDULED && <Col span={10}>
                <Select options={rescheduledOptions} value={rule.value as number} onChange={onChangeValue} placeholder={t('INTERNAL.CLIENT.CONDITION.SELECT_RESCHEDULED_STATE_PLACEHOLDER')}/>
            </Col>}
            {target === STATUS && <Col span={10}>
                <Select options={statusOptions} value={rule.value as number} onChange={onChangeValue} placeholder={t('INTERNAL.CLIENT.CONDITION.SELECT_STATUS')}/>
            </Col>}

            {/************ VALUE SELECTION *********/}
            {!!question && hasValue && <Col span={10}>
                {[LIST, MULTILIST, BOOL].indexOf(question.type)>=0 && <Select mode="multiple" options={optionsAnswers} value={rule.value as string} onChange={onChangeValue} placeholder={t('INTERNAL.CLIENT.CONDITION.SELECT_ANSWER_PLACEHOLDER')}/>}
                {NUMERIC === question.type && <InputNumber value={rule.value as number} onChange={onChangeValue as any}/>}
            </Col>}
        </Row>
    </StyledCard>
};

export default NpiInputConditionRule;