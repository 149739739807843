import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {Menu} from "antd";
import {Link, useHistory, useLocation} from "react-router-dom";
import AccountMultiple from "@2fd/ant-design-icons/lib/AccountMultiple";
import TruckDelivery from "@2fd/ant-design-icons/lib/TruckDelivery";
import Account from "@2fd/ant-design-icons/lib/Account";
import Earth from "@2fd/ant-design-icons/lib/Earth";
import Contacts from "@2fd/ant-design-icons/lib/Contacts";
import AccountCog from "@2fd/ant-design-icons/lib/AccountCog";
import ImageMultipleOutline from "@2fd/ant-design-icons/lib/ImageMultipleOutline";
import MonitorDashboard from "@2fd/ant-design-icons/lib/MonitorDashboard";
import Tag from "@2fd/ant-design-icons/lib/Tag"; 
import useAcl from "../../hooks/use-acl";
import styled from "styled-components";
import colors from "../../services/colors";
import {NpiInternalContext} from "../../contexts/internal-context";
import {FlagOutlined, LogoutOutlined, ShopFilled} from "@ant-design/icons";
import npiApi from "../../services/api";
import {DashboardFilled, DownOutlined, SettingFilled} from "@ant-design/icons/lib";
import {useTranslation} from "react-i18next";
import NpiLanguageSelector from "../select-language";
import {IRightsEnum} from "../../types/user";

const StyledMenu = styled(Menu)`
    .ant-menu-submenu-selected{
        background-color: ${colors.blue};
    }
    .menu-right{
        margin-left: auto;
    }
    .anticon{
        font-size: 24px;
        position: relative;
        top: 2px;
    }
    
    .app-logo{
        position: relative;
        width: 108px;
        text-align: right;
    
        .logo{
            position: absolute;
            top: 4px;
            left: 4px;
            height: 56px;
            width: 56px;
            margin-right: 8px;
            background-image: url("/images/logo-no-background-56x56.png");
            background-size:     cover;
            background-repeat:   no-repeat;
            background-position: center center;
            transition: all .25s;
            opacity: 0.9;
        }
        
        &:hover .logo{
            opacity: 0.6;
        }
    }
`;

/**
 * Menu of the application
 * @constructor
 */
const NpiDisplayMenu = () => {
    const history = useHistory()
    const {t} = useTranslation();
    const {pathname} = useLocation();
    const { hasRegionFeatureAccess, isAdmin, hasRight } = useAcl();
    const canViewSensitive = hasRight(IRightsEnum.VIEW_SENSITIVE_DATA);
    const {user, getRoleName, getRegionName} = useContext(NpiInternalContext);
    const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

    //Logout, then go back to first screen
    const onLogout = useCallback(() => npiApi.internal.security.logout().then(() => history.go(0)), [history]);

    useEffect(() => {
        if(pathname.indexOf('/shipments') === 0){
            setSelectedKeys(['shipments']);
        }
        else if(pathname.indexOf('/gallery') === 0){
            setSelectedKeys(['gallery']);
        }
        else if(pathname.indexOf('/admin') === 0){
            setSelectedKeys(['administration']);
        }
        else if(pathname.indexOf('/ww-dashboard') === 0){
            setSelectedKeys(['ww-dashboard']);
        }
        else{
            setSelectedKeys(['npi']);
        }
    }, [pathname])


    /**
     * Refresh interface context on language change
     */
    const onLanguageChange = useCallback(() => {
        window.location.reload();
    }, [])

    //Menu used by the app
    const items = useMemo(() => [
        {key: 'npi', className: "app-logo", label: <Link to="/npi"><div className="logo"/> {t('INTERNAL.CLIENT.NPI.NPI')}</Link>},
        ...canViewSensitive ? [
            {key: 'ww-dashboard', label: <Link to="/ww-dashboard"><DashboardFilled/> {t('INTERNAL.CLIENT.COMMON.READINESS')}</Link>},
        ] : [],
        ...(canViewSensitive && hasRegionFeatureAccess("has_shipment_access")) ? [
            {key: 'shipments', label: <Link to="/shipments"><TruckDelivery/> {t('INTERNAL.CLIENT.COMMON.SHIPMENTS')}</Link>}
        ] : [],
        {key: 'gallery', label: <Link to="/gallery"><ImageMultipleOutline /> {t('INTERNAL.CLIENT.COMMON.GALLERY')}</Link>},
        ...isAdmin ? [
            {key: 'administration', label: <span><SettingFilled/> {t('INTERNAL.CLIENT.COMMON.ADMINISTRATION')} <DownOutlined style={{fontSize: 16}}/></span>, children: [
                {key: 'users', label: <Link to="/admin/users"><AccountMultiple/> {t('INTERNAL.CLIENT.COMMON.USERS')}</Link>},
                {key: 'contacts', label: <Link to="/admin/contacts"><Contacts/> {t('INTERNAL.CLIENT.COMMON.CONTACTS')}</Link>},
                {key: 'countries', label: <Link to="/admin/countries"><Earth/> {t('INTERNAL.CLIENT.COMMON.COUNTRIES')}</Link>},
                {key: 'tableau', label: <Link to="/admin/tableau"><MonitorDashboard /> {t('INTERNAL.CLIENT.COMMON.TABLEAU')}</Link>},
                ...hasRegionFeatureAccess("has_tags_access") ? [
                    {key: 'tags', label: <Link to="/admin/tags"><Tag /> {t('INTERNAL.CLIENT.COMMON.TAGS')}</Link>}
                ] : [],
                {key: 'pos', label: <Link to="/admin/pos"><ShopFilled /> {t('INTERNAL.CLIENT.COMMON.POS')}</Link>},
            ]} as any
        ] : [],
        {key: 'user', className: "menu-right", label: <span><Account/> {user.firstname} {user.lastname} <DownOutlined style={{fontSize: 16}}/></span>, children: [
            {key: 'infos', label: <span>{getRoleName(user.role)} - {getRegionName(user.region_id)}</span>, disabled: true},
            {key: 'prefs', label: <a href={"/admin/preferences"}><AccountCog /> {t('INTERNAL.CLIENT.COMMON.PREFERENCES')}</a>},
            {key: 'logout', label: <div onClick={onLogout}><LogoutOutlined/> {t('INTERNAL.CLIENT.COMMON.LOGOUT')}</div>},
        ]} as any,
        {key: 'translation', label: <NpiLanguageSelector onChanged={onLanguageChange} darkMode={true} display={"medium"} inMenu={true} prefixIcon={<FlagOutlined/>}/>, children:[], style: {paddingLeft: "0px"} },
    ], [isAdmin, hasRegionFeatureAccess, getRegionName, getRoleName, onLogout, t, user, onLanguageChange, canViewSensitive]);

    return <StyledMenu theme="dark" mode="horizontal" selectedKeys={selectedKeys} items={items}/>
};

export default NpiDisplayMenu;