import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import NpiDisplayLayoutContent from "../../components/display/layout-content";
import {NpiInternalContext} from "../../contexts/internal-context";
import npiApi from "../../services/api";
import {Button, Modal, Select, Space, Tabs} from "antd";
import {useRequest} from "ahooks";
import useRestrictedToAdmin from "../../hooks/use-restricted-to-admin";
import styled from "styled-components";
import colors from "../../services/colors";
import {BlockOutlined, TagOutlined} from "@ant-design/icons";
import NpiModalManageTags from "../../components/tags/modal-manage-tags";
import {ITag, ITagPos} from "../../types/tag";
import NpiModalBuildTags from "../../components/tags/modal-build-tags";
import {useTranslation} from "react-i18next";
import AdminTagPosList from "../../components/tags/admin-tag-pos-list";
import AdminTagList from "../../components/tags/admin-tag-list";
import _ from "lodash";
import NpiDropdownEllipsis from "../../components/display/dropdown-ellipsis";


const StyledTabs = styled(Tabs)`
    .ant-table-cell.tag:empty{
        background: ${colors.greyLightBackground} !important;
    }
`;

const StyledOptions = styled.div`
    position: absolute;
    right: 20px;
    z-index: 100;
`;


const NpiContainerAdminTags = () => {
    useRestrictedToAdmin();
    const {t} = useTranslation();

    const {runAsync: fetchTag, loading: loadingTags} = useRequest(npiApi.internal.tag.list, {
        onSuccess: (data:ITag[]) => setTags(data)
    });
    const {runAsync: fetchPos, loading: loadingPos} = useRequest(npiApi.internal.tag.listPos, {manual: true,
        onSuccess: (data:any) => setPosList(data.data),
        onBefore: () => setPosList([]), //forces table refresh
    });

    const {loaded, setBreadcrumbs, isWW} = useContext(NpiInternalContext);
    const [tags, setTags] = useState<ITag[]>([]);
    const [posList, setPosList] = useState<ITagPos[]>([]);
    const [posFilters, setPosFilters] = useState<any>({}); //track changes to filter the display in the template modal
    const [tagFilterIds, setTagFilterIds] = useState<number[]>([]); //limit the tag displayed
    const [total, setTotal] = useState<number>(0); //track changes to display the total in the template modal

    const filteredTags = useMemo(() => {
        return tagFilterIds.length > 0 ? tags.filter(t => tagFilterIds.includes(t.id)) : tags
    }, [tags, tagFilterIds])

    //Set navigation
    useEffect(() => setBreadcrumbs([
        {name: t('INTERNAL.CLIENT.COMMON.ADMINISTRATION')},
        {url: '/admin/tags', name: t('INTERNAL.CLIENT.COMMON.TAGS')},
    ]), [setBreadcrumbs, t]);


    //When changing tags, update the list of tags, and refresh the POS tab
    const onUploadTags = useCallback(() => {
        //refresh list
        fetchTag({}).then(null);
        fetchPos({filters: posFilters}).then(null);
        Modal.destroyAll();
    }, [fetchTag, posFilters, fetchPos]);


    const onRemoveTag = useCallback((removedTag:ITag) => {
        setTags(tags.filter((t) => t.id !== removedTag.id ));
        const newFilters = _.pickBy(posFilters, (value, key) => key !== 'tags->'+removedTag.id);
        setPosFilters(newFilters);
        fetchPos({newFilters}).then(null);
    }, [fetchPos, posFilters, tags])


    const onUpdateTag = useCallback((updatedTag:ITag) => {
        const found = _.find(tags, {id: updatedTag.id})
        if( found ){
            _.extend(found, updatedTag);
            setTags([...tags]);
        }
    }, [tags])


    //Open Modal to manage tags
    const openManageTags = useCallback(() => {
        Modal.info({
            icon: <TagOutlined/>,
            width: 800,
            title: t('INTERNAL.CLIENT.TAGS.MANAGE'),
            content: <NpiModalManageTags onChange={onUploadTags} posFilters={posFilters} tagIdFilters={tagFilterIds} total={total}/>,
        })
    }, [onUploadTags, posFilters, tagFilterIds, total, t]);

    //Open Modal to build tags
    const openBuildTags = useCallback(() => {
        Modal.info({
            icon: <BlockOutlined />,
            width: 600,
            title: t('INTERNAL.CLIENT.TAGS.BUILD'),
            okText: t('INTERNAL.CLIENT.COMMON.CLOSE'),
            content: <NpiModalBuildTags/>,
        })
    }, [t]);


    return <NpiDisplayLayoutContent>
        {loaded && <>
            <StyledOptions>
                <Space>
                    <Select
                        options={tags.map(t => ({value: t.id, label: t.name}))}
                        value={tagFilterIds}
                        onChange={setTagFilterIds}
                        optionFilterProp="label"
                        mode={"multiple"} allowClear={true}
                        maxTagCount={'responsive'}
                        placeholder={t("INTERNAL.CLIENT.TAGS.FILTER_TAG_COLUMNS")} style={{minWidth: 250}}
                    />

                    <Button icon={<TagOutlined/>} onClick={openManageTags} type={"primary"}>{t('INTERNAL.CLIENT.TAGS.MANAGE')}</Button>

                    <NpiDropdownEllipsis disabled={!isWW} items={[
                        ...isWW ? [
                            {key: "build-tags", icon: <BlockOutlined/>, label: t('INTERNAL.CLIENT.TAGS.BUILD'), onClick:openBuildTags},
                        ] : [],
                    ]}/>
                </Space>
            </StyledOptions>

            <StyledTabs items={[
                {key: "tags", label: t('INTERNAL.CLIENT.COMMON.TAGS'), children: <AdminTagList tags={filteredTags} loadingTags={loadingTags} onRemoveTag={onRemoveTag} onUpdateTag={onUpdateTag}/>},
                {key: "pos", label: t('INTERNAL.CLIENT.COMMON.POS'), children: <AdminTagPosList tags={filteredTags} loadingTags={loadingTags} posList={posList} loadingPos={loadingPos} fetcher={fetchPos} onFiltersChange={setPosFilters} onTotalChange={setTotal}/>},
            ]}/>
        </>}

    </NpiDisplayLayoutContent>
};

export default NpiContainerAdminTags;