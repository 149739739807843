import React from "react";
import npiApi from "../../services/api";
import SelectDebounced, {IDebounceSelectProps} from "./select-debounced";
import {IQuestion, IQuestionTypeEnum} from "../../types/question";
import {useRequest} from "ahooks";
import questionsConfigurations from "../../services/questions-configurations";
import {useTranslation} from "react-i18next";

export interface ISelecQuestionProps<ValueType, T> extends Omit<IDebounceSelectProps<ValueType, T>, 'fetchOptions'> {
    npis?: number[];
    waves?: number[];
    limit ?: number;
    showIcon ?: boolean;
}

/**
 * @param {number[]} npis
 * @param {number[]} waves
 * @param limit
 * @param showIcon
 * @param props
 *
 * {@see SelectDebounced} for more options and information
 */
const NpiInputSelectQuestion = ({npis, waves, limit, showIcon = true, ...props}:ISelecQuestionProps<any, IQuestion>) => {

    const {runAsync: fetchQuestion, loading} = useRequest<IQuestion[],any>(npiApi.internal.question.searchSelect, {manual: true});
    const {t} = useTranslation();

    const displayQuestion = ( q: IQuestion ) => {
        const icon = (showIcon && q.type && questionsConfigurations[q.type as IQuestionTypeEnum]) ? <>{questionsConfigurations[q.type as IQuestionTypeEnum].icon} </> : '';
        const waveName = ( waves && waves.length > 1 && q.wave_name ) ? <>{q.wave_name} - </> : '';
        return <>{icon}{waveName}{q.name}</>;
    }
    return (
        <SelectDebounced
            {...props}
            placeholder={props.placeholder || t("INTERNAL.CLIENT.COMMON.SELECT_QUESTION_other", {count: (props.mode === "multiple" ? 2 : 1)})}
            loading={props.loading || loading}
            fetchOptions={search => fetchQuestion({search: search, limit: limit || 15, npis: npis || [], waves: waves || []})}
            fetchInitial={props.fetchInitial || (value => fetchQuestion({ids: Array.isArray(value) ? value : (value ? [value] : [])}))}
            mappedBy={props.mappedBy || (q => q.id)}
            display={props.display || displayQuestion}
        />
    );
}

export default NpiInputSelectQuestion;