import React from 'react';
import {IWavePosImage} from "../../types/wave-pos-image";
import styled from "styled-components";
import NpiDisplayDate from "../display/date";
import {Tooltip} from "antd";
import {CommentOutlined, QuestionCircleFilled, QuestionCircleOutlined} from "@ant-design/icons";
import {BasicProps} from "antd/lib/layout/layout";
import {useTranslation} from "react-i18next";
import NpiDisplayRegion from "../display/region";


interface IDescriptionProps extends BasicProps {
    image: IWavePosImage,
    full?: boolean,
}

const StyledDescription = styled.div<Omit<IDescriptionProps, 'image'>>`
  
  display: block;
  text-align: center;
  padding: 5px;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.65rem;
  
  .img-pos-name {
    font-size: 0.8rem;
    display: block;
  }
  
  .img-wave-name {
    font-style: italic;
    display: block;
  }
  
  .img-infos {
    display: block;
    
    .img-date, .img-question, .img-img-comment {
      display: ${props => props.full ? 'block' : 'inline'};
      margin-right: ${props => props.full ? '0px' : '5px'};
    }
    
    .img-question, .img-comment {
      text-transform: none;
      line-height: ${props => props.full ? 'inherit' : '16px'};
      margin-top: ${props => props.full ? '3px' : '0px'};
    }
  }
`;

const NpiGalleryImageDescription = ({image, full, ...props}:IDescriptionProps) => {

    const {t} = useTranslation();

    const imgQuestionStr = `${t(image.is_answer ? 'INTERNAL.CLIENT.GALLERY.ANSWER_FOR' : 'INTERNAL.CLIENT.GALLERY.LINKED_TO')} ${t('INTERNAL.CLIENT.COMMON.QUESTION')} : ${image.question_label}`;

    return <StyledDescription full={full} {...props}>
        <div className={'img-pos-name'}>{image.pos_id} - {image.pos_name}</div>
        <div className={'img-wave-name'}>{image.wave_id} - <NpiDisplayRegion id={image.region_id}/> - {image.wave_name}</div>
        <div className={'img-infos'}>
            <span className={'img-date'}><NpiDisplayDate value={image.created_at}/></span>
            {!!image.question_id && (
                full
                    ? <div className={'img-question'}><QuestionCircleOutlined/> {imgQuestionStr}</div>
                    : <Tooltip className={'img-question'} title={imgQuestionStr} overlayStyle={{ whiteSpace: 'pre-line' }}><QuestionCircleFilled/></Tooltip>
            )}
            {!!image.comment && (
                full
                    ? <div className={'img-comment'} ><CommentOutlined/> {image.comment}</div>
                    : <Tooltip className={'img-comment'} title={image.comment} overlayStyle={{ whiteSpace: 'pre-line' }}><CommentOutlined/></Tooltip>
            )}
        </div>
    </StyledDescription>;
}

export default NpiGalleryImageDescription;