import React, {ReactElement, useContext, useEffect} from "react";
import useRestrictedToAdmin from "../../hooks/use-restricted-to-admin";
import {NpiInternalContext} from "../../contexts/internal-context";
import styled from "styled-components";
import {Avatar, Card} from "antd";
import { CloudSyncOutlined } from "@ant-design/icons";
import {CardProps} from "antd/lib/card";
import {FlagOutlined} from "@ant-design/icons/lib";
import {Link} from "react-router-dom";
import NpiDashboardDebugInfos from "./dashboard-debug-infos";
import NpiDashboardSyncPieBoxExport from "./pie-box-export";
import NpiDashboardSpacePieBox from "./space-pie-box";


interface INpiContainerDevDashboardProps {
    //
}

const StyledContainer = styled.div`
    background: #F0F2F5;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
`;

const VerticalContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 100%;
    .ant-card-body {
      height: calc(100% - 60px);
      position: relative;
    }
`;

const StyledAvatar = styled(Avatar)`
  background-color: transparent;
  display: block;
  width: 100%; height: 100%;
  .anticon, .anticon > svg {
    width: 100%; height: 100%;
  }
`;


interface DevCardProps extends CardProps {
    card?: ReactElement<CardProps>
}
const DevCard = ({card, style, ...props}: DevCardProps) => {
    const cardProps: CardProps = {
        bordered:false,
        hoverable:true,
        style: {width: 310, maxWidth: '100%', overflow: "auto", height: 360, ...(style ? style : {})},
        ...props,
    }

    return card
        ? React.cloneElement<any>(card, cardProps)
        : <Card {...cardProps}/>;
}


const NpiContainerDevDashboard = ({...props}: INpiContainerDevDashboardProps) => {
    useRestrictedToAdmin();

    const {setBreadcrumbs} = useContext(NpiInternalContext);
    useEffect(() => setBreadcrumbs([{url: '/dev-dashboard', name: "Dev Dashboards"}]), [setBreadcrumbs]);

    return <StyledContainer>

        <VerticalContainer>
            <Link to={"/dev-dashboard/sync"}>
                <DevCard title={"Synchronization"} style={{height: 175}}>
                    <StyledAvatar icon={<CloudSyncOutlined style={{color: '#ccc'}}/>}/>
                </DevCard>
            </Link>

            <Link to={"/dev-dashboard/translation"}>
                <DevCard title={"Translations"} style={{height: 175}}>
                    <StyledAvatar icon={<FlagOutlined style={{color: '#ccc'}}/>}/>
                </DevCard>
            </Link>
        </VerticalContainer>

        <DevCard card={<NpiDashboardSyncPieBoxExport/>}/>
        <DevCard card={<NpiDashboardSpacePieBox env={"internal"}/>}/>
        <DevCard card={<NpiDashboardSpacePieBox env={"external"}/>}/>

        <DevCard card={<NpiDashboardDebugInfos/>} style={{flex: 1, minWidth: 400}}/>

    </StyledContainer>
}

export default NpiContainerDevDashboard;