import React, {DetailedHTMLProps, ImgHTMLAttributes, useState} from "react";
import {IWavePosImage} from "../../types/wave-pos-image";
import axios from "../../services/axios";
import {npiApiDownloadFileCallback} from "../../services/api";
import {Button} from "antd";
import {DownloadOutlined} from "@ant-design/icons";
import Box from "@2fd/ant-design-icons/lib/Box";
import styled from "styled-components";
import baseAxios from "axios";

interface NpiGalleryImageProps extends DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
    image: IWavePosImage,
    isThumb?: boolean,
    withLinks?: boolean,
}

const NpiGalleryImage = ({image, isThumb, withLinks, ...props}: NpiGalleryImageProps) => {

    const imageSrc = !!isThumb
        ? (image.box_thumbnail_route ? image.box_thumbnail_route : image.thumbnail_route)
        : (image.box_image_route ? image.box_image_route : image.image_route);

    const [downloading, setDownloading] = useState<boolean>(false)

    const onDownload = (e: any) => {
        e.stopPropagation();
        setDownloading(true)
        onDownloadImage(image).finally(() => setDownloading(false))
    }

    const onOpenBox = (e: any) => {
        e.stopPropagation();
        window.open(image.box_link, "_blank");
    }

    return <StyledDiv className={'img-container'}>
        <img src={imageSrc} alt={image.original_name} {...props}/>
        {withLinks && <>
            <Button loading={downloading} icon={<DownloadOutlined/>} className={'btn-download'} shape={'circle'} onClick={onDownload} title={"Download"}/>
            {!!image.box_link &&
                <Button icon={<Box/>} className={'btn-box'} shape={'circle'} onClick={onOpenBox} title={"Box"}/>
            }
        </>}
    </StyledDiv>
}

export const onDownloadImage = async (image: IWavePosImage): Promise<any> => {

    // rename file for download with pos, wave and question information
    const ext = image.original_name.split(".").pop();
    let filenameDownload = `w${image.wave_id}_p${image.pos_id}`;
    if( image.question_id ){
        filenameDownload +=  ` - Q${image.question_id}_${image.question_label}`;
    }
    filenameDownload += ` - ${image.id}.${ext}`;

    if( !!image.box_image_route ) {
        return baseAxios.get(image.box_image_route, {responseType: 'blob'}).then(npiApiDownloadFileCallback( filenameDownload ))
    } else {
        return axios.get(image.image_route, {responseType: 'blob', rawResponse: true}).then(npiApiDownloadFileCallback( filenameDownload ))
    }
}

const StyledDiv = styled.div`
    position: relative;
    width: 100%;
    .btn-download, .btn-box {
        position: absolute;
        background: #fffc;
        bottom: 15px;
        right: 15px;
        display: inline-block;
        &.btn-box{
            bottom: 50px;
            .anticon {font-size: 22px;}
        }
    }
`

export default NpiGalleryImage;