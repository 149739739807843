import React from "react";
import {Card, Tooltip} from "antd";
import styled from "styled-components";
import {CardProps} from "antd/lib/card";
import colors from "../../services/colors";
import ExclamationThick from '@2fd/ant-design-icons/lib/ExclamationThick';

const Container = styled.div`
    position: relative;
    &.with-errors .triangle{
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 28px 0 0 28px;
        border-color: transparent transparent transparent ${colors.red};
        position: absolute;
        bottom: 0;
        z-index: 2;
    }
    .exclamation-icon{
        color: ${colors.redBackground};
        position: absolute;
        bottom: 2px;
        left: 2px;
        z-index: 3;
    }
`

interface NpiValidatableCardType extends CardProps{
    errors?:string[],
}


const NpiDisplayValidatableCard = ({errors, style:baseStyle, ...props}:NpiValidatableCardType) => {
    const style = {...baseStyle};
    const hasErrors = !!errors?.length;

    if(hasErrors){
        style.backgroundColor = colors.redBackground;
        style.borderColor = colors.redBorder;
    }

    const tooltip = <ul style={{padding: "0 15px", margin: 0}}>
        {errors?.map((e,k) => <li key={k}>{e}</li>)}
    </ul>

    return <Container className={hasErrors ? "with-errors":""}>
        <Card {...props} style={style}/>
        {hasErrors && <div className="errors">
            <Tooltip title={tooltip} placement="right">
            <div className="triangle"/>
                <ExclamationThick className="exclamation-icon"/>
            </Tooltip>
        </div>}
    </Container>
}

export default NpiDisplayValidatableCard;