import React, {useMemo} from "react";
import {Bar, BarChart, CartesianGrid, Legend, Text, Tooltip, XAxis, YAxis} from "recharts";
import {IShipmentChart} from "../../types/shipment";

const NpiStackedBarChart = ({ kpis, status } : IShipmentChart) : JSX.Element => {
    const { colors } = kpis
    const colorSet   = colors[status];

    const dataSet = useMemo(() => {
        const flatten = []
        flatten.push({assetType: "2D", ...kpis[status]["2D"]})
        flatten.push({assetType: "3D", ...kpis[status]["3D"]})
        return flatten;
    }, [kpis, status])


    const barDefinition = useMemo(() => {
        return Object.keys(kpis[status].all);
    }, [kpis, status])

    return (
            <BarChart
                width={500} height={350}
                barCategoryGap={"25%"}
                barGap={0}
                layout="vertical"
                data={dataSet}
                margin={{ top: 80, right: 0, bottom: 0, left: 0 }}
                stackOffset="expand"
            >
                <Text>2D/3D Delivery Status</Text>
                <CartesianGrid strokeDasharray="1 1" />
                <Tooltip />
                <Legend />
                <XAxis  type="number" />
                <YAxis
                    type="category"
                    dataKey="assetType"
                    stroke="#666666"
                    fontSize="12"
                />
                {
                    barDefinition.map((label : string, index : number) => (
                        <Bar key={index} dataKey={label} fill={colorSet[label]} stackId="a" />
                    ))
                }
            </BarChart>
    );
}

export default NpiStackedBarChart;