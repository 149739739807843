import React from 'react';
import {Button, Dropdown, DropdownProps, Menu, Tooltip} from "antd";
import {EllipsisOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import {ItemType} from "antd/lib/menu/hooks/useItems";


interface INpiDropdownEllipsis extends Omit<DropdownProps, 'overlay'> {
    items: ItemType[]
    loading?: boolean
}

const NpiDropdownEllipsis = ({items, loading, ...props}: INpiDropdownEllipsis) => {
    const {t} = useTranslation();

    return <Dropdown {...props} overlay={<Menu items={items}/>}>
        <Tooltip title={t('INTERNAL.CLIENT.COMMON.MORE')}>{<Button icon={<EllipsisOutlined/>} loading={loading}/>}</Tooltip>
    </Dropdown>
};

export default NpiDropdownEllipsis;