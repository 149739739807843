import React from 'react';
import {Button, Modal, Popover} from "antd";
import NpiPaginatedTable, {NpiServerPagination} from "../../components/paginated-table";
import {HistoryOutlined, WarningOutlined} from "@ant-design/icons";
import moment from "moment";
import 'moment/locale/fr';
import npiApi from "../../services/api";
import NpiDisplayDate from "../../components/display/date";
import {useRequest} from "ahooks";
import {useTranslation} from "react-i18next";
moment.locale('fr');

const NpiDashboardSyncLogs = () => {

    const {runAsync: fetchLogs, loading} = useRequest<NpiServerPagination, any>(npiApi.internal.dev.synchro.logs, {manual: true});
    const {t} = useTranslation();

    const logsColumns:any = [
        {title: '#', dataIndex: 'id', sorter: true},
        {title: t('INTERNAL.CLIENT.COMMON.TABLE'), dataIndex: 'table_name', sorter: true},
        {title: t('INTERNAL.CLIENT.COMMON.GROUP'), dataIndex: 'identifier', sorter: true},
        {title: t('INTERNAL.CLIENT.COMMON.DIRECTION'), dataIndex: 'source', sorter: true, render: (dir:string) => {
            return (dir === 'INTERNAL') ? t('INTERNAL.CLIENT.COMMON.I_E') : t('INTERNAL.CLIENT.COMMON.E_I')
        }},
        {title: t('INTERNAL.CLIENT.COMMON.CNT_ITEMS'), sorter: (a:any, b:any) => a.count_total > b.count_total ? 1 : -1, render: (log:any) => {
            return (log.count_total !== 0) ? log.count_total :
                <Popover placement={'top'} content={<div style={{maxWidth: '1000px', maxHeight: '50vh', overflow: 'auto'}}>{log.status}</div>} title={t('COMMON.ERROR')} trigger="click"><WarningOutlined style={{color: '#f82d23'}}/></Popover>;
        }},
        {title: t('INTERNAL.CLIENT.COMMON.DATE'), dataIndex: 'created_at', sorter: true, render: (date: string) => <NpiDisplayDate value={date} hours={'inline'} formatHours={'HH:mm:ss'}/>},
    ];

    return <Button icon={<HistoryOutlined />} title={t('INTERNAL.CLIENT.NPI.SYNCHRONIZATION_LOGS')} shape={'circle'} onClick={() => {
        Modal.warning({width:800, title: t('INTERNAL.CLIENT.NPI.SYNCHRONIZATION_LOGS'), onOk() {}, content: <NpiPaginatedTable
                fetcher={fetchLogs}
                columns={logsColumns}
                pagination={{hideOnSinglePage: true}}
                loading={loading}
            />, closable:true});
    }} />;
};

export default NpiDashboardSyncLogs;