import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import NpiDisplayLayoutContent from "../components/display/layout-content";
import npiApi from "../services/api";
import {Alert, Button, Col, Dropdown, Menu, Row, Space, Typography,} from "antd";
import styled from "styled-components";
import {useRequest, useUpdateEffect} from "ahooks";
import {
    IShipmentCarrier,
    IShipmentComputedDropdown,
    IShipmentFilters,
    IShipmentFiltersPayload,
    IShipmentKpis,
    ShipmentFeedback
} from "../types/shipment";
import { NpiInternalContext } from "../contexts/internal-context";
import {CarOutlined, DownOutlined, OrderedListOutlined, SettingOutlined} from "@ant-design/icons";
import NpiModal from "../components/display/modal-function";
import {useTranslation} from "react-i18next";
import NpiDashboardShipmentFilters from "../components/shipment/dashboard-filters";
import NpiFeedbacksStatus from "../components/shipment/feedback-status";
import PieChart from "../components/shipment/pie-chart";
import NpiShipmentTable from "../components/shipment/shipment-table";
import StackedBarChart from "../components/shipment/stacked-bar-chart";
import ShipmentsKpis from "../components/shipment/statistics";
import ModalShipmentHelp from "../components/shipment/modal-help";
import ModalEditShipmentTemplates from "../components/shipment/modal-edit-shipment-templates";
import ModalEditShipmentCodes from "../components/shipment/modal-edit-shipment-codes";
import ModalEditShipmentCarriers from "../components/shipment/modal-edit-shipment-carriers";
import useRestrictedToRights from "../hooks/use-restricted-to-rights";
import {IRightsEnum} from "../types/user";

const { Title } = Typography;

const StyledHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
`;

const StyledChartContainer = styled.div`
  transition: all 0.5s ease;
  &.loading {
    opacity: 0.4;
  }
`;

const StyledTitle = styled( Title )`
    text-align : center;
    font-size: 20px !important;
    font-weight: 400 !important;
    margin-bottom: 0 !important;
    position : relative;
    bottom : -20px;
`

const NpiContainerDashboardShipment = (): JSX.Element => {
    useRestrictedToRights([IRightsEnum.VIEW_SENSITIVE_DATA]);

    const {t} = useTranslation();
    const {setBreadcrumbs} = useContext(NpiInternalContext);
    useEffect(() => setBreadcrumbs([{url: '#', name: 'Shipments'}]), [setBreadcrumbs]);

    const [ filters, setFilters ] = useState<IShipmentFilters>({shipment_code_id: null, country: [], program: [], carrier: []});
    const [gridFilters, setGridFilters ] = useState<any>( {});
    const [ filtersOptions, setFiltersOptions ] = useState<IShipmentComputedDropdown>();
    const shipmentCode = useMemo<number|null>(() => filters?.shipment_code_id, [filters])
    const hasShipmentCode = useMemo<boolean>(() => !!filtersOptions && !!shipmentCode, [filtersOptions, shipmentCode])

    const [ kpis, setKpis ] = useState( {} as IShipmentKpis );
    const [ status, setStatus ] = useState<ShipmentFeedback>( ShipmentFeedback.VENDOR )
    const [loading, setLoading] = useState<boolean>(false);
    const [filterKey, setFilterKey] = useState<number>(1);

    const {data: carriers, runAsync: reloadCarriers} = useRequest<IShipmentCarrier[], any>(npiApi.internal.shipments.carrier.list);

    /**
        Update charts when table datas have changed
        TODO ABE avoid reloading all table as changes already come from here
     */
    const onUpdateRowTable = () => npiApi.internal.shipments.kpis( {filters, filterModel: gridFilters})
            .then((kpis) => setKpis(kpis));

    useUpdateEffect(() => {
        if( !filters?.shipment_code_id ){
            return;
        }
        setLoading(true);

        const payload: IShipmentFiltersPayload = {filters, filterModel: gridFilters};
        Promise.all( [
            npiApi.internal.shipments.kpis( payload ),
            npiApi.internal.shipments.getFilters( payload )
        ] ).then( ([ kpis, filtersOptions ]) => {
            setKpis( kpis );
            setFiltersOptions( filtersOptions )
        } ).finally(() => setLoading(false))
    }, [filters, gridFilters, carriers]);

    const openModalEditCodes = useCallback(() => NpiModal.display(<ModalEditShipmentCodes
        //Update ShipmentCode Select Options when add a new shipmentCode
        onChangeShipmentCodeList={() => setFilterKey(Math.random())}
    />), [setFilterKey]);
    const openModalEditCarriers = useCallback(() => NpiModal.display(<ModalEditShipmentCarriers
        onChangeShipmentCarriers={reloadCarriers}
    />), [reloadCarriers]);
    const openModalEditTemplate = useCallback(() => NpiModal.display(<ModalEditShipmentTemplates/>), []);

    return (
        <NpiDisplayLayoutContent>
            <ModalShipmentHelp/>

            <Space direction="vertical" size="large" style={{width: '100%'}}>

                <StyledHeader>
                    <NpiDashboardShipmentFilters key={filterKey} filters={filters} onChange={setFilters} options={filtersOptions}/>

                    <Dropdown trigger={["click"]} overlay={
                        <Menu items={[
                            {key: "shipment-codes", icon:  <OrderedListOutlined/>, label: t('INTERNAL.CLIENT.DASHBOARD.SHIPMENT_CODE'), onClick:openModalEditCodes},
                            {key: "carrier-settings", icon:  <CarOutlined/>, label: t('INTERNAL.CLIENT.COMMON.CARRIER_SETTINGS'), onClick:openModalEditCarriers},
                            {key: "shipment-template", icon:  <SettingOutlined/>, label: t('INTERNAL.CLIENT.SHIPMENT.TEMPLATE_SETTINGS'), onClick:openModalEditTemplate},
                        ]}/>}>
                        <Button>{t('COMMON.ACTIONS')} <DownOutlined/></Button>
                    </Dropdown>
                </StyledHeader>


                {!hasShipmentCode || !shipmentCode
                    ? <Alert
                        message={t('INTERNAL.CLIENT.DASHBOARD.NO_SHIPMENT_CODE_SELECTED')}
                        description={t('INTERNAL.CLIENT.DASHBOARD.CHOOSE_SHIPMENT_CODE')}
                        type="info"
                        showIcon
                    />
                    : <StyledChartContainer className={loading ? 'loading' : ''}>
                        <Row gutter={ [ 16, 48 ] } align="bottom">
                            <Col><NpiFeedbacksStatus onChangeValue={ setStatus } disabled={loading}/></Col>
                        </Row>
                        <Row>
                            <Col span={4}><ShipmentsKpis kpis={ kpis } status={ status }/></Col>
                            <Col span={20}>
                                <Row>
                                    <Col>
                                        <StyledTitle level={3}>{t('INTERNAL.CLIENT.DASHBOARD.GLOBAL_DELIVERY_STATUS')}</StyledTitle>
                                        <PieChart kpis={kpis} status={status} />
                                    </Col>
                                    <Col>
                                        <StyledTitle level={3}>{t('INTERNAL.CLIENT.DASHBOARD.2D_3D_DELIVERY_STATUS')}</StyledTitle>
                                        <StackedBarChart kpis={kpis} status={status} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <NpiShipmentTable shipmentCode={shipmentCode} carriers={carriers} onGridChange={onUpdateRowTable} filters={filters} gridFilters={gridFilters} onGridFiltersChange={(data) => setGridFilters(data)}/>
                    </StyledChartContainer>
                }
            </Space>
        </NpiDisplayLayoutContent>
    );
};

export default NpiContainerDashboardShipment;
