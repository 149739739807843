import React, {useEffect, useState} from "react";
import {Select} from "antd";
import {useTranslation} from "react-i18next";
import {useControllableValue, useRequest} from "ahooks";
import {SelectProps} from "antd/es/select";
import npiApi from "../../services/api";


interface ISelectPartnerProps extends SelectProps<any> {
    regionId: number
    countries?: number[]
}

const NpiInputSelectPartner = ({regionId, countries, style, ...props}: ISelectPartnerProps) => {
    const {t} = useTranslation();

    const [state, setState] = useControllableValue<null|string|string[]>(props, {
        defaultValue: null,
    });

    const [options, setOptions] = useState<SelectProps['options']>([]);

    const {runAsync: listPartners, loading} = useRequest<string[], any>(npiApi.internal.wavepos.partners, {
        manual: true,
        onSuccess: data => setOptions(data?.map((partner) => ({
            label: partner,
            value: partner,
        })))
    });

    useEffect(() => {
        listPartners({region_id: regionId, countries}).then(null)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[regionId, countries]);

    return <Select
        {...props}
        options={options}
        value={state}
        onChange={setState}
        placeholder={props.placeholder || t(props.mode === "multiple" ? 'INTERNAL.CLIENT.COMMON.SELECT_PARTNER' : 'INTERNAL.CLIENT.COMMON.SELECT_PARTNERS')}
        loading={props.loading || loading}
        style={{minWidth: 150, ...style}}
    />
}

export default NpiInputSelectPartner;