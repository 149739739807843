import React, {useContext} from "react";
import {NpiInternalContext} from "../../contexts/internal-context";
import _ from "lodash";
import {useTranslation} from "react-i18next";

const NpiDisplayRegion = ({id}:{id:number}) => {
    const {regions, loaded} = useContext(NpiInternalContext);
    const region = _.find(regions, {id});
    const {t} = useTranslation();

    return <>{loaded && (region ? region.name : <i>{t('INTERNAL.CLIENT.COMMON.NO_REGION')}</i>)}</>
}

export default NpiDisplayRegion;