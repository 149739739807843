import {makeAutoObservable} from "mobx";
import npiApi from "../services/api";
import {INpi} from "../types/npi";
import {ISaveableStore} from "../types/store";

export class NpiStore implements ISaveableStore<INpi> {
    npi:INpi = {} as INpi;
    loaded = false;
    saving = false;

    constructor(){
        makeAutoObservable(this);
    }

    select = async (id:number):Promise<{npi:INpi}> => {
        const {npi} = await npiApi.internal.npi.detail(id);
        this.npi = npi;
        this.loaded = true;
        return {npi};
    }

    selectShort = async (id:number):Promise<{npi:INpi}> => {
        const {npi} = await npiApi.internal.npi.detailShort(id);
        this.npi = npi;
        this.loaded = true;
        return {npi};
    }

    save = async (npi:INpi) => {
        this.saving = true;
        const data = await npiApi.internal.npi.save(npi).finally(() => this.saving = false);
        this.npi = {...this.npi, ...data};
        return npi;
    }
}