import React, {useContext, useEffect, useState} from "react";
import {NpiInternalContext} from "../../contexts/internal-context";
import {Select} from "antd";
import {useControllableValue} from "ahooks";
import {SelectProps} from "antd/lib/select";
import _ from "lodash";
import {REGION_WW} from "../../helpers/constants";


interface ISelectRegionProps extends SelectProps<any> {
  restrict?: boolean;
  restrictWithWW?: boolean;
  withoutWW?: boolean;
}


/**
 * Select between a list of regions
 * Can be restricted (with restrict=true) to the region of current logged user (all for WW)
 *
 * @param {boolean} restrict
 * @param {boolean} restrictWithWW
 * @param {boolean} withoutWW
 * @param style
 * @param props
 */
const NpiInputSelectRegion = ({restrict, restrictWithWW, withoutWW, style, ...props}:ISelectRegionProps) => {
    const {regions, loaded, user} = useContext(NpiInternalContext);
    const [options, setOptions] = useState<any[]>([]);
    const [state, setState] = useControllableValue<null|number|number[]>(props, {
        defaultValue: [],
    });

    useEffect(() => {

        // restrict list of regions to the logged user region (or all for WW user)
        let restrictedRegions = ( restrict === true )
            ? (user ? regions.filter((r) => r.id === user.region_id || user.region_id === 1 || (restrictWithWW && r.id === REGION_WW)) : [])
            : regions;

        if( withoutWW ) {
            restrictedRegions = restrictedRegions.filter(r => r.id !== REGION_WW);
        }

        // set select options from filtered regions
        setOptions(restrictedRegions.map(r => ({
            label: r.name,
            value: r.id,
        })));

        //Auto select region if there is only one
        if(restrictedRegions.length === 1 && _.isEmpty(state)){
            setState(restrictedRegions[0].id);
        }

    }, [regions, restrict, restrictWithWW, withoutWW, user, state, setState]);

    return <Select loading={!loaded} options={options} value={state} onChange={setState} optionFilterProp="label" {...props} style={{minWidth: 150, ...style}}/>
};

export default NpiInputSelectRegion;