import {IWave} from "../../types/wave";
import React, {useMemo} from "react";

interface INpiDisplayWaveNameTranslation {
    wave: IWave,
    langId?: number
}


/**
 * Wave name translations by wave and language id
 * @param props
 * @constructor
 */
export const NpiDisplayWaveNameTranslation = (props: INpiDisplayWaveNameTranslation) => {
    const {langId = 0, wave} = props;

    const waveName = useMemo(() => {
        return wave.translations && wave.translations[`T${langId}`] && wave.translations[`T${langId}`][`W${wave.id}.name`] ?
            wave.translations[`T${langId}`][`W${wave.id}.name`] :
            wave.name
    }, [wave, langId]);

    return <span>{waveName}</span>
};
