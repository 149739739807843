import React from "react";
import {useControllableValue} from "ahooks";
import {SelectProps} from "antd/lib/select";
import {Select} from "antd";
import _ from "lodash";

interface INpiInputSelectNumericalTags extends SelectProps<string[]> {

}

/**
 * Can be used to select ids by typing them with a tag-like behavior
 * - Only keeps numbers
 * - Will perform unicity
 * - Can paste a string with any separator: it will extract the Apple IDs
 * @param props
 * @constructor
 */
const NpiInputSelectNumericalTags = ({...props}:INpiInputSelectNumericalTags) => {

    const [value, onChange] = useControllableValue(props);

    const onChangeSelect = (selectValue:any) => {
        const newValue:any = [];
        //We want to find all numbers
        selectValue.forEach((v:string) => {
            newValue.push(v.match(/\d+/g));
        })

        onChange(_.chain(newValue).flatten().uniq().value());
    }

    return (
        <Select mode="tags" open={false} allowClear={true} {...props} value={value} onChange={onChangeSelect}/>
    );
}

export default NpiInputSelectNumericalTags;