import React, {useCallback, useEffect, useState} from "react";
import {Button, Modal} from "antd";
import {FileOutlined, InfoOutlined, WarningOutlined} from "@ant-design/icons";
import styled from "styled-components";
import moment from "moment";
import {SettingOutlined} from "@ant-design/icons/lib";
import {useRequest} from "ahooks";
import npiApi, {npiApiDownloadFileCallback} from "../../services/api";
import api from "../../services/api";
import {useTranslation} from "react-i18next";

const StyledHelpButton = styled(Button)`
  position: absolute !important;
  top: 15px;
  right: 30px;
`;

const StyledCenterDiv = styled.div`
  text-align: center;
  padding: 10px;
`;

const ModalShipmentHelp = (props: any): JSX.Element => {

    const { t } = useTranslation();
    const [visible, setVisible] = useState<boolean>( false );
    const [lastUpdate, setLastUpdate] = useState<string>('');
    const [shipmentEmail, setShipmentEmail] = useState<string>('');

    const {runAsync: downloadExample, loading: isDownloading} = useRequest(api.internal.file.exportShipmentExample, {manual: true})
    const {runAsync: fetchShipmentInfos, loading: loadingModal} = useRequest(npiApi.internal.shipments.helpers, {
        manual: true,
        onSuccess: (data: {lastUpdate: string, shipmentEmail: string}) => {
            setLastUpdate(data.lastUpdate);
            setShipmentEmail(data.shipmentEmail);
        }
    });


    //Download the template
    const onDownload = useCallback(() => downloadExample({}).then(npiApiDownloadFileCallback('template-shipments-empty.xlsx')), [downloadExample]);

    const openModal = () => {
        setVisible(true);
        fetchShipmentInfos().then(null);
    }

    const closeModal = () => {
        setVisible(false);
    }

    useEffect(() => {
        if( visible ){
            const interval = setInterval(() => {
                fetchShipmentInfos().then(null);
            }, 30000);
            return () => clearInterval(interval);
        }
    }, [visible, fetchShipmentInfos]);


    // Config Modal
    const configModal = {
        title: t('INTERNAL.CLIENT.SHIPMENT.HELP.TITLE'),
        open: visible,
        footer: null,
        onCancel: closeModal,
        width: 800,
        loading: loadingModal,
        ...props,
    }

    return <>
        <StyledHelpButton onClick={openModal} icon={<InfoOutlined/>} shape={"circle"}/>

        <Modal {...configModal}>
            <div style={{textAlign: "right", marginBottom: '20px'}}>
                <em>{t('INTERNAL.CLIENT.SHIPMENT.HELP.LAST_FETCH')} {moment(lastUpdate).locale('en').fromNow()}</em>
            </div>

            <h2>@ {t('INTERNAL.CLIENT.SHIPMENT.HELP.EMAIL_TITLE')}</h2>
            <div>
                {t('INTERNAL.CLIENT.SHIPMENT.HELP.EMAIL_TEXT')} <br/>
                <StyledCenterDiv>
                    <strong><em>{shipmentEmail}</em></strong>
                </StyledCenterDiv>
            </div>

            <hr/>
            <br/>

            <h2><FileOutlined /> {t('INTERNAL.CLIENT.SHIPMENT.HELP.TEMPLATE_TITLE')}</h2>
            <div>
                {t('INTERNAL.CLIENT.SHIPMENT.HELP.TEMPLATE_TEXT')}
                <StyledCenterDiv>
                    <Button type={"link"} onClick={onDownload} loading={isDownloading}><u>{t('INTERNAL.CLIENT.SHIPMENT.HELP.DOWNLOAD_TEMPLATE_LABEL')}</u></Button>
                </StyledCenterDiv>

                <br/>
                <b style={{color: 'green'}}>{t('INTERNAL.CLIENT.SHIPMENT.HELP.GREEN_LABEL')}</b> {t('INTERNAL.CLIENT.SHIPMENT.HELP.GREEN_TEXT')}
                <br/>
                <b style={{color: 'blue'}}>{t('INTERNAL.CLIENT.SHIPMENT.HELP.BLUE_LABEL')}</b> {t('INTERNAL.CLIENT.SHIPMENT.HELP.BLUE_TEXT')}
            </div>

            <hr/>
            <br/>

            <h2><SettingOutlined /> {t('INTERNAL.CLIENT.SHIPMENT.HELP.CONFIG_TITLE')}</h2>
            <div>
                {t('INTERNAL.CLIENT.SHIPMENT.HELP.CONFIG_P')}
                <div style={{fontStyle: 'italic', marginTop: '20px', fontWeight: 500}}>
                    <WarningOutlined/> {t('INTERNAL.CLIENT.SHIPMENT.HELP.CONFIG_WARN_LABEL')}
                    <ul>
                        <li>{t('INTERNAL.CLIENT.SHIPMENT.HELP.CONFIG_WARN_LI_1')}</li>
                        <li>{t('INTERNAL.CLIENT.SHIPMENT.HELP.CONFIG_WARN_LI_2')}</li>
                    </ul>
                </div>

                <img alt="screenshot" src={process.env.PUBLIC_URL + "/images/shipment-help-1.png"} style={{width: '100%'}}/>
                <img alt="screenshot" src={process.env.PUBLIC_URL + "/images/shipment-help-2.png"} style={{width: '100%'}}/>
            </div>
        </Modal>
    </>
}

export default ModalShipmentHelp;