import React, {FunctionComponent, useCallback, useContext, useMemo, useState} from 'react';
import NpiDisplayLayoutContent from "../components/display/layout-content";
import {Alert, Avatar, Card, Col, Empty, Form, List, Row, Select, Spin, Typography} from "antd";
import { NpiInternalContext } from "../contexts/internal-context";
import { useParams } from 'react-router';
import NpiInputDateInterval from "../components/input/date-interval";
import moment from "moment-timezone";
import {useMount, useRequest} from "ahooks";
import npiApi from "../services/api";
import NpiBatteryChart from "../components/display/battery-charts";
import _ from "lodash";
import NpiInputSelectCountry from "../components/input/select-country";
import NpiInputSelectProgram from "../components/input/select-program";
import NpiInputDatePicker from "../components/input/date-picker";
import colors from "../services/colors";
import {IWavePosStatusEnum} from "../types/wave-pos";
import {useTranslation} from "react-i18next";
import useRestrictedToRights from "../hooks/use-restricted-to-rights";
import {IRightsEnum} from "../types/user";

const { Option } = Select;
const { Title, Text } = Typography;

interface INpiBatteryChartContainer {
    npiId: number;
}

type ParamTypes  = {
    npiId: string;
}

type Props = INpiBatteryChartContainer;

const groupingOptions : any = {
    1: ['wave', 'program', 'region', 'country'],
    2: ['wave', 'region', 'country', 'program'],
    3: ['wave', 'region', 'country', 'partner'],
};

const NpiBatteryChartContainer : FunctionComponent<Props> = () => {
    useRestrictedToRights([IRightsEnum.VIEW_SENSITIVE_DATA]);
    const {setBreadcrumbs} = useContext(NpiInternalContext);
    const {npiId} = useParams<ParamTypes>();
    const {t} = useTranslation();

    const [npi, setNpi] = useState<any>(null);
    const [npiDates, setNpiDates] = useState<any>({min:null, max:null});
    const [grouping, setGrouping] = useState<number>(1);
    const [countries, setCountries] = useState<number[]>([]);
    const [programs, setPrograms] = useState<string[]>([]);
    const [partners, setPartners] = useState<any[]>([]);
    const [selectedCountries, setSelectedCountries] = useState<number[]>([]);
    const [selectedPrograms, setSelectedPrograms] = useState<string[]>([]);
    const [selectedPartners, setSelectedPartners] = useState<string[]>([]);
    const [selectedDate, setSelectedDate] = useState<any>(null);
    const [data, setData] = useState<any>(null);
    const {runAsync, loading} = useRequest(npiApi.internal.battery.chart, {manual: true});
    const notReady = npiDates.min === null && npiDates.max === null && !!npi;
    const utcOffset = useMemo(() => {const offset = moment().utcOffset() / 60; return offset>0 ? '+'+offset:offset+''}, []);
    const timezone = useMemo(() => moment.tz.guess(), []);

    //Launch a request to the server to load battery charts
    const launch = useCallback((params={}) => {
        if(notReady) return;

        //Use default config + overwrite with params if necessary
        const config = _.extend({
            id: Number(npiId),
            date: selectedDate?.format("YYYY-MM-DD HH:mm:ss"),
            group: groupingOptions[grouping],
            countries: selectedCountries,
            programs: selectedPrograms,
            partners: selectedPartners,
        }, params);

        runAsync(config).then(({ data, countries, programs, partners, npiDates, npi } : any) => {
            setData(data);
            setCountries(countries);
            setPrograms(programs);
            setPartners(partners.map((p:string) => ({label: p, value: p})));
            setNpiDates(npiDates);
            setNpi(npi);
            setBreadcrumbs([
                {url: '/npi', name: 'NPI'},
                {url: `/battery-chart/${npiId}`, name: npi.name},
            ]);

            if(selectedDate === null){
                setSelectedDate(moment(npiDates.max));
            }
        });
    }, [npiId, runAsync, setData, setCountries, setPartners, setNpiDates, setNpi, setBreadcrumbs, selectedDate, selectedPrograms, selectedPartners, selectedCountries,  grouping, notReady]);

    //On change the date (from input of from slider)
    const onChangeDate = useCallback((selectedDate:any) => {
        const date = _.isString(selectedDate) ? moment(selectedDate, 'YYYY-MM-DD HH:mm:ss') : selectedDate;
        setSelectedDate(date);
        launch({date: date.format('YYYY-MM-DD HH:mm:ss')});
    }, [launch, setSelectedDate]);

    //Change grouping
    const onChangeGrouping = (grouping: number) => {
        setGrouping(grouping);
        launch({group: groupingOptions[grouping]});
    };

    /**
     * Toggle selected countries
     * @param newSelectedCountries
     */
    const onChangeCountries = (newSelectedCountries: number[]) => {
        if( ! _.isEqual(selectedCountries, newSelectedCountries)){
            setSelectedCountries(newSelectedCountries);
            launch({countries: newSelectedCountries});
        }
    };

    /**
     * Toggle selected programs
     * @param newSelectedPrograms
     */
    const onChangePrograms = (newSelectedPrograms: string[]) => {
        setSelectedPrograms(newSelectedPrograms);
        launch({programs: newSelectedPrograms});
    };

    /**
     * Toggle selected partners
     * @param newSelectedPartners
     */
    const onChangePartners = (newSelectedPartners: string[]) => {
        setSelectedPartners(newSelectedPartners);
        launch({partners: newSelectedPartners});
    };

    //Configuration of the datepicker
    const configDatePicker = useMemo(() => ({
        pickerProps: {
            placeholder: t('INTERNAL.CLIENT.COMMON.SELECT_DATE'),
            showTime: {format: 'HH:mm'},
            showNow: false,
            format: "YYYY-MM-DD HH:mm",
            disabled: loading || notReady,
        },
        value: selectedDate,
        onChange: onChangeDate,
        useLocalUtc: true,
    }), [loading, notReady, selectedDate, onChangeDate, t]);

    //Launch on mounting to load the initial full battery data
    useMount(launch);

    return <NpiDisplayLayoutContent>
        <Row gutter={[16, 40]}>
            <Col span={20}>
                <Title>{npi?.name}</Title>
            </Col>
            <Col span={4} style={{textAlign: 'end'}}>
                <Text italic>{t('INTERNAL.CLIENT.DASHBOARD.TIMES_DISPLAYED_IN_UTC', {utcOffset: utcOffset, timezone: timezone})}</Text>
            </Col>
        </Row>
        <Row gutter={[16, 40]}>
            <Col span={15}>
                <Select defaultValue={1} onChange={onChangeGrouping} style={{ width: "40%" }} disabled={loading || notReady}>
                    <Option value={1}>{t('INTERNAL.CLIENT.DASHBOARD.Group_By_Wave_Program_Region_Country')}</Option>
                    <Option value={2}>{t('INTERNAL.CLIENT.DASHBOARD.Group_By_Wave_Region_Country_Program')}</Option>
                    <Option value={3}>{t('INTERNAL.CLIENT.DASHBOARD.Group_By_Wave_Region_Country_Partner')}</Option>
                </Select>
            </Col>
            <Col span={9} style={{textAlign: 'end'}}>
                <NpiInputDatePicker {...configDatePicker}/>
            </Col>
        </Row>

         <NpiInputDateInterval selectedDate={ selectedDate } onChangeDate={ onChangeDate } interval={ 1 } dateMax={ npiDates.max } dateMin={ npiDates.min } disabled={loading || notReady}/>

         {/*<Form layout="inline" style={{marginBottom: 20}}>*/}
         <Row gutter={[20,20]}>
             <Col span={8}>
                 <Form.Item label={t('INTERNAL.CLIENT.COMMON.COUNTRIES')}>
                     <NpiInputSelectCountry mode='multiple' value={selectedCountries} onChange={onChangeCountries} inIdsList={countries} disabled={loading || notReady} dropdownMatchSelectWidth={false} allowClear/>
                 </Form.Item>
             </Col>
             <Col span={8}>
                 <Form.Item label={t('INTERNAL.CLIENT.COMMON.PROGRAMS')}>
                     <NpiInputSelectProgram mode='multiple' value={selectedPrograms} onChange={onChangePrograms} inList={programs} disabled={loading || notReady} dropdownMatchSelectWidth={false} allowClear/>
                 </Form.Item>
             </Col>
             <Col span={8}>
                 <Form.Item label={t('INTERNAL.CLIENT.COMMON.PARTNERS')}>
                     <Select mode='multiple' value={selectedPartners} options={partners} onChange={onChangePartners}  disabled={loading || notReady} dropdownMatchSelectWidth={false} allowClear placeholder="Partners..."/>
                 </Form.Item>
             </Col>
         </Row>

        {/*Message when the NPI has no data at all*/}
        {notReady && <Alert message={t('INTERNAL.CLIENT.DASHBOARD.NPI_HAS_NO_DATA')} style={{marginTop: 20, marginBottom: 20}}/>}

        {/*The battery display*/}
        <NpiBatteryChart depth={1} data={data} loading={loading} filters={{status: [IWavePosStatusEnum.NEUTRAL]}}/>

        {/*Filters are making no data*/}
        {data === null && !loading && !notReady && <Empty/>}

        {/*Show a spinner when loading and no data at all*/}
        {data === null && loading && <div style={{textAlign: 'center'}}><Spin size="large"/></div>}

        {data !== null && !notReady && <Row gutter={[20,20]}>
            <Col span={12}>
                <Card title="Legend" size="small">
                    <List bordered={false} size="small">
                        <List.Item><Avatar style={{backgroundColor: colors.blue}} size={18}/> {t('INTERNAL.CLIENT.COMMON.STORE_COMPLETE')}</List.Item>
                        <List.Item><Avatar style={{backgroundColor: colors.yellow}} size={18}/> {t('INTERNAL.CLIENT.COMMON.STORE_NOT_COMPLETE')}</List.Item>
                        <List.Item><Avatar style={{backgroundColor: colors.greyBatteryStatus}} size={18}/> {t('INTERNAL.CLIENT.COMMON.DATA_NOT_SUBMITTED')}</List.Item>
                    </List>
                </Card>
            </Col>
            {/*<Col span={12}>*/}
            {/*    <Card title="Information" size="small">*/}
            {/*        <ul>*/}
            {/*            <li>Click on a bar to expand it</li>*/}
            {/*            <li>Hover over the part of a bar to see the number of POS</li>*/}
            {/*        </ul>*/}
            {/*    </Card>*/}
            {/*</Col>*/}
        </Row>}

    </NpiDisplayLayoutContent>;
};

export default NpiBatteryChartContainer;
