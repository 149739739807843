import React, {useContext, useEffect, useState} from 'react';
import './App.css';
import {useLocation} from "react-router-dom";
import {ConfigProvider, notification, Spin} from 'antd';
import {LicenseManager} from "ag-grid-enterprise";
import 'ag-grid-enterprise';
import {EXTERNAL_ROUTE_DEV_PREFIX} from "./helpers/router";
import {ViewModeEnum, ViewTypeEnum} from "./types/view";
import NpiInternalApp from "./app-internal";
import NpiExternalApp from "./app-external";
import {Helmet, HelmetProvider} from "react-helmet-async";
import NpiLanguageContext from "./contexts/language-context";
import {NpiInternalContextProvider} from "./contexts/internal-context";
import {NpiUiContextProvider} from "./contexts/ui-context";

// -- set up ag grid
const license = process.env.AG_GRID_LICENSE || '';
LicenseManager.setLicenseKey(license);


function App() {
    const location = useLocation();
    const languageContext = useContext(NpiLanguageContext);
    const REACT_APP_VIEW_MODE = (process.env.REACT_APP_VIEW_MODE || "") as ViewModeEnum;

    const hasDevViewModeEnabled = REACT_APP_VIEW_MODE === ViewModeEnum.DEV;
    const hasInternalViewAccess = hasDevViewModeEnabled || REACT_APP_VIEW_MODE === ViewModeEnum.INTERNAL
    const hasExternalViewAccess = hasInternalViewAccess || REACT_APP_VIEW_MODE === ViewModeEnum.EXTERNAL

    const [currentViewType, setCurrentViewType] = useState<ViewTypeEnum>(ViewTypeEnum.NONE);


    /**
     * Check if REACT_APP_VIEW_MODE is correctly filled by user at component initialization
     */
    useEffect(() => {
        //check if REACT_APP_VIEW_MODE is set
        if (!process.env.REACT_APP_VIEW_MODE) {
            notification.error({
                message: `Please provide valid value for "REACT_APP_VIEW_MODE" env variable.`,
            });
            return;
        }

        //prevent unhandled value for REACT_APP_VIEW_MODE
        if (!(process.env.REACT_APP_VIEW_MODE in ViewModeEnum)) {
            notification.error({
                message: `Unhandled value "${process.env.REACT_APP_VIEW_MODE}" for "REACT_APP_VIEW_MODE" env variable.`,
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [null])

    /**
     * Define which view type need to be rendered on location change
     */
    useEffect(() => {
        //check if dev and startsWith("ext/")
        if (hasDevViewModeEnabled && location.pathname.startsWith(EXTERNAL_ROUTE_DEV_PREFIX || "")) {
            setCurrentViewType(ViewTypeEnum.EXTERNAL);
        }
        //the external mode is only used outside the dev environment, we assume that the external is on another domain name
        else if (hasExternalViewAccess && window.location.origin === process.env.REACT_APP_EXTERNAL_CLIENT_ROOT) {
            setCurrentViewType(ViewTypeEnum.EXTERNAL);
        } else if (hasInternalViewAccess) {
            setCurrentViewType(ViewTypeEnum.INTERNAL);
        } else if (hasExternalViewAccess) {
            setCurrentViewType(ViewTypeEnum.EXTERNAL);
        } else {
            setCurrentViewType(ViewTypeEnum.NONE);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, window.location])

    //do not display if unhandled view mode
    if (currentViewType === ViewTypeEnum.NONE) {
        return null;
    }

    //wait language context initialisation, for pass the header "language" to interface call
    if(!languageContext.isInitialized){
        return <div style={{textAlign: 'center', padding: '30px 50px'}}>
            <Spin tip={<>Loading langs ...</>}/>
        </div>
    }

    return (
        <HelmetProvider>
            <Helmet>
                <meta http-equiv="Content-Security-Policy"
                content={[
                    "default-src 'self' data: *.apple.com *.npi-tracker.com npi-tracker.com *.palenque.on-web.fr localhost ws://*.apple.com:3001 *.box.com *.boxcloud.com",
                    "style-src 'self' 'unsafe-inline' fonts.googleapis.com",
                    "font-src 'self' data: fonts.gstatic.com",
                    "img-src 'self' data: blob: *.apple.com *.npi-tracker.com npi-tracker.com *.palenque.on-web.fr localhost ws://*.apple.com:3001 *.apple.com:3001 cdnjs.cloudflare.com *.box.com *.boxcloud.com",
                    process.env.REACT_APP_DEV_MODE ? "script-src 'self' 'unsafe-inline'":null, //allow unsafe inline in development, to allow auto refresh on code change
                ].join(';')}/>
                <meta http-equiv="description" content="Apple New Product Integration."/>
            </Helmet>


            <ConfigProvider direction={languageContext.currentLang.is_rtl ? 'rtl' : 'ltr'}>
                <NpiUiContextProvider>
                    { currentViewType === ViewTypeEnum.INTERNAL
                        ? <NpiInternalContextProvider><NpiInternalApp/></NpiInternalContextProvider>
                        : <NpiExternalApp />
                    }
                </NpiUiContextProvider>
            </ConfigProvider>

        </HelmetProvider>
    );
}

export default App;
