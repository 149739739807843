import React, { useMemo } from "react";
import styled from "styled-components";
import { IShipmentKpis, ShipmentFeedback } from "../../types/shipment";
import { Tooltip } from "antd";
import NpiDisplayDate from "../display/date";
import {useTranslation} from "react-i18next";

const StyledCategory = styled.span`
    margin-bottom: 0px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.45);
`;
const StyledValue = styled.span` 
    color: rgba(0, 0, 0, 0.85);
`;


export interface IShipmentKpisProps {
    kpis: IShipmentKpis;
    status : ShipmentFeedback;
}
const ShipmentsKpis = (props: IShipmentKpisProps): JSX.Element => {

    const { kpis, status } = props;
    const { t } = useTranslation();

    const percent = useMemo(() => {
        const received = status === ShipmentFeedback.POS ? kpis.total_received_pos : kpis.total_received_vendor;
        return ((received / kpis.total_assets) * 100 || 0).toFixed(1);
    }, [kpis, status]);

    return (
        <div style={{height: '100%'}}>
            <div>
                <Tooltip placement="right" title="Total of lines in DB.">
                    <StyledCategory>{t('INTERNAL.CLIENT.SHIPMENT.SHIPMENT_STATS.TOTAL_IMPORTED')}: </StyledCategory> &nbsp; <StyledValue>{ kpis.total_lines }</StyledValue>
                </Tooltip>
            </div>
            <div>
                <Tooltip placement="right" title="Total of lines from file.">
                    <StyledCategory>{t('INTERNAL.CLIENT.SHIPMENT.SHIPMENT_STATS.TOTAL_WITHOUT_MERGE')}: </StyledCategory> &nbsp; <StyledValue>{ kpis.total_lines_merged }</StyledValue>
                </Tooltip>
            </div>
            <div>
                <Tooltip placement="right" title="Total number of unique Tracking IDs">
                    <StyledCategory>{t('INTERNAL.CLIENT.SHIPMENT.SHIPMENT_STATS.TOTAL_PACKAGE')}: </StyledCategory> &nbsp; <StyledValue>{ kpis.total_packages }</StyledValue>
                </Tooltip>
            </div>
            <div>
                <Tooltip placement="right" title="Sum of all Material Quantity">
                    <StyledCategory>{t('INTERNAL.CLIENT.SHIPMENT.SHIPMENT_STATS.TOTAL_ASSETS')} : </StyledCategory> &nbsp; <StyledValue>{ kpis.total_assets }</StyledValue>
                </Tooltip>
            </div>
            <div>
                <Tooltip placement="right" title={`percentage of asset quantities  ${status === ShipmentFeedback.VENDOR ? 'delivered' : 'received'}` }>
                    <StyledCategory>{status === ShipmentFeedback.VENDOR ? t('INTERNAL.CLIENT.SHIPMENT.SHIPMENT_STATS.TOTAL_DELIVRED')+ ' :' : t('INTERNAL.CLIENT.SHIPMENT.SHIPMENT_STATS.TOTAL_RECEIVED') + ' :'}</StyledCategory> &nbsp; <StyledValue>{`${percent}%`}</StyledValue>
                </Tooltip>
            </div>
            <div>
                <Tooltip placement="right" title="Total number of all assets with awaiting dispatch status">
                    <StyledCategory>{t('INTERNAL.CLIENT.SHIPMENT.SHIPMENT_STATS.TOTAL_AWAITNG')}: </StyledCategory> &nbsp; <StyledValue>{ kpis.total_awaiting_dispatch }</StyledValue>
                </Tooltip>
            </div>
            <div>
                <Tooltip placement="right" title="When data was last updated">
                    <StyledCategory>{t('INTERNAL.CLIENT.COMMON.LAST_UPDATED_AT')}: </StyledCategory> &nbsp; <StyledValue><NpiDisplayDate value={kpis.update_date} hours={'inline'}/></StyledValue>
                </Tooltip>
            </div>
        </div>
    );
};

export default ShipmentsKpis;