import NpiDisplayNpiInputFormTreeError from "../../display/form-tree-errors";
import NpiDisplayPosStatus from "../../display/pos-status";
import NpiExternalWaveCollapsePanel from "./wave-collapse-panel";
import NpiLanguageContext from "../../../contexts/language-context";
import React, {Dispatch, useContext, useEffect, useMemo, useState} from "react";
import Waves from "@2fd/ant-design-icons/lib/Waves";
import _ from "lodash";
import npiApi from "../../../services/api";
import styled from "styled-components";
import useExternalToken from "../../../hooks/use-external-token";
import {Button, Collapse, Empty, notification} from "antd";
import {ExternalTokenRight} from "../../../types/external-token";
import {
    IFormAnswer,
    IFormAnswerByQuestion,
    IFormImagesAnswer,
    IFormImagesByQuestion,
    IQuestion
} from "../../../types/question";
import {IWave} from "../../../types/wave";
import {NpiDisplayWaveNameTranslation} from "../../display/translations";
import {observable} from "mobx";
import {useRequest} from "ahooks";
import {useTranslation} from "react-i18next";
import {IExternalPosViewDetailResponse, useNpiExternalPosContext} from "../../../contexts/external-pos-context";
import {INpiInputQuestionError} from "../../input/question";
import useSurveyErrors from "../../../hooks/use-survey-errors";
import ModalSurveySubmitSuccess from "../modal-survey-submit-success";
import NpiTabSurveyWaveContent from "./tab-survey-wave-content";
import {IWavePosStatusEnum} from "../../../types/wave-pos";


interface INpiExternalPosViewTabSurveyProps {
    onSubmitting: Dispatch<boolean>
}

/**
 * External POS Tab: Survey
 */
const NpiExternalPosViewTabSurvey = ({onSubmitting}: INpiExternalPosViewTabSurveyProps) => {
    const {t} = useTranslation();
    const {currentLang} = useContext(NpiLanguageContext);
    const {getFormErrors} = useSurveyErrors();

    // token context
    const {hasReturnLink, hasRight} = useExternalToken();
    const isUnifiedView = hasRight(ExternalTokenRight.ACCESS_UNIFIED_VIEW);

    // POS context
    const {posId, wavePosMappedByWaveId, waves, imagesAllowed, setNewData} = useNpiExternalPosContext();
    //waves need to be observables due to mobx dependency in NpiForm
    const observablesWaves = useMemo(() => waves.map(wave => observable({...wave})), [waves])

    // API call (upload images and update form)
    const {runAsync: uploadSurveyQuestionImages} = useRequest<any, any>(npiApi.external.image.uploadSurveyQuestionImages, {manual: true});
    const {runAsync: saveSurveysChanges} = useRequest<IExternalPosViewDetailResponse, any>(npiApi.external.saveSurveysChanges, {
        manual: true,
        onSuccess: (data) => {
            // update current context
            setNewData(data);

            // display success modal and clean latest answers changes
            setOpenSuccessModal(true);
            setAnswersChangesByWaveId({});
            setImageQuestionsByWaveId({});
        },
        onFinally: () => setLoadingSubmit(false)
    });

    // display values
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
    const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false);
    const [expandedWaveKeys, setExpandedWaveKeys] = useState<number[]>([]);

    // get form status by Waves
    const [treeErrors, setTreeErrors] = useState<any[]>([]);
    const [errorsQuestionMessageByWaveId, setErrorsQuestionMessageByWaveId] = useState<Record<number, INpiInputQuestionError[]>>({})
    const [answersChangesByWaveId, setAnswersChangesByWaveId] = useState<Record<number, IFormAnswerByQuestion>>({});
    const [displayedQuestionsByWaveId, setDisplayedQuestionsByWaveId] = useState<Record<number, IQuestion[]>>({});
    const [imageQuestionsByWaveId, setImageQuestionsByWaveId] = useState<Record<number, IFormImagesByQuestion>>({});

    // get only waves that have changes
    const changedWaveIds = useMemo(() => Object.keys(answersChangesByWaveId).map(id => Number(id)), [answersChangesByWaveId]);
    const hasChanges = useMemo(() => changedWaveIds.length > 0, [changedWaveIds]);

    // -- Automatically expand/open Non-Valid surveys
    useEffect(() => {
        setExpandedWaveKeys( _.chain(wavePosMappedByWaveId)
            .filter(wi => wi.status !== IWavePosStatusEnum.POSITIVE)
            .map(wi => wi.wave_id)
            .value()
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wavePosMappedByWaveId])


    // -- callback on submit
    const onClickSubmitSurveys = () => {
        //check if survey changes are valid
        if (!validateSurveysAndDisplayErrors()) {
            return;
        }

        setLoadingSubmit(true);

        // get all displayed questions ids (for all waves)
        const allDisplayedQuestionIds = _.reduce(displayedQuestionsByWaveId,
            (acc: number[], questions: IQuestion[]) => [...acc, ...questions.map(q => q.id)],
            []);

        // merge all imageQuestions
        const imageQuestions = _.reduce(imageQuestionsByWaveId,
            (acc: IFormImagesAnswer[], imAnswers) => [...acc, ...Object.values(imAnswers)],
            []);

        // If a condition hides an ImageQuestion, we remove all its previous images (if no data send, server will delete images for this question)
        const displayedImageQuestions = imageQuestions.filter(iq => allDisplayedQuestionIds.includes(iq.question_id))

        // Upload Image Questions & Save the Form
        uploadSurveyQuestionImages(posId, displayedImageQuestions, changedWaveIds)
            .then(() => saveSurveysChanges(posId, answersChangesByWaveId))
            .catch(() => setLoadingSubmit(false))
        ;
    };

    // validating all surveys and display errors
    const validateSurveysAndDisplayErrors = (): boolean => {
        //avoid unwanted behavior
        if (!changedWaveIds.length) {
            return false;
        }

        let newTreeErrors: any = [];
        let errorsMessageByWaveId: Record<number, INpiInputQuestionError[]> = {};

        //check if all mandatory questions are fully filled
        for (let waveId of changedWaveIds) {
            const wave = waves.find(w => w.id === waveId);
            if (!wave) {
                throw new Error(`User try to fill unknown wave with ID=${waveId}.`);
            }

            let waveTreeErrors = [];

            //if image is mandatory for wave and user has not uploaded any images
            if (wave.is_images_mandatory && !wavePosMappedByWaveId[wave.id].images.length) {
                waveTreeErrors.push({
                    title: <p style={{color: "red", fontWeight: 'bold'}}>{t('EXTERNAL.CLIENT.POS_VIEW.ERROR_MANDATORY_IMAGES')}</p>,
                    selectable: false,
                    key: `${wave.id}-imageMandatory`
                })
            }

            //wave displayed questions
            const nonHiddenQuestions: IQuestion[] = displayedQuestionsByWaveId[waveId] ?? [];
            //wave answered questions
            const answers: IFormAnswer[] = Object.values({...wavePosMappedByWaveId[wave.id].answers ?? {}, ...answersChangesByWaveId[wave.id] ?? {}});

            const formErrors = getFormErrors(nonHiddenQuestions, answers, onErrorClick);

            //add question errors to wave errors (form & tree)
            waveTreeErrors = [ ...waveTreeErrors, ...formErrors.tree]
            errorsMessageByWaveId[waveId] = formErrors.form

            //skip if no errors
            if (waveTreeErrors.length > 0) {
                if (!isUnifiedView)
                { // for simple wave mode: we don't need to have wave parent
                    newTreeErrors = [...newTreeErrors, ...waveTreeErrors];
                }
                else
                { // add a parent tree data wave entry
                    const waveErrorNode = {
                        title: <span>
                                <NpiDisplayWaveNameTranslation wave={wave} langId={currentLang?.id}/>
                                <small style={{color: 'red', fontWeight: 'bold'}}>({waveTreeErrors.length} errors)</small>
                            </span>,
                        key: `${waveId}`,
                        selectable: false,
                        children: waveTreeErrors
                    }
                    //add errors
                    newTreeErrors = [...newTreeErrors, waveErrorNode];
                }
            }
        }

        //add errors
        setTreeErrors(newTreeErrors);
        setErrorsQuestionMessageByWaveId(errorsMessageByWaveId);

        return newTreeErrors.length === 0;
    };


    // -- NAVIGATION IN FORM

    // -- Disabled navigation when submitting
    useEffect(() => {
        onSubmitting(loadingSubmit);
        const handler = (e: BeforeUnloadEvent) => {
            e.preventDefault();
            if (!loadingSubmit) {
                return;
            }
            e.returnValue = true;
        };
        window.addEventListener("beforeunload", handler);
        return () => window.removeEventListener("beforeunload", handler);

    }, [loadingSubmit, onSubmitting]);

    // -- Scroll to error when Tree is not empty
    useEffect(() => {
        if (treeErrors.length > 0) {
            const tabPanelSurveysDiv = document.getElementById("survey-tab-pane");
            if (tabPanelSurveysDiv) {
                tabPanelSurveysDiv.scrollTo({top: tabPanelSurveysDiv.scrollHeight, behavior: 'smooth'});
            }
        }
    }, [treeErrors]);

    // -- Navigate to survey when click on error
    const onErrorClick = (question: IQuestion) => {
        //prevent unwanted behavior, but honestly this is not supposed to happen
        if (!question.wave_id) {
            notification.warn({message: t('EXTERNAL.CLIENT.POS_VIEW.QUESTION_REQUIRE_WAVE_ID')})
            return;
        }

        const questionHtmlElement = document.getElementById(`question-${question.id}`);
        if (!!questionHtmlElement) {
            //add the concerned wave to the open waves
            if( !expandedWaveKeys.includes(question.wave_id) ){
                setExpandedWaveKeys([...expandedWaveKeys, question.wave_id]);
            }

            //calculate where question ref is located in parent div, to scroll to it
            const tabPanelSurveysDiv = document.getElementById("survey-tab-pane");
            if (!!tabPanelSurveysDiv) {
                //scroll to html element and remove height for get approximate position
                setTimeout(() => {
                    tabPanelSurveysDiv.scrollTo({top: (questionHtmlElement.offsetTop - (questionHtmlElement.scrollHeight * 2)), behavior: 'smooth'});
                }, 300);
            }
        }
    }


    // -- Track answer changes in NpiInputForm
    const onAnswerChanges = (waveId: number, newChanges: IFormAnswerByQuestion) => {
        if (Object.keys(newChanges).length > 0) {
            answersChangesByWaveId[waveId] = newChanges;
        } else {
            delete answersChangesByWaveId[waveId];
        }
        setAnswersChangesByWaveId({...answersChangesByWaveId});
    }


    // -- RENDER

    // -- Render a single Wave survey content
    const renderWaveSurveyContent = (wave: IWave): React.ReactNode => <NpiTabSurveyWaveContent
        wave={wave}
        errors={errorsQuestionMessageByWaveId[wave.id]}
        loading={loadingSubmit}
        formChanges={answersChangesByWaveId[wave.id]}
        setFormChanges={v => onAnswerChanges(wave.id, v)}
        displayedQuestions={displayedQuestionsByWaveId[wave.id]}
        setDisplayedQuestions={v => setDisplayedQuestionsByWaveId({...displayedQuestionsByWaveId, [wave.id]: v})}
        imageQuestions={imageQuestionsByWaveId[wave.id]}
        setImageQuestions={v => setImageQuestionsByWaveId({...imageQuestionsByWaveId, [wave.id]: v})}
    />

    return <>
        {/* Save surveys success modal */}
        <ModalSurveySubmitSuccess imagesAllowed={imagesAllowed} open={openSuccessModal} setOpen={setOpenSuccessModal}/>

        {/*Display wave surveys*/}
        { !!observablesWaves.length
            ? ( !isUnifiedView
                    ? renderWaveSurveyContent(observablesWaves[0])
                    : <Collapse activeKey={expandedWaveKeys} onChange={(newKey: string|string[]) => setExpandedWaveKeys(typeof newKey === "string" ? [Number(newKey)] : newKey.map(k => Number(k)))}>
                        { observablesWaves.map((wave: IWave) =>
                            <NpiExternalWaveCollapsePanel key={wave.id} wave={wave} extra={<NpiDisplayPosStatus status={wavePosMappedByWaveId[wave.id].status} style={{fontSize: 15, paddingTop: 0}}/>}>
                                {renderWaveSurveyContent(wave)}
                            </NpiExternalWaveCollapsePanel>
                        )}
                    </Collapse>
            )
            : <Empty
                style={{paddingTop: 20}}
                image={<Waves style={{fontSize: 25}}/>}
                description={t('EXTERNAL.CLIENT.POS_VIEW.NO_WAVE_AVAILABLE')}
            />
        }

        {/* Display surveys tree errors */}
        {/*// TODO -- why expand tree error only when token CanViewPOSList ?*/}
        { !!treeErrors.length && <NpiDisplayNpiInputFormTreeError defaultExpandAll={hasReturnLink} treeData={treeErrors}/>}

        {/* Display the Submit Button on bottom, only when the user does some changes in answers */}
        { hasChanges && <StyledSubmitDiv>
            <Button type={"primary"} onClick={onClickSubmitSurveys} loading={loadingSubmit} style={{margin: "auto", display: 'block', padding: '4px 10px'}}>
                {t('COMMON.SAVE')}
            </Button>
        </StyledSubmitDiv>}
    </>
}

const StyledSubmitDiv = styled.div`
    position: fixed;
    border-top: 1px solid #a7a6a685;
    height: 38px;
    padding: 2px;
    background-color: white;
    bottom: 0;
    z-index: 500;
    width: 100%;
`

export default NpiExternalPosViewTabSurvey;