import { Col, Form, Input, Row, Select } from "antd";
import React from "react";
import {useTranslation} from "react-i18next";

const { Option } = Select;

const FormEditShipment = (props: any)  : JSX.Element => {
    const {t} = useTranslation();

    return (
        <>
            <Row>
                <Col span={12}>
                    <Form.Item  labelCol={{span: 10}} name="material_desc" label={t('INTERNAL.CLIENT.SHIPMENT.MATERIAL')}>
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item  labelCol={{span: 10}} name="contact" label={t('INTERNAL.CLIENT.COMMON.CONTACT')}>
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item  labelCol={{span: 10}} name="status" label={t('INTERNAL.CLIENT.COMMON.STATUS')}>
                        <Select>
                            <Option value="received">{t('INTERNAL.CLIENT.SHIPMENT.SHIPMENT_STATUS.RECEIVED')}</Option>
                            <Option value="not_received">{t('INTERNAL.CLIENT.SHIPMENT.SHIPMENT_STATUS.NOT_RECEIVED')}</Option>
                            <Option value="missing">{t('INTERNAL.CLIENT.SHIPMENT.SHIPMENT_STATUS.MISSING')}</Option>
                            <Option value="damaged">{t('INTERNAL.CLIENT.SHIPMENT.SHIPMENT_STATUS.DAMAGED')}</Option>
                            <Option value="wrong">{t('INTERNAL.CLIENT.SHIPMENT.SHIPMENT_STATUS.WRONG')}</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item  labelCol={{span: 10}} name="phone_number" label={t('INTERNAL.CLIENT.COMMON.PHONE_NUMBER')}>
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item  labelCol={{span: 10}} name="returned" label={t('INTERNAL.CLIENT.SHIPMENT.IS_RETURNED')}>
                        <Input disabled/>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item  labelCol={{span: 10}} name="address_1" label={t('INTERNAL.CLIENT.COMMON.ADDRESS')}>
                        <Input disabled/>
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item  labelCol={{span: 10}} name="carrier" label={t('INTERNAL.CLIENT.COMMON.CARRIER')}>
                        <Input disabled/>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item  labelCol={{span: 10}} name="address_2" label={t('INTERNAL.CLIENT.COMMON.ADDRESS_2')}>
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item  labelCol={{span: 10}} name="tracking_id" label={t('COMMON.TRACKING_ID')}>
                        <Input disabled/>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item  labelCol={{span: 10}} name="address_city" label={t('INTERNAL.CLIENT.COMMON.CITY')}>
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item  labelCol={{span: 10}} name="material_quantity" label={t('INTERNAL.CLIENT.SHIPMENT.QUANTITY')}>
                        <Input disabled/>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item  labelCol={{span: 10}} name="address_country" label={t('INTERNAL.CLIENT.COMMON.COUNTRY')}>
                        <Input disabled />
                    </Form.Item>
                </Col>
            </Row>
        </>
    );
}

export default FormEditShipment;
