import React, {useState} from "react";
import {Button, Input, Modal, ModalProps, notification, Select, Space, Switch, Table, Tag} from "antd";
import npiApi from "../../services/api";
import {ColumnProps} from "antd/es/table";
import {CloseOutlined, DeleteOutlined, EditOutlined, SaveOutlined, SettingOutlined} from "@ant-design/icons";
import _ from "lodash";
import {SelectProps} from "antd/lib/select";
import {PlusOutlined} from "@ant-design/icons/lib";
import {useTranslation} from "react-i18next";
import {IShipmentConfig} from "../../types/shipment";
import {observer} from "mobx-react";
import {useRequest} from "ahooks";
const { Option } = Select;

const ModalEditShipmentTemplates = observer((props: ModalProps) => {
    const {t} = useTranslation();

    const {loading: fetching} = useRequest(npiApi.internal.shipments.templates.list, {
        onSuccess: (data : IShipmentConfig[]) => setShipConfigs(data)
    });

    const {loading: creating, runAsync: runCreate} = useRequest(npiApi.internal.shipments.templates.create, {
        manual: true,
        onSuccess: (createConfig:IShipmentConfig) => {
            setShipConfigs([createConfig, ...shipConfigs]);
            setNewValue('');
        }
    });

    const {loading: saving, runAsync: runUpdate} = useRequest(npiApi.internal.shipments.templates.update, {
        manual: true,
        onSuccess: (updatedConfig:IShipmentConfig) => {
            // update config list with new datas
            const found = _.find(shipConfigs, {id: updatedConfig.id});
            if(found){
                shipConfigs.splice(shipConfigs.indexOf(found), 1, updatedConfig);
                setShipConfigs([...shipConfigs]);
            }
            switchEditRow(null)
        }
    });

    const [deleting, setDeleting] = useState<number|null>(null);
    const {runAsync: runDelete} = useRequest(npiApi.internal.shipments.templates.delete, {
        manual: true,
        onBefore: (params) => setDeleting(params[0]),
        onFinally: () => setDeleting(null),
        onSuccess: (deletedConfig:IShipmentConfig) => {
            setShipConfigs(shipConfigs.filter((c) => c.id !== deletedConfig.id));
        }
    });

    const [shipConfigs, setShipConfigs] = useState<IShipmentConfig[]>([]);
    const isSuperAdmin = false;
    const [editingKey, setEditingKey] = useState<number|null>(null);
    const [editingValues, setEditingValues] = useState<string[]>([]);
    const [newValue, setNewValue] = useState<string>('');

    // Edit row
    const isEditing = (id:number) => id === editingKey;
    const switchEditRow = (id:number|null, defaultValues:string[] = []) => {
        setEditingKey(id);
        setEditingValues(defaultValues);
    }


    const createRow = () => {
        runCreate({col_name: newValue})
            .then(() => notification.success({message: t('INTERNAL.CLIENT.SHIPMENT.TEMPLATE_SETTINGS_LINE_CREATED')}))
    }
    const updateRow = (id:number) => {
        runUpdate(id, {header_names: editingValues})
            .then(() => notification.success({message: t('INTERNAL.CLIENT.SHIPMENT.TEMPLATE_SETTINGS_LINE_UPDATED')}))
    }
    const updateSwitchValue = (id:number, field:string, value:boolean) => {
        runUpdate(id, {[field]: value})
            .then(() => notification.success({message: t('INTERNAL.CLIENT.SHIPMENT.TEMPLATE_SETTINGS_LINE_UPDATED')}))
    }

    // Delete a row
    const deleteRow = (id:number, colname: string) => {
        Modal.confirm({
            title: t('INTERNAL.CLIENT.SHIPMENT.TEMPLATE_SETTINGS_CONFIRM_DELETE', {colname: colname}),
            icon: <DeleteOutlined/>,
            okText: t('INTERNAL.CLIENT.COMMON.YES'), okButtonProps: {danger: true},
            onOk: () => runDelete(id)
                .then(() => notification.success({message: t('INTERNAL.CLIENT.SHIPMENT.TEMPLATE_SETTINGS_LINE_REMOVED')}))
        })
    }

    const selectEditProps:SelectProps<string[]> = {
        mode: "tags",
        style: { width: '100%' },
        placeholder: t('INTERNAL.CLIENT.SHIPMENT.TEMPLATE_SETTINGS_MATCHING_VALUES_PLACEHOLDER'),
        onChange: (v) => setEditingValues(v),
        optionFilterProp: "key", // if filter props is on "value" case is sensitive
    }

    // Table columns
    const columns:ColumnProps<IShipmentConfig>[] = [
        {title: t('INTERNAL.CLIENT.SHIPMENT.TEMPLATE_SETTINGS_COLUMN_NAME'), width: 130, dataIndex: 'col_name', fixed: 'left'},
        {title: t('INTERNAL.CLIENT.SHIPMENT.TEMPLATE_SETTINGS_COLUMN_ACTIVE'), width: 90, align: 'center', render: (config:IShipmentConfig) => <Switch size={'small'} checked={config.is_active} onChange={(checked) => updateSwitchValue(config.id, 'is_active', checked)} disabled={!isSuperAdmin}/>},
        {title: t('INTERNAL.CLIENT.SHIPMENT.TEMPLATE_SETTINGS_COLUMN_REQUIRED'), width: 90, align: 'center', render: (config:IShipmentConfig) => <Switch size={'small'} checked={config.is_required} onChange={(checked) => updateSwitchValue(config.id, 'is_required', checked)} disabled={!isSuperAdmin || !config.is_active}/>},
        {title: t('INTERNAL.CLIENT.SHIPMENT.TEMPLATE_SETTINGS_COLUMN_FILLED'), width: 90, align: 'center', render: (config:IShipmentConfig) => <Switch size={'small'} checked={config.must_be_filled} onChange={(checked) => updateSwitchValue(config.id, 'must_be_filled', checked)} disabled={!isSuperAdmin || !config.is_active}/>},

        // inline editing values
        {title: <>{t('INTERNAL.CLIENT.SHIPMENT.TEMPLATE_SETTINGS_MATCHING_VALUES')} <br/>
                <em><small>*{t('INTERNAL.CLIENT.SHIPMENT.TEMPLATE_SETTINGS_CASE')}</small></em>
            </>,
            render: (config:IShipmentConfig) => {
                const configHeaders = config.header_names ? config.header_names : [];
                const selectOptions = configHeaders.map((n) => <Option key={n.toLowerCase()} value={n}>{n}</Option>);
                return <>
                    {isEditing(config.id)
                        ? <Select {...selectEditProps} defaultValue={configHeaders}>
                            {selectOptions}
                        </Select>
                        : configHeaders.map((n, idx) => <Tag color="blue" key={idx}>{n}</Tag>)
                    }
                </>
        }},
        {
            width: 100,
            fixed: 'right',
            align: 'center',
            render: (config:IShipmentConfig) => {
                // -- switch editing mode
                return <>
                    {isEditing(config.id)
                        // SAVE or CANCEL
                        ? <Space size={'middle'}>
                            <Button onClick={() => updateRow(config.id)} title={t('COMMON.SAVE')} icon={<SaveOutlined/>} size={'small'} type={'primary'} loading={saving}/>
                            <Button onClick={() => switchEditRow(null)} title={t('COMMON.CANCEL')} icon={<CloseOutlined/>} size={'small'}/>
                        </Space>
                        // EDIT or DELETE
                        : <Space size={'middle'}>
                            <Button onClick={() => switchEditRow(config.id, config.header_names)} title={t('INTERNAL.CLIENT.COMMON.EDIT')} icon={<EditOutlined/>} size={'small'}/>
                            {isSuperAdmin
                            ? <Button onClick={() => deleteRow(config.id, config.col_name)} title={t('COMMON.DELETE')} icon={<DeleteOutlined/>} size={'small'} type={'primary'} danger loading={deleting === config.id}/>
                            : ''}
                        </Space>
                }
                </>
            },
        },
    ];

    // Config Modal
    const configModal = {
        title: t('INTERNAL.CLIENT.SHIPMENT.TEMPLATE_SETTINGS'),
        icon: <SettingOutlined/>,
        footer: null,
        width: 1000,
        ...props,
    }

    return <Modal {...configModal}>

        {/* -- create new line */}
        {isSuperAdmin ?
            <Input.Group compact size={'small'} style={{ maxWidth: '300px', marginLeft: 'auto' }}>
                <Input style={{ width: '200px' }} placeholder={t('INTERNAL.CLIENT.SHIPMENT.TEMPLATE_SETTINGS_NEW')} value={newValue} onChange={(e) => setNewValue(e.target.value)}/>
                <Button onClick={createRow} size={'small'} type={"primary"} icon={<PlusOutlined/>} loading={creating} disabled={creating || !newValue}>{t('INTERNAL.CLIENT.COMMON.CREATE')}</Button>
            </Input.Group> : ''}

        <Table
            rowKey="id"
            dataSource={shipConfigs}
            columns={columns}
            pagination={false}
            loading={{spinning: fetching, tip: t('INTERNAL.CLIENT.SHIPMENT.TEMPLATE_SETTINGS_FETCH')}}
            locale={{emptyText: <>{t('INTERNAL.CLIENT.SHIPMENT.TEMPLATE_SETTINGS_EMPTY')}</>}}
            scroll={{y: '60vh', x: 900}}
            size={'small'}
        />

    </Modal>
});

export default ModalEditShipmentTemplates;