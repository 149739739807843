import React, {Key, useContext, useMemo, useState} from 'react';
import {Button, Form, Input, Modal, notification, Table} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {ModalProps} from "antd/lib/modal";
import {IUser, IUserRoleEnum} from "../../types/user";
import npiApi from "../../services/api";
import NpiModalEdit from "../../components/modal-edit";
import NpiInputSelectRole from "../../components/input/select-role";
import NpiInputSelectRegion from "../../components/input/select-region";
import NpiInputSelectCountry from "../../components/input/select-country";
import {useTranslation} from "react-i18next";
import {NpiInternalContext} from "../../contexts/internal-context";
import NpiInputSelectMerchandisingGroup from "../../components/input/select-merchandising-group";

interface NpiModalUserAddType<T> extends ModalProps {
    onChange: (value:T)=>Promise<T>,
}

const IS_COUNTRY_RESTRICTED_ROLES = [
    IUserRoleEnum.COUNTRY_USER,
    IUserRoleEnum.MERCHANDISING_VENDOR,
]

/**
 * Fetch users from apple directory who are not in our database.
 * Choose a role and region for selected users and add them
 */
const NpiModalUserAddFromLDAP = ({onChange, ...props}:NpiModalUserAddType<any>) => {
    const {t} = useTranslation();
    const {regions} = useContext(NpiInternalContext);

    const [visible, setVisible] = useState<boolean>(false);
    const [fetching, setFetching] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [users, setUsers] = useState<IUser[]>([]);
    const [groupInfo, setGroupInfo] = useState<{dsid: number, name: string}|null>(null);


    // checkbox selection of user
    const [userSelected, setUserSelected] = useState<IUser[]>([]);
    const onChangeSelection = (selectedRowKeys: Key[], selectedRows: IUser[]) => {
        setUserSelected(selectedRows);
    };

    // search filter
    const [search, setSearch] = useState<string>('');
    const isFiltered = (u:IUser) => u.dsid.toLowerCase().includes(search) || u.firstname.toLowerCase().includes(search) || u.lastname.toLowerCase().includes(search) || u.email?.toLowerCase().includes(search);

    // fetch list of users from apple directory
    const fetchDatas = () => {
        setFetching(true);
        npiApi.internal.user.listLDAP()
            .then(({list,group}:{list: IUser[], group: any}) => {
                setUsers(list);
                setGroupInfo(group);
            })
            .finally(() => setFetching(false))
    }
    const openModal = () => {
        setVisible(true);
        fetchDatas();
    }


    // save new users (with config role, region, countries) and close modal
    const onSave = (config:any) => npiApi.internal.user.saveFromLDAP({users:userSelected.map((u) => u.dsid), ...config})
        .then((newUsers:IUser[]) => {
            notification.success({message: t('INTERNAL.CLIENT.USERS.NEW_USERS_ADDED', {nbUsers: newUsers.length})});
            onChange(newUsers);
            setUserSelected([]);
            setVisible(false);
        })
        .finally(() => setLoading(false))


    // Modal to choose role, region (and countries)
    const [selectedRole, setSelectedRole] = useState<number|null>(null);
    const [selectedRegion, setSelectedRegion] = useState<number|null>(null);
    const hasMerchandisingGroups = useMemo(() => {
        const region = regions.find( r => r.id === selectedRegion )
        return !!region && region["has_merchandising_groups_access"];
    }, [regions, selectedRegion]);

    const formModal = <NpiModalEdit
            button={t('INTERNAL.CLIENT.USERS.ADD_SELECTION', {nbSelection: userSelected.length})}
            title={t('INTERNAL.CLIENT.USERS.ADD_NEW_USERS')}
            okText={t('INTERNAL.CLIENT.COMMON.SAVE_SELECTION')}
            onChange={onSave}
            onClose={() => {
                setSelectedRole(null)
                setSelectedRegion(null)
            }}
            value={null}
            editable={true}
            formProps={{layout: 'vertical', wrapperCol: {span: 24}}}
    >
        <div style={{marginBottom: '20px'}}><em>{t('INTERNAL.CLIENT.USERS.CHOOSE_USERS_ROLE', {nbUsers: userSelected.length})}</em></div>

        <Form.Item name="role" label={t('INTERNAL.CLIENT.COMMON.ROLE')} rules={[{required: true}]}>
            <NpiInputSelectRole onChange={setSelectedRole} restrict={true} placeholder={t('INTERNAL.CLIENT.COMMON.ROLE')+'...'}/>
        </Form.Item>

        <Form.Item name="region_id" label={t('INTERNAL.CLIENT.COMMON.REGION')} rules={[{required: true}]}>
            <NpiInputSelectRegion onChange={setSelectedRegion} restrict={true} placeholder={t('INTERNAL.CLIENT.COMMON.REGION')+'...'}/>
        </Form.Item>

        {(!!selectedRole && !!selectedRegion && IS_COUNTRY_RESTRICTED_ROLES.includes(selectedRole)) ?
            <Form.Item name="countries" label={t('INTERNAL.CLIENT.COMMON.COUNTRIES')} rules={[{required: true}]}>
                <NpiInputSelectCountry inRegions={[selectedRegion]} mode={'multiple'} placeholder={t('INTERNAL.CLIENT.COMMON.COUNTRIES')} allowClear={true} grouped={true}/>
            </Form.Item> : ''}

        {(!!selectedRegion && hasMerchandisingGroups && selectedRole === IUserRoleEnum.MERCHANDISING_VENDOR) && <>
            <Form.Item name="merchandising_group_id" label={t('INTERNAL.CLIENT.COMMON.MERCHANDISING_GROUP')} rules={[{required: true}]}>
                <NpiInputSelectMerchandisingGroup regionId={selectedRegion} allowClear={true} dropdownMatchSelectWidth={false}/>
            </Form.Item>
        </>}
    </NpiModalEdit>;


    // Config Modal list of users
    const configModal = {
        open: visible,
        okButtonProps:{ disabled: !userSelected.length },
        onCancel: () => {
            setUserSelected([]);
            setVisible(false)
        },
        okText: formModal,
        maskClosable: false,
        width: 1000,
        bodyStyle: {maxHeight: '80vh', overflow: 'auto'},
        confirmLoading: loading,
        ...props,
    }

    const columnsTable = [
        {title: '#', dataIndex: 'dsid'},
        {title: t('INTERNAL.CLIENT.COMMON.FIRSTNAME'), dataIndex: 'firstname'},
        {title: t('INTERNAL.CLIENT.COMMON.LASTNAME'), dataIndex: 'lastname'},
        {title: t('INTERNAL.CLIENT.COMMON.EMAIL'), dataIndex: 'email'},
    ];

    return <>
        <Button onClick={openModal} type={"primary"} icon={<PlusOutlined />}>{t('INTERNAL.CLIENT.USERS.CREATE_USER')}</Button>
        <Modal {...configModal}>
            <div style={{marginTop: '30px'}}>

                <Input placeholder={t('INTERNAL.CLIENT.COMMON.SEARCH')+'...'} value={search} onChange={(e) => setSearch(e.target.value.toLowerCase())} allowClear={true} style={{marginBottom: '10px'}}/>

                <Table
                    rowKey="dsid"
                    rowSelection={{type: 'checkbox', onChange:onChangeSelection, selectedRowKeys: userSelected.map((u) => u.dsid)}}
                    loading={{spinning: fetching, tip: t('INTERNAL.CLIENT.USERS.FETCHING_USERS_FROM_APPLE_DIRECTORY')}}
                    dataSource={users.filter((u) => isFiltered(u))}
                    columns={columnsTable}
                    pagination={false}
                    locale={{emptyText: (<>{t('INTERNAL.CLIENT.USERS.CANNOT_FIND_USER')}
                        {groupInfo && <>, {t('INTERNAL.CLIENT.USERS.IN_GROUP')} <strong>{groupInfo.name} (<a href={"adir://groups/"+groupInfo.dsid} target={"_blank"} rel={"noreferrer"}>#{groupInfo.dsid}</a>)</strong></>}.
                        <Button onClick={fetchDatas} type={'link'}>{t('INTERNAL.CLIENT.COMMON.RELOAD')}</Button>
                    </>)}}
                />
            </div>
        </Modal>
    </>;
}

export default NpiModalUserAddFromLDAP;