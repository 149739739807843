import {IKpi} from "../../types/kpi";
import React from "react";
import {IDataRowDetail} from "../../containers/ww-dashboard";
import _ from "lodash";
import styled from "styled-components";
import NpiDisplayPercentage from "../display/percentage";
import {Tooltip} from "antd";

const StyledDiv = styled.div`

    .progress-container{
        float: left;
        position: relative;
        top: -10px;
        width: 100%;
    
        //Design of the bars
        .trail, .bar{
            height: 22px;
            border-bottom-right-radius: 5px;
            border-top-right-radius: 5px;
            position: absolute;
            left: 0;
            top: 0;
        }
        
        //text
        .percent-text{
            position: absolute;
            text-align: left;
            color: #fff;
            top: 2px;
            left: 5px;
            font-size: 12px;
            z-index: 10;
        }
        
        .trail{
            width: 100%;
            background: rgba(0,0,0,0.025);
        }
    }
    
    .points{
        position: absolute;
        right: 8px;
        top: 15px;
        font-size: 10px;
    }
    
    &.with-past{
        .progress-container{
            width: calc(100% - 40px);
        }
    }
`;

//Default colors
const defaultColors = ['#5e5e5f', '#a5a5a5', '#ccc'];

/**
 * Progress bar displayed total readiness of the same line
 * @param data
 * @param kpis
 * @constructor
 */
const NpiContainerWwDashboardProgress = ({data, kpis, configuration={}}:{data:IDataRowDetail, kpis:IKpi[], configuration:any})=> {
    const bars = _.reverse([...data.total_readiness] ?? []);
    const withPast = bars.length > 1;
    const points = bars.length > 1 ? data.total_readiness[0] - data.total_readiness[1] : 0;
    const colors = configuration?.colorBars??defaultColors;

    //Tooltip
    const tooltip = <ul style={{margin: 0}}>
        {data.total_readiness.map((r:number, k:number) => <React.Fragment key={k}>
            <li>Bar #{k+1}: <NpiDisplayPercentage value={r/100}/></li>
            <ul>
                <li>{data.grand_sum[k]} POS have validated the required KPIs</li>
                <li>{data.grand_total[k]} POS are applicable to these KPIs</li>
            </ul>
        </React.Fragment>)}
    </ul>;

    return <StyledDiv className={withPast ? 'with-past':''}>
        {/*important to have a fixed line height*/}
        <Tooltip title={tooltip} placement="right" overlayStyle={{minWidth: 400}}>
            <div className="progress-container">
                <div className="trail"></div>
                    {bars.map((readiness:number, k:number) => <div key={k+1} className="bar" style={{background: colors[k], width: readiness+'%', zIndex: (10-k)}}>
                </div>)}
                <div className="percent-text">
                    <NpiDisplayPercentage value={data.total_readiness?.[0]/100}/>
                </div>
            </div>
            {points>0 && <div className="points">{points>0 ? '+':'-'}{points}ppt</div>}
        </Tooltip>
    </StyledDiv>;
};

export default NpiContainerWwDashboardProgress;
export {defaultColors};