import React, {useCallback, useRef} from "react";
import {Card} from "antd";
import {Cell, Legend, Pie, PieChart, ResponsiveContainer} from "recharts";
import {useSize} from "ahooks";
import {IDashboardData} from "../../types/dashboard";
import _ from 'lodash';
import {
    collisionCircleSegment,
    numberFormat,
    percentageFormat
} from "../../helpers/toolbox";
import styled, {css} from "styled-components";

const StyledContainer = styled('div')<{ printMode?: boolean }>`
    height: 100%;
    width: 100%;

    svg.recharts-surface {
      overflow: visible;
    }
  
    ${props => props.printMode && css`
      .ant-card {
        border: 0;
      }
    `};
`;

const RADIAN = Math.PI / 180;

interface INpiDisplayKpiPieChart {
    chartKey: string
    data: IDashboardData
    displayUnits: boolean
    displayLabel: boolean
    alignLabel: boolean
    bottomLegend: boolean
    innerRadius: number
    outerRadius: number
    startAngle: number
    printMode?: boolean
}

interface ISlice {
    labels: string[]
    value: string
    color: string
    isZero: boolean
    mid: {cos: number, sin: number}
    start: {cos: number, sin: number}
    end: {cos: number, sin: number}
}
/**
 * Will display a pie chart in the dashboard
 * @param chartKey
 * @param data
 * @param displayUnits
 * @param displayLabel
 * @param alignLabel
 * @param bottomLegend
 * @param innerRadius
 * @param outerRadius
 * @param startAngle
 * @param printMode
 * @constructor
 */
const NpiDisplayKpiPieChart = ({chartKey, data, displayUnits=false, displayLabel=false, alignLabel=false, bottomLegend=true, innerRadius=30, outerRadius=80, startAngle=90, printMode=false}:INpiDisplayKpiPieChart) => {
    const chart = data.charts[chartKey];
    const total = _.sumBy(chart, 'value');

    const cardRef = useRef(null);
    const { width, height } = useSize(cardRef) as any ?? {};


    //Format label - fix overlapping values
    const refSlices = useRef({slices: [] as ISlice[]});
    const labelFormat = useCallback(({cx, cy, midAngle, startAngle, endAngle, outerRadius, value, index, fill, name }:any) => {

        const displayValue = displayUnits ? numberFormat(value) : percentageFormat(value/total);

        let slices = refSlices.current.slices;

        // initializing the list of all labels
        if( index === 0 ){
            slices = [];
        }

        // store each pie slice position, for each label (group "zeros" values together)
        const slice:ISlice = {
            labels: [name],
            value: displayValue,
            color: fill,
            isZero: displayUnits ? (value === 0) : (displayValue === '0%'),
            mid: {cos: Math.cos(-RADIAN * midAngle), sin: Math.sin(-RADIAN * midAngle)},
            start: {cos: Math.cos(-RADIAN * startAngle), sin: Math.sin(-RADIAN * startAngle)},
            end: {cos: Math.cos(-RADIAN * endAngle), sin: Math.sin(-RADIAN * endAngle)},
        }
        if( slice.isZero && slices.filter(s => s.isZero).length > 0 ){
            const found = _.find(slices, {isZero: true});
            if( found ){
                _.extend(found, {labels: [...found.labels, ...slice.labels]});
            }
        } else {
            slices.push(slice);
        }
        refSlices.current.slices = slices;

        // we only render on the last index to fix overlapping values
        if( index !== chart.length-1 ){
            return;
        }

        // manage overlapping (arrange y indexes)
        const lineHeight = displayLabel ? 15 : 8;
        const padding = 20;
        const topPie = cy - (outerRadius + padding);
        // const bottomPie = cy + (outerRadius + padding);

        // order slices from top to bottom
        slices = _.sortBy(slices, (s) => s.mid.sin, ['desc']);

        // we render all labels and lines here
        const last:any = {left: null, right: null};
        const totalHeight:any = {left: 0, right: 0};
        if( alignLabel ){
            slices.forEach((slice:ISlice) => {
                const sideXStr = (slice.mid.cos <= 0) ? "left" : "right";
                const labelCount = displayLabel ? slice.labels.length : 1;
                totalHeight[sideXStr] = totalHeight[sideXStr] + (lineHeight * labelCount);
            })
        }
        // start calculating each position & render
        return <>
            {slices.reverse().map((slice:ISlice, idx) => {
                let textX:number, textY:number, startLineY: number ;

                // label positions (left or right of pie)
                const sideX = slice.mid.cos <= 0 ? -1 : 1;
                const sideXStr = sideX === -1 ? "left" : "right";
                const anchor = sideX === -1 ? "end" : "start";
                const labelCount = displayLabel ? slice.labels.length : 1;
                const textHeight = (lineHeight * labelCount);

                if( !alignLabel ){ // -- simple render
                    textX = cx + ((outerRadius + 20) * slice.mid.cos);
                    textY = cy + ((outerRadius + 20) * slice.mid.sin);
                    startLineY = textY;

                } else { // -- align render

                    textX = cx + (sideX * (outerRadius + padding));
                    textY = cy + (outerRadius * slice.mid.sin);

                    // adjust position
                    const lastY = last[sideXStr];
                    if( (textY - totalHeight[sideXStr]) < topPie ){
                        textY = textY + totalHeight[sideXStr];
                    }
                    if( lastY && textY > lastY ){
                        textY = lastY;
                    }
                    const spaceY = (lineHeight+5);
                    last[sideXStr] = textY - spaceY - textHeight;
                    startLineY = textY - textHeight + lineHeight + ( displayLabel ? 5 : 0);
                }

                return <g key={idx}>
                    <path d={drawLine(cx, cy, outerRadius, slice.mid, slice.start, slice.end, textX, startLineY, !alignLabel)} stroke={slice.color} fill={"none"} />
                    {displayLabel && slice.labels.map((label:string, i:number) => <>
                        <text x={textX} y={textY-(lineHeight*i)} textAnchor={anchor} fill={slice.color} dominantBaseline="central">{label}</text>
                    </>)}
                    <text x={textX} y={textY+(displayLabel ? lineHeight : 0)} textAnchor={anchor} fill={slice.color} dominantBaseline="central">{slice.value}</text>
                </g>
            })}
        </>;
    }, [total, chart, displayUnits, displayLabel, alignLabel]);

    const drawLine = (cx: number, cy: number, radius: number, mid: any, start: any, end: any, ex: number, ey: number, simpleLine: boolean) => {

        const sideX = mid.cos <= 0 ? -1 : 1;

        const sx = cx + radius * mid.cos;
        const sy = cy + radius * mid.sin;

        // line end
        ex = ex - (sideX * 5);

        let parts:any = [];

        if(( sy < cy && ey > cy) || ( sy > cy && ey < cy)){ // cross center
            let p1 = {x: sx + (sideX * 10), y: sy};
            const p2 = {x: ex - (sideX * 5), y: ey};
            let delta = 1;
            while(collisionCircleSegment((radius+1), cx, cy, p1.x, p1.y, p2.x, p2.y)){
                p1 = {x: sx + (sideX * (10+delta)), y: sy};
                delta+=2;
            }
            parts.push(p1);
            parts.push(p2);
        } else {
            const sdx = cx + (radius+1) * mid.cos;
            const sdy = cy + (radius+1) * mid.sin;
            if( !simpleLine || collisionCircleSegment(radius, cx, cy, sdx, sdy, ex, ey) ) {
                let p1 = {x: sx + ((ex - sx) / 3), y: ey};
                let p2 = null;
                if( collisionCircleSegment(radius, cx, cy, sdx, sdy, p1.x, p1.y) ){
                    p1 = {x: sx + ((ex - sx) / 3), y: sy};
                    if( collisionCircleSegment(radius, cx, cy, sdx, sdy, p1.x, p1.y) ) {
                        p2 = {x: sx + ((ex - sx) / 3 * 2), y: ey};
                    }
                }
                parts.push(p1);
                if( p2 ){
                    parts.push(p2);
                }
            }
        }

        const partStr = parts.map((p:any) => `L${p.x},${p.y}`).join(',');
        return `M${sx},${sy}${partStr},L${ex},${ey}`;
    }

    return <StyledContainer ref={cardRef} printMode={printMode}>
        <Card>
            <ResponsiveContainer width={(width??0) - 48} height={(height??0) - 48}>
                <PieChart>
                    <Pie
                        startAngle={startAngle}
                        endAngle={startAngle-360}
                        dataKey="value"
                        cx="50%"
                        cy="50%"
                        data={chart}
                        innerRadius={innerRadius+"%"}
                        outerRadius={outerRadius+"%"}
                        fill="#8884d8"
                        label={labelFormat}
                        labelLine={false}
                        isAnimationActive={false}
                    >
                        {!!chart && chart.map((entry:any, index:number) => (
                            <Cell key={`cell-${index}`} fill={entry.color} />
                        ))}
                    </Pie>
                    {bottomLegend && <Legend/>}
                </PieChart>
            </ResponsiveContainer>
        </Card>
    </StyledContainer>
};

export default NpiDisplayKpiPieChart;