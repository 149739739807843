import React, {useState} from 'react';
import {Button, Dropdown, Menu, Modal, notification, Space} from "antd";
import {IQuestion, IQuestionAnswerDirectionEnum, IQuestionTypeEnum, IWithQuestions} from "../../types/question";
import styled from "styled-components";
import {PlusOutlined, RightOutlined, SearchOutlined} from "@ant-design/icons/lib";
import colors from "../../services/colors";
import NpiInputQuestionConfiguration from "../input/question-configuration";
import _ from "lodash";
import {observer} from "mobx-react";
import questionsConfigurations from "../../services/questions-configurations";
import {ColumnProps, TableProps} from "antd/lib/table";
import NpiDisplayQuestionTags from "../display/question-tags";
import NpiInputSearchQuestion, {INpiInputSearchQuestionType} from "../input/search-question";
import NpiSortTable, {DragHandle} from "../sort-table";
import {DeleteFilled} from "@ant-design/icons";
import npiApi from "../../services/api";
import FindReplace from '@2fd/ant-design-icons/lib/FindReplace';
import {IWave} from "../../types/wave";
import {useTranslation} from "react-i18next";
import ReorderHorizontal from "@2fd/ant-design-icons/lib/ReorderHorizontal";

const StyledNameCell = styled.div`

  .anticon {
    font-size: 18px;
    position: relative;
    top: 2px;
  }
  
  .anticon:not(.anticon-delete) {
    vertical-align: middle;
  }

  .drag-icon {
    margin-right: 10px;
    font-size: 15px;
    cursor: grab;

    &:hover {
      color: #b7b7b7;
    }
  }
`;


const StyledContainerDiv = styled.div`

  .ant-table-expanded-row > td {
    padding-top: 20px;
    box-shadow: rgba(50, 50, 93, 0.25) 0 10px 30px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  }

  .ant-table-row-expand-icon-cell .anticon {
    transition: 0.5s all;
    color: ${colors.greyDisabledText};
    font-size: 18px;
  }

  .expanded {
    transform: rotate(90deg);
  }
`;

const StyledNpiSortTable = styled(NpiSortTable)`
    .ant-table-row-expand-icon-cell, .ant-table-expand-icon-col{
        display: none;
    }
`;

const {LIST, MULTILIST, BOOL} = IQuestionTypeEnum;
const {POSITIVE, NEGATIVE} = IQuestionAnswerDirectionEnum;

interface INpiConfigFormQuestions {
    item: IWithQuestions
    defaultItem?: any
    saveQuestion: (question: IQuestion) => Promise<{question:IQuestion, parent:IWithQuestions}>,
    replaceQuestionsByWaveId?: (waveId: number, sourceWaveId: number) => Promise<any>,
}

const NpiConfigFormQuestions = observer(({
                                             item,
                                             defaultItem,
                                             saveQuestion,
                                             replaceQuestionsByWaveId = undefined
                                         }: INpiConfigFormQuestions) => {

    const {t} = useTranslation();
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);
    const [newQuestion, setNewQuestion] = useState<any>(false);
    const [loading, setLoading] = useState(false);
    const [searchModalVisible, setSearchModalVisible] = useState(false);
    const [replaceModalVisible, setReplaceModalVisible] = useState(false);

    const tableColumns: ColumnProps<IQuestion>[] = [
        {
            dataIndex: 'name', render: (_, row) => <StyledNameCell>
                <DragHandle children={<ReorderHorizontal className={'drag-icon'}/>}/>
                {questionsConfigurations[row.type as IQuestionTypeEnum].icon} {row.name}
                <DeleteFilled style={{marginLeft: 5, color: "red"}} onClick={(e) => onRemoveQuestion(row)}/>
            </StyledNameCell>
        },
        {
            render: (_, q) => <NpiDisplayQuestionTags question={q} conditions={item.conditions} kpis={item.kpis}/>,
            align: 'right' as 'right'
        },
    ];

    //Update a question when it changes
    const onChangeQuestion = (q: IQuestion) => {
        setLoading(true);
        saveQuestion(q).catch(null).then(({question, parent}) => {
            const found = _.find(item.questions, {id: question.id});
            if (found) {
                _.extend(found, question);
                item.questions = [...item.questions];
            }
            setExpandedRowKeys([]); //close expanded rows
            notification.success({message: <span>Question <b>{question.name}</b> saved</span>});

            // update question's translation to keep order
            item.translations = parent.translations;
        }).finally(() => setLoading(false));
    };

    //Create a question
    const onCreateQuestion = (q: IQuestion) => {
        setLoading(true);
        return saveQuestion(q).then(({question}) => {
            item.questions = [...item.questions, question];
            setNewQuestion(false);
        }).finally(() => setLoading(false));
    };


    /**
     * On replacing questions by wave
     * @param waveId
     */
    const onReplaceQuestions = (waveId: number): Promise<any> | void => {

        if (!replaceQuestionsByWaveId) {
            return;
        }

        setLoading(true);

        return replaceQuestionsByWaveId(item.id, waveId).then((wave: IWave) => {

            const {questions = [], conditions = [], translations = [], kpis = []} = wave;

            item = Object.assign(item, {
                questions, conditions, translations, kpis
            }) as IWave;

            setReplaceModalVisible(false);
        }).finally(() => setLoading(false));
    }

    //Delete a question
    const onDeleteQuestion = (q: IQuestion) => {
        const found = _.find(item.questions, {id: q.id});
        if (found) {
            item.questions.splice(item.questions.indexOf(found), 1);
            item.questions = [...item.questions];
            setExpandedRowKeys([]); //close expanded rows
        }
    };

    //Remove the question from the form (delete it)
    const onRemoveQuestion = (question: IQuestion) => {
        Modal.confirm({
            content: t('INTERNAL.CLIENT.QUESTION.ARE_YOU_SURE_TO_DELETE_QUESTION'),
            okText: t('INTERNAL.CLIENT.COMMON.YES'),
            okButtonProps: {danger: true},
            onOk: () => {
                const target = question.wave_id ? 'wave' : 'npi';
                npiApi.internal[target].removeQuestion(question)
                    .then(onDeleteQuestion)
                    .then(() => notification.success({message: t('INTERNAL.CLIENT.QUESTION.QUESTION_REMOVED')}));
            },
        })
    };

    //Open create modal
    const onClickCreate = ({key}: { key: any }) => {

        switch (key) {
            case 'search':
                setSearchModalVisible(true);
                break;
            case 'replace':
                setReplaceModalVisible(true);
                break;
            default:
                const maxSequence = _.maxBy(item.questions, 'sequence');
                const newQuestion = _.cloneDeep({
                    name: '',
                    display_name: '',
                    description: '',
                    type: key,
                    sequence: maxSequence ? maxSequence.sequence + 1 : 0,
                    config: {},
                    is_mandatory: false,
                    default_value: null,
                    ...defaultItem,
                });

                //Default answers for boolean question
                if (key === BOOL) {
                    newQuestion.config = {
                        answers: [
                            {label: t('INTERNAL.CLIENT.COMMON.YES'), value: 1, direction: POSITIVE},
                            {label: t('INTERNAL.CLIENT.COMMON.NO'), value: 0, direction: NEGATIVE},
                        ]
                    }
                }

                //Default for list questions
                if ([LIST, MULTILIST].indexOf(key) >= 0) {
                    newQuestion.config = {answers: []};
                }

                setNewQuestion(newQuestion);
                break;
        }
    };

    //When adding a new question found elsewhere
    const onAddFoundQuestion = (foundQuestion: IQuestion) => {
        const newQuestion = {
            ..._.cloneDeep(foundQuestion),
            ...defaultItem,
            id: null,
        };
        return onCreateQuestion(newQuestion);
    };

    //Reorder question with drag&drop
    const onMoveQuestion = (oldIndex: number, newIndex: number, q: IQuestion) => {
        setLoading(true);
        const oldOrder = [...item.questions];

        // we first reorder client side for animation fluidity
        item.questions.splice(oldIndex, 1);
        item.questions.splice(newIndex, 0, q);
        item.questions = [...item.questions];
        return npiApi.internal.question.move(q, newIndex)
            .then((orderList: IQuestion[]) => {
                // then we reorder from server side to be sure that it's synchronized
                item.questions = orderList;
            })
            .catch(() => {
                // if ordering fail we display back the old list
                item.questions = oldOrder;
            })
            .finally(() => setLoading(false));
    }

    //
    const tableExpandable = {
        expandedRowKeys,
        expandRowByClick: true,
        expandIcon: ({expanded, onExpand, record}: any) => <RightOutlined className={expanded ? 'expanded' : ''}
                                                                          onClick={e => onExpand(record, e)}/>,
        onExpandedRowsChange: (rows: any) => setExpandedRowKeys(rows.splice(-1)), //keep only one row expanded
        expandedRowRender: (record: IQuestion, index: number, indent: number, expanded: boolean) => {
            return <NpiInputQuestionConfiguration question={record} onChange={onChangeQuestion}
                                                  onCancel={() => setExpandedRowKeys([])} onDelete={onRemoveQuestion}
                                                  loading={loading}/>
        }
    };

    //Table configuration
    const tableConfig = {
        columns: tableColumns,
        expandable: tableExpandable,
        dataSource: item.questions,
        rowKey: 'id',
        showHeader: false,
        pagination: false as false,
    };

    const modalCreateConfig = {
        title: t('INTERNAL.CLIENT.QUESTION.NEW_QUESTION'),
        open: !!newQuestion,
        footer: null,
        onCancel: () => setNewQuestion(false),
        width: 800,
        destroyOnClose: true,
    };

    const modalSearchConfig = {
        title: <span><SearchOutlined/> {t('INTERNAL.CLIENT.QUESTION.SEARCH_FOR_EXISTING_QUESTION')}</span>,
        open: searchModalVisible,
        // footer: null,
        onCancel: () => {
            setNewQuestion(false);
            setSearchModalVisible(false);
        },
        okButtonProps: {style: {display: 'none'}},
        cancelText: t('INTERNAL.CLIENT.COMMON.CLOSE'),
        width: 800,
    };

    const modalReplaceConfigFromExistingWave = {
        title: <span><SearchOutlined/> {t('INTERNAL.CLIENT.QUESTION.COPY_QUESTIONS_FROM_EXISTING_WAVE')}</span>,
        open: replaceModalVisible,
        onCancel: () => {
            setNewQuestion(false);
            setReplaceModalVisible(false);
        },
        okButtonProps: {style: {display: 'none'}},
        cancelText: t('INTERNAL.CLIENT.COMMON.CLOSE'),
        width: 800,
    };

    const questionCreateMenu = <Menu onClick={onClickCreate} items={[
        ..._.map(questionsConfigurations, ({icon, name}, key) => ({key, icon, label: name})),
        {type: 'divider'},
        {key: 'search', icon: <SearchOutlined/>, label: t('INTERNAL.CLIENT.QUESTION.SEARCH_FOR_EXISTING_QUESTION')},
        {key: 'replace', icon: <FindReplace/>, label: t('INTERNAL.CLIENT.QUESTION.COPY_QUESTIONS_FROM_EXISTING_WAVE')},
    ] as any}/>;

    return <StyledContainerDiv>
        <Space className={"align-right"}>
            <Dropdown overlay={questionCreateMenu}>
                <Button type="primary" style={{marginBottom: 20}} icon={<PlusOutlined/>}>{t('INTERNAL.CLIENT.QUESTION.ADD_NEW_QUESTION')}</Button>
            </Dropdown>
        </Space>
        <Modal {...modalCreateConfig}>
            <NpiInputQuestionConfiguration question={newQuestion} onChange={onCreateQuestion}
                                           onCancel={() => setNewQuestion(false)} loading={loading}/>
        </Modal>
        <Modal {...modalSearchConfig}>
            <NpiInputSearchQuestion onAdd={onAddFoundQuestion}/>
        </Modal>


        {
            replaceQuestionsByWaveId &&
            <Modal {...modalReplaceConfigFromExistingWave}>
                <NpiInputSearchQuestion onReplace={onReplaceQuestions}
                                        searchType={INpiInputSearchQuestionType.REPLACE_QUESTIONS_WAVE}/>
            </Modal>
        }

        <StyledNpiSortTable onMoveItem={onMoveQuestion} {...tableConfig as TableProps<any>} />
    </StyledContainerDiv>
});

export default NpiConfigFormQuestions;