import { SelectProps } from "antd/lib/select";
import React, {useState} from "react";
import {useControllableValue, useRequest} from "ahooks";
import {Select} from "antd";
import npiApi from "../../services/api";
import {IShipmentCode} from "../../types/shipment";

interface ISelectShipmentCodeProps extends SelectProps<any> {
    regionId?: number|null; // restrict on a given region
    withArchived?: boolean; // fetch also archived shipment codes
}

/**
 * Select list of Shipment Code
 * @param restrict
 * @param regionId
 * @param withArchived
 * @param props
 * @constructor
 */
const NpiInputSelectShipmentCode = ({regionId=null, withArchived=false, ...props}:ISelectShipmentCodeProps) => {

    const [state, setState] = useControllableValue<null|number|number[]>(props, {
        defaultValue: null,
    });

    const {style} = props;
    const [options, setOptions] = useState<any[]>([]);

    const {loading} = useRequest<IShipmentCode[], any>(npiApi.internal.shipments.code.list, {
        defaultParams: [{region_id: regionId, with_archived: withArchived}],
        onSuccess: data => setOptions(data?.map(({ code, id }) => ({
            label: code,
            value: id,
        })))
    });

    const config = {
        value: state,
        onChange: setState,
        options,
        loading: loading,
        ...props,
        style: {minWidth: 150, ...style},
    }
    return <Select {...config}/>

}

export default NpiInputSelectShipmentCode;
