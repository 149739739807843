import React, {useContext} from "react";
import {NpiInternalContext} from "../../contexts/internal-context";
import _ from "lodash";
import NpiDisplayCountryFlag from "./country-flag";
import {Tooltip} from "antd";
import styled from "styled-components";

const StyledSpan = styled.span`
  white-space: nowrap;
`;

interface INpiDisplayCountryTypes {
    id:number,
    short?:boolean,
    flag?:boolean,
    style?:any
}

const properCase = (str:string) => str.replace(
    /\w\S*/g,
    function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
);

const NpiDisplayCountry = ({id, style, short=false, flag=true, ...rest} : INpiDisplayCountryTypes) => {
    const {countries} = useContext(NpiInternalContext);
    const country = _.find(countries, {id});

    // -- TODO TRADUCTION BACK
    if(country){
        if(short){
            return <StyledSpan className="country-flag" style={style}><Tooltip title={country.name}>
                {flag ? <NpiDisplayCountryFlag code={country.code}/> : <>{country.code}</>}
            </Tooltip></StyledSpan>
        }
        else{
            return <StyledSpan className="country-flag" style={style}>
                {flag && <NpiDisplayCountryFlag code={country.code}/>}
                &nbsp;{properCase(country.name)}
            </StyledSpan>
        }
    }
    else{
        return <StyledSpan className="country-flag" style={style}>???</StyledSpan>
    }
}

export default NpiDisplayCountry;