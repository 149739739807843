import React, {Dispatch, useEffect} from "react";
import {IDashboardBlockConfiguration} from "../../../types/dashboard";
import {Form, Input} from "antd";
import {useControllableValue} from "ahooks";
import {useForm} from "antd/es/form/Form";
import {useTranslation} from "react-i18next";
import _ from "lodash";


interface INpiInputKpiDashboardBlockConfigTitle {
    block:IDashboardBlockConfiguration
    onChange: Dispatch<IDashboardBlockConfiguration>
}

const NpiInputKpiDashboardBlockConfigTitle = ({ ...props}:INpiInputKpiDashboardBlockConfigTitle) => {
    const [block, setBlock] = useControllableValue(props, {valuePropName: "block"});
    const {t} = useTranslation();
    const [form] = useForm();

    const onValuesChange = (values:any) => {
        setBlock({..._.merge(block, values)});
    };

    //On load
    useEffect(() => {
        form.setFieldsValue(block);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return <div>
        <Form form={form} onValuesChange={onValuesChange} layout="vertical">
            <Form.Item label={t('INTERNAL.CLIENT.COMMON.HEADER')} name={["config", "value"]}>
                <Input/>
            </Form.Item>
            <Form.Item label={t('INTERNAL.CLIENT.KPI.DASHBOARD.TITLE_SIZE')} name={["config", "fontSize"]}>
                <Input type={"number"} min={0}/>
            </Form.Item>
        </Form>
    </div>
};

export default NpiInputKpiDashboardBlockConfigTitle;