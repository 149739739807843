import React from 'react';
import {useTranslation} from "react-i18next";
import {Spin} from "antd";
import {useNpiExternalContext} from "../../../contexts/external-context";

/**
 * 404 Not Found external app view
 * @constructor
 */
const NpiExternalErrorNotFound = () => {

    const {isLoading, initDefault} = useNpiExternalContext();
    const {t} = useTranslation();

    initDefault();

    return(
        <div style={{textAlign: 'center', marginTop: '20vh'}}>
            {isLoading
                ? <Spin/>
                : <>
                    <h1>404</h1>
                    <h2>{t('EXTERNAL.CLIENT.NOT_FOUND.LOST')}</h2>
                    <p>{t('EXTERNAL.CLIENT.NOT_FOUND.PAGE_NOT_AVAILABLE')}</p>
                </>
            }
        </div>
    );
}


export default NpiExternalErrorNotFound;