import React, {Dispatch, useMemo, useState} from 'react';
import {Button, Card, Dropdown, Form, Menu, Modal, notification, Select, Switch, Tabs, Tag} from "antd";
import {useControllableValue, useRequest} from "ahooks";
import styled from "styled-components";
import {DashboardFilled, FileExcelOutlined, FilterFilled, MenuOutlined, PrinterFilled} from "@ant-design/icons/lib";
import NpiInputSelectCountry from "../input/select-country";
import {useForm} from "antd/lib/form/Form";
import _ from "lodash";
import NpiDisplayListEllipsis from "../display/list-ellipsis";
import NpiDisplayCountry from "../display/country";
import npiApi, {npiApiDownloadFileCallback} from "../../services/api";
import NpiConfigKpis from "../config/kpis";
import {IWave} from "../../types/wave";
import {observable, observe} from "mobx";
import {useTranslation} from "react-i18next";

const StyledContainer = styled.div`
    position: relative;
    margin-top: -43px;
    margin-right: 20px;
    margin-bottom: 8px;
    pointer-events: none;
  
    .header {
        pointer-events: auto;
        margin-left: auto;
        max-width: calc(100% - 450px);
        display: flex;
        flex-wrap: wrap;
        justify-content: end;
        align-items: center;
        gap: 10px;
      
        .ant-select {
            width: calc(100vw - 900px);
            min-width: 250px;
            .ant-select-selector {
              overflow-x: hidden;
              .ant-select-selection-overflow {
                flex-wrap: nowrap;
                justify-content: flex-start;
                text-align: left;
              }
            }
        }
    }
    &.loading{
        opacity: 0.25;
    }
    
    @media print{
        display: none;
    }
`;

const StyledTag = styled(Tag)`
    height: 30px;
    font-size: 14px;
    padding-top: 4px;
    margin: 0;
    .country-flag{
        margin-left: 2.5px;
        margin-right: 2.5px;
    } 
`;

interface INpiKpiDashboardHeader {
    onChangeFilters: Dispatch<any>
    onPrint: Dispatch<any>
    onPreview: Dispatch<boolean>
    extra?: any[]
    extraMenus?: any[]
    loading:boolean
    filters: any
    lists: {
        waves: any[]
        countries: any[]
    }
}

const NpiKpiDashboardHeader = ({loading, extra, extraMenus, onPrint, onPreview, ...props}:INpiKpiDashboardHeader) => {
    const {t} = useTranslation();
    const [modalFilterVisible, setModalFilterVisible] = useState(false);
    const [modalKpisVisible, setModalKpisVisible] = useState(false);
    const [selectedWaveIsDirty, setSelectedWaveIsDirty] = useState(false);
    const [selectedWave, setSelectedWave] = useState<IWave>();
    const [form] = useForm();
    const {runAsync: fetchWave, loading: loadingWave} = useRequest(npiApi.internal.wave.detail, {manual: true, onSuccess: (data:any) => {
        const observableWave = observable(data.wave);
        observe(observableWave, () => setSelectedWaveIsDirty(true));
        setSelectedWave(observableWave);
    }});
    const {runAsync: fetchExport, loading: loadingExport} = useRequest(npiApi.internal.file.exportKpiDashboard, {manual: true});

    //Manage the list of possible items
    const [lists] = useControllableValue(props, {
        valuePropName: 'lists',
        defaultValue: {
            waves: [] as any[],
            countries: [],
        }
    });

    //Manage the filters
    const [filters, setFilters] = useControllableValue(props, {
        valuePropName: 'filters',
        trigger: 'onChangeFilters',
        defaultValue: {
            wavesIds: [] as number[],
            countries: [],
            reschedule: null as any,
        },
    });

    //When changing the filters locally, perform validation & trigger controllable value
    const onChangeFilters = (values:any) => {
        const newFilters = {...filters, ...values};
        if( ! newFilters.wavesIds?.length) {
            notification.error({message: t('INTERNAL.CLIENT.KPI.DASHBOARD.MUST_SELECT_AT_LEAST_ONE_WAVE')});
            return;
        }
        setFilters(newFilters);
    };

    //On opening the modal, clone filters in the form
    const onOpenFilters = () => {
        setModalFilterVisible(true);
        form.setFieldsValue(filters);
    };

    //Upon closing the modal, apply the filters & trigger controllable value
    const onCloseFilters = () => {
        setFilters({...filters, ...form.getFieldsValue()});
        setModalFilterVisible(false);
    };

    //Open the modal to edit KPIs
    const onOpenKpiEdit = () => {
        setSelectedWaveIsDirty(false);
        if( ! selectedWave){
            fetchWave(filters.wavesIds[0]).then(() => setModalKpisVisible(true));
        }
        else{
            setModalKpisVisible(true);
        }
    };

    //Close the modal to edit KPIs
    const onCloseKpiEdit = () => {
        if(selectedWaveIsDirty){
            setFilters({...filters}); //force refresh dashboard if there was any change
        }
        setModalKpisVisible(false);
    };

    const onExport = () => {
        fetchExport(filters).then(npiApiDownloadFileCallback(`kpi-dashboard.xlsx`));
    };

    //Available waves
    const waves = useMemo(() => lists.waves.filter(wave => filters.wavesIds?.indexOf(wave.value)>=0), [lists.waves, filters.wavesIds]);

    return <StyledContainer>

        {/* Menu */}
        <div className="header">

            <div className={'header-quickpreview'}>
                {t('INTERNAL.CLIENT.KPI.DASHBOARD.QUICK_PREVIEW')} <Switch onChange={onPreview} disabled={loading}/>
            </div>

            {/* Advanced filters display */}
            { ! _.isUndefined(filters.reschedule) && <StyledTag>Reschedule: {filters.reschedule? t('INTERNAL.CLIENT.COMMON.YES'):t('INTERNAL.CLIENT.COMMON.NO')}</StyledTag>}
            { filters.countries?.length>0 && <StyledTag>
                <NpiDisplayListEllipsis>{filters.countries.map((c:any) => <NpiDisplayCountry key={c} id={c} short/>)}</NpiDisplayListEllipsis>
            </StyledTag>}
            
            <Select disabled={loading} mode="multiple" value={filters.wavesIds} options={lists.waves} dropdownMatchSelectWidth={false} onChange={wavesIds => onChangeFilters({wavesIds})} maxTagCount={3}/>

            <Dropdown disabled={loading} overlay={<Menu items={[
                {key: 'kpis', label: <><DashboardFilled/> {t('INTERNAL.CLIENT.KPI.EDIT_KPIS')}</>, onClick:onOpenKpiEdit},
                {key: 'filters', label: <><FilterFilled/> {t('INTERNAL.CLIENT.KPI.DASHBOARD.ADVANCED_FILTERS')}</>, onClick:onOpenFilters},
                {key: 'print', label: <><PrinterFilled/> {t('INTERNAL.CLIENT.COMMON.PRINT')}</>, onClick:onPrint},
                {key: 'export', label: <><FileExcelOutlined/> {t('COMMON.EXPORT')}</>, onClick:onExport},
                ...(extraMenus ?? [])
            ]}/>}>
                <Button icon={<MenuOutlined/>} loading={loadingExport}>{t('INTERNAL.CLIENT.COMMON.MORE')}</Button>
            </Dropdown>

            {extra}
        </div>

        {/* Modal to edit advanced filters */}
        <Modal open={modalFilterVisible} onCancel={() => setModalFilterVisible(false)} title={<><FilterFilled/> {t('INTERNAL.CLIENT.KPI.DASHBOARD.ADVANCED_FILTERS')}</>} cancelButtonProps={{style: {display: 'none'}}} closable={false} maskClosable={false} onOk={onCloseFilters}>
            <Form form={form} labelCol={{span: 5}}>
                <Form.Item label={t('INTERNAL.CLIENT.COMMON.COUNTRIES')} name="countries">
                    <NpiInputSelectCountry mode="multiple" allowClear style={{width: '100%'}} inIdsList={lists.countries} maxTagCount={10}/>
                </Form.Item>
                <Form.Item label={t('INTERNAL.CLIENT.COMMON.RESCHEDULE')} name="reschedule">
                    <Select options={[{value: 1, label: t('INTERNAL.CLIENT.COMMON.YES')}, {value: 0, label: t('INTERNAL.CLIENT.COMMON.NO')}]}
                            allowClear
                            placeholder={`${t('INTERNAL.CLIENT.COMMON.RESCHEDULE')}...`}
                    />
                </Form.Item>
            </Form>
        </Modal>

        {/* Modal to configure KPIs */}
        <Modal open={modalKpisVisible} width="calc(100% - 100px)" onCancel={() => setModalKpisVisible(false)} closable={false} onOk={onCloseKpiEdit} cancelButtonProps={{style: {display: 'none'}}} maskClosable={false} >
            <Tabs type="card" tabPosition="left" onChange={key => fetchWave(Number(key))}>
                {waves.map(wave => <Tabs.TabPane tab={wave.label} key={wave.value}>
                        {!!selectedWave && <Card title={selectedWave.name} loading={loadingWave || loadingExport} bodyStyle={{padding: 0}}>
                            <NpiConfigKpis item={selectedWave} saveKpi={npiApi.internal.kpi.waveSave} defaultItem={{npi_id: selectedWave.npi_id, region_id: selectedWave.region_id}}/>
                        </Card>}
                </Tabs.TabPane>)}
            </Tabs>
        </Modal>
    </StyledContainer>
};

export default NpiKpiDashboardHeader;