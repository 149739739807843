import React from "react";
import {useControllableValue} from "ahooks";
import styled from "styled-components";
import {TwitterPicker} from "react-color";

const StyledTwitterPicker = styled(TwitterPicker)`
    box-shadow: none !important;
    &>div{
        padding: 0 !important;
    }
`;


/**
 * Color picker
 *
 * @param {boolean} restrict
 */
const NpiInputColorPicker = ({...props}) => {
    const [state, setState] = useControllableValue<string>(props);

    const onChange = (color:any) => {
        setState(color.hex);
    };

    return <StyledTwitterPicker triangle={"hide"} onChange={onChange} color={state}/>
}

export default NpiInputColorPicker;