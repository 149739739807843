import React, {FunctionComponent, useCallback, useEffect, useState} from 'react';
import moment from "moment-timezone";
import {useControllableValue} from "ahooks";
import {Slider} from "antd";
import styled from "styled-components";
import NpiDisplayDate from "../display/date";
import {makeDateFromString} from "../../helpers/date";

const StyledSlider = styled(Slider)`
    margin-bottom: 60px;

    .ant-slider-mark-text{
        white-space: pre;
        &:first-child{
            transform: translateX(-10%) !important;
        }
        &:last-child{
            transform: translateX(-90%) !important;
        }
    }
`;

interface IDateIntervalProps {
    dateMin: any;
    dateMax: any;
    interval: number;
    sliderPros?: object;
    onChangeDate: (value: any) => void;
    selectedDate: any
    disabled?: boolean
}

type Props = IDateIntervalProps;

// --return a date timestamp
const getTimestamp = (date: any): number => {
    if (date instanceof Date) {
        return date.getTime() / 1000;
    }
    return Date.parse(date) / 1000;
};

//Display dates (add UTC offset time to have local time display)
const tooltipFormater = (value: any): string|JSX.Element => {
    const offset = moment().utcOffset();
    return <NpiDisplayDate value={moment.unix(value).add(offset, "minutes")} hours={'block'}/>;
};

const NpiInputDateInterval: FunctionComponent<Props> = ({dateMin, dateMax, interval, ...props}) => {

    const [min, setMin] = useState<number>();
    const [max, setMax] = useState<number>();
    const [marks, setMarks] = useState<any[]>([]);
    const [timestamp, setTimestamp] = useState<any>();
    const ready = min && max;

    //Controllable value
    const [state, setState] = useControllableValue<any>(props, {
        defaultValue: null,
        valuePropName: 'selectedDate',
        trigger: 'onChangeDate'
    });

    //Compute the marks to display
    const getMarks = useCallback(() => {

        const min = makeDateFromString(dateMin);
        const max = makeDateFromString(dateMax);

        // -- UNIX Timestamp
        const minTs = Number(getTimestamp(min));
        const maxTs = Number(getTimestamp(max));
        const step = (maxTs - minTs) / 10;

        const addInterval = (date: Date): any => {
            const newDate = moment(date);
            newDate.add(step, 's');
            return newDate;
        };

        const getDateInInterval = (): Date[] => {
            const datesInBetween = [];
            let currentDate = min;

            while (currentDate < max) {
                datesInBetween.push(currentDate);
                currentDate = addInterval(currentDate);
            }
            return datesInBetween;
        };

        const dates = getDateInInterval();
        const offset = Math.round(dates.length / 6);
        const results: any = {};

        for (let i = 0; i < dates.length; i += offset) {
            const ts = getTimestamp(dates[i]);
            results[ts] = tooltipFormater(ts);
        }
        results[maxTs] = {
            style: {
                color: '#f50',
            },
            label: tooltipFormater(maxTs),
        };
        return results;
    }, [dateMin, dateMax]);

    //When slider finishes moving, apply changes
    const onChangeSlider = (value:any) => {
        //We first compute the moment unix value and THEN get the unix value from it to shave off milliseconds
        const newDate = moment.unix(value);
        setTimestamp(newDate.unix());
        setState(newDate);
    };

    //Update the slider when dragging
    const onDragSlider = (value: any) => {
        setTimestamp(value);
    };

    //Observe configuration properties to change the display of the slider when they change. Also set time to max when it happens
    useEffect(() => {
        // -- JS DATE
        const min = makeDateFromString(dateMin);
        const max = makeDateFromString(dateMax);

        // -- UNIX Timestamp
        const minTs = Number(getTimestamp(min));
        const maxTs = Number(getTimestamp(max));

        // -- set state
        setMin(minTs);
        setMax(maxTs);
        setTimestamp(maxTs);

        const marksToSet = getMarks();
        setMarks(marksToSet);
    }, [dateMin, dateMax, interval, getMarks]);

    //Observe external changes to state to apply them to our local input
    useEffect(() => {
        const isIdentical = moment(state).isSame(moment.unix(timestamp));
        if( ! isIdentical && state){
            setTimestamp(state.unix());
        }
        //We do not want to trigger on timestamp changes, only on state changes
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);

    if(ready){
        return <StyledSlider
            value={timestamp}
            handleStyle={{borderRadius: '2px', background: '#0073ea'}}
            tipFormatter={tooltipFormater}
            onAfterChange={onChangeSlider}
            onChange={onDragSlider}
            min={min}
            max={max}
            disabled={!marks}
            marks={marks as any}
            {...props}
        />
    }
    else{
        return <Slider handleStyle={{borderRadius: '2px', background: '#0073ea'}} disabled/>
    }
};

export default NpiInputDateInterval;
