import React, {useContext, useEffect, useState} from 'react';
import api, {npiApiDownloadFileCallback} from "../services/api";
import styled from "styled-components";
import {Button, Empty, Space, Spin, Tooltip} from "antd";
import {NpiInternalContext} from "../contexts/internal-context";
import {useHistory, useParams} from "react-router";
import {useNpiStore, useWaveStore, useWavePosStore} from "../contexts/stores";
import {IWavePosImage} from "../types/wave-pos-image";
import NpiContainerGalleryMasonryContainer from "../components/gallery/masonry-container";
import NpiContainerGalleryCarouselContainer from "../components/gallery/carousel-container";
import NpiDisplayLayoutContent from '../components/display/layout-content';
import NpiImageFilters, {
    areEmptyFilters,
    defaultImageFilters,
    IImageFilter,
} from "../components/gallery/image-filters";
import npiApi from "../services/api";
import {DownloadOutlined} from "@ant-design/icons";
import {IUserRoleEnum} from "../types/user";
import {useRequest} from "ahooks";
import {useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";


const StyledContainer = styled.div`
  .loader, .alert-msg{
    text-align: center;
    padding: 50px;
  }
`;

const StyledSpaceActionsBar = styled(Space)`
  position: absolute !important;
  top: 15px;
  right: 10px;
`;

const StyledCount = styled.div`
  position: absolute;
  top: 65px;
  right: 20px;
  font-size: 0.8rem;
  font-style: italic;
`;


// Number of images fetched in 1 call
const SCROLL_COUNT = 100;

// Use by infinite scroller && masonry gallery to detect a reset of the items list (when use filters)
function getRandomHash() {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
}

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const NpiContainerGallery = () => {
    // retrieve URL parameters
    const history = useHistory();
    const params:any = useParams();
    const searchQuery = useQuery();
    const {user, setBreadcrumbs} = useContext(NpiInternalContext);
    const {selectShort:selectNpi} = useNpiStore();
    const {select:selectWave} = useWaveStore();
    const {selectFromWaveAndPos:selectWavePos} = useWavePosStore();
    const [fetching, setFetching] = useState<boolean>(false);
    const {t} = useTranslation();

    // list of WavePosImages
    const [listImages, setListImages] = useState<IWavePosImage[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [pageLoaded, setPageLoaded] = useState<boolean>(false);
    const [tokenWaveId, setTokenWaveId] = useState<string>('');
    const [totalImgs, setTotalImgs] = useState<number>(0);

    //switch between masonry or carousel
    const [isCarousel, setIsCarousel] = useState<boolean>(false);
    // active index in listImages
    const [currentImage, setCurrentImage] = useState<number>(0);

    // filters
    const [filters, setFilters] = useState<IImageFilter>(defaultImageFilters);
    const [resetKey, setResetKey] = useState<string>(getRandomHash());



    /**
     * Fetch list of images from DB
     * TODO ABE
     *  - check that cancel work ({, cancel: cancelSearch})
     *  - 1 token for multiple waves ? (could allow to download zip with many waves)
     */
    const {runAsync: launchSearch, loading} = useRequest(api.internal.gallery.listImages, {
        manual: true,
        debounceWait: 50,
        onSuccess: ({list, total, current_page, token}: { list: IWavePosImage[], total: number, current_page: number, token: string }) => {
            setTotalImgs(total);
            setHasMore((list.length + listImages.length) < total );
            setTokenWaveId(token);
            setCurrentPage(current_page);
            if( list.length > 0 ) {
                setListImages([...listImages, ...list]);
            }
        },
    });


    /**
     * Load gallery based on filters
     */
    const onSubmitFilters = (newFilters:IImageFilter) => {
        setListImages([]);
        setResetKey( getRandomHash() );
        setFilters({...newFilters} );
        fetchInfos(
            false,
            true,
            newFilters.npis.length === 1 ? newFilters.npis[0] : null,
            newFilters.waves.length === 1 ? newFilters.waves[0] : null,
            newFilters.pos.length === 1 ? newFilters.pos[0] : null,
            null
        ).then(null)
    }

    /**
     * Reset current list of image when
     *  - filters are updated
     *  - or when page is loaded (when url parameters are parsed)
     */
    useEffect(() => {
        if( pageLoaded ) {
            // reload base on filters
            if( !areEmptyFilters(filters) ){
                launchSearch({current: 1, limit: SCROLL_COUNT, search_filters: filters}).then(null);
            }
        } else if( !fetching ) {
            // Load gallery based on url parameters
            const qId = searchQuery.get('q')
            setFetching(true);
            fetchInfos(
                true,
                false,
                params.npiId || null,
                params.waveId || null,
                params.posId || null,
                qId ? parseInt(qId) : null
            )
                .finally(() => {
                    setFetching(false)
                    setPageLoaded(true)
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters, pageLoaded]);


    /**
     * Get NPI, Wave or POS information & update BreadCrumbs, history or filter
     */
    const fetchInfos = async (updateFilters:boolean, pushHistory: boolean, npiId: number|null, waveId: number|null, posId: number|null, questionId: number|null) => {

        // Npi OR Wave infos  (default is Last Npi with images)
        let callback: (params: any, params2 ?: any) => Promise<any> = npiApi.internal.gallery.lastNpiWithImage;

        let noChange = true;
        let id = null;
        let id2 = null;
        if( waveId !== null ){
            callback = selectWave;
            id = waveId;
            if( waveId !== filters.waves?.[0] ) {
                noChange = false;
            }
            if( posId !== null ){
                callback = selectWavePos;
                id2 = posId;
                if( posId !== filters.pos?.[0] ) {
                    noChange = false;
                }
            }

        } else if( npiId !== null ){
            callback = selectNpi;
            id = npiId;
            if( npiId !== filters.npis?.[0] ) {
                noChange = false;
            }
        } else {
            noChange = false;
        }

        // Load wave or npi information
        return noChange ? null : callback(id, id2).then((ret:any) => {
            let crumbs = [{url: '/gallery', name: t('INTERNAL.CLIENT.COMMON.GALLERY')}];
            let tempFilters = filters;
            let historyStr = '/gallery';

            if( ret !== undefined && ret.npi ) {
                crumbs = [...crumbs, {url: `/npi/${ret.npi.id}`, name: ret.npi.name}];
                tempFilters = {...tempFilters, npis: [ret.npi.id]};
                historyStr = `/gallery/npi/${ret.npi.id}`;
            }
            if( ret !== undefined && ret.wave ) {
                crumbs = [...crumbs, {url: `/wave/${ret.wave.id}/report`, name: `${ret.wave.id} - ${ret.wave.name}`}];
                tempFilters = {...tempFilters, waves: [ret.wave.id]};
                historyStr = `/gallery/wave/${ret.wave.id}`;

                if(ret.wavepos) {
                    const wpname = ret.wavepos.f_trade_name || ret.wavepos.f_legal_name;
                    crumbs = [...crumbs, {url: '', name: `${ret.wavepos.pos_id} - ${wpname}`}];
                    tempFilters = {...tempFilters, pos: [ret.wavepos.pos_id]};
                    historyStr = `/gallery/wave/${ret.wave.id}/${ret.wavepos.pos_id}`;
                }

                if( questionId !== null ){
                    tempFilters = {...tempFilters, questions: [questionId]};
                }
            }

            setBreadcrumbs(crumbs);
            if( updateFilters ) {
                setFilters(tempFilters);
            }
            if(pushHistory) {
                history.push(historyStr);
            }
        })
    }


    /**
     * Switch Between Carousel & Masonry
     */
    const onClickCard = (wpiId:number) => {
        setCurrentImage(listImages.findIndex(i => i.id === wpiId));
        setIsCarousel(true);
    }
    const closeCarousel = (index?:number) => {
        if( index ){
            setCurrentImage(index);
        }
        setIsCarousel(false)
    }


    /**
     * Download zip of current set of filters + filter on user countries (if role = COUNTRY_USER)
     */
    const {runAsync: launchDownloadZip, loading: isDownloading} = useRequest(api.internal.gallery.downloadZip, {
        manual: true,
        onSuccess: npiApiDownloadFileCallback('npi-images.zip'),
    });
    const onDownloadZip = () => {
        // add filter on countries
        const parseFilters = ( user && user.role === IUserRoleEnum.COUNTRY_USER) ? {...filters, countries: user.countries} : filters;
        launchDownloadZip(tokenWaveId, {search_filters: parseFilters}).finally(null)
    }


    return <NpiDisplayLayoutContent>

        <StyledSpaceActionsBar>
            <Tooltip title={totalImgs > 200 ? t('INTERNAL.CLIENT.GALLERY.TOO_MANY_IMAGES') : null} placement={'left'}>
                <Button icon={<DownloadOutlined/>} disabled={!tokenWaveId || isDownloading} onClick={onDownloadZip} loading={isDownloading}>{t('INTERNAL.CLIENT.GALLERY.DOWNLOAD_ZIP')}</Button>
            </Tooltip>

            <NpiImageFilters value={filters} onSubmit={onSubmitFilters}/>
        </StyledSpaceActionsBar>

        <StyledCount>{totalImgs} images</StyledCount>

        <StyledContainer key={resetKey}>
            {filters.npis.length === 0

                ? ((!pageLoaded || loading )
                    ? <div className="loader"><Spin/></div>
                    : <Empty description={t('INTERNAL.CLIENT.COMMON.SELECT_ONE_NPI')} image={Empty.PRESENTED_IMAGE_SIMPLE}/>)

                : (!(!pageLoaded || loading ) && listImages.length === 0
                    ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
                    : isCarousel
                        ? <NpiContainerGalleryCarouselContainer
                            startIndex={currentImage}
                            listImages={listImages}
                            fetchMore={() => launchSearch({current: currentPage+1, limit: SCROLL_COUNT, search_filters: filters})}
                            hasMore={hasMore}
                            onClose={closeCarousel}
                            />

                        : <NpiContainerGalleryMasonryContainer
                                startIndex={currentImage}
                                listImages={listImages}
                                fetchMore={() => launchSearch({current: currentPage+1, limit: SCROLL_COUNT, search_filters: filters}).then(null)}
                                loading={!pageLoaded || loading}
                                hasMore={hasMore}
                                totalImgs={totalImgs}
                                onClickCard={onClickCard}
                                />
                )
            }
        </StyledContainer>
    </NpiDisplayLayoutContent>;
}

export default NpiContainerGallery;