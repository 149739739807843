import React, {useContext, useEffect, useRef, useState} from 'react';
import {Link} from "react-router-dom";
import {Col, Input, List, PageHeader, Row, Spin} from "antd";
import {useDebounceEffect, useDebounceFn, useScroll} from "ahooks";
import npiApi from "../../services/api";
import {useTranslation} from "react-i18next";
import {LoadingOutlined} from "@ant-design/icons/lib";
import {NpiDisplayWaveNameTranslation} from "../../components/display/translations";
import NpiLanguageContext from "../../contexts/language-context";
import NpiExternalDisplayPosCard from "../../components/external/pos-card";
import {useNpiExternalContext} from "../../contexts/external-context";
import useExternalToken from "../../hooks/use-external-token";
import {ExternalTokenRight} from "../../types/external-token";
import {IExternalPos} from "../../types/pos";


const NpiExternalContainerPosList = () => {
    const {t} = useTranslation();

    const {currentLang} = useContext(NpiLanguageContext);
    const {paginatedPosList, setPaginatedPosList, waves, screenSize} = useNpiExternalContext();
    const {getPosLink, hasRight} = useExternalToken();
    const {list = []} = paginatedPosList;

    const [searchedText, setSearchedText] = useState<string>(paginatedPosList.searchedText);
    const [isFetching, setIsFetching] = useState<boolean>(false);

    // scroll/infinite loading
    const scrollingZoneRef = useRef<HTMLDivElement>(null);
    const scroll = useScroll(scrollingZoneRef);
    //take height screen
    const responsiveListHeight = screenSize.height - (parseInt(getComputedStyle(document.documentElement).getPropertyValue('--pageViewHeaderHeight')) || 100);


    // -- Debounced getPosList api call, due to multiple effect behavior on the component
    const {run: fetchPosList} = useDebounceFn((page: number) => {
        setIsFetching(true);
        npiApi.external.getPosList(page)
            .then((data: any) => {
                const {left = 0, nextAvailablePage = null, list = []} = data;

                setPaginatedPosList({
                    ...paginatedPosList,
                    left,
                    nextAvailablePage,
                    list: (page > 1) ? [...paginatedPosList.list, ...list] : list,
                    searchedText: ''
                });
            })
            .finally(() => setIsFetching(false))

    }, {wait: 500});


    // -- Search POS by a given text
    const {run: searchPosByTerm} = useDebounceFn((search: string, page?: number) => {

        // if search is empty, we're just getting the pos list
        if(!search) {
            fetchPosList(1);
            return;
        }

        setIsFetching(true);
        npiApi.external.getSearchPosByTerm(search, page)
            .then((data: any) => {
                const {left = 0, nextAvailablePage = null, list = []} = data;

                setPaginatedPosList({
                    ...paginatedPosList,
                    left,
                    nextAvailablePage,
                    list: (page && page > 0) ? [...paginatedPosList.list, ...list] : list,
                    searchedText: search
                });
            })
            .finally(() => setIsFetching(false))

    }, {wait: 500});


    // -- Fetching the pos list at component mount
    useEffect(() => {
        if (!!paginatedPosList.list.length) {
            return;
        }
        fetchPosList(1);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [null]);


    // -- Handle when user scrolls and load more POS
    useDebounceEffect(() => {
        const maxScrollTop = (scrollingZoneRef.current?.scrollHeight || 0) - responsiveListHeight;
        const scrollPercent = Math.round((scroll?.top??0) / maxScrollTop * 100);

        //avoid fetching if already fully fetched
        if (!paginatedPosList.nextAvailablePage || isNaN(scrollPercent) || scrollPercent < 80) {
            return;
        }

        if (searchedText) {
            searchPosByTerm(searchedText, paginatedPosList.nextAvailablePage);
        } else {
            fetchPosList(paginatedPosList.nextAvailablePage);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scroll], {wait: 250})


    // -- Callback when user change searchedText value
    const onChangeSearchedText = (text: string) => {
        if (text !== searchedText) {
            setSearchedText(text);
            searchPosByTerm(text);
        }
    }


    return <>
        <PageHeader>
            { (waves.length === 1 && !hasRight(ExternalTokenRight.ACCESS_UNIFIED_VIEW)) &&
                <h1 style={{fontSize: 14, textAlign: 'center', margin: 0}}>
                    <NpiDisplayWaveNameTranslation wave={waves[0]} langId={currentLang?.id} />
                </h1>
            }

            <Row style={{marginTop: 10}} align={"middle"} justify={"space-between"}>
                <Col xs={18}>
                    <Input
                        value={searchedText}
                        onChange={({target}) => onChangeSearchedText(target.value)}
                        placeholder={t('EXTERNAL.CLIENT.POS_LIST.SEARCH_POS')}
                    />
                </Col>
                <Col xs={5}>
                    <span className={"link"} onClick={() => onChangeSearchedText("")}>{t('COMMON.CANCEL')}</span>
                </Col>
            </Row>
        </PageHeader>

        <Spin tip={t('EXTERNAL.CLIENT.POS_LIST.FETCHING_POS_LIST')} spinning={isFetching}>
            <div ref={scrollingZoneRef}
                 style={{height: responsiveListHeight, overflowX: 'auto', padding: 3, paddingTop: 10}}>
                <List
                    itemLayout="horizontal"
                    locale={t('EXTERNAL.CLIENT.POS_LIST.NO_POS_AVAILABLE')}
                    dataSource={list}
                    renderItem={pos => (
                        <Link to={getPosLink(pos.pos_id)}>
                            <NpiExternalDisplayPosCard pos={pos} showStatus={true} showPlanogram={false}/>
                        </Link>
                    )}
                />


                {/* Display spinning icon */}
                { !!paginatedPosList.nextAvailablePage &&  <div style={{textAlign: 'center'}}><LoadingOutlined/></div> }
            </div>
        </Spin>
    </>
}

export default NpiExternalContainerPosList;