import React, {useContext, useState} from 'react';
import {ITag, ITagNpi} from "../../types/tag";
import NpiDisplayRegion from "../display/region";
import {REGION_WW} from "../../helpers/constants";
import NpiDisplayBoolean from "../display/boolean";
import NpiDisplayTagRules from "../display/tag-rules";
import {Button, Form, Modal, Space, Switch, Table, Tag} from "antd";
import {DeleteFilled, EditFilled} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import {NpiInternalContext} from "../../contexts/internal-context";
import NpiInputSelectTagRules from "../input/select-tag-rules";
import {useRequest} from "ahooks";
import npiApi from "../../services/api";
import {SortOrder} from "antd/lib/table/interface";
import NpiDisplayDate from "../display/date";
import moment from "moment";
import NpiInputSelectNpi from "../input/select-npi";

interface IAdminTagList {
    tags: ITag[],
    loadingTags: boolean,
    onRemoveTag: (t:ITag) => void
    onUpdateTag: (t:ITag) => void
}
const AdminTagList = ({tags, loadingTags, onRemoveTag, onUpdateTag}: IAdminTagList) => {
    const {t} = useTranslation();
    const {user, isWW} = useContext(NpiInternalContext);

    const {runAsync: updateConfig, loading: loadingUpdate} = useRequest(npiApi.internal.tag.updateConfig, { manual: true,
        onSuccess: onUpdateTag,
    });

    const {runAsync: deleteTag, loading: loadingDelete} = useRequest(npiApi.internal.tag.delete, { manual: true,
        onSuccess: onRemoveTag,
        onError: () => true
    });

    // use to edit tags
    const [form] = Form.useForm();
    const [modalEdit, setModalEdit] = useState({
        tag: null as null|ITag,
        open: false,
        tagWW: false,
    });

    const canEditTagRules = (tag:ITag|null) => {
        if(!tag) return false;
        if(tag.region_ids.length === 0) return false;
        if(tag.config?.isWW && !isWW) return false;
        if( ! tag.config?.isWW && isWW) return false;
        return true;
    };

    const canEditTagNpis = (tag:ITag|null) => {
        if(!tag) return false;
        if(tag.region_ids.length === 0) return false;
        if(tag.config?.isWW) return true;
        if( ! tag.config?.isWW ) return !isWW;
    }


    //Open modal to edit config of the tags for the current region
    const onOpenModalEdit = (tag:ITag) => {
        setModalEdit({
            tag,
            open: true,
            tagWW: !!tag?.config?.isWW
        });
        form.resetFields();
        const config = (isWW ? tag.config : tag.config?.regions?.[user.region_id]) ?? {};
        form.setFieldsValue(config);
    };

    //Delete a tag
    const onDelete = (tag:ITag) => {
        Modal.confirm({
            content: <>Remove the Tag "{tag.name}" for your current region ?</>,
            onOk: () => deleteTag({id: tag.id})
        })
    };

    //Config for the modal to edit a Tag's configuration
    const configModalEdit = {
        maskClosable: false,
        open: modalEdit.open,
        title: <span>Edit Tag: {modalEdit.tag?.name}</span>,
        onCancel: () => setModalEdit({...modalEdit, open: false}),
        onOk: () => updateConfig({id: modalEdit.tag?.id, config: form.getFieldsValue()}).then(() => setModalEdit({...modalEdit, open: false})),
        okButtonProps: {loading: loadingUpdate},
        cancelButtonProps: {disabled: loadingUpdate},
    };

    //Config for the table to display the list of Tags for the current region
    const configTableTag = {
        rowKey: 'id',
        dataSource: tags,
        loading: loadingTags,
        columns: [
            {title: '#', dataIndex: 'id', sorter: (a:ITag, b:ITag) => a.id > b.id ? 1 : -1, defaultSortOrder: 'descend' as SortOrder},
            {title: t('INTERNAL.CLIENT.COMMON.NAME'), dataIndex: 'name', sorter: (a:ITag, b:ITag) => a.name.localeCompare(b.name) },
            {title: t('INTERNAL.CLIENT.COMMON.USED_BY'), dataIndex: 'region_ids', sorter: (a:ITag, b:ITag) => (Array.isArray(a.region_ids) && Array.isArray(b.region_ids)) ? a.region_ids.join(',').localeCompare(b.region_ids.join(',')) : -1,
                render: (regionIds:any, row:ITag) => !!row.config?.isWW
                    ? <NpiDisplayRegion key={REGION_WW} id={REGION_WW}/>
                    : regionIds.length > 0 && regionIds.map((rId:number) => <NpiDisplayRegion key={rId} id={rId}/>).reduce((prev:Element, curr:Element) => [prev, ', ', curr]) //joining with ','
            },
            {title: t('INTERNAL.CLIENT.COMMON.SEARCHABLE'), dataIndex: 'config', align: 'center' as 'center',
                render: (config:any) => <NpiDisplayBoolean value={config?.regions?.[user.region_id]?.searchable}/>
            },
            {title: t('INTERNAL.CLIENT.COMMON.RULES'),
                render: (row:ITag) => <NpiDisplayTagRules tag={row}/>
            },
            {title: t('INTERNAL.CLIENT.NPI.NPIS'), dataIndex: 'npis',
                render: (tagNpis:ITagNpi[]) => tagNpis.map(n => <Tag key={n.id} color={!n.isWWConfig ? 'geekblue' : undefined}>{n.name}</Tag>)
            },
            {title: t('INTERNAL.CLIENT.COMMON.UPDATED_AT'), dataIndex: 'updated_at', sorter: (a:ITag, b:ITag) => moment(a.updated_at) > moment(b.updated_at) ? 1 : -1, render: (date:string) => <NpiDisplayDate value={date} hours={"inline"}/>},
            {
                align: 'right' as 'right',
                render: (row:ITag) => <Space>
                    <Button type="primary" icon={<EditFilled/>} onClick={() => onOpenModalEdit(row)} loading={loadingDelete} disabled={loadingDelete || !(canEditTagRules(row) || canEditTagNpis(row))}/>
                    <Button type="primary" icon={<DeleteFilled/>} danger onClick={() => onDelete(row)} loading={loadingDelete} disabled={loadingDelete || ! canEditTagRules(row)}/>
                </Space>,
            },
        ],
    };

    return <>
        <Table {...configTableTag}/>

        <Modal {...configModalEdit}>
            <Form form={form} layout="horizontal">

                {canEditTagRules(modalEdit.tag) && <>
                    {!isWW &&
                        <Form.Item label={t('INTERNAL.CLIENT.TAGS.SEARCHABLE_ON_EXTERNAL_FORM')} valuePropName="checked" name={"searchable"} tooltip={t('INTERNAL.CLIENT.TAGS.IF_ACTIVE')}>
                            <Switch/>
                        </Form.Item>}
                    {isWW &&
                        <Form.Item name="rules">
                            <NpiInputSelectTagRules/>
                        </Form.Item>}
                </>}

                {canEditTagNpis(modalEdit.tag) && <>
                    {/* WW Tags → Geo users can add Geo npis and WW users can add WW npis*/}
                    { modalEdit.tagWW &&
                        <Form.Item label={t('INTERNAL.CLIENT.TAGS.RESTRICT_NPIS')} name="npis" {...!isWW ? {
                            help: `${t('INTERNAL.CLIENT.TAGS.WW_CONFIG')} : ${modalEdit.tag?.npis?.filter(n => n.isWWConfig).map(n => n.name).join(', ')}`
                        } : {}}>
                            <NpiInputSelectNpi mode={"multiple"} allowClear={true} regionIds={[user.region_id]}/>
                        </Form.Item>}

                    {/* Geo Tags → Geo users can add both WW and Geo npis*/}
                    { (!modalEdit.tagWW && !isWW) &&
                        <Form.Item label={t('INTERNAL.CLIENT.TAGS.RESTRICT_NPIS')} name="npis">
                            <NpiInputSelectNpi mode={"multiple"} allowClear={true} regionIds={[REGION_WW, user.region_id]}/>
                        </Form.Item>}
                </>}
            </Form>
        </Modal>
    </>;
}

export default AdminTagList;