import './assets/stylesheets/external.scss';
import NpiExternalContainerPosHiddenSearch from "./containers/external/pos-hidden-search";
import NpiExternalContainerPosList from "./containers/external/pos-list";
import NpiExternalContainerPosView from "./containers/external/pos-view";
import {NpiExternalContextProvider, useNpiExternalContext} from "./contexts/external-context";
import NpiExternalErrorNoSurvey from "./containers/external/errors/error-no-survey";
import NpiExternalErrorNotFound from "./containers/external/errors/error-not-found";
import NpiLanguageSelector from "./components/select-language";
import React, {useEffect} from "react";
import styled from "styled-components";
import useExternalToken from "./hooks/use-external-token";
import {ExternalTokenRight} from "./types/external-token";
import {LoadingOutlined} from "@ant-design/icons/lib";
import {Redirect, Route, Switch, useLocation} from "react-router-dom";
import {notification} from "antd";


const Spinner = styled.div`
  position: absolute;
  left: 50%;
  top: calc(50% - 45px);
  transform: translateX(-50%);

  svg {
    font-size: 45px;
  }
`

const OptionsHeader = styled.div`
  background: white;
  width: 100%;
  display: flex;
  align-items: center;
`;

const NpiExternalApp = () => {
    // use this to keep compatibility with old app
    return <NpiExternalContextProvider>
        <Switch>
            <Route path="/:tokenStr/list" render={({ match }) => <Redirect to={`/list?token=${match.params.tokenStr}`} />} />
            <Route path="/:tokenStr/hidden" render={({ match }) => <Redirect to={`/hidden?token=${match.params.tokenStr}`} />} />
            <Route path="/:tokenStr/pos/:posId/:tabType?" render={({ match }) => <Redirect to={`/pos/${match.params.posId}/${match.params.tabType}?token=${match.params.tokenStr}`} />} />
            <Route path={"/"} component={NpiExternalSubApp}/>
        </Switch>
    </NpiExternalContextProvider>
}

/**
 * External app entry point, provide and router system for restrict area
 * @constructor
 */
const NpiExternalSubApp = () => {
    const {isLoading, isInitialized, getInterface} = useNpiExternalContext();
    const {hasRight} = useExternalToken();

    const token = new URLSearchParams(useLocation().search).get("token");

    /**
     * Get interface with provided token
     */
    useEffect(() => {
        if (!!token) {
            getInterface(token);
        } else {
            notification.error({message: 'Access error, you need to provide valid token.'});
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token])


    //if context not fully initialized, we draw spinner
    if (isLoading) {
        return <Spinner>
            <LoadingOutlined/>
        </Spinner>;
    }

    if (!isInitialized) {
        return <NpiExternalErrorNoSurvey/>
    }

    return <div className="npi-external-app">
        <OptionsHeader>
            {/* Language selector */}
            <NpiLanguageSelector style={{ textAlign: 'end', marginLeft: 'auto', }}/>
        </OptionsHeader>

        <Switch>
            {hasRight(ExternalTokenRight.ACCESS_EXTERNAL_VIEW) && <>

                {hasRight(ExternalTokenRight.ACCESS_POS_LIST) && (
                    hasRight(ExternalTokenRight.ACCESS_POS_SEARCH)
                        ? <Route path="/hidden" component={NpiExternalContainerPosHiddenSearch}/>
                        : <Route path="/list" component={NpiExternalContainerPosList}/>
                )}
                <Route path={["/pos/:posId/:tabType?"]} component={NpiExternalContainerPosView}/>
            </>}
            <Route component={NpiExternalErrorNotFound}/>
        </Switch>
    </div>
}
export default NpiExternalApp;