import React, {Dispatch, useEffect, useMemo, useState} from 'react';
import {
    Button,
    Col,
    Form,
    Input,
    InputNumber,
    notification,
    Row,
    Select,
    Switch,
    Table,
    Tooltip
} from "antd";
import {IQuestionAnswer, IQuestionTypeEnum, IQuestion, IQuestionAnswerDirectionEnum} from "../../types/question";
import useForm from "antd/lib/form/hooks/useForm";
import {useControllableValue} from "ahooks";
import {toJS} from "mobx";
import _ from "lodash";
import {DeleteFilled, EyeInvisibleOutlined, PlusCircleFilled, SaveFilled} from "@ant-design/icons/lib";
import styled from "styled-components";
import Information from "@2fd/ant-design-icons/lib/Information";
import NpiInputDatePicker from "./date-picker";
import {useTranslation} from "react-i18next";
import NpiTableDragula from "./table-dragula";

const StyledTable = styled(Table)`
    margin: 20px;
    .ant-form-item{
        margin: 0;
    }
    .ant-form-item-explain{
      display: none;
    }
`;

const StyledNpiTableDragula = styled(NpiTableDragula)`
    margin: 20px;
    .ant-form-item{
        margin: 0;
    }
    .ant-form-item-explain{
      display: none;
    }
`;

const StyledForm = styled(Form)`
    position: relative;
    .ant-form-item-explain>*{
        display: none;
    }
    .ant-notification{
        position: absolute;
    }
`;

const {LIST, MULTILIST, BOOL, NUMERIC, IMAGE, TEXT, DATE} = IQuestionTypeEnum;
const {POSITIVE, NEGATIVE, NEUTRAL} = IQuestionAnswerDirectionEnum;

const requiredRules = [{required: true}];

interface INpiInputQuestionConfiguration {
    question?:IQuestion
    onChange?:Dispatch<IQuestion>
    onCancel?:Dispatch<void>
    onDelete?:Dispatch<IQuestion>
    loading?:boolean
}

const NpiInputQuestionConfiguration = (props:INpiInputQuestionConfiguration) => {
    const [question, setQuestion] = useControllableValue<IQuestion>(props, {
        valuePropName: 'question',
    });
    const [answers, setAnswers] = useState<IQuestionAnswer[]>([]);
    const [isDirty, setIsDirty] = useState(false);
    const {onCancel, onDelete, loading=false} = props;
    const [form] = useForm();
    const {t} = useTranslation();
    const {type, id} = question;
    const isAlwaysNeutral = [TEXT, MULTILIST, IMAGE].indexOf(type) >= 0;
    const alwaysNeutralText = (isAlwaysNeutral ? t("INTERNAL.CLIENT.QUESTION.TOOLTIP.NEUTRAL_TEXT", {type}):'');
    const tooltips = {
        blocking: t("INTERNAL.CLIENT.QUESTION.TOOLTIP.BLOCKING"),
        mandatory: t("INTERNAL.CLIENT.QUESTION.TOOLTIP.MANDATORY"),
        date_required: t("INTERNAL.CLIENT.QUESTION.TOOLTIP.DATE_REQUIRED"),
        status_neutral: t("INTERNAL.CLIENT.QUESTION.TOOLTIP.STATUS_NEUTRAL", {alwaysNeutralText: alwaysNeutralText}),
    };

    //Direction select options
    const optionsDirection = useMemo(()=> [
        {label: t("INTERNAL.CLIENT.CONDITION.POSITIVE"), value: POSITIVE},
        {label: t("INTERNAL.CLIENT.CONDITION.NEGATIVE"), value: NEGATIVE},
        {label: t("INTERNAL.CLIENT.CONDITION.NEUTRAL"), value: NEUTRAL},
    ], [t]);


    //Default answers columns used for LIST/MULTILIST/BOOL
    const defaultAnswersColumns = useMemo(() => [
        {
            title: t("INTERNAL.CLIENT.COMMON.ANSWER"),
            dataIndex: 'label',
            render: (_:any, row:any, rowKey:number) =><Form.Item name={["config", "answers", rowKey, "label"]} rules={requiredRules}><Input/></Form.Item>,
        },
        {
            title: <>{t("INTERNAL.CLIENT.COMMON.STORED_VALUE")} <small>({t('INTERNAL.CLIENT.COMMON.MAX_CHARACTERS', {value: 10})})</small></>,
            dataIndex: 'value',
            render: (_:any, row:any, rowKey:number) =><Form.Item name={["config", "answers", rowKey, "value"]} rules={[{pattern: /^[\w\W]{0,10}$/, message: t("INTERNAL.CLIENT.COMMON.MAX_LENGTH_REACHED")},...requiredRules]}><Input/></Form.Item>,
        },
    ], [t]);

    const impactColumn = useMemo(() => ({
        title:  t("INTERNAL.CLIENT.COMMON.IMPACT_ON_STATUS"),
        dataIndex: 'direction',
        render: (_:any, row:any, rowKey:number) =><Form.Item name={["config", "answers", rowKey, "direction"]} rules={requiredRules}><Select options={optionsDirection}/></Form.Item>,
    }), [t, optionsDirection]);

    //List of all answers not hidden, used by the default value select component
    const unhiddenAnswers = useMemo(() => _.chain(answers).cloneDeep().map(a => {a.hidden = false; return a}).value(), [answers]);

    //Initialize (or reset) the form
    const initializeFields = () => {
        const data = toJS(question);
        if(data.config.answers){
            data.config.answers = data.config.answers.map((a:IQuestionAnswer, k) => ({k:k+1, ...a}));
            setAnswers(data.config.answers);
        }
        if(isAlwaysNeutral){
            data.is_status_neutral = true;
        }
        if(type === MULTILIST){
            //Convert the default value stored when in MULTILIST type
            if(data.default_value === null) data.default_value = [];
            else if(_.isString(data.default_value)) data.default_value = JSON.parse(data.default_value);
        }
        const parseData = (_.isArray(data.config) && _.isEmpty(data.config)) ? _.extend(data, {config: {}}) : data; //fix config as array
        form.setFieldsValue(parseData);
        setIsDirty(false);
    };

    //On apply, save recursive merge all values to the question
    const onApply = () => {
        form.validateFields().then(() => {
            //If all is ok, save the question
            const changes = form.getFieldsValue(true);
            const oldQuestion = (_.isArray(question.config) && _.isEmpty(question.config)) ? _.extend(question, {config: {}}) : question;
            const newQuestion = _.merge(oldQuestion, changes);
            console.log('changes', toJS(changes));

            if(changes.config?.answers){
                //always take the answers changed, no a simple merge as it prevents deletion
                newQuestion.config.answers = changes.config.answers;
            }
            setQuestion(newQuestion);
        }).catch(() => {
            //Else notify
            notification.error({
                message: t("INTERNAL.CLIENT.COMMON.REQUIRED_FIELDS"),
            });
        });

        //
    };

    //On cancel reset the form to original values
    const onClickCancel = () => {
        initializeFields();
        onCancel && onCancel();
    };

    //When changing form, manage dirty state
    const onChange = (field:any/*, fields:any[]*/) => {
        setIsDirty(form.isFieldsTouched());

        //Blocking & Mandatory fields are linked together
        const values = form.getFieldsValue();
        if(field[0].name[0] === 'is_blocking' && values.is_blocking) form.setFieldsValue({is_mandatory: true});
        if(field[0].name[0] === 'is_mandatory' && !values.is_mandatory) form.setFieldsValue({is_blocking: false});

        //Update our local answers when the form changes them
        if(field[0].name[0] === 'config' && field[0].name[1] === 'answers') setAnswers(form.getFieldValue(['config', 'answers']));

        //Force always neutral for specific question types
        if(isAlwaysNeutral){form.setFieldsValue({is_status_neutral: true})}

        // Fix undefined value when clear that make the old default value not being overrided by new one.
        if(values.default_value === undefined) {
            form.setFieldsValue({default_value: null});
        }
    };

    //Sort answers
    const onSortAnswers = (answers: any) => {
        const values = form.getFieldsValue(true);
        values.config.answers = answers;
        form.setFieldsValue({...values});
        setAnswers(values.config.answers);
        setIsDirty(true);
    };

    //A label used many places
    const labelDefaultWhenHidden = <Tooltip title={t("INTERNAL.CLIENT.QUESTION.HIDDEN_QUESTION")}>{t("INTERNAL.CLIENT.COMMON.DEFAULT")} <EyeInvisibleOutlined/></Tooltip>;

    const columnsTypeList = useMemo(() => {
        //When adding an answer
        const onAddAnswer = () => {
            const values = form.getFieldsValue(true);
            if( ! values.config?.answers){
                values.config = _.extend(values.config, {answers: []});
            }
            const maxK = (_.maxBy(values.config.answers, 'k') as any)?.k ?? 0;
            values.config.answers.push({k: maxK+1});
            values.config.answers = [...values.config.answers];
            form.setFieldsValue({...values});
            setAnswers(values.config.answers);
            setIsDirty(true);
        };

        //When deleting an answer
        const onDeleteAnswer = (key:number) => {
            const values = form.getFieldsValue(true);
            if(values.config.answers.length === 1){
                notification.error({message: t("INTERNAL.CLIENT.QUESTION.LIST_QUESTION_MUST_HAVE_ANSWER")});
                return;
            }
            values.config.answers.splice(key, 1);
            values.config.answers = [...values.config.answers];
            form.setFieldsValue({...values});
            setAnswers(values.config.answers);
            setIsDirty(true);
        };

        //Preparing final columns
        const result = [...defaultAnswersColumns];

        //Impact is valid for LIST questions only, MULTILIST cannot have positive/negative status
        if(question.type === LIST){
            result.push({...impactColumn});
        }

        return [
            ...result,
            {
                title: <Tooltip title={t("INTERNAL.CLIENT.QUESTION.IF_CHECKED_VALUE_WILL_NEVER_BE_SHOWN_TO_USER")}><EyeInvisibleOutlined/></Tooltip>,
                render: (_:any, row:any, rowKey:number) => <Form.Item name={["config", "answers", rowKey, "hidden"]} valuePropName="checked"><Switch/></Form.Item>,
                align: 'center' as 'center',
            },
            {
                render: (_:any, row:any, rowKey:number) => <Button icon={<DeleteFilled/>} danger type="link" onClick={() => onDeleteAnswer(rowKey)}/>,
                align: 'right' as 'right',
                title: <Button icon={<PlusCircleFilled/>} type="link" onClick={onAddAnswer}/>,
            }
        ]
    }, [form, question.type, defaultAnswersColumns, impactColumn, t]);

    //Boolean type has basic columns + impact
    const columnsTypeBoolean = useMemo(() => [
        ...defaultAnswersColumns, _.cloneDeep(impactColumn)
    ], [defaultAnswersColumns, impactColumn]);

    //Initialize fields on load
    useEffect(initializeFields, [question, form, isAlwaysNeutral, type]);

    return <StyledForm form={form} labelCol={{ span: 4 }} onFieldsChange={onChange}>
        <Form.Item label={t("INTERNAL.CLIENT.COMMON.LABEL")} name={"name"} rules={requiredRules} required><Input/></Form.Item>
        <Form.Item label={t("INTERNAL.CLIENT.COMMON.QUESTION")} name={"display_name"} rules={requiredRules} required><Input/></Form.Item>
        <Form.Item label={t("INTERNAL.CLIENT.COMMON.TOOLTIP")} name={"description"}><Input.TextArea/></Form.Item>
        <Row>
            <Col md={{span: 24, offset:0}} xl={{span: 10, offset:2}} xxl={{span: 6, offset:2}}><Form.Item labelCol={{span:12}}  label={<Tooltip title={tooltips.mandatory}>{t("INTERNAL.CLIENT.COMMON.MANDATORY")} <Information/></Tooltip>} name={"is_mandatory"} valuePropName="checked"><Switch/></Form.Item></Col>
            <Col md={{span: 24, offset:0}} xl={10} xxl={5}><Form.Item labelCol={{span:12}} label={<Tooltip title={tooltips.blocking}>{t("INTERNAL.CLIENT.COMMON.BLOCKING")} <Information/></Tooltip>} name={"is_blocking"} valuePropName="checked"><Switch/></Form.Item></Col>
            <Col md={{span: 24, offset:0}} xl={{span: 10, offset: 2}} xxl={{span: 6, offset: 0}}><Form.Item labelCol={{span:12}}  label={<Tooltip title={tooltips.date_required}>{t("INTERNAL.CLIENT.COMMON.WITH_DATE")} <Information/></Tooltip>} name={"is_date_required"} valuePropName="checked"><Switch/></Form.Item></Col>
            <Col md={{span: 24, offset:0}} xl={10} xxl={5}><Form.Item labelCol={{span:12}}  label={<Tooltip title={tooltips.status_neutral}>{t("INTERNAL.CLIENT.CONDITION.NEUTRAL")} <Information/></Tooltip>} name={"is_status_neutral"} valuePropName="checked"><Switch disabled={isAlwaysNeutral}/></Form.Item></Col>
        </Row>

        {[LIST, MULTILIST].indexOf(type) >= 0 && <>
            <StyledNpiTableDragula columns={columnsTypeList} dataSource={answers} pagination={false} size={"small"} rowKey={'k'} onChange={onSortAnswers}/>
            <Form.Item label={labelDefaultWhenHidden} name={"default_value"}>
                <Select mode={LIST === type ? undefined:'multiple'} options={unhiddenAnswers} style={{width: '50%'}} placeholder={t("INTERNAL.CLIENT.COMMON.SELECT_VALUE")} allowClear={true}/>
            </Form.Item>
        </>}

        {BOOL === type && <>
            <StyledTable columns={columnsTypeBoolean} dataSource={answers} pagination={false} size={"small"} rowKey={(r:any)=>answers.indexOf(r)}/>
            <Form.Item label={labelDefaultWhenHidden} name={"default_value"}><Select options={answers} style={{width: '50%'}} placeholder={t("INTERNAL.CLIENT.COMMON.SELECT_VALUE")} allowClear={true}/></Form.Item>
        </>}

        {NUMERIC === type && <>
            <Form.Item label={t("INTERNAL.CLIENT.COMMON.MINIMUM")} name={["config", "min"]}><InputNumber/></Form.Item>
            <Form.Item label={t("INTERNAL.CLIENT.COMMON.MAXIMUM")} name={["config", "max"]}><InputNumber/></Form.Item>
            <Form.Item label={labelDefaultWhenHidden} name={"default_value"}><InputNumber/></Form.Item>
        </>}

        {IMAGE === type && <>
            <Form.Item label={<Tooltip title={t("INTERNAL.CLIENT.QUESTION.MAXIMUM_NUMBER_IMAGES")}>{t("INTERNAL.CLIENT.COMMON.MAXIMUM")} <Information/></Tooltip>} name={["config", "max"]}><InputNumber min={0}/></Form.Item>
        </>}

        {TEXT === type && <>
            <Form.Item label={labelDefaultWhenHidden} name={"default_value"}><Input.TextArea/></Form.Item>
        </>}

        {DATE === type && <>
            <Form.Item label={labelDefaultWhenHidden} name={"default_value"}>
                <NpiInputDatePicker/>
            </Form.Item>
        </>}

        {!!id && !!onDelete && <Button danger style={{float: 'left'}} icon={<DeleteFilled/>} onClick={() => onDelete(question)}>{t("COMMON.REMOVE")}</Button>}

        <div style={{float: 'right'}}>
            <Button onClick={onClickCancel} style={{marginRight: 10}}>{t("COMMON.CANCEL")}</Button>
            <Button type="primary" onClick={onApply} disabled={!isDirty} loading={loading} icon={<SaveFilled/>}>{t("COMMON.SAVE")}</Button>
        </div>
        <div className="clearfix"/>
    </StyledForm>
};

export default NpiInputQuestionConfiguration;