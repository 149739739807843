
export enum IBatteryColor {
    'POSITIVE' = '#0091ff',
    'NEGATIVE' = '#ffa600',
    'NEUTRAL' = 'rgb(209, 218, 222)',
    'WHITE'   = '#FFF',
    'GREY'    = '#666',
}

export interface IBatteryChartElementLabel {
    color: string;
}

export interface IBatteryChartElement {
    color?: IBatteryColor;
    width?: any;
    label?: string;
    value?: number;
    labelStyle?: IBatteryChartElementLabel
}
